import { motion } from "framer-motion";

/**
 * Content
 * This component renders client templates/contact method
 *
    2md:(p-16 !w-full)
    lg:(!w-[90%] -mx-2)
    xl:!w-[80%]
 */

export default function LeadDetailContent(props) {
  return (
    <motion.div
      className="fixed right-0 top-0 bottom-0 overflow-hidden flex flex-wrap flex-row-reverse content-start z-9999"
      {...props}
    >
      {props.children}
    </motion.div>
  );
}
