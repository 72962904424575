import "assets/css/components/campaign-details.css";
import LoadingIndicator from "assets/svg/loading-indicator";
import Toggle from "components/toggle/toggle";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

export const ReCaptcha = () => {
  const { http } = global.services;
  const { campaign } = useContext(Context);
  const [loaded, setLoaded] = useState(campaign && campaign.id ? true : false);
  const [enabled, setEnabled] = useState(!!campaign?.captcha_id);
  const [saving, setSaving] = useState(false);

  const updateEnabled = async (e) => {
    const previousEnabled = enabled;
    setEnabled(!previousEnabled);
    setSaving(true);

    try {
      if (campaign.id) {
        const params = {
          enabled: !previousEnabled,
          campaign_id: campaign.id,
        };
        const res = await http.post(`captchas`, params);
        if (!_.isEmpty(res.data.data)) {
          toast.success("Campaign reCAPTCHA Updated");
        }
      }
    } catch (e) {
      console.error(e);
      toast.error("It appears that something went wrong");
      setEnabled(previousEnabled);
    }

    setSaving(false);
  };

  useEffect(() => {
    if (campaign.id) {
      setLoaded(true);
      setEnabled(!!campaign.captcha_id);
    }
  }, [campaign, loaded]);

  return loaded ? (
    <div className="h-full bg-white border border-thBorder divide-y divide-thBorder">
      <div className="w-full md:flex">
        <div className="w-6/20 pl-10 py-6">
          <p className="text-xl">reCAPTCHA</p>
        </div>
        <div className="w-14/20 py-6 pl-10 md:pl-0">
          <p>Enable reCAPTCHA v3 for forms on this campaign.</p>
          <div
            className={`w-full my-3 flex items-center ${
              saving ? "opacity-50 pointer-events-none" : ""
            }`}
          >
            <Toggle
              disabled={saving}
              checked={enabled}
              onChange={updateEnabled}
            />
            {saving ? (
              <div className="flex items-center gap-2 ml-3">
                Saving... <LoadingIndicator className="inline-block h-4" />
              </div>
            ) : (
              <p className="ml-3">{enabled ? "Enabled" : "Disabled"}</p>
            )}
          </div>
          <p className="my-3 text-sm">
            By enabling reCAPTCHA you agree to Google's{" "}
            <a className="text-blue" href="https://policies.google.com/privacy">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a className="text-blue" href="https://policies.google.com/terms">
              Terms of Service
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full grid items-center justify-center">
      <LoadingIndicator className="w-7 h-7 text-secondary" />
    </div>
  );
};

export default ReCaptcha;
