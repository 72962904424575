import { createContext, useMemo, useState } from "react";
import { useTabs } from "react-headless-tabs";
import { POPUP_INITIALS } from "utils/constant";

export const Context = createContext();

export const ContextProvider = ({ initials, children }) => {
  const [alerts, setAlerts] = useState([]);
  const [primaryMediaTab, setPrimaryMediaTab] = useState("image");
  const [hasUploadError, setOnUploadError] = useState(false);
  const [snapshot, setSnapshot] = useState({});
  const [view, setView] = useState("desktop");
  const [campaign, setCampaign] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [popup, setPopup] = useState(POPUP_INITIALS);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [mobileContentOpen, setMobileContentOpen] = useState(true);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [fonts, setFonts] = useState([]);
  const [template, setTemplate] = useState({});
  const [guestCampaign, setGuestCampaign] = useState({});
  const [tabs, setTabs] = useTabs(["layout", "image"]);
  const [selectedTab, setSelectedTab] = useTabs([
    "basic",
    "popout",
    "template",
    "content",
    "contactM",
    "contactD",
    "style",
    "effects",
    "preview",
  ]);
  const [mobileTabs, setMobileTabs] = useTabs(["key-points", "contact-info"]);
  const [defaultTab, setDefaultTab] = useState("basic");
  const [templateData, setTemplateData] = useState([]);
  const temp = useMemo(
    () => ({ templateData, setTemplateData }),
    [templateData]
  );
  const [contactsData, setContactsData] = useState([]);
  const contacts = useMemo(
    () => ({ contactsData, setContactsData }),
    [contactsData]
  );
  const [slider, setSlider] = useState(0);
  const sliderWidth = useMemo(() => ({ slider, setSlider }), [slider]);
  const [overlay, toggleOverlay] = useState(0);
  const overlayHelp = useMemo(() => ({ overlay, toggleOverlay }), [overlay]);
  const [alternative, setAlternative] = useState({
    campaign_id: null,
    popups: [],
    condition: null,
    popup: {},
  });
  const editAlternative = useMemo(
    () => ({ alternative, setAlternative }),
    [alternative]
  );

  const [popoutCount, setPopoutCount] = useState(0);
  const countPopout = useMemo(
    () => ({ popoutCount, setPopoutCount }),
    [popoutCount]
  );
  const [disabledStylePopout, setDisabledStylePopout] = useState([]);
  const disabled = useMemo(
    () => ({ disabledStylePopout, setDisabledStylePopout }),
    [disabledStylePopout]
  );

  const [popoutOrder, setPopoutOrder] = useState([]);
  const popoutOrderList = useMemo(
    () => ({ popoutOrder, setPopoutOrder }),
    [popoutOrder]
  );

  const [image, setImage] = useState({
    uploading: false,
    id: null,
    image_url: null,
    image_key: null,
  });
  const imageObject = useMemo(() => ({ image, setImage }), [image]);

  return (
    <Context.Provider
      value={{
        alerts,
        setAlerts,
        mobileSidebar,
        setMobileSidebar,
        snapshot,
        setSnapshot,
        popup,
        setPopup,
        view,
        setView,
        selectedTab,
        setSelectedTab,
        mobileTabs,
        setMobileTabs,
        mobileContentOpen,
        setMobileContentOpen,
        fonts,
        setFonts,
        template,
        setTemplate,
        campaign,
        setCampaign,
        guestCampaign,
        setGuestCampaign,
        currentPlan,
        setCurrentPlan,
        forceUpdate,
        setForceUpdate,
        defaultTab,
        setDefaultTab,
        tabs,
        setTabs,
        hasUploadError,
        setOnUploadError,
        primaryMediaTab,
        setPrimaryMediaTab,
        validationErrors,
        setValidationErrors,
        ...temp,
        ...contacts,
        ...sliderWidth,
        ...overlayHelp,
        ...editAlternative,
        ...countPopout,
        ...disabled,
        ...popoutOrderList,
        ...imageObject,
      }}
    >
      {children}
    </Context.Provider>
  );
};
