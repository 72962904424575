import * as action from "./types";

export const addNotification = (payload) => ({
  type: action.ADD_NOTIFICATION,
  payload,
});

export const setNotifications = (payload) => ({
  type: action.SET_NOTIFICATIONS,
  payload,
});

export const setNotificationBanner = (payload) => ({
  type: action.SET_NOTIFICATION_BANNER,
  payload,
});

export const setNotificationUserReads = (payload) => ({
  type: action.SET_NOTIFICATION_USER_READS,
  payload,
});

export const addNotificationUserRead = (payload) => ({
  type: action.ADD_NOTIFICATION_USER_READ,
  payload,
});
