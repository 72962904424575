import React, { useContext, useEffect, useState } from "react";
import { ColorPicker, toColor, useColor } from "react-color-palette";
import { Context } from "context/context";
import Button from "components/button/button";
import Input from "components/input/input";
import PopupComponent from "components/popup/popup";
import PopupContent from "components/popup/popup-content";
import "react-color-palette/lib/css/styles.css";

export default function CampaignPopupColorPicker({
  margin = "100px 0px 0px 0px",
  icon = "plusIcon",
  defaultValue = null,
  className = "btn-color-picker rounded-full border border-thBorder",
  addColor = () => {},
  colorRef = null,
  name = null,
  primaryColor = null,
  buttonColor = null,
  position = "right center",
}) {
  const { popup, setPopup } = useContext(Context);
  const currentColor = popup[name] ? popup[name] : defaultValue;

  const ref = colorRef;
  const [color, setColor] = useColor("hex", currentColor);
  const [hex, setHex] = useState(currentColor);

  const toggleTooltip = () => {
    setPopup({ ...popup, dismiss_tutorial: true });
    ref.current.toggle();
  };

  const onAddColor = (colour) => {
    addColor(colour);
    toggleTooltip();
  };

  const changeColor = (e) => {
    setColor(e);
    setHex(e.hex);
  };

  const handleChangeColor = (el, value) => {
    if (el === "hex") {
      setHex(value);
      let hexColor = toColor("hex", value);
      setColor(hexColor);
    } else if (el === "r") {
      if (value > 255) value = 255;
      let rgb = { b: color.rgb.b, g: color.rgb.g, r: value };
      let rgbColor = toColor("rgb", rgb);
      setHex(rgbColor.hex);
      setColor(rgbColor);
    } else if (el === "g") {
      if (value > 255) value = 255;
      let rgb = { b: color.rgb.b, g: value, r: color.rgb.r };
      let rgbColor = toColor("rgb", rgb);
      setHex(rgbColor.hex);
      setColor(rgbColor);
    } else if (el === "b") {
      if (value > 255) value = 255;
      let rgb = { b: value, g: color.rgb.g, r: color.rgb.r };
      let rgbColor = toColor("rgb", rgb);
      setHex(rgbColor.hex);
      setColor(rgbColor);
    }
  };

  useEffect(() => {
    if (popup[name]) {
      const currentColor = toColor("hex", popup[name]);
      setColor(currentColor);
      setHex(currentColor.hex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryColor, buttonColor]);

  return (
    <PopupComponent
      position={position}
      className={`${className} relative bottom-2 box-shadow animate-btn`}
      activeColor="bg-default"
      closeOnDocumentClick={true}
      icon={icon}
      ref={ref}
    >
      <PopupContent
        width="357px"
        footerBg={"bg-white"}
        footer={
          <span className="w-full">
            <Button
              buttonName="ADD"
              buttonTextClass="text-white text-xs font-bold"
              buttonClass="bg-primary rounded-full py-3 float-right"
              buttonWidth="30%"
              onClick={() => onAddColor(color.hex)}
            />
            <Button
              buttonName="CANCEL"
              buttonTextClass="text-black text-xs font-bold"
              buttonClass="bg-transparent rounded-full py-3 float-right"
              buttonType="transparent"
              buttonWidth="30%"
              onClick={toggleTooltip}
            />
          </span>
        }
      >
        <div className="w-full p-5">
          <ColorPicker
            width={317}
            height={238}
            color={color}
            onChange={changeColor}
            hideHSV
            hideHEX
            hideRGB
          />
          {/* value of colors */}
          <div className="flex space-x-3 -mt-3">
            <div>
              <span className="text-xs font-bold pl-2">Hex</span>
              <Input
                noPaddingRight={true}
                value={hex}
                onChange={(e) => handleChangeColor("hex", e.target?.value)}
              />
            </div>
            <div className="input-colors">
              <span className="text-xs font-bold pl-2">R</span>
              <Input
                noPaddingRight={true}
                value={color.rgb?.r}
                onChange={(e) =>
                  handleChangeColor("r", Number(e.target?.value))
                }
              />
            </div>
            <div className="input-colors">
              <span className="text-xs font-bold pl-2">G</span>
              <Input
                noPaddingRight={true}
                value={color.rgb?.g}
                onChange={(e) =>
                  handleChangeColor("g", Number(e.target?.value))
                }
              />
            </div>
            <div className="input-colors">
              <span className="text-xs font-bold pl-2">B</span>
              <Input
                noPaddingRight={true}
                value={color.rgb?.b}
                onChange={(e) =>
                  handleChangeColor("b", Number(e.target?.value))
                }
              />
            </div>
          </div>
        </div>
        {/* content */}
      </PopupContent>
    </PopupComponent>
  );
}
