export const mapStyleOptions = (
  iconPosition,
  padding,
  errorMessage,
  label
) => ({
  menu: (provided) => ({
    ...provided,
    zIndex: 30,
  }),
  control: (provided) => {
    return {
      ...provided,
      width: "100%",
      minHeight: 0,
      boxShadow: "none",
      borderColor: errorMessage ? "red" : "#dfdfdf",
      "&:hover": {
        borderColor: "#dfdfdf",
      },
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      width: "100%",
      padding: 0,
      paddingLeft: "8px",
      paddingRight: "8px",
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      width: "100%",
      top: "45%",
      marginLeft: 30,
      color: "#aaaaaa",
      fontSize: "16px",
    };
  },
  singleValue: (provided) => ({
    ...provided,
    maxWidth: "90%",
  }),
  input: (provided) => {
    return {
      ...provided,
      width: "95%",
      paddingLeft: iconPosition === "left" ? "1.8rem" : "1rem",
      paddingRight: padding(),
      paddingTop: !label ? "0.5rem" : 2,
      paddingBottom: 0,
    };
  },
});
