const QuickSupportIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="51"
      height="68"
      viewBox="0 0 51 68"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25.5" cy="41.4031" r="25.5" fill="white" />
      <g clipPath="url(#clip0_5504_24753)">
        <path
          d="M16.4402 14.5783C16.9127 15.4687 17.2027 12.4872 17.6592 12.217C18.1157 11.9468 21.8653 9.55043 23.0805 8.92944C23.4973 8.7399 23.9418 8.65972 24.3883 8.69356C24.8348 8.72739 25.2745 8.87456 25.6817 9.12655C26.8948 9.83874 27.4594 11.5119 27.7427 13.1763C28.1309 15.4722 28.6501 14.9904 28.9522 16.2863C29.3243 17.8824 29.573 18.9711 29.7709 19.1777C29.9678 19.2845 30.1854 19.3001 30.3858 19.2218C30.5862 19.1434 30.6098 19.2138 30.6971 19.1017C30.871 18.8786 30.8684 18.5177 30.8655 17.6419C30.9983 15.6667 30.8215 13.665 30.3454 11.7544C29.866 9.83566 27.7426 7.48416 27.7426 7.48416C27.7426 7.48416 24.5458 5.8797 22.6105 6.12731C20.685 6.39382 18.5676 6.09789 17.1615 7.7653C16.4829 8.49691 16.1089 8.77898 16.2004 11.1255"
          fill="#263238"
        />
        <path
          d="M20.282 31.4598C20.282 31.4598 18.4229 44.036 18.2978 45.5501L34.1733 46.9393L31.6159 34.7152C31.6159 34.7152 30.8703 30.688 30.502 28.1863C29.8911 24.0353 32.0894 18.164 32.0894 18.164C32.4735 12.9022 29.7098 7.66475 24.6159 7.4472L24.1434 7.44027C21.8541 7.48384 18.7707 7.8963 17.1065 9.53267C15.4423 11.169 15.2213 13.2036 15.1609 16.2726C15.1005 19.3416 15.0838 18.8597 15.4197 21.5387C15.7556 24.2177 16.015 28.8806 20.282 31.4598Z"
          fill="#FFBE9D"
        />
        <path
          d="M24.1553 24.8896C24.1892 25.5819 24.0107 26.3764 23.5468 26.9387C23.0829 27.501 22.3336 27.8311 21.6552 27.6803C21.0452 27.5736 20.5305 27.0976 20.0841 26.6656L24.1553 24.8896Z"
          fill="white"
        />
        <rect
          x="28.5257"
          y="8.4082"
          width="1.78599"
          height="2.18288"
          fill="#FFBE9D"
        />
        <ellipse
          cx="21.3646"
          cy="23.3284"
          rx="0.512617"
          ry="0.512617"
          transform="rotate(1.79121 21.3646 23.3284)"
          fill="#EB996E"
        />
        <path
          d="M20.2811 31.4606C22.5676 31.4781 25.1779 31.1961 27.6236 28.8809C27.6236 28.8809 25.0186 33.1563 20.0825 32.6514L20.2811 31.4606Z"
          fill="#EB996E"
        />
        <path
          d="M31.4269 19.8477C31.5187 19.8345 35.3157 19.7073 34.0477 23.3638C32.7797 27.0202 29.9704 25.1789 29.9969 25.0711C30.0233 24.9634 31.4269 19.8477 31.4269 19.8477Z"
          fill="#FFBE9D"
        />
        <path
          d="M31.5641 23.883C31.5641 23.883 31.6049 23.9465 31.6863 24.0244C31.7422 24.0772 31.8078 24.1173 31.8794 24.1426C31.951 24.1678 32.0271 24.1775 32.1034 24.1713C32.3598 24.1029 32.5971 23.964 32.7912 23.7689C32.9852 23.5738 33.1289 23.3295 33.2075 23.0612C33.3153 22.7677 33.3632 22.4556 33.348 22.1461C33.3551 22.0284 33.3354 21.9117 33.2906 21.8061C33.2457 21.7005 33.177 21.6089 33.0904 21.5393C33.0329 21.497 32.9623 21.4786 32.8912 21.4874C32.8201 21.4962 32.7533 21.5315 32.7028 21.5871C32.6247 21.664 32.6459 21.7513 32.6033 21.7424C32.5607 21.7336 32.558 21.6589 32.6328 21.5255C32.6792 21.4477 32.7483 21.3877 32.8289 21.355C32.9426 21.3149 33.0648 21.3226 33.1696 21.3767C33.2936 21.4515 33.3954 21.5606 33.4648 21.6929C33.5342 21.8252 33.5686 21.9761 33.5646 22.1302C33.598 22.4758 33.5503 22.8282 33.4256 23.1564C33.3337 23.4698 33.1579 23.7514 32.9201 23.9659C32.6823 24.1804 32.393 24.3184 32.0884 24.3626C31.9935 24.3585 31.9015 24.332 31.8191 24.2849C31.7367 24.2378 31.666 24.1714 31.6121 24.0904C31.5408 23.967 31.5521 23.8849 31.5641 23.883Z"
          fill="#EB996E"
        />
        <path
          d="M25.8387 9.56764C24.3504 9.76609 23.2589 9.22304 21.969 9.56764C20.6792 9.91225 19.2373 10.4333 18.1985 8.87325C16.9084 6.93587 18.2981 5.30133 19.5876 4.80512C20.8772 4.30891 22.9173 6.09475 25.1443 6.09483C27.823 6.09493 29.4206 6.54968 30.7133 8.31588C31.6999 9.77229 32.3104 11.5833 32.4692 13.5241C32.6261 15.4565 32.4729 17.4281 32.0186 19.3231L32.0154 19.343C31.8773 20.1789 31.8201 20.5255 31.6137 20.712C31.5092 20.8064 31.4974 20.7342 31.287 20.7773C31.0765 20.8204 31.1748 20.8166 30.9982 20.6806C30.8368 20.4476 31.4077 17.0093 30.0058 15.1241C28.604 13.2389 30.9116 10.7942 29.5097 9.56764C28.1079 8.34105 27.327 9.3692 25.8387 9.56764Z"
          fill="#263238"
        />
        <path
          d="M23.3164 18.9909C23.4292 19.1049 24.1485 18.605 25.1433 18.601C26.1381 18.597 26.8506 19.0616 26.963 18.9438C27.0194 18.894 26.9103 18.6882 26.6012 18.4693C26.1812 18.1892 25.6743 18.0433 25.1495 18.0515C24.6321 18.0532 24.1211 18.2127 23.6916 18.5065C23.3763 18.7304 23.2633 18.9398 23.3164 18.9909Z"
          fill="#263238"
        />
        <path
          d="M16.3286 18.721C16.4246 18.8359 17.1033 18.4634 18.0045 18.5676C18.9057 18.6718 19.5257 19.1678 19.6339 19.0738C19.6877 19.035 19.6001 18.8378 19.332 18.607C18.9669 18.309 18.5157 18.1225 18.04 18.073C17.5713 18.0185 17.0998 18.1069 16.6948 18.3251C16.397 18.4927 16.2833 18.6692 16.3286 18.721Z"
          fill="#263238"
        />
        <path
          d="M22.7783 15.6406C23.2888 16.0987 26.7165 15.0124 29.1037 17.3229C28.7145 13.7477 23.0886 14.7963 22.7884 14.8413C22.7884 14.8413 22.2678 15.1824 22.7783 15.6406Z"
          fill="#263238"
        />
        <path
          d="M20.2241 15.6967C19.8664 16.0943 17.564 14.6375 15.8875 16.6635C16.2368 13.1623 20.0334 14.8253 20.2362 14.9031C20.2362 14.9031 20.5818 15.299 20.2241 15.6967Z"
          fill="#263238"
        />
        <path
          d="M21.2567 17.4402C21.1367 18.0999 20.9858 18.7422 20.8579 19.3959L20.6664 20.3798L20.4669 21.3576C20.3954 21.6818 20.339 22.0112 20.2754 22.3416L20.2218 22.5847L20.2082 22.6438C20.2091 22.6508 20.204 22.6658 20.2049 22.6728L20.2007 22.6948C20.2025 22.7089 20.1964 22.7169 20.1983 22.7309C20.2002 22.745 20.1941 22.7529 20.1959 22.767C20.2001 22.8523 20.2403 22.9399 20.3129 23.0019C20.3457 23.0333 20.3916 23.0558 20.4356 23.0643C20.4576 23.0685 20.4797 23.0727 20.5087 23.076L20.5298 23.0733L20.5579 23.0695L20.6221 23.0682C20.7927 23.0599 20.9563 23.0525 21.1268 23.0442C21.4619 23.0355 21.7979 23.0339 22.1376 23.0604L22.1441 23.1096C21.823 23.2237 21.4982 23.3097 21.1725 23.3887C21.0056 23.4251 20.8458 23.4606 20.679 23.497L20.6166 23.5124L20.5885 23.5161L20.5463 23.5217C20.4901 23.5292 20.4259 23.5305 20.3678 23.5239C20.2516 23.5107 20.1316 23.4694 20.03 23.4042C19.9284 23.339 19.845 23.2499 19.7809 23.1439C19.7168 23.0379 19.686 22.9133 19.6771 22.7928C19.6734 22.7647 19.6758 22.7286 19.6791 22.6996C19.6824 22.6705 19.6857 22.6415 19.689 22.6124L19.6975 22.5684L19.7078 22.5384L19.7275 22.4714L19.7961 22.2334C19.8896 21.9134 19.9902 21.5925 20.0766 21.2734L20.3422 20.3083L20.6147 19.3422C20.8008 18.6952 21.0645 17.6087 21.1818 17.4144C21.2991 17.22 21.2567 17.4402 21.2567 17.4402Z"
          fill="#263238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.3302 33.3843C30.3393 33.5296 30.4819 34.6847 30.6562 36.0906C30.7827 36.8542 30.6709 37.6397 30.337 38.333C30.0384 38.927 29.6761 39.4843 29.257 39.9943L27.0699 41.9673L26.5854 43.8418L23.642 39.5023C21.3168 38.8767 20.23 35.2658 20.23 35.2658L19.9685 33.5911L18.5536 35.0534L15.977 35.7305C15.977 35.7305 10.224 37.6051 8.79077 39.2218L13.5001 61.8942L13.4163 69.0503H40.6943C40.6943 68.6964 40.9388 45.2953 40.9388 45.2953L42.5758 36.1125L32.8877 34.3481L31.2793 33.0386C30.7273 33.2919 30.3302 33.3843 30.3302 33.3843Z"
          fill="#28265B"
        />
        <path
          d="M35.7361 61.4924C36.1395 60.9516 36.5741 60.4366 37.0374 59.95C37.4731 59.4345 37.9376 58.9459 38.4287 58.4868C38.0237 59.0276 37.5884 59.5434 37.1252 60.0315C36.6896 60.5456 36.2258 61.0334 35.7361 61.4924Z"
          fill="white"
        />
        <path
          d="M35.8305 55.6802C35.21 55.0473 34.7432 54.2712 34.4685 53.4155C34.5023 53.4016 34.7432 53.9444 35.1214 54.5665C35.4996 55.1886 35.8576 55.6569 35.8305 55.6802Z"
          fill="white"
        />
        <path
          d="M13.5497 51.3501C13.9531 50.8093 14.3877 50.2942 14.851 49.8077C15.2867 49.2921 15.7512 48.8036 16.2423 48.3445C15.8373 48.8853 15.402 49.4011 14.9388 49.8892C14.5032 50.4033 14.0394 50.891 13.5497 51.3501Z"
          fill="white"
        />
        <path
          d="M15.4517 58.5791C15.5459 57.6892 15.7161 56.8097 15.9605 55.9509C15.9333 56.398 15.8677 56.8416 15.7646 57.2766C15.6989 57.7198 15.5941 58.1557 15.4517 58.5791Z"
          fill="white"
        />
        <path
          d="M42.578 36.1116C43.6029 36.7148 44.502 37.5225 45.2234 38.4877C45.9447 39.453 46.4738 40.5568 46.7801 41.7352C47.8737 45.7841 51.2178 56.3283 51.2178 56.3283C51.2178 56.3283 53.1287 58.6551 53.3325 60.3773C53.5362 62.0995 53.1287 66.268 52.5129 67.0459C52.3345 67.2717 52.1055 67.4488 51.8459 67.5619C51.5862 67.6751 51.3037 67.7207 51.0231 67.695L42.9787 66.9054L39.809 44.9546L42.578 36.1116Z"
          fill="#28265B"
        />
        <path
          d="M8.79269 39.2209C8.79269 39.2209 6.89763 41.8945 6.11198 43.6401C5.32634 45.3858 -1.19656 60.0423 -1.58598 61.5583C-1.97541 63.0743 -2.98973 67.8403 -0.0124302 68.9463H6.11651L12.2658 56.0753L12.5805 41.1377L8.79269 39.2209Z"
          fill="#28265B"
        />
        <path
          d="M6.50094 59.6493C6.50094 59.6493 6.36509 59.5251 6.08208 59.3587C5.67813 59.1345 5.24498 58.972 4.79607 58.8761C4.48743 58.8061 4.17407 58.7606 3.85873 58.7402C3.49541 58.7118 3.13004 58.7387 2.77422 58.8198C1.9688 59.0339 1.24785 59.5024 0.71162 60.1601C0.166384 60.8105 -0.232631 61.578 -0.45666 62.4072C-0.641582 63.085 -0.764385 63.7793 -0.823444 64.4809C-0.882311 65.0807 -0.902688 65.5705 -0.923065 65.9055C-0.923065 66.0649 -0.941176 66.1937 -0.945704 66.2945C-0.94558 66.3405 -0.951677 66.3862 -0.963817 66.4304C-0.966732 66.3852 -0.966732 66.3397 -0.963817 66.2945C-0.963817 66.1914 -0.963817 66.0602 -0.963817 65.9055C-0.963817 65.5658 -0.950235 65.0784 -0.902689 64.4739C-0.85482 63.7608 -0.738783 63.0544 -0.556281 62.365C-0.333833 61.517 0.0709668 60.732 0.627849 60.0687C1.18082 59.3926 1.92545 58.9138 2.75611 58.7003C3.11926 58.6221 3.49171 58.6007 3.86099 58.6371C4.18065 58.6614 4.4979 58.7123 4.80965 58.7894C5.26359 58.8938 5.699 59.0709 6.1002 59.3142C6.20825 59.3833 6.3105 59.4617 6.40585 59.5485C6.4409 59.5786 6.47278 59.6123 6.50094 59.6493Z"
          fill="#263238"
        />
        <path
          d="M3.5968 57.0857C3.6638 57.0655 3.73307 57.0545 3.80284 57.0529C3.99198 57.0377 4.18198 57.0377 4.37112 57.0529C4.65276 57.07 4.93236 57.1132 5.20658 57.1818C5.88126 57.3506 6.51441 57.6635 7.06542 58.1003C7.28989 58.2771 7.4983 58.4748 7.68805 58.6908C7.81667 58.8373 7.93401 58.994 8.03898 59.1594C8.08172 59.2168 8.11675 59.2798 8.14313 59.3469C8.12501 59.3609 7.95747 59.1126 7.62691 58.7564C7.43161 58.5539 7.22198 58.3667 6.99975 58.1964C6.7261 57.9902 6.43513 57.8098 6.13034 57.6575C5.82382 57.5095 5.50569 57.3888 5.17941 57.2966C4.9118 57.2253 4.63931 57.1751 4.36433 57.1467C3.8934 57.0834 3.5968 57.1092 3.5968 57.0857Z"
          fill="#263238"
        />
        <path
          d="M9.13492 56.3799C9.04012 56.3839 8.94518 56.3769 8.85191 56.3589C8.67078 56.3354 8.41041 56.3073 8.08438 56.2815C6.39113 56.1509 4.68948 56.3617 3.07391 56.9025C2.76373 57.0056 2.51468 57.097 2.34487 57.1649C2.25821 57.2046 2.16796 57.2352 2.07544 57.2563C2.15338 57.2003 2.23781 57.1546 2.32675 57.1204C2.49203 57.0384 2.73882 56.9353 3.04674 56.8205C4.66351 56.228 6.38736 56.0151 8.09343 56.1972C8.4172 56.2323 8.67984 56.2722 8.8587 56.312C8.95266 56.3255 9.04515 56.3482 9.13492 56.3799Z"
          fill="#263238"
        />
        <path
          d="M26.5913 43.8568C26.5665 43.8345 26.5451 43.8084 26.5279 43.7795L26.3604 43.5451L25.7446 42.6641C25.217 41.8909 24.4789 40.813 23.6118 39.5477L23.7069 39.5337C23.3763 40.5365 23.0118 41.6425 22.6291 42.8141L22.6042 42.8867L22.5408 42.8445L22.4141 42.7602C21.9207 42.4334 21.4722 42.0393 21.0805 41.5886C20.7185 41.169 20.3991 40.712 20.1273 40.2249C19.675 39.4005 19.3229 38.5214 19.079 37.6076C18.9153 36.9903 18.7831 36.3646 18.6828 35.733C18.6466 35.5222 18.6194 35.3511 18.5968 35.2222C18.5904 35.1785 18.5805 35.1354 18.5673 35.0934C18.5673 35.0652 18.5515 35.0512 18.5537 35.0488C18.556 35.0465 18.6466 35.2832 18.7281 35.726C18.8096 36.1689 18.9386 36.8062 19.1605 37.5771C19.4113 38.4825 19.7679 39.3527 20.2224 40.1686C20.4934 40.6467 20.8097 41.0956 21.1665 41.5089C21.5515 41.9488 21.9916 42.3334 22.4752 42.6524L22.5997 42.7367L22.5137 42.7696C22.9008 41.598 23.2676 40.499 23.6027 39.4891L23.6367 39.386L23.6978 39.4751C24.5491 40.7521 25.2827 41.8393 25.7966 42.6172C26.0434 42.9968 26.2495 43.2991 26.3876 43.5194L26.5393 43.7537C26.5601 43.7861 26.5775 43.8206 26.5913 43.8568Z"
          fill="#263238"
        />
        <path
          d="M51.5511 61.2516C51.2914 60.9724 51.0129 60.7125 50.7179 60.4737C49.993 59.9124 49.1238 59.5855 48.2197 59.5342C47.3157 59.4829 46.4172 59.7095 45.6373 60.1855C45.3208 60.3896 45.0182 60.6159 44.7316 60.8627C44.7914 60.7767 44.8606 60.6981 44.9377 60.6284C45.1414 60.4324 45.3637 60.2581 45.6011 60.1082C46.3885 59.6006 47.3081 59.3568 48.2344 59.41C49.1607 59.4633 50.0485 59.811 50.7768 60.4058C50.997 60.581 51.1989 60.7797 51.3791 60.9986C51.4458 61.0757 51.5035 61.1606 51.5511 61.2516Z"
          fill="#263238"
        />
        <path
          d="M51.2296 59.0888C50.9058 58.8481 50.5554 58.6483 50.1859 58.4936C49.2775 58.1598 48.2907 58.1295 47.3648 58.4069C46.8554 58.5548 46.3651 58.7657 45.9044 59.0349C45.5294 59.2579 45.1754 59.5168 44.8471 59.8081C44.5664 60.0565 44.3513 60.2767 44.2041 60.4244C44.1319 60.5097 44.0523 60.5882 43.9664 60.6587C44.0253 60.5641 44.0936 60.4761 44.1701 60.3962C44.3657 60.1679 44.5736 59.9512 44.7928 59.7472C45.1181 59.4431 45.4724 59.1738 45.8501 58.9435C46.3154 58.6621 46.8139 58.4439 47.3331 58.2944C48.2818 58.0076 49.2952 58.0513 50.2176 58.4186C50.493 58.5325 50.7527 58.6838 50.9896 58.8685C51.0734 58.9318 51.13 58.9927 51.173 59.0302C51.216 59.0677 51.2296 59.0864 51.2296 59.0888Z"
          fill="#263238"
        />
        <path
          d="M51.2482 57.2151C51.2482 57.2386 50.7953 57.1003 50.0754 56.9996C49.6499 56.9405 49.2209 56.9123 48.7916 56.9152C48.2657 56.9212 47.7411 56.9698 47.2226 57.0605C46.7051 57.1589 46.1957 57.2983 45.6988 57.4776C45.2939 57.6264 44.8997 57.8049 44.5192 58.0118C43.8694 58.3633 43.5027 58.6491 43.4891 58.628C43.566 58.5508 43.6502 58.4817 43.7404 58.4219C43.839 58.3415 43.9426 58.2679 44.0506 58.2016C44.1751 58.1196 44.3155 58.0235 44.4785 57.9345C44.859 57.7166 45.2549 57.5286 45.6626 57.3721C46.163 57.1832 46.6781 57.0389 47.2022 56.941C47.7259 56.8493 48.256 56.803 48.7871 56.8027C49.2216 56.805 49.6553 56.8418 50.0844 56.9129C50.2678 56.9363 50.4308 56.9808 50.5735 57.0113C50.6973 57.0372 50.8198 57.0701 50.9403 57.1097C51.0456 57.1355 51.1487 57.1708 51.2482 57.2151Z"
          fill="#263238"
        />
        <path
          d="M32.0435 34.2698C32.1373 34.4879 32.1955 34.7207 32.2155 34.9587C32.2831 35.6307 32.251 36.3094 32.1204 36.9714C32.041 37.4466 31.9329 37.9162 31.7967 38.3773C31.6182 38.914 31.3986 39.435 31.1401 39.9355C30.599 41.0134 29.9197 42.1873 29.1318 43.4503L29.0413 43.5979V43.4222C29.0413 42.5927 29.0413 41.7234 29.0413 40.8212L29.1296 40.8658L27.0036 42.6208L27.0217 42.5927C26.5174 44.3031 26.2074 46.0684 26.0979 47.8531C25.8896 50.7047 26.0572 53.4321 26.1862 55.9065C26.3153 58.3809 26.3719 60.6139 26.4466 62.4884C26.5213 64.363 26.5824 65.8766 26.6368 66.924C26.6685 67.4442 26.6911 67.8472 26.707 68.1284C26.707 68.262 26.7228 68.3627 26.7273 68.4424C26.7319 68.5221 26.7273 68.5502 26.7273 68.5502C26.7273 68.5502 26.7273 68.515 26.7115 68.4447C26.6956 68.3745 26.6934 68.2643 26.6798 68.1308C26.6549 67.8566 26.6232 67.4513 26.5892 66.9264C26.5213 65.8813 26.4421 64.3653 26.3628 62.4908C26.2836 60.6163 26.1862 58.3879 26.0775 55.9112C25.9689 53.4345 25.7719 50.7047 25.9802 47.8437C26.0947 46.0484 26.4131 44.2734 26.9289 42.5552V42.5388H26.9424L29.0616 40.7791L29.1499 40.7041V40.8212C29.1499 41.7234 29.1499 42.5927 29.1499 43.4222L29.0571 43.3917C30.6126 40.8962 31.8782 38.7265 32.0729 36.9714C32.2074 36.3157 32.2486 35.6431 32.1952 34.9751C32.1767 34.7995 32.1456 34.6257 32.1023 34.4549C32.057 34.3284 32.0435 34.2698 32.0435 34.2698Z"
          fill="#263238"
        />
        <path
          d="M27.9804 64.4944H27.9079C27.743 64.5053 27.5888 64.5822 27.4777 64.7088C27.3666 64.8354 27.3075 65.0017 27.3128 65.1726C27.3181 65.3434 27.3874 65.5055 27.5061 65.6244C27.6247 65.7434 27.7835 65.8099 27.9487 65.8099C28.1139 65.8099 28.2726 65.7434 28.3913 65.6244C28.5099 65.5055 28.5792 65.3434 28.5845 65.1726C28.5898 65.0017 28.5307 64.8354 28.4196 64.7088C28.3086 64.5822 28.1543 64.5053 27.9894 64.4944H27.9804Z"
          fill="white"
        />
        <path
          d="M27.9807 55.4641H27.9082C27.7439 55.4751 27.5902 55.5517 27.4796 55.6778C27.3689 55.804 27.31 55.9697 27.3153 56.1399C27.3206 56.3102 27.3897 56.4717 27.508 56.5902C27.6262 56.7087 27.7843 56.7749 27.949 56.7749C28.1136 56.7749 28.2717 56.7087 28.39 56.5902C28.5082 56.4717 28.5773 56.3102 28.5826 56.1399C28.5879 55.9697 28.529 55.804 28.4183 55.6778C28.3077 55.5517 28.154 55.4751 27.9897 55.4641H27.9807Z"
          fill="white"
        />
        <path
          d="M27.9107 47.3837C28.0797 47.3951 28.2463 47.3367 28.3736 47.2211C28.501 47.1055 28.5788 46.9423 28.5899 46.7674C28.601 46.5925 28.5445 46.4201 28.4329 46.2883C28.3212 46.1565 28.1635 46.0759 27.9945 46.0645H27.9107C27.7414 46.0645 27.579 46.1341 27.4592 46.258C27.3395 46.3819 27.2722 46.55 27.2722 46.7252C27.2722 46.9005 27.3395 47.0685 27.4592 47.1925C27.579 47.3164 27.7414 47.386 27.9107 47.386V47.3837Z"
          fill="white"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M18.6259 35.3428C18.3112 38.5224 19.4794 41.8802 21.9451 43.8063C22.1375 43.9586 22.3798 44.1085 22.6084 44.0218C22.7609 43.9454 22.8827 43.816 22.9526 43.6563C23.6431 42.4217 23.9021 40.9788 23.6862 39.5698C23.2967 40.6336 22.9616 41.6787 22.5677 42.7893C20.4236 41.4397 19.0787 38.5716 18.6259 35.3428Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.3"
          d="M26.9747 42.5743L28.786 41.8901L29.0848 45.0557C29.0848 45.0557 31.0365 41.3746 31.8448 39.6969C32.6531 38.0192 32.2365 35.0527 32.2365 35.0527C32.2365 35.0527 32.524 37.6302 30.5701 40.927L29.0939 43.4108V40.8099L26.9747 42.5743Z"
          fill="black"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M5.94378 58.663C5.11806 58.3 4.21094 58.1824 3.32421 58.3232C2.37927 58.4032 1.47528 58.7573 0.715958 59.3448C-0.017613 59.9564 -0.513452 60.9218 -0.416096 61.8918L-0.325533 61.9036C0.306153 60.1368 1.56047 58.8575 3.17931 58.6958C4.79814 58.5341 6.37623 59.2839 7.48111 60.5141C7.36111 59.5956 6.74075 59.0543 5.94378 58.663Z"
            fill="black"
          />
        </g>
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M51.6651 60.4612C51.0376 59.7946 50.2173 59.3578 49.3285 59.2169C48.5543 59.0829 47.7609 59.1305 47.0069 59.3564C46.2529 59.5823 45.5576 59.9807 44.9724 60.5221L45.3165 60.2315C46.192 59.6859 47.1971 59.4035 48.2191 59.4161C49.2447 59.4343 50.2283 59.8411 50.9836 60.5596C51.6628 61.225 52.0681 62.1271 52.4552 63.0058C52.5179 62.5474 52.4794 62.0803 52.3425 61.6396C52.2057 61.1988 51.974 60.7959 51.6651 60.4612Z"
            fill="black"
          />
        </g>
        <path
          d="M40.009 45.768C40.0274 45.6801 40.0547 45.5945 40.0905 45.5126C40.1607 45.3275 40.2467 45.1002 40.3463 44.8307C40.5727 44.2567 40.8671 43.4647 41.184 42.5813C41.501 41.6979 41.775 40.8942 41.9719 40.3131L42.1984 39.6219C42.2226 39.5356 42.2561 39.4523 42.298 39.3735C42.2909 39.4633 42.2719 39.5516 42.2414 39.636C42.1984 39.8047 42.1304 40.0437 42.0421 40.3389C41.8655 40.9294 41.6029 41.7448 41.2859 42.6235C40.9689 43.5022 40.6542 44.2989 40.4142 44.8636C40.2965 45.1471 40.1878 45.3744 40.1222 45.5314C40.094 45.6148 40.056 45.6942 40.009 45.768Z"
          fill="#263238"
        />
        <path
          d="M11.4661 45.9976C11.4344 45.9976 11.1876 44.8893 10.9159 43.5069C10.6442 42.1244 10.4495 41.0114 10.4744 40.9927C10.4993 40.9739 10.7506 42.101 11.0223 43.4834C11.294 44.8659 11.4978 45.9906 11.4661 45.9976Z"
          fill="#263238"
        />
        <path
          d="M7.13566 51.3211C6.72873 50.6382 6.35543 49.9343 6.01719 49.2123C5.42853 48.0407 4.9757 47.066 5.00287 47.0496C5.4098 47.7325 5.7831 48.4364 6.12134 49.1584C6.49486 49.8609 6.83341 50.5828 7.13566 51.3211Z"
          fill="white"
        />
        <path
          d="M14.5508 42.7708C14.7438 42.1701 14.9745 41.5831 15.2413 41.0134C15.4729 40.4281 15.7399 39.8583 16.0406 39.3076C15.8463 39.9079 15.6157 40.4948 15.35 41.065C15.119 41.6506 14.852 42.2204 14.5508 42.7708Z"
          fill="white"
        />
        <path
          d="M21.8249 48.3186C21.4492 47.9446 21.1011 47.542 20.7834 47.1142C20.4371 46.7117 20.12 46.2833 19.8347 45.8325C20.2107 46.2056 20.5581 46.6083 20.8739 47.0369C21.2213 47.439 21.5392 47.8675 21.8249 48.3186Z"
          fill="white"
        />
        <path
          d="M20.1522 55.9508C20.382 55.5224 20.6439 55.1135 20.9356 54.7276C21.1982 54.3198 21.4928 53.935 21.8163 53.5771C21.5872 54.0059 21.3253 54.4149 21.033 54.8003C20.7718 55.2092 20.4771 55.5941 20.1522 55.9508Z"
          fill="white"
        />
        <path
          d="M17.9012 66.7316C17.7955 66.5866 17.7066 66.4293 17.6363 66.2629C17.4868 65.963 17.2944 65.5436 17.0997 65.0726C16.905 64.6016 16.7487 64.1635 16.6469 63.8471C16.5792 63.6785 16.5305 63.5024 16.502 63.3223C16.5952 63.4777 16.6712 63.6436 16.7284 63.8167L17.2151 65.0234C17.4031 65.4709 17.5706 65.8763 17.7178 66.2254C17.7969 66.3866 17.8584 66.5563 17.9012 66.7316Z"
          fill="white"
        />
        <path
          d="M24.2873 62.1485C23.8243 62.1168 23.3647 62.0454 22.913 61.9353C22.4519 61.8646 21.9974 61.7533 21.5546 61.6025C22.4734 61.705 23.3823 61.8876 24.2715 62.1485H24.2873Z"
          fill="white"
        />
        <path
          d="M29.2688 52.0769C29.6745 51.533 30.1115 51.015 30.5775 50.5257C31.0156 50.0072 31.4828 49.5159 31.9767 49.0542C31.5694 49.5981 31.1316 50.1168 30.6658 50.6077C30.2277 51.1247 29.7613 51.6152 29.2688 52.0769Z"
          fill="white"
        />
        <path
          d="M34.248 65.5085C34.2231 65.5319 33.4918 64.6532 32.6133 63.552C31.7348 62.4507 31.0443 61.5368 31.0692 61.5158C31.0941 61.4947 31.8254 62.371 32.7039 63.4723C33.5824 64.5736 34.2684 65.4874 34.248 65.5085Z"
          fill="white"
        />
        <path
          d="M3.27485 59.7854C3.2907 59.8182 2.62279 60.1697 1.89374 60.7484C1.1647 61.3272 0.65754 61.8989 0.630371 61.8732C0.712673 61.7248 0.816526 61.5905 0.93829 61.4748C1.20945 61.1751 1.50486 60.9 1.82129 60.6524C2.13638 60.4035 2.47114 60.1824 2.82203 59.9916C2.96248 59.9004 3.11503 59.831 3.27485 59.7854Z"
          fill="white"
        />
        <path
          d="M5.13934 54.6016C4.99547 54.9331 4.81706 55.2474 4.60727 55.5388C4.42854 55.8527 4.21668 56.145 3.97559 56.4105C4.11984 56.0792 4.29823 55.7649 4.50765 55.4732C4.68591 55.159 4.89781 54.8666 5.13934 54.6016Z"
          fill="white"
        />
        <path
          d="M9.34454 59.6909C8.98203 59.0943 8.82707 58.3876 8.9053 57.6875C8.93927 57.6875 8.95059 58.1561 9.07511 58.7021C9.19964 59.248 9.37624 59.6745 9.34454 59.6909Z"
          fill="white"
        />
        <path
          d="M9.96344 43.5419C9.68194 43.6435 9.38898 43.7073 9.09176 43.7317C8.79941 43.7952 8.50016 43.8181 8.20197 43.7996C8.48429 43.6981 8.77797 43.6343 9.07591 43.6098C9.36768 43.5476 9.666 43.5248 9.96344 43.5419Z"
          fill="white"
        />
        <path
          d="M13.1206 37.7483C12.633 38.1217 12.1002 38.4274 11.5358 38.6574C11.7675 38.4541 12.024 38.2831 12.2988 38.149C12.5552 37.9799 12.8314 37.8453 13.1206 37.7483Z"
          fill="white"
        />
        <path
          d="M22.0001 40.1956C21.6802 39.8589 21.3878 39.4952 21.1261 39.1084C20.8357 38.7449 20.5751 38.357 20.3473 37.9485C20.9599 38.6467 21.5129 39.3986 22.0001 40.1956Z"
          fill="white"
        />
        <path
          d="M33.2671 46.021C32.6431 45.3845 32.1736 44.604 31.8973 43.7434C31.9313 43.7294 32.1736 44.2753 32.5539 44.901C32.9343 45.5266 33.2943 45.9975 33.2671 46.021Z"
          fill="white"
        />
        <path
          d="M37.5678 47.4878C37.2946 48.3116 36.9458 49.1064 36.5263 49.8614C36.647 49.4438 36.8039 49.0384 36.995 48.65C37.1499 48.2447 37.3417 47.8555 37.5678 47.4878Z"
          fill="white"
        />
        <path
          d="M38.1709 41.4121C37.7593 40.9985 37.3765 40.5553 37.0253 40.0858C36.6437 39.6427 36.2919 39.1729 35.9725 38.6799C36.7647 39.5376 37.4993 40.4505 38.1709 41.4121Z"
          fill="white"
        />
        <path
          d="M31.6016 36.4776C31.5299 36.1042 31.4958 35.7242 31.4998 35.3435C31.4656 34.9636 31.4709 34.5811 31.5156 34.2024C31.5874 34.5758 31.6215 34.9558 31.6175 35.3365C31.653 35.7163 31.6477 36.099 31.6016 36.4776Z"
          fill="white"
        />
        <path
          d="M36.9672 36.1704C36.5083 36.2949 36.0403 36.3803 35.568 36.4258C35.1014 36.5107 34.6288 36.5553 34.1552 36.5594C34.614 36.4349 35.082 36.3495 35.5544 36.304C36.0209 36.219 36.4935 36.1744 36.9672 36.1704Z"
          fill="white"
        />
        <path
          d="M45.3936 42.5835C45.0792 42.0495 44.7978 41.4954 44.5513 40.9245C44.2714 40.3705 44.0263 39.7984 43.8177 39.2117C44.1334 39.7453 44.4155 40.2994 44.6623 40.8706C44.9413 41.4248 45.1855 41.9969 45.3936 42.5835Z"
          fill="white"
        />
        <path
          d="M43.9827 48.1245C44.0775 47.2296 44.2487 46.3451 44.4944 45.4814C44.4671 45.931 44.4011 46.3772 44.2974 46.8147C44.2314 47.2603 44.126 47.6988 43.9827 48.1245Z"
          fill="white"
        />
        <path
          d="M47.8865 52.4921C47.6225 51.9082 47.3956 51.3071 47.2073 50.6926C46.9829 50.0917 46.7961 49.4765 46.6481 48.8508C46.9106 49.4362 47.1374 50.038 47.3273 50.6527C47.5508 51.2531 47.7376 51.8674 47.8865 52.4921Z"
          fill="white"
        />
        <path
          d="M47.3403 55.0489C46.9361 54.9859 46.5477 54.8418 46.1969 54.6248C45.8286 54.4417 45.4997 54.1834 45.2302 53.8656C45.2528 53.8375 45.6694 54.1936 46.2535 54.517C46.8377 54.8403 47.3516 55.0137 47.3403 55.0489Z"
          fill="white"
        />
        <path
          d="M22.7504 69C22.6538 68.662 22.5946 68.3138 22.5738 67.962C22.5164 67.6146 22.4967 67.2618 22.5149 66.9099C22.6183 67.2499 22.6814 67.6016 22.7028 67.9573C22.7571 68.3019 22.773 68.6517 22.7504 69Z"
          fill="white"
        />
        <path
          d="M38.1276 68.5972C38.138 68.2542 38.1874 67.9136 38.2747 67.5826C38.3257 67.2435 38.4146 66.9116 38.5396 66.5938C38.5292 66.9369 38.4791 67.2776 38.3902 67.6083C38.3406 67.9475 38.2524 68.2794 38.1276 68.5972Z"
          fill="white"
        />
        <path
          d="M5.65639 39.7656L5.03185 35.6441L4.66413 30.3478L4.96563 25.867C4.96563 25.867 5.13341 25.4102 5.72625 25.6667C6.31909 25.9233 6.13136 28.3735 6.13136 28.3735L6.27191 30.1757C6.27191 30.1757 6.76489 31.5216 6.85536 31.3929C6.94582 31.2642 6.99638 28.7973 6.99638 28.7973L7.36363 23.4656C7.41693 23.2983 7.53126 23.1572 7.68389 23.0704C7.83651 22.9835 8.01623 22.9573 8.18729 22.997C8.5573 23.0671 8.85279 23.5544 8.90082 24.3325C8.94885 25.1107 8.7747 26.4531 8.79359 26.8972C8.81249 27.3413 8.83174 29.9099 8.83174 29.9099L9.87883 23.8663C9.87883 23.8663 9.97354 21.9298 10.7982 22.1144C11.6229 22.299 11.261 23.716 11.3079 24.3022C11.3548 24.8884 10.6857 29.0013 10.9151 30.096C11.1445 31.1907 11.2542 30.6166 11.2542 30.6166L11.6746 27.2511C11.8595 25.7677 12.3033 24.8673 12.9146 24.6864C13.526 24.5056 13.0942 27.943 13.0942 27.943L12.6085 32.7332L13.0519 34.1855L13.9285 32.723C13.9285 32.723 13.746 30.9715 14.7491 30.1074C15.7523 29.2432 16.1379 30.0401 16.1379 30.0401L14.9125 37.0453C14.5668 38.2124 11.7071 40.7444 11.7071 40.7444L11.9771 47.6011L4.87536 48.0512L5.65639 39.7656Z"
          fill="#FFBE9D"
        />
        <path
          d="M7.79056 37.0233C7.83065 37.0524 8.12043 36.6902 8.62343 36.1322C9.12644 35.5742 9.92078 34.8586 11.0313 34.7741C11.4902 34.726 11.9519 34.8332 12.3426 35.0786L12.4702 35.1642L12.5543 35.019C12.9181 34.3807 13.3438 33.8099 13.5822 33.4033C13.8207 32.9966 13.9399 32.7382 13.9177 32.7203C13.738 32.8982 13.5766 33.0938 13.4361 33.304C13.0275 33.8151 12.6459 34.3471 12.2929 34.898L12.5068 34.8481C12.0665 34.5538 11.5374 34.4224 11.0107 34.4765C10.443 34.5262 9.89942 34.729 9.43798 35.0634C9.0721 35.3298 8.74367 35.6443 8.46151 35.9982C7.98402 36.5887 7.76245 37.0017 7.79056 37.0233Z"
          fill="#EB996E"
        />
        <path
          d="M12.7733 62.8872L11.664 41.5822L5.56653 40.4579L3.35133 64.7415L12.7733 62.8872Z"
          fill="#FFBE9D"
        />
        <path
          d="M12.3031 63.2019C12.3451 62.9104 12.3622 62.6157 12.3542 62.3212C12.37 61.7512 12.3808 60.9285 12.3802 59.9138C12.3856 57.8802 12.3412 55.0715 12.2469 51.972C12.1526 48.8724 12.0343 46.0655 11.9356 44.0357C11.8787 43.0546 11.8336 42.245 11.798 41.6305C11.7929 41.3356 11.7638 41.0415 11.7111 40.7513C11.6807 41.0448 11.6741 41.3403 11.6914 41.6349C11.7064 42.249 11.7236 43.06 11.7399 44.0429C11.8028 46.1169 11.8852 48.9014 11.9798 51.9804L12.1867 59.8974C12.2127 60.878 12.2307 61.6811 12.2352 62.3054C12.2349 62.6055 12.2576 62.9053 12.3031 63.2019Z"
          fill="#EB996E"
        />
      </g>
      <defs>
        <clipPath id="clip0_5504_24753">
          <path
            d="M0.633972 0.801514H50.8402V41.9786C50.8402 55.8426 39.6011 67.0817 25.7371 67.0817C11.873 67.0817 0.633972 55.8426 0.633972 41.9786V0.801514Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuickSupportIconSVG;
