import React from "react";

export const Stepper = ({
  isActive,
  completed,
  number = 0,
  text = "Basic Details",
  onClick = () => {},
}) => (
  <button
    type="button"
    className={`stepper-item ${isActive && "active"} ${
      completed && "completed"
    }`}
    onClick={onClick}
  >
    <div className="step-counter">{number}</div>
    <div className="step-name">{text}</div>
  </button>
);
