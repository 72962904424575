const CornerSharpIconSVG = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    className="m-auto"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H45V45H0V0Z" fill="#C4C4C4" />
  </svg>
);

export default CornerSharpIconSVG;
