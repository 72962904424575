import React, { useState } from "react";
import Input from "components/input/input";
import toast from "react-hot-toast";
import Button from "components/button/button";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import LoadingIndicator from "assets/svg/loading-indicator";
import "../../assets/css/pages/forgot-password.css";

const ForgotPassword = () => {
  const { http } = global.services;
  const [state, setState] = useState({
    email: "",
    loading: false,
    validationErrors: null,
    success: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async (e) => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      await http.post("auth/forgot-password", state);
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          success: true,
          email: "",
          loading: false,
        }));
      }, 250);
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data,
          loading: false,
        }));

      toast.error("It appears that something went wrong");
    }
  };

  return (
    <div className="page-wrapper">
      <h2 className="column-title">Request Password Reset</h2>
      {state.success ? (
        <p className="column-subtitle text-green">
          Please check your inbox for the password reset link.
        </p>
      ) : (
        <p className="column-subtitle">
          Enter your email on the form below and we'll send you a password reset
          link.
        </p>
      )}

      <form
        className="column-container"
        id="forgot-password-page-component"
        onSubmit={handleSubmit}
      >
        <Input
          showInputValidation={true}
          validationPassed={/^\w+([.]?\w+)*@\w+([.]?\w+)*(\.\w{2,3})+$/.test(
            state.email
          )}
          inputContainerClass="input-container"
          value={state.email}
          defaultValue={state.email}
          autoFocus={true}
          name="email"
          label="Email Address"
          type="text"
          onChange={handleInputChange}
          errorMessage={state.validationErrors?.email}
        />

        <Button
          buttonName="SEND EMAIL"
          buttonClass="relative py-3 bg-secondary"
          buttonWidth="100%"
          buttonType="secondary"
          onClick={handleSubmit}
          disabled={state.loading}
          type="submit"
          icon={
            <div className="absolute right-3 bottom-0 top-0 flex items-center">
              {state.loading ? (
                <LoadingIndicator className="text-white w-5 h-5" />
              ) : (
                <FaChevronRight className="text-white text-base 2md:text-lg" />
              )}
            </div>
          }
        />

        <a
          href="https://support.conversioncow.com/hc/en-us/requests/new"
          target="_blank"
          rel="noreferrer"
          className="text-secondary text-xs font-medium underline w-full my-5 block text-center"
        >
          Forgot your Email Address?
        </a>

        <div>
          <p className="no-account-text">
            Don't have an account?{" "}
            <Link to={"/sign-up"} className="no-account-link">
              Sign Up
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
