import { Profile1 } from "constants/images";
import "./styles.css";

const ProfileShortDetails = ({
  profileImage = Profile1,
  isColumn = false,
  gap = 0,
  detailsTextColor = "black",
  containerClass = "",
  name = "",
  subtitle = "",
}) => {
  return (
    <div
      className={`
                flex profile-short-details-wrapper
                flex-${isColumn ? "col" : "row"}
                space-${isColumn ? "y" : "x"}-${gap}
                ${containerClass}
            `}
    >
      {profileImage && (
        <div className="profile-image-container">
          <img
            src={profileImage}
            alt="short-details-1"
            className="profile-image"
          />
        </div>
      )}
      <div className="profile-details-container">
        {name && <p className={`text-${detailsTextColor} name`}>{name}</p>}
        {subtitle && (
          <p className={`text-${detailsTextColor} subtitle`}>{subtitle}</p>
        )}
      </div>
    </div>
  );
};

export default ProfileShortDetails;
