import styled from "styled-components";
import { device } from "../../screens";

const InvisibleDiv = styled.div`
  position: absolute;
  z-index: -1;
  background-color: transparent;
  ${({ type }) => (type === "upper" ? "top: -100vh;" : "bottom: -100vh;")}
  left: 0px;
  height: 100vh;
  width: 100%;
  cursor: pointer;
  display: none;

  @media ${device.s2md} {
    display: block;
  }
`;

export default InvisibleDiv;
