import _ from "lodash";
/*
import { ReactComponent as BoldSecurity } from "./bold-line/001-security.svg";
import { ReactComponent as BoldWallet } from "./bold-line/002-wallet.svg";
import { ReactComponent as BoldDiscount } from "./bold-line/003-discount.svg";
import { ReactComponent as BoldNewspaper } from "./bold-line/004-newspaper.svg";
import { ReactComponent as BoldClock } from "./bold-line/005-clock.svg";
import { ReactComponent as BoldAlarm } from "./bold-line/061-alarm.svg";
import { ReactComponent as BoldCoin } from "./bold-line/062-coin.svg";
import { ReactComponent as BoldGift } from "./bold-line/063-gift.svg";
import { ReactComponent as BoldLink } from "./bold-line/064-link-1.svg";
import { ReactComponent as BoldCalculator } from "./bold-line/065-calculator-1.svg";
import { ReactComponent as BoldSitemap } from "./bold-line/066-sitemap-1.svg";
import { ReactComponent as BoldTag } from "./bold-line/067-tag-1.svg";
import { ReactComponent as BoldSound } from "./bold-line/068-sound.svg";
import { ReactComponent as BoldBriefcase } from "./bold-line/069-briefcase.svg";
import { ReactComponent as BoldEmail } from "./bold-line/070-email-1.svg";
import { ReactComponent as BoldToDoList } from "./bold-line/071-to-do-list-1.svg";
import { ReactComponent as BoldPeople } from "./bold-line/072-people-1.svg";
import { ReactComponent as BoldKing } from "./bold-line/073-king.svg";
import { ReactComponent as BoldFolder } from "./bold-line/074-folder-3.svg";
import { ReactComponent as BoldNetwork } from "./bold-line/075-network.svg";
import { ReactComponent as BoldSend } from "./bold-line/076-send.svg";
import { ReactComponent as BoldDice } from "./bold-line/077-dice.svg";
import { ReactComponent as BoldSettings } from "./bold-line/078-settings-1.svg";
import { ReactComponent as BoldLocation } from "./bold-line/079-location-1.svg";
import { ReactComponent as BoldSpeechBubble } from "./bold-line/080-speech-bubble.svg";
import { ReactComponent as BoldView } from "./bold-line/081-view-1.svg";
import { ReactComponent as BoldPencil } from "./bold-line/082-pencil.svg";
import { ReactComponent as BoldPieChart } from "./bold-line/083-pie-chart-1.svg";
import { ReactComponent as BoldPadlock } from "./bold-line/084-padlock-1.svg";
import { ReactComponent as BoldConnection } from "./bold-line/085-connection.svg";
import { ReactComponent as BoldMail } from "./bold-line/086-mail.svg";
import { ReactComponent as BoldSetting } from "./bold-line/087-setting.svg";
import { ReactComponent as BoldQuality } from "./bold-line/088-quality.svg";
import { ReactComponent as BoldCalendar } from "./bold-line/089-calendar-1.svg";
import { ReactComponent as BoldBrowser } from "./bold-line/090-browser.svg";
import { ReactComponent as BoldCloudSettings } from "./bold-line/091-cloud-settings.svg";
import { ReactComponent as BoldPantheon } from "./bold-line/092-pantheon-1.svg";
import { ReactComponent as BoldAd } from "./bold-line/093-ad.svg";
import { ReactComponent as BoldDeliveryBox } from "./bold-line/094-delivery-box.svg";
import { ReactComponent as BoldStatistic } from "./bold-line/095-statistic.svg";
import { ReactComponent as BoldSettingsSecondary } from "./bold-line/096-settings-2.svg";
import { ReactComponent as BoldTv } from "./bold-line/097-tv.svg";
import { ReactComponent as BoldLightBulb } from "./bold-line/098-light-bulb.svg";
import { ReactComponent as BoldLock } from "./bold-line/099-lock.svg";
import { ReactComponent as BoldCoding } from "./bold-line/100-coding-1.svg";
import { ReactComponent as BoldSettingThird } from "./bold-line/101-setting-1.svg";
import { ReactComponent as BoldCloudComputing } from "./bold-line/102-cloud-computing.svg";
import { ReactComponent as BoldChart } from "./bold-line/103-chart.svg";
import { ReactComponent as BoldVideo } from "./bold-line/104-video.svg";
import { ReactComponent as BoldDiscountSecondary } from "./bold-line/105-discount-2.svg";
import { ReactComponent as BoldChartSecondary } from "./bold-line/106-chart-1.svg";
import { ReactComponent as BoldEmailSecondary } from "./bold-line/107-email-2.svg";
import { ReactComponent as BoldInternet } from "./bold-line/108-internet-1.svg";
import { ReactComponent as BoldOnlineShop } from "./bold-line/109-online-shop.svg";

import { ReactComponent as SolidShield } from "./solid-fill/006-shield.svg";
import { ReactComponent as SolidTag } from "./solid-fill/007-tag.svg";
import { ReactComponent as SolidNews } from "./solid-fill/008-news.svg";
import { ReactComponent as SolidWallet } from "./solid-fill/014-wallet-1.svg";
import { ReactComponent as SolidDollarSymbol } from "./solid-fill/015-dollar-symbol.svg";
import { ReactComponent as SolidGiftBoxWithABow } from "./solid-fill/016-gift-box-with-a-bow.svg";
import { ReactComponent as SolidLink } from "./solid-fill/017-link.svg";
import { ReactComponent as SolidCalculator } from "./solid-fill/018-calculator.svg";
import { ReactComponent as SolidWebDevelopment } from "./solid-fill/019-web-development.svg";
import { ReactComponent as SolidAdvertisement } from "./solid-fill/020-advertisement.svg";
import { ReactComponent as SolidVideoMarketing } from "./solid-fill/021-video-marketing.svg";
import { ReactComponent as SolidInternet } from "./solid-fill/022-internet.svg";
import { ReactComponent as SolidWebSecurity } from "./solid-fill/023-web-security.svg";
import { ReactComponent as SolidSuitcase } from "./solid-fill/024-suitcase.svg";
import { ReactComponent as SolidBlackBackClosedEnvelopeShape } from "./solid-fill/025-black-back-closed-envelope-shape.svg";
import { ReactComponent as SolidToDoList } from "./solid-fill/026-to-do-list.svg";
import { ReactComponent as SolidPeople } from "./solid-fill/027-people.svg";
import { ReactComponent as SolidDesign } from "./solid-fill/028-design.svg";
import { ReactComponent as SolidTimer } from "./solid-fill/029-timer.svg";
import { ReactComponent as SolidView } from "./solid-fill/030-view.svg";
import { ReactComponent as SolidConversation } from "./solid-fill/031-conversation.svg";
import { ReactComponent as SolidCreativity } from "./solid-fill/032-creativity.svg";
import { ReactComponent as SolidDiceRoll } from "./solid-fill/033-dice-roll.svg";
import { ReactComponent as SolidPaperPlane } from "./solid-fill/034-paper-plane.svg";
import { ReactComponent as SolidFolder } from "./solid-fill/035-folder.svg";
import { ReactComponent as SolidRoyalCrown } from "./solid-fill/036-royal-crown.svg";
import { ReactComponent as SolidSettingSpanner } from "./solid-fill/037-setting-spanner.svg";
import { ReactComponent as SolidToolsAndUtensils } from "./solid-fill/038-tools-and-utensils.svg";
import { ReactComponent as SolidMedal } from "./solid-fill/039-medal.svg";
import { ReactComponent as SolidCalendar } from "./solid-fill/040-calendar.svg";
import { ReactComponent as SolidFolderSecondary } from "./solid-fill/041-folder-1.svg";
import { ReactComponent as SolidPackage } from "./solid-fill/042-package.svg";
import { ReactComponent as SolidPantheon } from "./solid-fill/043-pantheon.svg";
import { ReactComponent as SolidSeo } from "./solid-fill/044-seo.svg";
import { ReactComponent as SolidPieChart } from "./solid-fill/045-pie-chart.svg";
import { ReactComponent as SolidBarChart } from "./solid-fill/046-bar-chart.svg";
import { ReactComponent as SolidShare } from "./solid-fill/047-share.svg";
import { ReactComponent as SolidSettings } from "./solid-fill/048-settings.svg";
import { ReactComponent as SolidAds } from "./solid-fill/049-ads.svg";
import { ReactComponent as SolidPadlock } from "./solid-fill/050-padlock.svg";
import { ReactComponent as SolidEmail } from "./solid-fill/051-email.svg";
import { ReactComponent as SolidLocation } from "./solid-fill/052-location.svg";
import { ReactComponent as SolidCog } from "./solid-fill/053-cog.svg";
import { ReactComponent as SolidSitemap } from "./solid-fill/054-sitemap.svg";
import { ReactComponent as SolidCoding } from "./solid-fill/055-coding.svg";
import { ReactComponent as SolidFolderThird } from "./solid-fill/056-folder-2.svg";
import { ReactComponent as SolidCloudNetwork } from "./solid-fill/057-cloud-network.svg";
import { ReactComponent as SolidGraph } from "./solid-fill/058-graph.svg";
import { ReactComponent as SolidLaptop } from "./solid-fill/059-laptop.svg";
import { ReactComponent as SolidLaptopSecondary } from "./solid-fill/060-laptop-1.svg";

import { ReactComponent as ThinShield } from "./thin-line/009-shield-1.svg";
import { ReactComponent as ThinClock } from "./thin-line/010-clock-1.svg";
import { ReactComponent as ThinAlarmClock } from "./thin-line/110-alarm-clock.svg";
import { ReactComponent as ThinMoneyBack } from "./thin-line/111-money-back.svg";
import { ReactComponent as ThinGiftBox } from "./thin-line/112-gift-box.svg";
import { ReactComponent as ThinLink } from "./thin-line/113-link-2.svg";
import { ReactComponent as ThinCalculator } from "./thin-line/114-calculator-2.svg";
import { ReactComponent as ThinSitemap } from "./thin-line/115-sitemap-2.svg";
import { ReactComponent as ThinTag } from "./thin-line/116-tag-2.svg";
import { ReactComponent as ThinSpeaker } from "./thin-line/117-speaker.svg";
import { ReactComponent as ThinSafe } from "./thin-line/118-safe.svg";
import { ReactComponent as ThinSuitcase } from "./thin-line/119-suitcase-1.svg";
import { ReactComponent as ThinEnvelope } from "./thin-line/120-envelope.svg";
import { ReactComponent as ThinFiles } from "./thin-line/121-files.svg";
import { ReactComponent as ThinInternet } from "./thin-line/122-internet-2.svg";
import { ReactComponent as ThinSetting } from "./thin-line/123-setting-2.svg";
import { ReactComponent as ThinConnection } from "./thin-line/124-connection-1.svg";
import { ReactComponent as ThinCrown } from "./thin-line/125-crown.svg";
import { ReactComponent as ThinServer } from "./thin-line/126-server.svg";
import { ReactComponent as ThinAirplane } from "./thin-line/127-airplane.svg";
import { ReactComponent as ThinDice } from "./thin-line/128-dice-1.svg";
import { ReactComponent as ThinVideo } from "./thin-line/129-video-1.svg";
import { ReactComponent as ThinWebsite } from "./thin-line/130-website.svg";
import { ReactComponent as ThinCog } from "./thin-line/131-cog-1.svg";
import { ReactComponent as ThinChart } from "./thin-line/132-chart-2.svg";
import { ReactComponent as ThinLocation } from "./thin-line/133-location-2.svg";
import { ReactComponent as ThinChat } from "./thin-line/134-chat.svg";
import { ReactComponent as ThinEye } from "./thin-line/135-eye.svg";
import { ReactComponent as ThinPencil } from "./thin-line/136-pencil-1.svg";
import { ReactComponent as ThinPieChart } from "./thin-line/137-pie-chart-2.svg";
import { ReactComponent as ThinLightBulb } from "./thin-line/138-light-bulb-1.svg";
import { ReactComponent as ThinLock } from "./thin-line/139-lock-1.svg";
import { ReactComponent as ThinNeural } from "./thin-line/140-neural.svg";
import { ReactComponent as ThinWebProgramming } from "./thin-line/141-web-programming.svg";
import { ReactComponent as ThinSettingSecondary } from "./thin-line/142-setting-3.svg";
import { ReactComponent as ThinBadge } from "./thin-line/143-badge.svg";
import { ReactComponent as ThinGrowth } from "./thin-line/144-growth.svg";
import { ReactComponent as ThinCalendar } from "./thin-line/145-calendar-2.svg";
import { ReactComponent as ThinCogwheel } from "./thin-line/146-cogwheel.svg";
import { ReactComponent as ThinPantheon } from "./thin-line/147-pantheon-2.svg";
import { ReactComponent as ThinTarget } from "./thin-line/148-target.svg";
import { ReactComponent as ThinFolder } from "./thin-line/149-folder-4.svg";
import { ReactComponent as ThinPackageBox } from "./thin-line/150-package-box.svg";
import { ReactComponent as ThinDiamond } from "./thin-line/151-diamond.svg";
import { ReactComponent as ThinLineGraph } from "./thin-line/152-line-graph.svg";
import { ReactComponent as ThinEmailMarketing } from "./thin-line/153-email-marketing.svg";
import { ReactComponent as ThinBrowser } from "./thin-line/154-browser-1.svg";
import { ReactComponent as ThinIdeas } from "./thin-line/155-ideas.svg";
import { ReactComponent as ThinGear } from "./thin-line/156-gear.svg";
import { ReactComponent as ThinVideoMarketing } from "./thin-line/157-video-marketing-1.svg";
import { ReactComponent as ThinSeo } from "./thin-line/158-seo-1.svg";
import { ReactComponent as ThinNews } from "./thin-line/news.svg";
import { ReactComponent as ThinPercentage } from "./thin-line/percentage.svg";
import { ReactComponent as ThinWallet } from "./thin-line/wallet.svg";
*/
// material
import {
  AccessAlarmOutlined,
  AccessTimeOutlined,
  AccessibleOutlined,
  AccountBalanceOutlined,
  AccountBoxOutlined,
  AccountCircleOutlined,
  AddAPhotoOutlined,
  AddHomeOutlined,
  AddHomeWorkOutlined,
  AddIcCallOutlined,
  AddLocationOutlined,
  AgricultureOutlined,
  AirOutlined,
  AirlineSeatReclineExtraOutlined,
  AirplanemodeActiveOutlined,
  AirportShuttleOutlined,
  AllInclusiveOutlined,
  AodOutlined,
  ApartmentOutlined,
  ArrowCircleDownOutlined,
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
  ArrowCircleUpOutlined,
  AssignmentIndOutlined,
  AssignmentLateOutlined,
  AssignmentOutlined,
  AssistWalkerOutlined,
  AssistantPhotoOutlined,
  AttachFileOutlined,
  AttachMoneyOutlined,
  AutoAwesomeOutlined,
  AutoDeleteOutlined,
  AutorenewOutlined,
  BabyChangingStationOutlined,
  BakeryDiningOutlined,
  BalanceOutlined,
  BathtubOutlined,
  Battery3BarOutlined,
  BeachAccessOutlined,
  BedOutlined,
  BlenderOutlined,
  BlindOutlined,
  BluetoothOutlined,
  BoltOutlined,
  BookmarksOutlined,
  Brightness2Outlined,
  Brightness5Outlined,
  BrunchDiningOutlined,
  BugReportOutlined,
  BuildOutlined,
  BusinessCenterOutlined,
  CalendarMonthOutlined,
  CallOutlined,
  CameraAltOutlined,
  CampaignOutlined,
  CardGiftcardOutlined,
  CelebrationOutlined,
  ChairOutlined,
  CheckroomOutlined,
  ChildCareOutlined,
  ChildFriendlyOutlined,
  ChurchOutlined,
  CleanHandsOutlined,
  CloudOutlined,
  CloudUploadOutlined,
  CoPresentOutlined,
  ColorLensOutlined,
  CommentOutlined,
  CommuteOutlined,
  ConnectWithoutContactOutlined,
  ConstructionOutlined,
  ContactPhoneOutlined,
  ContactSupportOutlined,
  ContactlessOutlined,
  ContentCutOutlined,
  CoronavirusOutlined,
  CreditScoreOutlined,
  CurrencyBitcoinOutlined,
  CurrencyExchangeOutlined,
  DangerousOutlined,
  DeckOutlined,
  DesignServicesOutlined,
  DesktopWindowsOutlined,
  DiamondOutlined,
  DirectionsBikeOutlined,
  DirectionsBusFilledOutlined,
  DirectionsCarOutlined,
  DirectionsRunOutlined,
  DirectionsTransitFilledOutlined,
  Diversity1Outlined,
  Diversity3Outlined,
  DoneOutlined,
  DownhillSkiingOutlined,
  DownloadOutlined,
  DraftsOutlined,
  EditOutlined,
  EmailOutlined,
  EmojiEventsOutlined,
  EmojiFlagsOutlined,
  EmojiFoodBeverageOutlined,
  EmojiNatureOutlined,
  EmojiObjectsOutlined,
  EngineeringOutlined,
  EscalatorWarningOutlined,
  ExploreOutlined,
  ExtensionOutlined,
  Face4Outlined,
  FaceOutlined,
  FacebookOutlined,
  FamilyRestroomOutlined,
  FastForwardOutlined,
  FastfoodOutlined,
  FavoriteBorderOutlined,
  FestivalOutlined,
  FilterDramaOutlined,
  FilterHdrOutlined,
  FingerprintOutlined,
  FireplaceOutlined,
  FitnessCenterOutlined,
  FlatwareOutlined,
  FlightOutlined,
  FmdGoodOutlined,
  ForestOutlined,
  FormatPaintOutlined,
  FormatQuoteOutlined,
  ForumOutlined,
  GolfCourseOutlined,
  GpsFixedOutlined,
  GradeOutlined,
  HandshakeOutlined,
  HeadphonesOutlined,
  HealthAndSafetyOutlined,
  HearingOutlined,
  HikingOutlined,
  HistoryOutlined,
  HomeOutlined,
  HotelOutlined,
  HowToRegOutlined,
  HttpsOutlined,
  ImageOutlined,
  ImagesearchRollerOutlined,
  InsertEmoticonOutlined,
  KayakingOutlined,
  KeyboardVoiceOutlined,
  LanguageOutlined,
  LightModeOutlined,
  LightbulbOutlined,
  LiquorOutlined,
  LocalDiningOutlined,
  LocalFireDepartmentOutlined,
  LocalFloristOutlined,
  LocalGasStationOutlined,
  LocalPostOfficeOutlined,
  LocalPrintshopOutlined,
  LocalShippingOutlined,
  LuggageOutlined,
  LunchDiningOutlined,
  MapOutlined,
  MapsHomeWorkOutlined,
  MarkAsUnreadOutlined,
  ModeOutlined,
  MonetizationOnOutlined,
  MouseOutlined,
  MovieOutlined,
  NewReleasesOutlined,
  NightlightOutlined,
  NotificationsActiveOutlined,
  PaletteOutlined,
  PanToolOutlined,
  PanoramaOutlined,
  PaymentOutlined,
  PermPhoneMsgOutlined,
  PetsOutlined,
  PhoneInTalkOutlined,
  PianoOutlined,
  PieChartOutlined,
  PlayCircleFilledWhiteOutlined,
  PlumbingOutlined,
  PointOfSaleOutlined,
  PoolOutlined,
  PsychologyAltOutlined,
  PublicOutlined,
  PushPinOutlined,
  QrCodeOutlined,
  QrCodeScannerOutlined,
  QueryStatsOutlined,
  QuestionAnswerOutlined,
  QuestionMarkOutlined,
  RamenDiningOutlined,
  ReceiptLongOutlined,
  RecordVoiceOverOutlined,
  RememberMeOutlined,
  RemoveRedEyeOutlined,
  RocketLaunchOutlined,
  RocketOutlined,
  RouterOutlined,
  RssFeedOutlined,
  SailingOutlined,
  SanitizerOutlined,
  SavingsOutlined,
  SchoolOutlined,
  ScienceOutlined,
  ScreenSearchDesktopOutlined,
  ScreenshotOutlined,
  ScubaDivingOutlined,
  SearchOutlined,
  SettingsOutlined,
  ShoppingCartOutlined,
  ShowerOutlined,
  SlowMotionVideoOutlined,
  SmartDisplayOutlined,
  SmokeFreeOutlined,
  SmokingRoomsOutlined,
  SportsBarOutlined,
  SportsBaseballOutlined,
  SportsBasketballOutlined,
  SportsCricketOutlined,
  SportsEsportsOutlined,
  SportsFootballOutlined,
  SportsGolfOutlined,
  SportsGymnasticsOutlined,
  SportsHandballOutlined,
  SportsHockeyOutlined,
  SportsKabaddiOutlined,
  SportsMartialArtsOutlined,
  SportsMotorsportsOutlined,
  SportsRugbyOutlined,
  SportsScoreOutlined,
  SportsSoccerOutlined,
  SportsTennisOutlined,
  SportsVolleyballOutlined,
  StarOutlineOutlined,
  SubwayOutlined,
  SupportAgentOutlined,
  SwipeDownOutlined,
  SwipeLeftOutlined,
  SwipeOutlined,
  SwipeRightOutlined,
  SwipeVerticalOutlined,
  TabletAndroidOutlined,
  TheaterComedyOutlined,
  ThreePOutlined,
  ThumbsUpDownOutlined,
  ThunderstormOutlined,
  TouchAppOutlined,
  TrafficOutlined,
  TravelExploreOutlined,
  TrendingUpOutlined,
  TroubleshootOutlined,
  TuneOutlined,
  TwoWheelerOutlined,
  UTurnLeftOutlined,
  UTurnRightOutlined,
  VapeFreeOutlined,
  VapingRoomsOutlined,
  VideoCameraFrontOutlined,
  VideocamOutlined,
  VillaOutlined,
  VoicemailOutlined,
  VpnKeyOutlined,
  WatchOutlined,
  WavesOutlined,
  WindPowerOutlined,
  WindowOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@mui/icons-material";

export const filterList = [
  // { value: "bold-line", label: "Bold Line" },
  // { value: "solid-fill", label: "Solid Fill" },
  // { value: "thin-line", label: "Thin Line" },
  { value: "material", label: "Set 1" },
  { value: "material2", label: "Set 2" },
  { value: "material3", label: "Set 3" },
  { value: "material4", label: "Set 4" },
  { value: "material5", label: "Set 5" },
];

export const IconsList = [
  /*
  {
    id: "security-bold-line",
    name: "Security Bold Line",
    Icon: BoldSecurity,
    category: "bold-line",
  },
  {
    id: "wallet-bold-line",
    name: "Wallet Bold Line",
    Icon: BoldWallet,
    category: "bold-line",
  },
  {
    id: "discount-bold-line",
    name: "Discount Bold Line",
    Icon: BoldDiscount,
    category: "bold-line",
  },
  {
    id: "newspaper-bold-line",
    name: "Newspaper Bold Line",
    Icon: BoldNewspaper,
    category: "bold-line",
  },
  {
    id: "clock-bold-line",
    name: "Clock Bold Line",
    Icon: BoldClock,
    category: "bold-line",
  },
  {
    id: "alarm-bold-line",
    name: "Alarm Bold Line",
    Icon: BoldAlarm,
    category: "bold-line",
  },
  {
    id: "coin-bold-line",
    name: "Coin Bold Line",
    Icon: BoldCoin,
    category: "bold-line",
  },
  {
    id: "gift-bold-line",
    name: "Gift Bold Line",
    Icon: BoldGift,
    category: "bold-line",
  },
  {
    id: "link-bold-line",
    name: "Link Bold Line",
    Icon: BoldLink,
    category: "bold-line",
  },
  {
    id: "calculator-bold-line",
    name: "Calculator Bold Line",
    Icon: BoldCalculator,
    category: "bold-line",
  },
  {
    id: "sitemap-bold-line",
    name: "Sitemap Bold Line",
    Icon: BoldSitemap,
    category: "bold-line",
  },
  {
    id: "tag-bold-line",
    name: "Tag Bold Line",
    Icon: BoldTag,
    category: "bold-line",
  },
  {
    id: "sound-bold-line",
    name: "Sound Bold Line",
    Icon: BoldSound,
    category: "bold-line",
  },
  {
    id: "briefcase-bold-line",
    name: "Briefcase Bold Line",
    Icon: BoldBriefcase,
    category: "bold-line",
  },
  {
    id: "email-bold-line",
    name: "Email Bold Line",
    Icon: BoldEmail,
    category: "bold-line",
  },
  {
    id: "to-do-list-bold-line",
    name: "ToDoList Bold Line",
    Icon: BoldToDoList,
    category: "bold-line",
  },
  {
    id: "people-bold-line",
    name: "People Bold Line",
    Icon: BoldPeople,
    category: "bold-line",
  },
  {
    id: "king-bold-line",
    name: "King Bold Line",
    Icon: BoldKing,
    category: "bold-line",
  },
  {
    id: "folder-bold-line",
    name: "Folder Bold Line",
    Icon: BoldFolder,
    category: "bold-line",
  },
  {
    id: "network-bold-line",
    name: "Network Bold Line",
    Icon: BoldNetwork,
    category: "bold-line",
  },
  {
    id: "send-bold-line",
    name: "Send Bold Line",
    Icon: BoldSend,
    category: "bold-line",
  },
  {
    id: "dice-bold-line",
    name: "Dice Bold Line",
    Icon: BoldDice,
    category: "bold-line",
  },
  {
    id: "settings-bold-line",
    name: "Settings Bold Line",
    Icon: BoldSettings,
    category: "bold-line",
  },
  {
    id: "location-bold-line",
    name: "Location Bold Line",
    Icon: BoldLocation,
    category: "bold-line",
  },
  {
    id: "speech-bubble-bold-line",
    name: "SpeechBubble Bold Line",
    Icon: BoldSpeechBubble,
    category: "bold-line",
  },
  {
    id: "view-bold-line",
    name: "View Bold Line",
    Icon: BoldView,
    category: "bold-line",
  },
  {
    id: "pencil-bold-line",
    name: "Pencil Bold Line",
    Icon: BoldPencil,
    category: "bold-line",
  },
  {
    id: "pie-chart-bold-line",
    name: "PieChart Bold Line",
    Icon: BoldPieChart,
    category: "bold-line",
  },
  {
    id: "padlock-bold-line",
    name: "Padlock Bold Line",
    Icon: BoldPadlock,
    category: "bold-line",
  },
  {
    id: "connection-bold-line",
    name: "Connection Bold Line",
    Icon: BoldConnection,
    category: "bold-line",
  },
  {
    id: "mail-bold-line",
    name: "Mail Bold Line",
    Icon: BoldMail,
    category: "bold-line",
  },
  {
    id: "setting-bold-line",
    name: "Setting Bold Line",
    Icon: BoldSetting,
    category: "bold-line",
  },
  {
    id: "quality-bold-line",
    name: "Quality Bold Line",
    Icon: BoldQuality,
    category: "bold-line",
  },
  {
    id: "calendar-bold-line",
    name: "Calendar Bold Line",
    Icon: BoldCalendar,
    category: "bold-line",
  },
  {
    id: "browser-bold-line",
    name: "Browser Bold Line",
    Icon: BoldBrowser,
    category: "bold-line",
  },
  {
    id: "cloud-settings-bold-line",
    name: "CloudSettings Bold Line",
    Icon: BoldCloudSettings,
    category: "bold-line",
  },
  {
    id: "pantheon-bold-line",
    name: "Pantheon Bold Line",
    Icon: BoldPantheon,
    category: "bold-line",
  },
  {
    id: "ad-bold-line",
    name: "Ad Bold Line",
    Icon: BoldAd,
    category: "bold-line",
  },
  {
    id: "delivery-box-bold-line",
    name: "DeliveryBox Bold Line",
    Icon: BoldDeliveryBox,
    category: "bold-line",
  },
  {
    id: "statistic-bold-line",
    name: "Statistic Bold Line",
    Icon: BoldStatistic,
    category: "bold-line",
  },
  {
    id: "settings-secondary-bold-line",
    name: "SettingsSecondary Bold Line",
    Icon: BoldSettingsSecondary,
    category: "bold-line",
  },
  {
    id: "tv-bold-line",
    name: "Tv Bold Line",
    Icon: BoldTv,
    category: "bold-line",
  },
  {
    id: "light-bulb-bold-line",
    name: "LightBulb Bold Line",
    Icon: BoldLightBulb,
    category: "bold-line",
  },
  {
    id: "lock-bold-line",
    name: "Lock Bold Line",
    Icon: BoldLock,
    category: "bold-line",
  },
  {
    id: "coding-bold-line",
    name: "Coding Bold Line",
    Icon: BoldCoding,
    category: "bold-line",
  },
  {
    id: "setting-third-bold-line",
    name: "SettingThird Bold Line",
    Icon: BoldSettingThird,
    category: "bold-line",
  },
  {
    id: "cloud-computing-bold-line",
    name: "CloudComputing Bold Line",
    Icon: BoldCloudComputing,
    category: "bold-line",
  },
  {
    id: "chart-bold-line",
    name: "Chart Bold Line",
    Icon: BoldChart,
    category: "bold-line",
  },
  {
    id: "video-bold-line",
    name: "Video Bold Line",
    Icon: BoldVideo,
    category: "bold-line",
  },
  {
    id: "discount-secondary-bold-line",
    name: "DiscountSecondary Bold Line",
    Icon: BoldDiscountSecondary,
    category: "bold-line",
  },
  {
    id: "chart-secondary-bold-line",
    name: "ChartSecondary Bold Line",
    Icon: BoldChartSecondary,
    category: "bold-line",
  },
  {
    id: "email-secondary-bold-line",
    name: "EmailSecondary Bold Line",
    Icon: BoldEmailSecondary,
    category: "bold-line",
  },
  {
    id: "internet-bold-line",
    name: "Internet Bold Line",
    Icon: BoldInternet,
    category: "bold-line",
  },
  {
    id: "online-shop-bold-line",
    name: "OnlineShop Bold Line",
    Icon: BoldOnlineShop,
    category: "bold-line",
  },

  //solid start here
  {
    id: "shield-solid-fill",
    name: "Shield Solid Fill",
    Icon: SolidShield,
    category: "solid-fill",
  },
  {
    id: "tag-solid-fill",
    name: "Tag Solid Fill",
    Icon: SolidTag,
    category: "solid-fill",
  },
  {
    id: "news-solid-fill",
    name: "News Solid Fill",
    Icon: SolidNews,
    category: "solid-fill",
  },
  {
    id: "wallet-solid-fill",
    name: "Wallet Solid Fill",
    Icon: SolidWallet,
    category: "solid-fill",
  },
  {
    id: "dollar-symbol-solid-fill",
    name: "DollarSymbol Solid Fill",
    Icon: SolidDollarSymbol,
    category: "solid-fill",
  },
  {
    id: "gift-box-with-a-bow-solid-fill",
    name: "GiftBoxWithABow Solid Fill",
    Icon: SolidGiftBoxWithABow,
    category: "solid-fill",
  },
  {
    id: "link-solid-fill",
    name: "Link Solid Fill",
    Icon: SolidLink,
    category: "solid-fill",
  },
  {
    id: "calculator-solid-fill",
    name: "Calculator Solid Fill",
    Icon: SolidCalculator,
    category: "solid-fill",
  },
  {
    id: "web-development-solid-fill",
    name: "WebDevelopment Solid Fill",
    Icon: SolidWebDevelopment,
    category: "solid-fill",
  },
  {
    id: "advertisement-solid-fill",
    name: "Advertisement Solid Fill",
    Icon: SolidAdvertisement,
    category: "solid-fill",
  },
  {
    id: "video-marketing-solid-fill",
    name: "VideoMarketing Solid Fill",
    Icon: SolidVideoMarketing,
    category: "solid-fill",
  },
  {
    id: "internet-solid-fill",
    name: "Internet Solid Fill",
    Icon: SolidInternet,
    category: "solid-fill",
  },
  {
    id: "web-security-solid-fill",
    name: "WebSecurity Solid Fill",
    Icon: SolidWebSecurity,
    category: "solid-fill",
  },
  {
    id: "suitcase-solid-fill",
    name: "Suitcase Solid Fill",
    Icon: SolidSuitcase,
    category: "solid-fill",
  },
  {
    id: "black-back-closed-envelope-shape-solid-fill",
    name: "BlackBackClosedEnvelopeShape Solid Fill",
    Icon: SolidBlackBackClosedEnvelopeShape,
    category: "solid-fill",
  },
  {
    id: "to-do-list-solid-fill",
    name: "ToDoList Solid Fill",
    Icon: SolidToDoList,
    category: "solid-fill",
  },
  {
    id: "people-solid-fill",
    name: "People Solid Fill",
    Icon: SolidPeople,
    category: "solid-fill",
  },
  {
    id: "design-solid-fill",
    name: "Design Solid Fill",
    Icon: SolidDesign,
    category: "solid-fill",
  },
  {
    id: "timer-solid-fill",
    name: "Timer Solid Fill",
    Icon: SolidTimer,
    category: "solid-fill",
  },
  {
    id: "view-solid-fill",
    name: "View Solid Fill",
    Icon: SolidView,
    category: "solid-fill",
  },
  {
    id: "conversation-solid-fill",
    name: "Conversation Solid Fill",
    Icon: SolidConversation,
    category: "solid-fill",
  },
  {
    id: "creativity-solid-fill",
    name: "Creativity Solid Fill",
    Icon: SolidCreativity,
    category: "solid-fill",
  },
  {
    id: "dice-roll-solid-fill",
    name: "DiceRoll Solid Fill",
    Icon: SolidDiceRoll,
    category: "solid-fill",
  },
  {
    id: "paper-plane-solid-fill",
    name: "PaperPlane Solid Fill",
    Icon: SolidPaperPlane,
    category: "solid-fill",
  },
  {
    id: "folder-solid-fill",
    name: "Folder Solid Fill",
    Icon: SolidFolder,
    category: "solid-fill",
  },
  {
    id: "royal-crown-solid-fill",
    name: "RoyalCrown Solid Fill",
    Icon: SolidRoyalCrown,
    category: "solid-fill",
  },
  {
    id: "setting-spanner-solid-fill",
    name: "SettingSpanner Solid Fill",
    Icon: SolidSettingSpanner,
    category: "solid-fill",
  },
  {
    id: "tools-and-utensils-solid-fill",
    name: "ToolsAndUtensils Solid Fill",
    Icon: SolidToolsAndUtensils,
    category: "solid-fill",
  },
  {
    id: "medal-solid-fill",
    name: "Medal Solid Fill",
    Icon: SolidMedal,
    category: "solid-fill",
  },
  {
    id: "calendar-solid-fill",
    name: "Calendar Solid Fill",
    Icon: SolidCalendar,
    category: "solid-fill",
  },
  {
    id: "folder-secondary-solid-fill",
    name: "FolderSecondary Solid Fill",
    Icon: SolidFolderSecondary,
    category: "solid-fill",
  },
  {
    id: "package-solid-fill",
    name: "Package Solid Fill",
    Icon: SolidPackage,
    category: "solid-fill",
  },
  {
    id: "pantheon-solid-fill",
    name: "Pantheon Solid Fill",
    Icon: SolidPantheon,
    category: "solid-fill",
  },
  {
    id: "seo-solid-fill",
    name: "Seo Solid Fill",
    Icon: SolidSeo,
    category: "solid-fill",
  },
  {
    id: "pie-chart-solid-fill",
    name: "PieChart Solid Fill",
    Icon: SolidPieChart,
    category: "solid-fill",
  },
  {
    id: "bar-chart-solid-fill",
    name: "BarChart Solid Fill",
    Icon: SolidBarChart,
    category: "solid-fill",
  },
  {
    id: "share-solid-fill",
    name: "Share Solid Fill",
    Icon: SolidShare,
    category: "solid-fill",
  },
  {
    id: "settings-solid-fill",
    name: "Settings Solid Fill",
    Icon: SolidSettings,
    category: "solid-fill",
  },
  {
    id: "ads-solid-fill",
    name: "Ads Solid Fill",
    Icon: SolidAds,
    category: "solid-fill",
  },
  {
    id: "padlock-solid-fill",
    name: "Padlock Solid Fill",
    Icon: SolidPadlock,
    category: "solid-fill",
  },
  {
    id: "email-solid-fill",
    name: "Email Solid Fill",
    Icon: SolidEmail,
    category: "solid-fill",
  },
  {
    id: "location-solid-fill",
    name: "Location Solid Fill",
    Icon: SolidLocation,
    category: "solid-fill",
  },
  {
    id: "cog-solid-fill",
    name: "Cog Solid Fill",
    Icon: SolidCog,
    category: "solid-fill",
  },
  {
    id: "sitemap-solid-fill",
    name: "Sitemap Solid Fill",
    Icon: SolidSitemap,
    category: "solid-fill",
  },
  {
    id: "coding-solid-fill",
    name: "Coding Solid Fill",
    Icon: SolidCoding,
    category: "solid-fill",
  },
  {
    id: "folder-third-solid-fill",
    name: "FolderThird Solid Fill",
    Icon: SolidFolderThird,
    category: "solid-fill",
  },
  {
    id: "cloud-network-solid-fill",
    name: "CloudNetwork Solid Fill",
    Icon: SolidCloudNetwork,
    category: "solid-fill",
  },
  {
    id: "graph-solid-fill",
    name: "Graph Solid Fill",
    Icon: SolidGraph,
    category: "solid-fill",
  },
  {
    id: "laptop-solid-fill",
    name: "Laptop Solid Fill",
    Icon: SolidLaptop,
    category: "solid-fill",
  },
  {
    id: "laptop-secondary-solid-fill",
    name: "LaptopSecondary Solid Fill",
    Icon: SolidLaptopSecondary,
    category: "solid-fill",
  },
  //thin start here
  {
    id: "security-thin-line",
    name: "Security Thin Line",
    Icon: ThinShield,
    category: "thin-line",
  },
  {
    id: "clock-thin-line",
    name: "Clock Thin Line",
    Icon: ThinClock,
    category: "thin-line",
  },
  {
    id: "alarm-clock-thin-line",
    name: "AlarmClock Thin Line",
    Icon: ThinAlarmClock,
    category: "thin-line",
  },
  {
    id: "money-back-thin-line",
    name: "MoneyBack Thin Line",
    Icon: ThinMoneyBack,
    category: "thin-line",
  },
  {
    id: "gift-box-thin-line",
    name: "GiftBox Thin Line",
    Icon: ThinGiftBox,
    category: "thin-line",
  },
  {
    id: "link-thin-line",
    name: "Link Thin Line",
    Icon: ThinLink,
    category: "thin-line",
  },
  {
    id: "calculator-thin-line",
    name: "Calculator Thin Line",
    Icon: ThinCalculator,
    category: "thin-line",
  },
  {
    id: "sitemap-thin-line",
    name: "Sitemap Thin Line",
    Icon: ThinSitemap,
    category: "thin-line",
  },
  {
    id: "tag-thin-line",
    name: "Tag Thin Line",
    Icon: ThinTag,
    category: "thin-line",
  },
  {
    id: "speaker-thin-line",
    name: "Speaker Thin Line",
    Icon: ThinSpeaker,
    category: "thin-line",
  },
  {
    id: "safe-thin-line",
    name: "Safe Thin Line",
    Icon: ThinSafe,
    category: "thin-line",
  },
  {
    id: "suitcase-thin-line",
    name: "Suitcase Thin Line",
    Icon: ThinSuitcase,
    category: "thin-line",
  },
  {
    id: "envelope-thin-line",
    name: "Envelope Thin Line",
    Icon: ThinEnvelope,
    category: "thin-line",
  },
  {
    id: "files-thin-line",
    name: "Files Thin Line",
    Icon: ThinFiles,
    category: "thin-line",
  },
  {
    id: "internet-thin-line",
    name: "Internet Thin Line",
    Icon: ThinInternet,
    category: "thin-line",
  },
  {
    id: "setting-thin-line",
    name: "Setting Thin Line",
    Icon: ThinSetting,
    category: "thin-line",
  },
  {
    id: "connection-thin-line",
    name: "Connection Thin Line",
    Icon: ThinConnection,
    category: "thin-line",
  },
  {
    id: "crown-thin-line",
    name: "Crown Thin Line",
    Icon: ThinCrown,
    category: "thin-line",
  },
  {
    id: "server-thin-line",
    name: "Server Thin Line",
    Icon: ThinServer,
    category: "thin-line",
  },
  {
    id: "airplane-thin-line",
    name: "Airplane Thin Line",
    Icon: ThinAirplane,
    category: "thin-line",
  },
  {
    id: "dice-thin-line",
    name: "Dice Thin Line",
    Icon: ThinDice,
    category: "thin-line",
  },
  {
    id: "video-thin-line",
    name: "Video Thin Line",
    Icon: ThinVideo,
    category: "thin-line",
  },
  {
    id: "website-thin-line",
    name: "Website Thin Line",
    Icon: ThinWebsite,
    category: "thin-line",
  },
  {
    id: "cog-thin-line",
    name: "Cog Thin Line",
    Icon: ThinCog,
    category: "thin-line",
  },
  {
    id: "chart-thin-line",
    name: "Chart Thin Line",
    Icon: ThinChart,
    category: "thin-line",
  },
  {
    id: "location-thin-line",
    name: "Location Thin Line",
    Icon: ThinLocation,
    category: "thin-line",
  },
  {
    id: "chat-thin-line",
    name: "Chat Thin Line",
    Icon: ThinChat,
    category: "thin-line",
  },
  {
    id: "eye-thin-line",
    name: "Eye Thin Line",
    Icon: ThinEye,
    category: "thin-line",
  },
  {
    id: "pencil-thin-line",
    name: "Pencil Thin Line",
    Icon: ThinPencil,
    category: "thin-line",
  },
  {
    id: "pie-chart-thin-line",
    name: "PieChart Thin Line",
    Icon: ThinPieChart,
    category: "thin-line",
  },
  {
    id: "light-bulb-thin-line",
    name: "LightBulb Thin Line",
    Icon: ThinLightBulb,
    category: "thin-line",
  },
  {
    id: "lock-thin-line",
    name: "Lock Thin Line",
    Icon: ThinLock,
    category: "thin-line",
  },
  {
    id: "neural-thin-line",
    name: "Neural Thin Line",
    Icon: ThinNeural,
    category: "thin-line",
  },
  {
    id: "web-programming-thin-line",
    name: "WebProgramming Thin Line",
    Icon: ThinWebProgramming,
    category: "thin-line",
  },
  {
    id: "settingsecondary-thin-line",
    name: "Settingsecondary Thin Line",
    Icon: ThinSettingSecondary,
    category: "thin-line",
  },
  {
    id: "badge-thin-line",
    name: "Badge Thin Line",
    Icon: ThinBadge,
    category: "thin-line",
  },
  {
    id: "growth-thin-line",
    name: "Growth Thin Line",
    Icon: ThinGrowth,
    category: "thin-line",
  },
  {
    id: "calendar-thin-line",
    name: "Calendar Thin Line",
    Icon: ThinCalendar,
    category: "thin-line",
  },
  {
    id: "cogwheel-thin-line",
    name: "Cogwheel Thin Line",
    Icon: ThinCogwheel,
    category: "thin-line",
  },
  {
    id: "pantheon-thin-line",
    name: "Pantheon Thin Line",
    Icon: ThinPantheon,
    category: "thin-line",
  },
  {
    id: "target-thin-line",
    name: "Target Thin Line",
    Icon: ThinTarget,
    category: "thin-line",
  },
  {
    id: "folder-thin-line",
    name: "Folder Thin Line",
    Icon: ThinFolder,
    category: "thin-line",
  },
  {
    id: "package-box-thin-line",
    name: "PackageBox Thin Line",
    Icon: ThinPackageBox,
    category: "thin-line",
  },
  {
    id: "diamond-thin-line",
    name: "Diamond Thin Line",
    Icon: ThinDiamond,
    category: "thin-line",
  },
  {
    id: "line-graph-thin-line",
    name: "LineGraph Thin Line",
    Icon: ThinLineGraph,
    category: "thin-line",
  },
  {
    id: "email-marketing-thin-line",
    name: "EmailMarketing Thin Line",
    Icon: ThinEmailMarketing,
    category: "thin-line",
  },
  {
    id: "browser-thin-line",
    name: "Browser Thin Line",
    Icon: ThinBrowser,
    category: "thin-line",
  },
  {
    id: "ideas-thin-line",
    name: "Ideas Thin Line",
    Icon: ThinIdeas,
    category: "thin-line",
  },
  {
    id: "gear-thin-line",
    name: "Gear Thin Line",
    Icon: ThinGear,
    category: "thin-line",
  },
  {
    id: "video-marketing-thin-line",
    name: "VideoMarketing Thin Line",
    Icon: ThinVideoMarketing,
    category: "thin-line",
  },
  {
    id: "seo-thin-line",
    name: "Seo Thin Line",
    Icon: ThinSeo,
    category: "thin-line",
  },
  {
    id: "news-thin-line",
    name: "News Thin Line",
    Icon: ThinNews,
    category: "thin-line",
  },
  {
    id: "percentage-thin-line",
    name: "Percentage Thin Line",
    Icon: ThinPercentage,
    category: "thin-line",
  },
  {
    id: "wallet-thin-line",
    name: "Wallet Thin Line",
    Icon: ThinWallet,
    category: "thin-line",
  },
  */
  //-- material -- start here
  {
    id: "access-alarm-material",
    name: "Alarm Clock Reminder Schedule Time Notification",
    Icon: AccessAlarmOutlined,
    category: "material",
  },
  {
    id: "access-time-material",
    name: "Clock Time Hour Minute Second Watch",
    Icon: AccessTimeOutlined,
    category: "material",
  },
  {
    id: "accessible-material",
    name: "Accessibility Wheelchair Disabled Assistance Support",
    Icon: AccessibleOutlined,
    category: "material",
  },
  {
    id: "account-balance-material",
    name: "Bank Balance Finance Money Savings Investment",
    Icon: AccountBalanceOutlined,
    category: "material",
  },
  {
    id: "account-box-material",
    name: "User Profile Account Settings Personal Information",
    Icon: AccountBoxOutlined,
    category: "material",
  },
  {
    id: "account-circle-material",
    name: "User Login Profile Account Circle Identity",
    Icon: AccountCircleOutlined,
    category: "material",
  },
  {
    id: "add-a-photo-material",
    name: "Camera Add Photo Image Gallery Picture",
    Icon: AddAPhotoOutlined,
    category: "material",
  },
  {
    id: "add-home-material",
    name: "Add House Home Property Real Estate Building",
    Icon: AddHomeOutlined,
    category: "material",
  },
  {
    id: "add-home-work-material",
    name: "Add House Work Office Building Real Estate",
    Icon: AddHomeWorkOutlined,
    category: "material",
  },
  {
    id: "add-ic-material",
    name: "Add Call Telephone Communication Contact",
    Icon: AddIcCallOutlined,
    category: "material",
  },
  {
    id: "add-location-material",
    name: "Add Location Pin Map GPS Place",
    Icon: AddLocationOutlined,
    category: "material",
  },
  {
    id: "agriculture-material",
    name: "Farming Agriculture Tractor Farm Field",
    Icon: AgricultureOutlined,
    category: "material",
  },
  {
    id: "air-outlined-material",
    name: "Air Flow Breeze Wind Climate Ventilation",
    Icon: AirOutlined,
    category: "material",
  },
  {
    id: "airline-seat-recline-extra-material",
    name: "Airline Seat Recline Comfort Travel Flight",
    Icon: AirlineSeatReclineExtraOutlined,
    category: "material",
  },
  {
    id: "airplain-mode-active-material",
    name: "Airplane Mode Flight Travel Aviation",
    Icon: AirplanemodeActiveOutlined,
    category: "material",
  },
  {
    id: "airport-shuttle-material",
    name: "Airport Shuttle Transport Travel Bus Service",
    Icon: AirportShuttleOutlined,
    category: "material",
  },
  {
    id: "all-inclusive-material",
    name: "Infinity Loop Endless All Inclusive Continuous",
    Icon: AllInclusiveOutlined,
    category: "material",
  },
  {
    id: "aod-material",
    name: "App On Demand Technology Digital Service",
    Icon: AodOutlined,
    category: "material",
  },
  {
    id: "apartment-material",
    name: "Apartment Building Real Estate Housing Condo",
    Icon: ApartmentOutlined,
    category: "material",
  },
  {
    id: "arrow-circle-down-material",
    name: "Arrow Down Download Lower Decrease",
    Icon: ArrowCircleDownOutlined,
    category: "material",
  },
  {
    id: "arrow-circle-left-material",
    name: "Arrow Left Back Return Previous Navigate",
    Icon: ArrowCircleLeftOutlined,
    category: "material",
  },

  {
    id: "arrow-circle-right-material",
    name: "Right Direction Arrow Navigation Forward Move",
    Icon: ArrowCircleRightOutlined,
    category: "material",
  },
  {
    id: "arrow-circle-up-material",
    name: "Upward Direction Arrow Navigation Ascend Elevate",
    Icon: ArrowCircleUpOutlined,
    category: "material",
  },
  {
    id: "assignment-ind-material",
    name: "Identification Badge Profile Personal Document",
    Icon: AssignmentIndOutlined,
    category: "material",
  },
  {
    id: "assignment-late-material",
    name: "Deadline Overdue Late Warning Assignment Alert",
    Icon: AssignmentLateOutlined,
    category: "material",
  },
  {
    id: "assignment-material",
    name: "Task Brief Project Document Report Paperwork",
    Icon: AssignmentOutlined,
    category: "material",
  },
  {
    id: "assist-walker-material",
    name: "Mobility Aid Support Walker Disability Assistance",
    Icon: AssistWalkerOutlined,
    category: "material",
  },
  {
    id: "assistant-photo-material",
    name: "Flag Bookmark Marker Indication Signal Attention",
    Icon: AssistantPhotoOutlined,
    category: "material",
  },
  {
    id: "attach-file-material",
    name: "Clip Attachment Document Add File Paper",
    Icon: AttachFileOutlined,
    category: "material",
  },
  {
    id: "attach-money-material",
    name: "Money Attachment Finance Currency Dollar Cash",
    Icon: AttachMoneyOutlined,
    category: "material",
  },
  {
    id: "auto-awesome-material",
    name: "Automatic Sparkle Effect Star Shine Glamour",
    Icon: AutoAwesomeOutlined,
    category: "material",
  },
  {
    id: "auto-delete-material",
    name: "Automatic Deletion Timer Schedule Erase",
    Icon: AutoDeleteOutlined,
    category: "material",
  },
  {
    id: "autorenew-material",
    name: "Automatic Renewal Refresh Update Cycle",
    Icon: AutorenewOutlined,
    category: "material",
  },
  {
    id: "baby-changing-station-material",
    name: "Baby Care Changing Station Diaper Nursery",
    Icon: BabyChangingStationOutlined,
    category: "material",
  },
  {
    id: "bakery-dining-material",
    name: "Bakery Food Bread Pastry Dining Cafe",
    Icon: BakeryDiningOutlined,
    category: "material",
  },
  {
    id: "balance-material",
    name: "Scale Balance Justice Equality Fairness",
    Icon: BalanceOutlined,
    category: "material",
  },
  {
    id: "bathtub-material",
    name: "Bath Tub Bathroom Relaxation Spa Hygiene",
    Icon: BathtubOutlined,
    category: "material",
  },
  {
    id: "battery-3-bar-material",
    name: "Battery Level Indicator Power Charge Energy",
    Icon: Battery3BarOutlined,
    category: "material",
  },
  {
    id: "beach-access-material",
    name: "Beach Vacation Access Relaxation Coastal Sea",
    Icon: BeachAccessOutlined,
    category: "material",
  },
  {
    id: "bed-material",
    name: "Bed Furniture Sleep Mattress Bedroom Comfort",
    Icon: BedOutlined,
    category: "material",
  },
  {
    id: "blender-material",
    name: "Blender Mix Juice Smoothie Appliance Kitchen",
    Icon: BlenderOutlined,
    category: "material",
  },
  {
    id: "blind-material",
    name: "Blind Assistance Accessibility Guide Dog",
    Icon: BlindOutlined,
    category: "material",
  },
  {
    id: "bluetooth-material",
    name: "Bluetooth Connectivity Wireless Technology Connection",
    Icon: BluetoothOutlined,
    category: "material",
  },
  {
    id: "bolt-material",
    name: "Electric Bolt Lightning Power Energy Charge",
    Icon: BoltOutlined,
    category: "material",
  },
  {
    id: "bookmarks-material",
    name: "Bookmark Tag Save Favorite Marker Reminder",
    Icon: BookmarksOutlined,
    category: "material",
  },
  {
    id: "brightness-2-material",
    name: "Night Mode Darkness Low Light Moon",
    Icon: Brightness2Outlined,
    category: "material",
  },
  {
    id: "brightness-5-material",
    name: "Daylight Sun Brightness Sunshine Daytime",
    Icon: Brightness5Outlined,
    category: "material",
  },
  {
    id: "brunch-dining-material",
    name: "Brunch Dining Food Restaurant Meal",
    Icon: BrunchDiningOutlined,
    category: "material",
  },
  {
    id: "bug-report-material",
    name: "Bug Report Error Feedback Issue Technical",
    Icon: BugReportOutlined,
    category: "material",
  },
  {
    id: "build-material",
    name: "Tool Repair Service Construction Work DIY",
    Icon: BuildOutlined,
    category: "material",
  },
  {
    id: "business-material",
    name: "Business Center Office Corporate Work",
    Icon: BusinessCenterOutlined,
    category: "material",
  },
  {
    id: "calendar-month-material",
    name: "Calendar Schedule Date Month Event Planner",
    Icon: CalendarMonthOutlined,
    category: "material",
  },
  {
    id: "call-material",
    name: "Phone Call Contact Communication Talk",
    Icon: CallOutlined,
    category: "material",
  },
  {
    id: "camera-material",
    name: "Camera Photography Photo Picture Image",
    Icon: CameraAltOutlined,
    category: "material",
  },
  {
    id: "campaign-material",
    name: "Campaign Advertisement Marketing Newsletter Promotion",
    Icon: CampaignOutlined,
    category: "material",
  },
  {
    id: "card-gift-material",
    name: "Gift Card Present Voucher Coupon Reward",
    Icon: CardGiftcardOutlined,
    category: "material",
  },
  {
    id: "celebration-material",
    name: "Celebration Party Event Festivity Occasion",
    Icon: CelebrationOutlined,
    category: "material",
  },
  {
    id: "chair-material",
    name: "Chair Furniture Seat Comfort Clinic",
    Icon: ChairOutlined,
    category: "material",
  },
  {
    id: "checkroom-material",
    name: "Checkroom Coat Service Storage Facility",
    Icon: CheckroomOutlined,
    category: "material",
  },
  {
    id: "child-material",
    name: "Child Care Babysitting Kids Parenting Nursery",
    Icon: ChildCareOutlined,
    category: "material",
  },
  {
    id: "child-friendly-material",
    name: "Child Friendly Family Kids Parenting Safety",
    Icon: ChildFriendlyOutlined,
    category: "material2",
  },
  {
    id: "church-material",
    name: "Church Religion Worship Spiritual Location",
    Icon: ChurchOutlined,
    category: "material2",
  },
  {
    id: "clean-hands-material",
    name: "Clean Hands Hygiene Wash Sanitize Health",
    Icon: CleanHandsOutlined,
    category: "material2",
  },
  {
    id: "cloud-material",
    name: "Cloud Storage Data Online Backup Technology",
    Icon: CloudOutlined,
    category: "material2",
  },
  {
    id: "cloud-upload-material",
    name: "Cloud Upload Data Transfer Online Storage",
    Icon: CloudUploadOutlined,
    category: "material2",
  },
  {
    id: "co-present-material",
    name: "Presentation Co-Present Share Collaborate Meeting",
    Icon: CoPresentOutlined,
    category: "material2",
  },
  {
    id: "color-lens-material",
    name: "Color Lens Design Palette Creative Art",
    Icon: ColorLensOutlined,
    category: "material2",
  },
  {
    id: "comment-material",
    name: "Comment Message Chat Communication Feedback",
    Icon: CommentOutlined,
    category: "material2",
  },
  {
    id: "commute-material",
    name: "Commute Travel Transportation Trip Journey",
    Icon: CommuteOutlined,
    category: "material2",
  },
  {
    id: "connect-without-contact-material",
    name: "Wireless Connect Contactless Communication Technology",
    Icon: ConnectWithoutContactOutlined,
    category: "material2",
  },
  {
    id: "construction-material",
    name: "Construction Building Development Site Work",
    Icon: ConstructionOutlined,
    category: "material2",
  },
  {
    id: "contact-phone-material",
    name: "Contact Phone Call Support Help Desk",
    Icon: ContactPhoneOutlined,
    category: "material2",
  },
  {
    id: "contact-support-material",
    name: "Support FAQ Help Desk Assistance Information",
    Icon: ContactSupportOutlined,
    category: "material2",
  },
  {
    id: "contactless-material",
    name: "Contactless Payment NFC Cardless Transaction",
    Icon: ContactlessOutlined,
    category: "material2",
  },
  {
    id: "content-cut-material",
    name: "Content Cut Scissors Editing Trim Clip",
    Icon: ContentCutOutlined,
    category: "material2",
  },
  {
    id: "coronavirus-material",
    name: "Coronavirus COVID-19 Pandemic Health Virus Alert",
    Icon: CoronavirusOutlined,
    category: "material2",
  },
  {
    id: "credit-score-material",
    name: "Credit Score Finance Report Rating Financial Health",
    Icon: CreditScoreOutlined,
    category: "material2",
  },
  {
    id: "currency-bitcoin-material",
    name: "Bitcoin Cryptocurrency Digital Money Blockchain Finance",
    Icon: CurrencyBitcoinOutlined,
    category: "material2",
  },
  {
    id: "currency-exchange-material",
    name: "Currency Exchange Rate Money Transfer Financial Market",
    Icon: CurrencyExchangeOutlined,
    category: "material2",
  },
  {
    id: "dangerous-material",
    name: "Danger Warning Risk Hazard Safety Alert",
    Icon: DangerousOutlined,
    category: "material2",
  },
  {
    id: "deck-material",
    name: "Deck Patio Outdoor Furniture Garden Leisure",
    Icon: DeckOutlined,
    category: "material2",
  },
  {
    id: "design-services-material",
    name: "Design Services Creative Art Graphic Customization",
    Icon: DesignServicesOutlined,
    category: "material2",
  },
  {
    id: "desktop-windows-material",
    name: "Desktop Computer PC Windows Technology Hardware",
    Icon: DesktopWindowsOutlined,
    category: "material2",
  },
  {
    id: "diamond-material",
    name: "Diamond Luxury Jewelry Gemstone High-end Exclusive",
    Icon: DiamondOutlined,
    category: "material2",
  },
  {
    id: "directions-bike-material",
    name: "Bike Cycling Route Travel Exercise Transportation",
    Icon: DirectionsBikeOutlined,
    category: "material2",
  },
  {
    id: "direction-bus-filled-material",
    name: "Bus Public Transport City Transit Travel Commute",
    Icon: DirectionsBusFilledOutlined,
    category: "material2",
  },
  {
    id: "directions-car-material",
    name: "Car Directions Navigation Travel Drive Automobile",
    Icon: DirectionsCarOutlined,
    category: "material2",
  },
  {
    id: "directions-run-material",
    name: "Running Jogging Fitness Exercise Health Activity",
    Icon: DirectionsRunOutlined,
    category: "material2",
  },
  {
    id: "directions-transit-filled-material",
    name: "Transit Public Transportation Metro Train Bus",
    Icon: DirectionsTransitFilledOutlined,
    category: "material2",
  },
  {
    id: "diversity-1-material",
    name: "Diversity Inclusion People Group Community Team",
    Icon: Diversity1Outlined,
    category: "material2",
  },
  {
    id: "diversity-3-material",
    name: "Diversity Inclusion Teamwork People Group Unity",
    Icon: Diversity3Outlined,
    category: "material2",
  },
  {
    id: "done-material",
    name: "Done Checkmark Complete Success Confirmation",
    Icon: DoneOutlined,
    category: "material2",
  },
  {
    id: "downhill-skiiing-material",
    name: "Downhill Skiing Winter Sport Snow Mountain Leisure",
    Icon: DownhillSkiingOutlined,
    category: "material2",
  },
  {
    id: "download-material",
    name: "Download File Data Transfer Downloading Internet",
    Icon: DownloadOutlined,
    category: "material2",
  },
  {
    id: "drafts-material",
    name: "Email Drafts Correspondence Messages Communication",
    Icon: DraftsOutlined,
    category: "material2",
  },
  {
    id: "edit-material",
    name: "Edit Pencil Modify Change Customization",
    Icon: EditOutlined,
    category: "material2",
  },
  {
    id: "email-material",
    name: "Email Message Communication Correspondence Inbox",
    Icon: EmailOutlined,
    category: "material2",
  },
  {
    id: "emoji-events-material",
    name: "Trophy Award Achievement Recognition Success",
    Icon: EmojiEventsOutlined,
    category: "material2",
  },
  {
    id: "emoji-flags-material",
    name: "Flags Countries Representations Markers Symbols",
    Icon: EmojiFlagsOutlined,
    category: "material2",
  },
  {
    id: "emoji-food-beverage-material",
    name: "Food Beverage Drink Eat Meal Snack",
    Icon: EmojiFoodBeverageOutlined,
    category: "material2",
  },
  {
    id: "emoji-nature-material",
    name: "Nature Flower Environmental Green Eco",
    Icon: EmojiNatureOutlined,
    category: "material2",
  },
  {
    id: "emoji-objects-material",
    name: "Objects Items Things Artifacts Products",
    Icon: EmojiObjectsOutlined,
    category: "material2",
  },
  {
    id: "engineering-material",
    name: "Engineering Construction Design Development Innovation",
    Icon: EngineeringOutlined,
    category: "material2",
  },
  {
    id: "escalator-warning-material",
    name: "Escalator Caution Safety Warning Alert",
    Icon: EscalatorWarningOutlined,
    category: "material2",
  },
  {
    id: "explore-material",
    name: "Explore Discover Navigation Travel Adventure",
    Icon: ExploreOutlined,
    category: "material2",
  },
  {
    id: "extension-material",
    name: "Extension Add-On Customization Enhancement Extra",
    Icon: ExtensionOutlined,
    category: "material2",
  },
  {
    id: "face-4-material",
    name: "Face Woman Female Person Identity",
    Icon: Face4Outlined,
    category: "material2",
  },
  {
    id: "face-material",
    name: "Face Man Male Person Identity",
    Icon: FaceOutlined,
    category: "material2",
  },
  {
    id: "facebook-material",
    name: "Facebook Social Media Network Online Platform",
    Icon: FacebookOutlined,
    category: "material2",
  },
  {
    id: "family-restroom-material",
    name: "Family Restroom Bathroom Facilities Accessibility",
    Icon: FamilyRestroomOutlined,
    category: "material2",
  },
  {
    id: "fast-forward-material",
    name: "Fast Forward Speed Advance Skip Media",
    Icon: FastForwardOutlined,
    category: "material2",
  },
  {
    id: "fastfood-material",
    name: "Fast Food Quick Meal Burger Fries",
    Icon: FastfoodOutlined,
    category: "material2",
  },
  {
    id: "favorite-border-material",
    name: "Favorite Like Love Heart Preference",
    Icon: FavoriteBorderOutlined,
    category: "material2",
  },
  {
    id: "festival-material",
    name: "Festival Celebration Event Carnival Party",
    Icon: FestivalOutlined,
    category: "material2",
  },
  {
    id: "filter-drama-material",
    name: "Weather Clouds Sky Atmosphere Meteorology",
    Icon: FilterDramaOutlined,
    category: "material2",
  },
  {
    id: "filter-hdr-material",
    name: "Landscape Mountains Nature Scenery View",
    Icon: FilterHdrOutlined,
    category: "material2",
  },
  {
    id: "fingerprint-material",
    name: "Fingerprint Identification Security Biometric Authentication",
    Icon: FingerprintOutlined,
    category: "material2",
  },
  {
    id: "fireplace-material",
    name: "Fireplace Warmth Comfort Home Cozy",
    Icon: FireplaceOutlined,
    category: "material2",
  },
  {
    id: "fitness-center-material",
    name: "Fitness Exercise Workout Gym Health",
    Icon: FitnessCenterOutlined,
    category: "material2",
  },
  {
    id: "flatware-material",
    name: "Cutlery Utensils Dining Eatery Menu Food",
    Icon: FlatwareOutlined,
    category: "material2",
  },
  {
    id: "flight-material",
    name: "Airplane Travel Flying Departure Arrival Transportation",
    Icon: FlightOutlined,
    category: "material3",
  },
  {
    id: "fmd-good-material",
    name: "Location Map Pin Destination Position Place",
    Icon: FmdGoodOutlined,
    category: "material3",
  },
  {
    id: "forest-material",
    name: "Trees Nature Environment Eco Greenery Woods",
    Icon: ForestOutlined,
    category: "material3",
  },
  {
    id: "format-paint-material",
    name: "Paint Brush Art Design Color Decoration",
    Icon: FormatPaintOutlined,
    category: "material3",
  },
  {
    id: "format-quote-material",
    name: "Quotation Speech Text Testimonial Reference Words",
    Icon: FormatQuoteOutlined,
    category: "material3",
  },
  {
    id: "forum-material",
    name: "Discussion Chat Message Communication Talk",
    Icon: ForumOutlined,
    category: "material3",
  },
  {
    id: "golf-course-material",
    name: "Golf Sport Club Outdoor Leisure Activity",
    Icon: GolfCourseOutlined,
    category: "material3",
  },
  {
    id: "gps-fixed-material",
    name: "GPS Navigation Location Position Tracking Satellite",
    Icon: GpsFixedOutlined,
    category: "material3",
  },
  {
    id: "grade-material",
    name: "Star Rating Review Quality Excellence Feedback",
    Icon: GradeOutlined,
    category: "material3",
  },
  {
    id: "handshake-material",
    name: "Agreement Partnership Deal Cooperation Meeting",
    Icon: HandshakeOutlined,
    category: "material3",
  },
  {
    id: "headphones-material",
    name: "Music Audio Listen Sound Podcast Entertainment",
    Icon: HeadphonesOutlined,
    category: "material3",
  },
  {
    id: "health-and-safety-material",
    name: "Healthcare Safety Wellbeing Medical Protection",
    Icon: HealthAndSafetyOutlined,
    category: "material3",
  },
  {
    id: "heading-material",
    name: "Hearing Aid Audio Sound Assistance Ear",
    Icon: HearingOutlined,
    category: "material3",
  },
  {
    id: "hiking-material",
    name: "Hiking Trekking Adventure Nature Walk Outdoor",
    Icon: HikingOutlined,
    category: "material3",
  },
  {
    id: "history-material",
    name: "History Time Past Clock Rewind Event",
    Icon: HistoryOutlined,
    category: "material3",
  },
  {
    id: "home-material",
    name: "Home House Residence Living Real Estate Property",
    Icon: HomeOutlined,
    category: "material3",
  },
  {
    id: "hotel-material",
    name: "Hotel Accommodation Stay Lodging Travel Hospitality",
    Icon: HotelOutlined,
    category: "material3",
  },
  {
    id: "how-to-reg-material",
    name: "Registration Sign Up Account Verification Enroll",
    Icon: HowToRegOutlined,
    category: "material3",
  },
  {
    id: "https-material",
    name: "Secure Connection SSL Encryption Privacy Safety",
    Icon: HttpsOutlined,
    category: "material3",
  },
  {
    id: "image-material",
    name: "Photo Gallery Picture Image Display Visual",
    Icon: ImageOutlined,
    category: "material3",
  },
  {
    id: "imagesearch-roller-material",
    name: "Paint Roller Application Tool Home Improvement",
    Icon: ImagesearchRollerOutlined,
    category: "material3",
  },
  {
    id: "insert-emoticon-material",
    name: "Emoticon Smile Emoji Expression Mood Face",
    Icon: InsertEmoticonOutlined,
    category: "material3",
  },
  {
    id: "kayaking-material",
    name: "Kayaking Canoeing Water Sport Adventure River",
    Icon: KayakingOutlined,
    category: "material3",
  },
  {
    id: "keyboard-voice-material",
    name: "Voice Command Typing Keyboard Speech Input",
    Icon: KeyboardVoiceOutlined,
    category: "material3",
  },
  {
    id: "language-material",
    name: "Global Language International Communication World",
    Icon: LanguageOutlined,
    category: "material3",
  },
  {
    id: "light-mode-material",
    name: "Light Mode Brightness Display Settings Day",
    Icon: LightModeOutlined,
    category: "material3",
  },
  {
    id: "lightbulb-material",
    name: "Idea Innovation Lightbulb Thought Creativity",
    Icon: LightbulbOutlined,
    category: "material3",
  },
  {
    id: "liquor-material",
    name: "Liquor Alcohol Drink Beverage Bar Pub",
    Icon: LiquorOutlined,
    category: "material3",
  },
  {
    id: "local-dining-material",
    name: "Dining Food Restaurant Cuisine Meal Eat",
    Icon: LocalDiningOutlined,
    category: "material3",
  },
  {
    id: "local-fire-department-material",
    name: "Fire Department Emergency Services Firefighter Rescue",
    Icon: LocalFireDepartmentOutlined,
    category: "material3",
  },
  {
    id: "local-florist-material",
    name: "Florist Flowers Bouquet Floral Shop Nature",
    Icon: LocalFloristOutlined,
    category: "material3",
  },
  {
    id: "local-gas-station-material",
    name: "Gas Station Fuel Pump Petrol Diesel Service",
    Icon: LocalGasStationOutlined,
    category: "material3",
  },
  {
    id: "local-post-office-material",
    name: "Post Office Mail Postal Service Letters Packages",
    Icon: LocalPostOfficeOutlined,
    category: "material3",
  },
  {
    id: "local-printshop-material",
    name: "Printshop Printing Services Press Publisher",
    Icon: LocalPrintshopOutlined,
    category: "material3",
  },
  {
    id: "local-shipping-material",
    name: "Shipping Delivery Logistics Transport Cargo",
    Icon: LocalShippingOutlined,
    category: "material3",
  },
  {
    id: "luggage-material",
    name: "Luggage Travel Suitcase Baggage Trip",
    Icon: LuggageOutlined,
    category: "material3",
  },
  {
    id: "lunch-dining-material",
    name: "Lunch Dining Food Restaurant Meal Eatery",
    Icon: LunchDiningOutlined,
    category: "material3",
  },
  {
    id: "map-material",
    name: "Map Navigation Location Travel Direction Explore",
    Icon: MapOutlined,
    category: "material3",
  },
  {
    id: "maps-home-work-material",
    name: "Home Work Commute Navigation Route Directions",
    Icon: MapsHomeWorkOutlined,
    category: "material3",
  },
  {
    id: "mark-as-unread-material",
    name: "Mark as Unread Email Message Notification Inbox",
    Icon: MarkAsUnreadOutlined,
    category: "material3",
  },
  {
    id: "mode-material",
    name: "Edit Mode Write Pencil Draft Modify",
    Icon: ModeOutlined,
    category: "material3",
  },
  {
    id: "monetization-on-material",
    name: "Monetization Money Finance Dollar Currency Payment",
    Icon: MonetizationOnOutlined,
    category: "material3",
  },
  {
    id: "mouse-material",
    name: "Computer Mouse Click Pointer Device",
    Icon: MouseOutlined,
    category: "material3",
  },
  {
    id: "movie-material",
    name: "Film Cinema Video Entertainment Media Clip",
    Icon: MovieOutlined,
    category: "material3",
  },
  {
    id: "new-releases-material",
    name: "Latest New Releases Updates News Announcement",
    Icon: NewReleasesOutlined,
    category: "material3",
  },
  {
    id: "nightlight-material",
    name: "Night Light Moon Sleep Evening Comfort",
    Icon: NightlightOutlined,
    category: "material3",
  },
  {
    id: "notifications-active-material",
    name: "Alerts Notifications Bell Reminder Active",
    Icon: NotificationsActiveOutlined,
    category: "material3",
  },
  {
    id: "palette-material",
    name: "Art Color Palette Paint Creative Design",
    Icon: PaletteOutlined,
    category: "material3",
  },
  {
    id: "pan-tool-material",
    name: "Hand Gesture Stop Pan Assistance Help",
    Icon: PanToolOutlined,
    category: "material3",
  },
  {
    id: "panorama-material",
    name: "Wide Landscape View Panorama Scenery Photography",
    Icon: PanoramaOutlined,
    category: "material3",
  },
  {
    id: "payment-material",
    name: "Payment Transaction Money Finance Credit Card",
    Icon: PaymentOutlined,
    category: "material3",
  },
  {
    id: "perm-phone-msg-material",
    name: "Phone Message Voicemail Communication Contact",
    Icon: PermPhoneMsgOutlined,
    category: "material3",
  },
  {
    id: "pets-material",
    name: "Animals Pets Dog Cat Paw Love",
    Icon: PetsOutlined,
    category: "material3",
  },
  {
    id: "phone-in-talk-material",
    name: "Phone Call Conversation Communication Talk",
    Icon: PhoneInTalkOutlined,
    category: "material3",
  },
  {
    id: "piano-material",
    name: "Musical Instrument Piano Music Keyboard Melody",
    Icon: PianoOutlined,
    category: "material3",
  },
  {
    id: "pie-chart-material",
    name: "Data Visualization Statistics Graph Analysis",
    Icon: PieChartOutlined,
    category: "material3",
  },
  {
    id: "play-circle-filled-white-material",
    name: "Media Play Button Video Start Circle",
    Icon: PlayCircleFilledWhiteOutlined,
    category: "material3",
  },
  {
    id: "plumbing-material",
    name: "Pipes Wrench Plumbing Repair Waterworks Tools",
    Icon: PlumbingOutlined,
    category: "material3",
  },
  {
    id: "point-of-sale-material",
    name: "POS System Transaction Payment Sales Register",
    Icon: PointOfSaleOutlined,
    category: "material3",
  },
  {
    id: "pool-material",
    name: "Swimming Pool Water Leisure Sport Recreation",
    Icon: PoolOutlined,
    category: "material3",
  },
  {
    id: "psychology-alt-material",
    name: "Mind Brain Psychology Therapy Mental Health",
    Icon: PsychologyAltOutlined,
    category: "material4",
  },
  {
    id: "public-material",
    name: "Globe Earth World Public International Global",
    Icon: PublicOutlined,
    category: "material4",
  },
  {
    id: "push-pin-material",
    name: "Pin Location Map Notice Board Tack",
    Icon: PushPinOutlined,
    category: "material4",
  },
  {
    id: "qr-code-material",
    name: "QR Code Scan Digital Information Barcode",
    Icon: QrCodeOutlined,
    category: "material4",
  },
  {
    id: "qr-code-scanner-material",
    name: "QR Code Reader Scan Barcode Digital",
    Icon: QrCodeScannerOutlined,
    category: "material4",
  },
  {
    id: "query-stats-material",
    name: "Data Query Analysis Statistics Research Metrics",
    Icon: QueryStatsOutlined,
    category: "material4",
  },
  {
    id: "question-answer-material",
    name: "FAQ Dialog Conversation Communication Query",
    Icon: QuestionAnswerOutlined,
    category: "material4",
  },
  {
    id: "question-mark-material",
    name: "Query Doubt FAQ Help Information Inquiry",
    Icon: QuestionMarkOutlined,
    category: "material4",
  },
  {
    id: "ramen-dining-material",
    name: "Noodles Food Ramen Japanese Cuisine Dining",
    Icon: RamenDiningOutlined,
    category: "material4",
  },
  {
    id: "receipt-long-material",
    name: "Invoice Bill Payment Receipt Financial Document",
    Icon: ReceiptLongOutlined,
    category: "material4",
  },
  {
    id: "record-voice-over-material",
    name: "Voice Recording Microphone Audio Speak Narration",
    Icon: RecordVoiceOverOutlined,
    category: "material4",
  },
  {
    id: "remember-me-material",
    name: "Memory Reminder Remember Checkbox Option",
    Icon: RememberMeOutlined,
    category: "material4",
  },
  {
    id: "remove-red-eye-material",
    name: "Eye Redness Removal Edit Photo Correction",
    Icon: RemoveRedEyeOutlined,
    category: "material4",
  },
  {
    id: "rocket-launch-material",
    name: "Space Rocket Launch Startup Boost Acceleration",
    Icon: RocketLaunchOutlined,
    category: "material4",
  },
  {
    id: "rocket-material",
    name: "Space Rocket Ship Launch Aerospace",
    Icon: RocketOutlined,
    category: "material4",
  },
  {
    id: "router-material",
    name: "Internet Router Connectivity Network Wireless",
    Icon: RouterOutlined,
    category: "material4",
  },
  {
    id: "rss-feed-material",
    name: "RSS Feed News Update Broadcast Syndication",
    Icon: RssFeedOutlined,
    category: "material4",
  },
  {
    id: "sailing-material",
    name: "Boat Sailing Ocean Sea Nautical Voyage",
    Icon: SailingOutlined,
    category: "material4",
  },
  {
    id: "sanitizer-material",
    name: "Hand Sanitizer Hygiene Cleaning Disinfectant",
    Icon: SanitizerOutlined,
    category: "material4",
  },
  {
    id: "savings-material",
    name: "Money Savings Finance Piggy Bank Investment",
    Icon: SavingsOutlined,
    category: "material4",
  },
  {
    id: "school-material",
    name: "Education School Learning Academy Teaching",
    Icon: SchoolOutlined,
    category: "material4",
  },
  {
    id: "science-material",
    name: "Laboratory Science Research Chemical Experiment",
    Icon: ScienceOutlined,
    category: "material4",
  },
  {
    id: "screen-search-desktop-material",
    name: "Desktop Monitor Search Online Investigation",
    Icon: ScreenSearchDesktopOutlined,
    category: "material4",
  },
  {
    id: "screenshot-material",
    name: "Capture Screen Screenshot Snapshot Image",
    Icon: ScreenshotOutlined,
    category: "material4",
  },
  {
    id: "scuba-diving-material",
    name: "Scuba Diving Underwater Ocean Marine Adventure",
    Icon: ScubaDivingOutlined,
    category: "material4",
  },
  {
    id: "search-material",
    name: "Search Bar Magnifying Glass",
    Icon: SearchOutlined,
    category: "material4",
  },
  {
    id: "settings-material",
    name: "Settings Gear",
    Icon: SettingsOutlined,
    category: "material4",
  },
  {
    id: "shopping-cart-material",
    name: "Shopping Cart Trolley",
    Icon: ShoppingCartOutlined,
    category: "material4",
  },
  {
    id: "shower-material",
    name: "Shower Head",
    Icon: ShowerOutlined,
    category: "material4",
  },
  {
    id: "slow-motion-video-material",
    name: "Slow Motion Video Camera",
    Icon: SlowMotionVideoOutlined,
    category: "material4",
  },
  {
    id: "smart-display-material",
    name: "Smart Display Screen",
    Icon: SmartDisplayOutlined,
    category: "material4",
  },
  {
    id: "smoke-free-material",
    name: "Smoke Free Sign",
    Icon: SmokeFreeOutlined,
    category: "material4",
  },
  {
    id: "smoking-rooms-material",
    name: "Smoking Rooms Sign",
    Icon: SmokingRoomsOutlined,
    category: "material4",
  },
  {
    id: "sports-bar-material",
    name: "Sports Bar Glass",
    Icon: SportsBarOutlined,
    category: "material4",
  },
  {
    id: "sports-baseball-material",
    name: "Baseball Bat and Ball",
    Icon: SportsBaseballOutlined,
    category: "material4",
  },
  {
    id: "sports-basketball-material",
    name: "Basketball and Hoop",
    Icon: SportsBasketballOutlined,
    category: "material4",
  },
  {
    id: "sports-cricket-material",
    name: "Cricket Bat and Ball",
    Icon: SportsCricketOutlined,
    category: "material4",
  },
  {
    id: "sports-esports-material",
    name: "ESports Controller",
    Icon: SportsEsportsOutlined,
    category: "material4",
  },
  {
    id: "sports-football-material",
    name: "Football Soccer Ball",
    Icon: SportsFootballOutlined,
    category: "material4",
  },
  {
    id: "sports-golf-material",
    name: "Golf Club and Ball",
    Icon: SportsGolfOutlined,
    category: "material4",
  },
  {
    id: "sports-gymnastics-material",
    name: "Gymnastics Icon Sports Athletic",
    Icon: SportsGymnasticsOutlined,
    category: "material4",
  },
  {
    id: "sports-handball-material",
    name: "Handball Icon Sports Athletic",
    Icon: SportsHandballOutlined,
    category: "material4",
  },
  {
    id: "sports-hockey-material",
    name: "Hockey Icon Sports Athletic",
    Icon: SportsHockeyOutlined,
    category: "material4",
  },
  {
    id: "sports-kabaddi-material",
    name: "Kabaddi Icon Sports Athletic",
    Icon: SportsKabaddiOutlined,
    category: "material4",
  },
  {
    id: "sports-martial-arts-material",
    name: "Martial Arts Icon Sports Athletic",
    Icon: SportsMartialArtsOutlined,
    category: "material4",
  },
  {
    id: "sports-motorsports-material",
    name: "Motorsports Icon Sports Athletic",
    Icon: SportsMotorsportsOutlined,
    category: "material4",
  },
  {
    id: "sports-rugby-material",
    name: "Rugby Icon Sports Athletic",
    Icon: SportsRugbyOutlined,
    category: "material4",
  },
  {
    id: "sports-score-material",
    name: "Sports Score Icon Athletic",
    Icon: SportsScoreOutlined,
    category: "material4",
  },
  {
    id: "sports-soccer-material",
    name: "Soccer Icon Sports Athletic",
    Icon: SportsSoccerOutlined,
    category: "material4",
  },
  {
    id: "sports-tennis-material",
    name: "Tennis Icon Sports Athletic",
    Icon: SportsTennisOutlined,
    category: "material4",
  },
  {
    id: "sports-volleyball-material",
    name: "Volleyball Icon Sports Athletic",
    Icon: SportsVolleyballOutlined,
    category: "material4",
  },
  {
    id: "star-outline-material",
    name: "Outline Star Icon",
    Icon: StarOutlineOutlined,
    category: "material4",
  },
  {
    id: "subway-material",
    name: "Subway Transport",
    Icon: SubwayOutlined,
    category: "material4",
  },
  {
    id: "support-agent-material",
    name: "Support Agent Icon",
    Icon: SupportAgentOutlined,
    category: "material4",
  },
  {
    id: "swipe-down-material",
    name: "Swipe Down Gesture",
    Icon: SwipeDownOutlined,
    category: "material4",
  },
  {
    id: "swipe-left-material",
    name: "Swipe Left Gesture",
    Icon: SwipeLeftOutlined,
    category: "material4",
  },
  {
    id: "swipe-material",
    name: "Swipe Gesture",
    Icon: SwipeOutlined,
    category: "material4",
  },
  {
    id: "swipe-right-material",
    name: "Swipe Right Gesture",
    Icon: SwipeRightOutlined,
    category: "material4",
  },
  {
    id: "swipe-vertical-material",
    name: "Vertical Swipe Gesture",
    Icon: SwipeVerticalOutlined,
    category: "material4",
  },
  {
    id: "tablet-android-material",
    name: "Android Tablet",
    Icon: TabletAndroidOutlined,
    category: "material4",
  },
  {
    id: "theater-comedy-material",
    name: "Comedy Theater",
    Icon: TheaterComedyOutlined,
    category: "material5",
  },
  {
    id: "three-p-material",
    name: "Three P Logo",
    Icon: ThreePOutlined,
    category: "material5",
  },
  {
    id: "thumbs-up-down-material",
    name: "Thumbs Up Down Icon",
    Icon: ThumbsUpDownOutlined,
    category: "material5",
  },
  {
    id: "thunderstorm-material",
    name: "Thunderstorm Weather",
    Icon: ThunderstormOutlined,
    category: "material5",
  },
  {
    id: "touch-app-material",
    name: "Touch App Icon",
    Icon: TouchAppOutlined,
    category: "material5",
  },
  {
    id: "traffic-material",
    name: "Traffic Sign",
    Icon: TrafficOutlined,
    category: "material5",
  },
  {
    id: "travel-explore-material",
    name: "Travel Explore Icon",
    Icon: TravelExploreOutlined,
    category: "material5",
  },
  {
    id: "trending-up-material",
    name: "Trending Up Icon",
    Icon: TrendingUpOutlined,
    category: "material5",
  },
  {
    id: "troubleshoot-material",
    name: "Troubleshoot Icon",
    Icon: TroubleshootOutlined,
    category: "material5",
  },
  {
    id: "tune-material",
    name: "Tune Icon",
    Icon: TuneOutlined,
    category: "material5",
  },
  {
    id: "two-wheeler-material",
    name: "Bike Motorcycle Scooter",
    Icon: TwoWheelerOutlined,
    category: "material5",
  },
  {
    id: "uturn-left-material",
    name: "Left U-Turn Arrow",
    Icon: UTurnLeftOutlined,
    category: "material5",
  },
  {
    id: "uturn-right-material",
    name: "Right U-Turn Arrow",
    Icon: UTurnRightOutlined,
    category: "material5",
  },
  {
    id: "vape-free-material",
    name: "No Vaping Allowed",
    Icon: VapeFreeOutlined,
    category: "material5",
  },
  {
    id: "vape-rooms-material",
    name: "Vaping Rooms",
    Icon: VapingRoomsOutlined,
    category: "material5",
  },
  {
    id: "video-camera-front-material",
    name: "Front-Facing Video Camera",
    Icon: VideoCameraFrontOutlined,
    category: "material5",
  },
  {
    id: "videocam-material",
    name: "Video Camera",
    Icon: VideocamOutlined,
    category: "material5",
  },
  {
    id: "villa-material",
    name: "Luxury Villa House",
    Icon: VillaOutlined,
    category: "material5",
  },
  {
    id: "voicemail-material",
    name: "Voice Mailbox",
    Icon: VoicemailOutlined,
    category: "material5",
  },
  {
    id: "vpn-key-material",
    name: "VPN Security Key",
    Icon: VpnKeyOutlined,
    category: "material5",
  },
  {
    id: "watch-material",
    name: "Wrist Watch Timepiece",
    Icon: WatchOutlined,
    category: "material5",
  },
  {
    id: "waves-material",
    name: "Ocean Waves Water",
    Icon: WavesOutlined,
    category: "material5",
  },
  {
    id: "wind-power-material",
    name: "Wind Power Turbine",
    Icon: WindPowerOutlined,
    category: "material5",
  },
  {
    id: "window-material",
    name: "Glass Window Pane",
    Icon: WindowOutlined,
    category: "material5",
  },
  {
    id: "zoom-in-material",
    name: "Zoom In Magnifying Glass",
    Icon: ZoomInOutlined,
    category: "material5",
  },
  {
    id: "zoom-out-material",
    name: "Zoom Out Magnifying Glass",
    Icon: ZoomOutOutlined,
    category: "material5",
  },
  //--append new material icons here

  //-- material -- end here
];

export const getIcon = (id) => {
  if (id) {
    const result = _.find(IconsList, ["id", id]);
    return result.Icon;
  } else {
    return null;
  }
};
