import PlanModal from "components/account/plan-modal";
import QuickSupport from "components/campaigns/quick-support/quick-support";
import Alerts from "components/global/alerts";
import Footer from "components/global/footer";
import Header from "components/global/header";
import SideBar from "components/global/sidebar";
import VerifyEmailModal from "components/verify-email/verify-email";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import userActions from "redux/modules/user/actions";
import { useDialogHook } from "utils/customhooks";

const hidesInUrl = ["campaign", "campaign-condition", "dashboard/account"];

const Layout = ({
  children,
  title = null,
  routeName = "",
  userData,
  setUserData,
  noPadding = false,
  scrollYPosition = () => {},
}) => {
  const { http } = global.services;
  const planModal = useDialogHook(PlanModal);
  const emailVerify = useDialogHook(VerifyEmailModal);
  const { currentPlan, setCurrentPlan } = useContext(Context);
  const [loading, setLoading] = useState(true);

  const showPlanModal = () => {
    planModal(
      { userData: userData, setUserData: setUserData, buttonText: "SIGN UP" },
      (result) => {
        if (result) {
          window.location.href = "/dashboard";
        }
      }
    );
  };

  const checkEmailIfVerified = () => {
    return _.isEmpty(userData?.user?.email_verified_at);
  };

  const getCurrentPlan = async () => {
    setLoading(true);
    try {
      const result = await http.get(`subscriptions/${userData.user.id}`);
      const data = result.data.data;
      if (
        _.isEmpty(data) &&
        !userData?.user?.disable_billing &&
        userData?.user?.new_customer
      ) {
        showPlanModal();
      } else {
        setCurrentPlan(data);
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
      if (
        error.status === 403 &&
        userData?.user?.new_customer &&
        !userData?.user?.disable_billing
      ) {
        showPlanModal();
      }
    } finally {
      setLoading(false);
    }
  };

  //check is user has already a plan
  useEffect(() => {
    if (!userData) return;

    if (userData?.user?.role !== "admin") {
      if (checkEmailIfVerified()) {
        emailVerify(
          { userData: userData, setUserData: setUserData },
          (result) => {
            if (result) {
              if (_.isEmpty(currentPlan)) {
                getCurrentPlan();
              }
            }
          }
        );
      } else {
        getCurrentPlan();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div>
      <Helmet>
        <title>{title} - ConversionCow</title>
      </Helmet>
      <div className="h-screen lg:flex w-full relative">
        <SideBar routeName={routeName} />
        <div
          className="h-screen lg:static overflow-y-auto lg:overflow-x-hidden lg:flex-grow bg-siteBg custom-scroll"
          onScroll={(e) => scrollYPosition(e)}
        >
          <Header
            title={title}
            userData={userData}
            setUserData={setUserData}
            currentPlan={currentPlan?.plan_name || "Sign Up Today"}
            loading={
              currentPlan?.plan_name
                ? false
                : userData?.user?.role !== "admin"
                ? loading
                : false
            }
          />
          {userData && <Alerts />}
          <main
            className={
              noPadding
                ? "w-full p-0 flex flex-1 relative"
                : "main-wrapper mx-auto py-8 flex flex-1 bg-siteBg relative"
            }
          >
            {userData?.user?.role !== "guest" &&
              !hidesInUrl.includes(routeName) && <QuickSupport />}
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
