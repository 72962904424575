import React from "react";
import { motion } from "framer-motion";

export default function QuickSupportPopup({ ...props }) {
  return (
    <motion.div
      className="w-24 bg-secondary h-fit absolute p-4 top-0 -left-24 bottom-0 
      text-base text-white break-words font-bold cursor-pointer my-auto hidden 7md:block z-40 mb-10 2xl:mb-auto"
      style={{ borderRadius: "20px 0px 0px 20px" }}
      {...props}
    >
      {props.children}
    </motion.div>
  );
}
