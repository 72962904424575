import ContactEmptySVG from "assets/svg/contact-empty-svg";

const defaultStyle = {
  height: "calc(100vh - 279px)",
};

const EmptyContact = ({
  style = defaultStyle,
  text = "You have no contacts yet",
}) => {
  return (
    <div className="grid h-full" style={style}>
      <div className="m-auto">
        <ContactEmptySVG />
        <div className="text-center">
          <p className="pt-6 text-xl font-medium">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default EmptyContact;
