import React from "react";

const TermsConditions = (props) => {
  return (
    <div>
      <p>TermsConditions</p>
    </div>
  );
};

export default TermsConditions;
