import { isValid, parseJSON } from "date-fns";
import _ from "lodash";
import moment from "moment";

const PopoutDetails = ({
  children,
  data,
  isActive,
  abTest,
  abTestDate,
  abOngoing,
  hideStatus = false,
  title = "",
  statusDisplay = "lower",
  isBase = false,
}) => {
  const condition = JSON.parse(data?.popup_condition || "{}");
  const test = JSON.parse(data?.test_condition || "{}");
  const upperDisplay = statusDisplay === "upper";
  const abTestClass = abOngoing
    ? "text-white bg-primary"
    : "text-darkerGray bg-default";

  const formatDate = (date) => {
    const parsed = parseJSON(date);
    return isValid(parsed) ? parsed.toLocaleDateString() : "Invalid End Date";
  };

  return (
    <div className={`flex flex-col items-start ${upperDisplay ? "pr-10" : ""}`}>
      {upperDisplay && (
        <div className="mb-2" data-cc="record-status">
          {!hideStatus && (
            <span
              className={`px-2 py-2 text-sm font-bold text-white capitalize rounded-lg ${
                isActive ? "bg-lightGreen" : "bg-lightRed"
              }`}
            >
              {isActive ? "Active" : "Inactive"}
            </span>
          )}
          {abTest && (
            <span
              className={`px-2 py-2 ml-2 text-sm font-bold capitalize rounded-lg ${abTestClass}`}
            >
              A/B Test
            </span>
          )}
        </div>
      )}
      <h2 className="flex items-center text-xl" data-cc="record-title">
        <span>{condition?.title || title || "Untitled"}</span>
        {!upperDisplay && (
          <div className="ml-2" data-cc="record-title-display-status">
            {!hideStatus && (
              <span
                className={`px-2 py-2 text-sm font-bold text-white capitalize rounded-lg ${
                  isActive ? "bg-lightGreen" : "bg-lightRed"
                }`}
              >
                {isActive ? "Active" : "Inactive"}
              </span>
            )}
            {abTest && (
              <span
                className={`px-2 py-2 ml-2 text-sm font-bold capitalize rounded-lg ${abTestClass}`}
              >
                A/B Test
              </span>
            )}
          </div>
        )}
      </h2>
      {data?.label && (
        <h3 className="font-bold text-md" data-cc="record-label">
          {data?.label.name}:{" "}
          <span data-cc="record-label-value" className="font-extralight">
            {data?.label.value}
          </span>
        </h3>
      )}
      <div data-cc="record-content">
        {(condition.content || []).map((obj, index) => {
          if (obj.type === "timezone") {
            return (
              <p key={index} className="text-base text-black">
                <span className="font-bold">{obj.condition}:&nbsp;</span>
                {(obj?.value?.military_time || []).map((value, index) => {
                  const formatDateString = `${moment().format(
                    "YYYY-MM-DD"
                  )} ${value}`;
                  const formattedDate = new Date(formatDateString);
                  const date = moment(formattedDate);
                  return (
                    <span key={index}>
                      {moment(date).format("h:mm a")}
                      {index === 0 && " - "}
                      {index === 1 && ` (${obj.timezone})`}
                    </span>
                  );
                })}
              </p>
            );
          } else if (obj.type === "weekdays") {
            return (
              <p key={index} className="text-base text-black">
                <span className="font-bold">{obj.condition}:&nbsp;</span>

                {obj?.value.map((day, index) => (
                  <span key={index} className="capitalize">
                    {day}
                    {`${index + 1 < obj.value.length ? ", " : ""}`}
                  </span>
                ))}

                {obj.timezone && <span> ({obj.timezone})</span>}
              </p>
            );
          } else if (obj.type === "daterange") {
            return (
              <p key={index} className="text-base text-black">
                <span className="font-bold">{obj.condition}:&nbsp;</span>

                <span>
                  {formatDate(obj?.value?.startDate)} -{" "}
                  {formatDate(obj?.value?.endDate)}
                </span>

                {obj.timezone && <span> ({obj.timezone})</span>}
              </p>
            );
          } else if (obj.type === "internal-url") {
            if (!_.isEmpty(obj.condition) && !_.isEmpty(obj?.value)) {
              return (
                <p
                  key={index}
                  className="text-base text-black whitespace-nowrap"
                >
                  <span className="font-bold">{obj.condition}:&nbsp;</span>
                  <a
                    href={obj?.value}
                    target="_blank"
                    rel="noreferrer"
                    className="text-secondary"
                  >
                    {_.truncate(obj.value, {
                      length: 55,
                      separator: "...",
                    })}
                  </a>
                </p>
              );
            } else {
              return null;
            }
          } else if (obj.type === "referral-domain") {
            if (!_.isEmpty(obj.condition) && !_.isEmpty(obj?.value)) {
              return (
                <p
                  key={index}
                  className="text-base text-black whitespace-nowrap"
                >
                  <span className="font-bold">{obj.condition}:&nbsp;</span>
                  {_.truncate(obj.value, {
                    length: 55,
                    separator: ",",
                  })}
                </p>
              );
            } else {
              return null;
            }
          } else {
            if (!_.isEmpty(obj.condition) && !_.isEmpty(obj?.value)) {
              return (
                <p key={index} className="text-base text-black">
                  <span className="font-bold">{obj.condition}:&nbsp;</span>
                  <span>{obj?.value}</span>
                </p>
              );
            } else {
              return null;
            }
          }
        })}
      </div>
      <div
        className={`flex space-x-2 ${isBase ? "mt-2" : ""}`}
        data-cc="popup-details"
      >
        {data.popup_a ? (
          abTest ? (
            <p className="text-xs text-mutedGray">
              Popout A: {data.popup_a.title}, Popout B: {data.popup_b.title}
            </p>
          ) : (
            <p className="text-xs text-mutedGray">
              Popout: {data.popup_a.title}
            </p>
          )
        ) : (
          <p className="text-xs text-mutedGray">Popout: {data.title}</p>
        )}
      </div>
      <div className="flex space-x-2" data-cc="record-last-saved">
        <p className="text-xs text-mutedGray">
          Last Saved {moment(new Date(data.updated_at)).fromNow()}
        </p>
        {abTest && (
          <>
            <span className="text-xs text-mutedGray">&#x2022;</span>
            <p className="text-xs text-mutedGray">
              {`A/B Test ${abOngoing ? "ends" : "completed"} on ${
                test?.value
              } ${test?.type?.label}`}
            </p>
          </>
        )}
      </div>
      <div
        className="flex items-start mt-3 pr-4"
        data-cc="record-action-buttons"
      >
        {children}
      </div>
    </div>
  );
};

export default PopoutDetails;
