const PhoneIconSVG = ({ className = "", fill = "#808080" }) => (
  <svg
    className={className}
    width="28"
    height="27"
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.8528 11.738C26.0216 10.6043 25.965 9.44839 25.6861 8.33663C25.3055 6.81742 24.5185 5.43049 23.4094 4.32463C22.3036 3.21538 20.9167 2.42812 19.3974 2.0473C18.2856 1.76876 17.1298 1.71235 15.9961 1.8813M20.7928 11.202C20.8859 10.5832 20.8323 9.95118 20.6364 9.3569C20.4405 8.76261 20.1077 8.22268 19.6648 7.78063C19.2226 7.33786 18.6827 7.00516 18.0884 6.80934C17.4941 6.61353 16.8622 6.56008 16.2434 6.6533"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.58423 3.86255C7.82206 3.86267 8.05552 3.92641 8.26043 4.04714C8.46533 4.16788 8.63421 4.34123 8.74956 4.54922L10.3809 7.48655C10.4861 7.67611 10.5436 7.88848 10.5482 8.10525C10.5529 8.32202 10.5046 8.53665 10.4076 8.73055L8.83623 11.8725C8.83623 11.8725 9.29156 14.2139 11.1969 16.1192C13.1029 18.0246 15.4362 18.4725 15.4362 18.4725L18.5776 16.9012C18.7716 16.8042 18.9864 16.7559 19.2033 16.7607C19.4202 16.7655 19.6326 16.8231 19.8222 16.9285L22.7689 18.5665C22.9765 18.6822 23.1494 18.8511 23.2698 19.056C23.3902 19.2609 23.4536 19.4943 23.4536 19.7319V23.1146C23.4536 24.8366 21.8536 26.0812 20.2216 25.5299C16.8696 24.3992 11.6669 22.2452 8.36823 18.9479C5.07089 15.6499 2.91756 10.4465 1.78622 7.09455C1.23556 5.46255 2.47956 3.86255 4.20223 3.86255H7.58423V3.86255Z"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhoneIconSVG;
