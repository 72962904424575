import LoadingIndicator from "assets/svg/loading-indicator";
import React from "react";

const PageLoader = ({ title, message, visible = true }) => {
  return (
    visible && (
      <div className="h-screen w-screen bg-black opacity-70">
        <div className="inset-0 absolute flex flex-col items-center justify-center">
          <LoadingIndicator className="text-white h-14 w-14" />
          <p className="text-white text-xl mt-2">{title}</p>
          <p className="text-white text-sm">{message}</p>
        </div>
      </div>
    )
  );
};

export default PageLoader;
