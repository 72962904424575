import { motion } from "framer-motion";
import { FaTimes } from "react-icons/fa";
import ToggleDesktopIcon from "assets/svg/toggle-desktop-icon";
import ToggleMobileIcon from "assets/svg/toggle-mobile-icon";

export default function Floater({
  buttonTransistion = {},
  view = "web",
  setView = () => {},
  onClose = () => {},
}) {
  const { parent } = buttonTransistion;

  return (
    <div className="mb-78">
      <motion.button
        initial="initial"
        variants={parent}
        whileHover={parent.show}
        onClick={onClose}
        className="bg-white w-45 h-12.5 rounded-r-lg text-toggleColor hover:text-black"
      >
        <div className="w-full flex items-center justify-between px-4">
          <p className="text-lg tracking-normal-wide text-black">
            Close Preview
          </p>
          <FaTimes className="text-xl" />
        </div>
      </motion.button>
      {/* toggle buttons */}
      <motion.div
        initial="initial"
        variants={parent}
        whileHover={parent.show}
        className="bg-white w-45 h-25 rounded-r-lg flex items-center mt-3.5"
      >
        <div className="w-full">
          <button className="w-full py-2" onClick={() => setView("web")}>
            <div className="w-full flex items-center justify-between px-4">
              <p
                className={`text-lg tracking-normal-wide ${
                  view === "web" ? "text-black" : "text-toggleColor"
                }`}
              >
                Desktop View
              </p>
              <ToggleDesktopIcon
                className={view === "web" ? "text-black" : "text-toggleColor"}
              />
            </div>
          </button>
          <button className="w-full py-2" onClick={() => setView("mobile")}>
            <div className="w-full flex items-center justify-between px-4">
              <p
                className={`text-lg tracking-normal-wide ${
                  view === "mobile" ? "text-black" : "text-toggleColor"
                }`}
              >
                Mobile View
              </p>
              <ToggleMobileIcon
                className={
                  view === "mobile" ? "text-black mr-1" : " text-toggleColor"
                }
              />
            </div>
          </button>
        </div>
      </motion.div>
    </div>
  );
}
