import QuoteSVG from "assets/svg/quote";
import ProfileShortDetails from "components/profile-short-details/profile-short-details";
import Ratings from "components/ratings/ratings";
import { LogoWhite } from "constants/images";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";

const TwoColumnLayout = ({
  children,
  imageBanner = null,
  seo = {
    title: "ConversionCow",
  },
}) => {
  const history = useHistory();
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    dotsClass: "slick-dots slick-thumb",
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleClickLogo = () => {
    history.replace("/");
  };

  return (
    <div id="two-column-layout-component">
      <div
        className="banner-column"
        style={{ backgroundImage: `url(${imageBanner})` }}
      >
        <div className="banner-column-overlay z-10"></div>
        <div
          className="column-content-logo relative cursor-pointer"
          style={{ zIndex: 99 }}
          onClick={handleClickLogo}
        >
          <img
            src={LogoWhite}
            alt="two-column-layout-component-brand-logo"
            className="w-12 2md:w-14 lg:w-20 2xl:w-24 3xl:w-32 -ml-2"
          />
        </div>

        <Slider
          {...settings}
          className="md:absolute md:inset-0 justify-center flex flex-col relative z-10"
        >
          <div className="column-content-wrapper">
            <QuoteSVG className="column-content-quote" />
            <h3 className="column-content-text">
              I can't believe how fast it was to get it up and running!
            </h3>
            <div>
              <Ratings />
              <ProfileShortDetails
                profileImage=""
                containerClass="pt-4"
                gap={4}
                detailsTextColor={"white"}
                name="Lauren Siegel"
                subtitle="Match Alliance"
              />
            </div>
          </div>
          <div className="column-content-wrapper">
            <QuoteSVG className="column-content-quote" />
            <h3 className="column-content-text">
              Within just 2 weeks of signing up, we were amazed to secure 2
              leads!
            </h3>
            <div>
              <Ratings />
              <ProfileShortDetails
                profileImage=""
                containerClass="pt-4"
                gap={4}
                detailsTextColor={"white"}
                name="Nathaniel Garvin"
                subtitle="Digital Agency Owner"
              />
            </div>
          </div>
        </Slider>
      </div>
      <div className="content-column">
        <div className="content-wrapper">{children}</div>
      </div>
    </div>
  );
};

export default TwoColumnLayout;
