import _ from "lodash";
import PopoutA from "./table-statistics/popout-a";
import PopoutB from "./table-statistics/popout-b";

export default function PopoutData({ data, abTest = false, abOngoing = true }) {
  const getWinner = () => {
    let result = "A";
    if (!_.isEmpty(data?.preferred_popup)) {
      if (data.preferred_popup.id === data.popup_a_id) {
        result = "A";
      } else if (data.preferred_popup.id === data.popup_b_id) {
        result = "B";
      }
    }
    return result;
  };
  return (
    <div className="flex flex-col space-y-5">
      {/* POPUP A */}
      <PopoutA
        index={`popup-a-test-${data.popup_a_id}`}
        abOngoing={abOngoing}
        data={{ ...data.popup_a, summary: data.popup_a_summary }}
        abTest={abTest}
        type="A"
        winner={getWinner()}
      />

      {/* POPUP B */}
      {data.popup_b_id !== null && (
        <PopoutB
          index={`popup-a-test-${data.popup_b_id}`}
          abOngoing={abOngoing}
          data={{ ...data.popup_b, summary: data.popup_b_summary }}
          type="B"
          winner={getWinner()}
        />
      )}
    </div>
  );
}
