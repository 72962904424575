const CampaignModalSVG = () => {
  return (
    <svg
      width="300"
      height="192"
      viewBox="0 0 300 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="300" height="16.3934" fill="#EDEDED" />
      <rect
        width="300"
        height="168.852"
        transform="matrix(1 0 0 -1 0 185.246)"
        fill="white"
      />
      <rect
        x="108.197"
        y="26.2295"
        width="90.1639"
        height="147.541"
        fill="#EDEDED"
      />
      <rect
        x="198.361"
        y="26.2295"
        width="88.5246"
        height="147.541"
        fill="#F7F7F7"
      />
      <rect
        x="206.557"
        y="37.7051"
        width="72.1311"
        height="14.7541"
        fill="white"
      />
      <rect
        x="206.557"
        y="60.6558"
        width="72.1311"
        height="14.7541"
        fill="white"
      />
      <rect
        x="206.557"
        y="83.6064"
        width="72.1311"
        height="14.7541"
        fill="white"
      />
      <rect
        x="206.557"
        y="106.558"
        width="72.1311"
        height="14.7541"
        fill="white"
      />
      <rect
        x="206.557"
        y="131.147"
        width="72.1311"
        height="14.7541"
        fill="#EC5627"
      />
      <rect
        x="213.115"
        y="42.623"
        width="29.5082"
        height="3.27869"
        fill="#EDEDED"
      />
      <rect
        x="211.475"
        y="67.2129"
        width="29.5082"
        height="3.27869"
        fill="#EDEDED"
      />
      <rect
        x="211.475"
        y="90.1641"
        width="29.5082"
        height="3.27869"
        fill="#EDEDED"
      />
      <rect
        x="211.475"
        y="111.476"
        width="29.5082"
        height="3.27869"
        fill="#EDEDED"
      />
      <rect
        x="75.4091"
        y="81.0796"
        width="85.2459"
        height="49.1803"
        transform="rotate(-15 75.4091 81.0796)"
        fill="#CECECE"
      />
      <rect
        x="87.3699"
        y="87.688"
        width="30.13"
        height="4.63539"
        transform="rotate(-15 87.3699 87.688)"
        fill="white"
      />
      <rect
        x="89.5691"
        y="95.8965"
        width="67.2131"
        height="4.63539"
        transform="rotate(-15 89.5691 95.8965)"
        fill="white"
      />
      <rect
        x="91.7687"
        y="104.105"
        width="67.2131"
        height="4.63539"
        transform="rotate(-15 91.7687 104.105)"
        fill="white"
      />
      <rect
        x="93.9675"
        y="112.313"
        width="44.0362"
        height="4.63539"
        transform="rotate(-15 93.9675 112.313)"
        fill="white"
      />
      <g clipPath="url(#clip0_1579_20205)">
        <path
          d="M209.256 159.721L151.439 106.558L151.401 185.101L168.945 168.314L179.25 191.804L195.327 184.751L185.022 161.262L209.256 159.721Z"
          fill="#E7B44B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1579_20205">
          <rect
            width="85.2459"
            height="85.2459"
            fill="white"
            transform="translate(137.705 106.558)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CampaignModalSVG;
