import ImageIconSVG from "assets/svg/image-icon";
import SwitchAboveIconSVG from "assets/svg/switch-above-icon";
import SwitchBelowIconSVG from "assets/svg/switch-below-icon";
import ToggleDesktopIcon from "assets/svg/toggle-desktop-icon";
import ToggleMobileIcon from "assets/svg/toggle-mobile-icon";
import VideoLinkIconSVG from "assets/svg/video-link-icons";
import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import TicksPointsIconSVG from "assets/svg/ticks-points-icon";
import "./styles.css";
import _ from "lodash";

const icons = {
  default: {
    active: <ToggleMobileIcon className="absolute left-2.5 top-2 text-black" />,
    inactive: (
      <ToggleDesktopIcon className="absolute left-2 top-2.5 text-black" />
    ),
    activeMirror: (
      <ToggleDesktopIcon className="absolute top-4 left-3 text-tabTextColor" />
    ),
    inactiveMirror: (
      <ToggleMobileIcon className="absolute bottom-3.5 left-3.5 text-tabTextColor" />
    ),
  },
  switch: {
    active: <SwitchBelowIconSVG className="absolute top-0.5 -ml-5 w-5" />,
    activeMirror: <SwitchAboveIconSVG className="absolute top-0.5 -ml-5 w-5" />,
  },
  preview: {
    active: <ToggleMobileIcon className="absolute top-0.5 -ml-4" />,
    activeMirror: <ToggleDesktopIcon className="absolute top-1 -ml-5" />,
  },
  promotions: {
    active: null,
    inactive: null,
    activeMirror: (
      <TicksPointsIconSVG className="absolute -ml-6 w-6" selected={true} />
    ),
    inactiveMirror: <TicksPointsIconSVG className="absolute -ml-6 w-6" />,
  },
  ticks: {
    active: null,
    activeMirror: (
      <IoCheckmarkCircleOutline className="absolute top-0.5 -ml-5 text-base w-5" />
    ),
  },
  medias: {
    active: <VideoLinkIconSVG className="absolute -ml-6 top-0.5 w-6" />,
    inactive: <VideoLinkIconSVG className="absolute -ml-6 top-0.5 w-6" />,
    activeMirror: <ImageIconSVG className="absolute -ml-6" />,
    inactiveMirror: <ImageIconSVG className="absolute -ml-6" />,
  },
};

const bg = {
  default: {
    active: "bg-lightGreen",
    inactive: "bg-toggleBg",
  },
  vertical: {
    active: "bg-toggleVBg",
    inactive: "bg-toggleVBg",
  },
  bgdefault: {
    active: "bg-default",
    inactive: "bg-default",
  },
  bgblack: {
    active: "bg-black",
    inactive: "bg-black",
  },
  bgbilling: {
    active: "bg-billing",
    inactive: "bg-billing",
  },
};

const billingActive = "billing-inner-h-w ml-auto mr-2.5 rounded-full bg-white";
const billingInActive = "billing-inner-h-w ml-2.5 rounded-full bg-white";
const currencyActive = "w-16 h-8 ml-auto mr-1.5 rounded-full bg-white";
const currencyInActive = "w-16 h-8 ml-1.5 rounded-full bg-white";

const ToggleField = (
  {
    className = "w-16 h-8",
    autoFocus = false,
    name = "",
    checked = false,
    disabled = false,
    errorMessage = null,
    orientation = "horizontal",
    custom = null,
    labelLeft = "",
    labelRight = "",
    rightExtra = null,
    icon = "default",
    background = "default",
    hActiveClass = "w-6 h-6 ml-auto mr-1 rounded-full bg-white",
    hInactiveClass = "w-6 h-6 ml-1 rounded-full bg-white",
    cActiveClass = "w-28 h-8 ml-auto mr-1 rounded-full bg-white",
    cInactiveClass = "w-28 h-8 ml-1 rounded-full bg-white",
    vActiveClass = "relative w-8 h-8 mx-1 mt-9 rounded-full bg-white",
    vInactiveClass = "relative w-8 h-8 mx-1 mb-9 rounded-full bg-white",
    hoverEffects = "hover-effects",
    onChange = () => {},
    ...props // make sure this pops here is for input only
  },
  ref
) => {
  const bgColors = checked ? bg[background]?.active : bg[background]?.inactive;

  const mirrorIcon = checked
    ? icons[icon].activeMirror
    : icons[icon].inactiveMirror;

  const customDesigns = () => {
    if (custom === "popup") {
      return (
        <span
          className={`toggle-container ${
            checked ? cActiveClass : cInactiveClass
          }`}
        >
          <span
            className={`absolute left-8 top-2.5 text-sm ${hoverEffects}-left ${
              checked && "text-toggleColor pointer-effects"
            }`}
            onClick={() => onChange({ target: { name: name, checked: true } })}
          >
            {checked ? icons[icon].activeMirror : icons[icon].activeMirror}
            {labelLeft}
          </span>
          <span
            className={`absolute ${
              icons[icon].active ? "right-5" : "right-7"
            } top-2.5 text-sm ${hoverEffects}-right ${
              !checked && "text-toggleColor pointer-effects"
            }`}
            onClick={() => onChange({ target: { name: name, checked: false } })}
          >
            {checked ? icons[icon].active : icons[icon].active}
            {labelRight}
          </span>
        </span>
      );
    } else if (custom === "promotions") {
      return (
        <span
          className={`toggle-container ${
            checked ? cActiveClass : cInactiveClass
          }`}
        >
          <span
            className={`absolute left-9 top-2.5 text-sm hover-effects-left  ${
              checked && "text-toggleColor pointer-effects"
            }`}
            onClick={() => onChange({ target: { name: name, checked: false } })}
          >
            {checked ? icons[icon].inactiveMirror : icons[icon].activeMirror}
            {labelLeft}
          </span>
          <span
            className={`absolute ${
              icons[icon].active ? "right-5" : "right-7"
            } top-2.5 text-sm hover-effects-right ${
              !checked && "text-toggleColor pointer-effects"
            }`}
            onClick={() => onChange({ target: { name: name, checked: true } })}
          >
            {checked ? icons[icon].active : icons[icon].inactive}
            {labelRight}
          </span>
        </span>
      );
    } else if (custom === "billing") {
      return (
        <span
          className={`toggle-container-billing ${
            checked ? billingActive : billingInActive
          }`}
        >
          <span
            className={`absolute left-0 right-11/20 top-6 text-sm text-center font-bold ${
              checked && "text-black"
            }`}
            onClick={() => onChange({ target: { name: name, checked: true } })}
          >
            {labelLeft}
          </span>
          <span
            className={`absolute left-11/20 right-0 top-6 text-sm text-center font-bold ${
              !checked && "text-black"
            }`}
            onClick={() => onChange({ target: { name: name, checked: false } })}
          >
            {labelRight}
            <span className="pl-2.5 text-secondary">{rightExtra}</span>
          </span>
        </span>
      );
    } else if (custom === "currency") {
      return (
        <span
          className={`toggle-container-billing ${
            checked ? currencyActive : currencyInActive
          }`}
        >
          <span
            className={`absolute left-4 top-3 text-sm hover-effects-left  ${
              checked ? "text-black pointer-effects font-normal" : "font-bold"
            }`}
            onClick={() => onChange({ target: { name: name, checked: false } })}
          >
            {labelLeft}
          </span>
          <span
            className={`absolute right-4 top-3 text-sm hover-effects-right ${
              !checked ? "text-black pointer-effects font-normal" : "font-bold"
            }`}
            onClick={() => onChange({ target: { name: name, checked: true } })}
          >
            {labelRight}
          </span>
        </span>
      );
    } else {
      return (
        <span className={checked ? hActiveClass : hInactiveClass}>
          {labelLeft}
        </span>
      );
    }
  };

  return (
    <>
      {errorMessage && (
        <span className="text-red text-xs mb-0.5">* {errorMessage}</span>
      )}
      <label
        className={`${
          orientation === "horizontal" ? className : "h-20"
        } rounded-full flex justify-items-start items-center my-1 relative cursor-pointer ${
          disabled ? "opacity-30" : ""
        } ${bgColors} ${errorMessage && "border border-red"}`}
      >
        {orientation === "vertical" && mirrorIcon}

        <input
          type="checkbox"
          className="hidden"
          disabled={disabled}
          defaultChecked={checked}
          name={name}
          autoFocus={autoFocus}
          onChange={(e) => {
            if (orientation === "horizontal") {
              if (_.isEmpty(custom)) {
                onChange(e);
              }
            } else if (orientation === "vertical") {
              onChange(e);
            }
          }}
          ref={ref}
          {...props}
        />
        {orientation === "horizontal" ? (
          customDesigns()
        ) : (
          <span className={checked ? vActiveClass : vInactiveClass}>
            {checked ? icons[icon].active : icons[icon].inactive}
          </span>
        )}
      </label>
    </>
  );
};

const Toggle = React.forwardRef(ToggleField);

export default Toggle;
