const SortingIconSVG = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.80952L4.80952 1M4.80952 1L8.61905 4.80952M4.80952 1V15.8571"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.38098 12.0477L13.1905 15.8572M13.1905 15.8572L17 12.0477M13.1905 15.8572V1.00004"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SortingIconSVG;
