import "./notification-list.css";
const NotificationList = ({ className, children, ...props }) => {
  const classes = [
    "notification-list flex flex-col bg-lightGray shadow-2xl rounded overflow-y-auto",
    className,
  ].join(" ");
  return (
    <div className={classes} {...props}>
      <ul>{children}</ul>
    </div>
  );
};

export default NotificationList;
