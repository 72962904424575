import { Context } from "context/context";
import { useContext } from "react";
import CampaignPopupTemplateType from "../popup/campaign-popup-template-type";
import PromotionsTab from "./templates/promotions-tab";
import ProofPointsTab from "./templates/proof-points-tab";
import StatisticsDataTab from "./templates/statistics-data-tab";
import TestimonialsTab from "./templates/testimonials-tab";
import VideosTab from "./templates/videos-tab";

export default function PopupExampleTemplate({
  viewExample = false,
  animation,
  peep = false,
}) {
  const { popup, selectedTab } = useContext(Context);
  const masked = selectedTab === "contactM" || selectedTab === "contactD";
  return (
    <div
      className={
        viewExample
          ? "hidden 4md:block 4md:relative 4md:h-full 5md:hidden 3lg:block 3lg:relative 3lg:h-full"
          : "relative h-full"
      }
      style={{
        width: peep ? "45px" : "370px",
        filter: masked && "blur(2px)",
      }}
    >
      {(selectedTab === "template" || selectedTab === "content") && (
        <CampaignPopupTemplateType
          type={popup?.template_type || "proof-points"}
        />
      )}
      <div className={`popup-template ${animation}`}>
        {/* masked */}
        {masked && (
          <div>
            <div className="h-full w-1 bg-lightGray absolute -right-0.5"></div>
            <div className="absolute top-0 right-0 contents-bg w-full h-full"></div>
          </div>
        )}
        {/* end: masked */}
        {popup?.template_type === "proof-points" && (
          <ProofPointsTab popup={popup} />
        )}
        {popup?.template_type === "testimonials" && (
          <TestimonialsTab popup={popup} />
        )}
        {popup?.template_type === "videos" && <VideosTab popup={popup} />}
        {popup?.template_type === "promotions" && (
          <PromotionsTab popup={popup} />
        )}
        {popup?.template_type === "statistics-data" && (
          <StatisticsDataTab popup={popup} />
        )}
      </div>
    </div>
  );
}
