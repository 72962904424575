const CampaignDetailsEyeSVG = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0003 15.8334C8.63355 15.8504 7.28091 15.5554 6.04533 14.9709C5.08757 14.5036 4.22754 13.8582 3.51116 13.0692C2.75235 12.2535 2.15488 11.3014 1.75033 10.2634L1.66699 10L1.75449 9.73671C2.15934 8.69957 2.75552 7.74777 3.51199 6.93088C4.22811 6.14197 5.08786 5.4966 6.04533 5.02921C7.28092 4.4447 8.63356 4.14972 10.0003 4.16671C11.3671 4.14975 12.7197 4.44473 13.9553 5.02921C14.9131 5.49649 15.7731 6.14187 16.4895 6.93088C17.2497 7.74551 17.8474 8.69785 18.2503 9.73671L18.3337 10L18.2462 10.2634C16.9388 13.6665 13.6455 15.8912 10.0003 15.8334ZM10.0003 5.83338C7.16355 5.74448 4.55984 7.39596 3.43116 10C4.55966 12.6043 7.16349 14.2558 10.0003 14.1667C12.837 14.2554 15.4406 12.604 16.5695 10C15.4423 7.39469 12.8376 5.74261 10.0003 5.83338ZM10.0003 12.5C8.7981 12.508 7.75813 11.6645 7.5178 10.4865C7.27746 9.30853 7.90388 8.12506 9.01315 7.66143C10.1224 7.19779 11.4047 7.58349 12.0741 8.58213C12.7435 9.58077 12.6131 10.9134 11.7628 11.7634C11.2973 12.2344 10.6626 12.4997 10.0003 12.5Z"
      fill="black"
    />
  </svg>
);

export default CampaignDetailsEyeSVG;
