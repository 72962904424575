import styled from "styled-components";
import { device, size } from "../../screens";

const ContentItem = styled.div`
  width: 100%;
  overflow-y: hidden;
  background: #f4f4f4;
  height: 100vh;
  z-index: 999999;

  ${({ isActive }) => (isActive ? `display: inherit;` : `display: none;`)}

  @media ${device.xs} {
    width: ${size.xs};
  }

  @media (min-width: 1${({ fullW }) => fullW + 1}px) {
    background: ${({ type }) =>
      type === "contact-info" ? "#FFFFFF" : "#F4F4F4"} !important;
    width: calc(
      50% - ${({ width, overlay }) => (overlay ? width : 0)}px
    ) !important;
    display: unset !important;
  }
`;
export default ContentItem;
/**/
