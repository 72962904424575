import React from "react";
import StarSVG from "assets/svg/star";
import "./styles.css";

const Ratings = ({ ratings = 5 }) => {
  return (
    <div className="rating-wrapper">
      {Array.from(Array(5), (e, i) => {
        return <StarSVG key={i} className="rating-svg text-gold" />;
      })}
    </div>
  );
};

export default Ratings;
