import { Fragment } from "react";
import styled from "styled-components";
import TestimonialCard from "./testimonials-card";
import { device } from "../../../screens";
import HeaderTemplate from "../../commons/header-template";
import { sanitizeValueHTML } from "utils/constant";

const TestimonialsContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-wrap: wrap;

  margin-top: 28px;

  @media ${device.xs} {
    margin-top: 24px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-top: 36px;
  }
`;
const TestimonialsItem = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;

  flex-direction: row;
  align-items: center;

  padding-bottom: 28px;

  &:nth-child(2) {
    padding-top: 28px;
    border-top-width: 1px !important;
    border-color: #dbdbdb !important;
  }

  @media ${device.xs} {
    padding-bottom: 32px;

    &:nth-child(2) {
      padding-top: 32px;
    }
  }

  ${({ hidden }) => (hidden ? `display: none;` : "")}
`;

const Testimonials = ({ data }) => {
  return (
    <Fragment>
      <div style={{ width: "100%", height: "100%" }}>
        <HeaderTemplate
          family={data.font?.family}
          width={data.width}
          dangerouslySetInnerHTML={{ __html: sanitizeValueHTML(data.title) }}
        />
        <TestimonialsContainer responsive={data.width}>
          {data.testimonials.map((testimonial, testimonialIndex) => (
            <Fragment>
              {testimonial.quote && (
                <TestimonialsItem key={`testimonial-${testimonialIndex}`}>
                  <TestimonialCard
                    testimonial={testimonial}
                    width={data.width}
                  />
                </TestimonialsItem>
              )}
            </Fragment>
          ))}
        </TestimonialsContainer>
      </div>
    </Fragment>
  );
};

export default Testimonials;
