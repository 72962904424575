import numeral from "numeral";
// import { useMemo } from "react";
// import PopupContent from "components/popup/popup-content";
// import { calculatePercentage } from "helpers/converter";
// import Popup from "reactjs-popup";

export default function PopoutA({
  data,
  abTest,
  abOngoing,
  index,
  type,
  winner,
}) {
  const typeAClass = abOngoing ? "bg-secondary" : "bg-lightGreen";
  const typeBClass = abOngoing
    ? "bg-primary"
    : "bg-default text-black opacity-40";
  // const greaterThanFiftyPercent = useMemo(
  //   () => calculatePercentage(data?.summary?.leads, data?.summary?.clicks),
  //   [data]
  // );
  // const d =
  //   greaterThanFiftyPercent >= 50
  //     ? "M1 6.5L5.5 2M5.5 2L10 6.5M5.5 2V13.25"
  //     : "M1 6.75L5.5 11.25M5.5 11.25L10 6.75M5.5 11.25V0";
  // const stroke = greaterThanFiftyPercent >= 50 ? "#6FCF97" : "#EC282B";
  return (
    <div className="flex relative gap-4 items-center" key={index}>
      {type && abTest && (
        <div className={`ab-test ${winner === "A" ? typeAClass : typeBClass}`}>
          {type}
        </div>
      )}
      <div
        className={`flex divide-x divide-gray ${
          winner === "B" && !abOngoing && "opacity-40"
        }`}
      >
        <div className="pr-6">
          <h3 className="text-2xl">
            {numeral(data?.summary?.opens || 0).format("0,0")}
          </h3>
          <small className="font-extralight text-mutedGray">
            Unique Visitors
          </small>
        </div>
        <div className="px-6">
          <h3 className="text-2xl">
            {numeral(data?.summary?.clicks || 0).format("0,0")}
          </h3>
          <small className="font-extralight text-mutedGray">Unique Opens</small>
        </div>
        <div className="pl-6">
          <h3 className="flex items-center text-2xl">
            {numeral(data?.summary ? data?.summary?.interactions : 0).format(
              "0,0"
            )}
            {/* <Popup
              trigger={
                greaterThanFiftyPercent > 0 && (
                  <svg
                    width="11"
                    height="14"
                    viewBox="0 0 11 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2 cursor-pointer"
                  >
                    <path d={d} stroke={stroke} strokeWidth="2.25" />
                  </svg>
                )
              }
              position="bottom right"
              on={["hover"]}
              closeOnDocumentClick
            >
              <PopupContent>
                <div className="p-3">
                  <p className="text-bold">Higher Conversion</p>
                  <small className="text-sm font-extralight">
                    This means that this converts lower than your base popout/s.
                  </small>
                </div>
              </PopupContent>
            </Popup> */}
          </h3>
          <small className="font-extralight text-mutedGray">
            Unique Interactions
          </small>
        </div>
      </div>
    </div>
  );
}
