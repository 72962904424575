import ButtonClickIconSVG from "assets/svg/button-click-icon";
import EyeIconSVG from "assets/svg/eye-icon";
import EyeSlashIconSVG from "assets/svg/eye-slash-icon";
import LinkInputIconSVG from "assets/svg/link-input-icon";
import _ from "lodash";
import "./styles.css";

const iconClass = "absolute left-2.5 top-4";
const counterClass = "text-xs opacity-70 absolute right-2.5 top-4";

const icons = {
  default: {
    upper: <ButtonClickIconSVG className={iconClass} />,
    lower: <ButtonClickIconSVG className={iconClass} />,
  },
  contact_method: {
    upper: <ButtonClickIconSVG className={iconClass} />,
    lower: <LinkInputIconSVG className={iconClass} />,
  },
};

export default function InputDoubleField({
  icon = false,
  iconName = "default",
  name = ["title", "subtext"],
  label = ["Heading Title", "Subtext"],
  placeholder = ["e.g. Heading Title", "e.g. Subtext"],
  valueUpper = "",
  valueLower = "",
  showCounter = [true, true],
  maxLength = [40, 40],
  masked = true,
  visible = true,
  errorMessage = null,
  error = false,
  errorUpper = false,
  errorLower = false,
  onChangeVisible = () => {},
  onChangeUpper = () => {},
  onChangeLower = () => {},
  onBlurInputUpper = () => {},
  onBlurInputLower = () => {},
}) {
  return (
    <div className={`w-full relative ${masked && "pr-4"}`}>
      <div className="relative w-full">
        {icon && icons[iconName]?.upper}
        <input
          value={valueUpper}
          name={name[0]}
          className={`w-full input-twin-upper ${icon && "pl-10"} ${
            showCounter[0] && "pr-12"
          } ${error && "input-twin-upper-error"}
          ${errorUpper && "input-twin-upper-error-full"}`}
          maxLength={maxLength[0]}
          placeholder={placeholder[0]}
          onChange={onChangeUpper}
          onBlur={onBlurInputUpper}
        />
        {showCounter[0] && (
          <span className={counterClass}>
            {`${_.size(valueUpper)}/${maxLength[0]}`}
          </span>
        )}
        {label[0] && (
          <label
            htmlFor="input-upper"
            className={`input-label-twin ${icon ? "pl-10" : "pl-4"}`}
          >
            {label[0]}
          </label>
        )}
      </div>
      <div className="relative w-full">
        {icon && icons[iconName]?.lower}
        <input
          value={valueLower}
          name={name[1]}
          className={`
            w-full input-twin-lower
            ${icon && "pl-10"}
            ${showCounter[1] && "pr-12"}
            ${(error || errorLower) && "input-twin-lower-error"}
            ${errorLower && "input-twin-lower-error-full"}
            ${errorUpper && errorLower && "mt-0.5"}

          `}
          placeholder={placeholder[1]}
          maxLength={maxLength[1]}
          onChange={onChangeLower}
          onBlur={onBlurInputLower}
        />
        {showCounter[1] && (
          <span className={counterClass}>
            {`${_.size(valueLower)}/${maxLength[1]}`}
          </span>
        )}
        {label[1] && (
          <label
            htmlFor="input-lower"
            className={`input-label-twin ${icon ? "pl-10" : "pl-4"}`}
          >
            {label[1]}
          </label>
        )}
      </div>
      {/*  masked */}
      {masked && (
        <button
          type="button"
          className="masked-button"
          onClick={onChangeVisible}
        >
          {visible ? (
            <EyeIconSVG className="mx-auto" />
          ) : (
            <EyeSlashIconSVG className="mx-auto" />
          )}
        </button>
      )}
      {/* {errorMessage?.text?.[0] && <p className="input-error-message mt-1">* {errorMessage.text}</p>}
      {errorMessage?.email?.[0] && <p className="input-error-message mt-1">* {errorMessage.email}</p>} */}
      {errorMessage && (
        <p className="input-error-message mt-1">* {errorMessage}</p>
      )}
    </div>
  );
}
