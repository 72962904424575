import React, { useState, useRef, useEffect, memo } from "react";
import { FaChevronDown } from "react-icons/fa";
import "./styles.css";
import _ from "lodash";
import { IoClose } from "react-icons/io5";

const Select = ({
  options = [], // {label: '', value: ''}
  placeholder = "Select",
  label = null,
  subText = null,
  value = "",
  name = "select",
  onChange = () => {},
  readOnly = false,
  icon = <FaChevronDown />,
  height = "45px",
  emptyPlaceholder = null,
  wrapperClass = "select-wrapper",
  errorMessage = null,
  errorMessagePosition = "top",
  clearable = false,
  selected = {},
  onClearValue = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(value);
  const [list, setList] = useState(options);

  const handleOnChange = (e) => {
    let filterValue = e.target?.value;
    if (filterValue) {
      let result = _.filter(options, function (b) {
        let label = _.lowerCase(b.label);
        return label.includes(_.lowerCase(filterValue));
      });
      setList(result);
      setOpen(true);
    } else {
      setList(options);
    }
    setFilter(e.target?.value);
  };

  const handleOnSelect = (obj) => {
    //call props function
    // setFilter(obj.label);
    onChange(obj);
    //set state to false
    setOpen(false);
  };

  // outside clicks
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * set close if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  // end: outside clicks

  useEffect(() => {
    setList(options);
  }, [options]);

  useEffect(() => {
    if (value) {
      setFilter(value);
    }
  }, [value]);

  return (
    <div className="w-full">
      {label && <label htmlFor={label}>{label}</label>}
      {subText && <p className="opacity-70 text-xs">{subText}</p>}
      {errorMessage && errorMessagePosition === "top" && (
        <p className="text-red text-xs">* {errorMessage}</p>
      )}
      <div className={wrapperClass} ref={wrapperRef}>
        {/* <!-- component --> */}
        <div className="relative w-full">
          {/* <!-- trigger button --> */}
          <button
            type="button"
            style={{ height: height }}
            className={`select  ${
              errorMessage
                ? "ring-red"
                : open
                ? "ring-secondary"
                : "ring-thBorder"
            }`}
            onClick={() => setOpen(!open)}
          >
            <input
              className="w-full mr-3"
              name={name}
              autoComplete="off"
              placeholder={placeholder}
              readOnly={readOnly}
              value={filter}
              onChange={handleOnChange}
            />
            {clearable && selected?.value && (
              <button
                className="mr-2 opacity-50 hover:opacity-100"
                onClick={() => {
                  setFilter("");
                  onClearValue();
                }}
              >
                <IoClose className="text-xl" />
              </button>
            )}
            {icon}
          </button>
          {/* <!-- list items --> */}
          <ul
            className={`select-list-container select-scroll z-50 ${
              open ? "block" : "hidden"
            }`}
          >
            {_.isEmpty(list) && _.isEmpty(emptyPlaceholder) && (
              <li className="p-2 text-center opacity-50">{`No Data Found ...`}</li>
            )}
            {_.isEmpty(list) && !_.isEmpty(emptyPlaceholder) && (
              <li className="p-10">{emptyPlaceholder}</li>
            )}
            {(list || []).map((obj, index) => (
              <li
                key={index}
                className={`select-list ${
                  obj?.label === value && "list-active"
                }`}
                onClick={() => handleOnSelect(obj)}
              >
                {obj.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {errorMessage && errorMessagePosition === "bottom" && (
        <p className="text-red text-xs">* {errorMessage}</p>
      )}
    </div>
  );
};
export default memo(Select);
