import React from "react";
import CampaignModalIconSVG from "assets/svg/campaign-modal-icon-svg";
import Modal from "components/global/modal";
import Button from "components/button/button";
import CampaignModalSVG from "assets/svg/campaign-modal-svg";

export default function CampaignModal({ hide, ...props }) {
  return (
    <Modal
      footer={[
        <Button
          key="create-btn"
          buttonName="CREATE YOUR FIRST CAMPAIGN"
          buttonClass="bg-primary rounded-full py-3"
          buttonWidth="100%"
          onClick={() => hide("create")}
        />,
      ]}
    >
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center">
          <CampaignModalIconSVG />
        </div>
        <div className="mt-3 text-center sm:mt-3 sm:ml-7 sm:text-left">
          <h3 className="text-2xl leading-9" id="modal-title">
            Create your Campaign
          </h3>
          <div className="mt-2">
            <p className="text-base leading-6">
              This is where you will manage your Campaigns. Let’s build your
              first campaign.
            </p>
          </div>
        </div>
        {/* close button */}
        <div className="mt-3 text-center sm:mt-3 sm:ml-7 sm:text-left">
          <span className="close" onClick={() => hide()}></span>
        </div>
        {/* close button */}
      </div>
      <div className="grid mt-9 rounded-2xl h-64 bg-lightDarkGray">
        <div className="m-auto">
          <CampaignModalSVG />
        </div>
      </div>
    </Modal>
  );
}
