const StatsIcon = ({ className = "" }) => (
  <svg
    className={className}
    viewBox="0 0 22 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2 3.79922H0V2.19922H21.2V3.79922Z"
      fill="currentColor"
    />
    <path
      d="M13.6 3C13.6 4.65685 12.2569 6 10.6 6C8.94315 6 7.6 4.65685 7.6 3C7.6 1.34315 8.94315 0 10.6 0C12.2569 0 13.6 1.34315 13.6 3Z"
      fill="currentColor"
    />
  </svg>
);

export default StatsIcon;
