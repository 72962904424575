import { device } from "components/campaigns/preview/screens";
import styled from "styled-components";

const Svg = styled.svg`
  width: 70px;
  height: 70px;

  @media ${device.xs} {
    width: 100px;
    height: 100px;
  }
`;

const PlayCircleIconPreviewSVG = ({ className = "" }) => {
  return (
    <Svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1537_15842)">
        <circle cx="34" cy="30" r="30" fill="white" />
      </g>
      <path
        d="M46 31.0005L29.5 40.5268L29.5 21.4742L46 31.0005Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_d_1537_15842"
          x="0"
          y="0"
          width="68"
          height="68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1537_15842"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1537_15842"
            result="shape"
          />
        </filter>
      </defs>
    </Svg>
  );
};

export default PlayCircleIconPreviewSVG;
