import styled from "styled-components";
import { motion } from "framer-motion";

const PopupImage = styled(motion.div)`
  position: fixed;
  bottom: 96px;
  right: 0px;
  cursor: pointer;
  z-index: 3000;
  transition-duration: 500ms;

  @media (min-width: 1${({ fullW }) => fullW + 1}px) {
    display: none !important;
  }
`;

const PopupComponent = styled(motion.div)`
  position: fixed;
  bottom: 96px;
  right: 0px;
  transition-duration: 500ms;

  width: ${({ fullW }) => Number(fullW) - 18}px;
  font-size: 16px;
  line-height: 19px;
  overflow-wrap: break-word;
  font-weight: 700;
  cursor: pointer;
  z-index: 3000;

  padding-top: 24px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 24px;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;

  background-color: ${({ background }) => background};
  color: ${({ text }) => text} !important;
  border-radius: ${({ radius }) => radius} !important;

  @media (min-width: 1${({ fullW }) => fullW + 1}px) {
    display: none !important;
  }
`;

function PopupSecondary({
  background = "#3B3B3B",
  text = "white",
  radius = 0,
  isImage = false,
  hoverEffects = "",
  ...props
}) {
  if (isImage) {
    return <PopupImage className="cc-popout-item-secondary" {...props} />;
  } else {
    return (
      <PopupComponent
        className="cc-popout-item-secondary"
        background={background}
        text={text}
        radius={radius}
        {...props}
      />
    );
  }
}

export default PopupSecondary;
