const ChevronUpSVG = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49935 0.632324L0.238931 6.89274L1.71081 8.3667L6.49935 3.57503L11.2868 8.3667L12.7598 6.89378L6.49935 0.632324Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronUpSVG;
