import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { device } from "../../screens";
import StarIconPreview from "assets/svg/star-icon-preview";
import PlayCircleIconPreviewSVG from "assets/svg/play-circle-preview";
import HeaderTemplate from "../commons/header-template";
import { sanitizeValueHTML } from "utils/constant";

const VideosPlayer = styled.div`
  display: flex;
  position: relative;

  background-repeat: no-repeat;
  width: 100%;

  height: 187.84px;

  margin-bottom: 20px;
  margin-top: 24px;

  ${({ background }) => {
    if (background) {
      return `
			background-image: url(${background});
			background-position: center;
			background-size: cover;
			`;
    } else {
      return `background-color: #e6e6e6`;
    }
  }}

  @media ${device.xs} {
    margin-top: 32px;
    height: 320px !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-top: 36px;
    height: 320px !important;
  }
`;
const VideosPlayerButton = styled.button`
  background-color: transparent !important;
  margin: auto;
  line-height: inherit;
  color: inherit;
`;
const VideosHeading = styled.h1`
  margin: 0;
  font-weight: 700;

  letter-spacing: -0.03em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 16px;
  line-height: 22px;

  @media ${device.xs} {
    font-size: 18px;
    line-height: 25px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
const VideosBody = styled.p`
  margin: 0;
  margin-top: 16px;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 12px;
  line-height: 19px;

  @media ${device.xs} {
    font-size: 14px;
    line-height: 22px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

const StarContainer = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
`;

const Videos = ({ data }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    if (!data.overlay) {
      setPlaying(false);
    }
  }, [data.overlay]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <HeaderTemplate
        family={data.font?.family}
        width={data.width}
        dangerouslySetInnerHTML={{ __html: sanitizeValueHTML(data.title) }}
      />
      <VideosPlayer
        background={data.thumbnail?.url && data.thumbnail.url}
        responsive={data.width}
      >
        {data.video?.link && showVideo ? (
          <div style={{ width: "100%", height: "100%" }}>
            <ReactPlayer
              url={data.video.link}
              playing={playing}
              onPlay={() => setPlaying(true)}
              controls
              width="100%"
              height="100%"
              css={{
                position: `absolute`,
                top: 0,
                left: 0,
              }}
            />
          </div>
        ) : (
          <VideosPlayerButton
            onClick={() => {
              setShowVideo(!showVideo);
              setPlaying(true);
            }}
          >
            <PlayCircleIconPreviewSVG responsive={data.width} />
          </VideosPlayerButton>
        )}
      </VideosPlayer>
      {data.video?.rating ? (
        <StarContainer>
          {Array(5)
            .fill(0)
            .map((_, ratingIndex) => {
              const count = ratingIndex + 1;
              return (
                <StarIconPreview
                  responsive={data.width}
                  active={data.video?.rating >= count}
                  key={`video-rating-${ratingIndex}`}
                />
              );
            })}
        </StarContainer>
      ) : null}
      <VideosHeading responsive={data.width}>
        {data.video.heading}
      </VideosHeading>
      <VideosBody responsive={data.width}>{data.video.body}</VideosBody>
    </div>
  );
};

export default Videos;
