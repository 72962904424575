import image from "assets/images/calendy-image.png";
import calendly from "assets/images/calendly-dashboard.png";
import Button from "components/button/button";
import Input from "components/input/input";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useState } from "react";
import { CONTACTOBJ } from "utils/constant";
import validator from "validator";

export default function CalandyEventSchedulerDetails({
  handleChangeStep,
  state,
  setState,
  handleOnSave,
}) {
  const { setSelectedTab, popup } = useContext(Context);
  const [content, setContent] = useState(
    _.isEmpty(popup?.contact_method_content)
      ? _.find(CONTACTOBJ, { type: "calendy-event-scheduler" })
      : JSON.parse(popup?.contact_method_content)
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContent((prev) => ({
      ...prev,
      [name]: value,
    }));
    setState((prev) => ({
      ...prev,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const validateUrl = (url) => {
    if (validator.isURL(url)) {
      return true;
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          link: ["The link format is invalid."],
        },
      }));
      return false;
    }
  };

  const validateBeforeSave = (content) => {
    if (_.isEmpty(content.link)) {
      handleOnSave(content);
    } else {
      if (validateUrl(content.link)) {
        if (validateCalendyLink(content.link)) {
          handleOnSave(content);
        } else {
          setState((prev) => ({
            ...prev,
            validationErrors: {
              ...state.validationErrors,
              link: "Input valid calendy link",
            },
          }));
        }
      }
    }
  };

  const validateCalendyLink = (link) => {
    if (link.indexOf("https://calendly.com/") === 0) {
      const url = _.split(link, "/");
      const size = _.size(url);
      if (size >= 5 && size <= 7) {
        if (url[4]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  };

  return (
    <div className="w-full">
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Complete Your Contact Details</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleChangeStep(content);
          }}
          className="w-full bg-white rounded-xl relative campaign-left-max mr-32"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            <p className="text-xl pl-1 pb-2">Embed your Calendly schedule</p>
            {/* middle */}
            <div>
              <p className="pl-1 pt-2 pb-2">
                Enter your Calendly booking event link here:
              </p>
              {/* title */}
              <div>
                <Input
                  inputContainerClass="input-container"
                  labelClass="input-label-xs"
                  paddingTop="1.5rem"
                  placeholder="e.g. https://calendly.com/john-doe-company-meeting"
                  value={content.link}
                  maxLength={255}
                  label="Calendly Link"
                  autoFocus={true}
                  name="link"
                  type="text"
                  onChange={handleInputChange}
                  onBlur={() => validateBeforeSave(content)}
                  inputIcon={
                    <div
                      style={{ backgroundImage: `url(${image})` }}
                      className="w-5 h-5 object-cover bg-cover bg-no-repeat"
                    ></div>
                  }
                  iconPosition="left"
                  errorMessage={state.validationErrors?.link}
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-2/3">
                <p className="text-sm">
                  Find your Calendly booking event here:
                </p>
                <p className="text-sm">
                  Need more help?{" "}
                  <a
                    className={"text-blue font-bold underline"}
                    target={"_blank"}
                    href="https://support.conversioncow.com/hc/en-us/articles/6544925578255-How-to-Set-Calendly-Event-Contact-Method"
                    rel="noreferrer"
                  >
                    Find out more
                  </a>
                </p>
              </div>
              <div className={"w-1/3"}>
                <img
                  alt="calendly"
                  className="object-scale-down"
                  src={calendly}
                />
              </div>
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("contactM")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
