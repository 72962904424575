import {
  ADD_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_BANNER,
  SET_NOTIFICATION_USER_READS,
  ADD_NOTIFICATION_USER_READ,
} from "./types";
const initialState = {
  push: [],
  banner: null,
  users: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        push: [action.payload, ...state.push],
      };

    case SET_NOTIFICATIONS:
      return {
        ...state,
        push: action.payload,
      };

    case SET_NOTIFICATION_BANNER:
      return {
        ...state,
        banner: {
          ...action.payload,
          buttons:
            typeof action.payload.buttons === "string"
              ? JSON.parse(action.payload.buttons)
              : action.payload.buttons,
        },
      };

    case SET_NOTIFICATION_USER_READS:
      return {
        ...state,
        users: action.payload
          .filter((notif) => notif.status === "read")
          .map((notif) => notif.notification_id),
      };

    case ADD_NOTIFICATION_USER_READ:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };

    default:
      return state;
  }
};

export default reducer;
