export const popupTransition = {
  parent: {
    initial: { x: "12rem" },
    show: {
      x: 0,
      transition: {
        type: "tween",
        duration: 0.2,
        when: "beforeChildren",
        delay: 0.1,
        staggerChildren: 0.2,
      },
    },
  },
  children: {
    initial: { x: "5rem" },
    show: { x: 0 },
  },
};

export const backdropTransition = {
  initial: {
    display: "none",
    opacity: 0,
  },
  show: {
    display: "block",
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
  hide: {
    opacity: 0,
    display: "none",
    transition: {
      when: "beforeChildren",
      duration: 0.5,
      display: {
        delay: 0.5,
      },
    },
  },
};

export const buttonTransistion = {
  parent: {
    initial: {
      x: -140,
      transition: {
        when: "beforeChildren",
        duration: 0.5,
      },
    },
    show: {
      x: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  },
};

export const leadDetailTransition = {
  parent: {
    initial: {
      x: 500,
    },
    show: {
      x: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    hide: {
      x: 500,
      transition: {
        when: "beforeChildren",
        duration: 0.5,
      },
    },
  },
};

export const quickSupportTransition = {
  parent: {
    initial: {
      x: 562,
    },
    show: {
      x: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    hide: {
      x: 562,
      transition: {
        when: "beforeChildren",
        duration: 0.5,
      },
    },
  },
};

export const contentTransition = (
  imageW = 140,
  image = false,
  container = 120
) => {
  const def_w = container;
  const diff = Math.abs(imageW - def_w);
  const value_x = 1456 - diff;
  const value_y = image ? 1456 : value_x;
  return {
    parent: {
      initial: {
        x: image ? 1456 : value_x,
      },
      show: {
        x: 0,
        transition: {
          type: "tween",
          duration: 1,
        },
      },
      // content peep close transition
      peep_0: {
        x: value_y,
        transition: {
          type: "tween",
          duration: 0.5,
        },
      },
      // content close overlay transition
      peep_1000: {
        x: value_y,
        transition: {
          type: "tween",
          duration: 1,
        },
      },
      // not sure if their are any approach to make this reanimate without writing every variation object in framer motion
      // start mirror effect for peep
      peep_1: {
        x: value_y - 1,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_2: {
        x: value_y - 2,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_3: {
        x: value_y - 3,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_4: {
        x: value_y - 4,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_5: {
        x: value_y - 5,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_6: {
        x: value_y - 6,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_7: {
        x: value_y - 7,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_8: {
        x: value_y - 8,
        transition: {
          type: "tween",
          dduration: 0.3,
        },
      },
      peep_9: {
        x: value_y - 9,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_10: {
        x: value_y - 10,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_11: {
        x: value_y - 11,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_12: {
        x: value_y - 12,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_13: {
        x: value_y - 13,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_14: {
        x: value_y - 14,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_15: {
        x: value_y - 15,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_16: {
        x: value_y - 16,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_17: {
        x: value_y - 17,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_18: {
        x: value_y - 18,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_19: {
        x: value_y - 19,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_20: {
        x: value_y - 20,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_21: {
        x: value_y - 21,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_22: {
        x: value_y - 22,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_23: {
        x: value_y - 23,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_24: {
        x: value_y - 24,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_25: {
        x: value_y - 25,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_26: {
        x: value_y - 26,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_27: {
        x: value_y - 27,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_28: {
        x: value_y - 28,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_29: {
        x: value_y - 29,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_30: {
        x: value_y - 30,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_31: {
        x: value_y - 31,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_32: {
        x: value_y - 32,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_33: {
        x: value_y - 33,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_34: {
        x: value_y - 34,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_35: {
        x: value_y - 35,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_36: {
        x: value_y - 36,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_37: {
        x: value_y - 37,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_38: {
        x: value_y - 38,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_39: {
        x: value_y - 39,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_40: {
        x: value_y - 40,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_41: {
        x: value_y - 41,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_42: {
        x: value_y - 42,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_43: {
        x: value_y - 43,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_44: {
        x: value_y - 44,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_45: {
        x: value_y - 45,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_46: {
        x: value_y - 46,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_47: {
        x: value_y - 47,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_48: {
        x: value_y - 48,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_49: {
        x: value_y - 49,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_50: {
        x: value_y - 50,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_51: {
        x: value_y - 51,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_52: {
        x: value_y - 52,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_53: {
        x: value_y - 53,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_54: {
        x: value_y - 54,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_55: {
        x: value_y - 55,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_56: {
        x: value_y - 56,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_57: {
        x: value_y - 57,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_58: {
        x: value_y - 58,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_59: {
        x: value_y - 59,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_60: {
        x: value_y - 60,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_61: {
        x: value_y - 61,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_62: {
        x: value_y - 62,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_63: {
        x: value_y - 63,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_64: {
        x: value_y - 64,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_65: {
        x: value_y - 65,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_66: {
        x: value_y - 66,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_67: {
        x: value_y - 67,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_68: {
        x: value_y - 68,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_69: {
        x: value_y - 69,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_70: {
        x: value_y - 70,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_71: {
        x: value_y - 71,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_72: {
        x: value_y - 72,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_73: {
        x: value_y - 73,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_74: {
        x: value_y - 74,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_75: {
        x: value_y - 75,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_76: {
        x: value_y - 76,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_77: {
        x: value_y - 77,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_78: {
        x: value_y - 78,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_79: {
        x: value_y - 79,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_80: {
        x: value_y - 80,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_81: {
        x: value_y - 81,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_82: {
        x: value_y - 82,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_83: {
        x: value_y - 83,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_84: {
        x: value_y - 84,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_85: {
        x: value_y - 85,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_86: {
        x: value_y - 86,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_87: {
        x: value_y - 87,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_88: {
        x: value_y - 88,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_89: {
        x: value_y - 89,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_90: {
        x: value_y - 90,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_91: {
        x: value_y - 91,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_92: {
        x: value_y - 92,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_93: {
        x: value_y - 93,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_94: {
        x: value_y - 94,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_95: {
        x: value_y - 95,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_96: {
        x: value_y - 96,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_97: {
        x: value_y - 97,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_98: {
        x: value_y - 98,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_99: {
        x: value_y - 99,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_100: {
        x: value_y - 100,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_101: {
        x: value_y - 101,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_102: {
        x: value_y - 102,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_103: {
        x: value_y - 103,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_104: {
        x: value_y - 104,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_105: {
        x: value_y - 105,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_106: {
        x: value_y - 106,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_107: {
        x: value_y - 107,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_108: {
        x: value_y - 108,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_109: {
        x: value_y - 109,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_110: {
        x: value_y - 110,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_111: {
        x: value_y - 111,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_112: {
        x: value_y - 112,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_113: {
        x: value_y - 113,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_114: {
        x: value_y - 114,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_115: {
        x: value_y - 115,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_116: {
        x: value_y - 116,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_117: {
        x: value_y - 117,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_118: {
        x: value_y - 118,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_119: {
        x: value_y - 119,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_120: {
        x: value_y - 120,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_121: {
        x: value_y - 121,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_122: {
        x: value_y - 122,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_123: {
        x: value_y - 123,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_124: {
        x: value_y - 124,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_125: {
        x: value_y - 125,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_126: {
        x: value_y - 126,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_127: {
        x: value_y - 127,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_128: {
        x: value_y - 128,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_129: {
        x: value_y - 129,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_131: {
        x: value_y - 131,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_132: {
        x: value_y - 132,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_133: {
        x: value_y - 133,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_134: {
        x: value_y - 134,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_135: {
        x: value_y - 135,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_136: {
        x: value_y - 136,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_137: {
        x: value_y - 137,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_138: {
        x: value_y - 138,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_139: {
        x: value_y - 139,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_140: {
        x: value_y - 140,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_141: {
        x: value_y - 141,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_142: {
        x: value_y - 142,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_143: {
        x: value_y - 143,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_144: {
        x: value_y - 144,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_145: {
        x: value_y - 145,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_146: {
        x: value_y - 146,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_147: {
        x: value_y - 147,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_148: {
        x: value_y - 148,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_149: {
        x: value_y - 149,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_150: {
        x: value_y - 150,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_151: {
        x: value_y - 151,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_152: {
        x: value_y - 152,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_153: {
        x: value_y - 153,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_154: {
        x: value_y - 154,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_155: {
        x: value_y - 155,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_156: {
        x: value_y - 156,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_157: {
        x: value_y - 157,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_158: {
        x: value_y - 158,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_159: {
        x: value_y - 159,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_160: {
        x: value_y - 160,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_161: {
        x: value_y - 161,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_162: {
        x: value_y - 162,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_163: {
        x: value_y - 163,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_164: {
        x: value_y - 164,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_165: {
        x: value_y - 165,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_166: {
        x: value_y - 166,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_167: {
        x: value_y - 167,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_168: {
        x: value_y - 168,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_169: {
        x: value_y - 169,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_170: {
        x: value_y - 170,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_171: {
        x: value_y - 171,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_172: {
        x: value_y - 172,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_173: {
        x: value_y - 173,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_174: {
        x: value_y - 174,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_175: {
        x: value_y - 175,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_176: {
        x: value_y - 176,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_177: {
        x: value_y - 177,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_178: {
        x: value_y - 178,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_179: {
        x: value_y - 179,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_180: {
        x: value_y - 180,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_181: {
        x: value_y - 181,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_182: {
        x: value_y - 182,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_183: {
        x: value_y - 183,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_184: {
        x: value_y - 184,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_185: {
        x: value_y - 185,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_186: {
        x: value_y - 186,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_187: {
        x: value_y - 187,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_188: {
        x: value_y - 188,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_189: {
        x: value_y - 189,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },
      peep_190: {
        x: value_y - 190,
        transition: {
          type: "tween",
          duration: 0.3,
        },
      },

      hide_0: {
        x: image ? 1456 : value_x,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      //starts
      hide_1: {
        x: value_y - 1,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_2: {
        x: value_y - 2,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_3: {
        x: value_y - 3,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_4: {
        x: value_y - 4,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_5: {
        x: value_y - 5,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_6: {
        x: value_y - 6,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_7: {
        x: value_y - 7,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_8: {
        x: value_y - 8,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_9: {
        x: value_y - 9,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_10: {
        x: value_y - 10,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_11: {
        x: value_y - 11,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_12: {
        x: value_y - 12,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_13: {
        x: value_y - 13,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_14: {
        x: value_y - 14,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_15: {
        x: value_y - 15,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_16: {
        x: value_y - 16,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_17: {
        x: value_y - 17,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_18: {
        x: value_y - 18,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_19: {
        x: value_y - 19,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_20: {
        x: value_y - 20,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_21: {
        x: value_y - 21,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_22: {
        x: value_y - 22,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_23: {
        x: value_y - 23,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_24: {
        x: value_y - 24,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_25: {
        x: value_y - 25,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_26: {
        x: value_y - 26,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_27: {
        x: value_y - 27,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_28: {
        x: value_y - 28,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_29: {
        x: value_y - 29,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_30: {
        x: value_y - 30,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_31: {
        x: value_y - 31,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_32: {
        x: value_y - 32,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_33: {
        x: value_y - 33,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_34: {
        x: value_y - 34,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_35: {
        x: value_y - 35,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_36: {
        x: value_y - 36,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_37: {
        x: value_y - 37,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_38: {
        x: value_y - 38,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_39: {
        x: value_y - 39,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_40: {
        x: value_y - 40,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_41: {
        x: value_y - 41,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_42: {
        x: value_y - 42,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_43: {
        x: value_y - 43,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_44: {
        x: value_y - 44,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_45: {
        x: value_y - 45,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_46: {
        x: value_y - 46,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_47: {
        x: value_y - 47,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_48: {
        x: value_y - 48,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_49: {
        x: value_y - 49,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_50: {
        x: value_y - 50,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_51: {
        x: value_y - 51,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_52: {
        x: value_y - 52,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_53: {
        x: value_y - 53,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_54: {
        x: value_y - 54,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_55: {
        x: value_y - 55,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_56: {
        x: value_y - 56,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_57: {
        x: value_y - 57,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_58: {
        x: value_y - 58,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_59: {
        x: value_y - 59,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_60: {
        x: value_y - 60,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_61: {
        x: value_y - 61,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_62: {
        x: value_y - 62,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_63: {
        x: value_y - 63,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_64: {
        x: value_y - 64,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_65: {
        x: value_y - 65,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_66: {
        x: value_y - 66,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_67: {
        x: value_y - 67,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_68: {
        x: value_y - 68,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_69: {
        x: value_y - 69,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_70: {
        x: value_y - 70,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_71: {
        x: value_y - 71,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_72: {
        x: value_y - 72,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_73: {
        x: value_y - 73,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_74: {
        x: value_y - 74,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_75: {
        x: value_y - 75,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_76: {
        x: value_y - 76,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_77: {
        x: value_y - 77,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_78: {
        x: value_y - 78,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_79: {
        x: value_y - 79,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_80: {
        x: value_y - 80,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_81: {
        x: value_y - 81,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_82: {
        x: value_y - 82,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_83: {
        x: value_y - 83,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_84: {
        x: value_y - 84,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_85: {
        x: value_y - 85,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_86: {
        x: value_y - 86,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_87: {
        x: value_y - 87,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_88: {
        x: value_y - 88,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_89: {
        x: value_y - 89,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_90: {
        x: value_y - 90,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_91: {
        x: value_y - 91,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_92: {
        x: value_y - 92,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_93: {
        x: value_y - 93,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_94: {
        x: value_y - 94,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_95: {
        x: value_y - 95,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_96: {
        x: value_y - 96,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_97: {
        x: value_y - 97,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_98: {
        x: value_y - 98,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_99: {
        x: value_y - 99,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_100: {
        x: value_y - 100,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_101: {
        x: value_y - 101,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_102: {
        x: value_y - 102,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_103: {
        x: value_y - 103,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_104: {
        x: value_y - 104,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_105: {
        x: value_y - 105,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_106: {
        x: value_y - 106,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_107: {
        x: value_y - 107,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_108: {
        x: value_y - 108,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_109: {
        x: value_y - 109,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_110: {
        x: value_y - 110,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_111: {
        x: value_y - 111,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_112: {
        x: value_y - 112,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_113: {
        x: value_y - 113,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_114: {
        x: value_y - 114,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_115: {
        x: value_y - 115,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_116: {
        x: value_y - 116,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_117: {
        x: value_y - 117,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_118: {
        x: value_y - 118,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_119: {
        x: value_y - 119,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_120: {
        x: value_y - 120,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_121: {
        x: value_y - 121,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_122: {
        x: value_y - 122,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_123: {
        x: value_y - 123,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_124: {
        x: value_y - 124,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_125: {
        x: value_y - 125,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_126: {
        x: value_y - 126,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_127: {
        x: value_y - 127,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_128: {
        x: value_y - 128,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_129: {
        x: value_y - 129,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_130: {
        x: value_y - 130,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_131: {
        x: value_y - 131,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_132: {
        x: value_y - 132,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_133: {
        x: value_y - 133,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_134: {
        x: value_y - 134,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_135: {
        x: value_y - 135,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_136: {
        x: value_y - 136,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_137: {
        x: value_y - 137,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_138: {
        x: value_y - 138,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_139: {
        x: value_y - 139,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_140: {
        x: value_y - 140,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_141: {
        x: value_y - 141,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_142: {
        x: value_y - 142,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_143: {
        x: value_y - 143,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_144: {
        x: value_y - 144,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_145: {
        x: value_y - 145,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_146: {
        x: value_y - 146,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_147: {
        x: value_y - 147,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_148: {
        x: value_y - 148,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_149: {
        x: value_y - 149,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_150: {
        x: value_y - 150,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_151: {
        x: value_y - 151,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_152: {
        x: value_y - 152,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_153: {
        x: value_y - 153,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_154: {
        x: value_y - 154,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_155: {
        x: value_y - 155,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_156: {
        x: value_y - 156,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_157: {
        x: value_y - 157,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_158: {
        x: value_y - 158,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_159: {
        x: value_y - 159,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_160: {
        x: value_y - 160,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_161: {
        x: value_y - 161,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_162: {
        x: value_y - 162,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_163: {
        x: value_y - 163,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_164: {
        x: value_y - 164,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_165: {
        x: value_y - 165,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_166: {
        x: value_y - 166,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_167: {
        x: value_y - 167,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_168: {
        x: value_y - 168,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_169: {
        x: value_y - 169,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_170: {
        x: value_y - 170,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_171: {
        x: value_y - 171,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_172: {
        x: value_y - 172,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_173: {
        x: value_y - 173,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_174: {
        x: value_y - 174,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_175: {
        x: value_y - 175,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_176: {
        x: value_y - 176,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_177: {
        x: value_y - 177,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_178: {
        x: value_y - 178,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_179: {
        x: value_y - 179,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_180: {
        x: value_y - 180,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_181: {
        x: value_y - 181,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_182: {
        x: value_y - 182,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_183: {
        x: value_y - 183,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_184: {
        x: value_y - 184,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_185: {
        x: value_y - 185,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_186: {
        x: value_y - 186,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_187: {
        x: value_y - 187,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_188: {
        x: value_y - 188,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_189: {
        x: value_y - 189,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_190: {
        x: value_y - 190,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_191: {
        x: value_y - 191,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_192: {
        x: value_y - 192,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_193: {
        x: value_y - 193,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_194: {
        x: value_y - 194,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_195: {
        x: value_y - 195,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_196: {
        x: value_y - 196,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_197: {
        x: value_y - 197,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_198: {
        x: value_y - 198,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_199: {
        x: value_y - 199,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_200: {
        x: value_y - 200,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_201: {
        x: value_y - 201,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_202: {
        x: value_y - 202,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_203: {
        x: value_y - 203,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_204: {
        x: value_y - 204,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_205: {
        x: value_y - 205,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_206: {
        x: value_y - 206,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_207: {
        x: value_y - 207,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_208: {
        x: value_y - 208,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_209: {
        x: value_y - 209,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_210: {
        x: value_y - 210,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_211: {
        x: value_y - 211,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_212: {
        x: value_y - 212,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_213: {
        x: value_y - 213,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_214: {
        x: value_y - 214,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_215: {
        x: value_y - 215,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_216: {
        x: value_y - 216,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_217: {
        x: value_y - 217,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_218: {
        x: value_y - 218,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_219: {
        x: value_y - 219,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_220: {
        x: value_y - 220,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_221: {
        x: value_y - 221,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_222: {
        x: value_y - 222,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_223: {
        x: value_y - 223,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_224: {
        x: value_y - 224,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_225: {
        x: value_y - 225,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_226: {
        x: value_y - 226,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_227: {
        x: value_y - 227,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_228: {
        x: value_y - 228,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_229: {
        x: value_y - 229,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_230: {
        x: value_y - 230,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_231: {
        x: value_y - 231,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_232: {
        x: value_y - 232,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_233: {
        x: value_y - 233,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_234: {
        x: value_y - 234,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_235: {
        x: value_y - 235,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_236: {
        x: value_y - 236,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_237: {
        x: value_y - 237,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_238: {
        x: value_y - 238,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_239: {
        x: value_y - 239,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_240: {
        x: value_y - 240,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_241: {
        x: value_y - 241,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_242: {
        x: value_y - 242,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_243: {
        x: value_y - 243,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_244: {
        x: value_y - 244,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_245: {
        x: value_y - 245,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_246: {
        x: value_y - 246,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_247: {
        x: value_y - 247,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_248: {
        x: value_y - 248,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_249: {
        x: value_y - 249,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_250: {
        x: value_y - 250,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_251: {
        x: value_y - 251,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_252: {
        x: value_y - 252,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_253: {
        x: value_y - 253,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_254: {
        x: value_y - 254,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_255: {
        x: value_y - 255,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_256: {
        x: value_y - 256,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_257: {
        x: value_y - 257,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_258: {
        x: value_y - 258,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_259: {
        x: value_y - 259,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_260: {
        x: value_y - 260,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_261: {
        x: value_y - 261,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_262: {
        x: value_y - 262,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_263: {
        x: value_y - 263,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_264: {
        x: value_y - 264,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_265: {
        x: value_y - 265,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_266: {
        x: value_y - 266,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_267: {
        x: value_y - 267,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_268: {
        x: value_y - 268,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_269: {
        x: value_y - 269,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_270: {
        x: value_y - 270,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_271: {
        x: value_y - 271,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_272: {
        x: value_y - 272,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_273: {
        x: value_y - 273,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_274: {
        x: value_y - 274,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_275: {
        x: value_y - 275,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_276: {
        x: value_y - 276,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_277: {
        x: value_y - 277,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_278: {
        x: value_y - 278,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_279: {
        x: value_y - 279,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_280: {
        x: value_y - 280,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_281: {
        x: value_y - 281,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_282: {
        x: value_y - 282,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_283: {
        x: value_y - 283,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_284: {
        x: value_y - 284,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_285: {
        x: value_y - 285,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_286: {
        x: value_y - 286,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_287: {
        x: value_y - 287,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_288: {
        x: value_y - 288,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_289: {
        x: value_y - 289,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_290: {
        x: value_y - 290,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_291: {
        x: value_y - 291,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_292: {
        x: value_y - 292,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_293: {
        x: value_y - 293,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_294: {
        x: value_y - 294,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_295: {
        x: value_y - 295,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_296: {
        x: value_y - 296,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_297: {
        x: value_y - 297,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_298: {
        x: value_y - 298,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_299: {
        x: value_y - 299,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_300: {
        x: value_y - 300,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_301: {
        x: value_y - 301,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_302: {
        x: value_y - 302,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_303: {
        x: value_y - 303,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_304: {
        x: value_y - 304,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_305: {
        x: value_y - 305,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_306: {
        x: value_y - 306,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_307: {
        x: value_y - 307,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_308: {
        x: value_y - 308,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_309: {
        x: value_y - 309,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_310: {
        x: value_y - 310,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_311: {
        x: value_y - 311,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_312: {
        x: value_y - 312,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_313: {
        x: value_y - 313,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_314: {
        x: value_y - 314,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_315: {
        x: value_y - 315,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_316: {
        x: value_y - 316,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_317: {
        x: value_y - 317,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_318: {
        x: value_y - 318,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_319: {
        x: value_y - 319,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_320: {
        x: value_y - 320,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_321: {
        x: value_y - 321,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_322: {
        x: value_y - 322,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_323: {
        x: value_y - 323,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_324: {
        x: value_y - 324,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_325: {
        x: value_y - 325,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_326: {
        x: value_y - 326,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_327: {
        x: value_y - 327,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_328: {
        x: value_y - 328,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_329: {
        x: value_y - 329,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_330: {
        x: value_y - 330,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_331: {
        x: value_y - 331,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_332: {
        x: value_y - 332,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_333: {
        x: value_y - 333,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_334: {
        x: value_y - 334,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_335: {
        x: value_y - 335,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_336: {
        x: value_y - 336,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_337: {
        x: value_y - 337,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_338: {
        x: value_y - 338,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_339: {
        x: value_y - 339,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_340: {
        x: value_y - 340,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_341: {
        x: value_y - 341,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_342: {
        x: value_y - 342,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_343: {
        x: value_y - 343,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_344: {
        x: value_y - 344,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_345: {
        x: value_y - 345,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_346: {
        x: value_y - 346,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_347: {
        x: value_y - 347,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_348: {
        x: value_y - 348,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_349: {
        x: value_y - 349,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_350: {
        x: value_y - 350,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_351: {
        x: value_y - 351,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_352: {
        x: value_y - 352,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_353: {
        x: value_y - 353,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_354: {
        x: value_y - 354,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_355: {
        x: value_y - 355,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_356: {
        x: value_y - 356,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_357: {
        x: value_y - 357,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_358: {
        x: value_y - 358,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_359: {
        x: value_y - 359,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_360: {
        x: value_y - 360,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_361: {
        x: value_y - 361,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_362: {
        x: value_y - 362,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_363: {
        x: value_y - 363,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_364: {
        x: value_y - 364,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_365: {
        x: value_y - 365,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_366: {
        x: value_y - 366,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_367: {
        x: value_y - 367,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_368: {
        x: value_y - 368,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_369: {
        x: value_y - 369,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_370: {
        x: value_y - 370,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_371: {
        x: value_y - 371,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_372: {
        x: value_y - 372,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_373: {
        x: value_y - 373,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_374: {
        x: value_y - 374,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_375: {
        x: value_y - 375,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_376: {
        x: value_y - 376,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_377: {
        x: value_y - 377,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_378: {
        x: value_y - 378,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_379: {
        x: value_y - 379,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_380: {
        x: value_y - 380,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_381: {
        x: value_y - 381,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_382: {
        x: value_y - 382,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_383: {
        x: value_y - 383,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_384: {
        x: value_y - 384,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_385: {
        x: value_y - 385,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_386: {
        x: value_y - 386,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_387: {
        x: value_y - 387,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_388: {
        x: value_y - 388,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_389: {
        x: value_y - 389,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_390: {
        x: value_y - 390,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_391: {
        x: value_y - 391,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_392: {
        x: value_y - 392,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_393: {
        x: value_y - 393,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_394: {
        x: value_y - 394,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_395: {
        x: value_y - 395,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_396: {
        x: value_y - 396,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_397: {
        x: value_y - 397,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_398: {
        x: value_y - 398,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_399: {
        x: value_y - 399,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_400: {
        x: value_y - 400,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_401: {
        x: value_y - 401,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_402: {
        x: value_y - 402,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_403: {
        x: value_y - 403,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_404: {
        x: value_y - 404,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_405: {
        x: value_y - 405,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_406: {
        x: value_y - 406,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_407: {
        x: value_y - 407,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_408: {
        x: value_y - 408,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_409: {
        x: value_y - 409,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_410: {
        x: value_y - 410,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_411: {
        x: value_y - 411,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_412: {
        x: value_y - 412,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_413: {
        x: value_y - 413,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_414: {
        x: value_y - 414,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_415: {
        x: value_y - 415,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_416: {
        x: value_y - 416,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_417: {
        x: value_y - 417,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_418: {
        x: value_y - 418,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_419: {
        x: value_y - 419,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_420: {
        x: value_y - 420,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_421: {
        x: value_y - 421,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_422: {
        x: value_y - 422,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_423: {
        x: value_y - 423,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_424: {
        x: value_y - 424,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_425: {
        x: value_y - 425,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_426: {
        x: value_y - 426,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_427: {
        x: value_y - 427,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_428: {
        x: value_y - 428,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_429: {
        x: value_y - 429,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_430: {
        x: value_y - 430,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_431: {
        x: value_y - 431,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_432: {
        x: value_y - 432,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_433: {
        x: value_y - 433,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_434: {
        x: value_y - 434,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_435: {
        x: value_y - 435,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_436: {
        x: value_y - 436,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_437: {
        x: value_y - 437,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_438: {
        x: value_y - 438,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_439: {
        x: value_y - 439,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },
      hide_440: {
        x: value_y - 440,
        transition: {
          when: "beforeChildren",
          duration: 1,
        },
      },

      //ends
    },
  };
};
