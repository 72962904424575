const SelectCheckIconSVG = ({ className = "", ...props }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx="9.5" cy="10" rx="5.5" ry="6" fill="white" />
      <path
        d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM14.3192 6.73438L9.6183 13.2522C9.5526 13.3439 9.46599 13.4187 9.36564 13.4702C9.26529 13.5218 9.15411 13.5486 9.0413 13.5486C8.92848 13.5486 8.8173 13.5218 8.71695 13.4702C8.6166 13.4187 8.52999 13.3439 8.46429 13.2522L5.6808 9.39509C5.59598 9.27679 5.6808 9.11161 5.82589 9.11161H6.87277C7.10045 9.11161 7.31696 9.22098 7.45089 9.40848L9.04018 11.6138L12.5491 6.74777C12.683 6.5625 12.8973 6.45089 13.1272 6.45089H14.1741C14.3192 6.45089 14.404 6.61607 14.3192 6.73438Z"
        fill="#EC5627"
      />
    </svg>
  );
};

export default SelectCheckIconSVG;
