import styled from "styled-components";
import CloseSVG from "../../../../../assets/svg/close-icon";
import { device } from "../../screens";

const TabSelectorSelectorContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${device.xs} {
    position: absolute;
    top: 0px;
    right: 0px;
    margin-top: 0px !important;
    margin-right: 0px !important;
  }
`;
const TabSelectorResponsive = styled.div`
  display: none;
  @media (min-width: 1${({ width }) => width + 1}px) {
    display: block;
    position: absolute;
    top: 27px;
    right: 23px;
  }
`;
const Button = styled.button`
  margin: 0;
  background-color: transparent;
  background-image: none;
  line-height: inherit;
  color: inherit;
`;

const ButtonMobile = styled.button`
  margin: 0;
  background-color: transparent;
  background-image: none;
  line-height: inherit;
  color: inherit;

  padding-top: 19px;
  padding-left: 25px;
  padding-right: 18px;
`;

const TabSelectorClose = ({ onClose, content = false, width = 140 }) => {
  if (content) {
    return (
      <TabSelectorResponsive width={width}>
        <Button onClick={onClose}>
          <CloseSVG />
        </Button>
      </TabSelectorResponsive>
    );
  } else {
    return (
      <TabSelectorSelectorContainer width={width}>
        <ButtonMobile onClick={onClose}>
          <CloseSVG />
        </ButtonMobile>
      </TabSelectorSelectorContainer>
    );
  }
};

export default TabSelectorClose;
