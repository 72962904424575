import React, { useRef, useState, useEffect } from "react";
import CampaignPopupPopOutIcons from "../popup/campaign-popup-popout-icons";
import { useCallback } from "react";

export default function CampaignIconSelector({ onClick, Icon, label }) {
  const ref = useRef();
  const buttonRef = useRef();
  const iconRef = useRef();
  const [open, setOpen] = useState(false);

  const toogleSelector = useCallback(() => {
    if (!open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
    setOpen((prev) => !prev);
  }, [open]);

  const onClickIcon = (icon) => {
    onClick(icon);
    setOpen(false);
    ref.current.close();
  };

  const onCloseIcon = () => {
    setOpen(false);
    ref.current.close();
  };

  // outside clicks
  const useOutsideAlerter = (ref, iRef) => {
    useEffect(() => {
      /**
       * set close if clicked on outside of element
       */
      function handleClickOutside(event) {
        let buttonRef = ref.current && !ref.current.contains(event.target);
        let iconsRef = iRef.current && !iRef.current.contains(event.target);

        if (buttonRef && iconsRef) {
          setOpen(false);
        } else if (buttonRef && !iconsRef) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mouseup", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mouseup", handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, iRef, open]);
  };
  useOutsideAlerter(buttonRef, iconRef);
  // end: outside clicks

  return (
    <div className={`relative h-20 ${label ? "w-24" : "w-20"}`}>
      <CampaignPopupPopOutIcons
        onClick={onClickIcon}
        onClose={onCloseIcon}
        position="right center"
        ref={ref}
        iconRef={iconRef}
      />
      <button
        ref={buttonRef}
        type="button"
        className="bg-white border border-tabBorder h-20 w-20 rounded-full mt-3 box-shadow"
        onClick={() => toogleSelector()}
      >
        {Icon ? (
          <div className="relative">
            <Icon className="w-10 h-10 m-auto" sx={{ fontSize: 40 }} />
          </div>
        ) : (
          <div className="relative">
            <span className="w-10 h-10 m-auto opacity-70">None</span>
          </div>
        )}
      </button>
      {label && <p className="absolute text-xs pt-1">{label}</p>}
    </div>
  );
}
