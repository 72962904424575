const StatisticsAvatarSVG = ({ className }) => {
  return (
    <svg
      width="82"
      height="105"
      viewBox="0 0 82 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="40.2275"
        cy="64.0513"
        r="39.8504"
        fill="#EBEBEB"
        stroke="#BEBEBE"
        strokeWidth="0.754266"
      />
      <g clipPath="url(#clip0_1428_22511)">
        <path
          d="M25.9352 21.7342C26.6805 23.1389 27.138 18.4354 27.8581 18.0092C28.5782 17.5829 34.4935 13.8025 36.4105 12.8229C37.068 12.5238 37.7693 12.3974 38.4737 12.4507C39.178 12.5041 39.8716 12.7363 40.514 13.1338C42.4277 14.2573 43.3184 16.8968 43.7653 19.5225C44.3778 23.1444 45.1968 22.3844 45.6734 24.4286C46.2604 26.9466 46.6527 28.664 46.965 28.9899C47.2755 29.1585 47.6189 29.1831 47.935 29.0595C48.2511 28.9359 48.2883 29.0469 48.4261 28.8701C48.7005 28.5181 48.6963 27.9488 48.6917 26.5673C48.9012 23.4513 48.6223 20.2934 47.8712 17.2794C47.115 14.2525 43.7651 10.5429 43.7651 10.5429C43.7651 10.5429 38.7221 8.01174 35.6691 8.40236C32.6315 8.82279 29.2912 8.35595 27.073 10.9864C26.0024 12.1405 25.4125 12.5855 25.5568 16.2872"
          fill="#263238"
        />
        <path
          d="M31.9951 48.3649C31.9951 48.3649 29.0623 68.2045 28.8649 70.5932L53.9093 72.7846L49.875 53.5005C49.875 53.5005 48.6986 47.1475 48.1178 43.2009C47.1539 36.6524 50.6219 27.3902 50.6219 27.3902C51.2279 19.0893 46.868 10.8271 38.8321 10.4839L38.0867 10.4729C34.4753 10.5416 29.611 11.1923 26.9857 13.7738C24.3603 16.3552 24.0116 19.5648 23.9163 24.4064C23.821 29.2479 23.7948 28.4877 24.3246 32.7139C24.8544 36.9402 25.2638 44.2962 31.9951 48.3649Z"
          fill="#FFBE9D"
        />
        <path
          d="M25.9168 29.1569C25.8919 29.6064 26.8172 31.164 28.262 31.2159C29.7069 31.2677 30.9722 30.1186 30.9971 29.669C31.022 29.2195 29.9063 27.6713 28.4615 27.6194C27.0166 27.5676 25.9417 28.7073 25.9168 29.1569Z"
          fill="white"
        />
        <path
          d="M26.6003 28.8914C26.453 29.3631 26.5094 29.8648 26.7572 30.2889C27.0051 30.713 27.4247 31.0256 27.9261 31.1597C28.167 31.2371 28.4235 31.2682 28.6802 31.2513C28.9369 31.2344 29.1887 31.1698 29.4206 31.0614C29.6524 30.9529 29.8596 30.8028 30.0298 30.62C30.2 30.4372 30.3298 30.2254 30.4113 29.9973C30.5569 29.526 30.5008 29.0254 30.255 28.6011C30.0091 28.1769 29.5927 27.8622 29.0938 27.7237C28.8519 27.6472 28.5947 27.6168 28.3374 27.6343C28.08 27.6518 27.8277 27.7169 27.5954 27.8257C27.363 27.9345 27.1552 28.0849 26.9843 28.2679C26.8134 28.4509 26.6829 28.6629 26.6003 28.8914Z"
          fill="#EB996E"
        />
        <path
          d="M26.6327 28.967C26.506 29.3728 26.5545 29.8045 26.7678 30.1694C26.9811 30.5343 27.3421 30.8033 27.7735 30.9187C27.9808 30.9853 28.2015 31.0121 28.4224 30.9975C28.6433 30.983 28.8599 30.9274 29.0594 30.8341C29.2589 30.7408 29.4372 30.6116 29.5837 30.4543C29.7301 30.297 29.8417 30.1148 29.9119 29.9185C30.0371 29.513 29.9889 29.0822 29.7774 28.7172C29.5658 28.3522 29.2075 28.0814 28.7782 27.9622C28.5701 27.8964 28.3488 27.8702 28.1274 27.8853C27.906 27.9004 27.6889 27.9564 27.4889 28.05C27.289 28.1436 27.1102 28.273 26.9632 28.4305C26.8161 28.588 26.7038 28.7704 26.6327 28.967Z"
          fill="#263238"
        />
        <ellipse
          rx="0.654787"
          ry="0.599582"
          transform="matrix(0.985845 0.162825 -0.207498 0.97965 27.5289 28.9103)"
          fill="white"
        />
        <rect
          x="45"
          y="13.0005"
          width="2.81749"
          height="3.4436"
          fill="#FFBE9D"
        />
        <ellipse
          cx="33.701"
          cy="35.5377"
          rx="0.80868"
          ry="0.80868"
          transform="rotate(1.79121 33.701 35.5377)"
          fill="#EB996E"
        />
        <path
          d="M31.9953 48.3666C35.6023 48.3942 39.7201 47.9494 43.5784 44.2971C43.5784 44.2971 39.4689 51.0417 31.6819 50.2451L31.9953 48.3666Z"
          fill="#EB996E"
        />
        <path
          d="M49.577 30.0469C49.7217 30.026 55.7117 29.8255 53.7114 35.5937C51.711 41.3619 47.2793 38.4571 47.321 38.2871C47.3628 38.1172 49.577 30.0469 49.577 30.0469Z"
          fill="#FFBE9D"
        />
        <path
          d="M49.7936 36.4123C49.7936 36.4123 49.8579 36.5125 49.9864 36.6353C50.0745 36.7186 50.1781 36.782 50.291 36.8217C50.4039 36.8615 50.5239 36.8769 50.6444 36.8671C51.0488 36.7591 51.4232 36.54 51.7293 36.2322C52.0354 35.9245 52.2621 35.5392 52.3862 35.1158C52.5562 34.6528 52.6318 34.1604 52.6077 33.6722C52.6189 33.4865 52.5879 33.3025 52.5171 33.1359C52.4464 32.9692 52.3381 32.8248 52.2013 32.7149C52.1107 32.6483 51.9993 32.6193 51.8871 32.6331C51.775 32.6469 51.6696 32.7027 51.5899 32.7903C51.4668 32.9117 51.5001 33.0494 51.4329 33.0354C51.3657 33.0215 51.3615 32.9037 51.4795 32.6933C51.5527 32.5705 51.6617 32.4758 51.7889 32.4243C51.9683 32.3609 52.161 32.3732 52.3263 32.4585C52.5219 32.5765 52.6826 32.7486 52.792 32.9573C52.9014 33.1661 52.9558 33.404 52.9495 33.6472C53.0022 34.1923 52.9269 34.7483 52.7302 35.266C52.5852 35.7605 52.3078 36.2046 51.9327 36.543C51.5575 36.8814 51.1013 37.0991 50.6207 37.1689C50.471 37.1625 50.3258 37.1206 50.1958 37.0463C50.0658 36.972 49.9543 36.8672 49.8693 36.7394C49.7567 36.5447 49.7746 36.4153 49.7936 36.4123Z"
          fill="#EB996E"
        />
        <path
          d="M40.762 13.8293C38.4141 14.1423 36.6923 13.2856 34.6574 13.8293C32.6226 14.3729 30.348 15.1949 28.7092 12.7338C26.674 9.67752 28.8663 7.09895 30.9006 6.31615C32.935 5.53334 36.1533 8.3506 39.6666 8.35073C43.8923 8.35089 46.4126 9.06829 48.4519 11.8546C50.0083 14.1521 50.9715 17.0091 51.222 20.0709C51.4695 23.1193 51.2279 26.2296 50.5112 29.219L50.506 29.2505C50.2883 30.5691 50.198 31.1159 49.8724 31.4101C49.7075 31.559 49.689 31.4451 49.357 31.5131C49.0249 31.5811 49.18 31.5751 48.9014 31.3605C48.6467 30.9929 49.5473 25.5689 47.3359 22.5949C45.1244 19.6209 48.7647 15.7643 46.5532 13.8293C44.3418 11.8943 43.1099 13.5162 40.762 13.8293Z"
          fill="#263238"
        />
        <path
          d="M37.1255 28.7952C37.2811 28.909 38.286 28.3781 39.6684 28.348C41.0508 28.3179 42.0358 28.7752 42.1933 28.6515C42.2724 28.599 42.1229 28.3911 41.6957 28.175C41.1152 27.899 40.4123 27.7628 39.683 27.7849C38.964 27.8002 38.2522 27.977 37.6522 28.2892C37.2115 28.5268 37.0523 28.7443 37.1255 28.7952Z"
          fill="#263238"
        />
        <path
          d="M34.3667 40.8213C34.3856 40.6852 34.943 40.9932 35.4689 40.7604C35.9948 40.5276 36.2049 39.9126 36.3062 39.9989C36.3537 40.0332 36.3695 40.2564 36.2844 40.5398C36.1629 40.9083 35.9476 41.1688 35.6679 41.2858C35.3945 41.4072 35.0683 41.3759 34.7372 41.1964C34.4911 41.0568 34.357 40.8829 34.3667 40.8213Z"
          fill="#263238"
        />
        <path
          d="M26.0395 27.1951C26.1951 27.3089 27.2001 26.778 28.5825 26.7479C29.9649 26.7178 30.9499 27.1751 31.1074 27.0514C31.1864 26.9989 31.037 26.791 30.6098 26.5749C30.0293 26.2989 29.3264 26.1627 28.5971 26.1848C27.8781 26.2002 27.1663 26.3769 26.5662 26.6891C26.1256 26.9267 25.9664 27.1442 26.0395 27.1951Z"
          fill="#263238"
        />
        <path
          d="M35.9339 24.4091C36.7393 25.1318 42.1466 23.4181 45.9126 27.063C45.2986 21.4231 36.4235 23.0772 35.9499 23.1482C35.9499 23.1482 35.1285 23.6863 35.9339 24.4091Z"
          fill="#263238"
        />
        <path
          d="M31.9026 22.3805C31.3384 23.0078 27.7061 20.7096 25.0615 23.9057C25.6124 18.3824 31.6017 21.0059 31.9217 21.1287C31.9217 21.1287 32.4669 21.7532 31.9026 22.3805Z"
          fill="#263238"
        />
        <path
          d="M33.5343 26.2483C33.345 27.289 33.107 28.3023 32.9051 29.3334L32.603 30.8856L32.2884 32.4281C32.1756 32.9396 32.0865 33.4593 31.9863 33.9803L31.9017 34.364L31.8802 34.4571C31.8817 34.4682 31.8735 34.4918 31.875 34.5029L31.8683 34.5377C31.8713 34.5598 31.8617 34.5724 31.8646 34.5946C31.8675 34.6168 31.8579 34.6293 31.8609 34.6515C31.8674 34.786 31.9309 34.9243 32.0454 35.022C32.0971 35.0716 32.1695 35.1071 32.239 35.1205C32.2737 35.1272 32.3085 35.1338 32.3543 35.139L32.3876 35.1346L32.4319 35.1288L32.5332 35.1266C32.8023 35.1135 33.0603 35.1019 33.3294 35.0888C33.858 35.0752 34.388 35.0726 34.924 35.1144L34.9343 35.192C34.4277 35.372 33.9153 35.5077 33.4014 35.6322C33.1382 35.6897 32.8861 35.7456 32.6229 35.8031L32.5245 35.8274L32.4802 35.8333L32.4136 35.8421C32.3249 35.8539 32.2236 35.856 32.132 35.8456C31.9487 35.8248 31.7595 35.7596 31.5991 35.6567C31.4388 35.5538 31.3073 35.4132 31.2061 35.2461C31.105 35.0789 31.0564 34.8823 31.0425 34.6923C31.0366 34.6479 31.0403 34.591 31.0455 34.5452C31.0507 34.4993 31.056 34.4535 31.0612 34.4077L31.0745 34.3382L31.0908 34.2909L31.122 34.1852L31.2302 33.8097C31.3777 33.3049 31.5363 32.7987 31.6727 32.2953L32.0915 30.7728L32.5215 29.2488C32.815 28.2281 33.2311 26.514 33.4161 26.2074C33.6011 25.9009 33.5343 26.2483 33.5343 26.2483Z"
          fill="#263238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.8465 51.4009C47.8608 51.6301 48.0858 53.4524 48.3608 55.6703C48.5604 56.8749 48.384 58.114 47.8572 59.2078C47.3861 60.1448 46.8146 61.024 46.1535 61.8286L42.7031 64.941L41.9388 67.8982L37.2955 61.0523C33.6274 60.0654 31.9129 54.3692 31.9129 54.3692L31.5004 51.7275L29.2685 54.034L25.2039 55.1023C25.2039 55.1023 16.1281 58.0595 13.8672 60.61L21.2964 96.3769L21.1643 107.666H64.1966C64.1966 107.108 64.5824 70.1912 64.5824 70.1912L67.1648 55.7048L51.8813 52.9214L49.3439 50.8555C48.473 51.2551 47.8465 51.4009 47.8465 51.4009Z"
          fill="#EC5627"
        />
        <path
          d="M56.375 95.7424C57.0113 94.8894 57.6969 94.0768 58.4278 93.3092C59.1151 92.496 59.8479 91.7252 60.6226 91.001C59.9837 91.8541 59.2971 92.6678 58.5663 93.4379C57.8791 94.2489 57.1475 95.0183 56.375 95.7424Z"
          fill="white"
        />
        <path
          d="M56.5237 86.574C55.5448 85.5756 54.8084 84.3512 54.375 83.0014C54.4283 82.9793 54.8083 83.8357 55.4049 84.8171C56.0016 85.7985 56.5663 86.5373 56.5237 86.574Z"
          fill="white"
        />
        <path
          d="M21.375 79.7424C22.0113 78.8894 22.6969 78.0768 23.4278 77.3092C24.1151 76.496 24.8479 75.7252 25.6226 75.001C24.9837 75.8541 24.2971 76.6678 23.5663 77.4379C22.8791 78.2489 22.1475 79.0183 21.375 79.7424Z"
          fill="white"
        />
        <path
          d="M24.375 91.147C24.5236 89.7431 24.7922 88.3557 25.1777 87.001C25.1347 87.7062 25.0313 88.4061 24.8687 89.0924C24.765 89.7914 24.5998 90.4791 24.375 91.147Z"
          fill="white"
        />
        <path
          d="M67.169 55.7036C68.7859 56.6553 70.2044 57.9293 71.3423 59.4521C72.4802 60.9749 73.3149 62.7162 73.7982 64.5751C75.5233 70.9626 80.7988 87.5966 80.7988 87.5966C80.7988 87.5966 83.8134 91.2672 84.1348 93.9841C84.4563 96.7009 83.8134 103.277 82.8418 104.504C82.5605 104.86 82.1992 105.14 81.7896 105.318C81.38 105.497 80.9344 105.569 80.4916 105.528L67.8012 104.282L62.8008 69.654L67.169 55.7036Z"
          fill="#EC5627"
        />
        <path
          d="M13.8721 60.6089C13.8721 60.6089 10.8825 64.8265 9.64313 67.5804C8.40374 70.3343 -1.88646 93.4556 -2.5008 95.8472C-3.11514 98.2388 -4.71528 105.757 -0.0184407 107.502H9.65027L19.3511 87.1975L19.8476 63.6326L13.8721 60.6089Z"
          fill="#EC5627"
        />
        <path
          d="M10.256 92.8349C10.256 92.8349 10.0417 92.639 9.59527 92.3766C8.95802 92.0228 8.2747 91.7664 7.56652 91.6151C7.07963 91.5047 6.58529 91.433 6.08783 91.4007C5.51467 91.356 4.93827 91.3984 4.37696 91.5264C3.10637 91.8641 1.96903 92.6031 1.1231 93.6407C0.262961 94.6667 -0.366506 95.8775 -0.719922 97.1856C-1.01165 98.2549 -1.20537 99.3502 -1.29854 100.457C-1.39141 101.403 -1.42355 102.176 -1.4557 102.704C-1.4557 102.956 -1.48427 103.159 -1.49141 103.318C-1.49122 103.391 -1.50084 103.463 -1.51999 103.532C-1.52459 103.461 -1.52459 103.389 -1.51999 103.318C-1.51999 103.155 -1.51999 102.948 -1.51999 102.704C-1.51999 102.168 -1.49856 101.4 -1.42356 100.446C-1.34804 99.321 -1.16499 98.2066 -0.877079 97.1191C-0.526156 95.7813 0.112436 94.543 0.990946 93.4966C1.86329 92.43 3.03797 91.6747 4.34839 91.3378C4.92128 91.2144 5.50884 91.1808 6.09139 91.238C6.59567 91.2764 7.09615 91.3567 7.58795 91.4783C8.30406 91.6431 8.99095 91.9224 9.62385 92.3063C9.79431 92.4153 9.95561 92.539 10.106 92.676C10.1613 92.7233 10.2116 92.7766 10.256 92.8349Z"
          fill="#263238"
        />
        <path
          d="M5.67578 88.7915C5.78147 88.7596 5.89075 88.7421 6.00081 88.7397C6.2992 88.7157 6.59893 88.7157 6.89731 88.7397C7.34161 88.7667 7.7827 88.8347 8.21529 88.943C9.27963 89.2093 10.2785 89.7028 11.1477 90.392C11.5018 90.6709 11.8306 90.9827 12.1299 91.3235C12.3328 91.5546 12.5179 91.8018 12.6835 92.0628C12.751 92.1533 12.8062 92.2527 12.8478 92.3585C12.8193 92.3807 12.555 91.9889 12.0335 91.427C11.7254 91.1075 11.3947 90.8122 11.0441 90.5436C10.6124 90.2183 10.1534 89.9338 9.67256 89.6934C9.18901 89.46 8.68715 89.2695 8.17243 89.1241C7.75025 89.0116 7.3204 88.9325 6.8866 88.8876C6.14367 88.7877 5.67578 88.8284 5.67578 88.7915Z"
          fill="#263238"
        />
        <path
          d="M14.414 87.6775C14.2645 87.6838 14.1147 87.6726 13.9676 87.6442C13.6818 87.6073 13.2711 87.5629 12.7568 87.5223C10.0856 87.3161 7.40112 87.6488 4.85248 88.5018C4.36316 88.6645 3.97026 88.8086 3.70238 88.9158C3.56568 88.9784 3.4233 89.0266 3.27734 89.06C3.40029 88.9716 3.53349 88.8996 3.6738 88.8456C3.93454 88.7162 4.32387 88.5536 4.80963 88.3724C7.36015 87.4378 10.0796 87.1019 12.771 87.3892C13.2818 87.4446 13.6961 87.5075 13.9783 87.5703C14.1265 87.5916 14.2724 87.6275 14.414 87.6775Z"
          fill="#263238"
        />
        <path
          d="M41.9496 67.9214C41.9104 67.8862 41.8767 67.8451 41.8496 67.7994L41.5853 67.4297L40.6137 66.0399C39.7815 64.82 38.6171 63.1197 37.2492 61.1236L37.3992 61.1014C36.8777 62.6835 36.3026 64.4282 35.699 66.2764L35.6597 66.391L35.5597 66.3245L35.3597 66.1914C34.5815 65.6758 33.8739 65.0542 33.2559 64.3432C32.6849 63.6812 32.181 62.9604 31.7522 62.1919C31.0386 60.8914 30.4832 59.5045 30.0985 58.0629C29.8403 57.0892 29.6316 56.1021 29.4735 55.1058C29.4163 54.7731 29.3735 54.5032 29.3377 54.2999C29.3276 54.231 29.3121 54.163 29.2913 54.0966C29.2913 54.0523 29.2663 54.0301 29.2699 54.0264C29.2735 54.0227 29.4163 54.396 29.5449 55.0947C29.6735 55.7933 29.8771 56.7987 30.2271 58.0149C30.6227 59.4431 31.1852 60.816 31.9023 62.1031C32.3298 62.8573 32.8287 63.5655 33.3917 64.2175C33.9989 64.9115 34.6933 65.5182 35.4561 66.0214L35.6526 66.1544L35.5169 66.2062C36.1276 64.358 36.7063 62.6243 37.2349 61.0312L37.2885 60.8685L37.3849 61.009C38.7279 63.0236 39.8851 64.7387 40.6959 65.9659C41.0852 66.5648 41.4102 67.0416 41.6281 67.3891L41.8674 67.7587C41.9003 67.8098 41.9278 67.8643 41.9496 67.9214Z"
          fill="#263238"
        />
        <path
          d="M81.3245 95.3629C80.9147 94.9224 80.4755 94.5123 80.0101 94.1356C78.8665 93.2501 77.4952 92.7344 76.0691 92.6535C74.6429 92.5726 73.2254 92.9301 71.9951 93.681C71.4959 94.003 71.0186 94.3599 70.5664 94.7493C70.6608 94.6136 70.7698 94.4896 70.8914 94.3796C71.2129 94.0705 71.5635 93.7956 71.938 93.559C73.1802 92.7582 74.6309 92.3736 76.0922 92.4576C77.5535 92.5416 78.9541 93.0902 80.103 94.0284C80.4504 94.305 80.7688 94.6184 81.0531 94.9636C81.1583 95.0853 81.2494 95.2193 81.3245 95.3629Z"
          fill="#263238"
        />
        <path
          d="M80.8175 91.9508C80.3067 91.5711 79.754 91.2559 79.171 91.0119C77.7379 90.4854 76.1813 90.4376 74.7206 90.8752C73.917 91.1085 73.1435 91.4411 72.4168 91.8658C71.8252 92.2176 71.2668 92.626 70.7488 93.0856C70.3059 93.4775 69.9666 93.8249 69.7344 94.0578C69.6205 94.1925 69.4949 94.3162 69.3594 94.4275C69.4523 94.2782 69.56 94.1395 69.6808 94.0135C69.9893 93.6533 70.3173 93.3114 70.6631 92.9895C71.1764 92.5098 71.7352 92.085 72.3311 91.7217C73.0651 91.2777 73.8515 90.9336 74.6706 90.6977C76.1671 90.2453 77.7659 90.3141 79.221 90.8936C79.6555 91.0733 80.0651 91.312 80.4389 91.6034C80.5711 91.7032 80.6604 91.7993 80.7282 91.8584C80.7961 91.9176 80.8175 91.9471 80.8175 91.9508Z"
          fill="#263238"
        />
        <path
          d="M80.8458 88.9953C80.8458 89.0323 80.1315 88.8142 78.9957 88.6552C78.3244 88.562 77.6477 88.5176 76.9705 88.5221C76.1409 88.5316 75.3133 88.6082 74.4953 88.7513C73.6789 88.9066 72.8753 89.1266 72.0915 89.4093C71.4526 89.6441 70.8308 89.9257 70.2306 90.2521C69.2055 90.8066 68.6269 91.2575 68.6055 91.2243C68.7268 91.1024 68.8596 90.9934 69.0019 90.899C69.1575 90.7722 69.321 90.6562 69.4913 90.5515C69.6877 90.4221 69.9092 90.2706 70.1663 90.1301C70.7667 89.7864 71.3911 89.4899 72.0343 89.243C72.8237 88.9449 73.6362 88.7173 74.4631 88.5628C75.2892 88.4181 76.1255 88.3452 76.9633 88.3447C77.6488 88.3483 78.3331 88.4063 79.01 88.5184C79.2993 88.5554 79.5564 88.6257 79.7814 88.6737C79.9769 88.7146 80.17 88.7665 80.3601 88.829C80.5263 88.8697 80.6889 88.9254 80.8458 88.9953Z"
          fill="#263238"
        />
        <path
          d="M50.5495 52.7979C50.6976 53.142 50.7894 53.5093 50.821 53.8846C50.9276 54.9448 50.877 56.0155 50.671 57.0599C50.5457 57.8095 50.3751 58.5502 50.1602 59.2777C49.8786 60.1243 49.5323 60.9463 49.1244 61.7359C48.2708 63.4362 47.1992 65.2882 45.9563 67.2806L45.8134 67.5134V67.2362C45.8134 65.9277 45.8134 64.5563 45.8134 63.1331L45.9527 63.2034L42.5988 65.972L42.6274 65.9277C41.8318 68.6259 41.3428 71.4108 41.1701 74.2262C40.8415 78.7248 41.1059 83.0274 41.3094 86.9309C41.513 90.8343 41.6023 94.3571 41.7202 97.3142C41.8381 100.271 41.9345 102.659 42.0202 104.312C42.0702 105.132 42.1059 105.768 42.1309 106.212C42.1309 106.422 42.1559 106.581 42.1631 106.707C42.1702 106.833 42.1631 106.877 42.1631 106.877C42.1631 106.877 42.1631 106.821 42.1381 106.711C42.1131 106.6 42.1095 106.426 42.0881 106.215C42.0488 105.783 41.9988 105.143 41.9452 104.315C41.8381 102.667 41.713 100.275 41.588 97.3179C41.463 94.3608 41.3094 90.8454 41.138 86.9383C40.9665 83.0311 40.6558 78.7248 40.9844 74.2114C41.165 71.3793 41.6673 68.579 42.481 65.8685V65.8426H42.5024L45.8455 63.0666L45.9849 62.9483V63.1331C45.9849 64.5563 45.9849 65.9277 45.9849 67.2362L45.8384 67.1881C48.2922 63.2514 50.2888 59.8285 50.596 57.0599C50.8081 56.0254 50.8731 54.9644 50.7888 53.9105C50.7597 53.6336 50.7107 53.3593 50.6424 53.0899C50.571 52.8903 50.5495 52.7979 50.5495 52.7979Z"
          fill="#263238"
        />
        <path
          d="M44.1396 100.479H44.0253C43.7652 100.496 43.5218 100.617 43.3466 100.817C43.1714 101.016 43.0781 101.279 43.0865 101.548C43.0948 101.818 43.2042 102.074 43.3914 102.261C43.5786 102.449 43.829 102.554 44.0896 102.554C44.3502 102.554 44.6006 102.449 44.7878 102.261C44.9751 102.074 45.0844 101.818 45.0927 101.548C45.1011 101.279 45.0078 101.016 44.8326 100.817C44.6574 100.617 44.414 100.496 44.1539 100.479H44.1396Z"
          fill="white"
        />
        <path
          d="M44.1399 86.2329H44.0256C43.7665 86.2502 43.524 86.3711 43.3495 86.5701C43.1749 86.769 43.082 87.0305 43.0904 87.2991C43.0987 87.5677 43.2077 87.8224 43.3942 88.0093C43.5808 88.1963 43.8303 88.3008 44.0899 88.3008C44.3496 88.3008 44.5991 88.1963 44.7856 88.0093C44.9722 87.8224 45.0812 87.5677 45.0895 87.2991C45.0979 87.0305 45.0049 86.769 44.8304 86.5701C44.6558 86.3711 44.4134 86.2502 44.1542 86.2329H44.1399Z"
          fill="white"
        />
        <path
          d="M44.0307 73.4854C44.2973 73.5035 44.56 73.4113 44.761 73.229C44.9619 73.0467 45.0847 72.7892 45.1022 72.5132C45.1197 72.2373 45.0306 71.9654 44.8544 71.7574C44.6783 71.5495 44.4295 71.4224 44.1628 71.4043H44.0307C43.7635 71.4043 43.5073 71.5141 43.3184 71.7096C43.1296 71.9051 43.0234 72.1702 43.0234 72.4467C43.0234 72.7232 43.1296 72.9883 43.3184 73.1838C43.5073 73.3793 43.7635 73.4891 44.0307 73.4891V73.4854Z"
          fill="white"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M29.3841 54.4902C28.8876 59.5063 30.7306 64.8033 34.6202 67.8418C34.9238 68.0821 35.306 68.3187 35.6668 68.1819C35.9073 68.0613 36.0994 67.8572 36.2097 67.6052C37.299 65.6575 37.7076 63.3813 37.3669 61.1586C36.7526 62.8368 36.224 64.4854 35.6025 66.2376C32.22 64.1084 30.0984 59.5839 29.3841 54.4902Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.3"
          d="M42.5547 65.8988L45.4121 64.8194L45.8836 69.8134C45.8836 69.8134 48.9624 64.0062 50.2375 61.3596C51.5126 58.7129 50.8554 54.0332 50.8554 54.0332C50.8554 54.0332 51.309 58.0993 48.2266 63.3002L45.8978 67.2184V63.1154L42.5547 65.8988Z"
          fill="black"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M9.37647 91.2791C8.07386 90.7065 6.64283 90.5209 5.24396 90.7431C3.75328 90.8693 2.32718 91.4279 1.12932 92.3548C-0.0279274 93.3195 -0.810139 94.8425 -0.656554 96.3728L-0.513687 96.3913C0.48283 93.6042 2.46158 91.5859 5.01538 91.3308C7.56917 91.0758 10.0587 92.2587 11.8017 94.1993C11.6124 92.7503 10.6337 91.8964 9.37647 91.2791Z"
            fill="black"
          />
        </g>
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M81.5034 94.1158C80.5135 93.0643 79.2195 92.3752 77.8173 92.1529C76.596 91.9414 75.3444 92.0166 74.1549 92.373C72.9654 92.7294 71.8686 93.3578 70.9453 94.2119L71.4882 93.7535C72.8694 92.8927 74.455 92.4473 76.0672 92.4671C77.6852 92.4958 79.2369 93.1376 80.4283 94.271C81.4998 95.3208 82.1392 96.7439 82.7499 98.1301C82.8488 97.407 82.7881 96.6701 82.5722 95.9748C82.3562 95.2795 81.9908 94.6439 81.5034 94.1158Z"
            fill="black"
          />
        </g>
        <path
          d="M63.1172 70.9372C63.1462 70.7986 63.1893 70.6636 63.2458 70.5343C63.3565 70.2423 63.4922 69.8837 63.6494 69.4586C64.0065 68.553 64.4709 67.3036 64.9709 65.9101C65.471 64.5165 65.9031 63.2486 66.2139 62.3319L66.5711 61.2414C66.6094 61.1052 66.6621 60.9738 66.7282 60.8496C66.7171 60.9912 66.6871 61.1306 66.6389 61.2636C66.5711 61.5298 66.4639 61.9068 66.3246 62.3725C66.046 63.3041 65.6317 64.5904 65.1316 65.9766C64.6316 67.3628 64.1351 68.6195 63.7565 69.5104C63.5708 69.9577 63.3994 70.3162 63.2958 70.5639C63.2513 70.6954 63.1913 70.8208 63.1172 70.9372Z"
          fill="#263238"
        />
        <path
          d="M18.0873 71.2993C18.0373 71.2993 17.648 69.5509 17.2194 67.37C16.7908 65.1891 16.4836 63.4333 16.5229 63.4037C16.5622 63.3741 16.9587 65.1521 17.3873 67.333C17.8159 69.5139 18.1373 71.2882 18.0873 71.2993Z"
          fill="#263238"
        />
        <path
          d="M11.257 79.6971C10.6151 78.6197 10.0262 77.5093 9.4926 76.3703C8.56395 74.5221 7.8496 72.9844 7.89246 72.9585C8.53441 74.0359 9.12331 75.1463 9.6569 76.2853C10.2462 77.3936 10.7802 78.5324 11.257 79.6971Z"
          fill="white"
        />
        <path
          d="M22.957 66.2085C23.2615 65.2608 23.6254 64.3348 24.0464 63.4361C24.4118 62.5127 24.8329 61.6139 25.3072 60.7451C25.0008 61.692 24.637 62.618 24.2179 63.5175C23.8534 64.4413 23.4322 65.3402 22.957 66.2085Z"
          fill="white"
        />
        <path
          d="M34.4325 74.9605C33.8399 74.3704 33.2907 73.7353 32.7895 73.0605C32.2433 72.4256 31.743 71.7498 31.293 71.0386C31.886 71.6271 32.4342 72.2624 32.9324 72.9386C33.4804 73.5729 33.9819 74.2488 34.4325 74.9605Z"
          fill="white"
        />
        <path
          d="M31.793 87.0004C32.1554 86.3246 32.5686 85.6795 33.0288 85.0708C33.4431 84.4275 33.9078 83.8204 34.4182 83.2559C34.0567 83.9322 33.6435 84.5774 33.1824 85.1854C32.7703 85.8304 32.3055 86.4377 31.793 87.0004Z"
          fill="white"
        />
        <path
          d="M28.2425 104.008C28.0758 103.78 27.9355 103.531 27.8246 103.269C27.5889 102.796 27.2853 102.134 26.9781 101.391C26.6709 100.648 26.4245 99.9569 26.2638 99.4579C26.157 99.1919 26.0802 98.9141 26.0352 98.6299C26.1823 98.8751 26.3021 99.1367 26.3923 99.4098L27.1603 101.314C27.4567 102.02 27.721 102.659 27.9532 103.21C28.078 103.464 28.175 103.732 28.2425 104.008Z"
          fill="white"
        />
        <path
          d="M38.315 96.7773C37.5846 96.7272 36.8594 96.6147 36.147 96.4409C35.4195 96.3294 34.7025 96.1538 34.0039 95.916C35.4535 96.0777 36.8873 96.3658 38.29 96.7773H38.315Z"
          fill="white"
        />
        <path
          d="M46.1758 80.889C46.8157 80.0311 47.5052 79.2139 48.2402 78.442C48.9314 77.6241 49.6685 76.849 50.4476 76.1206C49.805 76.9786 49.1145 77.7969 48.3796 78.5714C47.6885 79.387 46.9527 80.1608 46.1758 80.889Z"
          fill="white"
        />
        <path
          d="M54.0275 102.079C53.9882 102.116 52.8345 100.729 51.4487 98.9921C50.0628 97.2548 48.9735 95.8132 49.0127 95.7799C49.052 95.7466 50.2057 97.1291 51.5915 98.8664C52.9774 100.604 54.0596 102.045 54.0275 102.079Z"
          fill="white"
        />
        <path
          d="M5.16789 93.0498C5.1929 93.1016 4.13923 93.656 2.98913 94.5691C1.83903 95.4821 1.03895 96.384 0.996094 96.3433C1.12593 96.1093 1.28976 95.8974 1.48185 95.7149C1.90962 95.2422 2.37564 94.8081 2.87483 94.4175C3.37189 94.0248 3.9 93.6761 4.45355 93.3751C4.67511 93.2313 4.91576 93.1217 5.16789 93.0498Z"
          fill="white"
        />
        <path
          d="M8.10932 84.8721C7.88236 85.3951 7.6009 85.8909 7.26996 86.3507C6.988 86.8458 6.65377 87.307 6.27344 87.7257C6.501 87.203 6.78242 86.7073 7.1128 86.2472C7.39401 85.7515 7.7283 85.2902 8.10932 84.8721Z"
          fill="white"
        />
        <path
          d="M14.7437 92.9002C14.1718 91.9591 13.9274 90.8442 14.0508 89.7397C14.1044 89.7397 14.1222 90.479 14.3187 91.3403C14.5151 92.2016 14.7937 92.8743 14.7437 92.9002Z"
          fill="white"
        />
        <path
          d="M15.7202 67.4247C15.2761 67.5851 14.814 67.6857 14.3451 67.7241C13.8839 67.8244 13.4118 67.8604 12.9414 67.8313C13.3868 67.6712 13.8501 67.5705 14.3201 67.5319C14.7804 67.4338 15.251 67.3977 15.7202 67.4247Z"
          fill="white"
        />
        <path
          d="M20.6994 58.2847C19.9302 58.8738 19.0896 59.356 18.1992 59.7189C18.5647 59.3981 18.9694 59.1284 19.4029 58.9168C19.8075 58.6501 20.2431 58.4377 20.6994 58.2847Z"
          fill="white"
        />
        <path
          d="M34.7089 62.1465C34.2042 61.6153 33.7431 61.0416 33.3302 60.4313C32.872 59.858 32.461 59.246 32.1016 58.6016C33.068 59.7031 33.9403 60.8891 34.7089 62.1465Z"
          fill="white"
        />
        <path
          d="M52.4812 71.336C51.4967 70.3319 50.7562 69.1006 50.3203 67.7431C50.3739 67.7209 50.7561 68.5822 51.3561 69.5691C51.9562 70.5561 52.5241 71.2991 52.4812 71.336Z"
          fill="white"
        />
        <path
          d="M59.268 73.6494C58.837 74.9489 58.2868 76.2028 57.625 77.3939C57.8154 76.7352 58.0629 76.0956 58.3644 75.4829C58.6088 74.8435 58.9113 74.2296 59.268 73.6494Z"
          fill="white"
        />
        <path
          d="M60.2182 64.0649C59.5688 63.4125 58.9649 62.7134 58.4109 61.9728C57.8089 61.2736 57.254 60.5326 56.75 59.7549C57.9998 61.1079 59.1586 62.548 60.2182 64.0649Z"
          fill="white"
        />
        <path
          d="M49.8558 56.2807C49.7427 55.6916 49.6888 55.0921 49.6951 54.4916C49.6413 53.8923 49.6496 53.2888 49.7201 52.6914C49.8333 53.2804 49.8872 53.88 49.8809 54.4805C49.9369 55.0797 49.9285 55.6834 49.8558 56.2807Z"
          fill="white"
        />
        <path
          d="M58.3189 55.7964C57.595 55.9928 56.8568 56.1275 56.1116 56.1993C55.3756 56.3331 54.6301 56.4036 53.8828 56.41C54.6067 56.2136 55.345 56.0789 56.0902 56.0071C56.8262 55.8731 57.5716 55.8026 58.3189 55.7964Z"
          fill="white"
        />
        <path
          d="M71.6109 65.9134C71.1149 65.0711 70.6711 64.1969 70.2823 63.2963C69.8406 62.4224 69.454 61.5198 69.125 60.5942C69.623 61.436 70.068 62.3102 70.4573 63.2113C70.8974 64.0855 71.2827 64.9881 71.6109 65.9134Z"
          fill="white"
        />
        <path
          d="M69.3867 74.654C69.5362 73.2421 69.8063 71.8468 70.1939 70.4844C70.1508 71.1936 70.0468 71.8975 69.8832 72.5877C69.779 73.2907 69.6128 73.9823 69.3867 74.654Z"
          fill="white"
        />
        <path
          d="M75.5436 81.5441C75.1271 80.623 74.7691 79.6746 74.4721 78.7052C74.118 77.7574 73.8233 76.7869 73.5898 75.7998C74.0039 76.7233 74.3618 77.6727 74.6614 78.6424C75.014 79.5895 75.3086 80.5587 75.5436 81.5441Z"
          fill="white"
        />
        <path
          d="M74.6843 85.5777C74.0467 85.4784 73.434 85.2511 72.8806 84.9086C72.2995 84.6198 71.7807 84.2124 71.3555 83.711C71.3912 83.6666 72.0484 84.2285 72.9699 84.7386C73.8914 85.2487 74.7022 85.5222 74.6843 85.5777Z"
          fill="white"
        />
        <path
          d="M35.8896 107.586C35.7373 107.053 35.6439 106.504 35.611 105.949C35.5206 105.401 35.4894 104.844 35.5182 104.289C35.6813 104.825 35.7808 105.38 35.8146 105.941C35.9002 106.485 35.9253 107.037 35.8896 107.586Z"
          fill="white"
        />
        <path
          d="M60.1484 106.951C60.1648 106.41 60.2428 105.873 60.3806 105.35C60.4611 104.815 60.6013 104.292 60.7985 103.791C60.7821 104.332 60.7029 104.869 60.5628 105.391C60.4844 105.926 60.3454 106.45 60.1484 106.951Z"
          fill="white"
        />
        <path
          d="M10.8475 36.7888C10.8475 36.7888 16.6689 40.4464 19.1682 33.1101C21.1432 27.3534 20.0539 21.3416 16.5097 19.7799L17.2284 18.8847C17.2284 18.8847 22.8032 20.4607 22.7706 27.6067C22.7381 34.7526 19.3239 39.5842 15.973 40.0765C12.6222 40.5688 10.7088 37.5117 10.7088 37.5117L10.7248 36.4713"
          fill="#455A64"
        />
        <path
          d="M46.8629 117.592C41.1514 106.674 15.5786 102.441 15.9824 101.5L18.9824 64.0005L8.72802 65.1331C8.72802 65.1331 0.74018 108.893 10.4816 111.5C23.8032 115.078 34.1595 124.595 34.1595 124.595C34.1595 124.595 50.9647 125.433 46.8629 117.592Z"
          fill="#FFBE9D"
        />
        <path
          d="M13.0676 49.4001L13.1258 49.7602L15.6857 64.9249L8.87777 65.6092L7.05638 57.0014"
          fill="#FFBE9D"
        />
        <path
          d="M14.6036 47.3218L14.3135 56.2043L15.1563 64.4462L19.0005 64C19.0005 64 20.1906 62.2738 20.3969 58.457C20.5091 55.7076 18.8043 54.078 17.0005 52C17.0005 52 16.9786 42.3886 15.4999 42.5001C14.0212 42.6117 14.6036 47.3218 14.6036 47.3218Z"
          fill="#FFBE9D"
        />
        <path
          d="M15.9902 39.7481C15.972 40.4997 13.949 66.8659 13.949 66.8659L11.1585 66.2793L12.8052 38.4068L15.9902 39.7481Z"
          fill="#263238"
        />
        <path
          d="M8.77958 64.282C8.77958 64.282 5.4491 57.7658 6.03855 55.2305C6.25062 54.3174 8.08706 42.7042 10.4831 43.4159C12.8791 44.1277 16.391 50.6196 14.9766 51.3565C14.5448 51.5406 14.0641 51.5764 13.6098 51.4583C13.1555 51.3402 12.7532 51.0748 12.4657 50.7036C12.4657 50.7036 15.965 54.9734 14.6144 55.4898C13.2637 56.0061 11.1428 53.3486 11.1428 53.3486C11.1428 53.3486 15.8617 58.201 14.1464 59.0776C12.431 59.9542 8.44028 55.9958 8.44028 55.9958L8.77958 64.282Z"
          fill="#FFBE9D"
        />
        <path
          d="M9.92578 47.1099C10.872 47.9058 11.7008 48.8317 12.3876 49.8599C12.6535 50.2325 12.9442 50.5868 13.2575 50.9205C13.5077 51.1641 13.8196 51.3348 14.1596 51.4144C14.7552 51.5377 15.1472 51.3692 15.1829 51.4469C15.2186 51.5247 14.848 51.8311 14.1001 51.7894C13.6636 51.7556 13.2496 51.5821 12.9195 51.2947C12.5534 50.9649 12.2268 50.5938 11.9462 50.1888C11.1192 49.2719 10.4378 48.2335 9.92578 47.1099Z"
          fill="#EB996E"
        />
        <path
          d="M8.60938 49.9873C8.76117 49.9393 10.5346 52.2726 13.0891 54.8931C13.4062 55.227 13.7073 55.5102 14.0126 55.525C14.3178 55.5397 14.4983 55.3713 14.487 55.0187C14.4345 54.6665 14.3264 54.325 14.1668 54.0067C14.0607 53.7049 13.9375 53.4094 13.7978 53.1216C13.2906 52.0464 12.8434 51.442 12.9046 51.3892C13.4341 51.7984 13.8576 52.3287 14.1396 52.9355C14.3082 53.2274 14.4594 53.529 14.5924 53.8387C14.7831 54.1979 14.9068 54.5888 14.9573 54.9923C14.9853 55.2636 14.9078 55.5353 14.7409 55.751C14.639 55.86 14.5138 55.9445 14.3746 55.9982C14.2354 56.0519 14.0859 56.0733 13.9372 56.0609C13.4284 55.9597 12.9742 55.6757 12.6605 55.2625C11.0461 53.7236 9.67956 51.9441 8.60938 49.9873Z"
          fill="#EB996E"
        />
        <path
          d="M7.94531 53.8706C8.54549 54.2597 9.09535 54.7214 9.58243 55.2452L11.209 56.868C11.5309 57.1817 11.8549 57.5022 12.1811 57.8295C12.497 58.1691 12.8415 58.481 13.2107 58.7617C13.3532 58.8699 13.5307 58.9214 13.7089 58.9062C13.8872 58.8909 14.0534 58.81 14.1754 58.6792C14.2701 58.5242 14.3235 58.3475 14.3304 58.166C14.3373 57.9845 14.2975 57.8043 14.2148 57.6425C13.8242 57.0097 13.3941 56.4021 12.9269 55.8235C12.4471 55.2639 12.0445 54.6425 11.7301 53.9757C12.2827 54.4311 12.7795 54.9503 13.2101 55.5224L13.9057 56.3488C14.2029 56.6647 14.4571 57.0185 14.6616 57.401C14.7917 57.644 14.8546 57.9172 14.8439 58.1926C14.8331 58.468 14.7492 58.7355 14.6006 58.9677C14.5096 59.0989 14.3902 59.2079 14.2513 59.2865C14.1123 59.3652 13.9574 59.4115 13.7981 59.4219C13.4933 59.4388 13.1905 59.364 12.9286 59.2072C12.5083 58.9113 12.1258 58.565 11.7898 58.1759L10.8347 57.1979L9.24321 55.4749C8.74929 54.9928 8.31368 54.4543 7.94531 53.8706Z"
          fill="#EB996E"
        />
        <path
          d="M16.8334 40.6161C16.0474 40.8696 15.2168 40.9541 14.3959 40.864C13.2081 40.6815 12.0855 40.2022 11.1321 39.4705C10.1787 38.7389 9.42538 37.7785 8.94177 36.6783C7.74657 34.1023 7.27963 31.2482 7.59164 28.4256C7.90365 25.603 8.98269 22.9198 10.7117 20.6671C11.3875 19.6618 12.3258 18.8608 13.4246 18.3509C14.5234 17.841 15.7409 17.6418 16.9449 17.7747C18.149 17.9077 19.2936 18.3678 20.2547 19.1052C21.2158 19.8426 21.9566 20.8291 22.3968 21.9576C23.639 24.5197 24.1093 27.3878 23.7503 30.2125C23.1739 35.3931 20.2841 39.5245 16.8334 40.6161ZM15.0921 20.016C14.0019 20.4064 13.0433 21.0954 12.3259 22.0043C10.8664 23.9355 9.95581 26.2251 9.69019 28.6311C9.42457 31.0371 9.81383 33.4702 10.8168 35.6732C11.1382 36.4779 11.6582 37.1879 12.3283 37.7371C12.9985 38.2863 13.7969 38.6566 14.649 38.8136C17.8762 39.1619 21.0899 35.1396 21.6551 30.0292C21.9766 27.6244 21.5895 25.178 20.5414 22.9899C20.2128 22.2042 19.6936 21.5128 19.0308 20.9781C18.368 20.4434 17.5824 20.0822 16.745 19.9273C16.1951 19.848 15.6345 19.8886 15.1017 20.0464L15.0921 20.016Z"
          fill="#263238"
        />
      </g>
      <path
        d="M81.3587 21H56.6413C56.2871 21 56 21.6975 56 22.5579V46.288C56 47.1484 56.2871 47.8459 56.6413 47.8459H81.3587C81.7129 47.8459 82 47.1484 82 46.288V22.5579C82 21.6975 81.7129 21 81.3587 21Z"
        fill="white"
      />
      <path
        d="M68.5443 32.7139H66.8477V45.5254H68.5443V32.7139Z"
        fill="#FF725E"
      />
      <path
        d="M65.9272 38.104H64.2305V45.5252H65.9272V38.104Z"
        fill="#455A64"
      />
      <path
        d="M63.3139 36.9487H61.6172V45.5256H63.3139V36.9487Z"
        fill="#FF725E"
      />
      <path d="M60.6967 42.0674H59V45.5259H60.6967V42.0674Z" fill="#455A64" />
      <path
        d="M73.7748 29.9155H72.0781V45.5256H73.7748V29.9155Z"
        fill="#FF725E"
      />
      <path
        d="M71.1615 31.3398H69.4648V45.5252H71.1615V31.3398Z"
        fill="#455A64"
      />
      <path
        d="M79.0053 28.3691H77.3086V45.5258H79.0053V28.3691Z"
        fill="#FF725E"
      />
      <path
        d="M76.3881 24.981H74.6914V45.5253H76.3881V24.981Z"
        fill="#455A64"
      />
      <path
        d="M74.889 24.2363C74.9701 24.2279 75.0519 24.2437 75.124 24.2816C75.2962 24.3633 75.4391 24.4959 75.5333 24.6616C75.6275 24.8272 75.6685 25.0178 75.6507 25.2076C75.6311 25.3908 75.5491 25.5617 75.4185 25.6918C75.272 25.8422 75.0819 25.9428 74.8751 25.9794C74.6683 26.0159 74.4553 25.9867 74.266 25.8956C74.0857 25.7898 73.9413 25.6323 73.8514 25.4435C73.7615 25.2547 73.7304 25.0433 73.762 24.8366C73.7876 24.6559 73.8653 24.4867 73.9857 24.3496C74.0904 24.2333 74.2201 24.1422 74.3651 24.0834C74.57 23.9969 74.7969 23.9771 75.0136 24.0268C75.1665 24.0664 75.2373 24.1174 75.2316 24.1259C75.226 24.1344 75.1495 24.1032 75.0051 24.0834C74.8008 24.0572 74.5933 24.0906 74.4076 24.1797C74.2715 24.2385 74.1529 24.3315 74.0631 24.4495C73.9733 24.5675 73.9155 24.7067 73.8951 24.8536C73.8719 25.0318 73.901 25.2128 73.979 25.3747C74.0569 25.5366 74.1803 25.6724 74.334 25.7654C74.4962 25.8413 74.6776 25.866 74.8542 25.8362C75.0308 25.8063 75.194 25.7233 75.3222 25.5983C75.4265 25.4946 75.4974 25.362 75.5257 25.2177C75.5539 25.0734 75.5383 24.9239 75.4808 24.7885C75.4099 24.5969 75.2748 24.4358 75.0985 24.3326C74.9711 24.2618 74.8862 24.2505 74.889 24.2363Z"
        fill="#263238"
      />
      <defs>
        <clipPath id="clip0_1428_22511">
          <path
            d="M1 0H80.2029V64.9589C80.2029 86.8301 62.4727 104.56 40.6014 104.56C18.7302 104.56 1 86.8301 1 64.9589V0Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StatisticsAvatarSVG;
