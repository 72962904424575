const PromotionIconSVG = ({ selected }) => {
  return (
    <div className="float-right">
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={selected ? "1" : "0.5"}>
          <path
            d="M13.3751 31.4692C13.842 31.1998 14.0019 30.6021 13.7326 30.1351C13.4629 29.6686 12.8655 29.5084 12.3986 29.7777C11.9317 30.0474 11.7714 30.6452 12.0411 31.1117C12.3104 31.5787 12.9082 31.7389 13.3751 31.4692Z"
            fill="#333C4E"
          />
          <path
            d="M9.37346 38.2079L14.2914 46.7265C15.3526 48.5667 17.7399 49.248 19.6277 48.1566C21.4958 47.077 22.1378 44.6902 21.0571 42.8206L18.1274 37.7455L20.6646 36.2807C21.1315 36.0106 21.2917 35.4136 21.022 34.9467L19.7521 32.7471C19.9036 32.7177 20.8992 32.5224 38.4571 29.0781C40.6277 28.9603 41.9281 26.5864 40.8317 24.6886L37.5862 19.0672L39.6603 15.9258C39.864 15.6168 39.8762 15.2197 39.6912 14.8993L37.738 11.5164C37.553 11.196 37.2017 11.0083 36.8336 11.03L33.0765 11.2555L29.4735 5.01465C28.9516 4.11057 28.0166 3.56316 26.9729 3.55019C26.9604 3.5498 26.9482 3.5498 26.9356 3.5498C25.927 3.5498 25.0114 4.05182 24.469 4.90059L11.7119 21.152L3.41833 25.9402C0.156387 27.8227 -0.973526 32.0074 0.916275 35.2782C2.64281 38.2689 6.28279 39.4453 9.37346 38.2079ZM19.3661 43.7975C19.9055 44.7306 19.5862 45.9246 18.6504 46.4651C17.7116 47.008 16.5161 46.6746 15.9828 45.7503L11.1 37.2919L14.4829 35.3388C20.134 45.1281 19.2753 43.6407 19.3661 43.7975ZM17.1509 36.0537L16.1743 34.3623L17.8657 33.3857L18.8423 35.0771L17.1509 36.0537ZM36.3483 13.0156L37.698 15.3532L36.4944 17.1759L34.1682 13.1464L36.3483 13.0156ZM26.1024 5.97137C26.3645 5.5426 26.7803 5.49835 26.9489 5.50293C27.1156 5.50484 27.531 5.55672 27.7817 5.99083L39.1403 25.6651C39.5103 26.3056 39.0606 27.1109 38.3186 27.1292C38.1855 27.1323 38.1241 27.1563 37.783 27.22L25.7679 6.40892C26.02 6.08467 26.0525 6.05339 26.1024 5.97137ZM24.4682 8.0645L35.7571 27.6175L18.7199 30.9592L13.5395 21.9866L24.4682 8.0645ZM2.60771 34.3016C2.17894 33.5596 1.95235 32.7174 1.95235 31.8652C1.95235 30.1234 2.88847 28.5014 4.3949 27.6316L12.0064 23.2371L16.8892 31.6943L9.27771 36.0892C6.94579 37.435 3.95392 36.6331 2.60771 34.3016Z"
            fill="#333C4E"
          />
          <path
            d="M10.3498 32.0875C10.0801 31.6206 9.48274 31.4604 9.01582 31.7301L7.32438 32.7067C6.85823 32.976 6.2597 32.8154 5.99038 32.3492C5.72068 31.8823 5.1233 31.7221 4.65638 31.9918C4.18946 32.2615 4.02925 32.8589 4.29895 33.3258C5.1027 34.7182 6.89561 35.2091 8.30094 34.3981L9.99238 33.4216C10.4593 33.1519 10.6195 32.5548 10.3498 32.0875Z"
            fill="#333C4E"
          />
          <path
            d="M48.549 5.63917L43.1074 8.66728C42.6362 8.92973 42.4665 9.52444 42.7289 9.99556C42.9906 10.4663 43.585 10.6368 44.0572 10.374L49.4985 7.34587C49.97 7.08342 50.1393 6.48871 49.8769 6.01759C49.6148 5.54609 49.0201 5.37672 48.549 5.63917Z"
            fill="#EC5627"
          />
          <path
            d="M47.9776 15.7529L44.2044 14.742C43.6833 14.6024 43.1477 14.9114 43.0081 15.4325C42.8685 15.9536 43.1779 16.4888 43.699 16.6284L47.4725 17.6393C47.9966 17.7801 48.5296 17.4661 48.6684 16.9488C48.808 16.4278 48.4987 15.8922 47.9776 15.7529Z"
            fill="#EC5627"
          />
          <path
            d="M38.4948 2.09119L37.4828 5.86468C37.3431 6.38577 37.6521 6.92097 38.1732 7.06097C38.6939 7.2002 39.2295 6.89198 39.3695 6.37051L40.3811 2.59702C40.5208 2.07593 40.2118 1.54035 39.6907 1.40073C39.17 1.26111 38.6344 1.5701 38.4948 2.09119Z"
            fill="#EC5627"
          />
        </g>
      </svg>
    </div>
  );
};

export default PromotionIconSVG;
