import PreviewAvatarSVG from "assets/svg/preview-avatar";
import Button from "components/button/button";
import { Context } from "context/context";
import { useContext } from "react";
import { BsEyeFill } from "react-icons/bs";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const CampaignPreview = ({ userData, cid }) => {
  const { setSelectedTab, popup } = useContext(Context); // context
  const history = useHistory();

  const handleFinishCampaign = () => {
    if (cid) {
      return history.push(`/campaign-details/${cid}/popouts`);
    } else {
      return history.push(`/popouts`);
    }
  };

  return (
    <div className="w-full">
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Review Your Popout</p>
      </div>

      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <div
          className="w-full bg-white rounded-xl relative campaign-left-max mr-32"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            <div>
              <p className="text-xl pl-1 pb-2">
                Congratulations, you’ve built your popout! 🎉
              </p>
            </div>
            {/* middle */}
            <div>
              <p className="pl-1 pt-2 pb-2">
                Click the preview button to see your popout fullscreen. Once
                you're done with the preview, click finish and select Campaigns
                from the sidebar of the dashboard to use this popout in a
                campaign.
              </p>
            </div>
            <div className="relative">
              <Button
                buttonName="SAMPLE PREVIEW"
                buttonTextClass="text-black text-xs font-bold flex px-3 justify-center"
                buttonClass="bg-transparent rounded-full py-3 px-9 border border-black absolute top-36 left-9"
                buttonType="transparent"
                iconAlign="left"
                icon={<BsEyeFill className="text-base mr-2" />}
                onClick={() =>
                  history.push(`/campaigns/popup/preview/${popup.id}`)
                }
              />
              <PreviewAvatarSVG className="w-full" />
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => {
                  setSelectedTab("effects");
                }}
              />
              <Button
                buttonName={
                  userData.user.role === "guest"
                    ? "SIGN UP TO INSTALL"
                    : "Finish"
                }
                buttonType="primary"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                onClick={handleFinishCampaign}
              />
            </div>
          </div>
        </div>
        {/* end card start */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

export default connect(mapStateToProps)(CampaignPreview);
