import React from "react";
import "./styles.css";

const Divider = ({ text = "", style = {} }) => {
  return (
    <div className="form-divider-container" style={style}>
      <div className="form-divider-line"></div>
      {text && (
        <div className="px-4">
          <p className="form-divider-text">{text}</p>
        </div>
      )}
      <div className="form-divider-line"></div>
    </div>
  );
};

export default Divider;
