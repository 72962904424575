import React from "react";
import "./styles.css";

const ButtonPreview = ({
  background = "black",
  buttonName = "",
  className = "py-2 px-4 mr-1 mb-2",
  btnTxtClass = "text-sm font-bold",
  radius = "999px",
  textColor = "#FFFFFF",
  font = "",
  hidden = false,
  onClick = () => {},
}) => {
  return (
    <button
      className={className + " duration-500 hover:opacity-80"}
      type="button"
      style={{
        display: hidden ? "none" : "inline-block",
        background: background,
        fontFamily: font,
        borderRadius: radius,
      }}
      onClick={onClick}
    >
      <p className={btnTxtClass} style={{ color: textColor }}>
        {buttonName}
      </p>
    </button>
  );
};

export default ButtonPreview;
