import drupal from "assets/images/install-drupal.png";
import gtm from "assets/images/install-gtm.png";
import joomla from "assets/images/install-joomla.png";
import manual from "assets/images/install-manual.png";
import squarespace from "assets/images/install-squarespace.png";
import wix from "assets/images/install-wix.png";
import wordpress from "assets/images/install-wordpress.png";
import InstallerCopier from "components/install/installer-copier";
import InstallerItem from "components/install/installer-item";
import InstallerSteps from "components/install/installer-steps";
import { JOOMLA_DOWNLOAD, MANUAL_URL, WP_DOWNLOAD } from "configs/api";
import Layout from "layouts/layout";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import userActions from "redux/modules/user/actions";

const Install = ({ userData }) => {
  const [selectedInstaller, setSelectedInstaller] = useState(0);
  const installScript = `!function(c,o,w){c[o]=function(){(c[o].w=c[o].w||[]).push(arguments)};var d=document,s=d.createElement(w);s.type="text/javascript",s.async=!0,s.src="${MANUAL_URL}";var e=d.getElementsByTagName(w)[0];e.parentNode.insertBefore(s,e)}(window,"ConversionCow","script");
  ConversionCow("init", "${userData?.user?.unique_identifier}");`;

  const installers = [
    {
      name: "Wordpress",
      brand: wordpress,
      isManual: false,
      steps: [
        <span>
          Download the ConversionCow WordPress plugin -{" "}
          <a
            href={WP_DOWNLOAD}
            target="_blank"
            rel="noreferrer"
            className="text-blue underline"
          >
            Click here to download
          </a>
        </span>,
        "Login to your WordPress admin panel.",
        <span>
          Open the <em>Plugins</em> page from the left sidebar, click{" "}
          <em>Add New</em>, and upload the ConversionCow plugin.
        </span>,
        "Make sure to activate the plugin after installing!",
        <span>
          Navigate to the <em>Settings</em> menu in the sidebar, and select{" "}
          <em>ConversionCow</em>
        </span>,
        <span>
          Paste the following text into the field labelled <em>User Code</em>{" "}
          and press <em>Save</em>
        </span>,
      ],
      content: `${userData?.user?.unique_identifier}`,
      type: "wordpress",
    },
    {
      name: "Google Tag Manager",
      brand: gtm,
      isManual: true,
      manualContent: (
        <p>
          Check out our{" "}
          <a
            href="https://support.conversioncow.com/hc/en-us/articles/10247093655951-How-to-install-ConversionCow-using-Google-Tag-Manager"
            target="_blank"
            rel="noreferrer"
            className="text-blue underline"
          >
            Google Tag Manager installation guide
          </a>{" "}
          and enter the following code when prompted:
        </p>
      ),
      content: `${userData?.user?.unique_identifier}`,
      type: "google",
    },
    {
      name: "Joomla",
      brand: joomla,
      isManual: false,
      steps: [
        <span>
          Download the ConversionCow Joomla plugin -{" "}
          <a
            href={JOOMLA_DOWNLOAD}
            target="_blank"
            rel="noreferrer"
            className="text-blue underline"
          >
            Click here to download
          </a>
        </span>,
        "Login to your Joomla admin panel.",
        <span>
          Open the <em>System</em> page from the left sidebar, click{" "}
          <em>Extensions</em>, and upload the ConversionCow plugin.
        </span>,
        <span>
          Open the <em>Home Dashboard</em> page from the left sidebar again,
          click <em>Plugins</em>, and search ConversionCow.
        </span>,
        <span>
          Enable the extension by clicking the <em>status</em> then open the
          plugin settings by clicking the name of the extension
        </span>,
        <span>
          Paste the following text into the field labelled <em>User ID</em> and
          press <em>Save</em>
        </span>,
      ],
      content: `${userData?.user?.unique_identifier}`,
      type: "joomla",
    },
    {
      name: "Wix",
      brand: wix,
      isManual: false,
      steps: [
        "Copy the Script displayed on this page.",
        <span>
          Next, log into your Wix website and{" "}
          <a
            href="https://support.wix.com/en/article/embedding-custom-code-on-your-site"
            target="_blank"
            rel="noreferrer"
            className="text-blue underline"
          >
            Follow the Wix Website Custom Code Embed instructions provided by
            Wix
          </a>
        </span>,
        "When instructed to choose where to place the embed, Paste the script in your websites code after the Body Start.",
        "To confirm your installation was successful, load your website according to the Campaign you've set in ConversionCow. Your popout will display if your installation was successful.",
      ],
      content: `<script type="text/javascript">${installScript}</script>`,
      type: "wix",
    },
    {
      name: "Drupal",
      brand: drupal,
      isManual: false,
      steps: [
        <span>
          Please examine the installation guidelines tailored to your selected
          Drupal version before commencing the installation process:
          <br />
          <ul className="mt-5 space-y-3 pl-5 list-disc">
            <li>
              <a
                href="https://support.conversioncow.com/hc/en-us/articles/8899778770319-How-To-Install-ConversionCow-on-Drupal-7"
                target="_blank"
                rel="noreferrer"
                className="text-blue underline"
              >
                Drupal Version 7
              </a>
            </li>
            <li>
              <a
                href="https://support.conversioncow.com/hc/en-us/articles/8899785056271-How-To-Install-ConversionCow-on-Drupal-8"
                target="_blank"
                rel="noreferrer"
                className="text-blue underline"
              >
                Drupal Version 8
              </a>
            </li>
            <li>
              <a
                href="https://support.conversioncow.com/hc/en-us/articles/8899773867151-How-To-Install-ConversionCow-on-Drupal-10"
                target="_blank"
                rel="noreferrer"
                className="text-blue underline"
              >
                Drupal Version 10
              </a>
            </li>
          </ul>
          <div className="text-xl font-medium mt-5">
            Drupal Installation Script
          </div>
        </span>,
      ],
      content: `<script type="text/javascript">${installScript}</script>`,
      type: "drupal",
    },
    {
      name: "SquareSpace",
      brand: squarespace,
      isManual: false,
      steps: [
        "Copy the Script displayed on this page.",
        <span>
          Next, log into your SquareSpace website and{" "}
          <a
            href="https://support.squarespace.com/hc/en-us/articles/205815908"
            target="_blank"
            rel="noreferrer"
            className="text-blue underline"
          >
            Follow the SquareSpace Code Injection instructions provided by
            SquareSpace
          </a>
        </span>,
        "When instructed to choose where to place the script, Paste the script in the Header section.",
        <span>
          To confirm your installation was successful, load your website
          according to the Campaign you've set in ConversionCow. Your popout
          will display if your installation was successful.
        </span>,
      ],
      content: `<script type="text/javascript">${installScript}</script>`,
      type: "squareSpace",
    },
    {
      name: "Manual",
      brand: manual,
      isManual: true,
      steps: [],
      id: 3,
      content: `<script>${installScript}</script>`,
      type: "manual",
    },
  ];

  const activeInstaller = installers[selectedInstaller];

  const [isCopied, setIsCopied] = useState(false);

  const copyInput = () => {
    if (isCopied) return;

    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(activeInstaller.content);
      setIsCopied(true);
      toast.success("Content Copied");
    } else {
      toast.error("Clipboard API is not supported");
    }
  };

  useEffect(() => {
    if (isCopied) {
      setIsCopied(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstaller]);

  return (
    <Layout title="Install" routeName="install">
      <div className="flex flex-col flex-1 w-full">
        <div className="flex flex-wrap -mx-2 overflow-hidden">
          <div className="w-full px-2 my-2 overflow-hidden">
            <h1 className="text-xl">
              Install ConversionCow using one of the options below:
            </h1>
          </div>

          <div className="w-full px-2 my-2 overflow-hidden">
            <div className="flex flex-wrap -mx-2 overflow-hidden">
              {installers.map((installer, installerIndex) => (
                <div
                  key={`installer-${installerIndex}`}
                  className="w-full px-2 my-2 overflow-hidden cursor-pointer sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
                  onClick={() => setSelectedInstaller(installerIndex)}
                >
                  <InstallerItem
                    name={installer.name}
                    brand={installer.brand}
                    selected={selectedInstaller === installerIndex}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="w-full px-2 my-2 overflow-hidden">
            <InstallerSteps
              isManual={activeInstaller.isManual}
              steps={activeInstaller.steps}
              manualContent={activeInstaller.manualContent}
            />
          </div>
          <div className="w-full px-2 my-2 overflow-hidden">
            <InstallerCopier
              content={activeInstaller.content}
              type={activeInstaller.type}
              isCopied={isCopied}
              onClick={copyInput}
            />
          </div>
          <div className="w-full px-2 my-2 mt-5">
            <h3 className="mb-2 text-xl font-medium">Need More Help?</h3>
            <div>
              <h4>Installation Guide:</h4>
              <div>
                Access our step-by-step{" "}
                <a
                  href="https://support.conversioncow.com/hc/en-us/categories/5206239334031-Get-Started"
                  className="text-blue underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Installation Guide
                </a>{" "}
                tailored to your CMS. Read yourself or share them with your
                developer.
              </div>
            </div>
            <br />
            <div>
              <h4>Explore Resources:</h4>
              <div>
                Check out our{" "}
                <a
                  href="https://support.conversioncow.com/hc/en-us"
                  className="text-blue underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Knowledge Base
                </a>{" "}
                for more insights.
              </div>
            </div>
            <br />
            <div>
              <h4>Troubleshooting Tips:</h4>
              <div>
                Find solutions in the guide. Need help? Contact our{" "}
                <a
                  href="mailto:support@conversioncow.com"
                  className="text-blue underline"
                >
                  Support Team
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Install);
