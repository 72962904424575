const ArrowLeftIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.35801 5.83333L6.34134 8.825L5.16634 10L0.166343 5L5.16634 0L6.34134 1.175L3.35801 4.16667H11.833V5.83333H3.35801Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowLeftIconSVG;
