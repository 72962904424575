import PlayCircleIconSVG from "assets/svg/play-circle-icon";
import StarRating from "components/rating/star-rating";
import { Context } from "context/context";
import { useContext, useState } from "react";
import ReactPlayer from "react-player";
import HeaderExample from "./component/header";

const showDummy = ["template", "content"];

export default function VideosTab({ popup }) {
  const { view, selectedTab } = useContext(Context);
  const mobile = view === "mobile";
  const data = popup.template_content && JSON.parse(popup.template_content);
  const show = showDummy.includes(selectedTab);

  const [showVideo, setShowVideo] = useState(false);

  return (
    <div
      className={mobile ? "" : "videos-tab cc-tab"}
      style={{ height: mobile ? "619px" : "635px" }}
    >
      <HeaderExample
        data={data}
        popup={popup}
        show={show}
        placeholder="Hear from our customers"
      />
      <div className="w-full py-6">
        <div
          className={`w-full grid ${
            data.thumbnail?.url || data.video?.link || show ? "video-empty" : ""
          }`}
          style={{
            backgroundImage: `url(${data.thumbnail?.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {data.video?.link && showVideo ? (
            <ReactPlayer
              className="z-50"
              url={data.video?.link}
              playing={showVideo}
              controls
              width="100%"
              height="100%"
            />
          ) : (
            (data.video?.link || show) && (
              <button
                className="m-auto"
                onClick={() => setShowVideo(!showVideo)}
              >
                <PlayCircleIconSVG disabled={!data.thumbnail?.url && show} />
              </button>
            )
          )}
        </div>
      </div>

      {show || data.video?.rating ? (
        <div className="pb-2">
          <StarRating value={data.video?.rating} />
        </div>
      ) : null}

      <p
        className={`font-videos font-bold ${
          !data.video?.heading && "text-tabBorder"
        }`}
      >
        {data.video?.heading ||
          (show &&
            "“Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.”")}
      </p>
      <p
        className={`font-videos-sub pt-4 ${
          !data.video?.body && "text-tabBorder"
        }`}
      >
        {data.video?.body ||
          (show &&
            "Lorem ipsum dolor sit consectetur adipiscing elit ut aliquip ex ea commodo consequat.")}
      </p>
    </div>
  );
}
