import Button from "components/global/button";
import "./banner.css";
import _ from "lodash";

const cxs = (clasess) => clasess.filter(Boolean).join(" ");

export const AlertBanner = ({
  data,
  className = "border border-thBorder",
  ...props
}) => {
  const classes = cxs([
    "w-full h-full px-5 py-3 rounded-md bg-cover bg-center bg-no-repeat ",
    className,
  ]);

  const messageClass = cxs([
    "text-white font-extralight mt-5",
    data?.image_banner_id && "mb-10",
  ]);
  return (
    <div
      className={classes}
      {...props}
      style={
        data?.banner_image_id
          ? { backgroundImage: `url(${data?.banner_image?.url})` }
          : { background: "#28265B" }
      }
    >
      <div className="flex h-full flex-wrap overflow-hidden">
        <div className="w-full h-full overflow-hidden flex-1 flex flex-col">
          <h1 className="text-xl text-white font-medium">{data.title}</h1>
          <p className={messageClass}>{data.message}</p>
          <div className="my-5 mt-auto">
            {data.buttons.map((btn, btnIndex) => {
              const className = !btnIndex
                ? "bg-white text-primary mr-4 border-2 border-white px-8"
                : "bg-transparent text-white border-2 border-white px-8";

              let url = btn.url;
              if (_.startsWith(btn.url, "https://")) {
                url = btn.url;
              } else if (_.startsWith(btn.url, "http://")) {
                url = _.replace(btn.url, "http", "https");
              } else {
                url = `https://${btn.url}`;
              }

              return (
                <Button
                  key={`banner-btn-${btnIndex}`}
                  as="link"
                  href={url}
                  target="_blank"
                  className={className + " inline-block"}
                >
                  {btn.label}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertBanner;
