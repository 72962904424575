const AddressesIconSVG = ({ selected }) => {
  return (
    <div className="float-right">
      <svg
        width="48"
        height="50"
        viewBox="0 0 48 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={selected ? "1" : "0.5"}>
          <path
            d="M34.0363 3.68574H32.5881V3.42207C32.5881 1.53516 31.0599 0 29.1812 0C27.3043 0 25.7773 1.53516 25.7773 3.42207V3.68574H13.0897V3.42207C13.0897 1.53516 11.5616 0 9.68311 0C7.80596 0 6.27881 1.53516 6.27881 3.42207V3.68574H3.22725C1.48857 3.68574 0.0742188 5.10869 0.0742188 6.85771V46.8281C0.0742188 48.577 1.48857 50 3.22725 50H15.5085C16.0516 50 16.492 49.5596 16.492 49.0165C16.492 48.4733 16.0516 48.033 15.5085 48.033H3.22725C2.57324 48.033 2.04121 47.4926 2.04121 46.8281V6.85771C2.04121 6.19326 2.57314 5.65283 3.22725 5.65283H11.1229V7.5292C11.1229 8.33154 10.477 8.98418 9.68311 8.98418C8.89062 8.98418 8.2459 8.33145 8.2459 7.5292C8.2459 6.98603 7.80547 6.5457 7.2624 6.5457C6.71924 6.5457 6.27891 6.98613 6.27891 7.5292C6.27891 9.41602 7.80605 10.9513 9.68311 10.9513C11.5616 10.9513 13.0898 9.41602 13.0898 7.5292V5.65283H30.6211V7.5292C30.6211 8.33154 29.9752 8.98418 29.1812 8.98418C28.389 8.98418 27.7443 8.33145 27.7443 7.5292C27.7443 6.98603 27.3039 6.5457 26.7609 6.5457C26.2177 6.5457 25.7774 6.98613 25.7774 7.5292C25.7774 9.41602 27.3044 10.9513 29.1812 10.9513C31.0599 10.9513 32.5882 9.41602 32.5882 7.5292V5.65283H34.0364C34.6904 5.65283 35.2226 6.19326 35.2226 6.85771V46.8281C35.2226 47.4813 34.6794 48.033 34.0364 48.033H23.5928C23.0497 48.033 22.6093 48.4734 22.6093 49.0165C22.6093 49.5596 23.0497 50 23.5928 50H34.0363C35.7748 50 37.1894 48.577 37.1894 46.8281V6.85771C37.1895 5.10869 35.7748 3.68574 34.0363 3.68574ZM8.2459 3.68574V3.42207C8.2459 2.61973 8.89062 1.96709 9.68311 1.96709C10.477 1.96709 11.1229 2.61982 11.1229 3.42207V3.68574H8.2459ZM27.7443 3.68574V3.42207C27.7443 2.61973 28.389 1.96709 29.1812 1.96709C29.9752 1.96709 30.6211 2.61982 30.6211 3.42207V3.68574H27.7443Z"
            fill="#333C4E"
          />
          <path
            d="M13.6585 14.4456C13.6585 13.9024 13.2182 13.4621 12.6751 13.4621H7.13389C6.59072 13.4621 6.15039 13.9025 6.15039 14.4456V20.0303C6.15039 20.5736 6.59082 21.0138 7.13389 21.0138H12.675C13.2182 21.0138 13.6584 20.5735 13.6584 20.0303V14.4456H13.6585ZM11.6915 19.0468H8.11738V15.4291H11.6915V19.0468Z"
            fill="#333C4E"
          />
          <path
            d="M17.6642 16.0077H29.0948C29.6379 16.0077 30.0782 15.5674 30.0782 15.0242C30.0782 14.4811 29.6379 14.0407 29.0948 14.0407H17.6642C17.1211 14.0407 16.6807 14.4812 16.6807 15.0242C16.6807 15.5674 17.1211 16.0077 17.6642 16.0077Z"
            fill="#333C4E"
          />
          <path
            d="M17.6642 20.292H29.0948C29.6379 20.292 30.0782 19.8517 30.0782 19.3085C30.0782 18.7654 29.6379 18.325 29.0948 18.325H17.6642C17.1211 18.325 16.6807 18.7655 16.6807 19.3085C16.6807 19.8517 17.1211 20.292 17.6642 20.292Z"
            fill="#333C4E"
          />
          <path
            d="M13.6585 26.2221C13.6585 25.6789 13.2182 25.2386 12.6751 25.2386H7.13389C6.59072 25.2386 6.15039 25.679 6.15039 26.2221V31.8068C6.15039 32.3501 6.59082 32.7903 7.13389 32.7903H12.675C13.2182 32.7903 13.6584 32.35 13.6584 31.8068V26.2221H13.6585ZM11.6915 30.8233H8.11738V27.2055H11.6915V30.8233Z"
            fill="#EC5627"
          />
          <path
            d="M17.6642 27.7844H29.0948C29.6379 27.7844 30.0782 27.3439 30.0782 26.8009C30.0782 26.2576 29.6379 25.8174 29.0948 25.8174H17.6642C17.1211 25.8174 16.6807 26.2577 16.6807 26.8009C16.6807 27.3439 17.1211 27.7844 17.6642 27.7844Z"
            fill="#EC5627"
          />
          <path
            d="M17.6642 32.0658H29.0948C29.6379 32.0658 30.0782 31.6254 30.0782 31.0823C30.0782 30.539 29.6379 30.0987 29.0948 30.0987H17.6642C17.1211 30.0987 16.6807 30.5391 16.6807 31.0823C16.6807 31.6254 17.1211 32.0658 17.6642 32.0658Z"
            fill="#EC5627"
          />
          <path
            d="M12.675 36.425H7.13389C6.59072 36.425 6.15039 36.8655 6.15039 37.4085V42.9934C6.15039 43.5367 6.59082 43.9769 7.13389 43.9769H12.675C13.2182 43.9769 13.6584 43.5366 13.6584 42.9934V37.4085C13.6585 36.8655 13.2182 36.425 12.675 36.425ZM11.6915 42.01H8.11738V38.3921H11.6915V42.01Z"
            fill="#333C4E"
          />
          <path
            d="M17.6642 38.9708H29.0948C29.6379 38.9708 30.0782 38.5304 30.0782 37.9873C30.0782 37.4441 29.6379 37.0038 29.0948 37.0038H17.6642C17.1211 37.0038 16.6807 37.4442 16.6807 37.9873C16.6807 38.5305 17.1211 38.9708 17.6642 38.9708Z"
            fill="#333C4E"
          />
          <path
            d="M30.0782 42.2686C30.0782 41.7254 29.6379 41.2852 29.0948 41.2852H17.6642C17.1211 41.2852 16.6807 41.7255 16.6807 42.2686C16.6807 42.8118 17.1211 43.2521 17.6642 43.2521H29.0948C29.6379 43.2521 30.0782 42.8118 30.0782 42.2686Z"
            fill="#333C4E"
          />
          <path
            d="M47.9242 17.4877C47.9237 17.4747 47.9232 17.4617 47.9223 17.4487C47.92 17.4194 47.9163 17.3906 47.9116 17.362C47.9102 17.354 47.9095 17.3458 47.9079 17.3378C47.9005 17.2992 47.891 17.2616 47.8792 17.2249C47.8784 17.2224 47.8772 17.2197 47.8765 17.2171C47.8653 17.1832 47.8521 17.1504 47.8376 17.1183C47.8358 17.1146 47.8349 17.1106 47.8331 17.1069L44.5056 10.0124V8.94355C44.5218 8.40068 44.0875 7.94736 43.5446 7.93115C43.5345 7.93086 43.5246 7.93066 43.5146 7.93066C42.985 7.93066 42.548 8.35186 42.5322 8.88486V9.98437L39.1978 17.107C39.1961 17.1104 39.1952 17.1141 39.1937 17.1175C39.1785 17.1506 39.1649 17.1845 39.1535 17.2193C39.1529 17.2211 39.1522 17.2227 39.1516 17.2245C39.1397 17.2615 39.1302 17.2995 39.1228 17.3383C39.1212 17.3458 39.1206 17.3533 39.1193 17.3608C39.1144 17.3899 39.1106 17.4193 39.1084 17.4492C39.1074 17.4619 39.1069 17.4745 39.1064 17.4873C39.106 17.4998 39.1045 17.5121 39.1045 17.5247V38.8499V41.1618C39.1045 43.6079 41.0833 45.5979 43.5153 45.5979C45.9474 45.5979 47.9261 43.6079 47.9261 41.1618V38.8499V17.5247C47.9262 17.5121 47.9247 17.5001 47.9242 17.4877ZM41.0717 18.5081H45.9592V37.8663H41.0717V18.5081ZM43.5154 12.5336L45.3951 16.5411H41.6357L43.5154 12.5336ZM43.5154 43.6308C42.168 43.6308 41.0717 42.5231 41.0717 41.1617V39.8333H45.9592V41.1617C45.9591 42.5232 44.863 43.6308 43.5154 43.6308Z"
            fill="#333C4E"
          />
          <path
            d="M19.6836 48.0329H19.6642C19.1209 48.0329 18.6807 48.4734 18.6807 49.0164C18.6807 49.5595 19.121 49.9999 19.6642 49.9999H19.6836C20.2267 49.9999 20.667 49.5595 20.667 49.0164C20.667 48.4734 20.2266 48.0329 19.6836 48.0329Z"
            fill="#333C4E"
          />
        </g>
      </svg>
    </div>
  );
};

export default AddressesIconSVG;
