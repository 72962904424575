import InstallerCopiedSVG from "assets/svg/installer-copied";
import InstallerCopySVG from "assets/svg/installer-copy";
import Button from "components/button/button";
import Card from "components/card/card";
import moment from "moment";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";

const noop = () => {};

const IntegrationCopier = ({ content, onClick = noop, isCopied }) => {
  return (
    <div className="w-full">
      <p className="mb-1 ml-1">API Key</p>

      <div className="flex min-h-10 items-center">
        <div className="w-full h-auto mr-0 p-4 text-gray-800 bg-white border-t border-b border-l rounded-l-lg border-gray">
          <input
            readOnly
            defaultValue={content}
            className="w-full text-ellipsis overflow-hidden"
          />
        </div>
        <button
          className={`flex items-center p-4 justify-center font-bold text-white text-gray-800 border-t border-b border-r rounded-r-lg ${
            !isCopied
              ? "border-primary bg-primary"
              : "border-lightGreen bg-lightGreen"
          }`}
          onClick={onClick}
        >
          <span className="mb-1 mr-2">
            {isCopied ? <InstallerCopiedSVG /> : <InstallerCopySVG />}
          </span>
          <span>{isCopied ? "Copied" : "Copy"}</span>
        </button>
      </div>
    </div>
  );
};

const IntegrationItem = ({
  name,
  connected,
  brand,
  content,
  details,
  onClick = noop,
  disconnect = noop,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyInput = useCallback(() => {
    if (isCopied) return;

    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(content);
      setIsCopied(true);
      toast.success("Content Copied");
    } else {
      toast.error("Clipboard API is not supported");
    }
  }, [content, isCopied]);

  return (
    <Card className="w-full border border-gray" padding="p-0">
      <div className="w-full grid 2md:grid-cols-2 divide-x 2md:divide-x-0 2md:divide-y-0 divide-y divide-gray">
        <div className="w-full py-12.5 px-12.4">
          <img src={brand} alt={`${name} Integration`} />
          {!connected && (
            <p className="my-5 text-base opacity-80">
              Zapier is a product that allows end users to integrate the web
              applications they use and automate workflows.
            </p>
          )}
          {/*  */}
          {connected ? (
            <div className="w-full mt-5">
              <IntegrationCopier
                content={content}
                onClick={copyInput}
                isCopied={isCopied}
              />
              <div className="w-full 7md:flex mt-4 7md:space-x-5 items-center">
                <div className="flex items-center">
                  <p className="font-bold">Created at:&nbsp;</p>
                  <p>{moment(details.created_at).format("DD/MM/YYYY H:m:s")}</p>
                </div>
                <div className="flex items-center">
                  <p className="font-bold">Status:&nbsp;</p>
                  <p className="text-sm px-2 py-0.5 bg-lightGreen text-white rounded">
                    Active
                  </p>
                </div>
              </div>
              <div className="w-full mt-5">
                <button
                  className="text-sm font-bold bg-primary py-3 rounded-full px-12 text-white hover:bg-primaryHover duration-300"
                  onClick={disconnect}
                >
                  DISCONNECT
                </button>
              </div>
            </div>
          ) : (
            <div className="w-full mt-5">
              <Button
                buttonName="CONNECT NOW"
                buttonClass="bg-primary px-12 py-3"
                buttonType="primary"
                buttonTextClass="text-white text-sm font-bold"
                onClick={onClick}
              />
            </div>
          )}

          {/*  */}
        </div>
        {/* instructions */}
        <div className="w-full py-12.5 px-12.4">
          <p className="text-xl">How to Setup</p>
          <p className="mt-1 text-sm text-opacity-70 text-black">
            Please refer to the{" "}
            <a
              class="text-blue underline"
              href="https://support.conversioncow.com/hc/en-us/articles/5325901868943-30-How-to-Integrate-With-Zapier"
              target="_blank"
              rel="noreferrer"
            >
              How to Integrate With Zapier
            </a>{" "}
            guide.
          </p>
        </div>
      </div>
    </Card>
  );
};

export default IntegrationItem;
