import React from "react";
import { motion } from "framer-motion";

export default function Backdrop({ zIndex = "", ...props }) {
  return (
    <motion.div
      className={`fixed inset-0 bg-black bg-opacity-40 ${zIndex}`}
      {...props}
    >
      {props.children}
    </motion.div>
  );
}
