const LOCALSTORAGE_CID_KEY = "trackdesk_cid";

/**
 * Check for a CID in the query params and store it for later.
 */
export function storeQueryStringCid() {
  const queryParams = new URLSearchParams(window.location.search);
  const cid = queryParams.get("cid");

  // Check if we have a UUID!
  if (cid && isUUID(cid)) {
    localStorage.setItem(LOCALSTORAGE_CID_KEY, cid);
  }
}

/**
 * Attempts to get a CID from local storage first, otherwise looks for a CID in Trackdesk cookies.
 */
export function getTrackDeskCid() {
  const storedCid = localStorage.getItem(LOCALSTORAGE_CID_KEY);
  if (storedCid && isUUID(storedCid)) {
    return storedCid;
  }

  // taken direct from the Trackdesk docs.
  const cookie = document.cookie.match("(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)");
  if (Array.isArray(cookie)) {
    try {
      const trakdeskCid = JSON.parse(cookie.pop());
      return trakdeskCid["cid"];
    } catch (e) {
      console.error(e);
    }
  }
  // return undefined if nothing is found.
}

function isUUID(uuid) {
  return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
    uuid
  );
}
