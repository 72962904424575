import Button from "components/button/button";
import Input from "components/input/input";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { isValidHttpUrl } from "utils/constant";
import { checkValidWebsite } from "utils/utils";
import validator from "validator";

export default function CampaignBasicDetails({
  setLoadingSnapshot,
  loadingSnapshot,
}) {
  const { snapshot, popup, setPopup, setSelectedTab } = useContext(Context);
  const { http } = global.services;
  const [state, setState] = useState({
    loading: false,
    validationErrors: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPopup((prev) => ({ ...prev, [name]: value }));
    setState((prev) => ({
      ...prev,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleOnSave = async (data = {}) => {
    const params = {
      ...popup,
      ...data,
    };
    try {
      if (popup.id) {
        setState((prev) => ({ ...prev, loading: true }));
        const updatePopup = await http.put(`popups/${popup?.id}`, params);
        if (updatePopup) {
          const updated = {
            ...popup,
            ...updatePopup?.data?.data,
          };
          setPopup(updated);
          return updated;
        }
      } else {
        setState((prev) => ({ ...prev, loading: true }));
        //insert only if campaign url and id are filled
        if (params.website_url) {
          const values = {
            ...params,
            goal: "leads",
          };
          const insertPopup = await http.post("popups", values);
          const data = insertPopup?.data?.data;
          if (data) {
            const updated = {
              ...popup,
              ...insertPopup?.data?.data,
            };
            setPopup(updated);
            return updated;
          }
        }
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));
      toast.error("It appears that something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const takeSnapShot = async (reload = false) => {
    setState((prev) => ({
      ...prev,
      validationErrors: { ...state.validationErrors, website_url: null },
    }));
    let url = popup?.website_url;
    let newUrl = "";
    let finalUrl = "";
    if (!_.isEmpty(url)) {
      newUrl = _.toLower(url).includes("http") ? url : `https://${url}`;
      finalUrl = _.toLower(url).includes("https") ? url : newUrl;
      if (checkValidWebsite(url)) {
        return setState((prev) => ({
          ...prev,
          validationErrors: {
            ...state.validationErrors,
            website_url:
              "Your URL is incorrect. Please check it and the try loading again.",
          },
        }));
      } else {
        const res = await http.post("blacklist/check", {
          website_url: popup?.website_url,
        });
        if (res.data.isBlackllisted) {
          return setState((prev) => ({
            ...prev,
            validationErrors: {
              ...state.validationErrors,
              website_url:
                "The URL is blacklisted. Please contact support if you think this is a mistake.",
            },
          }));
        } else {
          setPopup((prev) => ({ ...prev, website_url: finalUrl }));
        }
      }
    }

    if (url) {
      //validate if there is already snapshot taken
      if (_.isEmpty(snapshot) || reload) {
        // check if website url is not null
        if (finalUrl) {
          // validate URL if it is valid url
          if (validator.isURL(finalUrl) && isValidHttpUrl(finalUrl)) {
            setLoadingSnapshot(true);
            try {
              const { id } = await handleOnSave({ website_url: finalUrl });
              await http.post(`popups/${id}/snapshot`);
            } catch (error) {
              console.log("error", error);
              if (error.status === 422) {
                return setState((prev) => ({
                  ...prev,
                  validationErrors: error.data.data,
                }));
              }
              setLoadingSnapshot(false);
            }
          } else {
            return setState((prev) => ({
              ...prev,
              validationErrors: {
                ...state.validationErrors,
                website_url:
                  "Your URL is incorrect. Please check it and the try loading again.",
              },
            }));
          }
        }
      } else {
        // If snapshot is already taken, save the website URL on DB
        handleOnSave({ website_url: finalUrl });
      }
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...state.validationErrors,
          website_url: "Website URL is required.",
        },
      }));
    }
  };

  const handleChangeStep = () => {
    setSelectedTab("popout");
  };

  return (
    <div className="w-full">
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Start Building Your Popout Page</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <div
          className="bg-white rounded-xl relative campaign-left-max"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            {/* first */}
            <div>
              <p className="text-xl pl-1 pb-2">
                Which website is this popout page going to appear on?
              </p>
              <Input
                inputContainerClass="input-container"
                value={popup?.website_url || ""}
                outsideLabel="Sample Website URL"
                labelClass="mb-5"
                placeholder="e.g. www."
                name="website_url"
                type="text"
                disabledInput={loadingSnapshot}
                showGo={true}
                onChange={handleInputChange}
                onReloadSnapshot={() => {
                  if (!loadingSnapshot) {
                    takeSnapShot(true);
                  }
                }}
                errorMessage={state.validationErrors?.website_url}
              />
              {popup?.id && (
                <Input
                  inputContainerClass="input-container"
                  value={popup?.title || ""}
                  outsideLabel="Name Your Popout"
                  labelClass="mb-5"
                  placeholder="e.g. My Popout"
                  name="title"
                  type="text"
                  onChange={handleInputChange}
                  onBlur={(e) => {
                    if (!_.isEmpty(e.target.value)) {
                      handleOnSave();
                    }
                  }}
                  errorMessage={state.validationErrors?.title}
                />
              )}
            </div>
          </div>
          {/* footer */}
          {popup?.id && (
            <div className="border-t border-tabBorder">
              <div className="flex flex-row flex-1 justify-end p-5">
                <Button
                  buttonName="NEXT"
                  buttonClass="relative bg-primary rounded-full py-3 px-12"
                  buttonType="primary"
                  onClick={handleChangeStep}
                  disabled={
                    _.isEmpty(popup.website_url) ||
                    _.isEmpty(popup.title) ||
                    !_.isEmpty(state.validationErrors?.title) ||
                    !_.isEmpty(state.validationErrors?.website_url)
                  }
                />
              </div>
            </div>
          )}
        </div>
        {/* end card start */}
      </div>
    </div>
  );
}
