import React, { useEffect, useState } from "react";
import Input from "components/input/input";
import toast from "react-hot-toast";
import Button from "components/button/button";
import { Link, useHistory } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import LoadingIndicator from "assets/svg/loading-indicator";
import "../../assets/css/pages/forgot-password.css";

const PasswordReset = () => {
  const history = useHistory();
  const { http } = global.services;
  const [state, setState] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    loading: false,
    validationErrors: null,
    success: false,
    showPassword: false,
    showConfirmPassword: false,
    verifying: true,
  });

  useEffect(() => {
    const initialization = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        await http.post("auth/verify", {
          requestToken: urlSearchParams.get("requestToken"),
        });
        setState((prevState) => ({ ...prevState, verifying: false }));
      } catch (error) {
        let errorMsg =
          error?.data.error ||
          "An unknown error occured. Please refresh the page and try again.";
        setState((prevState) => ({
          ...prevState,
          error: errorMsg,
          verifying: false,
        }));
      }
    };

    initialization();
  }, [http]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async (e) => {
    setState((prev) => ({ ...prev, loading: true }));
    const urlSearchParams = new URLSearchParams(window.location.search);

    try {
      await http.post("auth/reset-password", {
        ...state,
        token: urlSearchParams.get("requestToken"),
      });
      setState((prev) => ({ ...prev, success: true }));
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({ ...prev, validationErrors: error.data }));

      toast.error(
        error.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <div className="page-wrapper">
      <div className="flex flex-row items-center justify-center space-x-2">
        <h2 className="column-title">
          {state.verifying
            ? `Verifying Request Token`
            : `Password Reset ${state.success ? "Success" : "Form"}`}
        </h2>
        {state.verifying && (
          <LoadingIndicator className="text-primary w-5 h-5" />
        )}
      </div>
      {!state.verifying && (
        <>
          {state.success ? (
            <p className="column-subtitle text-green">
              Congratulations! Your account's password has been successfully
              reset.
            </p>
          ) : (
            <p className="column-subtitle">
              Fill-up the form below to reset your account's current password.
            </p>
          )}

          <form
            className="column-container"
            id="password-reset-page-component"
            onSubmit={() =>
              state.success ? history.push("/login") : handleSubmit()
            }
          >
            {!state.success && (
              <>
                <Input
                  inputContainerClass="input-container"
                  value={state.email}
                  autoFocus={true}
                  name="email"
                  label="Email Address"
                  type="text"
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.email}
                />

                <Input
                  inputContainerClass="input-container"
                  value={state.password}
                  label="Password"
                  name="password"
                  type={state.showPassword ? "text" : "password"}
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.password}
                />

                <Input
                  inputContainerClass="input-container"
                  value={state.password_confirmation}
                  label="Confirm Password"
                  name="password_confirmation"
                  type={state.showConfirmPassword ? "text" : "password"}
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.password_confirmation}
                />
              </>
            )}

            <Button
              buttonName={state.success ? "Go to login" : "Reset Password"}
              buttonWidth="100%"
              buttonClass="relative py-3 bg-secondary"
              buttonType="secondary"
              onClick={() =>
                state.success ? history.push("/login") : handleSubmit()
              }
              disabled={state.loading}
              type="submit"
              icon={
                <div className="absolute right-3 bottom-0 top-0 flex items-center">
                  {state.loading ? (
                    <LoadingIndicator className="text-white w-5 h-5" />
                  ) : (
                    <FaChevronRight className="text-white text-base 2md:text-lg" />
                  )}
                </div>
              }
            />

            <div className="mt-8">
              <p className="no-account-text">
                Don't have an account?{" "}
                <Link to={"/sign-up"} className="no-account-link">
                  Sign Up
                </Link>
              </p>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default PasswordReset;
