import React, { useState, useRef, useEffect, memo } from "react";
import { FaChevronDown } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { EmptyWireframe } from "constants/images";
import "./styles.css";
import _ from "lodash";
import { getPopoutImage } from "utils/popout-wireframes";

const SelectPopout = ({
  options = [], // popout object
  label = "Choose the popout",
  abTest = false,
  disabled = false,
  name = "popout",
  value = "",
  onChange = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState("");
  const [list, setList] = useState([]);

  // end loader function
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleOnChange = (e) => {
    let filterValue = e.target?.value;
    if (filterValue) {
      let result = _.filter(options, function (b) {
        let label = _.lowerCase(b.title);
        return label.includes(_.lowerCase(filterValue));
      });
      setList(result);
      setOpen(true);
    } else {
      setList(options);
    }
    setFilter(e.target?.value);
  };

  const handleOnSelect = (obj) => {
    //call props function
    onChange(obj);
    setSelected(obj);
    //set state to false
    setOpen(false);
  };

  // outside clicks
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      // set close if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) setOpen(false);
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  // end: outside clicks

  useEffect(() => {
    setList(options);
    setSelected(value);
  }, [options, value]);

  return (
    <div className="w-full mt-2">
      {label && (
        <label className="text-base font-light" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="select-wrapper" ref={wrapperRef}>
        {/* <!-- component --> */}
        <div className="relative w-full">
          {/* <!-- trigger button --> */}
          <button
            type="button"
            className={`select-big ${open ? "ring-black" : "ring-thBorder"} ${
              disabled ? "pointer-events-none bg-lightGray" : ""
            }`}
            onClick={() => (disabled ? null : handleOpen())}
          >
            {/* abtest */}
            {abTest && (
              <div
                className={`ab-test-select ${
                  name === "popout"
                    ? "bg-secondary text-white"
                    : "bg-primary text-white"
                }`}
              >
                {name === "popout" ? "A" : "B"}
              </div>
            )}
            <div className="flex space-x-7 w-full px-4 text-left mr-2">
              {selected && (
                <>
                  <div
                    className="select-image"
                    style={
                      selected?.desktop_image
                        ? {
                            background: `url(${selected?.desktop_image?.url})`,
                            backgroundSize: "cover",
                          }
                        : {
                            background: "#AEACDF",
                          }
                    }
                  >
                    <img
                      className="w-full h-full rounded-md"
                      alt="popout-website"
                      src={
                        getPopoutImage(
                          selected?.template_type,
                          selected?.contact_method_type
                        ) || EmptyWireframe
                      }
                    />
                  </div>
                  <div className="my-auto">
                    <p className="text-xl">
                      {selected?.title || selected?.quote || "Untitled"}
                    </p>
                  </div>
                </>
              )}
            </div>
            <FaChevronDown />
          </button>
          {/* <!-- list items --> */}
          <div
            className={`z-50 select-div-container ${open ? "block" : "hidden"}`}
          >
            {/* search */}
            <div className="relative border-b border-campainBg px-8 py-1">
              <FiSearch className="absolute top-4 left-9 text-xl" />
              <input
                className="w-full py-3 px-9"
                placeholder="Search"
                value={filter}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            {/* end: search */}
            <div className="select-div popout-scroll">
              <ul>
                {_.isEmpty(list) && (
                  <li className="p-2 text-center opacity-50">
                    No Data. Try again
                  </li>
                )}
                {(list || []).map((obj, index) => (
                  <li
                    key={index}
                    className={`select-div-list ${
                      obj?.id === selected?.id && "list-active"
                    }`}
                    onClick={() => handleOnSelect(obj)}
                  >
                    <p className="text-xl py-2">
                      {obj.title || obj.quote || "Untitled"}
                    </p>
                    {/* <span className="text-sm opacity-40">
                      {obj?.subTitle || "from Special Conditions"}
                    </span> */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SelectPopout);
