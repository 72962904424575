import React, { useEffect, useState } from "react";
import Layout from "layouts/layout";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

export default function GrantAccess() {
  const { http } = global.services;
  const { key } = useParams();
  const [success, setSuccess] = useState({
    message: "Support Access Ready",
    success: true,
  });

  const accessGranted = async () => {
    try {
      const req = await http.get(`public/supports/access/${key}`);
      const data = req.data;
      if (data.success) {
        setSuccess(data);
      }
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    }
  };

  useEffect(() => {
    accessGranted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Grant Access" routeName="grant-access" id="scrollable">
      <div className="flex flex-col flex-1 w-full">
        {success.success && (
          <div className="alerts">
            <div className={"w-full alert"}>
              <div className={"flex items-center"}>
                <div className="flex items-center">
                  <p className="ml-1">{success.message}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
