const ArrowDownIconSVG = () => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.5 1.5L6.5 6.5L11.5 1.5" stroke="black" strokeWidth="1.5" />
    </svg>
  );
};

export default ArrowDownIconSVG;
