const CloseIconSVG = ({ className = "" }) => (
  <svg
    width="14"
    height="14"
    className={className}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L13 13" stroke="currentColor" strokeWidth="2.5" />
    <path d="M13 1L1 13" stroke="currentColor" strokeWidth="2.5" />
  </svg>
);

export default CloseIconSVG;
