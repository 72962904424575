import React, { useState } from "react";
import Button from "components/button/button";
import Modal from "components/global/modal";
import { ColorExtractor } from "react-color-extractor";
import _ from "lodash";

export default function ColorPickerModal({ hide, file, ...props }) {
  const [colors, setColors] = useState([]);
  const getColors = (colors) => {
    let uniq = _.uniq(colors);
    setColors((prev) => [...prev, ...uniq]);
  };

  return (
    <Modal
      width="30%"
      footerClass="border-t border-tabBorder"
      footer={[
        <Button
          key="save-btn"
          buttonName="SAVE"
          buttonClass="bg-primary rounded-full py-2"
          buttonTextClass="text-white text-sm font-bold"
          buttonType="primary"
          buttonWidth="30%"
          onClick={() => hide(colors)}
        />,
        <Button
          key="cancel-btn"
          buttonName="CANCEL"
          buttonClass="bg-transparent rounded-full py-3"
          buttonType="transparent"
          buttonTextClass="text-black text-sm font-bold"
          buttonWidth="30%"
          onClick={() => hide()}
        />,
      ]}
    >
      <div className="px-4">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:text-left w-full">
            <h3 className="text-2xl leading-9" id="modal-title">
              Color Picker from Image
            </h3>
          </div>
          {/* close button */}
          <div className="mt-5 text-center sm:text-right">
            <span
              className="close opacity-100 h-4 w-4"
              onClick={() => hide()}
            ></span>
          </div>
          {/* close button */}
        </div>
        {/* image */}

        <div className="mt-4">
          <ColorExtractor getColors={getColors}>
            <img
              src={file}
              className="w-full rounded-lg object-cover"
              style={{ height: "280px" }}
              alt="color-extract"
            />
          </ColorExtractor>
        </div>
        {/* colors */}
        <div className="mt-9 h-12 rounded-xl flex">
          {colors.map((color, index) => {
            let size = _.size(colors);
            let radius = "";
            if (index === 0) {
              radius = "rounded-l-xl";
            } else if (index === size - 1) {
              radius = "rounded-r-xl";
            }
            return (
              <div
                key={index}
                className={`w-full cursor-pointer ${radius}`}
                style={{ background: color }}
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
