const PersonIconSVG = ({ className = "" }) => (
  <svg
    width="26"
    height="30"
    viewBox="0 0 26 30"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.9996" cy="7.57818" r="7.22222" fill="#C4C4C4" />
    <path
      d="M0 28.8003C0 22.6638 4.97461 17.6892 11.1111 17.6892H14.8889C21.0254 17.6892 26 22.6638 26 28.8003V28.8003C26 29.0458 25.801 29.2448 25.5556 29.2448H0.444444C0.198985 29.2448 0 29.0458 0 28.8003V28.8003Z"
      fill="#C4C4C4"
    />
  </svg>
);

export default PersonIconSVG;
