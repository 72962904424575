import React, { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import toast from "react-hot-toast";
import LoadingIndicator from "assets/svg/loading-indicator";
import parseUrl from "parse-url";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function VerifyEmailModal({ hide, ...props }) {
  const { http, localstorage } = global.services;
  const history = useHistory();
  const { userData, setUserData } = props;
  const [loading, setLoading] = useState(false);
  const [btnName, setBtnName] = useState({
    name: "SEND VERIFICATION",
    disable: false,
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setBtnName({ name: "LOADING ...", disable: true });
      const params = { email: userData.user.email };
      await http.post(`auth/resend-email-verification`, params);
      toast.success("Verification has been sent to your email");
      setBtnName({ name: "SENT", disable: true });
    } catch (error) {
      setLoading(false);
      toast.error(
        error.status === 401
          ? "oops! Something went wrong"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      const parts = parseUrl(window.location.href);
      const pathname = parts.pathname || "";
      const search = parts.search || "";
      const hash = parts.hash || "";

      await http.post("auth/logout");
      localstorage.remove("user_data"); // Remove user data on storage
      setUserData(null); // Remove user data on redux
      history.push(`${pathname + search + hash}`); // Redirect to current page
    } catch (error) {
      console.error(error);
    }
  };

  const Footer = () => {
    return (
      <div className="relative flex items-center space-x-2 justify-end">
        <Button
          key="logout-btn"
          buttonName="LOGOUT"
          buttonClass="bg-default rounded-full py-3 px-9"
          buttonType="default"
          buttonTextClass="text-black text-sm font-bold"
          onClick={() => handleLogout()}
        />
        <Button
          key="save-btn"
          buttonName={btnName.name}
          buttonClass="bg-primary rounded-full py-3 px-12"
          buttonTextClass="text-white text-sm font-bold"
          buttonType="primary"
          onClick={() => handleSubmit()}
          disabled={btnName.disable}
          icon={
            loading && (
              <div className="absolute right-3 bottom-0 top-0 flex items-center">
                <LoadingIndicator className="text-white w-5 h-5" />
              </div>
            )
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-17/20 md:w-14/20 2md:w-10/20 3md:w-6/20"
      responsive={true}
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-tabBorder"
      footerDefaultClass="" //leave this empty
    >
      <div className="w-full px-4">
        {/* creadit card */}
        <div className="w-full">
          <h3 className="text-xl mt-2 font-medium" id="modal-title">
            Verify Email Address
          </h3>
          <p className="opacity-70 mt-5">
            We're happy you signed up for ConversionCow. To start exploring
            ConversionCow App, please confirm your email address.
          </p>
        </div>
      </div>
    </Modal>
  );
}
