import styled from "styled-components";
import { device } from "../../screens";

const ScrollableDiv = styled.div`
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    border-radius: 10px;
  }

  height: calc(100vh - 95px);

  @media ${device.xs} {
    height: calc(100vh - 86px);
  }

  @media (min-width: 1${({ width }) => width + 1}px) {
    height: 99%;
  }
`;

export default ScrollableDiv;
