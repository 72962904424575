const ContactDetailsAvatarSVG = () => {
  return (
    <svg
      width="81"
      height="105"
      viewBox="0 0 81 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="40.2275"
        cy="64.051"
        r="39.8504"
        fill="#EBEBEB"
        stroke="#BEBEBE"
        strokeWidth="0.754266"
      />
      <g clipPath="url(#clip0_1428_22813)">
        <path
          d="M25.5602 21.7336C26.3055 23.1382 26.763 18.4348 27.4831 18.0086C28.2032 17.5823 34.1185 13.8019 36.0355 12.8223C36.693 12.5232 37.3943 12.3968 38.0987 12.4501C38.803 12.5035 39.4966 12.7357 40.139 13.1332C42.0527 14.2567 42.9434 16.8962 43.3903 19.5219C44.0028 23.1437 44.8218 22.3838 45.2984 24.428C45.8854 26.946 46.2777 28.6634 46.59 28.9893C46.9005 29.1579 47.2439 29.1825 47.56 29.0589C47.8761 28.9353 47.9133 29.0463 48.0511 28.8695C48.3255 28.5175 48.3213 27.9482 48.3167 26.5666C48.5262 23.4507 48.2473 20.2928 47.4962 17.2788C46.74 14.2519 43.3901 10.5423 43.3901 10.5423C43.3901 10.5423 38.3471 8.01113 35.2941 8.40175C32.2565 8.82218 28.9162 8.35534 26.698 10.9858C25.6274 12.1399 25.0375 12.5849 25.1818 16.2866"
          fill="#263238"
        />
        <path
          d="M31.6201 48.3646C31.6201 48.3646 28.6873 68.2043 28.4899 70.5929L53.5343 72.7843L49 51.0002C49 51.0002 48.3236 47.1472 47.7428 43.2006C46.7789 36.6522 50.2469 27.39 50.2469 27.39C50.8529 19.0891 46.493 10.8268 38.4571 10.4836L37.7117 10.4727C34.1003 10.5414 29.236 11.1921 26.6107 13.7735C23.9853 16.355 23.6366 19.5646 23.5413 24.4061C23.446 29.2476 23.4198 28.4874 23.9496 32.7137C24.4794 36.9399 24.8888 44.2959 31.6201 48.3646Z"
          fill="#FFBE9D"
        />
        <path
          d="M40.5867 36.1724C40.7171 36.1291 40.6875 36.7652 41.1291 37.1337C41.5707 37.5023 42.2151 37.4185 42.1826 37.5475C42.1728 37.6053 41.9797 37.7182 41.6879 37.7674C41.3037 37.8215 40.9748 37.7437 40.7461 37.5448C40.5162 37.3533 40.3999 37.0469 40.4143 36.6706C40.4306 36.3882 40.5271 36.1909 40.5867 36.1724Z"
          fill="#EB996E"
        />
        <path
          d="M25.5418 29.1564C25.5169 29.606 26.4422 31.1635 27.887 31.2154C29.3319 31.2672 30.5972 30.1181 30.6221 29.6686C30.647 29.219 29.5313 27.6708 28.0865 27.619C26.6416 27.5671 25.5667 28.7069 25.5418 29.1564Z"
          fill="white"
        />
        <path
          d="M26.2253 28.8908C26.078 29.3624 26.1344 29.8642 26.3822 30.2883C26.6301 30.7124 27.0497 31.025 27.5511 31.1591C27.792 31.2365 28.0485 31.2676 28.3052 31.2507C28.5619 31.2338 28.8137 31.1692 29.0456 31.0607C29.2774 30.9523 29.4846 30.8022 29.6548 30.6194C29.825 30.4366 29.9548 30.2248 30.0363 29.9967C30.1819 29.5254 30.1258 29.0248 29.88 28.6005C29.6341 28.1763 29.2177 27.8616 28.7188 27.7231C28.4769 27.6466 28.2197 27.6162 27.9624 27.6337C27.705 27.6512 27.4527 27.7163 27.2204 27.8251C26.988 27.9339 26.7802 28.0843 26.6093 28.2673C26.4384 28.4503 26.3079 28.6623 26.2253 28.8908Z"
          fill="#EB996E"
        />
        <path
          d="M26.2577 28.9664C26.131 29.3722 26.1795 29.8039 26.3928 30.1688C26.6061 30.5337 26.9671 30.8027 27.3985 30.9181C27.6058 30.9847 27.8265 31.0114 28.0474 30.9969C28.2683 30.9824 28.4849 30.9268 28.6844 30.8335C28.8839 30.7401 29.0622 30.611 29.2087 30.4537C29.3551 30.2964 29.4667 30.1142 29.5369 29.9179C29.6621 29.5124 29.6139 29.0816 29.4024 28.7166C29.1908 28.3516 28.8325 28.0808 28.4032 27.9616C28.1951 27.8958 27.9738 27.8696 27.7524 27.8847C27.531 27.8998 27.3139 27.9558 27.1139 28.0494C26.914 28.143 26.7352 28.2724 26.5882 28.4299C26.4411 28.5873 26.3288 28.7698 26.2577 28.9664Z"
          fill="#263238"
        />
        <ellipse
          rx="0.654787"
          ry="0.599582"
          transform="matrix(0.985845 0.162825 -0.207498 0.97965 27.1539 28.9098)"
          fill="white"
        />
        <path
          d="M34.7056 40.4252C34.7006 40.2795 36.0017 40.4564 37.2321 39.8861C37.7573 39.6296 38.2335 39.3125 38.6486 38.9431C38.9865 38.6637 39.2149 38.4874 39.2791 38.5311C39.3433 38.5748 39.2105 38.8028 38.9352 39.1324C38.5714 39.594 38.0849 39.9737 37.5094 40.245C36.9227 40.5143 36.2446 40.6359 35.5484 40.5966C34.9925 40.5802 34.702 40.4725 34.7056 40.4252Z"
          fill="#263238"
        />
        <rect x="45" y="12" width="2.81749" height="3.4436" fill="#FFBE9D" />
        <ellipse
          cx="33.3279"
          cy="35.5372"
          rx="0.80868"
          ry="0.80868"
          transform="rotate(1.79121 33.3279 35.5372)"
          fill="#EB996E"
        />
        <path
          d="M31.6183 48.3663C35.2253 48.3939 39.3432 47.9491 43.2015 44.2968C43.2015 44.2968 39.0919 51.0414 31.305 50.2449L31.6183 48.3663Z"
          fill="#EB996E"
        />
        <path
          d="M49.202 30.0464C49.3467 30.0255 55.3367 29.825 53.3364 35.5932C51.336 41.3614 46.9043 38.4566 46.946 38.2866C46.9878 38.1167 49.202 30.0464 49.202 30.0464Z"
          fill="#FFBE9D"
        />
        <path
          d="M49.4186 36.4118C49.4186 36.4118 49.4829 36.512 49.6114 36.6348C49.6995 36.7181 49.8031 36.7815 49.916 36.8212C50.0289 36.861 50.1489 36.8764 50.2694 36.8666C50.6738 36.7586 51.0482 36.5395 51.3543 36.2318C51.6604 35.924 51.8871 35.5387 52.0112 35.1153C52.1812 34.6523 52.2568 34.1599 52.2327 33.6717C52.2439 33.486 52.2129 33.302 52.1421 33.1354C52.0714 32.9687 51.9631 32.8243 51.8263 32.7144C51.7357 32.6478 51.6243 32.6188 51.5121 32.6326C51.4 32.6464 51.2946 32.7022 51.2149 32.7898C51.0918 32.9112 51.1251 33.0489 51.0579 33.035C50.9907 33.021 50.9865 32.9032 51.1045 32.6928C51.1777 32.57 51.2867 32.4753 51.4139 32.4238C51.5933 32.3604 51.786 32.3727 51.9513 32.458C52.1469 32.576 52.3076 32.7481 52.417 32.9569C52.5264 33.1656 52.5808 33.4035 52.5745 33.6467C52.6272 34.1918 52.5519 34.7478 52.3552 35.2655C52.2102 35.76 51.9328 36.2041 51.5577 36.5425C51.1825 36.8809 50.7263 37.0986 50.2457 37.1684C50.096 37.162 49.9508 37.1201 49.8208 37.0458C49.6908 36.9715 49.5793 36.8667 49.4943 36.739C49.3817 36.5442 49.3996 36.4148 49.4186 36.4118Z"
          fill="#EB996E"
        />
        <path
          d="M40.387 13.8288C38.0391 14.1418 36.3173 13.2851 34.2824 13.8288C32.2476 14.3724 29.973 15.1944 28.3342 12.7333C26.299 9.67703 28.4913 7.09846 30.5256 6.31566C32.56 5.53286 35.7783 8.35011 39.2916 8.35024C43.5173 8.35041 46.0376 9.0678 48.0769 11.8541C49.6333 14.1516 50.5965 17.0086 50.847 20.0704C51.0945 23.1188 50.8529 26.2291 50.1362 29.2185L50.131 29.25C49.9133 30.5686 49.823 31.1154 49.4974 31.4096C49.3325 31.5585 49.314 31.4446 48.982 31.5126C48.6499 31.5806 48.805 31.5747 48.5264 31.36C48.2717 30.9924 49.1723 25.5684 46.9609 22.5944C44.7494 19.6204 48.3897 15.7638 46.1782 13.8288C43.9668 11.8938 42.7349 13.5157 40.387 13.8288Z"
          fill="#263238"
        />
        <path
          d="M36.0562 28.6953C36.2342 28.8751 37.3689 28.0865 38.9382 28.0802C40.5076 28.0739 41.6315 28.8068 41.8089 28.6209C41.898 28.5423 41.7258 28.2178 41.2381 27.8725C40.5757 27.4305 39.7759 27.2003 38.9481 27.2132C38.1318 27.216 37.3257 27.4676 36.6481 27.9311C36.1507 28.2843 35.9725 28.6146 36.0562 28.6953Z"
          fill="#263238"
        />
        <path
          d="M25.3812 27.2694C25.5326 27.4507 26.6032 26.863 28.0249 27.0274C29.4466 27.1918 30.4247 27.9742 30.5954 27.8259C30.6804 27.7647 30.5421 27.4535 30.1192 27.0894C29.5433 26.6193 28.8315 26.3251 28.081 26.2471C27.3416 26.1611 26.5978 26.3005 25.9589 26.6448C25.4891 26.9092 25.3098 27.1876 25.3812 27.2694Z"
          fill="#263238"
        />
        <path
          d="M35.5589 24.4089C36.3643 25.1317 41.7716 23.418 45.5376 27.0629C44.9236 21.4229 36.0485 23.0771 35.5749 23.148C35.5749 23.148 34.7535 23.6862 35.5589 24.4089Z"
          fill="#263238"
        />
        <path
          d="M31.5296 23.3804C30.9654 24.0077 27.3331 21.7095 24.6884 24.9056C25.2394 19.3823 31.2287 22.0057 31.5487 22.1286C31.5487 22.1286 32.0938 22.7531 31.5296 23.3804Z"
          fill="#263238"
        />
        <path
          d="M33.1573 26.2483C32.968 27.289 32.73 28.3023 32.5281 29.3334L32.2261 30.8856L31.9114 32.4281C31.7987 32.9396 31.7096 33.4593 31.6094 33.9803L31.5248 34.364L31.5033 34.4571C31.5047 34.4682 31.4966 34.4918 31.4981 34.5029L31.4914 34.5377C31.4943 34.5598 31.4847 34.5724 31.4876 34.5946C31.4906 34.6168 31.481 34.6293 31.4839 34.6515C31.4904 34.786 31.5539 34.9243 31.6684 35.022C31.7201 35.0716 31.7926 35.1071 31.862 35.1205C31.8968 35.1272 31.9315 35.1338 31.9773 35.139L32.0106 35.1346L32.055 35.1288L32.1563 35.1266C32.4254 35.1135 32.6834 35.1019 32.9525 35.0888C33.481 35.0752 34.0111 35.0726 34.547 35.1144L34.5573 35.192C34.0508 35.372 33.5384 35.5077 33.0245 35.6322C32.7613 35.6897 32.5091 35.7456 32.2459 35.8031L32.1476 35.8274L32.1032 35.8333L32.0367 35.8421C31.948 35.8539 31.8467 35.856 31.755 35.8456C31.5717 35.8248 31.3825 35.7596 31.2222 35.6567C31.0618 35.5538 30.9303 35.4132 30.8292 35.2461C30.728 35.0789 30.6794 34.8823 30.6655 34.6923C30.6596 34.6479 30.6634 34.591 30.6686 34.5452C30.6738 34.4993 30.679 34.4535 30.6842 34.4077L30.6976 34.3382L30.7139 34.2909L30.745 34.1852L30.8532 33.8097C31.0007 33.3049 31.1593 32.7987 31.2957 32.2953L31.7146 30.7728L32.1445 29.2488C32.4381 28.2281 32.8542 26.514 33.0392 26.2074C33.2242 25.9009 33.1573 26.2483 33.1573 26.2483Z"
          fill="#263238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.4715 51.401C47.4858 51.6302 47.7108 53.4526 47.9858 55.6704C48.1854 56.875 48.009 58.1141 47.4822 59.2079C47.0111 60.145 46.4396 61.0241 45.7785 61.8287L42.3281 64.9411L41.5638 67.8983L36.9205 61.0525C33.2524 60.0655 31.5379 54.3693 31.5379 54.3693L31.1254 51.7276L28.8935 54.0341L24.8289 55.1024C24.8289 55.1024 15.7531 58.0596 13.4922 60.6101L20.9214 96.377L20.7893 107.666H63.8216C63.8216 107.108 64.2074 70.1913 64.2074 70.1913L66.7898 55.7049L51.5063 52.9215L48.9689 50.8556C48.098 51.2552 47.4715 51.401 47.4715 51.401Z"
          fill="#EC5627"
        />
        <path
          d="M56 95.7425C56.6363 94.8895 57.3219 94.0769 58.0528 93.3093C58.7401 92.4961 59.4729 91.7253 60.2476 91.0011C59.6087 91.8542 58.9221 92.6679 58.1913 93.438C57.5041 94.249 56.7725 95.0184 56 95.7425Z"
          fill="white"
        />
        <path
          d="M56.1487 86.5741C55.1698 85.5757 54.4334 84.3514 54 83.0015C54.0533 82.9795 54.4333 83.8359 55.0299 84.8172C55.6266 85.7986 56.1913 86.5374 56.1487 86.5741Z"
          fill="white"
        />
        <path
          d="M21 79.7425C21.6363 78.8895 22.3219 78.0769 23.0528 77.3093C23.7401 76.4961 24.4729 75.7253 25.2476 75.0011C24.6087 75.8542 23.9221 76.6679 23.1913 77.438C22.5041 78.249 21.7725 79.0184 21 79.7425Z"
          fill="white"
        />
        <path
          d="M24 91.1471C24.1486 89.7433 24.4172 88.3558 24.8027 87.0011C24.7597 87.7063 24.6563 88.4062 24.4937 89.0925C24.39 89.7915 24.2248 90.4793 24 91.1471Z"
          fill="white"
        />
        <path
          d="M66.794 55.7036C68.4109 56.6553 69.8294 57.9293 70.9673 59.4521C72.1052 60.9749 72.9399 62.7162 73.4232 64.5751C75.1483 70.9626 80.4238 87.5966 80.4238 87.5966C80.4238 87.5966 83.4384 91.2672 83.7598 93.9841C84.0813 96.7009 83.4384 103.277 82.4668 104.504C82.1855 104.86 81.8242 105.14 81.4146 105.318C81.005 105.497 80.5594 105.569 80.1166 105.528L67.4262 104.282L62.4258 69.654L66.794 55.7036Z"
          fill="#EC5627"
        />
        <path
          d="M13.4951 60.6089C13.4951 60.6089 10.5056 64.8265 9.26618 67.5804C8.02678 70.3343 -2.26341 93.4556 -2.87775 95.8472C-3.49209 98.2388 -5.09224 105.757 -0.395394 107.502H9.27332L18.9742 87.1975L19.4706 63.6326L13.4951 60.6089Z"
          fill="#EC5627"
        />
        <path
          d="M9.88105 92.8352C9.88105 92.8352 9.66674 92.6392 9.22027 92.3768C8.58302 92.0231 7.8997 91.7666 7.19152 91.6153C6.70463 91.5049 6.21029 91.4333 5.71283 91.4009C5.13967 91.3563 4.56327 91.3986 4.00196 91.5266C2.73137 91.8643 1.59403 92.6034 0.748099 93.641C-0.112039 94.667 -0.741506 95.8777 -1.09492 97.1859C-1.38665 98.2551 -1.58037 99.3504 -1.67354 100.457C-1.76641 101.404 -1.79855 102.176 -1.8307 102.705C-1.8307 102.956 -1.85927 103.159 -1.86641 103.318C-1.86622 103.391 -1.87584 103.463 -1.89499 103.533C-1.89959 103.461 -1.89959 103.39 -1.89499 103.318C-1.89499 103.156 -1.89499 102.949 -1.89499 102.705C-1.89499 102.169 -1.87356 101.4 -1.79856 100.446C-1.72304 99.3213 -1.53999 98.2069 -1.25208 97.1193C-0.901156 95.7816 -0.262564 94.5432 0.615946 93.4968C1.48829 92.4302 2.66297 91.6749 3.97339 91.3381C4.54628 91.2146 5.13384 91.181 5.71639 91.2383C6.22067 91.2766 6.72115 91.357 7.21295 91.4786C7.92906 91.6433 8.61595 91.9227 9.24885 92.3066C9.41931 92.4156 9.58061 92.5392 9.73104 92.6762C9.78633 92.7235 9.83661 92.7768 9.88105 92.8352Z"
          fill="#263238"
        />
        <path
          d="M5.29883 88.7913C5.40451 88.7594 5.51379 88.742 5.62386 88.7396C5.92224 88.7156 6.22197 88.7156 6.52036 88.7396C6.96466 88.7665 7.40574 88.8346 7.83833 88.9429C8.90268 89.2092 9.90151 89.7027 10.7707 90.3919C11.1249 90.6708 11.4536 90.9826 11.753 91.3234C11.9559 91.5545 12.141 91.8017 12.3066 92.0627C12.374 92.1531 12.4293 92.2526 12.4709 92.3584C12.4423 92.3806 12.178 91.9888 11.6565 91.4269C11.3484 91.1074 11.0177 90.8121 10.6672 90.5434C10.2355 90.2182 9.77644 89.9336 9.29561 89.6933C8.81206 89.4599 8.3102 89.2694 7.79547 89.124C7.3733 89.0114 6.94344 88.9323 6.50964 88.8874C5.76672 88.7876 5.29883 88.8283 5.29883 88.7913Z"
          fill="#263238"
        />
        <path
          d="M14.0351 87.6779C13.8856 87.6842 13.7358 87.673 13.5887 87.6446C13.3029 87.6076 12.8922 87.5633 12.3778 87.5226C9.70666 87.3165 7.02221 87.6491 4.47358 88.5022C3.98425 88.6648 3.59136 88.809 3.32348 88.9162C3.18677 88.9787 3.04439 89.027 2.89844 89.0603C3.02139 88.972 3.15458 88.8999 3.2949 88.8459C3.55563 88.7166 3.94496 88.5539 4.43072 88.3728C6.98124 87.4381 9.7007 87.1023 12.3921 87.3895C12.9029 87.445 13.3172 87.5078 13.5994 87.5707C13.7476 87.5919 13.8935 87.6278 14.0351 87.6779Z"
          fill="#263238"
        />
        <path
          d="M41.5726 67.9215C41.5335 67.8863 41.4997 67.8452 41.4726 67.7995L41.2083 67.4298L40.2368 66.04C39.4046 64.8201 38.2402 63.1198 36.8722 61.1237L37.0222 61.1015C36.5007 62.6836 35.9257 64.4283 35.3221 66.2766L35.2828 66.3911L35.1828 66.3246L34.9828 66.1915C34.2045 65.676 33.4969 65.0543 32.879 64.3433C32.3079 63.6814 31.804 62.9605 31.3753 62.192C30.6617 60.8915 30.1062 59.5046 29.7216 58.063C29.4633 57.0893 29.2547 56.1022 29.0965 55.1059C29.0394 54.7732 28.9965 54.5034 28.9608 54.3001C28.9507 54.2311 28.9352 54.1631 28.9144 54.0967C28.9144 54.0524 28.8894 54.0302 28.8929 54.0265C28.8965 54.0228 29.0394 54.3962 29.168 55.0948C29.2965 55.7934 29.5001 56.7989 29.8502 58.015C30.2457 59.4432 30.8083 60.8161 31.5253 62.1033C31.9529 62.8574 32.4518 63.5656 33.0147 64.2176C33.622 64.9116 34.3163 65.5183 35.0792 66.0215L35.2756 66.1546L35.1399 66.2063C35.7507 64.3581 36.3293 62.6245 36.8579 61.0313L36.9115 60.8686L37.0079 61.0091C38.3509 63.0237 39.5082 64.7388 40.3189 65.9661C40.7083 66.5649 41.0333 67.0417 41.2512 67.3892L41.4905 67.7588C41.5233 67.8099 41.5508 67.8644 41.5726 67.9215Z"
          fill="#263238"
        />
        <path
          d="M80.9495 95.3632C80.5397 94.9228 80.1005 94.5127 79.6351 94.136C78.4915 93.2505 77.1202 92.7348 75.6941 92.6539C74.2679 92.573 72.8504 92.9305 71.6201 93.6813C71.1209 94.0033 70.6436 94.3603 70.1914 94.7496C70.2858 94.614 70.3948 94.4899 70.5164 94.38C70.8379 94.0709 71.1885 93.796 71.563 93.5594C72.8052 92.7586 74.2559 92.374 75.7172 92.458C77.1785 92.542 78.5791 93.0906 79.728 94.0288C80.0754 94.3053 80.3938 94.6188 80.6781 94.964C80.7833 95.0856 80.8744 95.2196 80.9495 95.3632Z"
          fill="#263238"
        />
        <path
          d="M80.4425 91.9512C79.9317 91.5715 79.379 91.2563 78.796 91.0123C77.3629 90.4858 75.8063 90.438 74.3456 90.8755C73.542 91.1089 72.7685 91.4415 72.0418 91.8662C71.4502 92.218 70.8918 92.6264 70.3738 93.086C69.9309 93.4778 69.5916 93.8253 69.3594 94.0582C69.2455 94.1929 69.1199 94.3166 68.9844 94.4278C69.0773 94.2786 69.185 94.1399 69.3058 94.0138C69.6143 93.6536 69.9423 93.3118 70.2881 92.9899C70.8014 92.5102 71.3602 92.0854 71.9561 91.722C72.6901 91.2781 73.4765 90.934 74.2956 90.6981C75.7921 90.2457 77.3909 90.3145 78.846 90.894C79.2805 91.0737 79.6901 91.3123 80.0639 91.6037C80.1961 91.7035 80.2854 91.7996 80.3532 91.8588C80.4211 91.9179 80.4425 91.9475 80.4425 91.9512Z"
          fill="#263238"
        />
        <path
          d="M80.4708 88.9954C80.4708 89.0324 79.7565 88.8143 78.6207 88.6553C77.9494 88.5622 77.2727 88.5177 76.5955 88.5223C75.7659 88.5317 74.9383 88.6083 74.1203 88.7514C73.3039 88.9067 72.5003 89.1267 71.7165 89.4094C71.0776 89.6442 70.4558 89.9258 69.8556 90.2522C68.8305 90.8067 68.2519 91.2576 68.2305 91.2244C68.3518 91.1025 68.4846 90.9935 68.6269 90.8991C68.7825 90.7723 68.946 90.6563 69.1163 90.5516C69.3127 90.4222 69.5342 90.2707 69.7913 90.1302C70.3917 89.7866 71.0161 89.49 71.6593 89.2431C72.4487 88.945 73.2612 88.7174 74.0881 88.5629C74.9142 88.4182 75.7505 88.3453 76.5883 88.3448C77.2738 88.3484 77.9581 88.4065 78.635 88.5186C78.9243 88.5555 79.1814 88.6258 79.4064 88.6738C79.6019 88.7147 79.795 88.7666 79.9851 88.8291C80.1513 88.8699 80.3139 88.9255 80.4708 88.9954Z"
          fill="#263238"
        />
        <path
          d="M50.1745 52.7979C50.3226 53.142 50.4144 53.5093 50.446 53.8846C50.5526 54.9448 50.502 56.0155 50.296 57.0599C50.1707 57.8095 50.0001 58.5502 49.7852 59.2777C49.5036 60.1243 49.1573 60.9463 48.7494 61.7359C47.8958 63.4362 46.8242 65.2882 45.5813 67.2806L45.4384 67.5134V67.2362C45.4384 65.9277 45.4384 64.5563 45.4384 63.1331L45.5777 63.2034L42.2238 65.972L42.2524 65.9277C41.4568 68.6259 40.9678 71.4108 40.7951 74.2262C40.4665 78.7248 40.7309 83.0274 40.9344 86.9309C41.138 90.8343 41.2273 94.3571 41.3452 97.3142C41.4631 100.271 41.5595 102.659 41.6452 104.312C41.6952 105.132 41.7309 105.768 41.7559 106.212C41.7559 106.422 41.7809 106.581 41.7881 106.707C41.7952 106.833 41.7881 106.877 41.7881 106.877C41.7881 106.877 41.7881 106.821 41.7631 106.711C41.7381 106.6 41.7345 106.426 41.7131 106.215C41.6738 105.783 41.6238 105.143 41.5702 104.315C41.4631 102.667 41.338 100.275 41.213 97.3179C41.088 94.3608 40.9344 90.8454 40.763 86.9383C40.5915 83.0311 40.2808 78.7248 40.6094 74.2114C40.79 71.3793 41.2923 68.579 42.106 65.8685V65.8426H42.1274L45.4705 63.0666L45.6099 62.9483V63.1331C45.6099 64.5563 45.6099 65.9277 45.6099 67.2362L45.4634 67.1881C47.9172 63.2514 49.9138 59.8285 50.221 57.0599C50.4331 56.0254 50.4981 54.9644 50.4138 53.9105C50.3847 53.6336 50.3357 53.3593 50.2674 53.0899C50.196 52.8903 50.1745 52.7979 50.1745 52.7979Z"
          fill="#263238"
        />
        <path
          d="M43.7646 100.479H43.6503C43.3902 100.496 43.1468 100.617 42.9716 100.817C42.7964 101.016 42.7031 101.279 42.7115 101.548C42.7198 101.818 42.8292 102.074 43.0164 102.261C43.2036 102.449 43.454 102.554 43.7146 102.554C43.9752 102.554 44.2256 102.449 44.4128 102.261C44.6001 102.074 44.7094 101.818 44.7177 101.548C44.7261 101.279 44.6328 101.016 44.4576 100.817C44.2824 100.617 44.039 100.496 43.7789 100.479H43.7646Z"
          fill="white"
        />
        <path
          d="M43.7649 86.2329H43.6506C43.3915 86.2502 43.149 86.3711 42.9745 86.5701C42.7999 86.769 42.707 87.0305 42.7154 87.2991C42.7237 87.5677 42.8327 87.8224 43.0192 88.0093C43.2058 88.1963 43.4553 88.3008 43.7149 88.3008C43.9746 88.3008 44.2241 88.1963 44.4106 88.0093C44.5972 87.8224 44.7062 87.5677 44.7145 87.2991C44.7229 87.0305 44.6299 86.769 44.4554 86.5701C44.2808 86.3711 44.0384 86.2502 43.7792 86.2329H43.7649Z"
          fill="white"
        />
        <path
          d="M43.6557 73.4856C43.9223 73.5038 44.185 73.4115 44.386 73.2292C44.5869 73.0469 44.7097 72.7894 44.7272 72.5135C44.7447 72.2375 44.6556 71.9656 44.4794 71.7577C44.3033 71.5497 44.0545 71.4227 43.7878 71.4045H43.6557C43.3885 71.4045 43.1323 71.5144 42.9434 71.7099C42.7546 71.9053 42.6484 72.1705 42.6484 72.4469C42.6484 72.7234 42.7546 72.9885 42.9434 73.184C43.1323 73.3795 43.3885 73.4893 43.6557 73.4893V73.4856Z"
          fill="white"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M29.0071 54.4905C28.5106 59.5066 30.3537 64.8036 34.2433 67.8421C34.5469 68.0823 34.9291 68.3189 35.2898 68.1821C35.5304 68.0615 35.7225 67.8575 35.8327 67.6055C36.922 65.6578 37.3306 63.3815 36.99 61.1589C36.3756 62.8371 35.847 64.4857 35.2255 66.2378C31.8431 64.1086 29.7215 59.5842 29.0071 54.4905Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.3"
          d="M42.1777 65.8989L45.0351 64.8196L45.5066 69.8135C45.5066 69.8135 48.5854 64.0064 49.8606 61.3597C51.1357 58.713 50.4785 54.0333 50.4785 54.0333C50.4785 54.0333 50.9321 58.0994 47.8497 63.3003L45.5209 67.2186V63.1155L42.1777 65.8989Z"
          fill="black"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M9.00147 91.2793C7.69886 90.7067 6.26783 90.5211 4.86896 90.7433C3.37828 90.8695 1.95218 91.4281 0.754318 92.355C-0.402927 93.3198 -1.18514 94.8427 -1.03155 96.373L-0.888687 96.3915C0.10783 93.6044 2.08658 91.5861 4.64038 91.3311C7.19417 91.076 9.68368 92.2589 11.4267 94.1995C11.2374 92.7505 10.2587 91.8966 9.00147 91.2793Z"
            fill="black"
          />
        </g>
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M81.1284 94.1159C80.1385 93.0644 78.8445 92.3753 77.4423 92.1531C76.221 91.9416 74.9694 92.0168 73.7799 92.3731C72.5904 92.7295 71.4936 93.3579 70.5703 94.212L71.1132 93.7536C72.4944 92.8928 74.08 92.4474 75.6922 92.4673C77.3102 92.4959 78.8619 93.1377 80.0533 94.2711C81.1248 95.3209 81.7642 96.7441 82.3749 98.1302C82.4738 97.4071 82.4131 96.6702 82.1972 95.9749C81.9812 95.2796 81.6158 94.644 81.1284 94.1159Z"
            fill="black"
          />
        </g>
        <path
          d="M62.7402 70.9371C62.7693 70.7985 62.8124 70.6634 62.8688 70.5342C62.9795 70.2422 63.1153 69.8836 63.2724 69.4585C63.6296 68.5529 64.0939 67.3035 64.594 65.9099C65.094 64.5164 65.5262 63.2485 65.8369 62.3318L66.1941 61.2413C66.2324 61.1051 66.2851 60.9737 66.3513 60.8495C66.3402 60.9911 66.3101 61.1304 66.262 61.2635C66.1941 61.5296 66.087 61.9067 65.9477 62.3724C65.6691 63.3039 65.2547 64.5903 64.7547 65.9765C64.2546 67.3626 63.7582 68.6194 63.3796 69.5103C63.1938 69.9575 63.0224 70.3161 62.9188 70.5638C62.8743 70.6953 62.8144 70.8207 62.7402 70.9371Z"
          fill="#263238"
        />
        <path
          d="M17.7123 71.2992C17.6623 71.2992 17.273 69.5508 16.8444 67.3698C16.4158 65.1889 16.1086 63.4331 16.1479 63.4036C16.1872 63.374 16.5837 65.152 17.0123 67.3329C17.4409 69.5138 17.7623 71.2881 17.7123 71.2992Z"
          fill="#263238"
        />
        <path
          d="M10.882 79.6971C10.2401 78.6197 9.6512 77.5093 9.1176 76.3703C8.18895 74.5221 7.4746 72.9844 7.51746 72.9585C8.15941 74.0359 8.74831 75.1463 9.2819 76.2853C9.87115 77.3936 10.4052 78.5324 10.882 79.6971Z"
          fill="white"
        />
        <path
          d="M22.5781 66.2083C22.8826 65.2607 23.2465 64.3346 23.6675 63.436C24.0329 62.5126 24.454 61.6138 24.9283 60.745C24.6219 61.6919 24.2581 62.6179 23.8389 63.5173C23.4745 64.4412 23.0533 65.34 22.5781 66.2083Z"
          fill="white"
        />
        <path
          d="M34.0536 74.9608C33.461 74.3707 32.9118 73.7356 32.4106 73.0608C31.8644 72.4258 31.3641 71.75 30.9141 71.0388C31.5071 71.6274 32.0553 72.2626 32.5535 72.9388C33.1015 73.5732 33.603 74.249 34.0536 74.9608Z"
          fill="white"
        />
        <path
          d="M31.416 87.0007C31.7784 86.325 32.1917 85.6798 32.6518 85.0712C33.0662 84.4278 33.5309 83.8208 34.0412 83.2562C33.6798 83.9326 33.2665 84.5778 32.8054 85.1858C32.3934 85.8308 31.9285 86.438 31.416 87.0007Z"
          fill="white"
        />
        <path
          d="M27.8636 104.008C27.6969 103.779 27.5566 103.531 27.4457 103.269C27.21 102.796 26.9064 102.134 26.5992 101.391C26.292 100.648 26.0456 99.9568 25.8848 99.4578C25.778 99.1918 25.7013 98.914 25.6562 98.6298C25.8034 98.875 25.9232 99.1366 26.0134 99.4097L26.7813 101.313C27.0778 102.019 27.3421 102.659 27.5743 103.21C27.6991 103.464 27.7961 103.732 27.8636 104.008Z"
          fill="white"
        />
        <path
          d="M37.94 96.7777C37.2096 96.7276 36.4844 96.6151 35.772 96.4413C35.0445 96.3298 34.3275 96.1542 33.6289 95.9164C35.0785 96.078 36.5123 96.3661 37.915 96.7777H37.94Z"
          fill="white"
        />
        <path
          d="M45.7969 80.889C46.4368 80.0311 47.1262 79.2139 47.8613 78.442C48.5525 77.6241 49.2896 76.849 50.0687 76.1206C49.4261 76.9786 48.7356 77.7969 48.0006 78.5714C47.3096 79.387 46.5738 80.1608 45.7969 80.889Z"
          fill="white"
        />
        <path
          d="M53.6525 102.079C53.6132 102.116 52.4595 100.729 51.0737 98.9921C49.6878 97.2548 48.5985 95.8132 48.6377 95.7799C48.677 95.7466 49.8307 97.1291 51.2165 98.8664C52.6024 100.604 53.6846 102.045 53.6525 102.079Z"
          fill="white"
        />
        <path
          d="M4.79094 93.05C4.81594 93.1018 3.76228 93.6563 2.61217 94.5693C1.46207 95.4823 0.662002 96.3842 0.619141 96.3436C0.748976 96.1096 0.91281 95.8976 1.1049 95.7152C1.53267 95.2424 1.99869 94.8084 2.49788 94.4177C2.99494 94.0251 3.52304 93.6764 4.07659 93.3753C4.29816 93.2315 4.53881 93.122 4.79094 93.05Z"
          fill="white"
        />
        <path
          d="M7.73237 84.8722C7.50541 85.3952 7.22395 85.891 6.893 86.3508C6.61104 86.8459 6.27682 87.3071 5.89648 87.7259C6.12405 87.2032 6.40546 86.7074 6.73585 86.2473C7.01706 85.7516 7.35134 85.2904 7.73237 84.8722Z"
          fill="white"
        />
        <path
          d="M14.3667 92.9006C13.7949 91.9594 13.5504 90.8445 13.6738 89.7401C13.7274 89.7401 13.7453 90.4794 13.9417 91.3407C14.1382 92.2019 14.4167 92.8747 14.3667 92.9006Z"
          fill="white"
        />
        <path
          d="M15.3413 67.4249C14.8972 67.5853 14.4351 67.6859 13.9662 67.7243C13.505 67.8246 13.0329 67.8607 12.5625 67.8315C13.0079 67.6714 13.4712 67.5708 13.9412 67.5321C14.4015 67.434 14.8721 67.398 15.3413 67.4249Z"
          fill="white"
        />
        <path
          d="M20.3225 58.2849C19.5532 58.874 18.7127 59.3562 17.8223 59.7191C18.1878 59.3983 18.5925 59.1286 19.0259 58.917C19.4305 58.6503 19.8662 58.438 20.3225 58.2849Z"
          fill="white"
        />
        <path
          d="M34.33 62.1463C33.8253 61.6151 33.3642 61.0415 32.9513 60.4312C32.4931 59.8579 32.0821 59.2458 31.7227 58.6014C32.6891 59.7029 33.5614 60.889 34.33 62.1463Z"
          fill="white"
        />
        <path
          d="M52.1043 71.3359C51.1198 70.3318 50.3792 69.1005 49.9434 67.743C49.9969 67.7208 50.3791 68.5821 50.9792 69.569C51.5792 70.556 52.1471 71.299 52.1043 71.3359Z"
          fill="white"
        />
        <path
          d="M58.8891 73.6497C58.4581 74.9492 57.9079 76.2031 57.2461 77.3942C57.4365 76.7354 57.684 76.0958 57.9854 75.4831C58.2299 74.8437 58.5324 74.2298 58.8891 73.6497Z"
          fill="white"
        />
        <path
          d="M59.8412 64.0652C59.1919 63.4128 58.5879 62.7136 58.0339 61.973C57.4319 61.2739 56.877 60.5329 56.373 59.7551C57.6229 61.1082 58.7816 62.5482 59.8412 64.0652Z"
          fill="white"
        />
        <path
          d="M49.4769 56.2807C49.3638 55.6916 49.3099 55.0921 49.3162 54.4916C49.2624 53.8923 49.2707 53.2888 49.3412 52.6914C49.4544 53.2804 49.5083 53.88 49.5019 54.4805C49.558 55.0797 49.5496 55.6834 49.4769 56.2807Z"
          fill="white"
        />
        <path
          d="M57.942 55.7963C57.218 55.9927 56.4798 56.1274 55.7346 56.1992C54.9986 56.333 54.2531 56.4035 53.5059 56.4099C54.2298 56.2135 54.968 56.0787 55.7132 56.007C56.4492 55.873 57.1947 55.8025 57.942 55.7963Z"
          fill="white"
        />
        <path
          d="M71.234 65.9133C70.738 65.0709 70.2942 64.1968 69.9053 63.2962C69.4637 62.4223 69.0771 61.5197 68.748 60.5941C69.2461 61.4359 69.6911 62.3101 70.0803 63.2112C70.5204 64.0854 70.9058 64.9879 71.234 65.9133Z"
          fill="white"
        />
        <path
          d="M69.0098 74.6542C69.1592 73.2424 69.4294 71.8471 69.817 70.4846C69.7738 71.1939 69.6698 71.8977 69.5062 72.5879C69.402 73.2909 69.2358 73.9826 69.0098 74.6542Z"
          fill="white"
        />
        <path
          d="M75.1666 81.5445C74.7501 80.6233 74.3922 79.675 74.0951 78.7056C73.741 77.7577 73.4464 76.7872 73.2129 75.8002C73.627 76.7237 73.9848 77.673 74.2844 78.6427C74.637 79.5899 74.9317 80.559 75.1666 81.5445Z"
          fill="white"
        />
        <path
          d="M74.3054 85.5779C73.6678 85.4786 73.0551 85.2513 72.5017 84.9089C71.9206 84.62 71.4018 84.2127 70.9766 83.7112C71.0123 83.6669 71.6695 84.2287 72.591 84.7388C73.5125 85.2489 74.3233 85.5225 74.3054 85.5779Z"
          fill="white"
        />
        <path
          d="M35.5146 107.587C35.3623 107.053 35.2689 106.504 35.236 105.949C35.1456 105.401 35.1144 104.845 35.1432 104.289C35.3063 104.826 35.4058 105.381 35.4396 105.942C35.5252 106.485 35.5503 107.037 35.5146 107.587Z"
          fill="white"
        />
        <path
          d="M59.7734 106.951C59.7898 106.41 59.8678 105.873 60.0056 105.35C60.0861 104.815 60.2263 104.292 60.4235 103.791C60.4071 104.332 60.3279 104.869 60.1878 105.391C60.1094 105.926 59.9704 106.45 59.7734 106.951Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.8096 63.6367L62.9085 68.4002L62.8688 68.4774L62.8886 68.4724C63.9247 72.5415 65.9215 81.5316 65.6205 84.9383C65.4914 86.3993 65.0339 88.0899 64.5679 89.8124C63.6754 93.1107 62.7514 96.5256 64.0406 98.6681C65.411 100.945 66.3313 103.694 66.6047 105.309C68.4249 107.182 70.3841 108.923 71.4542 109.016C73.8104 109.223 77.3359 107.878 79.1474 106.118C81.4117 103.919 81.4789 92.4332 79.8851 86.6656C79.6205 85.7132 76.8995 77.5408 74.5111 70.3674C73.0043 65.8415 71.6298 61.7133 71.0881 60.0467L70.8345 59.2732L61.8096 63.6367Z"
          fill="#FFBE9D"
        />
        <path
          d="M62.4431 65.5636C62.4474 65.6874 62.4644 65.8104 62.4939 65.9307C62.5471 66.194 62.6116 66.542 62.6971 66.9839C62.8835 67.9241 63.1537 69.2261 63.4779 70.8338C63.8022 72.4414 64.1956 74.397 64.5513 76.5612C64.9382 78.8993 65.1744 81.26 65.2582 83.6284C65.3329 86.0021 65.1183 88.3759 64.6194 90.6977C64.1527 92.8285 63.7247 94.793 63.8051 96.477C63.8273 97.7999 64.195 99.0939 64.8718 100.231C65.1149 100.65 65.4339 101.021 65.8126 101.324C64.5813 100.005 63.9222 98.2528 63.9792 96.4495C63.9137 94.7982 64.3706 92.8613 64.8372 90.7258C65.3489 88.388 65.571 85.9962 65.4985 83.6041C65.4171 81.2205 65.1727 78.8452 64.767 76.4949C64.4113 74.326 64.0041 72.3897 63.6512 70.7685C63.2983 69.1473 63.0002 67.8649 62.7951 66.9345C62.6908 66.4977 62.6076 66.1548 62.5497 65.8917C62.5203 65.7804 62.4847 65.6709 62.4431 65.5636Z"
          fill="#EB996E"
        />
        <path
          d="M52.7949 55.922L54.5161 53.9283L54.4638 51.6062L56.2358 48.9313L59.902 49.4343L61.0227 49.5035L60.1473 44.1778C59.6976 42.8205 59.1177 41.51 58.4158 40.2643C57.0545 37.6884 59.1232 36.4471 59.1232 36.4471L63.1949 43.2119L64.6001 47.7371L65.0734 44.0102C65.0734 44.0102 64.3312 40.4097 64.3837 38.548C64.4363 36.6863 66.3066 36.9134 66.3066 36.9134L67.9822 45.0591L68.0744 49.1511L69.3179 46.7054C69.3179 46.7054 69.5502 44.6507 69.7019 42.7868C69.8535 40.9228 71.3433 41.4465 71.3433 41.4465L71.4795 47.4925L69.9343 53.1089C69.9343 53.1089 71.1961 60.2817 71.3864 60.556L62.9182 66.7816C62.9182 66.7816 60.9984 65.4082 60.6328 65.3173C60.2671 65.2263 55.5313 61.8953 55.5313 61.8953L52.202 58.5278C52.202 58.5278 51.6367 58.1722 51.6283 57.7994C51.6199 57.4265 52.7949 55.922 52.7949 55.922ZM57.3054 58.054L59.146 57.172L60.1137 54.638L59.3229 53.2817L59.0479 52.8157L58.7028 52.5874L57.4668 52.8513C57.4668 52.8513 56.9497 54.6337 57.0535 55.4672C57.1573 56.3007 56.067 57.1658 56.067 57.1658L57.3054 58.054Z"
          fill="#FFBE9D"
        />
        <path
          d="M58.7048 52.5736C58.7054 52.6019 58.8758 52.617 59.129 52.531C59.4596 52.4084 59.7494 52.1957 59.9654 51.917C60.1857 51.6436 60.3225 51.3125 60.3596 50.9634C60.382 50.6983 60.3499 50.5337 60.3028 50.5395C60.2359 50.9886 60.0535 51.4127 59.7733 51.7701C59.4932 52.1275 59.125 52.406 58.7049 52.5783L58.7048 52.5736Z"
          fill="#EB996E"
        />
        <path
          d="M54.0258 57.4994C54.0399 56.5935 53.9784 55.6879 53.842 54.7922C53.7088 55.2352 53.6758 55.7022 53.7452 56.1595C53.7348 56.6218 53.8308 57.0802 54.0258 57.4994Z"
          fill="#EB996E"
        />
        <path
          d="M56.9771 60.9511C56.9771 60.9511 57.2097 60.7948 57.4659 60.4301C57.8176 59.9427 57.9727 59.3406 57.9001 58.744C57.8681 58.5616 57.7857 58.3917 57.6624 58.2535C57.5512 58.138 57.4355 58.032 57.3247 57.9353C57.1283 57.7714 56.9231 57.6184 56.71 57.4769C56.3455 57.2301 56.0881 57.1226 56.0701 57.1608C56.2373 57.3264 56.4159 57.4802 56.6046 57.621C56.773 57.7588 56.9656 57.9245 57.173 58.1182C57.2744 58.215 57.3806 58.3212 57.4823 58.4323C57.5738 58.5389 57.6354 58.6679 57.6607 58.806C57.7049 59.0962 57.6809 59.3926 57.5905 59.6718C57.5259 59.9101 57.431 60.1391 57.3084 60.3534C57.1085 60.7073 56.9435 60.9283 56.9771 60.9511Z"
          fill="#EB996E"
        />
        <path
          d="M54.5064 53.6922C54.4898 54.3856 54.7249 55.0615 55.168 55.5951C55.3368 55.8186 55.5583 55.997 55.8127 56.1143C56.0306 56.2022 56.2709 56.2183 56.4986 56.1603C56.8977 56.0521 56.9954 55.7759 56.9623 55.7719C56.9291 55.768 56.7914 55.9458 56.4618 55.9911C56.2709 56.0183 56.0762 55.9881 55.9024 55.9045C55.6879 55.7952 55.5017 55.6377 55.3583 55.4443C55.007 54.8955 54.7211 54.3074 54.5064 53.6922Z"
          fill="#EB996E"
        />
        <path
          d="M57.0507 55.3203C57.1073 55.319 57.2257 54.4947 57.4815 53.4832C57.5799 53.0455 57.652 52.6024 57.6972 52.1561C57.7225 51.8108 57.6845 51.5944 57.6845 51.5944C57.6845 51.5944 57.6136 51.7991 57.5455 52.1312C57.4476 52.6056 57.3573 52.9996 57.2631 53.4314C56.9838 54.4482 56.9704 55.3221 57.0507 55.3203Z"
          fill="#EB996E"
        />
        <path
          d="M54.039 58.0577C54.7929 57.9814 55.5134 57.7077 56.1279 57.2643C56.3852 57.1049 56.6138 56.9034 56.8041 56.6681C56.9533 56.4661 57.0547 56.2328 57.1005 55.9859C57.1659 55.7658 57.1488 55.5294 57.0525 55.321C57.01 55.3219 57.0582 55.5759 56.9343 55.9472C56.7764 56.4179 56.4484 56.8127 56.0145 57.0543C54.9858 57.7293 54.0136 57.978 54.039 58.0577Z"
          fill="#EB996E"
        />
        <path
          d="M61.7517 59.9515C62.2926 58.9991 62.9662 58.1285 63.7523 57.3659C64.5982 56.6638 65.539 56.0846 66.5469 55.6455C66.2201 55.6749 65.9018 55.7653 65.6085 55.9122C64.1318 56.5358 62.9076 57.6378 62.1326 59.041C61.9482 59.3171 61.8188 59.6263 61.7517 59.9515Z"
          fill="#EB996E"
        />
        <path
          d="M64.6705 51.2022C64.6721 51.273 65.3533 51.3144 66.148 51.5752C66.9427 51.836 67.5222 52.1866 67.5775 52.1287C67.1954 51.7676 66.7371 51.4969 66.2364 51.3365C65.7358 51.1761 65.2055 51.1301 64.6847 51.2019L64.6705 51.2022Z"
          fill="#EB996E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1428_22813">
          <path
            d="M0.625 0H79.8279V64.9589C79.8279 86.8301 62.0977 104.56 40.2264 104.56C18.3552 104.56 0.625 86.8301 0.625 64.9589V0Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ContactDetailsAvatarSVG;
