import { SET_CONTACTS } from "./types";

export const setContacts = (params) => {
  return {
    type: SET_CONTACTS,
    payload: params,
  };
};

const userActions = {
  setContacts,
};

export default userActions;
