import React from "react";
import "assets/css/components/campaign-tests.css";
import SplitTestingSetting from "./split-testing-setting";
import TestEndCondition from "./test-end-condition";

export const ABSetting = ({ data = {} }) => {
  return (
    <div className="flex flex-row items-center justify-between space-x-4 p-5">
      <SplitTestingSetting data={data} />
      <TestEndCondition data={data} />
    </div>
  );
};

export default ABSetting;
