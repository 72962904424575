import DashboardClickFileIconSVG from "assets/svg/dashboard-click-file-icon";
import DashboardEyeIconSVG from "assets/svg/dashboard-eye-icon";
import DashboardInteractionIconSVG from "assets/svg/dashboard-interaction-icon";
import CustomPlanModal from "components/account/custom-plan-modal";
import AlertBanner from "components/alerts/banner";
import PerformingPopouts from "components/campaigns/details/statistics/performing-popouts";
import TopEvents from "components/campaigns/details/statistics/top-events";
import Card from "components/card/card";
import LeadGenerated from "components/dashboard/lead-generated";
import StatisticsDashboard from "components/dashboard/statistics-dashboard/statistics-dashboard";
import WelcomeCard from "components/dashboard/welcome-card";
import { FilterSelector } from "components/global/filterSelector";
import Select from "components/select/select";
import { Context } from "context/context";
import { usePusherEvent } from "context/pusher";
import Layout from "layouts/layout";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useNotifications } from "redux/modules/notification/hooks";
import { getDays, getMonths, getWeeks } from "utils/constant";
import { useDialogHook } from "utils/customhooks";
import { sendCheckoutSuccessful } from "utils/gtm-triggers";

const filters = [
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

const Dashboard = ({ userData }) => {
  const { http } = global.services;
  const { setNotificationBanner, banner } = useNotifications();
  const [custom, setCustomPlan] = useState({ isCustom: false, data: {} });
  const { currentPlan, setCurrentPlan } = useContext(Context);
  const globalContext = useContext(Context);
  const [rawPopups, setRawPopups] = useState([]);
  const [popupIds, setPopupIds] = useState([]);
  const [filterDates, setFilterDates] = useState({
    start: moment().unix(),
    end: moment().unix(),
  });
  const [dateOptions, setDateOptions] = useState([]);
  const customPlanModal = useDialogHook(CustomPlanModal); //modal
  const [init, setInit] = useState(false);
  const [metrics, setMetrics] = useState([
    {
      title: "Visitors",
      total: 0,
      previous: 0,
      icon: <DashboardEyeIconSVG />,
    },
    {
      title: "Opens",
      total: 0,
      previous: 0,
      icon: <DashboardClickFileIconSVG />,
    },
    {
      title: "Interactions",
      total: 0,
      previous: 0,
      icon: <DashboardInteractionIconSVG />,
    },
  ]);
  const [rawInteractions, setRawInteractions] = useState();

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState({
    opens: [],
    interactions: [],
    impressions: [],
  });

  const [filter, setFilter] = useState("weekly");
  const [filterValue, setFilterValue] = useState("");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("sucessful_payment") === "true") {
      sendCheckoutSuccessful();
      localStorage.removeItem("trialCode");
      localStorage.removeItem("trialDays");
      // Now strip the URL parameters
      history.replace(location.pathname);
    }
  }, [location, history]);

  const fetchLatestBanner = async () => {
    try {
      const { data: response } = await http.get("notifications/banner");
      if (response.data) {
        setNotificationBanner(response.data);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const getBarGraphData = (labels = [], reports = {}) => {
    setLabels(labels);
    let opens = [];
    let interactions = [];
    let impressions = [];
    labels.forEach((value) => {
      let payload = {
        opens: 0,
        interactions: 0,
        impressions: 0,
      };
      Object.entries(reports).forEach(([date, data]) => {
        let rec = moment(new Date(date)).format("MM/DD");
        if (value === rec) {
          payload.opens = data?.clicks;
          payload.interactions = data?.interactions;
          payload.impressions = data?.opens;
        }
      });
      opens.push(payload.opens);
      interactions.push(payload.interactions);
      impressions.push(payload.impressions);
    });
    setDatasets({
      opens: opens,
      interactions: interactions,
      impressions: impressions,
    });
  };

  const fetchDashboardMetrics = async () => {
    try {
      const params = {
        start_date: filterDates.start,
        end_date: filterDates.end,
      };

      const { data: response } = await http.get("dashboard/metrics", params);
      if (response.data) {
        if (!response.popupExist) {
          globalContext.setAlerts([
            {
              message:
                "We’re happy you’re finally here! Create your first popout now",
              data: null,
              button: {
                name: "Get started",
                link: "/campaigns/popup",
              },
              type: "first-timer",
            },
          ]);
        } else if (userData.user.status === "suspended") {
          globalContext.setAlerts([
            {
              message: "Important: Your account is currently suspended.",
              data: null,
              button: {
                name: "Please contact support here.",
                link: "https://support.conversioncow.com/hc/en-us",
                target: "_blank",
              },
              type: "suspended-account",
            },
          ]);
        } else if (userData.user.status === "failed_pm") {
          globalContext.setAlerts([
            {
              message:
                "Important: Your subscription is suspended as your last payment failed.",
              data: null,
              insideButton: {
                name: "Update your payment details",
                link: "/dashboard/account/detail",
                afterButtonText: " to reactivate",
              },
              type: "no-subscription",
            },
          ]);
        }

        setMetrics((prev) =>
          prev.map((metric) => {
            if (metric.title === "Opens") {
              return {
                ...metric,
                total: response.data.unique_clicks,
              };
            }

            if (metric.title === "Visitors") {
              return {
                ...metric,
                total: response.data.unique_opens,
              };
            }

            if (metric.title === "Interactions") {
              return {
                ...metric,
                total: response.data.unique_interactions,
              };
            }

            return metric;
          })
        );
      }

      //bar graph
      const labels = getDays(
        moment.unix(filterDates.start),
        moment.unix(filterDates.end)
      );

      if (!_.isEmpty(response.reports)) {
        getBarGraphData(labels, response.reports);
      } else {
        const values = labels.map(() => {
          return 0;
        });
        setLabels(labels);
        setDatasets({
          opens: values,
          interactions: values,
          impressions: values,
        });
      }

      //end bar graph
      if (!_.isEmpty(response.popups)) {
        setRawPopups(response.popups);
        setPopupIds(response.popup_ids);
      } else {
        setRawPopups([]);
        setPopupIds([]);
      }

      if (!_.isEmpty(response.interactions)) {
        setRawInteractions(response.interactions);
      } else {
        setRawInteractions(undefined);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const checkCustomSubscriptions = async () => {
    try {
      const { data: response } = await http.get("custom-subscriptions/request");
      //update here
      if (response?.data && userData.user.email_verified_at) {
        setCustomPlan({ isCustom: true, data: response?.data });
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const setUpdateToCustomPlan = async () => {
    try {
      const result = await http.get(`subscriptions/${userData.user.id}`);
      const data = result.data.data;

      let payload = _.clone(data);
      let trialing = data.stripe_status === "trialing";
      payload.name = trialing ? `${data.name} (Free Trial)` : data.name;
      payload.stripe_product = data.items[0].stripe_product;
      setCurrentPlan(payload);
      setCustomPlan({ isCustom: false, data: {} });
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    }
  };

  const handleShowDetails = (data) => {
    customPlanModal({ data }, (result) => {
      if (result) {
        setUpdateToCustomPlan();
      }
    });
  };

  usePusherEvent(
    "private-client-notifications",
    "banner-notification-created-event",
    (data) => setNotificationBanner(data)
  );

  const loadingPlan = () => {
    let result = false;
    if (userData?.user?.role !== "admin") {
      if (_.isEmpty(currentPlan)) {
        result = true;
      } else {
        result = false;
      }
    }
    return result;
  };

  useEffect(
    () => {
      fetchLatestBanner();
      checkCustomSubscriptions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // here we will updates the results based on the times selected
  useEffect(() => {
    globalContext.setAlerts([]);
    if (init) fetchDashboardMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDates]);

  useEffect(() => {
    let options = [];
    if (filter === "weekly") {
      options = getWeeks(userData?.user?.created_at);
      setDateOptions(options);
      setFilterValue(options[0].value);
      setFilterDates({
        start: options[0].dates.start,
        end: options[0].dates.end,
      });
    } else {
      options = getMonths(userData?.user?.created_at);
      setDateOptions(options);
      setFilterValue(options[0].value);
      setFilterDates({
        start: options[0].dates.start,
        end: options[0].dates.end,
      });
    }
    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    const calculate_popups = async () => {
      let globalPopups = popupIds.map((item) =>
        rawPopups.filter((popup) => popup.popup.id === item)
      );
      var objectsLen = 0;
      let totals = globalPopups.map((item) => {
        objectsLen = 0;
        for (let index = 0; index < item.length; index++) {
          objectsLen = objectsLen + item[index].report.opens;
        }
        return { popup: item[0].popup, total: objectsLen };
      });
      totals.sort(function (a, b) {
        return a.report?.total || a.total - b.report?.total || b.total;
      });
    };
    if (rawPopups.length > 0 && popupIds.length > 0) {
      calculate_popups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupIds, rawPopups]);

  return (
    <Layout title="Dashboard" routeName="dashboard">
      <div className="flex flex-col flex-1 w-full">
        <div className="flex flex-wrap -mx-px overflow-hidden">
          <div className="w-full px-px my-px overflow-hidden">
            <div className="flex flex-wrap 2md:flex-nowrap flex-auto -mx-2 overflow-hidden">
              <div className="w-full px-2 my-2 overflow-hidden">
                <WelcomeCard
                  currentPlan={currentPlan?.plan_name}
                  planStatus={currentPlan?.stripe_status}
                  planExpiry={
                    currentPlan?.trial_end || currentPlan?.current_period_end
                  }
                  isCustomPlan={custom.isCustom}
                  onClick={() => handleShowDetails(custom.data)}
                  loading={loadingPlan()}
                  admin={userData?.user?.role === "admin"}
                />
              </div>
              {banner && (
                <div className="w-full px-2 my-2 overflow-hidden">
                  <AlertBanner data={banner} />
                </div>
              )}
            </div>
          </div>
          {/* Performance Overview */}
          <Card
            padding="px-8 py-3"
            className="w-full border border-thBorder"
            height=""
          >
            <div className="w-full grid justify-center 7md:flex items-center 7md:justify-between">
              <div>
                <p className="text-2xl leading-9">Performance Overview</p>
              </div>
              <div className="w-1/2 2md:w-9/12">
                <div className="flex items-center space-x-5 flex-wrap justify-end">
                  <nav className="block 2md:bg-lightDarkGray 2md:rounded-full 2md:py-1 2md:px-1 relative">
                    {filters.map((obj, index) => {
                      return (
                        <FilterSelector
                          key={`filters-${index}`}
                          isActive={filter === obj.value}
                          onClick={() => setFilter(obj.value)}
                        >
                          {obj.label}
                        </FilterSelector>
                      );
                    })}
                  </nav>
                  <div className="w-72">
                    <Select
                      placeholder="Filter"
                      value={_.find(dateOptions, ["value", filterValue])?.label}
                      options={dateOptions}
                      onChange={({ value, dates }) => {
                        setFilterValue(value);
                        setFilterDates({
                          start: dates.start,
                          end: dates.end,
                        });
                      }}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
          {/* end: Performance Overview */}
          <div className="w-full px-px my-px overflow-hidden">
            <div className="my-2 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2">
              {metrics.map((metric, metricIndex) => (
                <StatisticsDashboard
                  key={`dashboard-metrics-${metricIndex}`}
                  icon={metric.icon}
                  total={metric.total}
                  title={metric.title}
                  message={_.find(dateOptions, ["value", filterValue])?.label}
                />
              ))}
            </div>
          </div>
          {/* Device Environment & Top Performing Popouts (Form Submissions) */}
          <Card
            padding=""
            className="w-full border border-thBorder mb-2"
            height=""
          >
            <div className="w-full grid 5md:grid-cols-2 divide-y 5md:divide-x 5md:divide-y-0 divide-thBorder">
              <div className="p-8">
                <p className="text-xl leading-7.5 tracking-normalwide">
                  Top Events
                </p>
                <div className="w-full mt-2">
                  <TopEvents dataSource={rawInteractions} />
                </div>
              </div>
              <div className="p-8">
                <p className="text-xl leading-7.5 tracking-normalwide">
                  Top Performing Popouts
                </p>
                <div className="w-full mt-2">
                  <PerformingPopouts dataSource={rawPopups} />
                </div>
              </div>
            </div>
          </Card>
          {/* end: Device Environment & Top Performing Popouts (Form Submissions) */}
          <div className="w-full">
            <LeadGenerated labels={labels} datasets={datasets} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

export default connect(mapStateToProps)(Dashboard);
