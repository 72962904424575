const MailIconSVG = ({ className = "", fill = "#808080" }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33268 3.33325H16.666C17.5827 3.33325 18.3327 4.08325 18.3327 4.99992V14.9999C18.3327 15.9166 17.5827 16.6666 16.666 16.6666H3.33268C2.41602 16.6666 1.66602 15.9166 1.66602 14.9999V4.99992C1.66602 4.08325 2.41602 3.33325 3.33268 3.33325Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M18.3327 5L9.99935 10.8333L1.66602 5"
      stroke={fill}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default MailIconSVG;
