const StarSVG = ({ className }) => {
  return (
    <svg
      className={`fill-current ${className ?? ""}`}
      viewBox="0 0 21 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.0245 0.463526C10.1741 0.00287056 10.8259 0.00286996 10.9755 0.463525L12.9697 6.60081C13.0366 6.80682 13.2286 6.9463 13.4452 6.9463H19.8983C20.3827 6.9463 20.5841 7.56611 20.1922 7.85081L14.9715 11.6439C14.7963 11.7712 14.7229 11.9969 14.7899 12.2029L16.784 18.3402C16.9337 18.8008 16.4064 19.1839 16.0146 18.8992L10.7939 15.1061C10.6186 14.9788 10.3814 14.9788 10.2061 15.1061L4.98542 18.8992C4.59356 19.1839 4.06632 18.8008 4.216 18.3402L6.21012 12.2029C6.27706 11.9969 6.20373 11.7712 6.02849 11.6439L0.8078 7.85081C0.415943 7.56611 0.61733 6.9463 1.10169 6.9463H7.55482C7.77143 6.9463 7.96341 6.80682 8.03035 6.60081L10.0245 0.463526Z" />
    </svg>
  );
};

export default StarSVG;
