const ImageIconSVG = ({ className }) => {
  return (
    <svg
      width="25"
      height="25"
      className={className ?? ""}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.82806 0C1.92316 0 2.01474 0 2.10984 0C2.41276 0.130324 2.52195 0.366317 2.50082 0.690366C2.4832 0.936925 2.49729 1.18701 2.49729 1.44413C2.59944 1.44413 2.67693 1.44413 2.7509 1.44413C7.13613 1.44413 11.5249 1.44766 15.9101 1.43709C16.3222 1.43709 16.5653 1.68013 16.5617 2.08871C16.5512 6.48099 16.5547 10.8733 16.5547 15.2655C16.5547 15.3395 16.5547 15.4135 16.5547 15.5015C16.8259 15.5015 17.076 15.5156 17.3261 15.498C17.6466 15.4769 17.8685 15.5966 17.9988 15.889C17.9988 15.9841 17.9988 16.0757 17.9988 16.1708C17.865 16.4737 17.6325 16.5829 17.3085 16.5617C17.0619 16.5441 16.8118 16.5582 16.5688 16.5582C16.5617 16.5934 16.5617 16.597 16.5617 16.604C16.5617 16.8893 16.5582 17.1781 16.5582 17.4634C16.5582 17.6748 16.4666 17.8368 16.28 17.9354C15.9348 18.1221 15.5156 17.8826 15.5086 17.4916C15.5015 17.1852 15.5086 16.8752 15.5086 16.5582C15.41 16.5582 15.3325 16.5582 15.2585 16.5582C10.8697 16.5582 6.48099 16.5547 2.09575 16.5653C1.69069 16.5653 1.44061 16.3258 1.44413 15.9101C1.4547 11.5178 1.45118 7.12204 1.45118 2.72976C1.45118 2.6558 1.45118 2.58183 1.45118 2.49729C1.14826 2.49729 0.86648 2.49729 0.58822 2.49729C0.23247 2.49377 0 2.28243 0 1.97247C0 1.65899 0.228948 1.44413 0.570609 1.44413C0.855913 1.44413 1.14122 1.44413 1.44061 1.44413C1.44061 1.17644 1.4547 0.922836 1.43709 0.672755C1.41595 0.352228 1.53571 0.130324 1.82806 0ZM2.50786 2.50434C2.50786 6.84378 2.50786 11.1691 2.50786 15.491C6.8473 15.491 11.1727 15.491 15.4945 15.491C15.4945 11.155 15.4945 6.83322 15.4945 2.50434C11.1621 2.50434 6.84026 2.50434 2.50786 2.50434Z"
        fill="currentColor"
      />
      <path
        d="M8.98879 13.7435C7.38263 13.7435 5.78 13.7435 4.17384 13.7435C3.74412 13.7435 3.54688 13.5498 3.54688 13.1271C3.54688 10.3727 3.54688 7.62179 3.54688 4.86737C3.54688 4.4447 3.7406 4.25098 4.17032 4.25098C7.38616 4.25098 10.602 4.25098 13.8178 4.25098C14.2546 4.25098 14.4448 4.44118 14.4448 4.88499C14.4448 7.62532 14.4448 10.3656 14.4448 13.1095C14.4448 13.5568 14.2546 13.7435 13.8037 13.7435C12.2011 13.7435 10.5949 13.7435 8.98879 13.7435ZM4.6106 11.2744C4.674 11.1722 4.71275 11.1124 4.74797 11.0525C5.46299 9.85139 6.17449 8.6503 6.88951 7.44568C6.91065 7.41046 6.93178 7.37524 6.95292 7.34001C7.19243 6.96665 7.65385 6.96313 7.8828 7.34353C8.36183 8.13957 8.83381 8.94265 9.30932 9.7422C9.38681 9.87253 9.4643 9.99933 9.55236 10.1508C9.59815 10.0803 9.63337 10.0275 9.66859 9.97468C9.97855 9.47099 10.2885 8.9673 10.5985 8.46714C10.8873 7.99868 11.3276 7.99516 11.6164 8.46362C12.1624 9.35123 12.7083 10.2388 13.2543 11.1229C13.2895 11.1793 13.3282 11.2356 13.3846 11.3237C13.3846 9.2984 13.3846 7.31536 13.3846 5.32175C10.4576 5.32175 7.53761 5.32175 4.6106 5.32175C4.6106 7.2907 4.6106 9.25613 4.6106 11.2744ZM9.82357 12.6833C9.01697 11.3237 8.22446 9.99229 7.41786 8.63269C6.60773 9.99933 5.81522 11.3308 5.01214 12.6833C6.62534 12.6833 8.20685 12.6833 9.82357 12.6833ZM12.9725 12.6903C12.342 11.6654 11.7326 10.6756 11.1057 9.66119C10.7922 10.1684 10.4963 10.6439 10.2075 11.1229C10.1864 11.1582 10.1969 11.2357 10.2216 11.2779C10.4787 11.7217 10.7429 12.1655 11.0071 12.6058C11.0317 12.6446 11.0951 12.6868 11.1409 12.6868C11.7432 12.6939 12.3385 12.6903 12.9725 12.6903Z"
        fill="currentColor"
      />
      <path
        d="M9.70416 7.41791C9.40477 7.41791 9.1723 7.18544 9.17582 6.88604C9.17934 6.59722 9.40829 6.36827 9.70063 6.36475C10 6.36475 10.2325 6.59722 10.229 6.89661C10.2254 7.18544 9.9965 7.41439 9.70416 7.41791Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ImageIconSVG;
