import { INITIAL_BILLING_PERIOD } from "configs/api";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import PricingTable from "../component/pricing-table";

export default function UpgradePlan({
  pricing = [],
  loading = false,
  userPlan = {},
}) {
  const [billing, setBilling] = useState(INITIAL_BILLING_PERIOD);
  const [currentPlan, setCurrentPlan] = useState(null);

  const currency = "aud";

  const [plans, setPlans] = useState([]);
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const isSelected = (obj) => {
    return (
      currentPlan?.user_status !== "suspended" &&
      currentPlan?.price_id === obj?.price.id
    );
  };

  const handleChoose = async (obj) => {
    if (isSelected(obj)) {
      return;
    }

    setIsLoadingPortal(true);

    try {
      const resp = await global.services.http.post("subscriptions", {
        price: obj.price.id,
        account_page: true,
      });

      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      window.location.href = resp.data.url;
    } catch (e) {
      console.error(e);
      toast.error("Failed to open customer portal. Please contact support.");
      setIsLoadingPortal(false);
    }
  };

  const btnName = (currentPlan, obj) => {
    if (!currentPlan || currentPlan?.user_status === "suspended") {
      return "Sign Up";
    }
    if (currentPlan?.price_id === obj?.price?.id) {
      return "Current";
    }
    return "Change";
  };

  useEffect(() => {
    if (!_.isEmpty(pricing)) {
      let payload = pricing
        .map((obj) => {
          const price = obj.prices.find(
            (p) => p.currency === currency && p.recurring.interval === billing
          );
          return { ...obj, price };
        })
        .filter((obj) => !!obj.price)
        .sort((a, b) => a.price.unit_amount - b.price.unit_amount);
      setPlans(payload);
    }
  }, [pricing, billing, currency]);

  useEffect(() => {
    if (!_.isEmpty(userPlan)) {
      setCurrentPlan(userPlan);
      // Prevent redundant user data loads interfering with user selections.
      if (!hasInteracted) {
        setBilling(userPlan.recurring.interval);
      }
    }
  }, [userPlan, hasInteracted]);

  return (
    <div className="w-full">
      {/* header */}
      <div className="w-full mt-5">
        <div>
          <p className="plan-prices text-center">Plans & Prices</p>
          <p className="text-center">
            Choose the best fitting plan and start getting conversions today!
          </p>
          <div className="mt-7 w-full md:w-567 md:mx-auto p-1 grid grid-cols-2 gap-1 rounded-full bg-billing">
            <button
              className={`px-4 py-4 text-sm text-center font-bold rounded-full ${
                billing === "month" ? "bg-white text-black" : "text-textGray"
              }`}
              onClick={() => {
                setBilling("month");
                setHasInteracted(true);
              }}
            >
              MONTHLY
            </button>
            <button
              className={`px-4 py-4 text-sm text-center font-bold rounded-full ${
                billing === "year" ? "bg-white text-black" : "text-textGray"
              }`}
              onClick={() => {
                setBilling("year");
                setHasInteracted(true);
              }}
            >
              ANNUAL
              <span className="pl-2.5 text-secondary">SAVE 20%</span>
            </button>
          </div>
        </div>
      </div>
      {/* prices */}
      <div className="w-full mt-8">
        <div className="mb-3.5 flex justify-end">
          <div className="toggle-container-billing w-16 h-8 rounded-full bg-white font-bold relative text-sm text-center leading-8">
            $ AUD
          </div>
        </div>
        {loading ? (
          <Skeleton width={"100%"} height={350} />
        ) : (
          <div
            className={`grid 2md:grid-cols-2 lg:grid-cols-3 gap-5 ${
              isLoadingPortal
                ? " opacity-50 animate-pulse pointer-events-none"
                : ""
            }`}
          >
            {plans.map((obj, index) => {
              return (
                <PricingTable
                  onClick={() => {
                    handleChoose(obj);
                  }}
                  key={index}
                  title={obj.name}
                  currency={obj.price.currency || ""}
                  interval={obj.interval}
                  subTitle={obj.description}
                  price={obj.price.unit_amount / 100}
                  buttonName={btnName(currentPlan, obj)}
                  selected={isSelected(obj)}
                  type={billing}
                  list={obj.features?.map((x) => x.name)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
