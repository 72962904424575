import React from "react";
import _ from "lodash";
import LoadingIndicator from "assets/svg/loading-indicator";
import Pagination from "react-responsive-pagination";
import SortIconSVG from "assets/svg/sort-icon";

const thClass =
  "px-5 py-5 border-b border-t border-thBorder bg-thBg font-normal text-sm text-black text-opacity-70 ";
const tdClass = "px-5 py-6 border-b border-thBorder bg-white text-sm";

const emptyFunc = () => {
  return null;
};

export default function ContactTable({
  columns = [],
  dataSource = [],
  noData,
  metadata = {},
  onPageChange = () => {},
  loading,
  onHandleChangeSorting = emptyFunc,
  onClickRow = () => {},
}) {
  const handleSort = (enable, key) => {
    if (!enable) {
      onHandleChangeSorting(key);
    }
  };

  if (_.isEmpty(dataSource) && !loading) {
    return noData;
  } else {
    return (
      <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
        <div className="inline-block min-w-full shadow max-w-full overflow-x-auto">
          <table
            className="min-w-full leading-normal overflow-x-auto"
            style={{
              maxWidth: "1425px",
            }}
          >
            <thead>
              <tr>
                {(columns || []).map((value) => {
                  let {
                    title,
                    key,
                    width = "0px",
                    align = "left",
                    hideSorting = true,
                    thNowrap = false,
                  } = value;
                  return (
                    <th
                      key={key}
                      className={thClass + `text-${align}`}
                      width={width}
                    >
                      <div
                        className={`flex flex-row items-center space-x-2 cursor-pointer  ${
                          thNowrap && "whitespace-nowrap"
                        }`}
                        onClick={() => handleSort(hideSorting, key)}
                      >
                        <p
                          className={`text-sm  ${
                            thNowrap && "whitespace-nowrap"
                          }`}
                        >
                          {title}
                        </p>
                        {!hideSorting && (
                          <SortIconSVG className="" fill="gray" />
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td
                    className={tdClass + `text-center`}
                    colSpan={_.size(columns)}
                    align="center"
                  >
                    <LoadingIndicator className="w-7 h-7 text-secondary" />
                  </td>
                </tr>
              )}
              {!loading &&
                dataSource.map((record, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => onClickRow(record)}
                      className="cursor-pointer"
                    >
                      {(columns || []).map((column) => {
                        let {
                          key,
                          align = "center",
                          dataIndex,
                          render,
                          tdNowrap = false,
                        } = column;
                        if (render) {
                          return (
                            <td
                              key={key}
                              className={
                                tdClass +
                                `text-${align} ${
                                  tdNowrap && "whitespace-nowrap"
                                }`
                              }
                            >
                              {render(record)}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={key}
                              className={
                                tdClass +
                                `text-${align} ${
                                  tdNowrap && "whitespace-nowrap"
                                }`
                              }
                            >
                              <p
                                className={`text-sm text-gray-800 ${
                                  tdNowrap && "whitespace-nowrap"
                                }`}
                              >
                                {record[dataIndex]}
                              </p>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="relative mt-5 w-full flex justify-end">
          <div className="relative w-1/2 flex justify-end">
            {!_.isEmpty(dataSource) && (
              <Pagination
                total={metadata.lastPage}
                current={metadata.currentPage}
                onPageChange={(page) => onPageChange(page)}
                previousLabel="Previous"
                nextLabel="Next"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
