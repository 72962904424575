import React, { useState, useEffect, useRef } from "react";
import { useDialogHook } from "utils/customhooks";
import ConfirmModal from "components/confirm/confirm";
import PopoutContent from "./popout-content";
import toast from "react-hot-toast";
import _ from "lodash";

export default function PopoutList({
  dataSource = [],
  setCurrent,
  noData,
  setDefaultTab,
  setLoading,
  setState,
  deleteCallBack = () => {},
}) {
  const { http } = global.services;
  const confirm = useDialogHook(ConfirmModal);
  const [show, setShow] = useState({});

  const arrayRefs = useRef([]);
  arrayRefs.current = [];

  const handleConfirmDelete = (record) => {
    confirm(
      {
        title: "Delete Popout",
        buttonText: "YES, DELETE IT",
        description: "Do you want to delete this popout?",
      },
      (result) => {
        if (result) {
          handleDeletePopup(record);
        }
      }
    );
  };

  const handleDuplicatePopout = async (record) => {
    setLoading(true);
    if (record.id) {
      try {
        const result = await http.post(`popups/duplicate/${record.id}`);
        const data = result.data.data;
        const newData = [data, ...dataSource];
        setCurrent(newData);
        setState((prev) => ({
          ...prev,
          meta: { ...prev.meta, total: prev.meta?.total + 1 },
        }));
        toast.success("Popout successfully duplicated");
      } catch (error) {
        if (error.status === 422) {
          toast.error("It appears that something went wrong");
        } else {
          toast.error(error?.error?.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeletePopup = async (obj) => {
    if (obj) {
      try {
        await http.delete(`popups/${obj.id}`);
        setState((prev) => ({
          ...prev,
          meta: { ...prev.meta, total: prev.meta?.total - 1 },
        }));
        toast.success("Popout successfully deleted");
        let payload = _.clone(dataSource);
        _.remove(payload, function (n) {
          return n.id === obj.id;
        });
        setCurrent(payload);
        deleteCallBack();
      } catch (error) {
        toast.error("It appears that something went wrong");
      }
    }
  };

  const addToRef = (el) => {
    if (el && !arrayRefs.current.includes(el)) {
      arrayRefs.current.push(el);
    }
  };

  // outside clicks
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * set close if clicked on outside of element
       */
      function handleClickOutside(event) {
        let result = false;
        if (ref.current) {
          _.forEach(ref.current, function (value) {
            if (value.contains(event.target)) {
              result = true;
            }
          });
        }
        if (!result) {
          setShow({});
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(arrayRefs);

  if (_.isEmpty(dataSource)) {
    return noData;
  } else {
    return (
      <div className="w-full grid md:grid-cols-2 2md:grid-cols-3 gap-5 mt-3">
        {dataSource.map((record, index) => (
          <PopoutContent
            record={record}
            setDefaultTab={setDefaultTab}
            show={show}
            setShow={setShow}
            handleConfirmDelete={handleConfirmDelete}
            handleDuplicatePopout={handleDuplicatePopout}
            ref={addToRef}
            key={index}
          />
        ))}
      </div>
    );
  }
}
