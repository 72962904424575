import PasswordDottedIconSVG from "assets/svg/password-dotted-svg";
import Button from "components/button/button";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import userActions from "redux/modules/user/actions";
import { useDialogHook } from "utils/customhooks";
import { sendCheckoutSuccessful } from "utils/gtm-triggers";
import EditAccountModal from "../edit-account-modal";

const Details = ({ userData, setUserData, loading, currentPlan, toggle }) => {
  const modal = useDialogHook(EditAccountModal);
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { http } = global.services;

  const handleEdit = (title, field) => {
    modal({ userData, title, field, setUserData });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("sucessful_payment") === "true") {
      if (
        userData.user.event_data &&
        !userData.user.event_data.includes("checkoutSuccess")
      ) {
        http
          .put("ga4-events", {
            event_data: [...userData.user.event_data, "checkoutSuccess"],
          })
          .then((response) => {
            sendCheckoutSuccessful();
            setUserData({ ...userData, user: response.data.data });
          });
      }
      // Now strip the URL parameters
      history.replace(location.pathname);
    }
  }, [location, history, http, userData, setUserData]);
  /*
  const handleCancel = async () => {
    setIsLoadingPortal(true);

    try {
      const resp = await global.services.http.post("subscriptions/cancel");

      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      window.location.href = resp.data.url;
    } catch (e) {
      console.error(e);
      toast.error("Cancelling subscription failed. Please contact support.");
      setIsLoadingPortal(false);
    }
  };
  */

  const openPortal = async () => {
    setIsLoadingPortal(true);

    try {
      const resp = await global.services.http.post("subscriptions/manage");

      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      window.location.href = resp.data.url;
    } catch (e) {
      console.error(e);
      toast.error("Failed to open customer portal. Please contact support.");
      setIsLoadingPortal(false);
    }
  };

  return (
    <div className="account-table-wrapper">
      <div className="account-table-container-header">
        <div className="row-span-2">
          <p className="text-xl">Current Plan</p>
        </div>
        <div className="account-table-header-content">
          {loading ? (
            <Skeleton width={"100%"} height={130} />
          ) : _.isEmpty(currentPlan) || currentPlan.user_status !== "active" ? (
            <>
              <p className="text-3xl pb-2">No Active Subscription</p>
            </>
          ) : (
            <>
              <p className="text-3xl pb-2">
                {currentPlan.plan_name}{" "}
                {currentPlan.stripe_status === "trialing" && "(Free Trial)"}
              </p>
              {currentPlan.plan_description && (
                <p className="pb-4">{currentPlan.plan_description}</p>
              )}
              {currentPlan.quantity && (
                <p className="pb-4">
                  You may have a maximum of {currentPlan.quantity} active
                  campaign(s).
                </p>
              )}
              {/* 2023-10-30: John - Disabled pricing display due to Stripe's prorated charges messing with the display. */}
              {/* <p className="pb-4">
                {`$${currentPlan.unit_amount / 100}`}{" "}
                <span className="uppercase text-sm">
                  ({currentPlan.currency})
                </span>{" "}
                per {currentPlan.recurring.interval}.
              </p> */}
              <p className="text-sm opacity-50">
                {currentPlan.stripe_status === "trialing" ? (
                  currentPlan.cancel_at_period_end ? (
                    <>
                      After your free trial ends on{" "}
                      {moment(currentPlan.trial_end)
                        .utcOffset(0)
                        .format("MMM DD, YYYY")}
                      , this plan will no longer be available.
                    </>
                  ) : (
                    <>
                      After your free trial ends on{" "}
                      {moment(currentPlan.trial_end)
                        .utcOffset(0)
                        .format("MMM DD, YYYY")}
                      , this plan will continue automatically.
                    </>
                  )
                ) : (
                  <>
                    {currentPlan.cancel_at_period_end
                      ? "Ends at "
                      : "Renews on "}
                    {moment(currentPlan.current_period_end).format(
                      "MMM DD, YYYY"
                    )}
                  </>
                )}
              </p>
            </>
          )}
        </div>
        <div className="w-full col-span-3 pl-3 1md:space-x-4 2md:flex 2md:flex-col 2md:space-y-4 2md:space-x-0 2md:col-span-1 ">
          {/* justify-self-end   */}
          {!loading && (
            <>
              <Button
                buttonName={
                  !currentPlan || currentPlan?.user_status === "suspended"
                    ? "Sign Up"
                    : "Update Plan"
                }
                buttonTextClass="text-white text-sm font-bold"
                buttonClass="relative bg-primary py-3 w-full mb-2 1md:w-5/12 1md:mb-0 2md:w-full 2md:mb-0"
                buttonType="primary"
                disabled={isLoadingPortal}
                onClick={() => toggle("upgrade-plan")}
              />
              <Button
                buttonName={
                  currentPlan?.user_status === "active"
                    ? "Manage Subscription"
                    : "Manage Billing Details"
                }
                buttonTextClass="text-black text-sm font-bold"
                buttonClass="relative bg-default py-3 w-full 1md:w-5/12 2md:w-full"
                buttonType="default"
                disabled={isLoadingPortal}
                onClick={openPortal}
              />
              {/* {currentPlan?.user_status === "active" &&
                !currentPlan?.cancel_at_period_end && (
                  <Button
                    buttonName="Cancel Subscription"
                    buttonClass="relative bg-default py-3 w-full 1md:w-5/12 2md:w-full"
                    buttonType="default"
                    buttonTextClass="text-black text-sm font-bold"
                    disabled={isLoadingPortal}
                    onClick={handleCancel}
                  />
                )} */}
            </>
          )}
        </div>
      </div>
      <div className="account-table-container">
        <div>
          <p className="text-xl">Name</p>
        </div>
        <div
          className={`account-table-content ${
            !userData.profile.first_name && "opacity-50"
          }`}
        >
          <p className="text-xl">{userData?.user.full_name}</p>
        </div>
        <button
          className="account-table-action-2"
          onClick={() => handleEdit("Name", "name")}
        >
          Edit
        </button>
      </div>
      <div className="account-table-container">
        <div>
          <p className="text-xl">Email Address</p>
        </div>
        <div
          className={`account-table-content ${
            !userData.user.email && "opacity-50"
          }`}
        >
          <p className="text-xl">{userData?.user.email}</p>
          <p className="text-sm">
            <span className="opacity-60">Please</span>{" "}
            <a
              className="account-table-action-2"
              href="https://support.conversioncow.com/hc/en-us/requests/new"
            >
              contact support
            </a>{" "}
            <span className="opacity-60">to change your email.</span>
          </p>
        </div>
        {/* <button
          className="account-table-action-2"
          onClick={() => handleEdit("Email Address", "email")}
        >
          Edit
        </button> */}
      </div>
      <div className="account-table-container">
        <div>
          <p className="text-xl">Timezone</p>
        </div>
        <div
          className={`account-table-content ${
            !userData?.profile?.timezone && "opacity-50"
          }`}
        >
          <p className="text-xl">
            {userData?.profile?.timezone || "Not specified"}
          </p>
        </div>
        <button
          className="account-table-action-2"
          onClick={() => handleEdit("Timezone", "timezone")}
        >
          Edit
        </button>
      </div>
      <div className="account-table-container">
        <div>
          <p className="text-xl">Phone Number</p>
        </div>
        <div
          className={`account-table-content ${
            !userData.profile.phone && "opacity-50"
          }`}
        >
          <p className="text-xl">
            {userData?.profile.phone || "Not Specified"}
          </p>
        </div>
        <button
          className="account-table-action-2"
          onClick={() => handleEdit("Phone Number", "phone")}
        >
          Edit
        </button>
      </div>
      <div className="account-table-container">
        <div>
          <p className="text-xl">Address</p>
        </div>
        <div
          className={`account-table-content ${
            !userData.profile.address && "opacity-50"
          }`}
        >
          <p className="text-xl">
            {userData?.profile.address || "Not Specified"}
          </p>
        </div>
        <button
          className="account-table-action-2"
          onClick={() => handleEdit("Address", "address")}
        >
          Edit
        </button>
      </div>
      <div className="account-table-container">
        <div>
          <p className="text-xl">Password</p>
        </div>
        <div className={`account-table-content`}>
          {/* <p className="text-xl">********</p> */}
          <div className="mb-4">
            <PasswordDottedIconSVG />
          </div>
          {userData?.user.password_last_updated_at ? (
            <p className="text-sm opacity-60">
              Password last changed{" "}
              {moment(userData?.user.password_last_updated_at).format("ll")}.
            </p>
          ) : (
            <p className="text-sm opacity-60">Password never changed.</p>
          )}
        </div>
        <button
          className="account-table-action-2"
          onClick={() => handleEdit("Password", "password")}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
