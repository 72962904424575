import React from "react";

const color = {
  default: {
    active: "bg-white rounded-full text-black",
    inactive: "border-transparent text-tabTextColor hover:text-black",
  },
};

export const FilterSelector = React.forwardRef(
  (
    {
      isActive,
      colorType = "default",
      children,
      onClick = () => {},
      filterWidth = "122px",
    },
    ref
  ) => (
    <button
      ref={ref}
      type="button"
      className={`filter-btn ${
        isActive ? color[colorType].active : color[colorType].inactive
      }`}
      style={{ width: filterWidth }}
      onClick={onClick}
    >
      {children}
    </button>
  )
);
