import React from "react";

const color = {
  default: {
    acitve: "tab-animation active text-black border-b-4",
    inactive:
      "border-transparent text-tabTextColor hover:text-black tab-animation border-b-4",
  },
  black: {
    acitve: "tab-animation-black active text-black border-b-4",
    inactive:
      "border-transparent text-tabTextColor hover:text-black tab-animation-black border-b-4",
  },
  secondary: {
    acitve:
      "border-black text-black focus:outline-none focus:text-black focus:border-black border-b-2",
    inactive:
      "border-transparent text-tabTextColor hover:text-black hover:border-black border-b-2",
  },
};

export const TabSelector = ({
  isActive,
  colorType = "default",
  children,
  gap = "mr-8",
  paddingY = "py-4",
  paddingX = "px-2",
  onClick = () => {},
}) => (
  <button
    type="button"
    className={`${gap} relative group inline-flex items-center ${paddingX} ${paddingY} text-sm leading-5 cursor-pointer whitespace-nowrap ${
      isActive ? color[colorType].acitve : color[colorType].inactive
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);
