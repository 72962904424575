import { device } from "components/campaigns/preview/screens";
import styled from "styled-components";

const Svg = styled.svg`
  margin-right: 4px;
  fill: currentColor;
  color: ${({ active }) => (active ? "#E78D4B" : "#c4c4c4")};

  width: 24px;
  height: 24px;

  @media ${device.xs} {
    width: 28px;
    height: 28px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    width: 32px;
    height: 32px;
  }
`;

const StarIconPreview = ({ active, responsive }) => {
  return (
    <Svg
      active={active}
      responsive={responsive}
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.94972 0.927053C7.24908 0.00574255 8.55249 0.00573969 8.85184 0.92705L9.94386 4.28794C10.0777 4.69997 10.4617 4.97893 10.8949 4.97893H14.4288C15.3975 4.97893 15.8003 6.21854 15.0166 6.78795L12.1576 8.86509C11.8071 9.11974 11.6605 9.5711 11.7943 9.98313L12.8864 13.344C13.1857 14.2653 12.1312 15.0315 11.3475 14.4621L8.48857 12.3849C8.13808 12.1303 7.66348 12.1303 7.313 12.3849L4.45405 14.4621C3.67034 15.0315 2.61585 14.2653 2.91521 13.344L4.00723 9.98313C4.1411 9.57111 3.99444 9.11974 3.64396 8.86509L0.785011 6.78795C0.00129724 6.21855 0.40407 4.97893 1.37279 4.97893H4.90665C5.33987 4.97893 5.72383 4.69997 5.8577 4.28795L6.94972 0.927053Z"
        fill="currentColor"
      />
    </Svg>
  );
};
export default StarIconPreview;
