import DashboardWelcomeCogSVG from "assets/svg/dashboard-welcome-cog";
import DashboardWelcomeCowSVG from "assets/svg/dashboard-welcome-cow";
import Card from "components/card/card";
import moment from "moment";
import { FiArrowRight } from "react-icons/fi";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const WelcomeCard = ({
  currentPlan,
  planExpiry,
  loading = false,
  planStatus,
  isCustomPlan = false,
  onClick = () => {},
  admin = false,
}) => (
  <Card className="border border-thBorder">
    <div className="flex">
      <DashboardWelcomeCowSVG />
      <h1 className="text-lg ">
        <span className="ml-2">Welcome to ConversionCow!</span>
      </h1>
    </div>
    <div className="mt-14 mb-4">
      {loading ? (
        <Skeleton width={"40%"} height={80} />
      ) : (
        <>
          {currentPlan && (
            <p className="text-base font-semibold">Your Current Plan</p>
          )}
          {isCustomPlan ? (
            <div className="flex items-center">
              <p className="mr-2 text-3xl font-semibold">
                Custom Plan Available
              </p>
              <button onClick={onClick}>
                <FiArrowRight className="text-3xl text-secondary" />
              </button>
            </div>
          ) : (
            <div className="flex items-center">
              <p className="mr-2 text-3xl font-semibold">
                {admin ? "Administrator Plan" : currentPlan || "Sign Up Today"}
              </p>
              <Link to="/dashboard/account/detail">
                <DashboardWelcomeCogSVG />
              </Link>
            </div>
          )}

          {/* update for custom plan */}
          {currentPlan && (
            <>
              {!admin && !isCustomPlan && (
                <p className="text-sm leading-4.5 text-textGray opacity-60">
                  {planStatus === "trialing" &&
                    `Your trial ends on ${moment(planExpiry).format(
                      "MMM DD, YYYY"
                    )}.`}
                </p>
              )}
              {!admin && isCustomPlan && (
                <p className="text-sm leading-4.5 text-textGray opacity-60">
                  You’ve offered a custom plan on the dashboard
                </p>
              )}
            </>
          )}
        </>
      )}
    </div>
  </Card>
);

export default WelcomeCard;
