const VideoLinkIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="20"
      height="16"
      className={className ?? ""}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0774 15.9962C12.5335 15.9962 6.99355 15.9962 1.44974 15.9962C1.38478 15.9733 1.31983 15.9504 1.25488 15.9313C0.502207 15.7137 0.00169808 15.0649 0.00169808 14.2824C0.00169808 10.0878 -0.0021226 5.89313 0.00169808 1.69466C0.00169808 0.759542 0.762015 0 1.7019 0C7.07761 0 12.4571 0 17.8328 0.00381679C18.5167 0.00381679 19.0173 0.339695 19.3382 0.935115C19.4222 1.09542 19.4681 1.27481 19.5292 1.44657C19.5292 5.81298 19.5292 10.1832 19.5292 14.5496C19.5139 14.5916 19.4987 14.6298 19.4872 14.6718C19.3305 15.2634 18.9714 15.6718 18.4021 15.8931C18.2951 15.9351 18.1843 15.9618 18.0774 15.9962ZM9.78647 1.14122C7.14256 1.14122 4.49864 1.14122 1.85473 1.14122C1.36186 1.14122 1.14408 1.35496 1.14408 1.84733C1.14408 5.94657 1.14408 10.0458 1.14408 14.145C1.14408 14.6374 1.35804 14.8511 1.85091 14.8511C7.12345 14.8511 12.3998 14.8511 17.6724 14.8511C18.1652 14.8511 18.383 14.6336 18.383 14.145C18.383 10.0458 18.383 5.94657 18.383 1.84733C18.383 1.35496 18.1691 1.14122 17.6762 1.14122C15.0476 1.14122 12.4151 1.14122 9.78647 1.14122Z"
        fill="currentColor"
      />
      <path
        d="M11.8045 12.5687C10.2036 12.5687 8.60275 12.5725 7.00188 12.5649C6.86051 12.5649 6.79938 12.6069 6.73443 12.7328C6.41732 13.355 5.90534 13.7023 5.19852 13.7061C4.48405 13.7099 3.96444 13.3664 3.6435 12.7366C3.57091 12.5954 3.49831 12.5572 3.35695 12.5649C3.18502 12.5763 3.01309 12.5725 2.84115 12.5649C2.52022 12.5572 2.28333 12.3015 2.28715 11.9847C2.29098 11.6756 2.53168 11.4313 2.84497 11.4237C3.04365 11.4198 3.2385 11.4313 3.43718 11.416C3.49449 11.4122 3.57855 11.3664 3.60529 11.3168C3.92623 10.6679 4.43056 10.2939 5.16413 10.2824C5.92063 10.2748 6.44788 10.6488 6.77646 11.3206C6.79939 11.3664 6.87198 11.4046 6.92929 11.416C7.01334 11.4313 7.10504 11.4198 7.19674 11.4198C10.3412 11.4198 13.4856 11.4198 16.63 11.4198C17.0923 11.4198 17.3827 11.8435 17.1878 12.2366C17.077 12.4618 16.886 12.5611 16.63 12.5611C15.0177 12.5687 13.413 12.5687 11.8045 12.5687ZM5.76016 11.9924C5.75634 11.6908 5.48507 11.4237 5.18323 11.4275C4.8814 11.4313 4.61395 11.7023 4.61777 12.0038C4.62159 12.3053 4.89286 12.5725 5.1947 12.5687C5.49653 12.5649 5.76398 12.2939 5.76016 11.9924Z"
        fill="currentColor"
      />
      <path
        d="M6.9043 6.27864C6.9043 5.53818 6.9043 4.79391 6.9043 4.05345C6.9043 3.54582 7.32075 3.28246 7.78305 3.51146C9.2693 4.24811 10.7517 4.99238 12.238 5.73284C12.471 5.85116 12.6277 6.01528 12.6239 6.29009C12.62 6.55727 12.471 6.72139 12.2456 6.83208C10.7555 7.57635 9.26548 8.32063 7.77541 9.06108C7.32839 9.28246 6.91194 9.0191 6.90812 8.52292C6.9043 7.77482 6.9043 7.02673 6.9043 6.27864ZM8.05432 7.63742C8.96365 7.18322 9.84622 6.74047 10.7632 6.28246C9.83858 5.82063 8.95601 5.38169 8.05432 4.93131C8.05432 5.84353 8.05432 6.72139 8.05432 7.63742Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default VideoLinkIconSVG;
