import React from "react";
import styled from "styled-components";
import Input from "../../commons/input";
import Select from "../../commons/select";
import Textarea from "../../commons/textarea";
import Button from "../../commons/button";
import _ from "lodash";
import ContactDetails from "../../commons/contact-details";
import { device } from "../../../screens";
import CheckCircleSVG from "assets/svg/check-circle-preview";
import PowerByIconSVG from "assets/svg/powered-by-icon";
import ContactsContainer from "../../commons/contact-container";
import FormContainer from "../../commons/form-container";
import CCIconLogoContainer from "../../commons/cc-logo-container";
import HeaderTemplate from "../../commons/header-template";
import { sanitizeValueHTML } from "utils/constant";

const TicksItem = styled.div`
  width: 100%;
  display: inline-flex;
  column-gap: 10px;

  margin-top: 14px;

  ${({ hidden }) => (hidden ? `display: none;` : "")}

  @media ${device.xs} {
    column-gap: 13px;
  }
`;
const FormResponsive = styled.div`
  width: 100%;
  margin-bottom: 6px;
  display: grid;
  row-gap: 6px;
  column-gap: 12px;

  @media ${device.xs} {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
`;

const TicksText = styled.p`
  margin: 0;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 14px;
  line-height: 17px;

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;
const BtnContainer = styled.div`
  width: 100%;
  margin-top: ${({ hiddenDisclaimer }) =>
    hiddenDisclaimer ? "28px" : "14px"} !important;
`;

const BtnText = styled.span`
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
`;

const Div = styled.div``;

const EnquiryForm = ({ data }) => {
  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <HeaderTemplate
          family={data.font?.family}
          width={data.width}
          dangerouslySetInnerHTML={{ __html: sanitizeValueHTML(data.title) }}
          contacts={true}
        />
        <ContactsContainer>
          <ContactDetails data={data} />
          <FormContainer responsive={data.width}>
            <FormResponsive responsive={data.width}>
              {data.show_name && (
                <Input
                  responsive={data.width}
                  label={data.name_input_label ? data.name_input_label : "Name"}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                />
              )}
              {data.show_email_address && (
                <Input
                  responsive={data.width}
                  label={
                    data.email_input_label
                      ? data.email_input_label
                      : "Email Address"
                  }
                  id="email"
                  type="text"
                  name="email"
                  placeholder="Email Address"
                />
              )}
              {data.show_phone && (
                <Input
                  responsive={data.width}
                  label={
                    data.phone_input_label ? data.phone_input_label : "Phone"
                  }
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Phone"
                />
              )}
              {data.show_enquiry_type && (
                <Select
                  responsive={data.width}
                  label={
                    data.enquiry_input_label
                      ? data.enquiry_input_label
                      : "Enquiry Type"
                  }
                  name="enquiry"
                  id="enquiry"
                  placeholder="Enquiry Type"
                  options={data?.enquiry_type_options}
                />
              )}
            </FormResponsive>
            {data.show_message && (
              <Textarea
                responsive={data.width}
                label={
                  data.message_input_label
                    ? data.message_input_label
                    : "Message"
                }
                placeholder="Message"
                name="message"
                rows="4"
              />
            )}

            {!_.isEmpty(data.text.value) && (
              <TicksItem hidden={!data.text.visible}>
                {data.show_ticks && !_.isEmpty(data.text.value) && (
                  <Div>
                    <CheckCircleSVG
                      color={data?.button_colour || "#808080"}
                      responsive={data.width}
                    />
                  </Div>
                )}
                <TicksText responsive={data.width}>{data.text.value}</TicksText>
              </TicksItem>
            )}

            <BtnContainer hiddenDisclaimer={!data.text.visible}>
              {data.submit_button.text && (
                <Button
                  responsive={data.width}
                  display={
                    !data.submit_button.visible ? "none" : "inline-block"
                  }
                  background={data?.button_colour || "black"}
                  family={data.font?.family}
                  radius={data?.button_corner_style}
                  color={data?.button_font_colour}
                  type="submit"
                >
                  <BtnText>{data.submit_button.text}</BtnText>
                </Button>
              )}
            </BtnContainer>
          </FormContainer>
        </ContactsContainer>
      </div>
      {data?.show_logo && (
        <CCIconLogoContainer>
          <PowerByIconSVG />
        </CCIconLogoContainer>
      )}
    </>
  );
};

export default EnquiryForm;
