import Card from "components/card/card";
import React from "react";

const InstallerItem = ({ name, brand, selected }) => {
  return (
    <Card
      className={`border-2 flex ${
        selected ? "border-secondary" : "border-gray"
      }`}
    >
      <img src={brand} alt={`${name} Install`} className="m-auto" />
    </Card>
  );
};

export default InstallerItem;
