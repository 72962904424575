import LoadingIndicator from "assets/svg/loading-indicator";
import VerifyEmailIcon from "assets/svg/verify-email-icon";
import React from "react";

const VerificationContent = (props) => {
  const { loading, resend } = props;

  return (
    <div className="column-container flex flex-col items-center">
      {loading ? (
        <LoadingIndicator className="text-secondary w-8 h-8 mb-5" />
      ) : (
        <VerifyEmailIcon className="w-14 h-14 mb-3" />
      )}
      <h2 className="column-title font-medium">Please Verify your Email</h2>

      <p className="text-md text-center font-extralight my-5">
        You’re almost there. We’ve sent you an email, please check your inbox to
        verify your account.
      </p>
      <p className="text-md text-center font-extralight">
        Didn’t receive an email?
        {loading ? (
          <span className="text-secondary"> Sending...</span>
        ) : (
          <span
            className="text-secondary cursor-pointer hover:text-primary"
            onClick={resend}
          >
            {" "}
            Resend Email Verification
          </span>
        )}
      </p>
    </div>
  );
};

export default VerificationContent;
