const ToggleDesktopIcon = ({ className = "" }) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 9.75V2.25C1.25 1.85218 1.40804 1.47064 1.68934 1.18934C1.97064 0.908035 2.35218 0.75 2.75 0.75H13.25C13.6478 0.75 14.0294 0.908035 14.3107 1.18934C14.592 1.47064 14.75 1.85218 14.75 2.25V9.75C14.75 10.1478 14.592 10.5294 14.3107 10.8107C14.0294 11.092 13.6478 11.25 13.25 11.25H2.75C2.35218 11.25 1.97064 11.092 1.68934 10.8107C1.40804 10.5294 1.25 10.1478 1.25 9.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 4H5.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ToggleDesktopIcon;
