import Details from "components/account/tab-panel/details";
import UpgradePlan from "components/account/tab-panel/upgrade-plan";
import Card from "components/card/card";
import { TabSelector } from "components/global/tabselector";
import { Context } from "context/context";
import Layout from "layouts/layout";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const Account = ({ userData }) => {
  const { http } = global.services;
  const { active_tab } = useParams();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useTabs([
    "detail",
    "credit-card",
    "transactions",
    "upgrade-plan",
  ]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  // const [currentPlan, setCurrentPlan] = useState({});
  const { currentPlan, setCurrentPlan } = useContext(Context);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      setSelectedTab(tab);
      history.push(`/dashboard/account/${tab}`);
    }
  };

  const getPlans = async () => {
    try {
      const result = await http.post("subscriptions/plans");
      const data = result.data.data;
      if (data) {
        let payload = _.clone(data);
        setList(payload);
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getUserSubscription = async () => {
    try {
      const result = await http.get(`subscriptions/${userData.user.id}`);
      const data = result.data.data;
      if (!_.isEmpty(data)) {
        let payload = _.clone(data);
        setCurrentPlan(payload);
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    } finally {
      setUserLoading(false);
    }
  };

  const tabs = [
    {
      tab: "detail",
      name: "Account Detail",
      component: (
        <Details
          loading={userLoading}
          currentPlan={currentPlan}
          toggle={toggle}
          getUserSubscription={getUserSubscription}
        />
      ),
    },
    {
      tab: "upgrade-plan",
      name: "Update Plan",
      component: (
        <UpgradePlan
          pricing={list}
          loading={loading}
          userPlan={currentPlan}
          getUserSubscription={getUserSubscription}
        />
      ),
    },
  ];

  useEffect(() => {
    getPlans();
    getUserSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!active_tab) history.push(`/dashboard/account/detail`);
    else setSelectedTab(active_tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_tab]);

  return (
    <Layout title="Account" routeName="dashboard/account">
      <div className="flex-col space-y-8 w-full">
        <nav className="w-full space-y-2 2md:flex 2md:space-y-0">
          <div className="relative w-full 2md:w-1/2">
            {tabs.map((item, index) => {
              return (
                <TabSelector
                  key={index}
                  isActive={selectedTab === item.tab}
                  onClick={() => toggle(item.tab)}
                >
                  {item.name}
                </TabSelector>
              );
            })}
          </div>
        </nav>

        <div className="h-5/6">
          {tabs.map((item, index) => {
            return (
              <TabPanel key={index} hidden={selectedTab !== item.tab}>
                {selectedTab === "detail" ? (
                  <Card padding="p-0">{item.component}</Card>
                ) : (
                  item.component
                )}
              </TabPanel>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

export default connect(mapStateToProps)(Account);
