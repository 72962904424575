const SearchIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1701 21.5L13.0401 15.3717C10.2674 17.2007 6.56026 16.6349 4.45945 14.0621C2.35864 11.4893 2.54568 7.74422 4.89239 5.39353C7.24283 3.04609 10.9886 2.85825 13.5622 4.95877C16.1357 7.05929 16.7018 10.7665 14.8725 13.5393L21 19.6702L19.1701 21.5ZM9.47031 6.08788C7.6176 6.08671 6.02206 7.39433 5.65943 9.21108C5.2968 11.0278 6.26809 12.8477 7.97931 13.5578C9.69052 14.2679 11.6651 13.6704 12.6954 12.1307C13.7258 10.591 13.525 8.53801 12.2158 7.22714C11.4896 6.49594 10.5009 6.0857 9.47031 6.08788Z"
      fill="#C6C6C6"
    />
  </svg>
);

export default SearchIcon;
