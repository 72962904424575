const CampaignBackCircleSVG = ({ className = "" }) => (
  <svg
    className={className}
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="45" height="45" rx="22.5" fill="#DEDEDE" />
    <path
      d="M17.2875 23.75L21.7625 28.2375L20 30L12.5 22.5L20 15L21.7625 16.7625L17.2875 21.25H32.5V23.75H17.2875Z"
      fill="#787878"
    />
  </svg>
);

export default CampaignBackCircleSVG;
