import React from "react";
import "./styles.css";

export default function PopoutAction({
  className = "action-btn",
  children,
  dropdown = [],
  show = false,
  setShow = () => {},
  id = "",
  width = "w-56",
  ...props
}) {
  const clickedAction = (action = () => {}) => {
    setShow({ [id]: false });
    action();
  };

  return (
    <div className="relative">
      <button
        {...props}
        className={`${className} ${show ? "bg-lightGray" : "bg-transparent"}`}
        onClick={() => setShow({ [id]: !show })}
      >
        {children}
      </button>
      {show && (
        <ul
          className={`absolute right-0 z-50 ${width} flex flex-col bg-white border rounded-md shadow-2xl border-lightGray top-12`}
        >
          {dropdown.map((item, itemIndex) => (
            <li
              key={`dropdown-${itemIndex}`}
              className="flex items-center px-4 py-2 pr-10 space-x-2 cursor-pointer hover:bg-lightGray"
              onClick={() => clickedAction(item.action)}
            >
              <div className="w-7">{item.icon && item.icon}</div>
              <span>{item.text}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
