import { mapStyleOptions } from "assets/css/map-style-options";
import EyeIconSVG from "assets/svg/eye-icon";
import EyeSlashIconSVG from "assets/svg/eye-slash-icon";
import { GOOGLE_MAPS_API_KEY } from "configs/api";
import React, { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { FaCheck, FaTimes } from "react-icons/fa";
import { components } from "react-select";
import _ from "lodash";
import "./styles.css";

const AutocompleteInput = (props) => (
  <components.Input {...props} isHidden={false} />
);
const InputMap = (
  {
    autoFocus = false,
    validationPassed = false,
    showInputValidation = false,
    type = "text",
    name = "",
    label = null,
    inputContainerClass = "",
    inputIcon = null,
    iconPosition = "right", // ["left", "right"]
    placeholder = "",
    errorMessage = null,
    outsideLabel = "",
    inputClass = "",
    labelClass = "input-label",
    outsideLabelClass = "",
    paddingTop = null,
    errorMessagePosition = "top",
    showPasswordStrength = false,
    showCounter = false,
    disabledInput = false,
    masked = false,
    visible = true,
    extra = { show: false, label: "" },
    onChangeVisible = () => {},
    ...props // make sure this pops here is for input only
  },
  ref
) => {
  const [inputValue, setInputValue] = useState(props.value || "");

  const padding = () => {
    let result = "1rem";
    if (showPasswordStrength) {
      result = "9rem";
    } else if (showInputValidation) {
      result = "3rem";
    } else if (showCounter) {
      result = "3.7rem";
      if (masked) {
        result = "4.5rem";
      }
    } else if (masked) {
      result = "2rem";
    }
    return result;
  };

  const onInputChange = (value, { action }) => {
    if (action === "input-change") setInputValue(value);
  };

  return (
    <div className={inputContainerClass + " relative"}>
      {outsideLabel && (
        <label
          htmlFor={name}
          className={`input-label-upper ${outsideLabelClass}`}
        >
          {outsideLabel}
        </label>
      )}
      {errorMessage && errorMessagePosition === "top" && (
        <p className="input-error-message">* {errorMessage}</p>
      )}

      <div className={`input-wrapper ${masked && "pr-3"}`}>
        {props?.value?.length > 1 && showInputValidation && (
          <div
            className={`validation-container ${
              validationPassed ? "bg-lightGreen" : "bg-red"
            } ${outsideLabel && "top-2.5"}`}
          >
            {validationPassed ? (
              <FaCheck className="icon-input" />
            ) : (
              <FaTimes className="icon-input" />
            )}
          </div>
        )}

        {inputIcon && (
          <div
            className={`h-full absolute items-center flex ${iconPosition}-3 z-40`}
          >
            {inputIcon}
          </div>
        )}

        <GooglePlacesAutocomplete
          apiKey={GOOGLE_MAPS_API_KEY}
          debounce={500}
          apiOptions={{ libraries: ["places"] }}
          selectProps={{
            ...props,
            id: name,
            type: type,
            value: props.value,
            name: name,
            disabled: disabledInput,
            instanceId: name,
            inputValue: inputValue,
            styles: mapStyleOptions(iconPosition, padding, errorMessage, label),
            placeholder: placeholder,
            onInputChange: onInputChange,
            noOptionsMessage: () => null,
            controlShouldRenderValue: false,
            className: `input-class ${inputClass} ${outsideLabel && "mt-2"} ${
              disabledInput && "bg-lightGray"
            }`,
            classNamePrefix: "input-class mt-2 bg-lightGray",
            components: {
              DropdownIndicator: null,
              LoadingIndicator: null,
              Input: AutocompleteInput,
            },
            onChange: async (location) => {
              setInputValue(location?.label || "");
              props.onChange(location);
            },
          }}
        />
        {label && (
          <label
            htmlFor={name}
            className={labelClass}
            style={{ paddingLeft: iconPosition === "left" ? "2.5rem" : "1rem" }}
          >
            {label}
          </label>
        )}
        {showCounter && (
          <span
            className={`text-xs opacity-70 absolute ${
              masked ? "top-5 right-10" : "top-3 right-2.5"
            }`}
          >
            {`${_.size(props?.value)}/${props?.maxLength}`}
          </span>
        )}
        {masked && (
          <button
            type="button"
            className={`masked-button-input ${label ?? "top-1.5"}`}
            onClick={onChangeVisible}
          >
            {visible ? (
              <EyeIconSVG className="mx-auto" />
            ) : (
              <EyeSlashIconSVG className="mx-auto" />
            )}
          </button>
        )}
      </div>

      {errorMessage && errorMessagePosition === "bottom" && (
        <p className="input-error-message mt-2 -mb-1">* {errorMessage}</p>
      )}
    </div>
  );
};

const InputMapField = React.forwardRef(InputMap);

export default InputMapField;
