import CampaignBackCircleSVG from "assets/svg/campaign/back-circle-icon";
import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import ConfirmModal from "components/confirm/confirm";
import Slider from "components/input-range/slider";
import Input from "components/input/input";
import Select from "components/select/select";
import SelectPopout from "components/select/select-popout";
import Toggle from "components/toggle/toggle";
import { Context } from "context/context";
import { removeTrailingSlash } from "helpers/stringConverter";
import Layout from "layouts/layout";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { SPLIT_SETTINGS_OBJ } from "utils/constant";
import { useDialogHook } from "utils/customhooks";
import SpecialCondition from "./popout/special-condition";
import { domainIsValid } from "../../../helpers/domainValidator";

const testEndConditionOptions = [
  {
    label: "Unique Visitors",
    value: "total_opens",
  },
  {
    label: "Unique Opens",
    value: "total_clicks",
  },
  {
    label: "User Interactions",
    value: "total_interactions",
  },
  {
    label: "Form Submissions",
    value: "submissions",
  },
];

export default function CampaignSpecialCondtion() {
  const { http } = global.services;
  const history = useHistory();
  const { id } = useParams();
  const {
    setCampaign,
    campaign,
    forceUpdate,
    setForceUpdate,
    alternative: props,
  } = useContext(Context);
  const [validationError, setValidationErrors] = useState({});
  const [conditions, setConditions] = useState([]);
  const [testContent, setTestContent] = useState(SPLIT_SETTINGS_OBJ);
  const [popupOptions, setPopupOptions] = useState([]);
  const [selectedPopupA, setSelectedPopupA] = useState("");
  const [selectedPopupB, setSelectedPopupB] = useState("");
  const [winner, setWinner] = useState(false);
  const [title, setTitle] = useState("");
  const [abTest, setAbTest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [splitPercent, setSplitPercent] = useState({
    a_percent: 50,
    b_percent: 50,
  });
  const confirm = useDialogHook(ConfirmModal);

  const handleChange = (e, name) => {
    setValidationErrors((prev) => ({
      ...prev,
      [name]: null,
      conditions: null,
    }));
    setTitle(e);
  };

  const handleAddCondition = () => {
    let payload = _.clone(conditions);
    let obj = { condition: "", value: "", timezone: "", type: "" };
    payload.push(obj);
    setConditions(payload);
  };

  const handleUpdateSplitTesting = (a, b) => {
    setSplitPercent({ ...splitPercent, a_percent: a, b_percent: b });
  };

  const handleChangeTestCondition = (objValue, key) => {
    if (key === "value") {
      if (Number(objValue) >= 0) {
        setTestContent((prev) => ({ ...prev, [key]: objValue }));
      } else {
        setTestContent((prev) => ({ ...prev, [key]: 0 }));
      }
    } else {
      setTestContent((prev) => ({ ...prev, [key]: objValue }));
    }
  };

  const validateCondition = (obj) => {
    let result = true;
    let message = "";
    let type = "";
    if (!_.isEmpty(obj)) {
      _.forEach(obj, function (e) {
        if (e.condition === "Time") {
          if (_.isEmpty(e.timezone)) {
            result = false;
            message = "Required Timezone.";
            type = "timezone";
          } else {
            if (_.isEmpty(e.value) || _.isEmpty(e.value?.military_time)) {
              result = false;
              message = "Invalid Timezone Format.";
              type = e.condition;
            } else {
              let time = e.value?.military_time;
              if (_.isEmpty(time?.[0]) || _.isEmpty(time?.[1])) {
                result = false;
                message = "Invalid Timezone Format.";
                type = e.condition;
              }
            }
          }
        }
        if (e.condition === "UTM") {
          if (_.isEmpty(e.value)) {
            result = false;
            message = "UTM is required";
            type = e.condition;
          }
        }
        if (e.condition === "Returning User") {
          if (_.isEmpty(e.value)) {
            result = false;
            message = "Returning User is required.";
            type = e.condition;
          }
        }
        if (e.condition === "Internal URL") {
          if (_.isEmpty(e.value)) {
            result = false;
            message = "Internal URL is required.";
            type = e.condition;
          }
        }
        if (e.condition === "Day Of the Week") {
          if (_.isEmpty(e.value)) {
            result = false;
            message = "Select atleast one day of the week";
            type = e.condition;
          }
        }
        if (e.condition === "Date Range") {
          if (_.isEmpty(e.value)) {
            result = false;
            message = "Select a Date Range";
            type = e.condition;
          }
        }
        if (e.condition === "Referral Domain") {
          message = [];
          e.value.forEach((item, index) => {
            message.push("");
            if (_.isEmpty(item)) {
              result = false;
              message[index] = "Referral Domain is required.";
              type = e.condition;
            } else if (!domainIsValid(item)) {
              result = false;
              message[index] = "URL is Invalid";
              type = e.condition;
            }
          });
        }
      });
    }
    return { result: result, message: message, type: type };
  };

  const handleCreate = async () => {
    setValidationErrors((prev) => ({
      ...prev,
      title: null,
      conditions: null,
      testContentType: null,
      testContentValue: null,
    }));
    const { campaign_id } = props;
    // Remove slashes at the end of the URL if there’s any
    const INTERNAL_URL_CONDITION_INDEX = conditions.findIndex(
      (obj) => obj.type === "internal-url"
    );
    if (INTERNAL_URL_CONDITION_INDEX > -1)
      conditions[INTERNAL_URL_CONDITION_INDEX].value = removeTrailingSlash(
        conditions[INTERNAL_URL_CONDITION_INDEX].value
      );

    setLoading(true);
    if (campaign_id) {
      const validate = validateCondition(conditions);
      let errorsContent = {};
      if (validate.result) {
        const params = {
          campaign_id,
          popup_a_id: selectedPopupA.id,
          popup_condition: JSON.stringify({
            content: conditions,
            title,
          }),
          ...(abTest && {
            //set parameters needed if "Add A/B Test" is toggled
            ...splitPercent, // test percentage parameters (a_percent and b_percent)
            popup_b_id: selectedPopupB.id,
            test_condition: JSON.stringify(testContent),
          }),
        };

        if (_.isEmpty(title)) {
          errorsContent = {
            ...errorsContent,
            title: "This field is required",
          };
        }
        if (abTest) {
          if (_.isEmpty(testContent?.type)) {
            errorsContent = {
              ...errorsContent,
              testContentType: "This field is required",
            };
          }
          if (_.isEmpty(testContent?.value)) {
            errorsContent = {
              ...errorsContent,
              testContentValue: "This field is required",
            };
          }
        }
        if (!_.isEmpty(errorsContent)) {
          setValidationErrors((prev) => ({
            ...prev,
            ...errorsContent,
          }));

          return setLoading(false);
        }

        try {
          const result = await http.post("campaign-popups", { ...params });
          const data = result.data.data;

          //Automatically reflect the newly created campaign popup after it is being saved
          let updatedPopups = [...campaign.campaign_popups, data];
          setCampaign((prev) => ({ ...prev, campaign_popups: updatedPopups }));
          toast.success("Special Condition successfully created.");
          history.push(`/campaign-details/${id}/popouts`);
        } catch (error) {
          if (error.status === 422)
            toast.error("It appears that something went wrong");
        } finally {
          setLoading(false);
        }
      } else {
        setValidationErrors((prev) => ({
          ...prev,
          ...errorsContent,
          conditions: validate,
        }));
        return setLoading(false);
      }
    }
  };

  const handleUpdate = async () => {
    const { campaign_id } = props;
    setLoading(true);
    if (campaign_id) {
      const validate = validateCondition(conditions);
      if (validate.result) {
        const trim_conditions = conditions.filter(
          (cond) => cond.condition !== ""
        );
        const params = {
          popup_a_id: selectedPopupA.id,
          popup_condition: JSON.stringify({
            content: trim_conditions,
            title,
          }),
          ...(abTest
            ? {
                //set parameters needed if "Add A/B Test" is toggled
                ...splitPercent, // test percentage parameters (a_percent and b_percent)
                popup_b_id: selectedPopupB.id,
                test_condition: JSON.stringify(testContent),
              }
            : {
                // set null
                a_percent: null,
                b_percent: null,
                popup_b_id: null,
                test_condition: null,
              }),
        };

        try {
          const result = await http.put(`campaign-popups/${props.popup.id}`, {
            ...params,
          });
          const data = result.data.data;
          // automatically reflect updates to the selected popup campaign
          let cpIndex = campaign.campaign_popups.findIndex(
            (cp) => cp.id === data.id
          );
          campaign.campaign_popups[cpIndex] = data;

          setCampaign(campaign);
          setForceUpdate(!forceUpdate);
          toast.success("Special Condition successfully updated.");
          history.push(`/campaign-details/${id}/popouts`);
        } catch (error) {
          if (error.data?.message) toast.error(error.data.message);

          if (error.status === 422)
            toast.error("It appears that something went wrong");
        } finally {
          setLoading(false);
        }
        setLoading(false);
      } else {
        setValidationErrors((prev) => ({
          ...prev,
          conditions: validate,
        }));
        return setLoading(false);
      }
    }
  };

  const confirmOnUpdate = () => {
    setValidationErrors((prev) => ({
      ...prev,
      title: null,
      conditions: null,
      testContentType: null,
      testContentValue: null,
    }));
    const { campaign_id } = props;

    // Remove slashes at the end of the URL if there’s any
    const INTERNAL_URL_CONDITION_INDEX = conditions.findIndex(
      (obj) => obj.type === "internal-url"
    );
    if (INTERNAL_URL_CONDITION_INDEX > -1)
      conditions[INTERNAL_URL_CONDITION_INDEX].value = removeTrailingSlash(
        conditions[INTERNAL_URL_CONDITION_INDEX].value
      );

    if (abTest && !selectedPopupB)
      return toast.error("Please make sure that you selected a valid popout.");

    if (campaign_id) {
      const validate = validateCondition(conditions);
      let errorsContent = {};
      if (validate.result) {
        if (_.isEmpty(title)) {
          errorsContent = {
            ...errorsContent,
            title: "This field is required",
          };
        }
        if (abTest) {
          if (_.isEmpty(testContent?.type)) {
            errorsContent = {
              ...errorsContent,
              testContentType: "This field is required",
            };
          }
          if (_.isEmpty(testContent?.value)) {
            errorsContent = {
              ...errorsContent,
              testContentValue: "This field is required",
            };
          }
        }
        if (!_.isEmpty(errorsContent)) {
          setValidationErrors((prev) => ({
            ...prev,
            ...errorsContent,
          }));
          return setLoading(false);
        }

        confirm(
          {
            title: "Update Special Condition",
            buttonText: "YES, UPDATE IT",
            description: `Are you sure you want to update this Special Condition? Note that updating special conditions will reset the analytics.`,
            btnType: "primary",
            btnBg: "bg-primary",
          },
          (result) => {
            if (result) {
              handleUpdate();
            }
          }
        );
      } else {
        setValidationErrors((prev) => ({
          ...prev,
          ...errorsContent,
          conditions: validate,
        }));
        return setLoading(false);
      }
    }
  };

  const handleSelectPopup = (popup_key, value) => {
    popup_key(
      popupOptions.find((popup) => popup.id === value.id) || props.popups[0]
    );
  };

  const checkPopupTests = (defaultPopupA) => {
    // check if this popup is on the tests popup A
    if (!props.popup?.popup_b) {
      return setSelectedPopupB(
        props.popups[defaultPopupA.id === props.popups[0].id ? 1 : 0]
      );
    }

    const test_condition =
      typeof props.popup?.test_condition === "string"
        ? JSON.parse(props.popup?.test_condition)
        : props.popup?.test_condition;
    setSelectedPopupB(props.popup.popup_b);
    handleUpdateSplitTesting(props.popup.a_percent, props.popup.b_percent);
    setTestContent(!_.isEmpty(test_condition) ? test_condition : testContent);
    setWinner(test_condition.create_winner ?? false);
    setAbTest(true);
  };

  useEffect(() => {
    if (popupOptions?.length === 0) setPopupOptions(props.popups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Contains the "Popout Selection" section when they are about to add or edit conditions */
  useEffect(() => {
    if (popupOptions?.length === 0) return;
    const defaultPopupA = props.popup?.popup_a
      ? props.popup.popup_a
      : props.popups[0];
    setSelectedPopupA(defaultPopupA);
    checkPopupTests(defaultPopupA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupOptions]);

  useEffect(() => {
    if (props.popup) {
      const popup_condition =
        typeof props.popup.popup_condition === "string"
          ? JSON.parse(props.popup.popup_condition)
          : props.popup.popup_condition;
      if (!_.isEmpty(popup_condition)) {
        setConditions(popup_condition.content || []);
        setTitle(popup_condition.title || "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.popup]);

  useEffect(() => {
    if (_.isEmpty(props.campaign_id)) {
      history.push(`/campaign-details/${id}/popouts`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleUpdateCheckbox = (e) => {
    const isChecked = e.target.checked;
    handleChangeTestCondition(true, "create_winner");
    setWinner(isChecked);
  };

  return (
    <Layout title="Campaigns" routeName="campaign-condition" noPadding={true}>
      <div className="w-full mt-1" style={{ height: "calc(100vh - 93px)" }}>
        <form
          className="w-full h-full"
          onSubmit={(e) => {
            e.preventDefault();
            if (props.popup?.id) {
              confirmOnUpdate();
            } else {
              handleCreate();
            }
          }}
        >
          <div className="w-full h-full grid grid-cols-2">
            {/* conditions */}
            <div className="w-full h-full pl-7 pr-16 py-7">
              <div className="w-full flex">
                {/* back */}
                <div>
                  <Button
                    buttonClass="bg-transparent"
                    icon={<CampaignBackCircleSVG className="w-10 h-10" />}
                    iconOnly
                    onClick={() =>
                      history.push(`/campaign-details/${id}/popouts`)
                    }
                  />
                </div>
                {/* content */}
                <div className="pl-5 w-full">
                  {/* title */}
                  <div className="w-full flex items-center">
                    <h1 className="text-2xl mt-1 mr-5">
                      {props?.popup?.id
                        ? "Edit Special Conditions"
                        : "Create Special Conditions"}
                    </h1>
                  </div>
                  <div className="mt-4">
                    <Input
                      inputContainerClass="input-container"
                      value={title}
                      outsideLabel={"Title"}
                      labelClass="mb-5"
                      placeholder="Special Condition Title"
                      name="title"
                      type="text"
                      onChange={(e) => handleChange(e.target?.value, "title")}
                      errorMessage={validationError?.title}
                    />
                  </div>
                  <div className="w-full mt-4">
                    <p className="text-xl">Special Condition</p>
                    <SpecialCondition
                      data={conditions}
                      campaign={campaign}
                      setConditions={setConditions}
                      conditionError={validationError?.conditions}
                    />
                    <Button
                      data-cc="add-condition-button"
                      buttonName="+ ADD CONDITION"
                      buttonClass="bg-transparent mt-4"
                      buttonType="transparent"
                      buttonTextClass="text-sm font-bold text-left text-secondary"
                      onClick={handleAddCondition}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* popout details */}
            <div className="w-full h-full">
              <div
                className="bg-white w-full px-16 py-8"
                style={{ height: "calc(100% - 94px)" }}
              >
                <p className="text-xl">Popout Selection</p>
                {/* POPOUT A */}
                <SelectPopout
                  label={abTest ? "Popout A" : "Choose popout"}
                  name="popout"
                  abTest={abTest}
                  options={popupOptions}
                  value={selectedPopupA}
                  onChange={(value) =>
                    handleSelectPopup(setSelectedPopupA, value)
                  }
                />
                <div className="flex space-x-4">
                  <Toggle
                    checked={abTest}
                    // disabled={campaign?.popups?.length <= 1}
                    onChange={(e) => setAbTest(e?.target?.checked)}
                  />
                  <div className="flex flex-col">
                    <p className="my-auto text-base font-light">
                      Enable A/B Test
                    </p>
                  </div>
                </div>
                {abTest && (
                  <div>
                    {/* POPOUT B */}
                    <SelectPopout
                      label="Popout B"
                      name="variation-popout"
                      abTest={abTest}
                      options={popupOptions.filter(
                        (e) => e.id !== props?.popup?.id
                      )} // filters popup options to not add the selected popup A on the list
                      value={selectedPopupB}
                      onChange={(value) =>
                        handleSelectPopup(setSelectedPopupB, value)
                      }
                    />
                    <div className="mt-10">
                      <p className="text-xl flex space-x-4">
                        <span>Split Testing Settings</span>
                      </p>
                      <p className="pt-1">Choose split percentages</p>
                      <div className="mt-5 mb-2">
                        <Slider
                          defaultValueA={props?.popup?.a_percent || 50}
                          defaultValueB={props?.popup?.b_percent || 50}
                          onChangeFinish={handleUpdateSplitTesting}
                        />
                      </div>
                      <p className="text-sm opacity-70">
                        Move slider to change split
                      </p>
                    </div>
                    <div className="flex space-x-4 mt-5">
                      <div className="w-full">
                        <Select
                          label="Test End Condition"
                          placeholder="Select Option"
                          value={testContent?.type.label}
                          onChange={(e) => handleChangeTestCondition(e, "type")}
                          options={testEndConditionOptions}
                          errorMessage={
                            validationError?.testContentType || null
                          }
                        />
                      </div>
                      <div className="w-full pt-0.5">
                        <Input
                          inputClass="input-number"
                          type="number"
                          outsideLabel="Is Equal to"
                          placeholder="Is Equal to"
                          value={testContent?.value}
                          noPaddingRight={true}
                          onChange={(e) =>
                            handleChangeTestCondition(e.target?.value, "value")
                          }
                          errorMessage={
                            validationError?.testContentValue || null
                          }
                        />
                      </div>
                    </div>
                    <div className="w-full mt-6">
                      <CustomCheckbox
                        label="Create Condition from winner"
                        labelClass="text-base leading-6 tracking-normalwide my-auto"
                        name="winner"
                        checked={winner}
                        onChange={handleUpdateCheckbox}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="bg-white w-full h-23 mt-1 px-16">
                <div className="w-full h-full flex items-center justify-end">
                  <div>
                    <Button
                      key="cancel-btn"
                      buttonName="CANCEL"
                      buttonClass="bg-default rounded-full py-3 px-9 mr-3"
                      buttonTextClass="text-black text-sm font-bold"
                      buttonType="default"
                      onClick={() =>
                        history.push(`/campaign-details/${id}/popouts`)
                      }
                    />
                    <Button
                      key="save-btn"
                      type="submit"
                      buttonName={props.popup?.id ? "UPDATE" : "CREATE"}
                      buttonClass="relative bg-primary rounded-full py-3 px-12"
                      buttonTextClass="text-white text-sm font-bold"
                      buttonType="primary"
                      disabled={loading}
                      icon={
                        loading && (
                          <div className="absolute right-3 bottom-0 top-0 flex items-center">
                            <LoadingIndicator className="text-white w-5 h-5" />
                          </div>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}
