import ContactNumberIconSVG from "assets/svg/contact-number-icon";
import FileUpload from "components/file-upload/file-upload";
import Input from "components/input/input";
import Button from "components/button/button";
import Toggle from "components/toggle/toggle";
import { useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import _ from "lodash";
import ButtonClickIconSVG from "assets/svg/button-click-icon";
import LinkInputIconSVG from "assets/svg/link-input-icon";
import update from "immutability-helper";
import MinusIconSVG from "assets/svg/minus-icon";

export default function LeadMagnetDetailsTab({
  onHandleBlur,
  content,
  state,
  setState,
  setContent,
  handleInputChange,
  handleInputWithObjectChange,
  handleInputWithObjectChangeAddress,
  onUploadImage,
  handleRemoveImage,
}) {
  const [mediaTab, setMediaTab] = useTabs("image");
  const [emails, setEmails] = useState(
    content.submit_button.email ? content.submit_button.email.split(",") : []
  );

  useEffect(() => {
    onHandleBlur(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content.show_name,
    content.enquiry_type_options,
    content.enquiry_type_options?.length,
    content.show_enquiry_type,
    content.show_message,
    content.text?.visible,
    content.show_email_address,
    content.show_phone,
    content.email_address?.visible,
    content.contact_number?.visible,
    content.address.visible,
    content.submit_button?.visible,
    content.submit_button?.email,
    content.show_ticks,
  ]);

  const handleAddMoreEmails = () => {
    let payload = _.clone(emails);
    let counter = _.size(payload) + 1;
    if (counter > 5) {
      return setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          submit_button: { email: ["A maximum of 5 email addresses"] },
        },
      }));
    }
    payload.push(`your-email-${counter}@example.com`);
    setEmails(payload);
    setContent((prev) => ({
      ...prev,
      submit_button: {
        ...prev.submit_button,
        email: payload.join(","),
      },
    }));
    setState((prev) => ({
      ...prev,
      validationErrors: { ...prev.validationErrors, submit_button: null },
    }));
  };

  const handleRemoveEmail = (index) => {
    let payload = _.clone(emails);
    let data = update(payload, { $splice: [[index, 1]] });
    setEmails(data);
    setContent((prev) => ({
      ...prev,
      submit_button: {
        ...prev.submit_button,
        email: data.join(","),
      },
    }));
    setState((prev) => ({
      ...prev,
      validationErrors: { ...prev.validationErrors, submit_button: null },
    }));
  };

  const handleChangeValueEmail = (value, index) => {
    let payload = _.clone(emails);
    let data = update(payload, {
      [index]: { $set: value },
    });
    setEmails(data);
    setState((prev) => ({
      ...prev,
      validationErrors: { submit_button: null },
    }));
  };

  const handleOnBlurEmail = () => {
    setContent((prev) => ({
      ...prev,
      submit_button: {
        ...prev.submit_button,
        email: emails.join(","),
      },
    }));
  };

  return (
    <div>
      <p className="text-sm pl-1 pt-2 pb-2">Edit Input Fields</p>
      <div className="mt-2">
        {/* Layout */}

        {/* form-button */}
        <div className="pl-1 pr-4">
          <Input
            inputContainerClass="mb-3"
            visible={content.show_name}
            value={content.name_input_label ?? "First Name"}
            name="name_input_label"
            onChange={handleInputChange}
            onBlur={onHandleBlur}
            placeholder="First Name"
          />
          <Input
            inputContainerClass="mb-3"
            visible={content.show_email_address}
            value={content.email_input_label ?? "Email Address"}
            name="email_input_label"
            onChange={handleInputChange}
            onBlur={onHandleBlur}
            placeholder="Email Address"
          />
        </div>

        <div className="pl-1 pr-4">
          <Input
            inputContainerClass="input-container"
            labelClass="input-label-xs"
            paddingTop="1.5rem"
            placeholder="e.g. Submit"
            value={content.submit_button?.text}
            maxLength={60}
            showCounter={true}
            label="Button Name"
            name="text"
            type="text"
            inputIcon={<ButtonClickIconSVG className="w-5 h-5 text-gray" />}
            iconPosition="left"
            onChange={(e) => {
              setContent((prev) => {
                return {
                  ...prev,
                  submit_button: {
                    ...prev.submit_button,
                    text: e.target.value,
                  },
                };
              });
              setState({
                ...state,
                validationErrors: { submit_button: null },
              });
            }}
            onBlur={onHandleBlur}
            onChangeVisible={() => {
              handleInputWithObjectChange("text", "visible");
            }}
            errorMessage={
              state.validationErrors?.submit_button?.text &&
              state.validationErrors?.submit_button?.text[0]
            }
          />
          <Input
            inputContainerClass="input-container"
            visible={true}
            value="Recipient Email Addresses"
            extra={{
              show: _.size(emails) > 0,
              label: ` ${_.size(emails)} Email Address`,
            }}
            inputIcon={<LinkInputIconSVG />}
            iconPosition="left"
            disabledInput
            errorMessage={
              state.validationErrors?.submit_button?.email &&
              state.validationErrors?.submit_button?.email[0]
            }
          />
          {/* start: content enquiry type */}
          <div className="ml-1 mr-4 relative">
            <div className="pl-8">
              {emails.map((value, index) => (
                <div key={index} className="flex space-x-3">
                  <Input
                    inputContainerClass="input-container-1"
                    placeholder={`e.g. Email ${index + 1}`}
                    value={value}
                    labelClass="mb-5"
                    name={value}
                    type="email"
                    maxLength={60}
                    onChange={(e) =>
                      handleChangeValueEmail(e.target?.value, index)
                    }
                    onBlur={handleOnBlurEmail}
                  />
                  <div className="my-auto relative bottom-1">
                    <button
                      type="button"
                      onClick={() => handleRemoveEmail(index)}
                      className="btn-remove rounded-full"
                    >
                      <MinusIconSVG className="m-auto" />
                    </button>
                  </div>
                </div>
              ))}
              <Button
                buttonName="+ ADD EMAIL ADDRESS"
                buttonClass="bg-transparent"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold text-left pb-5"
                buttonWidth="50%"
                onClick={handleAddMoreEmails}
              />
            </div>
          </div>
        </div>
        {/* media */}
        <Toggle
          name="show_ticks"
          background="bgdefault"
          checked={mediaTab === "image"}
          onChange={(e) => {
            setMediaTab(e?.target?.checked ? "image" : "video");
          }}
          icon="medias"
          className="w-72 h-10 my-5"
          cInactiveClass="toggle-container-left w-36 h-8 ml-1 rounded-full bg-white"
          cActiveClass="toggle-container-right w-32 h-8 ml-auto mr-1 rounded-full bg-white"
          custom="promotions"
          labelLeft="Upload an Image"
          labelRight="Link a Video"
        />
        {/* Image */}
        <TabPanel hidden={mediaTab === "image"}>
          <FileUpload
            wrapperClass="mt-2"
            leftContentClass="w-full h-20"
            imageClass="h-20 w-full"
            accept="image/*"
            width={"100%"}
            image_url={content.image_url}
            uploading={state.uploading}
            removable={true}
            onRemoveImage={handleRemoveImage}
            maxFileText="Maximum upload file size"
            allowedFiles="SVG, JPG, PNG, GIF"
            maxFileSize="300KB"
            onChange={onUploadImage}
          />
        </TabPanel>
        {/* Video Link */}
        <TabPanel hidden={mediaTab !== "image"}>
          {mediaTab === "image" && (
            <div className="mt-5">
              <Input
                inputContainerClass="input-container-2"
                placeholder="e.g. https://www.youtube.com/"
                labelClass="input-label-xs"
                paddingTop="1.5rem"
                autoFocus={true}
                value={content.video_link}
                label="Video Link"
                name="video_link"
                type="text"
                onChange={handleInputChange}
                onBlur={onHandleBlur}
                errorMessage={
                  state.validationErrors?.video_link?.value
                    ? state.validationErrors?.video_link?.value[0]
                    : null
                }
              />
            </div>
          )}
        </TabPanel>
        {/* Tick Points */}
        <Toggle
          name="show_ticks"
          background="bgdefault"
          checked={!content.show_ticks}
          onChange={(e) =>
            setContent((prev) => ({
              ...prev,
              show_ticks: e?.target?.checked,
            }))
          }
          icon="ticks"
          className="w-60 h-10 mt-3 mb-3"
          custom="popup"
          labelLeft="Tick Points"
          labelRight="Just Text"
        />
        <Input
          inputContainerClass="input-container-2"
          labelClass="input-label-xs"
          paddingTop="1.5rem"
          value={content.text.value}
          visible={content.text.visible}
          masked={true}
          label="Disclaimer Message"
          maxLength={60}
          showCounter={true}
          placeholder="e.g. More Text for Disclaimer / Message"
          name="text"
          type="text"
          inputIcon={<ContactNumberIconSVG className="w-5 h-5 text-gray" />}
          iconPosition="left"
          onChange={(e) => handleInputWithObjectChange(e, "value")}
          onBlur={onHandleBlur}
          onChangeVisible={() => handleInputWithObjectChange("text", "visible")}
          errorMessage={
            state.validationErrors?.text?.value
              ? state.validationErrors?.text?.value[0]
              : null
          }
        />
      </div>
    </div>
  );
}
