import React, { useContext, useEffect, useState } from "react";
import Layout from "layouts/layout";
import Button from "components/button/button";
import EmptyPopout from "components/campaigns/empty-popout";
import toast from "react-hot-toast";
import _ from "lodash";
import PopoutList from "components/popouts/popout-list";
import Pagination from "react-responsive-pagination";
import LoadingIndicator from "assets/svg/loading-indicator";
import { Context } from "context/context";
import { POPUP_INITIALS } from "utils/constant";
import { useHistory, useParams } from "react-router-dom";
import SelectPopoutSort from "components/select/select-sort-popout";

const SORT_BY = [
  { label: "Updated: Ascending", value: "updated_at ", sort: "ASC" },
  { label: "Updated: Descending", value: "updated_at ", sort: "DESC" },
  { label: "Created: Ascending", value: "created_at", sort: "ASC" },
  { label: "Created: Descending", value: "created_at", sort: "DESC" },
  { label: "Popout Title: Ascending", value: "title", sort: "ASC" },
  { label: "Popout Title: Descending", value: "title", sort: "DESC" },
];

const Popouts = () => {
  const { http } = global.services;
  const { id } = useParams(); //get id in url
  const {
    setCampaign,
    setPopup,
    setSnapshot,
    setDefaultTab,
    setPopoutCount,
    setSelectedTab,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    meta: {},
    loading: false,
    limit: 12,
  });
  const history = useHistory();
  const [current, setCurrent] = useState([]);
  const [currentPage, setCurrentPage] = useState(id || 1);
  const [sort, setSort] = useState(SORT_BY[1]);

  const getPopups = async (page) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const search = new URLSearchParams(window.location.search);
      const result = await http.get(`popups`, {
        limit: state.limit,
        page: page,
        orderBy: sort.value,
        order: sort.sort,
        show: search.get("show") ?? "owned",
      });
      const data = result.data.data;

      if (data) {
        setState((prev) => ({ ...prev, meta: result.data.meta }));
        setPopoutCount(result.data.meta?.total ? result.data.meta.total : 0);
        setCurrent(data);
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
      setState((prev) => ({ ...prev, loading: false }));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const checkScroll = () => {
    let header = document.getElementById("sticky-header");
    let text = document.getElementById("text-decoration");
    let bound = header.getBoundingClientRect();
    let sticky = bound.top;

    if (sticky === 0) {
      header.classList.add("bg-white", "py-4", "border-b", "border-thBorder");
      text.classList.add("text-xl", "text-decoration-30");
      text.classList.remove("text-2xl", "text-decoration-20");
    } else {
      header.classList.remove(
        "bg-white",
        "py-4",
        "border-b",
        "border-thBorder"
      );
      text.classList.remove("text-xl", "text-decoration-30");
      text.classList.add("text-2xl", "text-decoration-20");
    }
  };

  useEffect(() => {
    history.push(`/popouts/${currentPage}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    getPopups(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sort]);

  useEffect(() => {
    setCampaign({});
    setPopup(POPUP_INITIALS);
    setSnapshot({});
    setSelectedTab("basic");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      title="Popouts"
      routeName="popouts"
      noPadding={true}
      scrollYPosition={checkScroll}
    >
      <div className="w-full py-8">
        <div className="w-full sticky top-0 z-10" id="sticky-header">
          <div className="relative popout-wrapper mx-auto flex-1 px-7">
            <div className="flex items-center justify-between flex-wrap">
              <div className="flex items-center gap-x-5 flex-wrap">
                <p
                  className="text-2xl font-medium underline text-decoration text-decoration-20"
                  id="text-decoration"
                >
                  Popouts {!_.isEmpty(current) && `(${state.meta?.total})`}
                </p>
                {loading && (
                  <div className="flex items-center">
                    <LoadingIndicator className="text-secondary w-7 h-7" />
                  </div>
                )}
              </div>
              <div className="flex 7md:justify-end items-center 7md:space-x-5 flex-wrap">
                <div className="flex items-center w-71">
                  <SelectPopoutSort
                    wrapperClass="relative"
                    name="popoutSort"
                    options={SORT_BY}
                    placeholder="Sort By"
                    value={sort}
                    readOnly={true}
                    onChange={(e) => setSort(e)}
                  />
                </div>
                <Button
                  buttonName="CREATE POPOUT"
                  buttonType="primary"
                  buttonTextClass="text-white text-sm font-bold"
                  buttonClass="bg-primary rounded-full py-3 px-12 text-center"
                  onClick={() => history.push("/campaigns/popup")}
                />
              </div>
            </div>
          </div>
        </div>
        {/* contents */}
        <div className="relative mt-6 popout-wrapper mx-auto flex flex-1 px-7">
          {!state.loading ? (
            <div className="relative w-full">
              <PopoutList
                dataSource={current}
                setCurrent={setCurrent}
                setDefaultTab={setDefaultTab}
                setLoading={setLoading}
                setState={setState}
                deleteCallBack={() => {
                  if (currentPage > 1 && current.length === 1) {
                    setCurrentPage(currentPage - 1);
                  } else {
                    getPopups(currentPage);
                  }
                }}
                noData={
                  <EmptyPopout
                    title="You have no popouts yet"
                    subTitle="Do you want to create a popout?"
                    link={{
                      path: "/campaigns/popup",
                      label: "Create your first popout",
                    }}
                  />
                }
              />
              <div className="mt-5 w-full">
                <Pagination
                  total={state.meta.last_page || 1}
                  current={state.meta.current_page || 1}
                  onPageChange={(page) => setCurrentPage(page)}
                  previousLabel="Previous"
                  nextLabel="Next"
                />
              </div>
            </div>
          ) : (
            <div
              className="absolute inset-0 flex items-center justify-center"
              style={{ height: "65vh" }}
            >
              <LoadingIndicator className="w-10 h-10 text-secondary" />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Popouts;
