const RightArrow = ({ color = "black", className = "" }) => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.93125 1L9.93125 4M9.93125 4L6.93125 7M9.93125 4H0.03125"
        stroke={color}
        strokeWidth="1.53374"
      />
    </svg>
  );
};

export default RightArrow;
