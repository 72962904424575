const ContactNumberIconSVG = ({ className = "", fill = "#808080" }) => (
  <svg
    className={className}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.52 20C12.6122 19.8083 11.7708 19.4326 10.9373 19.0452C9.19209 18.2352 7.59944 17.1904 6.14767 15.9225C3.80762 13.8799 1.99976 11.4459 0.755382 8.60109C0.508855 8.0376 0.262327 7.46237 0.105802 6.87148C-0.16029 5.86581 0.0744973 4.95405 0.821905 4.19882C1.45974 3.55706 2.09367 2.9114 2.73542 2.27356C3.20109 1.8079 3.75675 1.62398 4.39068 1.85094C4.6372 1.94094 4.87982 2.10529 5.06765 2.28921C6.00289 3.20097 6.92639 4.13229 7.84597 5.0597C8.54642 5.76407 8.54251 6.69539 7.84597 7.39976C7.56423 7.68541 7.27465 7.97107 6.98508 8.25673C8.19815 10.2211 9.77514 11.802 11.7748 13.0347C12.0604 12.7333 12.3265 12.4086 12.6356 12.1346C13.2891 11.5633 14.107 11.5438 14.7331 12.1386C15.7544 13.1129 16.7562 14.1108 17.7305 15.136C18.3449 15.7856 18.3136 16.6504 17.6914 17.2961C17.0379 17.9769 16.3609 18.6343 15.684 19.2917C15.2887 19.6752 14.8074 19.8865 14.2635 19.9648C14.2322 19.9687 14.2087 19.9883 14.1774 20.0039C13.9622 20 13.7391 20 13.52 20ZM13.8291 18.7048C14.2244 18.6774 14.5648 18.5561 14.8426 18.2822C15.457 17.6717 16.0714 17.0613 16.6818 16.4508C16.9009 16.2317 16.9009 16.1495 16.6857 15.9343C15.774 15.0225 14.8622 14.1108 13.9505 13.199C13.7313 12.9799 13.6452 12.9799 13.43 13.1951C13.07 13.5551 12.7139 13.9151 12.3539 14.2712C12.0878 14.5334 11.7904 14.5764 11.4852 14.4003C10.4952 13.829 9.57166 13.1716 8.72251 12.4046C7.4977 11.2933 6.47638 10.0216 5.64679 8.59326C5.43157 8.22151 5.45896 7.93194 5.76419 7.62672C6.13202 7.25888 6.49985 6.89105 6.86769 6.52321C7.1103 6.2806 7.1103 6.19842 6.87551 5.95972C5.97941 5.06362 5.07939 4.1636 4.18328 3.26749C3.93284 3.01705 3.86241 3.02096 3.60805 3.27532C3.01326 3.8662 2.42237 4.47665 1.81584 5.06362C1.36192 5.50189 1.22496 6.01842 1.39713 6.6093C1.51062 6.99279 1.6554 7.37236 1.81193 7.7402C3.00152 10.5537 4.74677 12.9681 7.06334 14.9677C8.89469 16.5486 10.9491 17.7461 13.2265 18.5561C13.4222 18.6265 13.6296 18.6578 13.8291 18.7048Z"
      fill={fill}
    />
    <path
      d="M20.0032 9.64587C19.8976 9.90805 19.7332 10.1037 19.4358 10.1468C19.0211 10.2055 18.6845 9.88849 18.6845 9.42674C18.6806 8.63237 18.5632 7.85758 18.3284 7.10234C17.8941 5.71318 17.1506 4.51968 16.0862 3.52574C14.8653 2.38702 13.4292 1.67483 11.7779 1.41265C11.367 1.34613 10.9483 1.34222 10.5335 1.31482C10.0326 1.28352 9.72348 0.845249 9.89566 0.406979C9.9974 0.152626 10.2322 -0.0078125 10.5218 -0.0078125C12.2983 -0.0078125 13.9575 0.442197 15.464 1.38526C17.7962 2.84486 19.2558 4.93056 19.8272 7.63061C19.8976 7.96714 19.9211 8.3115 19.968 8.65194C19.9759 8.70672 19.9915 8.76151 20.0072 8.8202C20.0032 9.10195 20.0032 9.37196 20.0032 9.64587Z"
      fill={fill}
    />
    <path
      d="M10.8631 2.56312C12.2367 2.63747 13.481 3.07183 14.5884 3.88576C15.962 4.89534 16.862 6.22972 17.2533 7.88888C17.3746 8.39759 17.4098 8.92977 17.4529 9.45022C17.4842 9.8337 17.1868 10.1389 16.8228 10.1507C16.4433 10.1624 16.1498 9.88066 16.1381 9.48152C16.1067 8.18628 15.7233 7.01234 14.9093 5.99884C13.9897 4.85621 12.808 4.15968 11.3562 3.94054C11.0745 3.8975 10.7888 3.88184 10.5031 3.86228C10.1353 3.8388 9.85747 3.56879 9.85356 3.21661C9.84964 2.85269 10.1275 2.55921 10.4992 2.54356C10.6166 2.53964 10.7418 2.5553 10.8631 2.56312Z"
      fill={fill}
    />
    <path
      d="M10.7428 5.13401C12.3041 5.26314 13.4976 5.98707 14.2998 7.3371C14.6911 7.99451 14.8633 8.71452 14.875 9.47367C14.8828 9.84542 14.5933 10.1428 14.2333 10.1506C13.8615 10.1585 13.572 9.88455 13.5563 9.49715C13.4937 8.1119 12.7424 7.04753 11.4941 6.621C11.1967 6.51926 10.8758 6.48013 10.5588 6.44882C10.1088 6.40969 9.8271 6.14751 9.84666 5.74446C9.86623 5.36489 10.1949 5.10271 10.6215 5.12618C10.6684 5.1301 10.7036 5.13401 10.7428 5.13401Z"
      fill={fill}
    />
  </svg>
);

export default ContactNumberIconSVG;
