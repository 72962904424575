const NotificationMarkSVG = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline cursor-pointer"
  >
    <path
      d="M8.50015 8.66665V4.99998M1.69067 8.00003C1.69067 4.23923 4.7394 1.19051 8.5002 1.19051C12.261 1.19051 15.3097 4.23923 15.3097 8.00003C15.3097 11.7608 12.261 14.8096 8.5002 14.8096C4.7394 14.8096 1.69067 11.7608 1.69067 8.00003Z"
      stroke="#616162"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default NotificationMarkSVG;
