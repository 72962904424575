import "assets/css/components/campaign-tests.css";
import CrownIconSVG from "assets/svg/campaign/popout/crown-icon";
import { useEffect, useState } from "react";

export const TestEndCondition = ({ data }) => {
  const [payload, setPayload] = useState({ test_condition: {} });

  useEffect(() => {
    if (typeof data.test_condition === "string") {
      setPayload({ test_condition: JSON.parse(data?.test_condition) });
    }
  }, [data]);

  return (
    <div className="w-full flex flex-col">
      <div className="flex space-x-2 items-center">
        <h1 className="text-lg font-medium">Test End Condition</h1>
      </div>
      <p className="text-sm font-light">
        Winner will be determined after this condition:
      </p>
      <div className="flex justify-between items-center space-x-4 border border-thBorder p-4 rounded mt-5 h-24">
        {payload?.test_condition.type?.label &&
        payload?.test_condition?.value ? (
          <div className="flex flex-col">
            <p className="text-lg font-medium">
              {payload.test_condition?.value}
            </p>
            <p className="text-xs">{payload.test_condition?.type?.label}</p>
          </div>
        ) : (
          <h1 className="text-xl opacity-40">No Test Condition Selected</h1>
        )}
        <div>
          <CrownIconSVG className="w-8 h-8" />
        </div>
      </div>
    </div>
  );
};

export default TestEndCondition;
