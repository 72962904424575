import CloseIconSVG from "assets/svg/campaign/close-icon";
import ImageUploadIconSVG from "assets/svg/image-upload-icon";
import LoadingIndicator from "assets/svg/loading-indicator";
import _ from "lodash";
import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

const FileUploadComponent = (
  {
    id = "media-upload",
    label = "Browse Image",
    title = null,
    maxFileSize = null,
    recommendedImageSize = null,
    allowedFiles = null,
    uploading = false,
    image_url = null,
    width = "100%",
    wrapperClass = "mt-5",
    leftContainerClass = "w-3/12 flex justify-center items-center",
    leftContentClass = "w-full h-32",
    rightContainerClass = "px-6 py-1",
    rightContentClass = "my-2",
    titleTextClass = "text-tipsColor text-sm",
    className = "custom-file-upload flex py-1 justify-center text-center text-sm px-2",
    otherLabelClass = "mt-2 mb-2",
    icon = <AiOutlineCloudUpload className="text-lg mr-2" />,
    iconLeft = <ImageUploadIconSVG />,
    maxFileText = "Max file size",
    removable = false,
    onRemoveImage = () => {},
    tutorial = null,
    imageClass = "h-full w-full",
    ...props
  },
  ref
) => {
  return (
    <div className={wrapperClass}>
      <div className="flex flex-col 1md:flex-row items-center 1md:items-start">
        {/* ---- Left Section ---- */}
        <div className={leftContainerClass}>
          {uploading ? (
            // Upload Loader
            <div
              className={`${leftContentClass} flex justify-center items-center`}
            >
              <LoadingIndicator className="text-secondary w-10 h-10" />
            </div>
          ) : // Image Display
          image_url ? (
            <div
              className={`${leftContentClass} flex justify-center items-center`}
            >
              <div className="relative" style={{ height: "fit-content" }}>
                {removable && (
                  <button
                    className="bg-default w-6 h-6 rounded-full absolute -top-2.5 -right-1 close-hover"
                    onClick={onRemoveImage}
                    type="button"
                  >
                    <CloseIconSVG className="m-auto w-3 h-3 svg-icon" />
                  </button>
                )}
                <img
                  src={image_url}
                  alt="upload-images"
                  className={imageClass}
                />
              </div>
            </div>
          ) : (
            <div className={`${leftContentClass} bg-default relative`}>
              <label
                htmlFor={id}
                className="w-full h-full flex justify-center items-center cursor-pointer"
              >
                {iconLeft}
              </label>
            </div>
          )}
        </div>
        {/* ---- Right Section ---- */}
        <div className={rightContainerClass}>
          {/* Title */}
          {title && <p className={titleTextClass}>{title}</p>}
          {/* Content */}
          <div
            className={`${uploading ? "opacity-30" : ""} ${rightContentClass}`}
          >
            <label
              htmlFor={id}
              style={{ width: width }}
              className={`${
                uploading ? "cursor-default" : "cursor-pointer"
              } ${className}`}
            >
              {icon}
              {label}
            </label>
            <input
              ref={ref}
              disabled={uploading}
              type="file"
              id={id}
              name={id}
              {...props}
            />
          </div>
          {/* Other Labels/Instructions */}
          <p className={`text-xs text-tipsColor ${otherLabelClass}`}>
            {allowedFiles && `Allowed file: ${allowedFiles}`}
            {allowedFiles && <br />}
            {recommendedImageSize &&
              `Recommended image size: ${recommendedImageSize}`}
            {recommendedImageSize && <br />}
            {maxFileSize && `${maxFileText}: ${maxFileSize}`}
            {props.filename &&
              _.truncate(props.filename, {
                length: 18,
              })}
          </p>
          {tutorial}
        </div>
      </div>
    </div>
  );
};

const FileUpload = React.forwardRef(FileUploadComponent);

export default FileUpload;
