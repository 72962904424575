import React, { useEffect, useState } from "react";

const PasswordStrength = ({
  password,
  progressPercentage,
  wrapperClassName = "",
}) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    let progress = 0;
    if (password) {
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasEightCharacters = password.length >= 8;
      const hasNumber = /\d/.test(password);

      if (password.length >= 3) progress += 20; // weak
      if (password.length >= 4) {
        if (hasEightCharacters) {
          progress += 20; // normal
          if (hasLowerCase) progress += 20; // normal
          if (hasUpperCase) progress += 20; // strong
          if (hasNumber) progress += 20; // strong
        }
      }
    }

    setPercent(progress);
    progressPercentage(progress);
  }, [password, progressPercentage]);

  return (
    <div
      className={`bg-white rounded h-2 shadow overflow-hidden ${wrapperClassName}`}
    >
      <div
        className={`
                    h-2
                    ${percent >= 20 && "bg-gray"}
                    ${percent >= 40 && "bg-yellow"}
                    ${percent >= 80 && "bg-green"}

                    ${percent >= 20 && "w-3/12"}
                    ${percent >= 40 && "w-4/12"}
                    ${percent >= 60 && "w-6/12"}
                    ${percent >= 80 && "w-8/12"}
                    ${percent === 100 && "w-full"}
                `}
      />
    </div>
  );
};

export default PasswordStrength;
