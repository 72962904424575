export const size = {
  xss: "450px",
  xs: "663px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  s1md: "470px",
  s2md: "1130px",
  s3md: "1456px",
  s4md: "1596px",
  s2xl: "1920px",
  s3xl: "2560px",
  s4xl: "4560px",
};

export const device = {
  xss: `(min-width: ${size.xss})`,
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  s1md: `(min-width: ${size.s1md})`,
  s2md: `(min-width: ${size.s2md})`,
  s3md: `(min-width: ${size.s3md})`,
  s4md: `(min-width: ${size.s4md})`,
  s2xl: `(min-width: ${size.s2xl})`,
  s3xl: `(min-width: ${size.s3xl})`,
  s4xl: `(min-width: ${size.s4xl})`,
};
