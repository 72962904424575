import "./styles.css";

const CustomCheckbox = ({
  name,
  checked,
  label = "",
  labelClass = "",
  onChange = null,
}) => {
  return (
    <div className="checkbox-container-custom">
      <input
        type="checkbox"
        id={`checkbox-${name}`}
        checked={checked}
        onChange={(value) => (onChange ? onChange(value) : null)}
      />
      <div className="checkmark" />
      <label htmlFor={`checkbox-${name}`} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
