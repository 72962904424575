import styled from "styled-components";
import { device } from "../../screens";

const ContactsContainer = styled.div`
  width: 100%;
  margin-top: 24px;

  @media ${device.xs} {
    margin-top: 26px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-top: 28px;
  }
`;
export default ContactsContainer;
