import CampaignBackCircleSVG from "assets/svg/campaign/back-circle-icon";
import CloseIconSVG from "assets/svg/campaign/close-icon";
import CampaignDetailsPencilSVG from "assets/svg/campaign/pencil-icon";
import CampaignPopoutCogSVG from "assets/svg/campaign/popout/cog-icon";
import CampaignPopoutDeleteSVG from "assets/svg/campaign/popout/delete-icon";
import CampaignPopoutDuplicateSVG from "assets/svg/campaign/popout/duplicate-icon";
import CampaignSwitchIconSVG from "assets/svg/campaign/popout/switch-icon";
import CheckboxSVG from "assets/svg/checkbox-svg";
import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import CampaignCreate from "components/campaigns/campaign-create-modal";
import CampaignDetailsButton from "components/campaigns/details/button";
import CampaignLeads from "components/campaigns/details/leads";
import CampaignPopout from "components/campaigns/details/popout/popout";
import ReCaptcha from "components/campaigns/details/recaptcha";
import { CampaignSettings } from "components/campaigns/details/settings";
import CampaignStatistics from "components/campaigns/details/statistics/statistics";
import ConfirmModal from "components/confirm/confirm";
import NoticeModal from "components/confirm/notice";
import { TabSelector } from "components/global/tabselector";
import Input from "components/input/input";
import { Context } from "context/context";
import Layout from "layouts/layout";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import toast from "react-hot-toast";
import { FaChevronDown } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useHistory, useParams } from "react-router-dom";
import { POPUP_INITIALS } from "utils/constant";
import { useDialogHook } from "utils/customhooks";

const tabs = [
  { tab: "popouts", name: "Popouts", component: <CampaignPopout /> },
  {
    tab: "statistics",
    name: "Statistics Overview",
    component: <CampaignStatistics />,
  },
  { tab: "contacts", name: "Form Contacts", component: <CampaignLeads /> },
  {
    tab: "settings",
    name: "Advanced Settings",
    component: <CampaignSettings />,
  },
  {
    tab: "recaptcha",
    name: "reCAPTCHA",
    component: <ReCaptcha />,
  },
];

// let rendered = false;
export default function CampaignDetails() {
  const { http } = global.services;
  const { campaign, setCampaign, setPopup, setSnapshot, setSelectedTab } =
    useContext(Context);
  const modalCreate = useDialogHook(CampaignCreate);
  const confirm = useDialogHook(ConfirmModal);
  const notice = useDialogHook(NoticeModal);
  const [notFound, setNotFound] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const { active_tab, id } = useParams();
  const history = useHistory();
  const [state, setState] = useState({
    title: "",
    isEditTitle: false,
    isLoading: false,
    quickEditLoading: false,
  });

  const [selectedTab, setSelectedTabs] = useTabs([
    "popouts",
    "statistics",
    "contacts",
    "settings",
    "recaptcha",
  ]);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      setSelectedTabs(tab);
      history.push(`/campaign-details/${id}/${tab}`);
    }
  };
  //get campaign data
  const getCampaign = async (id) => {
    setState({ ...state, isLoading: true });
    try {
      const result = await http.get(`campaigns/${id}`);
      const data = result.data.data;
      if (data) {
        setState({ ...state, title: data.title, isLoading: false });
        fetchPopupsData(data);
      }
    } catch (error) {
      if (error.status === 422) {
        toast.error("It appears that something went wrong");
      } else if (error.status === 400) {
        setNotFound(true);
      } else {
        setNotFound(true);
      }
      setState({ ...state, isLoading: false });
    }
  };

  const fetchPopupsData = async (data) => {
    try {
      const result = await http.get("popups", {
        limit: 1000,
      });
      const popups = result.data.data;
      setCampaign((prev) => ({ ...prev, ...data, popups }));
    } catch (ex) {
      toast.error("Error while fetching toast data");
      console.error(ex);
    }
  };

  const handleUpdateTitle = async (type) => {
    if (type === "save") {
      setState((prev) => ({ ...prev, quickEditLoading: true }));
      try {
        const res = await http.put(`campaigns/${id}`, {
          title: state.title,
          id,
        });
        const payload = res?.data?.data;
        if (!_.isEmpty(payload)) {
          setCampaign({
            ...campaign,
            title: state.title,
            updated_at: payload.updated_at,
          });
        }
      } catch (e) {
        toast.error("It appears that something went wrong");
      } finally {
        setState((prev) => ({
          ...prev,
          quickEditLoading: false,
          isEditTitle: !state.isEditTitle,
        }));
      }
    } else {
      setState({
        ...state,
        isEditTitle: !state.isEditTitle,
        title: campaign.title,
      });
    }
  };

  const handleCreate = (data) => {
    let payload = {
      ...data,
      type: "edit",
    };
    modalCreate(payload, (res) => {
      if (!_.isEmpty(res)) {
        let payload = _.clone(res);
        delete payload.message;
        setCampaign((prevstate) => ({ ...prevstate, ...payload }));
        toast.success(res.message);
      }
    });
  };

  const handleChangeStatus = async (campaign) => {
    const previousStatus = campaign.status;
    const status = campaign.status === "dormant" ? "active" : "dormant";
    try {
      const update = await http.put(`campaigns/activate/${campaign.id}`, {
        status,
      });
      const result = update.data;
      const data = update.data.data;
      if (result.success) {
        toast.success(result.message);
        if (!_.isEmpty(data)) {
          setCampaign((prev) => ({
            ...prev,
            status: data.status,
            updated_at: data.updated_at,
          }));
        }
      }
    } catch (error) {
      if (error.status >= 400 && error.status < 500 && error.data?.message) {
        notice({
          title: "Unable to activate your campaign",
          buttonText: "Ok",
          description: error.data.message,
        });
      } else {
        toast.error(
          `It appears that something went wrong: ${error.data?.message}`
        );
      }
      setCampaign((prev) => ({ ...prev, status: previousStatus }));
    }
  };

  const handleEditCampaignStatus = async (campaign) => {
    const activeCampaigns = await getActiveCampaigns(campaign);

    if (campaign.status === "dormant") {
      if (activeCampaigns.active >= activeCampaigns.max) {
        notice({
          title: "Active Campaign Limit",
          buttonText: "Ok",
          description: (
            <>
              <strong>
                You've hit your limit of {activeCampaigns.max} campaign(s)
                active.
              </strong>
              <br />
              Deactivate one before activating this one.
            </>
          ),
        });
      } else {
        confirm(
          {
            title: "Activate Campaign",
            buttonText: "YES, ACTIVATE IT",
            description: "Are you sure you want to activate this Campaign?",
          },
          (result) => {
            if (result) {
              handleChangeStatus(campaign);
            }
          }
        );
      }
    } else {
      handleChangeStatus(campaign);
    }
  };

  const getActiveCampaigns = async (campaign) => {
    try {
      const activeCampaign = await http.get(`campaigns/${campaign.id}/active`);
      return activeCampaign.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmDelete = (record) => {
    const payload = {
      title: "Delete Campaign",
      buttonText: "YES, DELETE IT",
      description: "Do you want to delete this campaign?",
    };
    confirm(payload, (result) => {
      if (result) {
        handleDeleteCampaign(record);
      }
    });
  };

  const handleDeleteCampaign = async (campaign) => {
    if (campaign) {
      try {
        const del = await http.delete(`campaigns/${campaign.id}`);
        const res = del.data;
        if (res.success) {
          history.push(`/campaigns`);
        }
      } catch (error) {
        if (error.status === 422)
          toast.error("It appears that something went wrong");
      }
    }
  };

  const handleDuplicateCampaign = async (campaign) => {
    if (campaign) {
      setDuplicating(true);
      try {
        const del = await http.post(`campaigns/duplicate/${campaign.id}`);
        const res = del.data;
        if (res.success) {
          toast.success(
            "Campaign duplicated. Please return to Campaigns to view"
          );
        }
      } catch (error) {
        toast.error("It appears that something went wrong");
      } finally {
        setDuplicating(false);
      }
    }
  };

  const createEditDropdown = (data) => [
    {
      icon: <CampaignPopoutCogSVG />,
      text: "Edit Campaign Detail",
      action: () => handleCreate(data),
    },
    {
      icon: <CampaignSwitchIconSVG />,
      text: `Turn ${campaign.status === "dormant" ? "On" : "Off"} Campaign`,
      action: () => handleEditCampaignStatus(data),
    },
    {
      icon: <CampaignPopoutDuplicateSVG />,
      text: "Duplicate Campaign",
      action: () => handleDuplicateCampaign(data),
    },
    {
      icon: <CampaignPopoutDeleteSVG />,
      text: "Delete Campaign",
      action: () => handleConfirmDelete(data),
    },
  ];

  useEffect(() => {
    if (!active_tab) {
      history.push(`/campaign-details/${id}/popouts`);
    } else {
      setSelectedTabs(active_tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      if (_.isEmpty(campaign)) {
        getCampaign(id);
      }
      setPopup(POPUP_INITIALS);
      setSnapshot({});
      setSelectedTab("basic");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const url =
    _.toLower(campaign?.website_url).includes("https") ||
    _.toLower(campaign?.website_url).includes("http")
      ? campaign?.website_url
      : `https://${campaign?.website_url}`;

  const checkScroll = () => {
    let header = document.getElementById("sticky-header");
    let btn = document.getElementById("back-button");
    let bound = header.getBoundingClientRect();
    let sticky = bound.top;
    if (sticky === 0) {
      header.classList.add("bg-white", "pt-4", "border-b", "border-thBorder");
      btn.classList.add("hidden");
    } else {
      header.classList.remove(
        "bg-white",
        "pt-4",
        "border-b",
        "border-thBorder"
      );
      btn.classList.remove("hidden");
    }
  };

  return (
    <Layout
      title="Campaigns"
      routeName="campaign"
      noPadding={true}
      scrollYPosition={checkScroll}
    >
      <div className="w-full py-8">
        {/* start: header */}
        {notFound ? (
          <div
            className="w-full flex justify-center"
            style={{ height: "65vh" }}
          >
            <div className="my-auto">
              <p className="text-center text-4xl opacity-70">
                This campaign cannot be found.
                <br />
                Please return to the campaigns dashboard here.
              </p>
              <div className="mt-1 w-full flex justify-center">
                <Button
                  buttonName="Back to Campaign"
                  linkClass=""
                  buttonTextClass="text-secondary"
                  type="link"
                  path="/campaigns"
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="w-full sticky top-0 z-10" id="sticky-header">
              <div className="relative popout-wrapper mx-auto flex-1 px-7">
                {/*  */}
                <div className="absolute -left-14" id="back-button">
                  <Button
                    buttonClass="bg-transparent"
                    buttonType="transparent"
                    icon={<CampaignBackCircleSVG className="w-10 h-10" />}
                    iconOnly
                    onClick={() => history.push("/campaigns")}
                  />
                </div>
                <div className="w-full flex items-center justify-between">
                  {!_.isEmpty(campaign?.title) ? (
                    <div className="flex flex-col 2md:w-15/20">
                      <h1 className="flex items-center text-2xl flex-wrap">
                        {state.isEditTitle ? (
                          <div className="w-full md:w-450">
                            <Input
                              inputContainerClass="form-input-container"
                              value={state.title || ""}
                              placeholder="Enter title here..."
                              name="title"
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  handleUpdateTitle("save");
                                }
                              }}
                              autoFocus
                              type="text"
                              onChange={(e) =>
                                setState({ ...state, title: e.target.value })
                              }
                            />
                          </div>
                        ) : (
                          <>
                            <p>
                              {_.truncate(campaign?.title, {
                                length: 100,
                                separator: "...",
                              }) || "Untitled"}
                            </p>
                            <span
                              className={`px-2 py-1 mx-3 text-sm font-medium text-white rounded-md ${
                                campaign?.status === "active"
                                  ? "bg-lightGreen"
                                  : "bg-lightRed"
                              }`}
                            >
                              {campaign?.status === "active"
                                ? "Active"
                                : "Inactive"}
                            </span>
                          </>
                        )}
                        {state.isEditTitle ? (
                          <div className="ml-4 flex flex-row items-center space-x-3">
                            <button onClick={() => handleUpdateTitle("cancel")}>
                              <CloseIconSVG className="text-darkGray w-4 h-4 cursor-pointer" />
                            </button>

                            <button onClick={() => handleUpdateTitle("save")}>
                              {state.quickEditLoading ? (
                                <LoadingIndicator className="w-5 h-5 text-secondary" />
                              ) : (
                                <CheckboxSVG className="text-green w-5 h-5 cursor-pointer" />
                              )}
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() =>
                              setState({
                                ...state,
                                isEditTitle: !state.isEditTitle,
                              })
                            }
                          >
                            <CampaignDetailsPencilSVG className="h-5 w-5 cursor-pointer" />
                          </button>
                        )}
                      </h1>
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-secondary whitespace-nowrap overflow-hidden"
                      >
                        {url}
                      </a>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <div className="mr-2">
                        <Skeleton width={200} height={35} />
                      </div>
                      <div className="flex-1">
                        <Skeleton width={200} height={20} />
                      </div>
                    </div>
                  )}
                  <div className="flex items-center">
                    <p className="mr-3 text-sm font-extralight text-mutedGray">
                      Last saved {moment(campaign?.updated_at).fromNow()}
                    </p>
                    {duplicating ? (
                      <LoadingIndicator className="w-5 h-5 text-secondary" />
                    ) : (
                      <CampaignDetailsButton
                        className="bg-white"
                        dropdown={createEditDropdown(campaign)}
                      >
                        Options
                        <FaChevronDown className="ml-2" />
                      </CampaignDetailsButton>
                    )}
                  </div>
                </div>
                {/*  */}
                {/* start:tabs */}
                <nav className="flex mt-6">
                  <div className="relative">
                    {tabs.map((item, index) => {
                      return (
                        <TabSelector
                          key={index}
                          isActive={selectedTab === item.tab}
                          onClick={() => toggle(item.tab)}
                        >
                          {item.name}
                        </TabSelector>
                      );
                    })}
                  </div>
                </nav>
                {/* end:tabs */}
              </div>
            </div>
            {/* end: header */}
            {/* start: content */}
            <div className="mt-6 relative popout-wrapper mx-auto px-7">
              {tabs.map((item, index) => {
                return (
                  <TabPanel key={index} hidden={selectedTab !== item.tab}>
                    {item.component}
                  </TabPanel>
                );
              })}
            </div>
            {/* end: content */}
          </>
        )}
      </div>
    </Layout>
  );
}
