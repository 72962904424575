import { combineReducers, createStore, applyMiddleware } from "redux";
import subscribeActionMiddleware from "redux-subscribe-action";
import UserReducer from "./modules/user/reducer";
import ContactsReducer from "./modules/contacts/reducer";
import NotificationReducer from "./modules/notification/reducer";

const allReducers = combineReducers({
  user: UserReducer,
  contacts: ContactsReducer,
  notifications: NotificationReducer,
});

const store = createStore(
  allReducers,
  applyMiddleware(subscribeActionMiddleware)
);
export default store;
