import React from "react";

const InstallerCopySVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 13C10.4295 13.5741 10.9774 14.0491 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9548 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.5521 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 11.0002C13.5706 10.4261 13.0227 9.95104 12.3935 9.60729C11.7643 9.26353 11.0685 9.05911 10.3534 9.00789C9.63822 8.95667 8.92043 9.05986 8.24867 9.31044C7.57691 9.56103 6.9669 9.95316 6.46002 10.4602L3.46002 13.4602C2.54923 14.4032 2.04525 15.6662 2.05665 16.9772C2.06804 18.2882 2.59388 19.5423 3.52092 20.4693C4.44796 21.3964 5.70203 21.9222 7.01301 21.9336C8.32399 21.945 9.58701 21.441 10.53 20.5302L12.24 18.8202"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InstallerCopySVG;
