const AddIconCircleSVG = ({ className = "" }) => (
  <svg
    className={className}
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1462_27794)">
      <circle cx="19" cy="17" r="15" fill="white" />
    </g>
    <path d="M25.5 17.25L13 17.25" stroke="black" strokeWidth="2" />
    <path d="M19.25 23.5L19.25 11" stroke="black" strokeWidth="2" />
    <defs>
      <filter
        id="filter0_d_1462_27794"
        x="0"
        y="0"
        width="38"
        height="38"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1462_27794"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1462_27794"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default AddIconCircleSVG;
