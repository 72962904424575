function timeToSeconds(time) {
  if (!time) return "";

  const hms = time; // your input string
  const a = hms.split(":"); // split it at the colons

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  const seconds = +a[0] * 60 * 60 + +a[1] * 60;
  return seconds;
}

export const calculatePercentage = (partOf, total) =>
  total ? Number(((partOf / total) * 100).toFixed(2)) : 0;

export { timeToSeconds };
