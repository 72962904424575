const SwitchBelowIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.93816 14.1126C4.48729 13.7265 6.69179 11.8771 7.40154 9.22824C8.29928 5.87781 6.48745 2.43978 3.30967 1.24139"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66047 11.4192L1.89879 14.2618C1.87124 14.3646 1.88566 14.4741 1.93888 14.5663C1.99209 14.6585 2.07975 14.7257 2.18255 14.7533L5.02518 15.515"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SwitchBelowIconSVG;
