import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import PopupPreview from "components/campaigns/preview/popup";
import { PhoneCase } from "constants/images";
import { Context } from "context/context";
import { useWindowDimensions } from "utils/customhooks";
import { getIcon } from "assets/icons/icons-list";
import {
  popupTransition,
  contentTransition,
  buttonTransistion,
  backdropTransition,
} from "components/campaigns/preview/transitions";
import toast from "react-hot-toast";
import PreviewLayout from "layouts/preview-layout";
import ContentItem from "components/campaigns/preview/component/content/content-item";
import Content from "components/campaigns/preview/component/content/content";
import ContactPreview from "components/campaigns/preview/component/contact-details/contacts";
import TemplatePreview from "components/campaigns/preview/component/template/template";
import MobileNav from "components/campaigns/preview/component/mobile-nav/mobile-nav";
import Floater from "components/campaigns/preview/floater";
import PopupExampleMobileTemplate from "components/campaigns/popup-example/mobile/popup-example-mobile-template";
import PopupExampleTab from "components/campaigns/popup-example/popup-example-tab";
import mobileTop from "assets/images/mobile-top-view-black.png";
import PopupIcon from "components/campaigns/preview/component/commons/popup-icon";
import InvisibleDiv from "components/campaigns/preview/component/commons/invisible-div";
import PopupText from "components/campaigns/preview/component/commons/popup-text";
import ArrowPreview from "assets/svg/arrow";
import PopupIconMobile from "components/campaigns/preview/component/commons/popup-icon-mobile";
import PopupMobile from "components/campaigns/preview/popup-mobile";
import styled from "styled-components";
import PhoneCaseSide from "assets/images/phone_side.png";
import Backdrop from "components/campaigns/preview/component/commons/backdrop";

import _ from "lodash";
import "./styles.css";
import "../../components/campaigns/popup-example/popup-example.css";

const MotoinP = styled(motion.p)`
  padding-top: 10px;
  margin: 0;
  font-family: inherit;
  color: inherit;
`;

const ImgDesktop = styled.img`
  display: block !important;
`;

export default function PreviewCampaign() {
  let { id } = useParams();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const { http } = global.services;
  const {
    mobileTabs,
    popup,
    setPopup,
    setView: setMobile,
    mobileContentOpen,
    setMobileContentOpen,
    setSlider,
    setSelectedTab,
  } = useContext(Context);
  const [overlay, toggleOverlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("web");
  const [containerWidth, setContainerWidth] = useState(120);
  const [timer, setTimer] = useState(null);
  const halfWidth = containerWidth / 2;
  const [peepPercent, setPeepPercent] = useState(0);

  const popupWidth =
    popup?.tab_type === "image"
      ? popup?.tab_image_size
      : popup?.container_width;
  const isPeep = popup?.effects === "peep";

  const handleMouseEnter = () => {
    if (popup?.popup_trigger === "hover") {
      // Set a timer for 0.8 seconds
      const newTimer = setTimeout(() => {
        // set overlay to true
        toggleOverlay(true);
      }, 800);
      setTimer(newTimer);
    }
  };

  const handleMouseLeave = () => {
    // Clear the timer if it exists
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  };

  useEffect(() => {
    if (overlay && timer) {
      clearTimeout(timer);
      setTimer(null);
    }
  }, [overlay, timer]);

  //get campaign object if id is present
  const getCampaign = async () => {
    setLoading(true);
    try {
      const result = await http.get(`popups/${id}`);
      const data = result.data.data;
      if (data) {
        setPopup((prev) => ({ ...prev, ...data.campaign, ...data }));
        setContainerWidth(data?.container_width);
        if (data?.effects === "red_carpet") {
          toggleOverlay(true);
        }
        //set for image in mobile view
        if (data.image?.width) {
          let imageH = parseInt(data.image?.width || 0);
          let maxSlider = 230;
          if (imageH < maxSlider) {
            setSlider(imageH);
          } else {
            setSlider(maxSlider);
          }
        }
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const showIcon = (icon) => {
    const { id } = JSON.parse(icon);
    const Icon = getIcon(id);

    return (
      Icon && (
        <Icon
          className="icon-svg m-auto"
          fill="#000"
          sx={{
            fontSize: 18,
            color: "#000",
          }}
        />
      )
    );
  };

  const curved = (type) => {
    let radius = "0px";
    if (type === "curved") {
      radius = "16px 0px 0px 16px";
    } else if (type === "rounded") {
      radius = "32px 0px 0px 32px";
    }
    return radius;
  };

  const hide = (currentW, image) => {
    let popupW =
      popup.tab_type === "image" && image
        ? popup.tab_image_size
        : containerWidth;
    let checkWidth = overlay ? 1596 : 1456;
    let value = 0;
    if (currentW >= checkWidth || currentW - 1000 <= popupW) {
      value = 0;
    } else {
      value = Math.abs(currentW - checkWidth);
    }
    return value;
  };

  const onToggleOverlay = () => {
    if (!overlay) {
      setPeepPercent(1000);
    }
    toggleOverlay(!overlay);
  };

  const onToggleView = (value) => {
    if (value === "web") {
      setMobile("desktop");
      setMobileContentOpen(true);
    } else {
      setMobile(value);
      setMobileContentOpen(false);
    }
    setView(value);
  };

  const widthContentItem = () => {
    let w = halfWidth;
    if (popup?.tab_type === "image") {
      if (Number(popup.tab_image_size) < containerWidth) {
        w = halfWidth;
      } else {
        w = Number(popup.tab_image_size) / 2;
      }
    }
    return w;
  };

  const widthMobileNav = () => {
    let w = containerWidth;
    if (popup?.tab_type === "image") {
      if (Number(popup.tab_image_size) < containerWidth) {
        w = containerWidth;
      } else {
        if (Number(popup.tab_image_size) > 500) {
          w = 500;
        } else {
          w = Number(popup.tab_image_size);
        }
      }
    }
    return w;
  };

  const showPopout = () => {
    return loading ? "initial" : "show";
  };

  // outside clicks
  const useOutsideClicks = (ref) => {
    useEffect(() => {
      // set close if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target))
          toggleOverlay(false);
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideClicks(wrapperRef);
  // end: outside clicks

  useEffect(() => {
    if (!id) return;

    getCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    onmousemove = (event) => {
      if (overlay === false) {
        var x = event.pageX;
        var viewport_width = window.innerWidth;
        var third = viewport_width / 3;
        var diff = viewport_width - x;
        var element = document.getElementsByClassName("cc-popout-item");
        if (element && element.length > 0) {
          if (diff < third) {
            const percentPosition = 100 - (diff * 100) / third;
            // we only want to mirror untill the cursor touches the popup
            // percentPosition determines the amount the cursor will still detect the mouse movement to the right
            if (percentPosition <= 65) {
              const actualPeepPercent = Math.round(
                (percentPosition / 65) * 100
              );
              // actualPeepPercent will determine how much the peep will open
              if (actualPeepPercent <= 80) {
                setPeepPercent(actualPeepPercent);
              }
            }
            element[0].classList.add("openup");
            element[0].classList.remove("transition");
          } else {
            setPeepPercent(0);
            element[0].classList.remove("openup");
            element[0].classList.add("transition");
          }
        }
      }
    };

    return () => {
      document.removeEventListener("onmousemove", onmousemove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlay, width, isPeep]);

  const onClickPeepContent = () => {
    // Check first if it's peep and if the popup is not open
    if (isPeep && !overlay && peepPercent < 1000) {
      onToggleOverlay();
    }
  };

  return (
    <PreviewLayout title="Popout Preview">
      <div className="h-screen">
        <div className="relative h-screen">
          {/* floater */}
          <div className="preview-root-left h-full z-20">
            <Floater
              buttonTransistion={buttonTransistion}
              view={view}
              setView={onToggleView}
              onClose={() => {
                setSelectedTab("preview");
                history.goBack();
              }}
            />
          </div>
          {/* floater */}
          {view === "web" && !loading && (
            <ImgDesktop
              src={popup?.desktop_image?.url}
              alt="CC Popout Web Snapshot"
              className="absolute top-0 w-full h-screen object-cover object-top"
            />
          )}

          <div
            className={`preview-root-mobile h-full z-10 ${
              view === "mobile" ? "" : "hidden"
            }`}
          >
            <div
              className="relative w-97 h-792 bg-white bg-center bg-cover mobile-padding"
              style={{ background: `url(${PhoneCase})` }}
            >
              {view === "mobile" && (
                <div className="bg-white relative w-full h-full mobile-rounded overflow-hidden">
                  {!mobileContentOpen && (
                    <div
                      className="w-full h-full mobile-rounded"
                      style={{
                        background: `url(${popup?.mobile_image?.url})`,
                      }}
                    >
                      <div className="w-full preview-mobile-masked popup-root">
                        <img
                          src={mobileTop}
                          alt="mobile-top"
                          className="mobile-image-top"
                        />
                        <PopupExampleTab preview={true} />
                      </div>
                    </div>
                  )}
                  {mobileContentOpen && (
                    <div className="w-full">
                      <PopupExampleMobileTemplate preview={true} />
                    </div>
                  )}
                  {/* image */}
                  <img
                    src={PhoneCaseSide}
                    alt="phone-case"
                    style={{ position: "absolute", top: 9, right: -26 }}
                  />
                </div>
              )}
            </div>
            {/* magiv div */}
            <div
              className="absolute right-0 h-792"
              style={{
                width: "calc(50% - 198px)",
                backgroundColor: "#434261",
              }}
            />
          </div>

          {/* content */}
          {view === "web" && (
            <div className="h-full z-10 cc-preview">
              <Backdrop
                initial="initial"
                animate={overlay ? "show" : "hide"}
                variants={backdropTransition}
              />
              {!loading && (
                <Content
                  initial="initial"
                  variants={
                    contentTransition(
                      popupWidth,
                      popup?.tab_type === "image",
                      popup?.container_width
                    ).parent
                  }
                  animate={
                    overlay
                      ? "show"
                      : view === "mobile"
                      ? "hide_mobile"
                      : isPeep
                      ? `peep_${peepPercent + hide(width)}`
                      : `hide_${hide(width)}`
                  }
                  ref={wrapperRef}
                  mobile={view === "mobile"}
                  layout
                  overlay={overlay}
                  fullW={
                    popup?.tab_type === "image"
                      ? Number(popup?.tab_image_size)
                      : containerWidth
                  }
                  isPeep={isPeep}
                  onClick={onClickPeepContent}
                >
                  <MobileNav
                    width={widthMobileNav()}
                    onClose={onToggleOverlay}
                    mobile={view === "mobile"}
                  />

                  {/* contact details */}
                  <ContentItem
                    className="bg-white z-50"
                    isActive={mobileTabs === "contact-info"}
                    type="contact-info"
                    width={widthContentItem()}
                    mobile={view === "mobile"}
                    fullW={
                      popup?.tab_type === "image"
                        ? Number(popup?.tab_image_size)
                        : containerWidth
                    }
                    overlay={overlay}
                  >
                    {popup?.id && (
                      <ContactPreview
                        onClose={onToggleOverlay}
                        popup={popup}
                        campaign_id={popup.campaign?.id}
                        // mobile={view === "mobile"}
                        overlay={overlay}
                        campaign={
                          popup?.contact_method_content &&
                          JSON.parse(popup.contact_method_content)
                        }
                        containerWidth={containerWidth}
                      />
                    )}
                  </ContentItem>
                  <ContentItem
                    className="bg-white z-50"
                    isActive={mobileTabs === "key-points"}
                    type="key-points"
                    width={widthContentItem()}
                    mobile={view === "mobile"}
                    fullW={
                      popup?.tab_type === "image"
                        ? Number(popup?.tab_image_size)
                        : containerWidth
                    }
                    overlay={overlay}
                  >
                    {popup?.id && (
                      <TemplatePreview
                        popup={popup}
                        overlay={overlay}
                        // mobile={view === "mobile"}
                        campaign={
                          popup?.template_content &&
                          JSON.parse(popup?.template_content)
                        }
                        containerWidth={containerWidth}
                      />
                    )}
                  </ContentItem>
                  {view === "web" && (
                    <PopupPreview
                      onClick={onToggleOverlay}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      variants={popupTransition.parent}
                      initial="initial"
                      animate={showPopout()}
                      background={
                        popup?.tab_type === "image"
                          ? "transparent"
                          : popup?.primary_colour || "#3B3B3B"
                      }
                      radius={curved(popup?.corner_style)}
                      text="white"
                      isImage={popup?.tab_type === "image"}
                      corner_style={popup?.corner_style}
                      primary_colour={popup?.primary_colour}
                      overlay={overlay}
                      effects={popup?.effects}
                      fullW={
                        popup?.tab_type === "image"
                          ? Number(popup?.tab_image_size)
                          : containerWidth
                      }
                    >
                      {/* hide in mobile view */}
                      {overlay && (
                        <InvisibleDiv type="upper">&nbsp;</InvisibleDiv>
                      )}

                      {popup?.tab_type === "image" ? (
                        <img
                          src={popup?.image?.url}
                          style={{ width: `${popup?.tab_image_size}px` }}
                          alt="conversion-cow-popup"
                        />
                      ) : (
                        <>
                          {popup?.icon_position === "above" &&
                            !_.isEmpty(popup?.icon) && (
                              <PopupIcon type="above" radius="100%">
                                {popup?.icon && showIcon(popup?.icon)}
                              </PopupIcon>
                            )}

                          {popup?.quote && (
                            <PopupText
                              mobile={false}
                              color={popup?.tab_font_colour}
                            >
                              {popup.quote}
                            </PopupText>
                          )}

                          {popup?.quote && (
                            <MotoinP>
                              <ArrowPreview
                                color={
                                  popup?.primary_colour
                                    ? popup.tab_font_colour
                                    : "black"
                                }
                              />
                            </MotoinP>
                          )}

                          {popup?.icon_position === "below" &&
                            !_.isEmpty(popup?.icon) && (
                              <PopupIcon type="below" radius="100%">
                                {popup?.icon && showIcon(popup?.icon)}
                              </PopupIcon>
                            )}
                        </>
                      )}
                      {/* hide in mobile view */}
                      {overlay && (
                        <InvisibleDiv type="bottom">&nbsp;</InvisibleDiv>
                      )}
                    </PopupPreview>
                  )}
                </Content>
              )}
              <PopupMobile
                onClick={onToggleOverlay}
                variants={popupTransition.parent}
                initial="initial"
                animate={showPopout()}
                background={
                  popup?.tab_type === "image"
                    ? "transparent"
                    : popup?.primary_colour || "#3B3B3B"
                }
                radius={curved(popup?.corner_style)}
                text="white"
                isImage={popup?.tab_type === "image"}
                fullW={
                  popup?.tab_type === "image"
                    ? Number(popup?.tab_image_size)
                    : containerWidth
                }
              >
                {popup?.tab_type === "image" ? (
                  <img
                    src={popup?.image?.url}
                    style={{ width: `${popup?.tab_image_size}px` }}
                    alt="conversion-cow-popup"
                  />
                ) : (
                  <>
                    {popup?.icon_position === "above" &&
                      !_.isEmpty(popup?.icon) && (
                        <PopupIconMobile type="above" radius="100%">
                          {popup?.icon && showIcon(popup?.icon)}
                        </PopupIconMobile>
                      )}
                    {popup?.quote && (
                      <>
                        <PopupText mobile={true} color={popup?.tab_font_colour}>
                          {popup.quote}
                        </PopupText>
                        <MotoinP>
                          <ArrowPreview
                            color={
                              popup?.primary_colour
                                ? popup.tab_font_colour
                                : "black"
                            }
                          />
                        </MotoinP>
                      </>
                    )}
                    {popup?.icon_position === "below" &&
                      !_.isEmpty(popup?.icon) && (
                        <PopupIconMobile type="below" radius="100%">
                          {popup?.icon && showIcon(popup?.icon)}
                        </PopupIconMobile>
                      )}
                  </>
                )}
              </PopupMobile>
            </div>
          )}
          {/* end content */}
        </div>
      </div>
    </PreviewLayout>
  );
}
