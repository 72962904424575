const RecaptchaEmptySVG = ({ className = "m-auto" }) => {
  return (
    <svg
      className={className}
      width="72"
      height="70"
      viewBox="0 0 72 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1589 32.7385H59.2408V69.7164C59.2408 69.7917 59.2109 69.8639 59.1577 69.9172C59.1044 69.9704 59.0322 70.0003 58.9569 70.0003H11.4429C11.3676 70.0003 11.2954 69.9704 11.2421 69.9172C11.1888 69.8639 11.1589 69.7917 11.1589 69.7164V32.7385Z"
        fill="#EAEAEA"
      />
      <path
        d="M47.1786 18.2473H24.8216L11.1589 32.738H59.2408L47.1786 18.2473Z"
        fill="#C6C6C6"
      />
      <path
        d="M15.7131 12.4514L24.8215 18.2477L11.1589 32.7384L0.80835 24.872L15.7131 12.4514Z"
        fill="#DDDDDD"
      />
      <path
        d="M56.287 12.4514L47.1786 18.2477L59.2408 32.7384L71.1917 24.872L56.287 12.4514Z"
        fill="#DDDDDD"
      />
      <path
        d="M30.6953 12.7473C30.9523 12.6254 31.1523 12.4089 31.2534 12.143C31.3546 11.8771 31.349 11.5825 31.238 11.3206L27.1405 1.65866C27.0825 1.5218 26.9974 1.39809 26.8903 1.29497C26.7832 1.19185 26.6564 1.11145 26.5174 1.0586C26.3785 1.00575 26.2303 0.981546 26.0818 0.987439C25.9332 0.993332 25.7874 1.0292 25.6531 1.09289L24.8637 1.46721C24.7294 1.5309 24.6093 1.62109 24.5108 1.73236C24.4122 1.84363 24.3371 1.97369 24.2901 2.11471C24.2431 2.25573 24.2251 2.4048 24.2372 2.55296C24.2492 2.70112 24.2911 2.84531 24.3603 2.97686L29.2472 12.2644C29.3797 12.5162 29.6043 12.707 29.8742 12.797C30.144 12.8869 30.4382 12.8691 30.6953 12.7473Z"
        fill="#C6C6C6"
      />
      <path
        d="M35.8324 11.8107C36.0184 11.7974 36.1921 11.7128 36.3172 11.5745C36.4423 11.4362 36.5091 11.2549 36.5037 11.0684L36.3013 4.19001C36.2985 4.09258 36.2759 3.99673 36.235 3.90824C36.1942 3.81974 36.1359 3.74042 36.0636 3.67504C35.9913 3.60966 35.9065 3.55957 35.8144 3.52779C35.7222 3.49601 35.6246 3.48319 35.5274 3.4901L34.9559 3.53082C34.8587 3.53774 34.7639 3.56424 34.6772 3.60872C34.5905 3.6532 34.5137 3.71475 34.4514 3.78967C34.3891 3.86459 34.3426 3.95133 34.3146 4.04468C34.2867 4.13804 34.2779 4.23607 34.2889 4.3329L35.0628 11.1707C35.0837 11.356 35.1755 11.5261 35.3189 11.6454C35.4623 11.7647 35.6463 11.8239 35.8324 11.8107Z"
        fill="#C6C6C6"
      />
      <path
        d="M39.7982 14.3536C40.3099 14.6494 41.0283 14.378 41.4267 13.7381L48.501 2.37667C48.9235 1.69795 48.8234 0.870716 48.2807 0.55694L47.5242 0.119642C46.9815 -0.194191 46.2146 0.131681 45.8372 0.836527L39.5193 12.6352C39.1635 13.2997 39.2866 14.0577 39.7982 14.3536Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export default RecaptchaEmptySVG;
