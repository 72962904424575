const ChevronDownSVG = ({ className = "" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50065 8.36768L12.7611 2.10726L11.2892 0.633301L6.50065 5.42497L1.71315 0.633301L0.240234 2.10622L6.50065 8.36768Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronDownSVG;
