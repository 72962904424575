const KeyPointSVG = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5353 0.00352025C2.77115 0.0844842 3.01756 0.144327 3.23934 0.256973C4.18274 0.728676 4.65093 1.84809 4.34115 2.85838C4.02785 3.87923 3.0246 4.54102 1.97911 4.42134C1.01458 4.30869 0.226064 3.5765 0.0430143 2.62605C0.0359739 2.59437 0.018373 2.56269 0.0078125 2.531C0.0078125 2.31979 0.0078125 2.10858 0.0078125 1.89737C0.018373 1.87273 0.0359739 1.84809 0.0430143 1.81993C0.169741 1.15814 0.518237 0.644192 1.10259 0.313295C1.349 0.172488 1.63765 0.102085 1.90519 0C2.11288 0.00352017 2.32409 0.00352025 2.5353 0.00352025ZM3.37662 2.21067C3.3731 1.58408 2.84508 1.05957 2.21848 1.05957C1.58837 1.05957 1.0533 1.59464 1.06034 2.22475C1.06738 2.85134 1.59189 3.37584 2.21848 3.37584C2.8486 3.37584 3.38366 2.84078 3.37662 2.21067Z"
        fill="currentColor"
      />
      <path
        d="M0.0035204 7.52265C0.119686 7.234 0.197129 6.9207 0.355537 6.65669C0.883564 5.78017 1.97482 5.41055 2.93935 5.74496C3.9074 6.07938 4.53399 7.06503 4.42135 8.07532C4.30518 9.13137 3.39697 10.0079 2.3726 10.0501C1.21094 10.0959 0.246413 9.35314 0.0352018 8.25133C0.0281614 8.21965 0.0105605 8.18796 0 8.15628C0.00352018 7.94155 0.0035204 7.73034 0.0035204 7.52265ZM2.20715 8.9976C2.83023 9.00464 3.36177 8.48718 3.37585 7.85707C3.38993 7.22696 2.86191 6.68485 2.22827 6.67781C1.6052 6.67077 1.07013 7.18824 1.05957 7.81483C1.04549 8.44494 1.57352 8.98704 2.20715 8.9976Z"
        fill="currentColor"
      />
      <path
        d="M0.0035204 13.1408C0.119686 12.8522 0.197129 12.5389 0.355537 12.2749C0.883564 11.3983 1.97482 11.0287 2.93935 11.3631C3.9074 11.6975 4.53399 12.6832 4.42135 13.6935C4.30518 14.7495 3.39697 15.6261 2.3726 15.6683C1.21094 15.7141 0.246413 14.9713 0.0352018 13.8695C0.0281614 13.8378 0.0105605 13.8061 0 13.7744C0.00352018 13.5632 0.0035204 13.352 0.0035204 13.1408ZM3.37585 13.4506C3.37233 12.824 2.84783 12.2995 2.22123 12.296C1.59112 12.2925 1.05605 12.8275 1.05957 13.4612C1.06309 14.0877 1.5876 14.6123 2.21419 14.6158C2.84431 14.6193 3.37937 14.0842 3.37585 13.4506Z"
        fill="currentColor"
      />
      <path
        d="M11.8174 0.56665C13.3205 0.56665 14.8236 0.56665 16.3303 0.56665C17.1786 0.56665 17.8686 1.1686 17.9848 2.00992C18.0974 2.809 17.5729 3.59048 16.7738 3.80873C16.64 3.84393 16.4992 3.86506 16.3584 3.86506C13.3276 3.86858 10.2967 3.86858 7.2658 3.86506C6.35056 3.86506 5.6254 3.1223 5.62892 2.20353C5.63244 1.29885 6.36111 0.56665 7.27284 0.56665C8.78652 0.56665 10.3002 0.56665 11.8174 0.56665ZM11.7998 2.81604C13.0741 2.81604 14.3519 2.81604 15.6262 2.81604C15.8621 2.81604 16.0944 2.82308 16.3303 2.81604C16.7632 2.80196 17.0449 2.40418 16.9005 2.00992C16.809 1.75999 16.5837 1.6227 16.2563 1.6227C13.2959 1.6227 10.3319 1.6227 7.37141 1.6227C7.32917 1.6227 7.29044 1.6227 7.2482 1.62622C6.83634 1.66495 6.57937 2.06273 6.72721 2.43938C6.82226 2.6858 7.04403 2.81956 7.36085 2.81956C8.8358 2.81604 10.3178 2.81604 11.7998 2.81604Z"
        fill="currentColor"
      />
      <path
        d="M11.8205 6.18833C13.3236 6.18833 14.8267 6.18833 16.3334 6.18833C17.1817 6.18833 17.8717 6.79732 17.9843 7.63512C18.0935 8.43068 17.576 9.2016 16.784 9.42689C16.6467 9.46561 16.4953 9.48673 16.351 9.48673C13.3201 9.49025 10.2892 9.49025 7.25836 9.48673C6.35368 9.48673 5.625 8.74398 5.625 7.83929C5.625 6.93109 6.35016 6.19185 7.25484 6.18833C8.77556 6.18481 10.2998 6.18833 11.8205 6.18833ZM11.817 7.24086C10.518 7.24086 9.2191 7.24086 7.92016 7.24086C7.70191 7.24086 7.48717 7.23382 7.26892 7.24438C6.8465 7.26199 6.57545 7.6668 6.71978 8.0505C6.81482 8.30044 7.04011 8.43772 7.36397 8.43772C10.3244 8.43772 13.2884 8.43772 16.2489 8.43772C16.2911 8.43772 16.3299 8.43772 16.3721 8.4342C16.7769 8.39548 17.0304 8.00826 16.8966 7.63512C16.8051 7.38167 16.5798 7.24086 16.2559 7.24086C14.7775 7.23734 13.299 7.24086 11.817 7.24086Z"
        fill="currentColor"
      />
      <path
        d="M11.8103 15.1086C10.2931 15.1086 8.7794 15.1121 7.26221 15.1086C6.44553 15.1086 5.77669 14.5207 5.63589 13.6935C5.50916 12.9472 5.97734 12.1657 6.7201 11.9087C6.88907 11.8524 7.07212 11.8137 7.25165 11.8137C10.2896 11.8067 13.3275 11.8067 16.3619 11.8102C17.2665 11.8102 17.9917 12.5529 17.9917 13.4611C17.9917 14.3693 17.263 15.1051 16.3583 15.1086C14.8411 15.1086 13.3275 15.1086 11.8103 15.1086ZM11.8138 12.8592C10.4515 12.8592 9.08566 12.8592 7.72335 12.8592C7.57198 12.8592 7.4171 12.8521 7.26573 12.8627C6.83627 12.8873 6.56169 13.3027 6.73066 13.6864C6.84683 13.954 7.0686 14.056 7.35021 14.056C10.3248 14.056 13.2958 14.056 16.2703 14.056C16.3055 14.056 16.3407 14.056 16.3759 14.0525C16.7878 14.0103 17.0413 13.6125 16.8934 13.2358C16.7984 12.9965 16.5801 12.8592 16.2774 12.8592C14.7883 12.8592 13.3028 12.8592 11.8138 12.8592Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default KeyPointSVG;
