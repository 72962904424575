import styled from "styled-components";

const FormContainer = styled.div`
  width: 100%;
  margin-top: 24px;

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-top: 28px;
  }
`;
export default FormContainer;
