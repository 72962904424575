import { Context } from "context/context";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import Promotion from "./add-content/promotions";
import ProofPoints from "./add-content/proof-points";
import StatisticsData from "./add-content/statistics-data";
import Testimonials from "./add-content/testimonials";
import Videos from "./add-content/videos";

const CampaignChooseTemplatedDetails = () => {
  const { popup, setPopup, setSelectedTab, setMobileTabs } =
    useContext(Context);
  const { http } = global.services;
  const [state, setState] = useState({
    loading: false,
    validationErrors: {},
  });

  const handleOnSave = async (content) => {
    setState((prev) => ({ ...prev, loading: true, validationErrors: {} }));
    const params = {
      template_content: JSON.stringify(content),
    };

    try {
      if (popup?.id) {
        setPopup({
          ...popup,
          template_content: params?.template_content,
        });
        await http.put(`popups/${popup?.id}`, params);
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));

      toast.error("It appears that something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleChangeStep = () => {
    setSelectedTab("contactM");
    setMobileTabs("key-points");
  };

  return (
    <div className="w-full pb-7">
      {popup.template_type === "proof-points" && (
        <ProofPoints
          handleChangeStep={handleChangeStep}
          handleOnSave={(template) => handleOnSave(template)}
          setState={setState}
          {...state}
        />
      )}
      {popup.template_type === "testimonials" && (
        <Testimonials
          handleChangeStep={handleChangeStep}
          handleOnSave={(template) => handleOnSave(template)}
          setState={setState}
          {...state}
        />
      )}
      {popup.template_type === "videos" && (
        <Videos
          handleChangeStep={handleChangeStep}
          handleOnSave={(template) => handleOnSave(template)}
          setState={setState}
          {...state}
        />
      )}
      {popup.template_type === "promotions" && (
        <Promotion
          handleChangeStep={handleChangeStep}
          handleOnSave={(template) => handleOnSave(template)}
          setState={setState}
          {...state}
        />
      )}
      {popup.template_type === "statistics-data" && (
        <StatisticsData
          handleChangeStep={handleChangeStep}
          handleOnSave={(template) => handleOnSave(template)}
          setState={setState}
          {...state}
        />
      )}
    </div>
  );
};

export default CampaignChooseTemplatedDetails;
