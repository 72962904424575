import React from "react";
import _ from "lodash";
import LoadingIndicator from "assets/svg/loading-indicator";
// import Checkbox from "components/checkbox/checkbox";
import Pagination from "react-responsive-pagination";
import SortIconSVG from "assets/svg/sort-icon";

const thClass =
  "px-8 py-5 border-b border-t border-thBorder bg-thBg font-normal text-sm text-black ";
const tdClass = "px-8 pt-6 pb-7 border-b border-thBorder bg-white text-sm";

const emptyFunc = () => {
  return null;
};

export default function LeadsTable({
  columns = [],
  dataSource = [],
  loading = false,
  metadata = {},
  onPageChange = () => {},
  onHandleChangeSorting = emptyFunc,
  onClickRow = () => {},
}) {
  const handleSort = (enable, key) => {
    if (!enable) {
      onHandleChangeSorting(key);
    }
  };

  return (
    <div className="px-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
      <div className="inline-block min-w-full shadow max-w-full overflow-x-auto">
        <table
          className="min-w-full leading-normal overflow-x-auto"
          style={{
            maxWidth: "1425px",
          }}
        >
          <thead>
            <tr>
              {(columns || []).map((value) => {
                let {
                  title,
                  key,
                  width,
                  align = "left",
                  hideSorting = true,
                  thNowrap = false,
                } = value;
                return (
                  <th
                    key={key}
                    className={thClass + `text-${align}`}
                    width={width}
                  >
                    <div
                      className={`flex flex-row items-center space-x-2 cursor-pointer  ${
                        thNowrap && "whitespace-nowrap"
                      }`}
                      onClick={() => handleSort(hideSorting, key)}
                    >
                      <p
                        className={`opacity-70  ${
                          thNowrap && "whitespace-nowrap"
                        }`}
                      >
                        {title}
                      </p>
                      {!hideSorting && <SortIconSVG className="" fill="gray" />}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td
                  className={tdClass + `text-center`}
                  colSpan={_.size(columns) + 1}
                  align="center"
                >
                  <LoadingIndicator className="w-7 h-7 text-secondary" />
                </td>
              </tr>
            )}
            {_.isEmpty(dataSource) && !loading && (
              //empty
              <tr>
                <td
                  className={tdClass + `text-center`}
                  colSpan={_.size(columns) + 1}
                >
                  <p className="text-base text-black opacity-40 text-center">
                    No Data
                  </p>
                </td>
              </tr>
            )}
            {!loading &&
              (dataSource || []).map((record, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => onClickRow(record)}
                    className="cursor-pointer"
                  >
                    {/* <td className="pl-4 pt-6 pb-7 border-b border-thBorder bg-white text-base">
                      <Checkbox
                        name={`check-element-${index}`}
                        checked={checkedRows(record.id)}
                        onChange={(e) =>
                          handleSelectRow(e.target.checked, record)
                        }
                      />
                    </td> */}
                    {(columns || []).map((column) => {
                      let {
                        key,
                        align = "center",
                        dataIndex,
                        tdNowrap = false,
                        render,
                      } = column;
                      if (render) {
                        return (
                          <td
                            key={key}
                            className={
                              tdClass +
                              `text-${align} ${tdNowrap && "whitespace-nowrap"}`
                            }
                          >
                            {render(record)}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={key}
                            className={
                              tdClass +
                              `text-${align} ${tdNowrap && "whitespace-nowrap"}`
                            }
                          >
                            <p
                              className={`text-sm ${
                                tdNowrap && "whitespace-nowrap"
                              }`}
                            >
                              {record[dataIndex]}
                            </p>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
          {!_.isEmpty(metadata) && (
            <tfoot>
              <tr>
                <td
                  className={thClass + " text-base"}
                  colSpan={columns.length + 1}
                >
                  <div className="flex items-center justify-between">
                    <p>
                      Showing{" "}
                      <span className="text-medium">{metadata.from}</span> to{" "}
                      <span className="text-medium">{metadata.to}</span> of{" "}
                      <span className="text-medium">{metadata.total}</span>{" "}
                      results
                    </p>
                    <div className="w-7/20 flex justify-end">
                      {!_.isEmpty(dataSource) && (
                        <Pagination
                          total={metadata.lastPage}
                          current={metadata.currentPage}
                          onPageChange={(page) => onPageChange(page)}
                          previousLabel="Previous"
                          nextLabel="Next"
                        />
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
}
