import { Context } from "context/context";
import { useContext } from "react";
import CampaignPopupContact from "../popup/campaign-popup-contacts";
import "./popup-example.css";
import CalendyEventPopout from "./templates/contact-method/calendy-event-popout";
import EnquiryPopout from "./templates/contact-method/enquiry-popout";
import LeadMagnetPopout from "./templates/contact-method/lead-magnet-popout";
import MultipleAddressesPopout from "./templates/contact-method/multiple-address-popout";
import StandardPopout from "./templates/contact-method/standard-popout";

export default function PopupExampleContactMethod({ viewExample = false }) {
  const { popup, selectedTab } = useContext(Context);

  return (
    <div
      className={
        viewExample
          ? "hidden 4md:block 4md:relative 4md:h-full 5md:hidden 3lg:block 3lg:relative 3lg:h-full"
          : "relative h-full"
      }
      style={{ width: "370px" }}
    >
      {(selectedTab === "contactM" || selectedTab === "contactD") && (
        <CampaignPopupContact
          type={popup?.contact_method_type || "standard-details"}
        />
      )}
      <div className="popup-contact-method slideIn mobile-scroll rounded-br-xl">
        <div className="absolute z-10 right-2.5 top-2.5">
          <button className="flex justify-end">
            <span className="close opacity-100 w-2.5" />
          </button>
        </div>
        {viewExample && !popup?.contact_method_type && (
          <>
            {/* masked */}
            <div className="absolute top-0 right-0 contacts-bg w-full h-full"></div>
            {/* end: masked */}
            <StandardPopout popup={popup} viewExample />
          </>
        )}
        {popup?.contact_method_type === "standard-details" && (
          <>
            {viewExample && (
              <div className="absolute top-0 right-0 contacts-bg w-full h-full"></div>
            )}
            <StandardPopout popup={popup} />
          </>
        )}
        {popup?.contact_method_type === "enquiry-form" && (
          <>
            {viewExample && (
              <div className="absolute top-0 right-0 contacts-bg w-full h-full"></div>
            )}
            <EnquiryPopout popup={popup} />
          </>
        )}
        {popup?.contact_method_type === "lead-magnet" && (
          <>
            {viewExample && (
              <div className="absolute top-0 right-0 contacts-bg w-full h-full"></div>
            )}
            <LeadMagnetPopout popup={popup} />
          </>
        )}
        {popup?.contact_method_type === "multiple-addresses" && (
          <>
            {viewExample && (
              <div className="absolute top-0 right-0 contacts-bg w-full h-full"></div>
            )}
            <MultipleAddressesPopout popup={popup} />
          </>
        )}
        {popup?.contact_method_type === "calendy-event-scheduler" && (
          <>
            {viewExample && (
              <div className="absolute top-0 right-0 contacts-bg w-full h-full"></div>
            )}
            <CalendyEventPopout popup={popup} />
          </>
        )}
      </div>
    </div>
  );
}
