import { useMemo } from "react";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "./actions";

export const useNotifications = () => {
  const notifications = useSelector((state) =>
    state.notifications.push.map((notif) => ({
      ...notif,
      isRead: state.notifications.users.includes(notif.id),
    }))
  );
  const hasUnreadNotification = useMemo(
    () => notifications.some((notif) => !notif.isRead),
    [notifications]
  );
  const banner = useSelector((state) => state.notifications.banner);
  const dispatch = useDispatch();

  const setNotifications = useCallback(
    (payload) => dispatch(actions.setNotifications(payload)),
    [dispatch]
  );

  const addNotification = useCallback(
    (payload) => dispatch(actions.addNotification(payload)),
    [dispatch]
  );

  const setNotificationBanner = useCallback(
    (payload) => dispatch(actions.setNotificationBanner(payload)),
    [dispatch]
  );

  const setNotificationUserReads = useCallback(
    (payload) => dispatch(actions.setNotificationUserReads(payload)),
    [dispatch]
  );

  const addNotificationUserRead = useCallback(
    (payload) => dispatch(actions.addNotificationUserRead(payload)),
    [dispatch]
  );

  return {
    notifications,
    hasUnreadNotification,
    banner,
    setNotifications,
    addNotification,
    setNotificationBanner,
    setNotificationUserReads,
    addNotificationUserRead,
  };
};
