export const sendClickCheckout = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "checkoutButtonClick",
  });
};

export const sendCheckoutSuccessful = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "checkoutSuccess",
  });
};

export const sendVirtualPageview = (pathname, document) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "virtualPageview",
    pageUrl: pathname,
    pageTitle: document.title,
  });
};
