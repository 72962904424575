import React from "react";
import CloseIconSVG from "assets/svg/campaign/close-icon";
import {
  leadDetailTransition,
  backdropTransition,
} from "../../preview/transitions";
import LeadDetailContent from "./content";
import "./styles.css";
import Backdrop from "./backdrop";
import _ from "lodash";

export default function LeadDetail({
  overlay = false,
  toggleOverlay = () => {},
  data = {},
}) {
  const condition = JSON.parse(data?.campaign_popup?.popup_condition || "{}");
  return (
    <>
      <div className="preview-lead-details h-full">
        <Backdrop
          initial="initial"
          animate={overlay ? "show" : "hide"}
          variants={backdropTransition}
          onClick={() => toggleOverlay(!overlay)}
          zIndex="z-9999"
        ></Backdrop>
        <LeadDetailContent
          initial="initial"
          animate={overlay ? "show" : "hide"}
          variants={leadDetailTransition.parent}
        >
          {/*start content*/}
          <div className="lead-details-content-item overflow-y-auto custom-scroll">
            {/* title */}
            <div className="w-full flex justify-between">
              <div className="flex items-center">
                {_.isEmpty(data.dropdown) ? (
                  <h3 className="text-xl font-medium">Lead Detail</h3>
                ) : (
                  <h3 className="text-xl font-medium">Contact Detail</h3>
                )}
              </div>
              <button onClick={() => toggleOverlay(!overlay)}>
                <CloseIconSVG />
              </button>
            </div>
            {/* content */}
            <div className="w-full mt-7">
              <div className="w-full grid grid-cols-2 gap-y-8 gap-x-5">
                <div className="w-full">
                  <p className="font-bold text-base text-black text-opacity-50">
                    Name
                  </p>
                  <p>{data?.firstname}</p>
                </div>
                <div className="w-full">
                  <p className="font-bold text-base text-black text-opacity-50">
                    Email Address
                  </p>
                  <p>{data?.email}</p>
                </div>
                {data?.phone && (
                  <div className="w-full">
                    <p className="font-bold text-base text-black text-opacity-50">
                      Phone Number
                    </p>
                    <p>{data?.phone}</p>
                  </div>
                )}
              </div>
              {data?.message && (
                <div className="w-full my-8">
                  <p className="font-bold text-base text-black text-opacity-50">
                    Message
                  </p>
                  <p className="break-all">{data?.message}</p>
                </div>
              )}
              <div
                className={`w-full grid grid-cols-2 gap-y-8 gap-x-5 ${
                  data?.message ? "" : "mt-8"
                }`}
              >
                <div className="w-full">
                  <p className="font-bold text-base text-black text-opacity-50">
                    Special Condition
                  </p>
                  <p>{_.isEmpty(condition) ? "--" : condition?.title}</p>
                </div>
                <div className="w-full">
                  <p className="font-bold text-base text-black text-opacity-50">
                    Popout
                  </p>
                  <p>{data?.popup?.title}</p>
                </div>
                {data.dropdown && (
                  <div className="w-full">
                    <p className="font-bold text-base text-black text-opacity-50">
                      Enquiry Type
                    </p>
                    <p>{data.dropdown}</p>
                  </div>
                )}
                <div className="w-full">
                  <p className="font-bold text-base text-black text-opacity-50">
                    Date Subscribed
                  </p>
                  <p>{new Date(data?.created_at).toLocaleString()}</p>
                </div>
              </div>
            </div>
            {/* end: content */}
          </div>
        </LeadDetailContent>
      </div>
    </>
  );
}
