import { getIcon } from "assets/icons/icons-list";
import { device } from "components/campaigns/preview/screens";
import styled from "styled-components";

const ProofPointsCardContainer = styled.div`
  display: grid !important;
  width: 100% !important;
`;
const ProofPointsItemIcon = styled.div`
  ${({ invisible }) => (invisible ? `display: none !important;` : "")}

  height: 60px !important;
  width: 60px !important;

  @media ${device.xs} {
    height: 70px !important;
    width: 70px !important;
  }

  @media (min-width: 1${({ width }) => width + 1}px) {
    height: 80px !important;
    width: 80px !important;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
const ProofPointsCardTitle = styled.div`
  margin: 0 !important;

  font-size: 16px !important;
  line-height: 21px !important;
  font-weight: 700 !important;

  letter-spacing: -0.03em !important;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747 !important;

  @media ${device.xs} {
    font-size: 20px !important;
    line-height: 26px !important;
  }

  @media (min-width: 1${({ width }) => width + 1}px) {
    font-size: 24px !important;
    line-height: 31px !important;
  }
`;
const ProofPointsCardSubtitle = styled.p`
  margin: 0 !important;

  font-size: 14px !important;
  line-height: 22px !important;

  letter-spacing: -0.01em !important;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747 !important;

  @media ${device.xs} {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  @media (min-width: 1${({ width }) => width + 1}px) {
    font-size: 18px !important;
    line-height: 27px !important;
  }
`;
const ProofPointsResponsive = styled.div`
  width: 100% !important;
  margin-top: 24px !important;
  display: grid !important;
  row-gap: 8px !important;
`;

const ProofPointsCard = ({ width, color, point }) => {
  const Icon = getIcon(point.icon?.id);

  return (
    <ProofPointsCardContainer width={width}>
      <ProofPointsItemIcon width={width} color={color} invisible={!point.icon}>
        {Icon && (
          <Icon
            fill={color ? color : "#000000"}
            sx={{
              color: color ? color : "#000000",
            }}
          />
        )}
      </ProofPointsItemIcon>
      <ProofPointsResponsive width={width}>
        <ProofPointsCardTitle width={width}>{point.title}</ProofPointsCardTitle>
        <ProofPointsCardSubtitle width={width}>
          {point.subtext}
        </ProofPointsCardSubtitle>
      </ProofPointsResponsive>
    </ProofPointsCardContainer>
  );
};

export default ProofPointsCard;
