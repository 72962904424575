const CrownIconSVG = ({ className = "" }) => (
  <svg
    className={className}
    viewBox="0 0 39 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3219 2.38989L10.9319 9.08589L17.7119 0.737888C17.9018 0.504509 18.1418 0.316967 18.4142 0.18923C18.6866 0.0614921 18.9843 -0.00314981 19.2851 0.000118002C19.5859 0.00338581 19.8821 0.0744795 20.1517 0.208104C20.4212 0.341728 20.6572 0.53444 20.8419 0.771888L27.3119 9.08389L35.1679 2.29189C35.4764 2.02571 35.8589 1.86011 36.2641 1.81725C36.6693 1.7744 37.0779 1.85633 37.4353 2.05208C37.7926 2.24784 38.0817 2.54808 38.2637 2.91262C38.4458 3.27716 38.5121 3.6886 38.4539 4.09189L35.4539 24.7859H3.0679L0.0218981 4.18589C-0.0386075 3.78036 0.0269878 3.36603 0.209799 2.99903C0.39261 2.63202 0.683803 2.33007 1.04394 2.13407C1.40407 1.93807 1.81575 1.85749 2.22321 1.90325C2.63066 1.949 3.01421 2.11889 3.3219 2.38989ZM3.2239 26.7859H35.2239V28.7859C35.2239 29.3163 35.0132 29.825 34.6381 30.2001C34.263 30.5752 33.7543 30.7859 33.2239 30.7859H5.2239C4.69347 30.7859 4.18476 30.5752 3.80968 30.2001C3.43461 29.825 3.2239 29.3163 3.2239 28.7859V26.7859Z"
      fill="#B4B4B4"
    />
  </svg>
);

export default CrownIconSVG;
