import React, { useCallback, useState } from "react";
import PopupComponent from "components/popup/popup";
import PopupContent from "components/popup/popup-content";
import { AiOutlineSearch } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import _ from "lodash";
import IconList from "../icons/icon-list";
import { IconsList, filterList } from "assets/icons/icons-list";

const CampaignPopupPopOutIconsComponent = (
  {
    margin = "0px 0px 0px 0px",
    icon = "edit",
    onClick = () => {},
    onClose = () => {},
    className = "absolute p-2 right-3 top-1",
    position,
    iconRef = null,
    inactiveColor = "bg-primary",
  },
  ref
) => {
  const icons = IconsList;

  //filters [sharp-edges, rounded, solid, outline]
  const [filter, setFilter] = useState({
    value: "material",
    label: "Set 1",
  });
  const [search, setSearch] = useState("");
  const [list, setList] = useState(_.filter(icons, ["category", filter.value]));

  const onFilter = useCallback(
    (e, type) => {
      if (type === "category") {
        setList(
          _.filter(icons, function (b) {
            return (
              b.category === e.value &&
              _.toLower(b.name).includes(_.toLower(search))
            );
          })
        );
        setFilter(e);
      } else {
        if (_.isEmpty(e)) {
          setList(_.filter(icons, ["category", filter.value]));
        } else {
          setList(
            _.filter(icons, function (b) {
              return (
                _.toLower(b.name).includes(_.toLower(e)) ||
                _.toLower(b.id).includes(_.toLower(e))
              );
            })
          );
        }
        setSearch(e);
      }
    },
    [filter.value, icons, search]
  );

  return (
    <PopupComponent
      position={position}
      styles={{ marginLeft: 10 }}
      className={className}
      inactiveColor={inactiveColor}
      closeOnDocumentClick={true}
      icon={icon}
      ref={ref}
    >
      <PopupContent
        width="1000px"
        divMargin="0px 0px 0px 10px"
        remainArrow={true}
        margin={margin}
        ref={iconRef}
      >
        {/* content */}
        <div className="pl-6 flex flex-row w-full">
          <div className="border-r border-tabBorder py-4 w-1/6">
            <p className="text-sm font-medium">ICON SET</p>
            <ul className="text-sm mt-3 cursor-pointer">
              <button
                onClick={() => onClick(null)}
                className="bg-primary rounded-full text-white text-sm px-6 py-2 relative duration-300 hover:bg-primaryHover cursor-pointer"
              >
                No Icon
              </button>
              {!search &&
                filterList.map((e, index) => (
                  <li
                    key={index}
                    className={`py-1 ${
                      filter?.value === e?.value && "text-secondary"
                    }`}
                    onClick={() => onFilter(e, "category")}
                  >
                    {e?.label}
                  </li>
                ))}
            </ul>
          </div>
          <div className="w-5/6">
            <div className="relative border-b border-tabBorder pr-6 pl-3 py-1">
              <div className="w-full flex">
                <AiOutlineSearch className="absolute top-3.5 left-4" />
                <input
                  className="w-full py-2 px-8 text-sm"
                  placeholder="Search Icon"
                  onChange={(e) => onFilter(e?.target?.value, "name")}
                  value={search}
                />
                <button onClick={onClose}>
                  <FaTimes className="opacity-50" />
                </button>
              </div>
            </div>
            <div className="pr-6 pl-4 py-2">
              <p className="pt-2">{search ? "All Set" : filter?.label}</p>
              <IconList icons={list} onClick={onClick} />
            </div>
          </div>
        </div>
        {/* content */}
      </PopupContent>
    </PopupComponent>
  );
};

const CampaignPopupPopOutIcons = React.forwardRef(
  CampaignPopupPopOutIconsComponent
);
export default CampaignPopupPopOutIcons;
