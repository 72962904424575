import MailIconSVG from "assets/svg/mail-icon";
import PhoneIconSVG from "assets/svg/phoneIcon";
import PowerByIconSVG from "assets/svg/powered-by-icon";
import SendIconSVG from "assets/svg/send-icon";
import { Context } from "context/context";
import _ from "lodash";
import { useContext } from "react";
import HeaderExample from "../component/header";

const showDummy = ["template", "content", "contactM", "contactD"];

export default function MultipleAddressesPopout({ popup }) {
  const { view, selectedTab } = useContext(Context);
  const mobile = view === "mobile";
  const data =
    popup.contact_method_content && JSON.parse(popup.contact_method_content);
  const show = showDummy.includes(selectedTab);

  return (
    <div
      className={
        mobile
          ? "overflow-y-auto mobile-scroll"
          : "contacts-details-container overflow-y-auto mobile-scroll"
      }
      style={{ height: mobile ? "619px" : "635px" }}
    >
      {!_.isEmpty(data) && (
        <div className="w-full">
          <HeaderExample
            data={data}
            popup={popup}
            show={show}
            className="font-medium"
            placeholder="Call Us At Your Local Branch Closest To You"
          />
          {/* addresses */}
          {(data?.contacts || []).map((obj, index) => {
            let valueShow =
              !_.isEmpty(obj.contact_title?.value) ||
              !_.isEmpty(obj?.address?.value) ||
              !_.isEmpty(obj.email_address?.value) ||
              !_.isEmpty(obj?.contact_number?.value);

            if (show || valueShow) {
              return (
                <div key={index} className="pt-3">
                  <p
                    className={`text-lg font-semibold pb-2 ${
                      !obj.contact_title?.visible && "hidden"
                    } ${!obj.contact_title?.value && "text-tabBorder"}`}
                  >
                    {obj.contact_title?.value || (show && "Melbourne")}
                  </p>
                  <a
                    className={`addresses flex pb-2 ${
                      !obj.address?.visible && "hidden"
                    } ${!obj.address?.value && "text-tabBorder"}`}
                    target="_blank"
                    rel="noreferrer"
                    href={`https://maps.google.com/?q=${obj.address.latitude},${obj.address.longitude}`}
                  >
                    <span className="pr-2">
                      {(obj?.address?.value || show) && (
                        <SendIconSVG
                          className={`w-4 h-4 ${
                            !obj?.address?.value && show ? "opacity-20" : ""
                          }`}
                          fill={
                            popup?.button_colour
                              ? popup.button_colour
                              : "#D1D1D1"
                          }
                        />
                      )}
                    </span>
                    {obj.address?.value ||
                      (show &&
                        "375 Thoroughfare, Lake Sabina, Queensland, 3126")}
                  </a>
                  <p
                    className={`addresses flex pb-2 ${
                      !obj.contact_number?.visible && "hidden"
                    } ${!obj.contact_number?.value && "text-tabBorder"}`}
                  >
                    <span className="pr-2">
                      {(obj?.contact_number?.value || show) && (
                        <PhoneIconSVG
                          className={`w-4 h-4 ${
                            !obj?.contact_number?.value && show
                              ? "opacity-20"
                              : ""
                          }`}
                          fill={
                            popup?.button_colour
                              ? popup.button_colour
                              : "#D1D1D1"
                          }
                        />
                      )}
                    </span>
                    {obj.contact_number?.value || (show && "(03) 9827 2836")}
                  </p>
                  <p
                    className={`addresses flex pb-2 ${
                      !obj.email_address?.visible && "hidden"
                    } ${!obj.email_address?.value && "text-tabBorder"}`}
                  >
                    <span className="pr-2">
                      {(obj?.email_address?.value || show) && (
                        <MailIconSVG
                          className={`w-4 h-4 ${
                            !obj?.email_address?.value && show
                              ? "opacity-20"
                              : ""
                          }`}
                          fill={
                            popup?.button_colour
                              ? popup.button_colour
                              : "#D1D1D1"
                          }
                        />
                      )}
                    </span>
                    {obj.email_address?.value ||
                      (show && "melbourne@conversioncow.com")}
                  </p>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
      {popup?.show_logo && (
        <div className="absolute bottom-5 right-7">
          <PowerByIconSVG className="w-40" />
        </div>
      )}
    </div>
  );
}
