import React from "react";
import styled from "styled-components";
import { device } from "../../screens";

const InputComponent = styled.input`
  width: 100% !important;
  background-color: transparent !important;
  border-width: 1.5px !important;
  border-color: ${({ error }) =>
    error ? "rgb(248, 113, 113)" : "#dbdbdb"} !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;

  font-size: 12px !important;
  line-height: 16px !important;

  letter-spacing: -0.01em !important;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747 !important;
  font-family: inherit !important;

  margin: 0 !important;
  height: fit-content !important;
  outline: none !important;

  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;

  &:focus-visible {
    outline: none !important;
    border-width: 1.5px !important;
  }

  @media ${device.xs} {
    font-size: 14px !important;
    line-height: 18px !important;

    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px !important;
    line-height: 21px !important;

    padding-top: 17.5px !important;
    padding-bottom: 17.5px !important;
  }
`;

const Label = styled.label`
  font-weight: 700 !important;
  letter-spacing: -0.01em !important;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747 !important;
  margin-bottom: 6px !important;

  font-size: 10px !important;
  line-height: 13px !important;

  @media ${device.xs} {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 14px !important;
    line-height: 18px !important;
  }
`;

const Div = styled.div`
  width: 100% !important;
  position: relative !important;
`;

const Error = styled.span`
  color: rgb(248, 113, 113) !important;
  font-size: 12px !important;
  line-height: 16px !important;
`;

const Required = styled.span`
  color: rgb(248, 113, 113) !important;
  font-size: 12px !important;
  line-height: 16px !important;
`;

const Input = (
  { error, withAsterisk = true, className = "", responsive, ...props },
  ref
) => {
  return (
    <Div>
      {props?.label && (
        <Label htmlFor={props?.label} responsive={responsive}>
          {props.label} {withAsterisk && <Required>*</Required>}
        </Label>
      )}
      <InputComponent
        className={`forms-elements ${className}`}
        responsive={responsive}
        {...props}
        ref={ref}
        error={error}
      />
      {error && <Error>{error.message}</Error>}
    </Div>
  );
};

export default React.forwardRef(Input);
