const CampaignEmptySVG = () => {
  return (
    <svg
      width="220"
      height="219"
      viewBox="0 0 220 219"
      className="m-auto"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="110" cy="109.5" r="109.5" fill="#DADADA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.523 168.09C183.105 198.691 148.925 219 110 219C49.5245 219 0.5 169.976 0.5 109.5C0.5 68.1947 23.3702 32.2311 57.1385 13.5815H202.523V168.09Z"
        fill="#EDEDED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8116 27.1626H202.523V39.0463H26.1721C29.747 34.7973 33.639 30.8239 37.8116 27.1626Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.3372 198.602V44.1396H165.174V204.104C148.973 213.573 130.12 219 110 219C86.2557 219 64.2768 211.443 46.3372 198.602Z"
        fill="white"
      />
      <rect
        x="66.7094"
        y="66.2095"
        width="78.093"
        height="15.2791"
        fill="#EC5627"
      />
      <rect
        x="68.407"
        y="89.9766"
        width="50.9302"
        height="6.7907"
        fill="#D2D2D2"
      />
      <rect
        x="85.3837"
        y="106.954"
        width="33.9535"
        height="6.7907"
        fill="#D2D2D2"
      />
      <rect
        x="85.3837"
        y="123.93"
        width="33.9535"
        height="6.7907"
        fill="#D2D2D2"
      />
      <rect
        x="68.407"
        y="139.209"
        width="76.3953"
        height="25.4651"
        fill="#D2D2D2"
      />
      <rect
        x="68.407"
        y="174.86"
        width="37.3488"
        height="11.8837"
        fill="#E7B44B"
      />
      <circle cx="74.3488" cy="111.198" r="4.24419" fill="#D2D2D2" />
      <circle cx="74.3488" cy="128.174" r="4.24419" fill="#D2D2D2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8553 28.0118C40.245 24.9672 43.8257 22.131 47.5773 19.5234H125.279L133.767 28.0118H36.8553Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export default CampaignEmptySVG;
