import "assets/css/components/campaign-tests.css";
import CampaignDetailsClicksSVG from "assets/svg/campaign-details-clicks-icon";
import CampaignDetailsEyeSVG from "assets/svg/campaign-details-eye-icon";
import CampaignDetailsSubmissionsSVG from "assets/svg/campaign-details-submissions-icon";
import { numberWithCommas } from "helpers/numberConverters";
import _ from "lodash";
import numeral from "numeral";
import { useEffect, useState } from "react";

export const ResultsOverview = ({ data = {} }) => {
  const [overview, setOverview] = useState([]);

  const getSummary = () => {
    let opens = 0;
    let clicks = 0;
    let submissions = 0;
    let interactions = 0;

    if (!_.isEmpty(data)) {
      let pop_a = data.reports_a;
      let pop_b = data.reports_b;
      let sumOpens = pop_a.unique_opens + pop_b.unique_opens; //impressions
      let sumClicks = pop_a.unique_clicks + pop_b.unique_clicks; //opens
      let sumSubmissions =
        parseFloat(_.split(pop_a.conversion, "%")[0]) +
        parseFloat(_.split(pop_b.conversion, "%")[0]);
      let sumInteractions =
        pop_a.unique_interactions + pop_b.unique_interactions;
      opens += sumOpens;
      clicks += sumClicks;
      submissions += sumSubmissions;
      interactions += sumInteractions;
    }

    return { opens, clicks, submissions, interactions };
  };

  useEffect(() => {
    const { opens, clicks, submissions, interactions } = getSummary();
    setOverview(() => [
      {
        label: "Unique Visitors",
        total: opens || 0,
        percent: 0,
        icon: <CampaignDetailsEyeSVG />,
      },
      {
        label: "Unique Opens",
        total: clicks || 0,
        percent: 0,
        icon: <CampaignDetailsClicksSVG />,
      },
      {
        label: "Conversion",
        total: `${numeral(submissions).format("0,0.00")}%`,
        percent: 0,
        icon: <CampaignDetailsSubmissionsSVG />,
      },
      {
        label: "Unique Interactions",
        total: interactions,
        percent: 0,
        icon: <CampaignDetailsSubmissionsSVG />,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="w-4/12 border-r border-gray">
      {overview.map((item, index) => {
        return (
          <div
            key={index}
            className={`${
              overview.length > index + 1 && "border-b"
            } border-gray px-8 py-4 flex justify-between`}
          >
            <div>
              <p className="text-xs font-light">{item.label}</p>
              <p className="text-3xl mb-1.5">
                {!item.total
                  ? 0.0
                  : typeof item.total === "string"
                  ? item.total
                  : numberWithCommas(item.total)}
              </p>
              <div className="flex space-x-2 items-center">
                <p className="text-xs font-bold text-nowrap bg-lightGreen text-white px-1 rounded">
                  +{item.percent}%
                </p>
                <p className="text-xs font-light text-nowrap">From yesterday</p>
              </div>
            </div>
            <div className="h-10 w-10 p-2 bg-lightGray rounded-full flex items-center justify-center mt-2">
              {item.icon}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ResultsOverview;
