import CampaignDetailsEyeSVG from "assets/svg/campaign-details-eye-icon";
import CampaignBackCircleSVG from "assets/svg/campaign/back-circle-icon";
import CampaignPopoutCogSVG from "assets/svg/campaign/popout/cog-icon";
import CampaignPopoutDeleteSVG from "assets/svg/campaign/popout/delete-icon";
import CampaignPopoutDuplicateSVG from "assets/svg/campaign/popout/duplicate-icon";
import CampaignPopoutEditSVG from "assets/svg/campaign/popout/edit-icon";
import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import Toggle from "components/toggle/toggle";
import { EmptyWireframe } from "constants/images";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { POPUP_INITIALS } from "utils/constant";
import CampaignDetailsButton from "../button";
import PopoutData from "./popout-data";
import PopoutDetails from "./popout-details";
import PopoutTable from "./popout-table";

import ConfirmModal from "components/confirm/confirm";
import TooltipInfo from "components/tooltip/tooltip-info";
import { calculatePercentage } from "helpers/converter";
import useDebounce, { useDialogHook } from "utils/customhooks";
import { getPopoutImage } from "utils/popout-wireframes";
import { ABTestContent } from "../ab-tests/ab-test-content";
import PreviewDropdown from "./preview-dropdown";

const createEditDropdown = (data, onHandleDropdownAction) => [
  {
    icon: <CampaignPopoutCogSVG />,
    text: "Configure Conditions",
    action: () => onHandleDropdownAction("configure-conditions", data),
  },
  {
    icon: <CampaignPopoutDuplicateSVG />,
    text: "Duplicate Condition",
    action: () => onHandleDropdownAction("duplicate-popout", data),
  },
  data?.popup_a?.status === "active" &&
    data?.popup_b?.status === "active" && {
      icon: <CampaignPopoutEditSVG />,
      text: "Edit Popout A",
      action: () => onHandleDropdownAction("edit-popout", data),
    },
  data?.popup_a?.status === "active" &&
    data?.popup_b?.status === "active" && {
      icon: <CampaignPopoutEditSVG />,
      text: "Edit Popout B",
      action: () => onHandleDropdownAction("edit-popout-b", data),
    },
  !data?.popup_b && {
    icon: <CampaignPopoutEditSVG />,
    text: "Edit Popout",
    action: () => onHandleDropdownAction("edit-popout", data),
  },
  {
    icon: <CampaignPopoutDeleteSVG />,
    text: "Delete",
    action: () => onHandleDropdownAction("delete-popout", data),
  },
];

// let IS_INACTIVATE_MAIN_POPOUT = false;

export default function CampaignPopout() {
  const history = useHistory();
  const { http } = global.services;
  const { id } = useParams();
  const {
    campaign: details,
    setPopup,
    setCampaign,
    setDefaultTab,
    setAlternative,
    popoutOrder,
    setPopoutOrder,
  } = useContext(Context);
  const [isLoading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [content, setContent] = useState({
    type: "popouts",
    details: null,
  });
  const base = details?.campaign_popups?.[0];
  const confirm = useDialogHook(ConfirmModal);
  const popoutOrderList = useDebounce(popoutOrder, 2000);

  const onAddAlternative = () => {
    setPopup(POPUP_INITIALS);
    //sets
    setAlternative({
      campaign_id: id,
      popups: details?.popups || [],
    });
    history.push(`/campaign-details/${id}/manage/special-condition`);
  };

  const onHandleDropdownAction = (type, data = null) => {
    if (!_.isEmpty(data)) {
      if (type === "edit-popout") onEditPopout(data);

      if (type === "edit-popout-b") onEditPopoutB(data);

      if (type === "configure-conditions") onEditAlternative(data);

      if (type === "make-base-popout") onHandleMakeBasePopout(data);

      if (type === "duplicate-popout") onHandleDuplicatePopout(data);

      if (type === "delete-popout") handleConfirmDelete(data);
    }
  };

  const onEditPopout = (data) => {
    setDefaultTab("basic");
    try {
      setTimeout(() => {
        history.push(`/campaigns/${id}/popup/${data?.popup_a_id}`);
      }, 300);
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  };

  const onEditPopoutB = (data) => {
    setDefaultTab("basic");
    try {
      setTimeout(() => {
        history.push(
          `/campaigns/${data?.campaign_id}/popup/${data.popup_b_id}`
        );
      }, 300);
    } catch (e) {
      console.error(e);
      toast.error(JSON.stringify(e));
    }
  };

  const onEditAlternative = (popup) => {
    //set
    setAlternative({
      campaign_id: id,
      popups: details?.popups || [],
      condition: popup.condition,
      popup,
    });
    history.push(`/campaign-details/${id}/manage/special-condition`);
  };

  const onHandleMakeBasePopout = (popup) => {
    const tempPopout = [...details?.campaign_popups];

    popup.order_in = 0; // update selected popup as base popup
    const sourceIndex = tempPopout.findIndex((item) => item.id === popup.id); // selected popup
    const destinationIndex = tempPopout.findIndex(
      (item) => item.order_in === 0
    ); // base popup
    const [selectedRow] = tempPopout.splice(sourceIndex, 1);

    tempPopout.splice(destinationIndex, 0, selectedRow);
    const data = tempPopout.map((obj, index) => ({ ...obj, order_in: index })); // update order_in on each popup from its index

    handleUpdateOrder(data, true);
  };

  const onHandleDuplicatePopout = async (popup) => {
    if (!_.isEmpty(setLoading)) setLoading(true);
    if (popup.id) {
      try {
        const result = await http.post(`campaign-popups/duplicate/${popup.id}`);
        const data = result.data.data;

        // insert duplicated popup under the original popup
        details.campaign_popups?.splice(data.order_in, 0, data);

        setCampaign((prev) => ({
          ...prev,
          campaign_popups: details.campaign_popups,
        }));
      } catch (error) {
        if (error.status === 422)
          toast.error("It appears that something went wrong");
      } finally {
        if (!_.isEmpty(setLoading)) setLoading(false);
      }
    }
  };

  const handleConfirmDelete = (popup) => {
    const payload = {
      title: "Delete Popout",
      buttonText: "YES, DELETE IT",
      description: "Do you want to delete this popout?",
    };
    confirm(payload, (result) => {
      if (result) {
        onHandleDeletePopout(popup);
      }
    });
  };

  const onHandleDeletePopout = async (popup) => {
    // Alerts delete is not allowed if there's only one popout
    if (details?.campaign_popups.length === 1) {
      return toast.error(
        "Unable to delete popout since this campaign has only one(1) popout"
      );
    }

    if (popup) {
      try {
        const result = await http.delete(`campaign-popups/${popup.id}`);
        const data = result.data.data;
        const popupIndex = details.campaign_popups?.findIndex(
          (item) => item.id === data.id
        );

        details.campaign_popups.splice(popupIndex, 1);
        // if removed popout is a base popouts
        if (popupIndex === 0) {
          const newBasePopout = details.campaign_popups?.find(
            (item) => item.order_in === 1
          );

          return onHandleMakeBasePopout(newBasePopout);
        }

        setCampaign((prev) => ({
          ...prev,
          campaign_popups: details.campaign_popups,
        }));
        toast.success("Successfully removed popout");
      } catch (error) {
        if (error.status === 422)
          toast.error("It appears that something went wrong");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUpdateOrder = async (data, forceUpdateData = false) => {
    try {
      await http.put(`campaign-popups/reorder/${id}`, data);

      if (forceUpdateData) {
        setCampaign((prev) => ({ ...prev, campaign_popups: data }));
        toast.success("Successfully updated default popout");
      } else {
        setCampaign((prev) => ({
          ...prev,
          campaign_popups: [prev.campaign_popups[0], ...data],
        }));
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    } finally {
      setTableLoading(false);
    }
  };

  const handleChangeStatus = async (id) => {
    const popupIndex = details?.campaign_popups.findIndex(
      (popup) => popup.id === id
    );
    const popupDetails = details?.campaign_popups.find(
      (popup) => popup.id === id
    );
    const active = popupDetails.active === 1 ? 0 : 1;
    setPopupStatus(active, popupIndex);
    setAbDetailStatus(active);

    try {
      const { data } = await http.put(`campaign-popups/${id}`, { active });
      const response = data;

      if (response.force_reorder) {
        onHandleMakeBasePopout(response.force_reorder);
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
      // set back to previous status if got an error
      setPopupStatus(popupDetails.active, popupIndex);
      setAbDetailStatus(popupDetails.active);
    }
  };

  const setAbDetailStatus = (status) => {
    if (content.type === "ab-details") {
      setContent({
        ...content,
        details: {
          ...content.details,
          active: status,
        },
      });
    }
  };

  const setPopupStatus = (active, index) => {
    const result = update(details?.campaign_popups, {
      [index]: {
        active: { $set: active },
      },
    });
    setCampaign((prev) => ({ ...prev, campaign_popups: result }));
  };

  const handleChangeContent = (content, details = null) => {
    setContent({
      ...content,
      type: content,
      details: details,
    });
  };

  const handlePreview = (id, cid) => {
    history.push(`/campaigns/popup/preview/${id}/${cid}`);
  };

  useEffect(() => {
    if (_.isEmpty(details)) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [basePopupData, ...otherPopupsData] = useMemo(() => {
    if (_.isEmpty(details) || _.isNull(details)) return [{}, []];
    return details.campaign_popups.map((popup) => {
      const popup_a_summary = popup.popup_a?.summary;
      const popup_b_summary = popup.popup_b?.summary;
      return {
        ...popup,
        open: popup_a_summary?.opens || 0,
        click: popup_a_summary?.clicks || 0,
        form_entry: popup_a_summary
          ? calculatePercentage(popup_a_summary?.leads, popup_a_summary?.clicks)
          : 0,
        popup_b_summary,
        popup_a_summary,
      };
    });
  }, [details]);

  useEffect(() => {
    setAlternative({
      campaign_id: null,
      popups: [],
      condition: null,
      popup: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(popoutOrderList) && !_.isEmpty(details)) {
      handleUpdateOrder(popoutOrderList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoutOrderList]);

  return content.type === "popouts" ? (
    <Fragment>
      {/* base popup */}
      <div className="w-full h-full bg-white border rounded-sm border-thBorder mb-4">
        <div className="flex flex-row items-center justify-between w-full px-6 py-5 border-b border-thBorder">
          <div className="flex flex-row items-center">
            <h1 className="mr-3 text-2xl">Default Popout</h1>
            {_.isEmpty(details) ? (
              <LoadingIndicator className="text-secondary w-6 h-6" />
            ) : (
              <TooltipInfo text="This displays by default if no other conditions match. May be hidden by it's own conditions." />
            )}
          </div>
        </div>
        {/* content */}
        {!_.isEmpty(details) && !_.isEmpty(basePopupData) ? (
          <div className="flex">
            <div
              className={`w-8 bg-${
                basePopupData?.active ? "lightGreen" : "lightRed"
              }`}
            />
            <div className="py-2">
              <div
                className="m-6 h-60 rounded-lg my-auto"
                style={{
                  maxWidth: "374px",
                  background: details?.desktop_image
                    ? `url(${details?.desktop_image?.url})`
                    : "#AEACDF",
                  backgroundSize: "cover",
                }}
              >
                <img
                  className="object-fill h-full rounded-lg"
                  style={{ maxWidth: "374px" }}
                  src={
                    getPopoutImage(
                      basePopupData?.popup_a?.template_type,
                      basePopupData?.popup_a?.contact_method_type
                    ) || EmptyWireframe
                  }
                  alt={`Campaign ${basePopupData?.id}`}
                />
              </div>
            </div>
            <div className="w-full p-6 border-r border-thBorder flex gap-4 flex-wrap items-center justify-between">
              <div>
                <PopoutDetails
                  data={basePopupData}
                  title={basePopupData?.popup_a?.title || "Untitled"}
                  abTest={basePopupData?.popup_b_id}
                  abOngoing={!basePopupData?.test_has_ended}
                  abTestDate={basePopupData?.abTestDate}
                  isActive={basePopupData?.active}
                  isBase={true}
                />
                <div className="flex items-center flex-wrap gap-2 mt-2">
                  <CampaignDetailsButton
                    onClick={() =>
                      !base?.popup_b_id &&
                      handlePreview(base?.popup_a_id, base?.campaign_id)
                    }
                    dropdown={
                      base?.popup_b_id
                        ? PreviewDropdown(base, handlePreview)
                        : []
                    }
                  >
                    <CampaignDetailsEyeSVG className="mr-1" />
                    Preview
                    {base?.popup_b_id && <FaChevronDown className="ml-1" />}
                  </CampaignDetailsButton>
                  {basePopupData?.popup_b_id && (
                    <CampaignDetailsButton
                      onClick={() =>
                        handleChangeContent("ab-details", basePopupData)
                      }
                    >
                      A/B Details
                    </CampaignDetailsButton>
                  )}
                  <CampaignDetailsButton
                    dropdown={createEditDropdown(base, onHandleDropdownAction)}
                  >
                    Options
                    <FaChevronDown className="ml-1" />
                  </CampaignDetailsButton>
                </div>
              </div>
              <PopoutData
                data={basePopupData}
                abTest={basePopupData?.popup_b_id}
                abOngoing={basePopupData?.abOngoing}
              />
            </div>
            <div className="flex items-center p-6">
              <Toggle
                checked={basePopupData?.active}
                onChange={() => handleChangeStatus(basePopupData?.id)}
              />
            </div>
          </div>
        ) : (
          <>
            {!_.isEmpty(details) && (
              <div className="w-full py-5">
                <p className="text-2xl opacity-50 text-center">
                  Base Popout not available for this campaign
                </p>
              </div>
            )}
          </>
        )}
      </div>
      {/* special condition */}
      <div className="h-full mb-16 bg-white border border-thBorder">
        <div className="w-full p-6 border-b border-thBorder flex items-center gap-4 justify-between">
          <div className="flex items-center">
            <h1 className="mr-3 text-xl">Special Conditions</h1>
            {_.isEmpty(details) || isLoading ? (
              <LoadingIndicator className="text-secondary w-6 h-6" />
            ) : (
              <TooltipInfo text="Used to display different popouts when certain conditions are met." />
            )}
          </div>
          {!_.isEmpty(details, true) && (
            <Button
              buttonName="ADD SPECIAL CONDITION"
              buttonTextClass="text-white text-sm font-bold flex justify-center"
              buttonClass="bg-secondary border-none rounded-full py-3 border px-12"
              buttonType="secondary"
              iconAlign="left"
              icon={<FaPlus className="mr-1 mt-0.5" />}
              onClick={onAddAlternative}
            />
          )}
        </div>
        {/* content */}
        {!_.isEmpty(otherPopupsData) && (
          <PopoutTable
            data={otherPopupsData}
            popups={details?.campaign_popups}
            onHandleDropdownAction={onHandleDropdownAction}
            onUpdateOrder={handleUpdateOrder}
            onChangeStatus={handleChangeStatus}
            setCampaign={setCampaign}
            setPopoutOrder={setPopoutOrder}
            setLoading={setLoading}
            tableLoading={tableLoading}
            onChangeContent={handleChangeContent}
          />
        )}
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div
        className="flex flex-row items-center space-x-2 cursor-pointer hover:opacity-60 w-2/12"
        onClick={() => handleChangeContent("popouts")}
      >
        <CampaignBackCircleSVG className="w-6 h-6" />
        <p className="text-dark whitespace-nowrap">Back to Campaign</p>
      </div>
      <div className="mt-5">
        <div className="tests-wrapper">
          {!_.isEmpty(details?.campaign_popups) && (
            <ABTestContent
              data={content?.details}
              popups={details?.campaign_popups}
              details={details}
              onChangeStatus={handleChangeStatus}
              setAlternative={setAlternative}
              handleConfirmDelete={(e) => handleConfirmDelete(e)}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}
