import CloseIconSVG from "assets/svg/campaign/close-icon";
import QuickSupportIconSVG from "assets/svg/quick-support-icon";
import { OverviewImage, SetupImage } from "constants/images";
import { Context } from "context/context";
import { useContext } from "react";
import { FaChevronRight } from "react-icons/fa";
import Backdrop from "../details/lead-details/backdrop";
import {
  backdropTransition,
  popupTransition,
  quickSupportTransition,
} from "../preview/transitions";
import QuickSupportContent from "./content";
import QuickSupportPopup from "./popup";
import "./styles.css";

const list = [
  {
    title: "Quick Overview",
    image: OverviewImage,
    link: "https://support.conversioncow.com/hc/en-us/articles/6481443678735-02-General-Overview-of-ConversionCow-Interface",
  },
  {
    title: "Get Started",
    image: SetupImage,
    link: "https://support.conversioncow.com/hc/en-us/categories/5206239334031-Get-Started",
  },
];

const links = [
  {
    link: "Customer Service",
    url: "https://support.conversioncow.com/hc/en-us",
  },
  {
    link: "Contact Us",
    url: "https://support.conversioncow.com/hc/en-us/requests/new",
  },
  {
    link: "Feedback/Suggest a Feature",
    url: "https://www.conversioncow.com/feedback/",
  },
];

export default function QuickSupport() {
  const { overlay, toggleOverlay } = useContext(Context);

  return (
    <div className="preview-quicksupport h-full z-40">
      {/* backdrop */}
      <Backdrop
        initial="initial"
        animate={overlay ? "show" : "hide"}
        variants={backdropTransition}
        exit={{ display: "none" }}
        onClick={() => toggleOverlay(!overlay)}
      />
      {/* backdrop */}
      <QuickSupportContent
        initial="initial"
        animate={overlay ? "show" : "hide"}
        variants={quickSupportTransition.parent}
      >
        {/* start content */}
        <div className="quicksupport-content-item">
          {/* title */}
          <div className="w-full flex justify-between">
            <div className="flex items-center">
              <h3 className="text-xl font-medium">Quick Support</h3>
            </div>
            <button onClick={() => toggleOverlay((prev) => !prev)}>
              <CloseIconSVG />
            </button>
          </div>
          {/* content */}
          <div className="w-full divide-y-2 divide-default">
            <div className="w-full mt-7 grid grid-cols-2 gap-y-6 gap-x-5 pb-8">
              {list.map((obj, index) => (
                <div className="w-full rounded-lg" key={index}>
                  <p className="text-base">{obj.title}</p>
                  <a
                    className="mt-3 w-full"
                    href={obj.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={obj.image}
                      className="rounded-lg"
                      alt="conversion-cow-quick-help"
                    />
                  </a>
                </div>
              ))}
            </div>
            <div className="w-full pt-6">
              <h3 className="text-xl font-medium">Helpful Links</h3>
              <div className="w-full mt-5 grid grid-cols-2 gap-3">
                {links.map((obj, index) => (
                  <div
                    key={index}
                    className="w-full text-textGray border-b border-lighterGray"
                  >
                    <div className="pb-1.5 flex justify-between">
                      <a
                        href={obj.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {obj.link}
                      </a>
                      <FaChevronRight className="text-lighterGray" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* end: content */}
        </div>
        {/* end content */}
        <QuickSupportPopup
          onClick={() => toggleOverlay((prev) => !prev)}
          variants={popupTransition.parent}
          initial="initial"
          animate="show"
        >
          <QuickSupportIconSVG className="mb-2" />
          Need Help?
        </QuickSupportPopup>
      </QuickSupportContent>
    </div>
  );
}
