const ProofPointsAvatarSVG = ({ className = "" }) => {
  return (
    <svg
      width="81"
      height="105"
      viewBox="0 0 81 105"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="40.2275"
        cy="64.0513"
        r="39.8504"
        fill="#EBEBEB"
        stroke="#BEBEBE"
        strokeWidth="0.754266"
      />
      <g clipPath="url(#clip0_1428_22031)">
        <path
          d="M25.5602 21.7342C26.3055 23.1389 26.763 18.4354 27.4831 18.0092C28.2032 17.5829 34.1185 13.8025 36.0355 12.8229C36.693 12.5238 37.3943 12.3974 38.0987 12.4507C38.803 12.5041 39.4966 12.7363 40.139 13.1338C42.0527 14.2573 42.9434 16.8968 43.3903 19.5225C44.0028 23.1444 44.8218 22.3844 45.2984 24.4286C45.8854 26.9466 46.2777 28.664 46.59 28.9899C46.9005 29.1585 47.2439 29.1831 47.56 29.0595C47.8761 28.9359 47.9133 29.0469 48.0511 28.8701C48.3255 28.5181 48.3213 27.9488 48.3167 26.5673C48.5262 23.4513 48.2473 20.2934 47.4962 17.2794C46.74 14.2525 43.3901 10.5429 43.3901 10.5429C43.3901 10.5429 38.3471 8.01174 35.2941 8.40236C32.2565 8.82279 28.9162 8.35595 26.698 10.9864C25.6274 12.1405 25.0375 12.5855 25.1818 16.2872"
          fill="#263238"
        />
        <path
          d="M31.6201 48.3649C31.6201 48.3649 28.6873 68.2045 28.4899 70.5932L53.5343 72.7846L49 51.0005C49 51.0005 48.3236 47.1475 47.7428 43.2009C46.7789 36.6524 50.2469 27.3902 50.2469 27.3902C50.8529 19.0893 46.493 10.8271 38.4571 10.4839L37.7117 10.4729C34.1003 10.5416 29.236 11.1923 26.6107 13.7738C23.9853 16.3552 23.6366 19.5648 23.5413 24.4064C23.446 29.2479 23.4198 28.4877 23.9496 32.7139C24.4794 36.9402 24.8888 44.2962 31.6201 48.3649Z"
          fill="#FFBE9D"
        />
        <path
          d="M34.3219 38.7538L37.5371 38.5946C37.7214 38.5669 37.9082 38.5939 38.0748 38.6725C38.2438 38.7951 38.3593 38.9796 38.3981 39.189C38.5456 39.7069 38.5385 40.262 38.3775 40.7845C38.2166 41.3071 37.909 41.7738 37.4935 42.1259C37.0634 42.4583 36.537 42.63 36.0055 42.6114C35.4741 42.5927 34.971 42.3849 34.5833 42.0238C33.8064 41.2328 33.8383 39.8959 34.2125 38.8099L34.3219 38.7538Z"
          fill="#EB996E"
        />
        <path
          d="M34.4125 42.1922C34.4024 42.2158 34.4946 42.3474 34.7246 42.5139C35.0764 42.7455 35.4823 42.8812 35.9047 42.9085C36.5509 42.9498 37.1957 42.743 37.7127 42.3286C38.0345 42.079 38.2995 41.7607 38.4883 41.397C38.6772 41.0334 38.7852 40.6336 38.8043 40.2268C38.826 39.806 38.7558 39.3881 38.5988 39.0035C38.5218 38.8188 38.4246 38.644 38.309 38.4824C38.2398 38.3853 38.1484 38.307 38.0424 38.2542C37.9384 38.2052 37.8243 38.181 37.7085 38.1832C37.5346 38.1973 37.3612 38.2204 37.189 38.2522L36.7278 38.3319L35.8981 38.4744C34.8875 38.6615 34.2633 38.8071 34.2698 38.8655C34.2763 38.9239 34.9097 38.8936 35.9315 38.7855L36.774 38.6761L37.2475 38.6202C37.4056 38.6079 37.5815 38.5752 37.7159 38.5761C37.7728 38.5727 37.8291 38.5853 37.8785 38.6127C37.9279 38.64 37.9685 38.681 37.9959 38.7311C38.0872 38.8667 38.1636 39.0125 38.2238 39.1657C38.3506 39.4933 38.408 39.8462 38.3924 40.2019C38.3756 40.5462 38.2863 40.885 38.1306 41.195C37.9748 41.5049 37.7562 41.7789 37.4898 41.9981C37.0511 42.3688 36.5034 42.5749 35.9415 42.5806C35.5552 42.5789 35.1751 42.4993 34.8231 42.3465C34.5666 42.2525 34.4357 42.1695 34.4125 42.1922Z"
          fill="#263238"
        />
        <rect
          x="44"
          y="12.0005"
          width="2.81749"
          height="3.4436"
          fill="#FFBE9D"
        />
        <ellipse
          cx="33.3279"
          cy="35.5377"
          rx="0.80868"
          ry="0.80868"
          transform="rotate(1.79121 33.3279 35.5377)"
          fill="#EB996E"
        />
        <path
          d="M31.6183 48.3666C35.2253 48.3942 39.3432 47.9494 43.2015 44.2971C43.2015 44.2971 39.0919 51.0417 31.305 50.2451L31.6183 48.3666Z"
          fill="#EB996E"
        />
        <path
          d="M49.202 30.0469C49.3467 30.026 55.3367 29.8255 53.3364 35.5937C51.336 41.3619 46.9043 38.4571 46.946 38.2871C46.9878 38.1172 49.202 30.0469 49.202 30.0469Z"
          fill="#FFBE9D"
        />
        <path
          d="M49.4186 36.4123C49.4186 36.4123 49.4829 36.5125 49.6114 36.6353C49.6995 36.7186 49.8031 36.782 49.916 36.8217C50.0289 36.8615 50.1489 36.8769 50.2694 36.8671C50.6738 36.7591 51.0482 36.54 51.3543 36.2322C51.6604 35.9245 51.8871 35.5392 52.0112 35.1158C52.1812 34.6528 52.2568 34.1604 52.2327 33.6722C52.2439 33.4865 52.2129 33.3025 52.1421 33.1359C52.0714 32.9692 51.9631 32.8248 51.8263 32.7149C51.7357 32.6483 51.6243 32.6193 51.5121 32.6331C51.4 32.6469 51.2946 32.7027 51.2149 32.7903C51.0918 32.9117 51.1251 33.0494 51.0579 33.0354C50.9907 33.0215 50.9865 32.9037 51.1045 32.6933C51.1777 32.5705 51.2867 32.4758 51.4139 32.4243C51.5933 32.3609 51.786 32.3732 51.9513 32.4585C52.1469 32.5765 52.3076 32.7486 52.417 32.9573C52.5264 33.1661 52.5808 33.404 52.5745 33.6472C52.6272 34.1923 52.5519 34.7483 52.3552 35.266C52.2102 35.7605 51.9328 36.2046 51.5577 36.543C51.1825 36.8814 50.7263 37.0991 50.2457 37.1689C50.096 37.1625 49.9508 37.1206 49.8208 37.0463C49.6908 36.972 49.5793 36.8672 49.4943 36.7394C49.3817 36.5447 49.3996 36.4153 49.4186 36.4123Z"
          fill="#EB996E"
        />
        <path
          d="M40.387 13.8293C38.0391 14.1423 36.3173 13.2856 34.2824 13.8293C32.2476 14.3729 29.973 15.1949 28.3342 12.7338C26.299 9.67752 28.4913 7.09895 30.5256 6.31615C32.56 5.53334 35.7783 8.3506 39.2916 8.35073C43.5173 8.35089 46.0376 9.06829 48.0769 11.8546C49.6333 14.1521 50.5965 17.0091 50.847 20.0709C51.0945 23.1193 50.8529 26.2296 50.1362 29.219L50.131 29.2505C49.9133 30.5691 49.823 31.1159 49.4974 31.4101C49.3325 31.559 49.314 31.4451 48.982 31.5131C48.6499 31.5811 48.805 31.5751 48.5264 31.3605C48.2717 30.9929 49.1723 25.5689 46.9609 22.5949C44.7494 19.6209 48.3897 15.7643 46.1782 13.8293C43.9668 11.8943 42.7349 13.5162 40.387 13.8293Z"
          fill="#263238"
        />
        <path
          d="M36.4077 28.6942C36.5858 28.874 37.7205 28.0854 39.2898 28.0791C40.8591 28.0728 41.9831 28.8057 42.1604 28.6198C42.2495 28.5412 42.0773 28.2167 41.5896 27.8714C40.9272 27.4294 40.1275 27.1992 39.2996 27.2121C38.4834 27.2149 37.6773 27.4665 36.9997 27.93C36.5022 28.2832 36.3241 28.6135 36.4077 28.6942Z"
          fill="#263238"
        />
        <path
          d="M25.3831 28.2692C25.5346 28.4506 26.6052 27.8628 28.0269 28.0273C29.4486 28.1917 30.4267 28.9741 30.5974 28.8257C30.6824 28.7646 30.5441 28.4534 30.1212 28.0893C29.5453 27.6192 28.8334 27.325 28.083 27.247C27.3435 27.161 26.5997 27.3004 25.9608 27.6446C25.4911 27.909 25.3117 28.1875 25.3831 28.2692Z"
          fill="#263238"
        />
        <path
          d="M35.1429 23.4091C35.9483 24.1318 41.3556 22.4181 45.1216 26.063C44.5076 20.4231 35.6325 22.0772 35.1589 22.1482C35.1589 22.1482 34.3375 22.6863 35.1429 23.4091Z"
          fill="#263238"
        />
        <path
          d="M31.1136 23.4977C30.5493 24.125 26.9171 21.8268 24.2724 25.0229C24.8234 19.4996 30.8127 22.1231 31.1326 22.2459C31.1326 22.2459 31.6778 22.8704 31.1136 23.4977Z"
          fill="#263238"
        />
        <path
          d="M33.1573 26.2483C32.968 27.289 32.73 28.3023 32.5281 29.3334L32.2261 30.8856L31.9114 32.4281C31.7987 32.9396 31.7096 33.4593 31.6094 33.9803L31.5248 34.364L31.5033 34.4571C31.5047 34.4682 31.4966 34.4918 31.4981 34.5029L31.4914 34.5377C31.4943 34.5598 31.4847 34.5724 31.4876 34.5946C31.4906 34.6168 31.481 34.6293 31.4839 34.6515C31.4904 34.786 31.5539 34.9243 31.6684 35.022C31.7201 35.0716 31.7926 35.1071 31.862 35.1205C31.8968 35.1272 31.9315 35.1338 31.9773 35.139L32.0106 35.1346L32.055 35.1288L32.1563 35.1266C32.4254 35.1135 32.6834 35.1019 32.9525 35.0888C33.481 35.0752 34.0111 35.0726 34.547 35.1144L34.5573 35.192C34.0508 35.372 33.5384 35.5077 33.0245 35.6322C32.7613 35.6897 32.5091 35.7456 32.2459 35.8031L32.1476 35.8274L32.1032 35.8333L32.0367 35.8421C31.948 35.8539 31.8467 35.856 31.755 35.8456C31.5717 35.8248 31.3825 35.7596 31.2222 35.6567C31.0618 35.5538 30.9303 35.4132 30.8292 35.2461C30.728 35.0789 30.6794 34.8823 30.6655 34.6923C30.6596 34.6479 30.6634 34.591 30.6686 34.5452C30.6738 34.4993 30.679 34.4535 30.6842 34.4077L30.6976 34.3382L30.7139 34.2909L30.745 34.1852L30.8532 33.8097C31.0007 33.3049 31.1593 32.7987 31.2957 32.2953L31.7146 30.7728L32.1445 29.2488C32.4381 28.2281 32.8542 26.514 33.0392 26.2074C33.2242 25.9009 33.1573 26.2483 33.1573 26.2483Z"
          fill="#263238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.4715 51.4009C47.4858 51.6301 47.7108 53.4524 47.9858 55.6703C48.1854 56.8749 48.009 58.114 47.4822 59.2078C47.0111 60.1448 46.4396 61.024 45.7785 61.8286L42.3281 64.941L41.5638 67.8982L36.9205 61.0523C33.2524 60.0654 31.5379 54.3692 31.5379 54.3692L31.1254 51.7275L28.8935 54.034L24.8289 55.1023C24.8289 55.1023 15.7531 58.0595 13.4922 60.61L20.9214 96.3769L20.7893 107.666H63.8216C63.8216 107.108 64.2074 70.1912 64.2074 70.1912L66.7898 55.7048L51.5063 52.9214L48.9689 50.8555C48.098 51.2551 47.4715 51.4009 47.4715 51.4009Z"
          fill="#EC5627"
        />
        <path
          d="M56 95.7424C56.6363 94.8894 57.3219 94.0768 58.0528 93.3092C58.7401 92.496 59.4729 91.7252 60.2476 91.001C59.6087 91.8541 58.9221 92.6678 58.1913 93.4379C57.5041 94.2489 56.7725 95.0183 56 95.7424Z"
          fill="white"
        />
        <path
          d="M56.1487 86.574C55.1698 85.5756 54.4334 84.3512 54 83.0014C54.0533 82.9793 54.4333 83.8357 55.0299 84.8171C55.6266 85.7985 56.1913 86.5373 56.1487 86.574Z"
          fill="white"
        />
        <path
          d="M21 79.7424C21.6363 78.8894 22.3219 78.0768 23.0528 77.3092C23.7401 76.496 24.4729 75.7252 25.2476 75.001C24.6087 75.8541 23.9221 76.6678 23.1913 77.4379C22.5041 78.2489 21.7725 79.0183 21 79.7424Z"
          fill="white"
        />
        <path
          d="M24 91.147C24.1486 89.7431 24.4172 88.3557 24.8027 87.001C24.7597 87.7062 24.6563 88.4061 24.4937 89.0924C24.39 89.7914 24.2248 90.4791 24 91.147Z"
          fill="white"
        />
        <path
          d="M66.794 55.7036C68.4109 56.6553 69.8294 57.9293 70.9673 59.4521C72.1052 60.9749 72.9399 62.7162 73.4232 64.5751C75.1483 70.9626 80.4238 87.5966 80.4238 87.5966C80.4238 87.5966 83.4384 91.2672 83.7598 93.9841C84.0813 96.7009 83.4384 103.277 82.4668 104.504C82.1855 104.86 81.8242 105.14 81.4146 105.318C81.005 105.497 80.5594 105.569 80.1166 105.528L67.4262 104.282L62.4258 69.654L66.794 55.7036Z"
          fill="#EC5627"
        />
        <path
          d="M13.4951 60.6089C13.4951 60.6089 10.5056 64.8265 9.26618 67.5804C8.02678 70.3343 -2.26341 93.4556 -2.87775 95.8472C-3.49209 98.2388 -5.09224 105.757 -0.395394 107.502H9.27332L18.9742 87.1975L19.4706 63.6326L13.4951 60.6089Z"
          fill="#EC5627"
        />
        <path
          d="M9.88105 92.8349C9.88105 92.8349 9.66674 92.639 9.22027 92.3766C8.58302 92.0228 7.8997 91.7664 7.19152 91.6151C6.70463 91.5047 6.21029 91.433 5.71283 91.4007C5.13967 91.356 4.56327 91.3984 4.00196 91.5264C2.73137 91.8641 1.59403 92.6031 0.748099 93.6407C-0.112039 94.6667 -0.741506 95.8775 -1.09492 97.1856C-1.38665 98.2549 -1.58037 99.3502 -1.67354 100.457C-1.76641 101.403 -1.79855 102.176 -1.8307 102.704C-1.8307 102.956 -1.85927 103.159 -1.86641 103.318C-1.86622 103.391 -1.87584 103.463 -1.89499 103.532C-1.89959 103.461 -1.89959 103.389 -1.89499 103.318C-1.89499 103.155 -1.89499 102.948 -1.89499 102.704C-1.89499 102.168 -1.87356 101.4 -1.79856 100.446C-1.72304 99.321 -1.53999 98.2066 -1.25208 97.1191C-0.901156 95.7813 -0.262564 94.543 0.615946 93.4966C1.48829 92.43 2.66297 91.6747 3.97339 91.3378C4.54628 91.2144 5.13384 91.1808 5.71639 91.238C6.22067 91.2764 6.72115 91.3567 7.21295 91.4783C7.92906 91.6431 8.61595 91.9224 9.24885 92.3063C9.41931 92.4153 9.58061 92.539 9.73104 92.676C9.78633 92.7233 9.83661 92.7766 9.88105 92.8349Z"
          fill="#263238"
        />
        <path
          d="M5.29883 88.7915C5.40451 88.7596 5.51379 88.7421 5.62386 88.7397C5.92224 88.7157 6.22197 88.7157 6.52036 88.7397C6.96466 88.7667 7.40574 88.8347 7.83833 88.943C8.90268 89.2093 9.90151 89.7028 10.7707 90.392C11.1249 90.6709 11.4536 90.9827 11.753 91.3235C11.9559 91.5546 12.141 91.8018 12.3066 92.0628C12.374 92.1533 12.4293 92.2527 12.4709 92.3585C12.4423 92.3807 12.178 91.9889 11.6565 91.427C11.3484 91.1075 11.0177 90.8122 10.6672 90.5436C10.2355 90.2183 9.77644 89.9338 9.29561 89.6934C8.81206 89.46 8.3102 89.2695 7.79547 89.1241C7.3733 89.0116 6.94344 88.9325 6.50964 88.8876C5.76672 88.7877 5.29883 88.8284 5.29883 88.7915Z"
          fill="#263238"
        />
        <path
          d="M14.0351 87.6775C13.8856 87.6838 13.7358 87.6726 13.5887 87.6442C13.3029 87.6073 12.8922 87.5629 12.3778 87.5223C9.70666 87.3161 7.02221 87.6488 4.47358 88.5018C3.98425 88.6645 3.59136 88.8086 3.32348 88.9158C3.18677 88.9784 3.04439 89.0266 2.89844 89.06C3.02139 88.9716 3.15458 88.8996 3.2949 88.8456C3.55563 88.7162 3.94496 88.5536 4.43072 88.3724C6.98124 87.4378 9.7007 87.1019 12.3921 87.3892C12.9029 87.4446 13.3172 87.5075 13.5994 87.5703C13.7476 87.5916 13.8935 87.6275 14.0351 87.6775Z"
          fill="#263238"
        />
        <path
          d="M41.5726 67.9214C41.5335 67.8862 41.4997 67.8451 41.4726 67.7994L41.2083 67.4297L40.2368 66.0399C39.4046 64.82 38.2402 63.1197 36.8722 61.1236L37.0222 61.1014C36.5007 62.6835 35.9257 64.4282 35.3221 66.2764L35.2828 66.391L35.1828 66.3245L34.9828 66.1914C34.2045 65.6758 33.4969 65.0542 32.879 64.3432C32.3079 63.6812 31.804 62.9604 31.3753 62.1919C30.6617 60.8914 30.1062 59.5045 29.7216 58.0629C29.4633 57.0892 29.2547 56.1021 29.0965 55.1058C29.0394 54.7731 28.9965 54.5032 28.9608 54.2999C28.9507 54.231 28.9352 54.163 28.9144 54.0966C28.9144 54.0523 28.8894 54.0301 28.8929 54.0264C28.8965 54.0227 29.0394 54.396 29.168 55.0947C29.2965 55.7933 29.5001 56.7987 29.8502 58.0149C30.2457 59.4431 30.8083 60.816 31.5253 62.1031C31.9529 62.8573 32.4518 63.5655 33.0147 64.2175C33.622 64.9115 34.3163 65.5182 35.0792 66.0214L35.2756 66.1544L35.1399 66.2062C35.7507 64.358 36.3293 62.6243 36.8579 61.0312L36.9115 60.8685L37.0079 61.009C38.3509 63.0236 39.5082 64.7387 40.3189 65.9659C40.7083 66.5648 41.0333 67.0416 41.2512 67.3891L41.4905 67.7587C41.5233 67.8098 41.5508 67.8643 41.5726 67.9214Z"
          fill="#263238"
        />
        <path
          d="M80.9495 95.3629C80.5397 94.9224 80.1005 94.5123 79.6351 94.1356C78.4915 93.2501 77.1202 92.7344 75.6941 92.6535C74.2679 92.5726 72.8504 92.9301 71.6201 93.681C71.1209 94.003 70.6436 94.3599 70.1914 94.7493C70.2858 94.6136 70.3948 94.4896 70.5164 94.3796C70.8379 94.0705 71.1885 93.7956 71.563 93.559C72.8052 92.7582 74.2559 92.3736 75.7172 92.4576C77.1785 92.5416 78.5791 93.0902 79.728 94.0284C80.0754 94.305 80.3938 94.6184 80.6781 94.9636C80.7833 95.0853 80.8744 95.2193 80.9495 95.3629Z"
          fill="#263238"
        />
        <path
          d="M80.4425 91.9508C79.9317 91.5711 79.379 91.2559 78.796 91.0119C77.3629 90.4854 75.8063 90.4376 74.3456 90.8752C73.542 91.1085 72.7685 91.4411 72.0418 91.8658C71.4502 92.2176 70.8918 92.626 70.3738 93.0856C69.9309 93.4775 69.5916 93.8249 69.3594 94.0578C69.2455 94.1925 69.1199 94.3162 68.9844 94.4275C69.0773 94.2782 69.185 94.1395 69.3058 94.0135C69.6143 93.6533 69.9423 93.3114 70.2881 92.9895C70.8014 92.5098 71.3602 92.085 71.9561 91.7217C72.6901 91.2777 73.4765 90.9336 74.2956 90.6977C75.7921 90.2453 77.3909 90.3141 78.846 90.8936C79.2805 91.0733 79.6901 91.312 80.0639 91.6034C80.1961 91.7032 80.2854 91.7993 80.3532 91.8584C80.4211 91.9176 80.4425 91.9471 80.4425 91.9508Z"
          fill="#263238"
        />
        <path
          d="M80.4708 88.9953C80.4708 89.0323 79.7565 88.8142 78.6207 88.6552C77.9494 88.562 77.2727 88.5176 76.5955 88.5221C75.7659 88.5316 74.9383 88.6082 74.1203 88.7513C73.3039 88.9066 72.5003 89.1266 71.7165 89.4093C71.0776 89.6441 70.4558 89.9257 69.8556 90.2521C68.8305 90.8066 68.2519 91.2575 68.2305 91.2243C68.3518 91.1024 68.4846 90.9934 68.6269 90.899C68.7825 90.7722 68.946 90.6562 69.1163 90.5515C69.3127 90.4221 69.5342 90.2706 69.7913 90.1301C70.3917 89.7864 71.0161 89.4899 71.6593 89.243C72.4487 88.9449 73.2612 88.7173 74.0881 88.5628C74.9142 88.4181 75.7505 88.3452 76.5883 88.3447C77.2738 88.3483 77.9581 88.4063 78.635 88.5184C78.9243 88.5554 79.1814 88.6257 79.4064 88.6737C79.6019 88.7146 79.795 88.7665 79.9851 88.829C80.1513 88.8697 80.3139 88.9254 80.4708 88.9953Z"
          fill="#263238"
        />
        <path
          d="M50.1745 52.7979C50.3226 53.142 50.4144 53.5093 50.446 53.8846C50.5526 54.9448 50.502 56.0155 50.296 57.0599C50.1707 57.8095 50.0001 58.5502 49.7852 59.2777C49.5036 60.1243 49.1573 60.9463 48.7494 61.7359C47.8958 63.4362 46.8242 65.2882 45.5813 67.2806L45.4384 67.5134V67.2362C45.4384 65.9277 45.4384 64.5563 45.4384 63.1331L45.5777 63.2034L42.2238 65.972L42.2524 65.9277C41.4568 68.6259 40.9678 71.4108 40.7951 74.2262C40.4665 78.7248 40.7309 83.0274 40.9344 86.9309C41.138 90.8343 41.2273 94.3571 41.3452 97.3142C41.4631 100.271 41.5595 102.659 41.6452 104.312C41.6952 105.132 41.7309 105.768 41.7559 106.212C41.7559 106.422 41.7809 106.581 41.7881 106.707C41.7952 106.833 41.7881 106.877 41.7881 106.877C41.7881 106.877 41.7881 106.821 41.7631 106.711C41.7381 106.6 41.7345 106.426 41.7131 106.215C41.6738 105.783 41.6238 105.143 41.5702 104.315C41.4631 102.667 41.338 100.275 41.213 97.3179C41.088 94.3608 40.9344 90.8454 40.763 86.9383C40.5915 83.0311 40.2808 78.7248 40.6094 74.2114C40.79 71.3793 41.2923 68.579 42.106 65.8685V65.8426H42.1274L45.4705 63.0666L45.6099 62.9483V63.1331C45.6099 64.5563 45.6099 65.9277 45.6099 67.2362L45.4634 67.1881C47.9172 63.2514 49.9138 59.8285 50.221 57.0599C50.4331 56.0254 50.4981 54.9644 50.4138 53.9105C50.3847 53.6336 50.3357 53.3593 50.2674 53.0899C50.196 52.8903 50.1745 52.7979 50.1745 52.7979Z"
          fill="#263238"
        />
        <path
          d="M43.7646 100.479H43.6503C43.3902 100.496 43.1468 100.617 42.9716 100.817C42.7964 101.016 42.7031 101.279 42.7115 101.548C42.7198 101.818 42.8292 102.074 43.0164 102.261C43.2036 102.449 43.454 102.554 43.7146 102.554C43.9752 102.554 44.2256 102.449 44.4128 102.261C44.6001 102.074 44.7094 101.818 44.7177 101.548C44.7261 101.279 44.6328 101.016 44.4576 100.817C44.2824 100.617 44.039 100.496 43.7789 100.479H43.7646Z"
          fill="white"
        />
        <path
          d="M43.7649 86.2329H43.6506C43.3915 86.2502 43.149 86.3711 42.9745 86.5701C42.7999 86.769 42.707 87.0305 42.7154 87.2991C42.7237 87.5677 42.8327 87.8224 43.0192 88.0093C43.2058 88.1963 43.4553 88.3008 43.7149 88.3008C43.9746 88.3008 44.2241 88.1963 44.4106 88.0093C44.5972 87.8224 44.7062 87.5677 44.7145 87.2991C44.7229 87.0305 44.6299 86.769 44.4554 86.5701C44.2808 86.3711 44.0384 86.2502 43.7792 86.2329H43.7649Z"
          fill="white"
        />
        <path
          d="M43.6557 73.4854C43.9223 73.5035 44.185 73.4113 44.386 73.229C44.5869 73.0467 44.7097 72.7892 44.7272 72.5132C44.7447 72.2373 44.6556 71.9654 44.4794 71.7574C44.3033 71.5495 44.0545 71.4224 43.7878 71.4043H43.6557C43.3885 71.4043 43.1323 71.5141 42.9434 71.7096C42.7546 71.9051 42.6484 72.1702 42.6484 72.4467C42.6484 72.7232 42.7546 72.9883 42.9434 73.1838C43.1323 73.3793 43.3885 73.4891 43.6557 73.4891V73.4854Z"
          fill="white"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M29.0071 54.4902C28.5106 59.5063 30.3537 64.8033 34.2433 67.8418C34.5469 68.0821 34.9291 68.3187 35.2898 68.1819C35.5304 68.0613 35.7225 67.8572 35.8327 67.6052C36.922 65.6575 37.3306 63.3813 36.99 61.1586C36.3756 62.8368 35.847 64.4854 35.2255 66.2376C31.8431 64.1084 29.7215 59.5839 29.0071 54.4902Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.3"
          d="M42.1777 65.8988L45.0351 64.8194L45.5066 69.8134C45.5066 69.8134 48.5854 64.0062 49.8606 61.3596C51.1357 58.7129 50.4785 54.0332 50.4785 54.0332C50.4785 54.0332 50.9321 58.0993 47.8497 63.3002L45.5209 67.2184V63.1154L42.1777 65.8988Z"
          fill="black"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M9.00147 91.2791C7.69886 90.7065 6.26783 90.5209 4.86896 90.7431C3.37828 90.8693 1.95218 91.4279 0.754318 92.3548C-0.402927 93.3195 -1.18514 94.8425 -1.03155 96.3728L-0.888687 96.3913C0.10783 93.6042 2.08658 91.5859 4.64038 91.3308C7.19417 91.0758 9.68368 92.2587 11.4267 94.1993C11.2374 92.7503 10.2587 91.8964 9.00147 91.2791Z"
            fill="black"
          />
        </g>
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M81.1284 94.1158C80.1385 93.0643 78.8445 92.3752 77.4423 92.1529C76.221 91.9414 74.9694 92.0166 73.7799 92.373C72.5904 92.7294 71.4936 93.3578 70.5703 94.2119L71.1132 93.7535C72.4944 92.8927 74.08 92.4473 75.6922 92.4671C77.3102 92.4958 78.8619 93.1376 80.0533 94.271C81.1248 95.3208 81.7642 96.7439 82.3749 98.1301C82.4738 97.407 82.4131 96.6701 82.1972 95.9748C81.9812 95.2795 81.6158 94.6439 81.1284 94.1158Z"
            fill="black"
          />
        </g>
        <path
          d="M62.7402 70.9372C62.7693 70.7986 62.8124 70.6636 62.8688 70.5343C62.9795 70.2423 63.1153 69.8837 63.2724 69.4586C63.6296 68.553 64.0939 67.3036 64.594 65.9101C65.094 64.5165 65.5262 63.2486 65.8369 62.3319L66.1941 61.2414C66.2324 61.1052 66.2851 60.9738 66.3513 60.8496C66.3402 60.9912 66.3101 61.1306 66.262 61.2636C66.1941 61.5298 66.087 61.9068 65.9477 62.3725C65.6691 63.3041 65.2547 64.5904 64.7547 65.9766C64.2546 67.3628 63.7582 68.6195 63.3796 69.5104C63.1938 69.9577 63.0224 70.3162 62.9188 70.5639C62.8743 70.6954 62.8144 70.8208 62.7402 70.9372Z"
          fill="#263238"
        />
        <path
          d="M17.7123 71.2993C17.6623 71.2993 17.273 69.5509 16.8444 67.37C16.4158 65.1891 16.1086 63.4333 16.1479 63.4037C16.1872 63.3741 16.5837 65.1521 17.0123 67.333C17.4409 69.5139 17.7623 71.2882 17.7123 71.2993Z"
          fill="#263238"
        />
        <path
          d="M10.882 79.6971C10.2401 78.6197 9.6512 77.5093 9.1176 76.3703C8.18895 74.5221 7.4746 72.9844 7.51746 72.9585C8.15941 74.0359 8.74831 75.1463 9.2819 76.2853C9.87115 77.3936 10.4052 78.5324 10.882 79.6971Z"
          fill="white"
        />
        <path
          d="M22.5781 66.2085C22.8826 65.2608 23.2465 64.3348 23.6675 63.4361C24.0329 62.5127 24.454 61.6139 24.9283 60.7451C24.6219 61.692 24.2581 62.618 23.8389 63.5175C23.4745 64.4413 23.0533 65.3402 22.5781 66.2085Z"
          fill="white"
        />
        <path
          d="M34.0536 74.9605C33.461 74.3704 32.9118 73.7353 32.4106 73.0605C31.8644 72.4256 31.3641 71.7498 30.9141 71.0386C31.5071 71.6271 32.0553 72.2624 32.5535 72.9386C33.1015 73.5729 33.603 74.2488 34.0536 74.9605Z"
          fill="white"
        />
        <path
          d="M31.416 87.0004C31.7784 86.3246 32.1917 85.6795 32.6518 85.0708C33.0662 84.4275 33.5309 83.8204 34.0412 83.2559C33.6798 83.9322 33.2665 84.5774 32.8054 85.1854C32.3934 85.8304 31.9285 86.4377 31.416 87.0004Z"
          fill="white"
        />
        <path
          d="M27.8636 104.008C27.6969 103.78 27.5566 103.531 27.4457 103.269C27.21 102.796 26.9064 102.134 26.5992 101.391C26.292 100.648 26.0456 99.9569 25.8848 99.4579C25.778 99.1919 25.7013 98.9141 25.6562 98.6299C25.8034 98.8751 25.9232 99.1367 26.0134 99.4098L26.7813 101.314C27.0778 102.02 27.3421 102.659 27.5743 103.21C27.6991 103.464 27.7961 103.732 27.8636 104.008Z"
          fill="white"
        />
        <path
          d="M37.94 96.7773C37.2096 96.7272 36.4844 96.6147 35.772 96.4409C35.0445 96.3294 34.3275 96.1538 33.6289 95.916C35.0785 96.0777 36.5123 96.3658 37.915 96.7773H37.94Z"
          fill="white"
        />
        <path
          d="M45.7969 80.889C46.4368 80.0311 47.1262 79.2139 47.8613 78.442C48.5525 77.6241 49.2896 76.849 50.0687 76.1206C49.4261 76.9786 48.7356 77.7969 48.0006 78.5714C47.3096 79.387 46.5738 80.1608 45.7969 80.889Z"
          fill="white"
        />
        <path
          d="M53.6525 102.079C53.6132 102.116 52.4595 100.729 51.0737 98.9921C49.6878 97.2548 48.5985 95.8132 48.6377 95.7799C48.677 95.7466 49.8307 97.1291 51.2165 98.8664C52.6024 100.604 53.6846 102.045 53.6525 102.079Z"
          fill="white"
        />
        <path
          d="M4.79094 93.0498C4.81594 93.1016 3.76228 93.656 2.61217 94.5691C1.46207 95.4821 0.662002 96.384 0.619141 96.3433C0.748976 96.1093 0.91281 95.8974 1.1049 95.7149C1.53267 95.2422 1.99869 94.8081 2.49788 94.4175C2.99494 94.0248 3.52304 93.6761 4.07659 93.3751C4.29816 93.2313 4.53881 93.1217 4.79094 93.0498Z"
          fill="white"
        />
        <path
          d="M7.73237 84.8721C7.50541 85.3951 7.22395 85.8909 6.893 86.3507C6.61104 86.8458 6.27682 87.307 5.89648 87.7257C6.12405 87.203 6.40546 86.7073 6.73585 86.2472C7.01706 85.7515 7.35134 85.2902 7.73237 84.8721Z"
          fill="white"
        />
        <path
          d="M14.3667 92.9002C13.7949 91.9591 13.5504 90.8442 13.6738 89.7397C13.7274 89.7397 13.7453 90.479 13.9417 91.3403C14.1382 92.2016 14.4167 92.8743 14.3667 92.9002Z"
          fill="white"
        />
        <path
          d="M15.3413 67.4247C14.8972 67.5851 14.4351 67.6857 13.9662 67.7241C13.505 67.8244 13.0329 67.8604 12.5625 67.8313C13.0079 67.6712 13.4712 67.5705 13.9412 67.5319C14.4015 67.4338 14.8721 67.3977 15.3413 67.4247Z"
          fill="white"
        />
        <path
          d="M20.3225 58.2847C19.5532 58.8738 18.7127 59.356 17.8223 59.7189C18.1878 59.3981 18.5925 59.1284 19.0259 58.9168C19.4305 58.6501 19.8662 58.4377 20.3225 58.2847Z"
          fill="white"
        />
        <path
          d="M34.33 62.1465C33.8253 61.6153 33.3642 61.0416 32.9513 60.4313C32.4931 59.858 32.0821 59.246 31.7227 58.6016C32.6891 59.7031 33.5614 60.8891 34.33 62.1465Z"
          fill="white"
        />
        <path
          d="M52.1043 71.336C51.1198 70.3319 50.3792 69.1006 49.9434 67.7431C49.9969 67.7209 50.3791 68.5822 50.9792 69.5691C51.5792 70.5561 52.1471 71.2991 52.1043 71.336Z"
          fill="white"
        />
        <path
          d="M58.8891 73.6494C58.4581 74.9489 57.9079 76.2028 57.2461 77.3939C57.4365 76.7352 57.684 76.0956 57.9854 75.4829C58.2299 74.8435 58.5324 74.2296 58.8891 73.6494Z"
          fill="white"
        />
        <path
          d="M59.8412 64.0649C59.1919 63.4125 58.5879 62.7134 58.0339 61.9728C57.4319 61.2736 56.877 60.5326 56.373 59.7549C57.6229 61.1079 58.7816 62.548 59.8412 64.0649Z"
          fill="white"
        />
        <path
          d="M49.4769 56.2807C49.3638 55.6916 49.3099 55.0921 49.3162 54.4916C49.2624 53.8923 49.2707 53.2888 49.3412 52.6914C49.4544 53.2804 49.5083 53.88 49.5019 54.4805C49.558 55.0797 49.5496 55.6834 49.4769 56.2807Z"
          fill="white"
        />
        <path
          d="M57.942 55.7964C57.218 55.9928 56.4798 56.1275 55.7346 56.1993C54.9986 56.3331 54.2531 56.4036 53.5059 56.41C54.2298 56.2136 54.968 56.0789 55.7132 56.0071C56.4492 55.8731 57.1947 55.8026 57.942 55.7964Z"
          fill="white"
        />
        <path
          d="M71.234 65.9134C70.738 65.0711 70.2942 64.1969 69.9053 63.2963C69.4637 62.4224 69.0771 61.5198 68.748 60.5942C69.2461 61.436 69.6911 62.3102 70.0803 63.2113C70.5204 64.0855 70.9058 64.9881 71.234 65.9134Z"
          fill="white"
        />
        <path
          d="M69.0098 74.654C69.1592 73.2421 69.4294 71.8468 69.817 70.4844C69.7738 71.1936 69.6698 71.8975 69.5062 72.5877C69.402 73.2907 69.2358 73.9823 69.0098 74.654Z"
          fill="white"
        />
        <path
          d="M75.1666 81.5441C74.7501 80.623 74.3922 79.6746 74.0951 78.7052C73.741 77.7574 73.4464 76.7869 73.2129 75.7998C73.627 76.7233 73.9848 77.6727 74.2844 78.6424C74.637 79.5895 74.9317 80.5587 75.1666 81.5441Z"
          fill="white"
        />
        <path
          d="M74.3054 85.5777C73.6678 85.4784 73.0551 85.2511 72.5017 84.9086C71.9206 84.6198 71.4018 84.2124 70.9766 83.711C71.0123 83.6666 71.6695 84.2285 72.591 84.7386C73.5125 85.2487 74.3233 85.5222 74.3054 85.5777Z"
          fill="white"
        />
        <path
          d="M35.5146 107.586C35.3623 107.053 35.2689 106.504 35.236 105.949C35.1456 105.401 35.1144 104.844 35.1432 104.289C35.3063 104.825 35.4058 105.38 35.4396 105.941C35.5252 106.485 35.5503 107.037 35.5146 107.586Z"
          fill="white"
        />
        <path
          d="M59.7734 106.951C59.7898 106.41 59.8678 105.873 60.0056 105.35C60.0861 104.815 60.2263 104.292 60.4235 103.791C60.4071 104.332 60.3279 104.869 60.1878 105.391C60.1094 105.926 59.9704 106.45 59.7734 106.951Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.5154 68.3623L72.1027 69.9849C72.821 72.0045 73.4323 74.0605 73.9342 76.1445C75.1277 81.579 75.7319 87.1264 75.7361 92.6905C75.5767 97.1013 70.155 100.699 67.9679 101.918C66.6317 102.663 64.6255 101.874 62.4126 100.234C61.3435 95.8144 60.6832 86.5901 61.0673 84.7302C61.3578 83.3235 60.4289 73.1294 59.6666 65.6256C59.6821 65.4333 59.6642 65.235 59.6057 65.0291C59.5332 64.3206 59.4626 63.64 59.3951 62.9974L59.2905 61.3288L59.2564 60.7842C59.2564 60.7842 57.9455 54.3894 58.1314 53.2077C58.2527 52.734 58.4321 52.2771 58.6656 51.8475C59.5064 50.1354 61.0323 47.6997 61.0323 47.6997C61.0323 47.6997 61.0637 46.2487 61.1335 44.2522C61.2775 40.328 61.5963 34.3739 62.2431 33.5916C62.7586 32.9705 64.464 32.5462 64.7268 36.1888C64.9896 39.8314 65.2072 43.8516 65.2072 43.8516C65.2072 43.8516 70.1531 33.8834 71.7163 33.9345C73.2795 33.9856 73.3817 34.7018 71.9515 37.6859C70.599 40.5083 69.4137 44.8382 69.4137 44.8382C69.9735 44.9179 70.4923 45.1769 70.8924 45.5765C71.2925 45.9761 71.5522 46.4946 71.6326 47.0543L72.0214 49.1099C72.0214 49.1099 73.6768 50.6923 73.1511 52.6753C73.0772 52.959 72.9427 53.3765 72.7719 53.8843L71.285 57.8545L69.3091 63.1745C69.3091 63.1745 70.3323 65.2248 71.5154 68.3623Z"
          fill="#FFBE9D"
        />
        <path
          d="M64.3797 59.0062C64.5351 58.9073 64.6699 58.7794 64.7767 58.6295C65.1129 58.2308 65.3732 57.7739 65.5448 57.2814C65.8204 56.512 65.8782 55.6814 65.7117 54.8812C65.492 53.8346 64.9203 52.8948 64.0919 52.2184L63.9263 52.5041C64.9189 52.9727 65.9949 53.2393 67.0916 53.2883C68.2391 53.2966 69.6032 52.7943 70.2704 51.6055C70.3149 51.5224 70.3513 51.4322 70.3885 51.3534L70.4632 51.2034L70.3087 51.1175L65.5501 48.3991C65.19 48.1779 64.8069 47.9967 64.4074 47.8589C64.0093 47.7294 63.5821 47.719 63.1782 47.829C62.4889 48.0704 61.8965 48.5288 61.4898 49.1354C61.162 49.6077 60.8583 50.0964 60.5798 50.5995C60.3691 50.9666 60.1231 51.3124 59.8454 51.632C59.592 51.8906 59.3132 52.1233 59.0134 52.3265C59.1083 52.3088 59.1993 52.2746 59.2824 52.2255C59.5289 52.0992 59.7535 51.9341 59.9476 51.7364C60.2557 51.4261 60.5279 51.082 60.7589 50.7106C61.0423 50.2914 61.3162 49.7809 61.7026 49.2979C62.0834 48.7534 62.6287 48.3453 63.2585 48.1336C63.9378 47.9304 64.6665 48.2709 65.3742 48.7045L70.119 51.4467L70.0354 51.2111C70.0014 51.2782 69.9757 51.3563 69.9343 51.4277C69.3708 52.4419 68.1584 52.9232 67.0874 52.9176C66.0386 52.8737 65.008 52.6281 64.0521 52.1941L62.9868 51.7906L63.8877 52.4988C64.6676 53.1214 65.2161 53.9878 65.4451 54.9591C65.6179 55.7136 65.5881 56.5003 65.3587 57.2395C65.1337 57.8791 64.8028 58.4763 64.3797 59.0062Z"
          fill="#EB996E"
        />
        <path
          d="M67.1081 53.2605C67.0853 53.262 67.0075 53.3624 66.8949 53.5798C66.7309 53.8942 66.6228 54.2348 66.5757 54.5863C66.5322 54.8699 66.5695 55.16 66.6834 55.4234C66.7973 55.6868 66.9832 55.9127 67.2196 56.0753C67.3725 56.166 67.5444 56.22 67.7217 56.2327C67.8991 56.2455 68.077 56.2168 68.2413 56.1489C68.5606 56.0132 68.8508 55.8171 69.0959 55.5715C71.0068 53.7273 72.4811 52.1781 72.4114 52.1022C72.3416 52.0263 70.7339 53.4615 68.8376 55.2933C68.3876 55.7382 67.8211 56.0338 67.399 55.7658C67.2138 55.6377 67.0652 55.4635 66.968 55.2604C66.8708 55.0572 66.8283 54.8322 66.8447 54.6076C66.8689 54.276 66.9331 53.9484 67.0358 53.6321C67.1017 53.4024 67.1317 53.2705 67.1081 53.2605Z"
          fill="#EB996E"
        />
        <path
          d="M68.6827 50.4434C68.7748 50.5102 69.2122 49.9857 69.9167 49.5745C70.6212 49.1633 71.2768 49.0075 71.266 48.8973C71.2553 48.7871 70.4936 48.7737 69.7203 49.2466C68.947 49.7194 68.5958 50.3991 68.6827 50.4434Z"
          fill="#EB996E"
        />
        <path
          d="M65.7246 48.453C65.8234 48.5042 66.3031 47.6788 67.1282 46.8739C67.4757 46.5299 67.8496 46.2137 68.2466 45.9282C68.4096 45.8284 68.596 45.7732 68.7871 45.7681C68.7859 45.7491 68.7626 45.6817 68.6414 45.6396C68.4718 45.5874 68.289 45.5989 68.1273 45.6719C67.6572 45.9031 67.2294 46.2117 66.8616 46.5847C65.9836 47.4618 65.6305 48.4169 65.7246 48.453Z"
          fill="#EB996E"
        />
        <path
          d="M61.3981 92.6481C61.365 92.546 61.3415 92.441 61.3281 92.3346C61.2867 92.1304 61.24 91.8233 61.1848 91.4288C61.0434 90.3135 60.9639 89.1913 60.9467 88.0672C60.9099 86.6438 60.9508 84.9526 60.959 83.0759C60.9671 81.1992 60.9081 79.135 60.7827 76.9711C60.5319 72.6434 60.0895 68.7422 59.7689 65.9198C59.6112 64.5219 59.4826 63.3861 59.3903 62.577L59.2965 61.6649C59.28 61.559 59.2744 61.4518 59.2796 61.3448C59.307 61.4492 59.3266 61.5554 59.3384 61.6627C59.3731 61.8863 59.4156 62.1859 59.47 62.5652C59.5803 63.3542 59.7358 64.4924 59.9133 65.9046C60.268 68.7213 60.7334 72.6251 60.9847 76.9605C61.0988 79.1288 61.1657 81.1964 61.1457 83.0661C61.1257 84.9357 61.0738 86.6351 61.0913 88.0557C61.1088 89.4763 61.1842 90.6187 61.2679 91.4092C61.3108 91.789 61.3467 92.1081 61.373 92.3169C61.3904 92.4264 61.3988 92.5372 61.3981 92.6481Z"
          fill="#EB996E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1428_22031">
          <path
            d="M0.625 0H79.8279V64.9589C79.8279 86.8301 62.0977 104.56 40.2264 104.56C18.3552 104.56 0.625 86.8301 0.625 64.9589V0Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProofPointsAvatarSVG;
