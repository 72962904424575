const EyeSlashIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.15546 12.8468C3.77171 11.8643 2.67671 10.5863 2.05796 9.77925C1.88494 9.55648 1.79102 9.28244 1.79102 9.00038C1.79102 8.71831 1.88494 8.44427 2.05796 8.2215C3.17771 6.75975 5.86421 3.75 9.00071 3.75C10.4077 3.75 11.7232 4.35525 12.8482 5.1555"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5982 7.41525C10.3899 7.2035 10.1418 7.03508 9.8681 6.91971C9.59441 6.80434 9.30058 6.74431 9.00357 6.74306C8.70655 6.74182 8.41223 6.79939 8.13758 6.91246C7.86293 7.02554 7.61338 7.19187 7.40334 7.40186C7.19329 7.61186 7.0269 7.86137 6.91376 8.13599C6.80063 8.41062 6.74298 8.70492 6.74416 9.00194C6.74533 9.29895 6.8053 9.59279 6.92061 9.86652C7.03591 10.1402 7.20427 10.3884 7.41597 10.5967"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 15L15 3"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 14.028C7.9869 14.1729 8.49199 14.2477 9 14.25C12.1365 14.25 14.823 11.2403 15.9427 9.7785C16.1157 9.55553 16.2095 9.28132 16.2094 8.99912C16.2093 8.71692 16.1152 8.4428 15.942 8.22C15.5485 7.70662 15.1263 7.21584 14.6775 6.75"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeSlashIconSVG;
