/** Resource: https://jakzaizzat.com/how-to-build-custom-checkbox-in-tailwindcss/ */

const Checkbox = ({
  name,
  checked,
  label = "",
  className = "h-5 w-5",
  onChange = null,
  value = true,
  disabled = false,
  containerStyle = {},
  containerClassName = "relative flex items-center space-x-2",
  labelClass = "select-none text-lg focus-within:font-medium cursor-pointer",
}) => {
  return (
    <div className={containerClassName} style={containerStyle}>
      <input
        type="checkbox"
        id={`checkbox-${name}`}
        value={value}
        checked={checked}
        className={`rounded ${className}`}
        onChange={(value) => (onChange ? onChange(value) : null)}
        disabled={disabled}
      />
      <label htmlFor={`checkbox-${name}`} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
