import React from "react";

export default function SuggestionButton({
  disabledPrev = false,
  disabledNext = false,
  onClick = () => {},
}) {
  return (
    <div className="flex flex-row text-sm space-x-3">
      <button
        type="button"
        className={`bg-default rounded-full py-1 px-4 ${
          disabledPrev ? "opacity-50" : "hover:bg-defaultHover duration-100"
        }`}
        disabled={disabledPrev}
        onClick={() => onClick("prev")}
      >
        Previous Suggestion
      </button>
      <button
        type="button"
        className={`bg-default rounded-full py-1 px-4 ${
          disabledNext ? "opacity-50" : "hover:bg-defaultHover duration-100"
        }`}
        disabled={disabledNext}
        onClick={() => onClick("next")}
      >
        Next Suggestion
      </button>
    </div>
  );
}
