import AddIconCircleSVG from "assets/svg/add-circle-icon";
import EyeIconSVG from "assets/svg/eye-icon";
import EyeSlashIconSVG from "assets/svg/eye-slash-icon";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { HiArrowRight } from "react-icons/hi";
import "./styles.css";

const Input = (
  {
    autoFocus = false,
    validationPassed = false,
    showInputValidation = false,
    type = "text",
    name = "",
    label = null,
    inputContainerClass = "",
    inputIcon = null,
    iconPosition = "right", // ["left", "right"]
    placeholder = "",
    errorMessage = null,
    outsideLabel = "",
    inputClass = "",
    labelClass = "input-label",
    outsideLabelClass = "",
    paddingTop = null,
    errorMessagePosition = "top",
    showPasswordStrength = false,
    showCounter = false,
    disabledInput = false,
    masked = false,
    showGo = false,
    visible = true,
    extra = { show: false, label: "" },
    onChangeVisible = () => {},
    onReloadSnapshot = () => {},
    showList = false,
    noPaddingRight = false,
    showEmptyIndicator = true,
    list = [],
    onSelect = () => {},
    noLabelPaddingY = "0.6rem",
    rightButton = null,
    additionalStyles = {},
    ...props // make sure this pops here is for input only
  },
  ref
) => {
  const padding = () => {
    let result = "2.5rem";
    if (showPasswordStrength) {
      result = "9rem";
    } else if (showInputValidation) {
      result = "3rem";
    } else if (showCounter) {
      result = "3.7rem";
      if (masked) {
        result = "4.5rem";
      }
    } else if (masked || showGo) {
      result = "2rem";
      if (showGo) {
        result = "5rem";
      }
    } else if (rightButton) {
      result = "5.5rem";
    }
    return result;
  };

  const noPadding = () => {
    let result = "0px";
    if (type === "number") {
      result = "10px";
    }
    return result;
  };

  const [content, setContent] = useState(list);
  const [open, setOpen] = useState(false);

  const handleSelect = (obj) => {
    onSelect(obj);
    setOpen(false);
  };
  // useEffect
  useEffect(() => {
    if (showList) {
      if (props.value) {
        let result = _.filter(list, function (b) {
          let label = _.lowerCase(b);
          return label.includes(_.lowerCase(props.value));
        });
        setContent(result);
      } else {
        setContent(list);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <div className={inputContainerClass}>
      {outsideLabel && (
        <label
          htmlFor={name}
          className={`input-label-upper ${outsideLabelClass}`}
        >
          {outsideLabel}
        </label>
      )}
      {errorMessage && errorMessagePosition === "top" && (
        <p className="input-error-message">* {errorMessage}</p>
      )}

      <div className={`input-wrapper flex items-center ${masked && "pr-3"}`}>
        {props?.value?.length > 1 && showInputValidation && (
          <div
            className={`validation-container ${
              validationPassed ? "bg-lightGreen" : "bg-red"
            } ${outsideLabel && "top-2.5"}`}
          >
            {validationPassed ? (
              <FaCheck className="icon-input" />
            ) : (
              <FaTimes className="icon-input" />
            )}
          </div>
        )}

        {inputIcon && (
          <div
            className={`h-full absolute items-center flex ${iconPosition}-3`}
          >
            {inputIcon}
          </div>
        )}
        {!_.isEmpty(rightButton) && (
          <button
            type="button"
            className={`${
              rightButton?.applied ? "text-lightGreen" : "text-primary"
            } absolute right-3 top-3.5`}
            onClick={rightButton.onClick}
          >
            <div className="text-sm font-bold tracking-lightwide flex items-center">
              {rightButton?.applied && <FaCheck className="mb-1" />}
              <span className="ml-2">{rightButton?.btnText}</span>
            </div>
          </button>
        )}
        <input
          id={name}
          disabled={disabledInput}
          className={`input-class ${inputClass} ${outsideLabel && "mt-2"} ${
            disabledInput && "bg-lightGray"
          }`}
          style={{
            paddingLeft: iconPosition === "left" ? "2.5rem" : "1rem",
            paddingRight: noPaddingRight ? noPadding() : padding(),
            borderColor: errorMessage && "red",
            paddingTop: !label ? noLabelPaddingY : paddingTop,
            paddingBottom: !label && noLabelPaddingY,
            ...additionalStyles,
          }}
          type={type}
          autoFocus={autoFocus}
          name={name}
          onFocus={() => setOpen(true)}
          placeholder={placeholder}
          {...props} // so that user can select whether controlled input or uncontrolled
          ref={ref}
        />
        {label && (
          <label
            htmlFor={name}
            className={labelClass}
            style={{ paddingLeft: iconPosition === "left" ? "2.5rem" : "1rem" }}
          >
            {label}
          </label>
        )}
        {extra.showAddButton && (
          <div className="absolute -bottom-0.5 left-28 ml-1">
            <button
              type="button"
              onClick={() => extra.onPressAdd()}
              className="btn-remove rounded-full"
            >
              <AddIconCircleSVG className="m-auto w-8 h-8" />
            </button>
          </div>
        )}
        {extra.show && (
          <span className={`extra-label ${extra.showAddButton && "ml-3"}`}>
            {extra.label}
          </span>
        )}
        {showCounter && (
          <span
            className={`text-xs opacity-70 absolute ${
              masked ? "top-5 right-10" : "top-3.5 right-2.5"
            }`}
          >
            {`${_.size(props?.value)}/${props?.maxLength}`}
          </span>
        )}
        {masked && (
          <button
            type="button"
            className={`masked-button-input ${label ?? "top-1.5"}`}
            onClick={onChangeVisible}
          >
            {visible ? (
              <EyeIconSVG className="mx-auto" />
            ) : (
              <EyeSlashIconSVG className="mx-auto" />
            )}
          </button>
        )}
        {showGo && (
          <button
            type="button"
            className={`reload-button-go bg-primary rounded-full text-white text-sm items-center py-1 px-2 mt-1 hover:bg-primaryHover cursor-pointer ${
              disabledInput ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={onReloadSnapshot}
          >
            <p className="flex items-center text-12-5">
              <span>LOAD</span>
              <HiArrowRight className="text-base ml-1" />
            </p>
          </button>
        )}
      </div>

      {errorMessage && errorMessagePosition === "bottom" && (
        <p className="input-error-message mt-2 -mb-1">* {errorMessage}</p>
      )}
      {showList && (
        <ul
          className={`${
            !showEmptyIndicator && _.isEmpty(content)
              ? ""
              : "input-list-container"
          } input-scroll z-50 ${open ? "block" : "hidden"}`}
        >
          {showEmptyIndicator && _.isEmpty(content) && (
            <li className="p-2 text-center opacity-50">{`No Data ...`}</li>
          )}
          {(content || []).map((obj, index) => (
            <li
              key={index}
              className="input-list"
              onClick={() => handleSelect(obj)}
            >
              {obj}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const InputField = React.forwardRef(Input);

export default InputField;
