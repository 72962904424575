const InstallerSteps = ({ isManual = true, steps = [], manualContent }) => {
  if (isManual) {
    if (manualContent) {
      return manualContent;
    }
    return (
      <p>
        Want to manually install ConversionCow instead? Insert the following
        script right before the closing{" "}
        <code className="p-1 text-sm rounded-md text-orange-600 bg-gray">
          &lt;/head&gt;
        </code>{" "}
        tag of your HTML:
      </p>
    );
  }

  return (
    <>
      <h3 className="mb-2 text-xl font-medium">How to setup</h3>
      <ol>
        {steps.map((step, stepIndex) => (
          <li key={`installer-step-${stepIndex}`} className="mb-1 text-sm">
            {stepIndex + 1}
            {". "}
            {step}
          </li>
        ))}
      </ol>
    </>
  );
};

export default InstallerSteps;
