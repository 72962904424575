import styled from "styled-components";
import { device } from "../../../screens";
import StarIconPreview from "assets/svg/star-icon-preview";
import { sanitizeValueHTML } from "utils/constant";

const TestimonialsCardContainer = styled.div`
  position: relative;
  width: 100%;
`;
const TestimonialsCardQuote = styled.div`
  margin: 0;
  letter-spacing: -0.03em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 16px;
  line-height: 24px;

  @media ${device.xs} {
    font-size: 18px;
    line-height: 27px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
const TestimonialsCardPerson = styled.div`
  display: inline-flex;

  align-items: center;

  margin-top: 16px;

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-top: 20px;
  }
`;
const TestimonialsCardPersonImage = styled.img`
  object-fit: cover !important;
  border-radius: 9999px;
  margin-right: 12px;

  width: 42px;
  height: 42px;

  @media ${device.xs} {
    width: 50px;
    height: 50px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    width: 60px;
    height: 60px;
  }
`;
const TestimonialsCardPersonName = styled.p`
  margin: 0;
  font-weight: 700;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 14px;
  line-height: 17px;

  @media ${device.xs} {
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;
const TestimonialsCardPersonPosition = styled.p`
  margin: 0px;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 12px;
  line-height: 14px;

  @media ${device.xs} {
    font-size: 14px;
    line-height: 17px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const StarContainer = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
`;

const TestimonialsCard = ({ width, testimonial }) => {
  return (
    <TestimonialsCardContainer>
      {testimonial.rating ? (
        <StarContainer>
          {Array(5)
            .fill(0)
            .map((_, ratingIndex) => {
              const count = ratingIndex + 1;
              return (
                <StarIconPreview
                  active={testimonial.rating >= count}
                  key={`testimonial-rating-${ratingIndex}`}
                  responsive={width}
                />
              );
            })}
        </StarContainer>
      ) : null}
      <TestimonialsCardQuote
        responsive={width}
        dangerouslySetInnerHTML={{
          __html: sanitizeValueHTML(testimonial.quote),
        }}
      />
      <TestimonialsCardPerson responsive={width}>
        {testimonial.image_url && (
          <TestimonialsCardPersonImage
            responsive={width}
            src={testimonial.image_url}
          />
        )}
        <div>
          <TestimonialsCardPersonName responsive={width}>
            {testimonial.name}
          </TestimonialsCardPersonName>
          <TestimonialsCardPersonPosition responsive={width}>
            {testimonial.position}
          </TestimonialsCardPersonPosition>
        </div>
      </TestimonialsCardPerson>
    </TestimonialsCardContainer>
  );
};

export default TestimonialsCard;
