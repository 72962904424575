import React from "react";
import { Helmet } from "react-helmet";

const PreviewLayout = ({ children, title }) => {
  return (
    <div
      className="w-screen h-screen lg:static lg:overflow-y-auto lg:overflow-x-hidden lg:flex-grow flex flex-col flex-1"
      style={{ backgroundColor: "#434261" }}
    >
      {title && (
        <Helmet>
          <title>{title} - ConversionCow</title>
        </Helmet>
      )}
      <main>{children}</main>
    </div>
  );
};

export default PreviewLayout;
