import { getIcon } from "assets/icons/icons-list";
import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import ContentIconSelector from "components/campaigns/icons/content-icon-selector";
import InputDoubleField from "components/input/input-double";
import WYSIWYGEditorDraft, {
  converToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import {
  MAX_TITLE_LENGTH,
  TEMPLATEOBJ,
  TITLESUGGESTIONS,
  positions,
  sanitizeValueHTML,
} from "utils/constant";
import SuggestionButton from "./component/suggestions-btn";

export default function StatisticsData({
  handleChangeStep = () => console.log("We are missing a function here"),
  handleOnSave = () => {},
  loading = false,
  validationErrors = null,
  setState,
}) {
  const { setSelectedTab, popup, setPopup } = useContext(Context); // context
  const initials = useMemo(() => {
    if (_.isEmpty(popup?.template_content)) {
      return _.find(TEMPLATEOBJ, { type: "statistics-data" });
    } else {
      return JSON.parse(popup?.template_content);
    }
  }, [popup.template_content]);
  // start wysiwyg
  const titleInitials = useMemo(() => {
    let data = {};
    if (_.isEmpty(popup?.template_content)) {
      data = _.find(TEMPLATEOBJ, { type: "statistics-data" });
    } else {
      data = JSON.parse(popup?.template_content);
    }
    return converToEditorState(data.title || "");
  }, [popup.template_content]);
  const [editorState, setEditorState] = useState(titleInitials);
  // end wysiwyg
  const [suggestion, setSuggestion] = useState(0);
  const [object, setObject] = useState(initials);

  const handleChangeValue = (value, element) => {
    let payload = _.clone(object);
    let finalValue = value;
    let data = payload;
    if (element === "title") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
        data = update(payload, {
          [element]: { $set: finalValue },
          title_decoration: { $set: "normal" },
        });
      } else {
        data = update(payload, {
          [element]: { $set: sanitizeValueHTML(finalValue) },
          title_decoration: { $set: "normal" },
        });
      }
    } else {
      data = update(payload, {
        [element]: { $set: finalValue },
      });
    }
    setObject(data);
    //set to popout
    if (element === "title") {
      setPopup((prev) => ({
        ...prev,
        template_content: JSON.stringify(data),
      }));
    }
  };

  const handleSelectIcon = (icon, index) => {
    let payload = _.clone(object);
    delete payload.stats[index].icon_url;
    delete payload.stats[index].icon_id;
    let data = update(payload, {
      stats: {
        [index]: {
          icon: { $set: icon },
        },
      },
    });
    setObject(data);
    handleOnSave(data);
  };

  const handleChangeText = (value, index, element) => {
    let payload = _.clone(object);
    let data = update(payload, {
      stats: {
        [index]: {
          [element]: { $set: value },
        },
      },
    });
    setObject(data);
    if (element === "visible") {
      handleOnSave(data);
    }
  };

  const handleOnBlur = (e) => {
    handleOnSave(object);
  };

  const checkPoints = () => {
    const stats = _.clone(object.stats);
    const obj = _.filter(stats, function (o) {
      return o.data && o.visible;
    });
    return _.size(obj);
  };

  const checkIconsVisible = () => {
    let stats = object.stats;
    let count = _.size(
      _.filter(stats, function (o) {
        return o.visible;
      })
    );
    return count;
  };

  const validateBeforeNext = () => {
    let payload = _.clone(validationErrors);

    if (_.isEmpty(object?.title)) {
      payload.title = "Title is required";
    }
    if (checkIconsVisible() < 2) {
      payload.icons = "Cannot hide more than 2 Statistics Data.";
      payload.iconsError = [true, true];
    }
    if (checkPoints() < 2) {
      payload.icons = "At least 2 Statistics Data are required.";
      payload.iconsError = [true, true];
    }

    setState((prev) => ({
      ...prev,
      validationErrors: {
        ...prev.validationErrors,
        ...payload,
      },
    }));
    if (_.isEmpty(payload)) {
      handleChangeStep();
    }
  };

  //suggesstions
  const handleSuggestion = (type) => {
    const title = TITLESUGGESTIONS;
    const size = _.size(TITLESUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = title[0];
      } else {
        value = title[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = title[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = title[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = title[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      let payload = _.clone(object);
      let data = update(payload, {
        title: { $set: value },
      });
      //handle set
      setEditorState(converToEditorState(value));
      setObject(data);
      handleOnSave(data);
    }
  };

  return (
    <>
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Add Your Content</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <div
          className="bg-white rounded-xl relative campaign-left-max"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            {/* first */}
            <div>
              <p className="text-xl pl-1 pb-2">Enter your statistics/data</p>
            </div>
            <div className="w-full mb-2">
              <p className="pl-1 pt-2 pb-2">Type your heading here:</p>
              <WYSIWYGEditorDraft
                placeholder={`e.g. Excepteur sint occaecat cupidatat non proident, sunt in cul...`}
                onChange={(value) => {
                  handleChangeValue(value, "title");
                }}
                onBlur={handleOnBlur}
                errorMessage={validationErrors?.title}
                showCounter={true}
                maxLength={MAX_TITLE_LENGTH}
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>
            {/* title_decoration */}
            <div className="w-full flex items-center 5md:block 3lg:flex 3lg:items-center justify-start">
              <SuggestionButton onClick={(e) => handleSuggestion(e)} />
            </div>
            {/* icons */}
            <p className="pl-1 pt-4 pb-2">
              Complete at least 2 stats or data points here:
            </p>
            <div className="mt-3">
              {validationErrors?.icons && (
                <p className="text-red text-xs mb-3">
                  * {validationErrors?.icons}
                </p>
              )}
              {((object && object.stats) || [0, 1, 2, 3]).map(
                (value, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-row justify-center space-x-6 pb-5"
                    >
                      <ContentIconSelector
                        onClick={(e) => handleSelectIcon(e, index)}
                        Icon={getIcon(value?.icon?.id)}
                        position={positions[index]}
                      />
                      <InputDoubleField
                        name={["data", "subtext"]}
                        label={["Data", "Subtext"]}
                        placeholder={["e.g. Data", "e.g. Subtext"]}
                        maxLength={[10, 70]}
                        valueUpper={value?.data || ""}
                        valueLower={value?.subtext || ""}
                        error={
                          value?.data
                            ? false
                            : validationErrors?.iconsError?.[index]
                        }
                        visible={value?.visible}
                        onBlurInputUpper={handleOnBlur}
                        onChangeUpper={(e) =>
                          handleChangeText(e?.target?.value, index, "data")
                        }
                        onBlurInputLower={handleOnBlur}
                        onChangeLower={(e) =>
                          handleChangeText(e?.target?.value, index, "subtext")
                        }
                        onChangeVisible={() => {
                          handleChangeText(!value.visible, index, "visible");
                        }}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("template")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                onClick={validateBeforeNext}
                disabled={loading}
                icon={
                  <div className="absolute right-2 bottom-0 top-0 flex items-center">
                    {loading && (
                      <LoadingIndicator className="text-white w-4 h-4" />
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
        {/* end card start */}
      </div>
    </>
  );
}
