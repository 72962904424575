import React, { useContext } from "react";
import { Context } from "context/context";
import { getIcon } from "assets/icons/icons-list";
import SecureShieldIconSVG from "assets/svg/secure-shield";
import PhotographIconSVG from "assets/svg/example/photograph-icon";
import StarIconSVG from "assets/svg/example/star-icon";
import DiscountIconSVG from "assets/svg/example/discount-icon";
import HeaderExample from "./component/header";

const IconsExample = [
  <SecureShieldIconSVG className="icon-size text-tabBorder" />,
  <PhotographIconSVG className="icon-size text-tabBorder" />,
  <StarIconSVG className="icon-size text-tabBorder" />,
  <DiscountIconSVG className="icon-size text-tabBorder" />,
];

const showDummy = ["template", "content"];

export default function ProofPointsTab({ popup }) {
  const { view, selectedTab } = useContext(Context);
  const mobile = view === "mobile";
  // const mobileClass = mobile ? "flex space-x-5 mb-8" : "";
  const desktopClass = "grid grid-cols-2 gap-y-8 gap-x-2";
  const titleDClass = "text-xs font-bold pt-2";
  const titleMClass = "text-base font-bold pt-2";
  const subTextDClass = "text-10 pt-1";
  const subTextMClass = "text-12-5 pt-1";
  const show = showDummy.includes(selectedTab);

  const data = popup.template_content && JSON.parse(popup.template_content);

  return (
    <div
      className={!mobile ? "proof-points-tab" : ""}
      style={{ height: mobile ? "619px" : "635px" }}
    >
      <HeaderExample data={data} popup={popup} show={show} />
      <div className={`pt-6 ${desktopClass}`}>
        {(data?.icons || []).map((obj, index) => {
          const Icon = getIcon(obj.icon?.id);
          return (
            <div
              key={index}
              className={` ${!obj.visible && "hidden"}`} //${mobileClass}
            >
              <div>
                {Icon ? (
                  <Icon
                    className="icon-size"
                    fill={popup.button_colour ? popup.button_colour : "#000"}
                    sx={{
                      fontSize: 50,
                      color: popup.button_colour ? popup.button_colour : "#000",
                    }}
                  />
                ) : (
                  show && IconsExample[index]
                )}
              </div>
              <div className="overflow-hidden">
                <p
                  className={`${mobile ? titleMClass : titleDClass} ${
                    !obj?.title && "text-tabBorder"
                  }`}
                >
                  {obj?.title || (show && "Ut aliquip ex ea commodo consequat")}
                </p>
                <p
                  className={`${mobile ? subTextMClass : subTextDClass} ${
                    !obj?.subtext && "text-tabBorder"
                  }`}
                >
                  {obj?.subtext ||
                    (show && "Lorem ipsum dolor sit consectetur adipiscing")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
