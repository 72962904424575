import React, { useEffect, useState } from "react";
import Input from "components/input/input";
import toast from "react-hot-toast";
import Button from "components/button/button";
import { Link, useHistory } from "react-router-dom";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import GoogleAuthButton from "components/google-auth-button/google-auth-button";
import Divider from "components/divider/divider";
import { API_ENDPOINT, RECAPTCHA_KEY } from "configs/api";
import { FaChevronRight } from "react-icons/fa";
import LoadingIndicator from "assets/svg/loading-indicator";
import "../../assets/css/pages/login.css";
import VerificationContent from "pages/sign-up/verification-content";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import CustomCheckbox from "components/checkbox/custom-checkbox";

const Login = ({
  user,
  setUserData,
  showSignupModal = {}, // when guest decides to use signup process
  modal = false, // for modal
  hide = null, // for modal
}) => {
  const history = useHistory();
  const { http, localstorage } = global.services;
  const redirectUrl = localstorage.get("redirectUrl");
  const [accessToken, setAccessToken] = useState(null);
  const [isVerification, setIsVerification] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const [recapthcaError, setRecapthcaError] = useState(null);
  const [state, setState] = useState({
    email: "",
    password: "",
    showPassword: false,
    rememberMe: false,
    loading: false,
    validationErrors: null,
    recaptchaToken: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (RECAPTCHA_KEY) {
      if (!recaptcha) {
        return setRecapthcaError("Recaptcha is required.");
      }

      state.recaptchaToken = recaptcha;
    }

    setState((prev) => ({ ...prev, loading: true }));

    try {
      const req = await http.post("auth/login", state);

      if (!req.data.user.email_verified_at) {
        setAccessToken(req.data.access_token);
        setState((prev) => ({ ...prev, loading: false }));
        return setIsVerification(true);
      }

      if (req && user?.user?.role === "guest")
        return switchToPermanentAccount(req.data);

      localstorage.set("user_data", JSON.stringify(req.data));
      setUserData(req.data);
      history.replace(
        decodeURIComponent(redirectUrl.value) === "null"
          ? "/dashboard"
          : decodeURIComponent(redirectUrl.value)
      );
      updateAuthentication();
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data,
        }));

      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  // to update user's last_login
  const updateAuthentication = async () => {
    try {
      await http.post(`accounts/update-auth`);
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : "It appears that something went wrong"
      );
    }
  };

  const switchToPermanentAccount = async (data) => {
    try {
      await http.post(`accounts/switch/${data.user.id}`);

      localstorage.set("user_data", JSON.stringify(data));
      setUserData(data);
      hide(data);
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data,
          loading: false,
        }));

      setState((prev) => ({ ...prev, loading: false }));
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : "It appears that something went wrong"
      );
    }
  };

  const sendEmailVerification = async (token) => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      const config = {
        baseURL: API_ENDPOINT,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const params = { email: state.email };
      await axios.post("auth/resend-email-verification", params, config);
      setIsVerification(true);
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateCheckbox = (e) => {
    const isChecked = e.target.checked;
    setState({ ...state, rememberMe: isChecked });
  };

  const handleGoogleLogin = () => {
    window.location.href = `${API_ENDPOINT}/auth/login/google`;
  };

  const onChangeCaptcha = (value) => {
    setRecaptcha(value);
  };

  useEffect(() => {
    if (user && !modal) return history.replace("/dashboard");

    return () => {
      setState({
        email: "",
        password: "",
        showPassword: false,
        rememberMe: false,
        loading: false,
        validationErrors: null,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-wrapper">
      {isVerification ? (
        <VerificationContent
          loading={state.loading}
          resend={() => sendEmailVerification(accessToken)}
        />
      ) : (
        <>
          <h2 className="column-title">
            Welcome Back!
            <small className="column-sub-title">Log into ConversionCow</small>
          </h2>

          <form
            className="column-container"
            id="login-page-component"
            onSubmit={handleLogin}
          >
            <Input
              inputContainerClass="input-container"
              value={state.email}
              autoFocus={true}
              name="email"
              outsideLabel="Email Address"
              outsideLabelClass="text-[#616162] text-xs"
              placeholder="name@example.com"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.email}
            />
            <Input
              inputContainerClass="input-container"
              value={state.password}
              outsideLabel="Password"
              outsideLabelClass="text-[#616162] text-xs"
              name="password"
              type={state.showPassword ? "text" : "password"}
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.password}
            />

            <div className="checkbox-container leading-6 px-1">
              {/* <Checkbox
                label={<p className="checkbox-text">Keep me logged in</p>}
                name={"termsAndConditions"}
                checked={state.terms}
                onChange={handleUpdateCheckbox}
              /> */}
              <CustomCheckbox
                label="Keep me logged in"
                labelClass="text-xs font-light"
                name={"termsAndConditions"}
                checked={state.terms}
                onChange={handleUpdateCheckbox}
              />

              <Link to={"/forgot-password"} className="forgot-password-link">
                Forgot password?
              </Link>
            </div>

            {RECAPTCHA_KEY && (
              <div
                style={{
                  width: "100%",
                  marginTop: "15px",
                  marginBottom: "15px",
                  display: "relative",
                }}
                className="px-1"
                id="recaptcha-v3"
              >
                <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={onChangeCaptcha} />
                {/* <GoogleReCaptcha onVerify={onChangeCaptcha} /> */}
                {recapthcaError && (
                  <p className="text-red text-xs mt-1">{recapthcaError}</p>
                )}
              </div>
            )}
            <div className="px-1">
              <Button
                buttonName="Login"
                buttonClass="relative py-3 bg-secondary"
                buttonType="secondary"
                buttonWidth="100%"
                onClick={handleLogin}
                disabled={state.loading}
                type="submit"
                icon={
                  <div className="absolute right-3 bottom-0 top-0 flex items-center">
                    {state.loading ? (
                      <LoadingIndicator className="text-white w-5 h-5" />
                    ) : (
                      <FaChevronRight className="text-white text-base 2md:text-lg" />
                    )}
                  </div>
                }
              />
              <Divider text="or" />

              <GoogleAuthButton
                onClick={handleGoogleLogin}
                title="Log in with Google"
              />
              <div className="mt-8">
                <div className="no-account-text text-xs">
                  Don’t have an account?{" "}
                  <Link
                    to={"/sign-up"}
                    className="no-account-link font-medium underline"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
