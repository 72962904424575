const ToggleMobileIcon = ({ className = "" }) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66667 1.3335H2.33333C1.59695 1.3335 1 1.93045 1 2.66683V13.3335C1 14.0699 1.59695 14.6668 2.33333 14.6668H7.66667C8.40305 14.6668 9 14.0699 9 13.3335V2.66683C9 1.93045 8.40305 1.3335 7.66667 1.3335Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.9668 12H5.03346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ToggleMobileIcon;
