const PencilIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5422 5.1875C14.8966 4.83313 15.0916 4.3625 15.0916 3.86188C15.0916 3.36125 14.8966 2.89063 14.5422 2.53625L13.0553 1.04938C12.7009 0.695 12.2303 0.5 11.7297 0.5C11.2291 0.5 10.7584 0.695 10.405 1.04844L0.4375 10.985V15.1241H4.57469L14.5422 5.1875ZM11.7297 2.375L13.2175 3.86094L11.7269 5.34594L10.24 3.86L11.7297 2.375ZM2.3125 13.2491V11.7631L8.9125 5.18375L10.3994 6.67063L3.80031 13.2491H2.3125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PencilIconSVG;
