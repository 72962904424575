import { Fragment } from "react";
import styled from "styled-components";
import { device } from "../../screens";
import CheckCircleSVG from "assets/svg/check-circle-preview";
import HeaderTemplate from "../commons/header-template";
import { sanitizeValueHTML } from "utils/constant";

const PromotionsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 29px;
  row-gap: 18px;

  margin-top: 20px;

  @media ${device.xs} {
    margin-top: 36px;
  }
`;
const PromotionsItem = styled.div`
  width: 100%;
  display: inline-flex;
  column-gap: 10px;

  ${({ visible }) => (!visible ? `display: none;` : "")}

  @media ${device.xs} {
    column-gap: 13px;
  }
`;

const PromotionsCover = styled.img`
  ${({ hasCover }) =>
    !hasCover ? `display: none;` : `object-fit: contain;width: 100%;`}

  margin-top: 28px;

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-top: ${({ hasTitle }) => (hasTitle ? "36px" : "0px")} !important;
  }
`;

const TicksText = styled.p`
  margin: 0;
  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 12px;
  line-height: 18px;

  @media ${device.xs} {
    font-size: 14px;
    line-height: 21px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const Div = styled.div`
  margin-top: 2px;
`;

const Promotions = ({ data }) => {
  return (
    <Fragment>
      <div style={{ width: "100%", height: "100%" }}>
        {data.title && (
          <HeaderTemplate
            family={data.font?.family}
            width={data.width}
            dangerouslySetInnerHTML={{ __html: sanitizeValueHTML(data.title) }}
          />
        )}
        <PromotionsCover
          hasCover={!!data.thumbnail.url}
          hasTitle={!!data.title}
          alt="Promotion Cover"
          src={data.thumbnail.url}
          responsive={data.width}
        />
        <PromotionsContainer>
          {data.points.map((point, pointIndex) => (
            <Fragment key={pointIndex}>
              {point.visible ? (
                <PromotionsItem
                  key={`promotions-${pointIndex}`}
                  visible={point.visible}
                >
                  {data.show_ticks && point.text && (
                    <Div>
                      <CheckCircleSVG
                        responsive={data.width}
                        color={data?.button_colour || "#808080"}
                      />
                    </Div>
                  )}
                  <TicksText responsive={data.width}>{point.text}</TicksText>
                </PromotionsItem>
              ) : null}
            </Fragment>
          ))}
        </PromotionsContainer>
      </div>
    </Fragment>
  );
};

export default Promotions;
