import styled from "styled-components";
import { motion } from "framer-motion";
import { device, size } from "../../screens";

const Content = styled(motion.div)`
  width: 100%;
  overflow: visible;
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 999999;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-content: flex-start;
  background: ${({ background }) => background} !important;
  height: 100vh;

  ${({ isPeep, overlay }) =>
    isPeep && !overlay
      ? "cursor: pointer !important;"
      : "cursor: default !important;"}

  @media ${device.xs} {
    min-width: 0px;
    max-width: ${size.xs};
  }

  @media (min-width: 1${({ fullW }) => fullW + 1}px) {
    min-width: 0px;
    max-width: ${({ overlay }) => (overlay ? size.s4md : size.s3md)};
  }
`;
//
Content.defaultProps = {
  background: "transparent",
  overlay: false,
  fullW: 120,
  isPeep: false,
};

export default Content;
