const VerifyEmailIcon = ({ className = "" }) => {
  return (
    <svg
      width="80"
      height="44"
      viewBox="0 0 80 44"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 25.0864C0.546367 24.2927 1.30095 24.086 2.24965 24.0923C7.55677 24.1283 12.8639 24.1095 18.1726 24.1111C18.4591 24.1111 18.7471 24.1033 19.0305 24.1361C19.7694 24.2207 20.3142 24.789 20.3862 25.5232C20.4567 26.2465 20.0136 26.9008 19.306 27.1388C18.9757 27.25 18.6391 27.2343 18.3025 27.2343C12.9171 27.2343 7.53172 27.2186 2.14633 27.25C1.20075 27.2562 0.49627 26.9807 0.00313104 26.1791V25.0864H0Z"
        fill="#28265B"
      />
      <path
        d="M51.617 0.700279C59.0344 0.700279 66.455 0.776989 73.8709 0.667402C77.4497 0.614174 80.0312 3.30531 79.9999 6.82617C79.9075 17.0803 79.9686 27.3361 79.967 37.5918C79.967 41.36 77.6563 43.6723 73.8912 43.6723C59.0031 43.6723 44.115 43.6738 29.2269 43.6723C26.3479 43.6723 24.1577 41.9909 23.5096 39.3217C23.3797 38.7847 23.3797 38.2383 23.3797 37.692C23.3781 27.358 23.375 17.0255 23.3797 6.69153C23.3812 3.00316 25.6841 0.703409 29.3631 0.701844C36.7805 0.698713 44.1995 0.701844 51.617 0.701844V0.700279ZM27.2481 39.8054C27.7522 40.3706 28.4019 40.5522 29.1345 40.5506C32.0214 40.5444 34.9082 40.5475 37.7934 40.5475C49.8589 40.5475 61.9259 40.5475 73.9914 40.5506C74.7945 40.5506 75.5444 40.452 76.0923 39.7773C76.7342 39.245 76.8454 38.5233 76.8454 37.7452C76.8407 27.3862 76.8438 17.0287 76.8391 6.66961C76.8391 6.42226 76.9049 6.16082 76.764 5.90564C76.4399 5.94791 76.2959 6.22031 76.1017 6.40347C73.467 8.88169 70.8369 11.3646 68.2115 13.8538C65.248 16.6639 62.2923 19.4803 59.3334 22.2951C58.1937 23.2688 57.1276 24.3271 55.8611 25.1522C53.1903 26.8915 50.5117 27.0167 47.7611 25.3322C46.4022 24.4993 45.2845 23.38 44.0712 22.3734C44.0242 22.3123 43.9866 22.2419 43.9318 22.1902C38.2834 16.8502 32.635 11.5102 26.9819 6.17491C26.8677 6.06689 26.7863 5.84928 26.5045 5.96043C26.5045 6.28136 26.5045 6.61795 26.5045 6.95297C26.5045 12.2053 26.5045 17.4576 26.5045 22.7084C26.5045 27.7259 26.5076 32.7449 26.5013 37.7624C26.5013 38.5436 26.6172 39.2638 27.2512 39.8023L27.2481 39.8054ZM74.2294 3.86889H29.2566L29.1095 4.05989C29.2473 4.1272 29.4132 4.16478 29.5197 4.26497C35.406 9.81944 41.2877 15.3802 47.1725 20.9362C47.9114 21.6329 48.7067 22.2591 49.5865 22.7757C50.936 23.5694 52.2823 23.6117 53.6522 22.8305C54.4474 22.3765 55.1785 21.8364 55.8455 21.2149C57.3875 19.7777 58.9217 18.3327 60.4544 16.8862C65.0132 12.581 69.5688 8.27427 74.2309 3.87046L74.2294 3.86889Z"
        fill="#28265B"
      />
      <path
        d="M16.4395 18.0619C15.688 18.0619 14.935 18.0791 14.1835 18.0587C13.2661 18.0321 12.6431 17.4231 12.618 16.5668C12.5914 15.6713 13.2051 14.9731 14.1428 14.9527C15.724 14.9183 17.3068 14.9167 18.8879 14.9543C19.7725 14.9762 20.4081 15.6917 20.3955 16.523C20.383 17.3621 19.738 18.0259 18.8519 18.0556C18.0488 18.0822 17.2441 18.0619 16.441 18.0619H16.4395Z"
        fill="#28265B"
      />
      <path
        d="M8.87804 36.3675C8.09998 36.3675 7.32191 36.3879 6.54385 36.3612C5.66246 36.3315 5.05191 35.7429 5.00024 34.9272C4.94702 34.085 5.52 33.3226 6.41547 33.2881C8.0718 33.2255 9.73438 33.2271 11.3907 33.2928C12.2423 33.3273 12.8216 34.0834 12.7918 34.8756C12.7605 35.7194 12.1281 36.3331 11.2107 36.3628C10.4342 36.3879 9.65454 36.3675 8.87647 36.3675H8.87804Z"
        fill="#28265B"
      />
      <path
        d="M17.7655 36.355C17.4274 36.355 17.0877 36.38 16.7526 36.3519C15.9808 36.2845 15.4047 35.6928 15.3405 34.9335C15.2811 34.2337 15.7476 33.512 16.463 33.3539C17.382 33.1519 18.3276 33.1582 19.2465 33.3476C19.9823 33.4979 20.4348 34.1805 20.3925 34.9037C20.3471 35.6677 19.7835 36.2783 19.0133 36.3472C18.6015 36.3847 18.182 36.3534 17.7671 36.355H17.7655Z"
        fill="#28265B"
      />
      <path
        d="M5.9397 18.0604C5.60155 18.0604 5.26183 18.0776 4.92524 18.0557C4.0689 18.0009 3.45522 17.3747 3.4333 16.5543C3.40982 15.7387 3.99376 15.0295 4.83131 14.9638C5.55458 14.9074 6.28881 14.9105 7.01365 14.9575C7.906 15.0154 8.48993 15.709 8.45706 16.5762C8.42575 17.406 7.82302 18.0071 6.95259 18.0588C6.61601 18.0792 6.27629 18.0619 5.93814 18.0619L5.9397 18.0604Z"
        fill="#28265B"
      />
      <path
        d="M76.0938 39.7789C75.5474 40.4537 74.7975 40.5523 73.9929 40.5523C61.9274 40.5476 49.8603 40.5492 37.7949 40.5492C34.908 40.5492 32.0212 40.546 29.136 40.5523C28.4017 40.5523 27.752 40.3723 27.2495 39.8071C27.4405 39.5613 27.6096 39.2952 27.8256 39.0729C31.8396 34.9321 35.8708 30.8085 39.8692 26.652C41.2547 25.2118 42.6041 23.737 44.071 22.375C45.2843 23.3817 46.4021 24.501 47.761 25.3339C50.5116 27.0184 53.1902 26.8947 55.861 25.1538C57.1275 24.3288 58.1936 23.2689 59.3333 22.2968C61.572 24.645 63.7982 27.0043 66.051 29.34C68.5761 31.9576 71.1279 34.547 73.6578 37.1583C74.4876 38.0146 75.2828 38.9054 76.0938 39.7789Z"
        fill="#F45430"
      />
    </svg>
  );
};

export default VerifyEmailIcon;
