import React from "react";
import "assets/css/components/campaign-tests.css";
import PopoutDetails from "components/campaigns/details/popout/popout-details";
import CampaignDetailsButton from "components/campaigns/details/button";
import CampaignDetailsEyeSVG from "assets/svg/campaign-details-eye-icon";
import { FaChevronDown } from "react-icons/fa";
import Toggle from "components/toggle/toggle";
import PopoutA from "../../../../details/popout/table-statistics/popout-a";
import PopoutB from "../../../../details/popout/table-statistics/popout-b";
import PopoutDropdown from "components/campaigns/details/popout/popout-dropdown";
import { useHistory } from "react-router-dom";

const ABPopouts = ({ data = {}, onChangeStatus = {} }) => {
  const history = useHistory();

  const handlePreview = (id) => {
    history.push(`/campaigns/popup/preview/${id}/${data.campaign_id}`);
  };

  const PopupATestDetails = ({ record }) => {
    return (
      <div className={`w-full bg-white flex items-center`}>
        <div className="w-1/12 h-full py-5 pl-5">
          <div className={`ab-test-block bg-secondary`}>A</div>
        </div>
        <div className="w-full h-full py-5 pl-5">
          <PopoutDetails
            data={record?.popup_a}
            title={record?.popup_a?.title || record?.popup_a?.quote}
            abTest={record?.popup_b_id}
            abOngoing={!record?.test_has_ended}
            abTestDate={record?.abTestDate}
            isActive={record.active}
            statusDisplay="upper"
          >
            <CampaignDetailsButton
              onClick={() => handlePreview(record.popup_a_id)}
              className="mr-2"
            >
              <CampaignDetailsEyeSVG className="mr-2" />
              Preview
            </CampaignDetailsButton>
            <CampaignDetailsButton dropdown={PopoutDropdown(record?.popup_a)}>
              Options
              <FaChevronDown className="ml-2" />
            </CampaignDetailsButton>
          </PopoutDetails>
        </div>
        <div className="w-8/12 h-full py-5">
          <PopoutA
            data={{ ...record.popup_a, summary: record.popup_a_summary }}
          />
        </div>
      </div>
    );
  };

  const PopupBTestDetails = ({ record }) => {
    return (
      <div className={`w-full bg-white flex items-center`}>
        <div className="w-1/12 h-full py-5 pl-5">
          <div className={`ab-test-block bg-primary`}>B</div>
        </div>
        <div className="w-full h-full py-5 pl-5">
          <PopoutDetails
            data={record?.popup_b}
            title={record?.popup_b?.title || record?.popup_b?.quote}
            abTest={record?.popup_b_id}
            abOngoing={!record?.test_has_ended}
            abTestDate={record?.abTestDate}
            isActive={record.active}
            statusDisplay="upper"
          >
            <CampaignDetailsButton
              onClick={() => handlePreview(record.popup_b_id)}
              className="mr-2"
            >
              <CampaignDetailsEyeSVG className="mr-2" />
              Preview
            </CampaignDetailsButton>
            <CampaignDetailsButton dropdown={PopoutDropdown(record?.popup_b)}>
              Options
              <FaChevronDown className="ml-2" />
            </CampaignDetailsButton>
          </PopoutDetails>
        </div>
        <div className="w-8/12 h-full py-5">
          <PopoutB
            data={{ ...record.popup_b, summary: record.popup_b_summary }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex relative">
      <div className="w-full">
        <div className="w-full relative">
          <div className="flex items-center">
            <div className="w-full border-r border-thBorder">
              <PopupATestDetails record={data} />
              <PopupBTestDetails record={data} />
            </div>

            <div className="w-1/12 flex items-center justify-center">
              <Toggle
                checked={data?.active}
                onChange={() => onChangeStatus(data.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ABPopouts;
