import Card from "components/card/card";
import numeral from "numeral";

const StatisticsDashboard = ({ title, total, message, icon }) => {
  return (
    <Card
      padding="px-8 py-6.5 w-full flex items-center gap-4"
      className="border border-thBorder"
    >
      {icon && <div>{icon}</div>}
      <div className="flex-1">
        <p className="text-lg leading-5.5">{title}</p>
        <p className="text-sm leading-4.5 text-textGray opacity-60">
          {message}
        </p>
      </div>
      <div>
        <p className="text-3xl font-medium">{numeral(total).format("0,0")}</p>
      </div>
    </Card>
  );
};

export default StatisticsDashboard;
