import React from "react";
import { FaCheck } from "react-icons/fa";

const PasswordValidation = (props) => {
  const { passwordValidation } = props;

  return (
    <div className="w-full italic bg-white border-slate-200 p-2 border">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex flex-row items-center mb-2">
          {passwordValidation.passwordHasEightCharacters ? (
            <FaCheck className="text-green h-3 w-3 mr-1.5" />
          ) : (
            <div className={`p-1 border border-gray rounded-full mr-2`} />
          )}
          <p
            className={`text-xs 2md:text-sm 2xl:text-lg 3xl:text-2xl opacity-80 font-light ${
              passwordValidation.passwordHasEightCharacters && "text-green"
            }`}
          >
            At least 8 characters
          </p>
        </div>

        <div className="flex flex-row items-center mb-2">
          {passwordValidation.passwordHasUpperCaseLetter ? (
            <FaCheck className="text-green h-3 w-3 mr-1.5" />
          ) : (
            <div className={`p-1 border border-gray rounded-full mr-2`} />
          )}
          <p
            className={`text-xs 2md:text-sm 2xl:text-lg 3xl:text-2xl opacity-80 font-light ${
              passwordValidation.passwordHasUpperCaseLetter && "text-green"
            }`}
          >
            At least one uppercase
          </p>
        </div>

        <div className="flex flex-row items-center">
          {passwordValidation.passwordHasNumber ? (
            <FaCheck className="text-green h-3 w-3 mr-1.5" />
          ) : (
            <div className={`p-1 border border-gray rounded-full mr-2`} />
          )}
          <p
            className={`text-xs 2md:text-sm 2xl:text-lg 3xl:text-2xl opacity-80 font-light ${
              passwordValidation.passwordHasNumber && "text-green"
            }`}
          >
            At least one number
          </p>
        </div>

        <div className="flex flex-row items-center">
          {passwordValidation.passwordHasLowerCaseLetter ? (
            <FaCheck className="text-green h-3 w-3 mr-1.5" />
          ) : (
            <div className={`p-1 border border-gray rounded-full mr-2`} />
          )}
          <p
            className={`text-xs 2md:text-sm 2xl:text-lg 3xl:text-2xl opacity-80 font-light ${
              passwordValidation.passwordHasLowerCaseLetter && "text-green"
            }`}
          >
            At least one lowercase
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordValidation;
