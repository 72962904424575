const CampaignModalIconSVG = () => {
  return (
    <svg
      width="81"
      height="105"
      viewBox="0 0 81 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="40.2275"
        cy="64.0513"
        r="39.8504"
        fill="#EBEBEB"
        stroke="#BEBEBE"
        strokeWidth="0.754266"
      />
      <g clipPath="url(#clip0_1579_20229)">
        <path
          d="M25.9352 21.7342C26.6805 23.1389 27.138 18.4354 27.8581 18.0092C28.5782 17.5829 34.4935 13.8025 36.4105 12.8229C37.068 12.5238 37.7693 12.3974 38.4737 12.4507C39.178 12.5041 39.8716 12.7363 40.514 13.1338C42.4277 14.2573 43.3184 16.8968 43.7653 19.5225C44.3778 23.1444 45.1968 22.3844 45.6734 24.4286C46.2604 26.9466 46.6527 28.664 46.965 28.9899C47.2755 29.1585 47.6189 29.1831 47.935 29.0595C48.2511 28.9359 48.2883 29.0469 48.4261 28.8701C48.7005 28.5181 48.6963 27.9488 48.6917 26.5673C48.9012 23.4513 48.6223 20.2934 47.8712 17.2794C47.115 14.2525 43.7651 10.5429 43.7651 10.5429C43.7651 10.5429 38.7221 8.01174 35.6691 8.40236C32.6315 8.82279 29.2912 8.35595 27.073 10.9864C26.0024 12.1405 25.4125 12.5855 25.5568 16.2872"
          fill="#263238"
        />
        <path
          d="M31.996 48.3649C31.996 48.3649 29.0632 68.2045 28.8658 70.5932L53.9101 72.7846L49.8759 53.5005C49.8759 53.5005 48.6995 47.1475 48.1186 43.2009C47.1548 36.6524 50.6227 27.3902 50.6227 27.3902C51.2287 19.0893 46.8688 10.8271 38.833 10.4839L38.0876 10.4729C34.4761 10.5416 29.6119 11.1923 26.9865 13.7738C24.3612 16.3552 24.0124 19.5648 23.9172 24.4064C23.8219 29.2479 23.7956 28.4877 24.3255 32.7139C24.8553 36.9402 25.2646 44.2962 31.996 48.3649Z"
          fill="#FFBE9D"
        />
        <path
          d="M38.106 38.0005C38.1594 39.0925 37.8778 40.3459 37.146 41.233C36.4141 42.12 35.232 42.6408 34.1619 42.4029C33.1995 42.2346 32.3875 41.4836 31.6833 40.8022L38.106 38.0005Z"
          fill="white"
        />
        <rect
          x="45.0005"
          y="12.0005"
          width="2.81749"
          height="3.4436"
          fill="#FFBE9D"
        />
        <ellipse
          cx="33.7033"
          cy="35.5377"
          rx="0.80868"
          ry="0.80868"
          transform="rotate(1.79121 33.7033 35.5377)"
          fill="#EB996E"
        />
        <path
          d="M31.9945 48.3666C35.6015 48.3942 39.7194 47.9494 43.5777 44.2971C43.5777 44.2971 39.4681 51.0417 31.6812 50.2451L31.9945 48.3666Z"
          fill="#EB996E"
        />
        <path
          d="M49.5779 30.0469C49.7226 30.026 55.7126 29.8255 53.7122 35.5937C51.7119 41.3619 47.2801 38.4571 47.3219 38.2871C47.3636 38.1172 49.5779 30.0469 49.5779 30.0469Z"
          fill="#FFBE9D"
        />
        <path
          d="M49.7938 36.4123C49.7938 36.4123 49.858 36.5125 49.9865 36.6353C50.0747 36.7186 50.1782 36.782 50.2911 36.8217C50.404 36.8615 50.5241 36.8769 50.6445 36.8671C51.0489 36.7591 51.4234 36.54 51.7295 36.2322C52.0355 35.9245 52.2623 35.5392 52.3863 35.1158C52.5563 34.6528 52.6319 34.1604 52.6078 33.6722C52.6191 33.4865 52.588 33.3025 52.5173 33.1359C52.4465 32.9692 52.3382 32.8248 52.2015 32.7149C52.1108 32.6483 51.9994 32.6193 51.8872 32.6331C51.7751 32.6469 51.6697 32.7027 51.59 32.7903C51.4669 32.9117 51.5002 33.0494 51.433 33.0354C51.3659 33.0215 51.3617 32.9037 51.4797 32.6933C51.5528 32.5705 51.6618 32.4758 51.789 32.4243C51.9684 32.3609 52.1611 32.3732 52.3264 32.4585C52.522 32.5765 52.6827 32.7486 52.7921 32.9573C52.9016 33.1661 52.9559 33.404 52.9496 33.6472C53.0023 34.1923 52.927 34.7483 52.7304 35.266C52.5853 35.7605 52.3079 36.2046 51.9328 36.543C51.5577 36.8814 51.1014 37.0991 50.6209 37.1689C50.4711 37.1625 50.3259 37.1206 50.1959 37.0463C50.0659 36.972 49.9544 36.8672 49.8694 36.7394C49.7569 36.5447 49.7747 36.4153 49.7938 36.4123Z"
          fill="#EB996E"
        />
        <path
          d="M40.7617 13.8293C38.4138 14.1423 36.692 13.2857 34.6572 13.8293C32.6223 14.3729 30.3477 15.1949 28.7089 12.7338C26.6737 9.67754 28.8661 7.09897 30.9004 6.31617C32.9347 5.53336 36.1531 8.35062 39.6663 8.35075C43.892 8.35091 46.4124 9.06831 48.4517 11.8546C50.008 14.1521 50.9713 17.0091 51.2218 20.0709C51.4693 23.1193 51.2276 26.2296 50.5109 29.219L50.5057 29.2505C50.288 30.5692 50.1978 31.1159 49.8721 31.4101C49.7073 31.559 49.6887 31.4451 49.3567 31.5131C49.0247 31.5811 49.1798 31.5752 48.9011 31.3605C48.6465 30.9929 49.5471 25.5689 47.3356 22.5949C45.1242 19.6209 48.7644 15.7643 46.553 13.8293C44.3415 11.8943 43.1097 13.5162 40.7617 13.8293Z"
          fill="#263238"
        />
        <path
          d="M36.7829 28.6942C36.9609 28.874 38.0956 28.0854 39.6649 28.0791C41.2343 28.0728 42.3582 28.8057 42.5355 28.6198C42.6246 28.5412 42.4525 28.2167 41.9648 27.8714C41.3024 27.4294 40.5026 27.1992 39.6748 27.2121C38.8585 27.2149 38.0524 27.4665 37.3748 27.93C36.8773 28.2832 36.6992 28.6135 36.7829 28.6942Z"
          fill="#263238"
        />
        <path
          d="M25.759 28.2692C25.9104 28.4506 26.9811 27.8628 28.4027 28.0273C29.8244 28.1917 30.8025 28.9741 30.9732 28.8257C31.0582 28.7646 30.9199 28.4534 30.497 28.0893C29.9211 27.6192 29.2093 27.325 28.4588 27.247C27.7194 27.161 26.9756 27.3004 26.3367 27.6446C25.8669 27.909 25.6876 28.1875 25.759 28.2692Z"
          fill="#263238"
        />
        <path
          d="M35.9339 23.4091C36.7393 24.1318 42.1466 22.4181 45.9126 26.063C45.2986 20.4231 36.4235 22.0772 35.9499 22.1482C35.9499 22.1482 35.1285 22.6863 35.9339 23.4091Z"
          fill="#263238"
        />
        <path
          d="M31.9046 23.4977C31.3404 24.125 27.7081 21.8268 25.0634 25.0229C25.6144 19.4996 31.6037 22.1231 31.9237 22.2459C31.9237 22.2459 32.4688 22.8704 31.9046 23.4977Z"
          fill="#263238"
        />
        <path
          d="M33.5331 26.2483C33.3438 27.289 33.1057 28.3023 32.9039 29.3334L32.6018 30.8856L32.2872 32.4281C32.1744 32.9396 32.0853 33.4593 31.9851 33.9803L31.9005 34.364L31.879 34.4571C31.8805 34.4682 31.8723 34.4918 31.8738 34.5029L31.8671 34.5377C31.8701 34.5598 31.8604 34.5724 31.8634 34.5946C31.8663 34.6168 31.8567 34.6293 31.8596 34.6515C31.8662 34.786 31.9296 34.9243 32.0442 35.022C32.0959 35.0716 32.1683 35.1071 32.2378 35.1205C32.2725 35.1272 32.3072 35.1338 32.3531 35.139L32.3863 35.1346L32.4307 35.1288L32.532 35.1266C32.8011 35.1135 33.0591 35.1019 33.3282 35.0888C33.8568 35.0752 34.3868 35.0726 34.9227 35.1144L34.933 35.192C34.4265 35.372 33.9141 35.5077 33.4002 35.6322C33.137 35.6897 32.8849 35.7456 32.6217 35.8031L32.5233 35.8274L32.479 35.8333L32.4124 35.8421C32.3237 35.8539 32.2224 35.856 32.1308 35.8456C31.9474 35.8248 31.7582 35.7596 31.5979 35.6567C31.4376 35.5538 31.3061 35.4132 31.2049 35.2461C31.1038 35.0789 31.0551 34.8823 31.0412 34.6923C31.0354 34.6479 31.0391 34.591 31.0443 34.5452C31.0495 34.4993 31.0547 34.4535 31.0599 34.4077L31.0733 34.3382L31.0896 34.2909L31.1207 34.1852L31.229 33.8097C31.3765 33.3049 31.5351 32.7987 31.6715 32.2953L32.0903 30.7728L32.5203 29.2488C32.8138 28.2281 33.2299 26.514 33.4149 26.2074C33.5999 25.9009 33.5331 26.2483 33.5331 26.2483Z"
          fill="#263238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.8472 51.4009C47.8615 51.6301 48.0865 53.4524 48.3615 55.6703C48.5611 56.8749 48.3847 58.114 47.8579 59.2078C47.3869 60.1448 46.8154 61.024 46.1542 61.8286L42.7039 64.941L41.9395 67.8982L37.2963 61.0523C33.6281 60.0654 31.9136 54.3692 31.9136 54.3692L31.5011 51.7275L29.2693 54.034L25.2046 55.1023C25.2046 55.1023 16.1288 58.0595 13.8679 60.61L21.2971 96.3769L21.165 107.666H64.1974C64.1974 107.108 64.5831 70.1912 64.5831 70.1912L67.1655 55.7048L51.882 52.9214L49.3446 50.8555C48.4737 51.2551 47.8472 51.4009 47.8472 51.4009Z"
          fill="#EC5627"
        />
        <path
          d="M56.3757 95.7424C57.0121 94.8894 57.6976 94.0768 58.4285 93.3092C59.1158 92.496 59.8487 91.7252 60.6234 91.001C59.9844 91.8541 59.2978 92.6678 58.567 93.4379C57.8799 94.2489 57.1482 95.0183 56.3757 95.7424Z"
          fill="white"
        />
        <path
          d="M56.5244 86.574C55.5455 85.5756 54.8091 84.3512 54.3757 83.0014C54.429 82.9793 54.809 83.8357 55.4057 84.8171C56.0023 85.7985 56.567 86.5373 56.5244 86.574Z"
          fill="white"
        />
        <path
          d="M21.3757 79.7424C22.0121 78.8894 22.6976 78.0768 23.4285 77.3092C24.1158 76.496 24.8487 75.7252 25.6234 75.001C24.9844 75.8541 24.2978 76.6678 23.567 77.4379C22.8799 78.2489 22.1482 79.0183 21.3757 79.7424Z"
          fill="white"
        />
        <path
          d="M24.3757 91.147C24.5244 89.7431 24.793 88.3557 25.1784 87.001C25.1355 87.7062 25.0321 88.4061 24.8694 89.0924C24.7657 89.7914 24.6005 90.4791 24.3757 91.147Z"
          fill="white"
        />
        <path
          d="M67.1688 55.7036C68.7857 56.6553 70.2041 57.9293 71.342 59.4521C72.4799 60.9749 73.3147 62.7162 73.7979 64.5751C75.5231 70.9626 80.7986 87.5966 80.7986 87.5966C80.7986 87.5966 83.8131 91.2672 84.1346 93.9841C84.456 96.7009 83.8131 103.277 82.8416 104.504C82.5602 104.86 82.199 105.14 81.7893 105.318C81.3797 105.497 80.9341 105.569 80.4914 105.528L67.801 104.282L62.8005 69.654L67.1688 55.7036Z"
          fill="#EC5627"
        />
        <path
          d="M13.8709 60.6089C13.8709 60.6089 10.8813 64.8265 9.64191 67.5804C8.40252 70.3343 -1.88768 93.4556 -2.50202 95.8472C-3.11636 98.2388 -4.7165 105.757 -0.0196614 107.502H9.64905L19.3499 87.1975L19.8464 63.6326L13.8709 60.6089Z"
          fill="#EC5627"
        />
        <path
          d="M10.2559 92.8349C10.2559 92.8349 10.0416 92.639 9.59515 92.3766C8.9579 92.0228 8.27458 91.7664 7.5664 91.6151C7.07951 91.5047 6.58517 91.433 6.0877 91.4007C5.51454 91.356 4.93815 91.3984 4.37684 91.5264C3.10625 91.8641 1.96891 92.6031 1.12298 93.6407C0.262838 94.6667 -0.366628 95.8775 -0.720044 97.1856C-1.01177 98.2549 -1.2055 99.3502 -1.29866 100.457C-1.39153 101.403 -1.42368 102.176 -1.45582 102.704C-1.45582 102.956 -1.48439 103.159 -1.49154 103.318C-1.49134 103.391 -1.50096 103.463 -1.52011 103.532C-1.52471 103.461 -1.52471 103.389 -1.52011 103.318C-1.52011 103.155 -1.52011 102.948 -1.52011 102.704C-1.52011 102.168 -1.49868 101.4 -1.42368 100.446C-1.34816 99.321 -1.16511 98.2066 -0.877201 97.1191C-0.526278 95.7813 0.112314 94.543 0.990824 93.4966C1.86317 92.43 3.03785 91.6747 4.34827 91.3378C4.92116 91.2144 5.50871 91.1808 6.09127 91.238C6.59555 91.2764 7.09603 91.3567 7.58783 91.4783C8.30393 91.6431 8.99083 91.9224 9.62373 92.3063C9.79419 92.4153 9.95549 92.539 10.1059 92.676C10.1612 92.7233 10.2115 92.7766 10.2559 92.8349Z"
          fill="#263238"
        />
        <path
          d="M5.67407 88.7915C5.77976 88.7596 5.88904 88.7421 5.9991 88.7397C6.29749 88.7157 6.59722 88.7157 6.8956 88.7397C7.3399 88.7667 7.78099 88.8347 8.21358 88.943C9.27792 89.2093 10.2768 89.7028 11.146 90.392C11.5001 90.6709 11.8289 90.9827 12.1282 91.3235C12.3311 91.5546 12.5162 91.8018 12.6818 92.0628C12.7493 92.1533 12.8045 92.2527 12.8461 92.3585C12.8176 92.3807 12.5532 91.9889 12.0318 91.427C11.7237 91.1075 11.393 90.8122 11.0424 90.5436C10.6107 90.2183 10.1517 89.9338 9.67086 89.6934C9.1873 89.46 8.68544 89.2695 8.17072 89.1241C7.74855 89.0116 7.31869 88.9325 6.88489 88.8876C6.14197 88.7877 5.67407 88.8284 5.67407 88.7915Z"
          fill="#263238"
        />
        <path
          d="M14.4109 87.6775C14.2613 87.6838 14.1115 87.6726 13.9644 87.6442C13.6787 87.6073 13.2679 87.5629 12.7536 87.5223C10.0824 87.3161 7.39794 87.6488 4.84931 88.5018C4.35998 88.6645 3.96709 88.8086 3.69921 88.9158C3.56251 88.9784 3.42012 89.0266 3.27417 89.06C3.39712 88.9716 3.53031 88.8996 3.67063 88.8456C3.93137 88.7162 4.32069 88.5536 4.80645 88.3724C7.35698 87.4378 10.0764 87.1019 12.7679 87.3892C13.2786 87.4446 13.6929 87.5075 13.9751 87.5703C14.1233 87.5916 14.2692 87.6275 14.4109 87.6775Z"
          fill="#263238"
        />
        <path
          d="M41.949 67.9214C41.9098 67.8862 41.8761 67.8451 41.849 67.7994L41.5846 67.4297L40.6131 66.0399C39.7809 64.82 38.6165 63.1197 37.2485 61.1236L37.3986 61.1014C36.8771 62.6835 36.302 64.4282 35.6984 66.2764L35.6591 66.391L35.5591 66.3245L35.3591 66.1914C34.5809 65.6758 33.8733 65.0542 33.2553 64.3432C32.6843 63.6812 32.1804 62.9604 31.7516 62.1919C31.038 60.8914 30.4826 59.5045 30.0979 58.0629C29.8397 57.0892 29.631 56.1021 29.4729 55.1058C29.4157 54.7731 29.3729 54.5032 29.3371 54.2999C29.327 54.231 29.3115 54.163 29.2907 54.0966C29.2907 54.0523 29.2657 54.0301 29.2693 54.0264C29.2728 54.0227 29.4157 54.396 29.5443 55.0947C29.6729 55.7933 29.8765 56.7987 30.2265 58.0149C30.6221 59.4431 31.1846 60.816 31.9016 62.1031C32.3292 62.8573 32.8281 63.5655 33.3911 64.2175C33.9983 64.9115 34.6927 65.5182 35.4555 66.0214L35.652 66.1544L35.5163 66.2062C36.127 64.358 36.7056 62.6243 37.2343 61.0312L37.2878 60.8685L37.3843 61.009C38.7272 63.0236 39.8845 64.7387 40.6953 65.9659C41.0846 66.5648 41.4096 67.0416 41.6275 67.3891L41.8668 67.7587C41.8997 67.8098 41.9272 67.8643 41.949 67.9214Z"
          fill="#263238"
        />
        <path
          d="M81.3244 95.3629C80.9146 94.9224 80.4754 94.5123 80.01 94.1356C78.8663 93.2501 77.4951 92.7344 76.0689 92.6535C74.6427 92.5726 73.2253 92.9301 71.995 93.681C71.4958 94.003 71.0184 94.3599 70.5663 94.7493C70.6606 94.6136 70.7697 94.4896 70.8913 94.3796C71.2127 94.0705 71.5633 93.7956 71.9378 93.559C73.1801 92.7582 74.6308 92.3736 76.0921 92.4576C77.5533 92.5416 78.954 93.0902 80.1028 94.0284C80.4502 94.305 80.7687 94.6184 81.0529 94.9636C81.1582 95.0853 81.2493 95.2193 81.3244 95.3629Z"
          fill="#263238"
        />
        <path
          d="M80.8175 91.9508C80.3067 91.5711 79.754 91.2559 79.171 91.0119C77.7379 90.4854 76.1813 90.4376 74.7206 90.8752C73.917 91.1085 73.1435 91.4411 72.4168 91.8658C71.8252 92.2176 71.2668 92.626 70.7488 93.0856C70.3059 93.4775 69.9666 93.8249 69.7344 94.0578C69.6205 94.1925 69.4949 94.3162 69.3594 94.4275C69.4523 94.2782 69.56 94.1395 69.6808 94.0135C69.9893 93.6533 70.3173 93.3114 70.6631 92.9895C71.1764 92.5098 71.7352 92.085 72.3311 91.7217C73.0651 91.2777 73.8515 90.9336 74.6706 90.6977C76.1671 90.2453 77.7659 90.3141 79.221 90.8936C79.6555 91.0733 80.0651 91.312 80.4389 91.6034C80.5711 91.7032 80.6604 91.7993 80.7282 91.8584C80.7961 91.9176 80.8175 91.9471 80.8175 91.9508Z"
          fill="#263238"
        />
        <path
          d="M80.8464 88.9953C80.8464 89.0323 80.1321 88.8142 78.9963 88.6552C78.325 88.562 77.6483 88.5176 76.9711 88.5221C76.1415 88.5316 75.3139 88.6082 74.4959 88.7513C73.6795 88.9066 72.8759 89.1266 72.0921 89.4093C71.4532 89.6441 70.8314 89.9257 70.2312 90.2521C69.2061 90.8066 68.6275 91.2575 68.6061 91.2243C68.7274 91.1024 68.8602 90.9934 69.0025 90.899C69.1581 90.7722 69.3216 90.6562 69.4919 90.5515C69.6883 90.4221 69.9098 90.2706 70.1669 90.1301C70.7673 89.7864 71.3917 89.4899 72.035 89.243C72.8243 88.9449 73.6369 88.7173 74.4637 88.5628C75.2898 88.4181 76.1261 88.3452 76.964 88.3447C77.6495 88.3483 78.3337 88.4063 79.0106 88.5184C79.2999 88.5554 79.557 88.6257 79.7821 88.6737C79.9775 88.7146 80.1707 88.7665 80.3607 88.829C80.527 88.8697 80.6895 88.9254 80.8464 88.9953Z"
          fill="#263238"
        />
        <path
          d="M50.5503 52.7979C50.6984 53.142 50.7901 53.5093 50.8217 53.8846C50.9283 54.9448 50.8777 56.0155 50.6717 57.0599C50.5464 57.8095 50.3758 58.5502 50.161 59.2777C49.8794 60.1243 49.533 60.9463 49.1251 61.7359C48.2715 63.4362 47.2 65.2882 45.957 67.2806L45.8141 67.5134V67.2362C45.8141 65.9277 45.8141 64.5563 45.8141 63.1331L45.9534 63.2034L42.5996 65.972L42.6281 65.9277C41.8326 68.6259 41.3435 71.4108 41.1709 74.2262C40.8423 78.7248 41.1066 83.0274 41.3102 86.9309C41.5138 90.8343 41.6031 94.3571 41.7209 97.3142C41.8388 100.271 41.9352 102.659 42.0209 104.312C42.071 105.132 42.1067 105.768 42.1317 106.212C42.1317 106.422 42.1567 106.581 42.1638 106.707C42.171 106.833 42.1638 106.877 42.1638 106.877C42.1638 106.877 42.1638 106.821 42.1388 106.711C42.1138 106.6 42.1102 106.426 42.0888 106.215C42.0495 105.783 41.9995 105.143 41.9459 104.315C41.8388 102.667 41.7138 100.275 41.5888 97.3179C41.4638 94.3608 41.3102 90.8454 41.1387 86.9383C40.9673 83.0311 40.6565 78.7248 40.9851 74.2114C41.1657 71.3793 41.6681 68.579 42.4817 65.8685V65.8426H42.5031L45.8463 63.0666L45.9856 62.9483V63.1331C45.9856 64.5563 45.9856 65.9277 45.9856 67.2362L45.8391 67.1881C48.2929 63.2514 50.2895 59.8285 50.5967 57.0599C50.8089 56.0254 50.8738 54.9644 50.7896 53.9105C50.7604 53.6336 50.7115 53.3593 50.6431 53.0899C50.5717 52.8903 50.5503 52.7979 50.5503 52.7979Z"
          fill="#263238"
        />
        <path
          d="M44.1403 100.479H44.026C43.766 100.496 43.5226 100.617 43.3473 100.817C43.1721 101.016 43.0788 101.279 43.0872 101.548C43.0955 101.818 43.2049 102.074 43.3921 102.261C43.5793 102.449 43.8297 102.554 44.0903 102.554C44.351 102.554 44.6014 102.449 44.7886 102.261C44.9758 102.074 45.0851 101.818 45.0935 101.548C45.1018 101.279 45.0085 101.016 44.8333 100.817C44.6581 100.617 44.4147 100.496 44.1546 100.479H44.1403Z"
          fill="white"
        />
        <path
          d="M44.1407 86.2329H44.0264C43.7672 86.2502 43.5248 86.3711 43.3502 86.5701C43.1757 86.769 43.0827 87.0305 43.0911 87.2991C43.0994 87.5677 43.2084 87.8224 43.395 88.0093C43.5815 88.1963 43.831 88.3008 44.0907 88.3008C44.3503 88.3008 44.5998 88.1963 44.7864 88.0093C44.9729 87.8224 45.0819 87.5677 45.0902 87.2991C45.0986 87.0305 45.0057 86.769 44.8311 86.5701C44.6566 86.3711 44.4141 86.2502 44.155 86.2329H44.1407Z"
          fill="white"
        />
        <path
          d="M44.0308 73.4854C44.2975 73.5035 44.5602 73.4113 44.7611 73.229C44.962 73.0467 45.0848 72.7892 45.1023 72.5132C45.1198 72.2373 45.0307 71.9654 44.8546 71.7574C44.6784 71.5495 44.4296 71.4224 44.1629 71.4043H44.0308C43.7637 71.4043 43.5075 71.5141 43.3186 71.7096C43.1297 71.9051 43.0236 72.1702 43.0236 72.4467C43.0236 72.7232 43.1297 72.9883 43.3186 73.1838C43.5075 73.3793 43.7637 73.4891 44.0308 73.4891V73.4854Z"
          fill="white"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M29.3831 54.4902C28.8866 59.5063 30.7296 64.8033 34.6193 67.8418C34.9229 68.0821 35.305 68.3187 35.6658 68.1819C35.9063 68.0613 36.0984 67.8572 36.2087 67.6052C37.298 65.6575 37.7066 63.3813 37.3659 61.1586C36.7516 62.8368 36.223 64.4854 35.6015 66.2376C32.219 64.1084 30.0974 59.5839 29.3831 54.4902Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.3"
          d="M42.5542 65.8988L45.4116 64.8194L45.8831 69.8134C45.8831 69.8134 48.9619 64.0062 50.237 61.3596C51.5121 58.7129 50.8549 54.0332 50.8549 54.0332C50.8549 54.0332 51.3085 58.0993 48.2261 63.3002L45.8974 67.2184V63.1154L42.5542 65.8988Z"
          fill="black"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M9.3766 91.2791C8.07398 90.7065 6.64295 90.5209 5.24408 90.7431C3.7534 90.8693 2.3273 91.4279 1.12944 92.3548C-0.0278053 93.3195 -0.810017 94.8425 -0.656432 96.3728L-0.513565 96.3913C0.482952 93.6042 2.4617 91.5859 5.0155 91.3308C7.5693 91.0758 10.0588 92.2587 11.8018 94.1993C11.6125 92.7503 10.6338 91.8964 9.3766 91.2791Z"
            fill="black"
          />
        </g>
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M81.504 94.1158C80.5141 93.0643 79.2201 92.3752 77.818 92.1529C76.5966 91.9414 75.345 92.0166 74.1555 92.373C72.9661 92.7294 71.8692 93.3578 70.9459 94.2119L71.4888 93.7535C72.87 92.8927 74.4556 92.4473 76.0678 92.4671C77.6858 92.4958 79.2375 93.1376 80.4289 94.271C81.5004 95.3208 82.1398 96.7439 82.7505 98.1301C82.8495 97.407 82.7887 96.6701 82.5728 95.9748C82.3569 95.2795 81.9914 94.6439 81.504 94.1158Z"
            fill="black"
          />
        </g>
        <path
          d="M63.1161 70.9372C63.1451 70.7986 63.1882 70.6636 63.2447 70.5343C63.3554 70.2423 63.4911 69.8837 63.6483 69.4586C64.0054 68.553 64.4698 67.3036 64.9698 65.9101C65.4699 64.5165 65.902 63.2486 66.2128 62.3319L66.57 61.2414C66.6083 61.1052 66.661 60.9738 66.7271 60.8496C66.716 60.9912 66.686 61.1306 66.6378 61.2636C66.57 61.5298 66.4628 61.9068 66.3235 62.3725C66.0449 63.3041 65.6306 64.5904 65.1305 65.9766C64.6305 67.3628 64.134 68.6195 63.7554 69.5104C63.5697 69.9577 63.3983 70.3162 63.2947 70.5639C63.2502 70.6954 63.1902 70.8208 63.1161 70.9372Z"
          fill="#263238"
        />
        <path
          d="M18.0878 71.2993C18.0378 71.2993 17.6485 69.5509 17.2199 67.37C16.7913 65.1891 16.4841 63.4333 16.5234 63.4037C16.5627 63.3741 16.9592 65.1521 17.3878 67.333C17.8164 69.5139 18.1378 71.2882 18.0878 71.2993Z"
          fill="#263238"
        />
        <path
          d="M11.257 79.6971C10.6151 78.6197 10.0262 77.5093 9.4926 76.3703C8.56395 74.5221 7.8496 72.9844 7.89246 72.9585C8.53441 74.0359 9.12331 75.1463 9.6569 76.2853C10.2462 77.3936 10.7802 78.5324 11.257 79.6971Z"
          fill="white"
        />
        <path
          d="M22.9546 66.2085C23.2591 65.2608 23.623 64.3348 24.044 63.4361C24.4094 62.5127 24.8305 61.6139 25.3048 60.7451C24.9984 61.692 24.6345 62.618 24.2154 63.5175C23.8509 64.4413 23.4298 65.3402 22.9546 66.2085Z"
          fill="white"
        />
        <path
          d="M34.4297 74.9605C33.8371 74.3704 33.2879 73.7353 32.7867 73.0605C32.2405 72.4256 31.7402 71.7498 31.2902 71.0386C31.8832 71.6271 32.4314 72.2624 32.9296 72.9386C33.4776 73.5729 33.9791 74.2488 34.4297 74.9605Z"
          fill="white"
        />
        <path
          d="M31.7911 87.0004C32.1536 86.3246 32.5668 85.6795 33.027 85.0708C33.4413 84.4275 33.906 83.8204 34.4164 83.2559C34.0549 83.9322 33.6417 84.5774 33.1806 85.1854C32.7685 85.8304 32.3036 86.4377 31.7911 87.0004Z"
          fill="white"
        />
        <path
          d="M28.2403 104.008C28.0736 103.78 27.9334 103.531 27.8224 103.269C27.5867 102.796 27.2831 102.134 26.9759 101.391C26.6687 100.648 26.4223 99.9569 26.2616 99.4579C26.1548 99.1919 26.078 98.9141 26.033 98.6299C26.1801 98.8751 26.2999 99.1367 26.3901 99.4098L27.1581 101.314C27.4545 102.02 27.7188 102.659 27.951 103.21C28.0758 103.464 28.1729 103.732 28.2403 104.008Z"
          fill="white"
        />
        <path
          d="M38.3148 96.7773C37.5843 96.7272 36.8592 96.6147 36.1467 96.4409C35.4192 96.3294 34.7023 96.1538 34.0037 95.916C35.4532 96.0777 36.887 96.3658 38.2898 96.7773H38.3148Z"
          fill="white"
        />
        <path
          d="M46.1727 80.889C46.8127 80.0311 47.5021 79.2139 48.2372 78.442C48.9284 77.6241 49.6654 76.849 50.4445 76.1206C49.802 76.9786 49.1115 77.7969 48.3765 78.5714C47.6854 79.387 46.9496 80.1608 46.1727 80.889Z"
          fill="white"
        />
        <path
          d="M54.0282 102.079C53.9889 102.116 52.8353 100.729 51.4494 98.9921C50.0636 97.2548 48.9742 95.8132 49.0135 95.7799C49.0528 95.7466 50.2064 97.1291 51.5923 98.8664C52.9781 100.604 54.0604 102.045 54.0282 102.079Z"
          fill="white"
        />
        <path
          d="M5.16643 93.0498C5.19143 93.1016 4.13776 93.656 2.98766 94.5691C1.83756 95.4821 1.03749 96.384 0.994629 96.3433C1.12446 96.1093 1.2883 95.8974 1.48039 95.7149C1.90816 95.2422 2.37418 94.8081 2.87336 94.4175C3.37043 94.0248 3.89853 93.6761 4.45208 93.3751C4.67365 93.2313 4.9143 93.1217 5.16643 93.0498Z"
          fill="white"
        />
        <path
          d="M8.10749 84.8721C7.88053 85.3951 7.59907 85.8909 7.26813 86.3507C6.98616 86.8458 6.65194 87.307 6.27161 87.7257C6.49917 87.203 6.78059 86.7073 7.11097 86.2472C7.39218 85.7515 7.72647 85.2902 8.10749 84.8721Z"
          fill="white"
        />
        <path
          d="M14.7417 92.9002C14.1699 91.9591 13.9254 90.8442 14.0488 89.7397C14.1024 89.7397 14.1203 90.479 14.3167 91.3403C14.5132 92.2016 14.7917 92.8743 14.7417 92.9002Z"
          fill="white"
        />
        <path
          d="M15.7178 67.4247C15.2737 67.5851 14.8115 67.6857 14.3427 67.7241C13.8815 67.8244 13.4094 67.8604 12.939 67.8313C13.3843 67.6712 13.8476 67.5705 14.3177 67.5319C14.7779 67.4338 15.2486 67.3977 15.7178 67.4247Z"
          fill="white"
        />
        <path
          d="M20.6985 58.2847C19.9292 58.8738 19.0886 59.356 18.1982 59.7189C18.5638 59.3981 18.9685 59.1284 19.4019 58.9168C19.8065 58.6501 20.2422 58.4377 20.6985 58.2847Z"
          fill="white"
        />
        <path
          d="M34.7061 62.1465C34.2014 61.6153 33.7403 61.0416 33.3274 60.4313C32.8692 59.858 32.4582 59.246 32.0988 58.6016C33.0651 59.7031 33.9375 60.8891 34.7061 62.1465Z"
          fill="white"
        />
        <path
          d="M52.4807 71.336C51.4962 70.3319 50.7557 69.1006 50.3198 67.7431C50.3734 67.7209 50.7556 68.5822 51.3556 69.5691C51.9557 70.5561 52.5236 71.2991 52.4807 71.336Z"
          fill="white"
        />
        <path
          d="M59.2651 73.6494C58.834 74.9489 58.2839 76.2028 57.6221 77.3939C57.8125 76.7352 58.06 76.0956 58.3614 75.4829C58.6058 74.8435 58.9084 74.2296 59.2651 73.6494Z"
          fill="white"
        />
        <path
          d="M60.2167 64.0649C59.5674 63.4125 58.9634 62.7134 58.4094 61.9728C57.8074 61.2736 57.2525 60.5326 56.7485 59.7549C57.9983 61.1079 59.1571 62.548 60.2167 64.0649Z"
          fill="white"
        />
        <path
          d="M49.8529 56.2807C49.7397 55.6916 49.6859 55.0921 49.6922 54.4916C49.6383 53.8923 49.6467 53.2888 49.7172 52.6914C49.8304 53.2804 49.8842 53.88 49.8779 54.4805C49.934 55.0797 49.9256 55.6834 49.8529 56.2807Z"
          fill="white"
        />
        <path
          d="M58.3173 55.7964C57.5934 55.9928 56.8552 56.1275 56.11 56.1993C55.374 56.3331 54.6285 56.4036 53.8812 56.41C54.6052 56.2136 55.3434 56.0789 56.0886 56.0071C56.8246 55.8731 57.5701 55.8026 58.3173 55.7964Z"
          fill="white"
        />
        <path
          d="M71.6107 65.9134C71.1147 65.0711 70.6709 64.1969 70.282 63.2963C69.8404 62.4224 69.4538 61.5198 69.1248 60.5942C69.6228 61.436 70.0678 62.3102 70.457 63.2113C70.8971 64.0855 71.2825 64.9881 71.6107 65.9134Z"
          fill="white"
        />
        <path
          d="M69.385 74.654C69.5345 73.2421 69.8046 71.8468 70.1922 70.4844C70.1491 71.1936 70.0451 71.8975 69.8815 72.5877C69.7772 73.2907 69.6111 73.9823 69.385 74.654Z"
          fill="white"
        />
        <path
          d="M75.543 81.5441C75.1265 80.623 74.7685 79.6746 74.4715 78.7052C74.1174 77.7574 73.8227 76.7869 73.5892 75.7998C74.0033 76.7233 74.3612 77.6727 74.6608 78.6424C75.0134 79.5895 75.308 80.5587 75.543 81.5441Z"
          fill="white"
        />
        <path
          d="M74.6819 85.5777C74.0443 85.4784 73.4316 85.2511 72.8782 84.9086C72.297 84.6198 71.7783 84.2124 71.353 83.711C71.3887 83.6666 72.0459 84.2285 72.9675 84.7386C73.889 85.2487 74.6997 85.5222 74.6819 85.5777Z"
          fill="white"
        />
        <path
          d="M35.8901 107.586C35.7378 107.053 35.6443 106.504 35.6115 105.949C35.5211 105.401 35.4899 104.844 35.5187 104.289C35.6818 104.825 35.7813 105.38 35.8151 105.941C35.9007 106.485 35.9258 107.037 35.8901 107.586Z"
          fill="white"
        />
        <path
          d="M60.1482 106.951C60.1646 106.41 60.2425 105.873 60.3804 105.35C60.4608 104.815 60.601 104.292 60.7982 103.791C60.7818 104.332 60.7027 104.869 60.5625 105.391C60.4842 105.926 60.3451 106.45 60.1482 106.951Z"
          fill="white"
        />
        <path
          d="M8.92312 61.4678L7.93787 54.9658L7.35778 46.6108L7.83341 39.542C7.83341 39.542 8.0981 38.8214 9.03333 39.2261C9.96856 39.6308 9.67241 43.4961 9.67241 43.4961L9.89413 46.3392C9.89413 46.3392 10.6718 48.4625 10.8145 48.2595C10.9573 48.0564 11.037 44.1647 11.037 44.1647L11.6164 35.7538C11.7005 35.4898 11.8808 35.2672 12.1216 35.1302C12.3624 34.9932 12.6459 34.9519 12.9157 35.0144C13.4994 35.125 13.9656 35.8937 14.0414 37.1213C14.1171 38.3489 13.8424 40.4667 13.8722 41.1673C13.902 41.8679 13.9324 45.9199 13.9324 45.9199L15.5842 36.3858C15.5842 36.3858 15.7336 33.3309 17.0346 33.6222C18.3355 33.9134 17.7647 36.1487 17.8387 37.0735C17.9127 37.9983 16.8571 44.4865 17.219 46.2134C17.5809 47.9404 17.754 47.0348 17.754 47.0348L18.4172 41.7256C18.7089 39.3855 19.4089 37.965 20.3734 37.6796C21.3378 37.3943 20.6566 42.817 20.6566 42.817L19.8904 50.3739L20.5899 52.665L21.9728 50.3577C21.9728 50.3577 21.6848 47.5947 23.2674 46.2314C24.85 44.8681 25.4582 46.1253 25.4582 46.1253L23.525 57.1763C22.9797 59.0176 18.4685 63.0119 18.4685 63.0119L18.8944 73.8287L7.69101 74.5387L8.92312 61.4678Z"
          fill="#FFBE9D"
        />
        <path
          d="M12.2897 57.1421C12.353 57.188 12.8101 56.6165 13.6036 55.7363C14.3972 54.856 15.6503 53.7272 17.4022 53.5939C18.126 53.518 18.8545 53.6871 19.4709 54.0742L19.6721 54.2092L19.8047 53.9802C20.3787 52.9732 21.0503 52.0728 21.4264 51.4313C21.8025 50.7898 21.9906 50.3821 21.9557 50.3538C21.6721 50.6345 21.4175 50.943 21.1959 51.2748C20.5513 52.0809 19.9493 52.9202 19.3923 53.7892L19.7298 53.7106C19.0353 53.2463 18.2006 53.039 17.3696 53.1244C16.4741 53.2027 15.6166 53.5227 14.8886 54.0502C14.3114 54.4705 13.7933 54.9666 13.3482 55.5249C12.5949 56.4564 12.2454 57.1079 12.2897 57.1421Z"
          fill="#EB996E"
        />
        <path
          d="M20.1504 97.9438L18.4004 64.334L8.78136 62.5605L5.28676 100.869L20.1504 97.9438Z"
          fill="#FFBE9D"
        />
        <path
          d="M19.4089 98.4396C19.4752 97.9796 19.5021 97.5148 19.4895 97.0502C19.5144 96.1509 19.5315 94.8532 19.5305 93.2524C19.5391 90.0443 19.4689 85.6134 19.3202 80.7237C19.1715 75.8341 18.9848 71.406 18.8291 68.2039C18.7393 66.6562 18.6682 65.379 18.6121 64.4096C18.604 63.9443 18.5581 63.4804 18.4749 63.0225C18.4269 63.4855 18.4166 63.9517 18.4439 64.4165C18.4675 65.3853 18.4947 66.6646 18.5204 68.2152C18.6196 71.4871 18.7496 75.8798 18.8988 80.737L19.2252 93.2265C19.2663 94.7735 19.2946 96.0404 19.3017 97.0252C19.3013 97.4987 19.3371 97.9715 19.4089 98.4396Z"
          fill="#EB996E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1579_20229">
          <path
            d="M1 0H80.2029V64.9589C80.2029 86.8301 62.4727 104.56 40.6014 104.56C18.7302 104.56 1 86.8301 1 64.9589V0Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CampaignModalIconSVG;
