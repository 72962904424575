import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";

const CampaignDetailsButton = ({
  className,
  children,
  paddingY = "py-2",
  paddingX = "px-5",
  listPositionTop = "top-12",
  textStyle = "text-xs font-medium",
  dropdown = [],
  onClick = () => {},
  ...props
}) => {
  const [show, setShow] = useState(false);

  // outside clicks
  const useOutsideClicks = (ref) => {
    useEffect(() => {
      // set close if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) setShow(false);
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideClicks(wrapperRef);
  // end: outside clicks

  const onClickMenuItem = (item) => {
    if (item) {
      if (!item.disabled) {
        // item.action();
        const timeout = setTimeout(() => {
          item.action();
          clearTimeout(timeout);
        }, 300);
      }
    }
    setShow(false);
  };

  return (
    <div className="relative" ref={wrapperRef} onClick={onClick}>
      <button
        className={`flex items-center ${paddingX} ${paddingY} ${textStyle} uppercase border rounded-full appearance-none border-gray focus:border-black ${className}`}
        {...props}
        onClick={() => setShow(!show)}
      >
        {children}
      </button>
      {show && dropdown.length > 0 && (
        <ul
          className={`absolute right-0 z-50 flex flex-col w-full bg-white border rounded-md shadow-2xl border-lightGray ${listPositionTop} min-w-max`}
        >
          {dropdown.map((item, itemIndex) => {
            return (
              <React.Fragment key={`dropdown-${itemIndex}`}>
                {!_.isEmpty(item) && (
                  <li>
                    <button
                      className={`w-full flex items-center px-4 py-2 pr-10 space-x-2 cursor-pointer ${
                        item.disabled ? "opacity-50" : "hover:bg-lightGray"
                      }`}
                      onClick={() => onClickMenuItem(item)}
                    >
                      {item.icon && item.icon}
                      <span>{item.text}</span>
                    </button>
                  </li>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default CampaignDetailsButton;
