import { Context } from "context/context";
import { useContext } from "react";
import ImageUploadIconSVG from "assets/svg/image-upload-icon";
import CheckIconSVG from "assets/svg/check-icon-circle";
import HeaderExample from "./component/header";

const TEXT = [
  "30 days free trial",
  "No lock in contracts",
  "Easy to install",
  "No lock in contracts",
];

const showDummy = ["template", "content"];

export default function PromotionsTab({ popup }) {
  const { view, selectedTab } = useContext(Context);
  const mobile = view === "mobile";
  const data = popup.template_content && JSON.parse(popup.template_content);
  const show = showDummy.includes(selectedTab);

  return (
    <div
      className={
        mobile
          ? "overflow-y-auto mobile-scroll"
          : "promotions-tab cc-tab overflow-y-auto mobile-scroll"
      }
      style={{ height: mobile ? "619px" : "635px" }}
    >
      <div>
        <HeaderExample data={data} popup={popup} show={show} />
        <div
          className={`w-full grid mt-4 ${
            show ? !data.thumbnail?.url && "promotion-empty" : ""
          }`}
        >
          {data.thumbnail?.url ? (
            <img
              src={data.thumbnail?.url}
              alt="promotion-cover"
              className="object-center object-contain"
            />
          ) : (
            show && (
              <div className="m-auto relative w-40 flex justify-center">
                <ImageUploadIconSVG className="h-10 w-10 text-center" />
                <p className="absolute -bottom-9 promotion-color">
                  Photo or Video
                </p>
              </div>
            )
          )}
        </div>
        <div className="pt-4 grid grid-cols-2 gap-3">
          {(data?.points || []).map((obj, index) => (
            <div key={index} className={`${!obj.visible && "hidden"}`}>
              <div
                className={`flex space-x-1 ${!obj.text && "text-tabBorder"}`}
              >
                {data.show_ticks && (
                  <div className={`mt-0.5 ${!obj.text && "opacity-50"}`}>
                    <CheckIconSVG
                      className="w-3.5 h-3.5"
                      fill={popup.button_colour}
                    />
                  </div>
                )}
                <p className="text-xs break-words">
                  {obj.text || (show && TEXT[index])}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
