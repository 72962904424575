import PromotionIconSVG from "assets/svg/promotion-icon";
import ProofPointsIconSVG from "assets/svg/proof-points-icon";
import StatisticsDataIconSVG from "assets/svg/statistics-data-icon";
import TestimonialsIconSVG from "assets/svg/testimonials-icon";
import VideosIconSVG from "assets/svg/videos-icon";
import Button from "components/button/button";
import ButtonBigIcon from "components/button/button-big-with-icons";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { TEMPLATEOBJ } from "utils/constant";

const templates = [
  {
    value: "proof-points",
    label: "Proof Points",
    icon: ProofPointsIconSVG,
  },
  {
    value: "testimonials",
    label: "Testimonials",
    icon: TestimonialsIconSVG,
  },
  {
    value: "videos",
    label: "Videos",
    icon: VideosIconSVG,
  },
  {
    value: "promotions",
    label: "Promotions",
    icon: PromotionIconSVG,
  },
  {
    value: "statistics-data",
    label: "Statistics/Data",
    icon: StatisticsDataIconSVG,
  },
];

export default function CampaignChooseTemplate({ id }) {
  const { popup, setPopup, setSelectedTab, templateData, setTemplateData } =
    useContext(Context);
  const { http } = global.services;
  const [state, setState] = useState({
    loading: false,
    validationErrors: null,
  });

  const [selected, setSelected] = useState(
    popup.template_type || "proof-points"
  );

  const handleSelect = (value) => {
    //save current data before update for pulling
    if (!_.isEmpty(popup.template_content)) {
      setTemplateData((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          [popup.template_type]: popup.template_content,
        },
      }));
    }
    setSelected(value);
    setState((prev) => ({
      validationErrors: {
        ...prev.validationErrors,
        type: null,
      },
    }));
    let data = update(popup, {
      template_type: { $set: value },
    });
    handleOnSave(data);
  };

  const handleOnSave = async (data) => {
    setState((prev) => ({ ...prev, loading: true }));
    const prev_template = popup?.template_type;
    let params = {
      ...popup,
      template_type: data?.template_type,
    };

    if (prev_template !== data?.template_type) {
      //overwrite content if not the same value
      let content = {};
      if (!_.isEmpty(templateData[id]?.[data?.template_type])) {
        content = templateData[id][data?.template_type];
      } else {
        content = JSON.stringify(
          _.find(TEMPLATEOBJ, { type: data?.template_type })
        );
      }
      params = {
        ...popup,
        template_content: content,
        template_type: data?.template_type,
      };
    }

    try {
      if (popup?.id) {
        setPopup({
          ...popup,
          template_type: params.template_type,
          template_content: params.template_content,
        });
        await http.put(`popups/${popup?.id}`, params);
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));
      toast.error("It appears that something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleChangeStep = () => {
    if (_.isEmpty(popup?.template_type)) {
      setState((prev) => ({
        validationErrors: {
          ...prev.validationErrors,
          type: "Select template before proceeding with the next step.",
        },
      }));
    } else {
      setSelectedTab("content");
    }
  };

  return (
    <div className="w-full">
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Choose Your Template</p>
      </div>

      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <div
          className="bg-white rounded-xl relative campaign-left-max"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            {/* first */}
            <div>
              <p className="text-xl pl-1 pb-2">
                Which method would give your customers the most confidence?
              </p>
            </div>
            {/* middle */}
            <div>
              <p className="pl-1 pt-2 pb-4">
                Select one of the following templates below and see the preview
                on the right hand side:
              </p>
              {state.validationErrors?.type && (
                <p className="text-red text-xs mb-0.5">
                  * {state.validationErrors?.type}
                </p>
              )}
              <div className="grid grid-cols-2 gap-4">
                {templates.map((obj) => (
                  <ButtonBigIcon
                    key={obj.value}
                    selected={selected && selected === obj.value}
                    buttonName={obj.label}
                    Icon={obj.icon}
                    onClick={() => handleSelect(obj.value)}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("popout")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                onClick={handleChangeStep}
                // disabled={state.loading}
                // icon={
                //   <div className="absolute right-2 bottom-0 top-0 flex items-center">
                //     {state.loading && (
                //       <LoadingIndicator className="text-white w-4 h-4" />
                //     )}
                //   </div>
                // }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
