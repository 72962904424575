import ContactNumberIconSVG from "assets/svg/contact-number-icon";
import EmailIconSVG from "assets/svg/email-icon";
import MarkerIconSVG from "assets/svg/marker-icon";
import FileUpload from "components/file-upload/file-upload";
import Input from "components/input/input";
import InputDoubleField from "components/input/input-double";
import InputMapField from "components/input/input-map";
import Toggle from "components/toggle/toggle";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useEffect } from "react";
import { TabPanel } from "react-headless-tabs";

const PLACHOLDER = [
  "30 days free trial",
  "No lock in contracts",
  "Easy to install",
  "No lock in contracts",
];

export default function StandardDetailsTab({
  onHandleBlur,
  content,
  state,
  setContent,
  handleInputChange,
  handleInputWithObjectChange,
  handleInputWithObjectChangeAddress,
  onUploadImage,
  handleRemoveImage,
  imageVideo,
  setImageVideo,
}) {
  const { primaryMediaTab, setPrimaryMediaTab } = useContext(Context);

  const handleValidationMessage = (index) => {
    if (state.validationErrors?.buttons) {
      let buttons = state.validationErrors?.buttons;
      let button = { ...buttons[index] };
      if (Object.keys(button).length > 0) button.url[0] = "";
    }
  };

  const onToggle = (e) => {
    let payload = _.clone(content);
    let data = update(payload, {
      show_ticks: { $set: !e },
    });
    setContent(data);
  };

  const handleToggleMediaTab = (e) => {
    const checked = e?.target?.checked;
    setPrimaryMediaTab(checked ? "video" : "image");
    setImageVideo(null);
    onHandleBlur();
  };

  useEffect(() => {
    setPrimaryMediaTab(content?.video_link ? "video" : "image");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onHandleBlur(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content.contact_number.visible,
    content.email_address.visible,
    content.address.visible,
    content.show_ticks,
  ]);

  return (
    <div className="w-full">
      <div className="mt-5">
        {/* Layout */}
        <Input
          inputContainerClass="input-container-2"
          labelClass="input-label-xs"
          paddingTop="1.5rem"
          placeholder="e.g. 1800 XXX XXX"
          value={content.contact_number.value}
          visible={content.contact_number.visible}
          masked={true}
          label="Contact Number"
          name="contact_number"
          type="text"
          inputIcon={<ContactNumberIconSVG className="w-5 h-5 text-gray" />}
          iconPosition="left"
          onChange={(e) => handleInputWithObjectChange(e, "value")}
          onBlur={onHandleBlur}
          onChangeVisible={() => {
            handleInputWithObjectChange("contact_number", "visible");
          }}
          errorMessage={state.validationErrors?.contact_number?.value[0]}
        />
        <Input
          inputContainerClass="input-container-2"
          placeholder="e.g. info@email.com"
          value={content.email_address.value}
          visible={content.email_address.visible}
          masked={true}
          label="Email Address"
          name="email_address"
          labelClass="input-label-xs"
          paddingTop="1.5rem"
          type="text"
          inputIcon={<EmailIconSVG className="w-5 h-5 text-gray" />}
          iconPosition="left"
          onChange={(e) => handleInputWithObjectChange(e, "value")}
          onBlur={onHandleBlur}
          onChangeVisible={() => {
            handleInputWithObjectChange("email_address", "visible");
          }}
          errorMessage={state.validationErrors?.email_address?.value[0]}
        />
        <Input
          inputContainerClass="input-container-3"
          placeholder="e.g. 375 Thoroughfare, Lake Sabina, Queensland"
          value={content.address.value}
          visible={content.address.visible}
          masked={true}
          label="Address Label"
          name="address"
          labelClass="input-label-xs"
          paddingTop="1.5rem"
          maxLength={90}
          showCounter={true}
          type="text"
          inputIcon={<MarkerIconSVG className="w-5 h-5 text-gray" />}
          iconPosition="left"
          onChange={(e) => handleInputWithObjectChange(e, "value")}
          onBlur={onHandleBlur}
          onChangeVisible={() => {
            handleInputWithObjectChange("address", "visible");
          }}
          errorMessage={state.validationErrors?.address?.value[0]}
        />
        <div className="pl-1 pr-4">
          {content.address?.visible && (
            <InputMapField
              placeholder="Pin Location (Address on Google Maps)"
              value={content.address?.pin}
              label="Pin Location"
              name="pin"
              labelClass="input-label-xs"
              paddingTop="1.5rem"
              type="text"
              inputIcon={<MarkerIconSVG className="w-5 h-5 text-gray" />}
              iconPosition="left"
              onChange={(e) => handleInputWithObjectChangeAddress(e, "pin")}
              onBlur={onHandleBlur}
              errorMessage={state.validationErrors?.pin?.value[0]}
            />
          )}
        </div>
        {/* buttons */}
        <div className="w-full mt-3">
          {content.buttons.map((item, index) => {
            return (
              <div key={index} className="mb-4 pl-1">
                <InputDoubleField
                  icon={true}
                  iconName="contact_method"
                  name={["text", "url"]}
                  valueUpper={item?.text || ""}
                  valueLower={item?.url || ""}
                  label={[`Button ${index + 1}`, `Button ${index + 1} URL`]}
                  placeholder={[
                    `e.g. Button ${index + 1}`,
                    "e.g. https://www.youtube.com/",
                  ]}
                  maxLength={[30, 255]}
                  showCounter={[true, false]}
                  visible={item?.visible}
                  onBlurInputLower={onHandleBlur}
                  onBlurInputUpper={onHandleBlur}
                  onChangeUpper={(e) => {
                    content.buttons[index].text = e.target.value;
                    setContent({ ...content });
                    handleValidationMessage(index);
                  }}
                  onChangeLower={(e) => {
                    content.buttons[index].url = e.target.value;
                    setContent({ ...content });
                    handleValidationMessage(index);
                  }}
                  onChangeVisible={(e) => {
                    e.preventDefault();
                    content.buttons[index].visible = !item.visible;
                    setContent({ ...content });
                    onHandleBlur();
                  }}
                  errorMessage={
                    state.validationErrors?.buttons
                      ? state.validationErrors?.buttons[index]?.url[0] || ""
                      : ""
                  }
                />
              </div>
            );
          })}
        </div>
        {/* media */}
        <Toggle
          name="show_ticks"
          background="bgdefault"
          checked={primaryMediaTab === "video"}
          onChange={(e) => handleToggleMediaTab(e)}
          icon="medias"
          className="w-72 h-10 my-5"
          cInactiveClass="toggle-container-left w-36 h-8 ml-1 rounded-full bg-white"
          cActiveClass="toggle-container-right w-32 h-8 ml-auto mr-1 rounded-full bg-white"
          custom="promotions"
          labelLeft="Upload an Image"
          labelRight="Link a Video"
        />
        {imageVideo && <p className="text-red text-xs mt-2">* {imageVideo}</p>}
        {/* Image */}
        <TabPanel hidden={primaryMediaTab === "video"}>
          <FileUpload
            wrapperClass="mt-2"
            leftContentClass="w-full h-20"
            accept="image/*"
            width={"100%"}
            imageClass="w-full object-contain object-center h-20"
            removable={true}
            onRemoveImage={handleRemoveImage}
            image_url={content.image_url}
            uploading={state.uploading}
            maxFileText="Maximum upload file size"
            allowedFiles="SVG, JPG, PNG, GIF"
            maxFileSize="300KB"
            onChange={onUploadImage}
          />
        </TabPanel>
        {/* Video Link */}
        <TabPanel hidden={primaryMediaTab === "image"}>
          {primaryMediaTab === "video" && (
            <div className="mt-5">
              <Input
                inputContainerClass="input-container-2"
                placeholder="e.g. https://www.youtube.com/"
                labelClass="input-label-xs"
                paddingTop="1.5rem"
                autoFocus={true}
                value={content.video_link}
                label="Video Link"
                name="video_link"
                type="text"
                onChange={handleInputChange}
                onBlur={async () => {
                  if (
                    primaryMediaTab === "video" &&
                    content?.image_id &&
                    content.video_link
                  ) {
                    await handleRemoveImage();
                    setContent((prev) => ({
                      ...prev,
                      image_url: null,
                      image_key: null,
                    }));
                  }
                  onHandleBlur();
                }}
                errorMessage={state.validationErrors?.video_link?.value[0]}
              />
            </div>
          )}
        </TabPanel>
        {/* Tick Points */}
        <Toggle
          name="show_ticks"
          background="bgdefault"
          checked={!content.show_ticks}
          onChange={(e) => {
            onToggle(!e?.target?.checked);
          }}
          icon="ticks"
          className="w-60 h-10 my-5"
          custom="popup"
          labelLeft="Tick Points"
          labelRight="Text Only"
        />
        {content.points.map((item, index) => {
          return (
            <Input
              key={index}
              inputContainerClass="input-container-2"
              value={item.point}
              label={`Tick Point ${index + 1}`}
              placeholder={`e.g. ${PLACHOLDER[index]}`}
              labelClass="input-label-xs"
              paddingTop="1.5rem"
              name={item.name}
              visible={item.visible}
              type="text"
              showCounter={true}
              masked={true}
              maxLength={160}
              onBlur={onHandleBlur}
              onChange={(e) => {
                content.points[index].point = e.target.value;
                setContent({ ...content });
              }}
              onChangeVisible={() => {
                content.points[index].visible = !item.visible;
                setContent({ ...content });
                onHandleBlur();
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
