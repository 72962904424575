import React, { useState } from "react";
import { Context } from "context/context";
import { MegaPhone, Close } from "assets/svg/sidebar/icons";
import toast from "react-hot-toast";
import LoadingIndicator from "assets/svg/loading-indicator";

export default function Alerts() {
  const { http, localstorage } = global.services;

  const userData = JSON.parse(localstorage.get("user_data").value);
  const { alerts, setAlerts } = React.useContext(Context);
  const [state, setState] = useState({
    loading: false,
    verificationSent: false,
  });

  const removeAlert = (removeAlert) => {
    const updated = alerts.filter((item) => item !== removeAlert);
    setAlerts(updated);
  };

  const sendEmailVerification = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const params = { email: userData.user.email };
      await http.post(`auth/resend-email-verification`, params);
      toast.success("Verification has been sent to your email");
      setState((prev) => ({ ...prev, verificationSent: true }));
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <div className="alerts">
      {alerts &&
        alerts.length > 0 &&
        alerts.map((alert, i) => (
          <div className={"w-full alert"} key={`alert${i}`}>
            {!userData.user.email_verified_at ? (
              <div className={"flex items-center"}>
                <MegaPhone className="mr-4" />
                <div className="flex items-center">
                  <p className="ml-1">
                    {state.verificationSent
                      ? `We've sent an email to ${userData.user.email}. Did not receive an email? `
                      : "Your email is not yet verified, get verified by clicking"}
                  </p>
                  <span
                    onClick={sendEmailVerification}
                    className="ml-1 hover:opacity-50 font-bold underline cursor-pointer"
                  >
                    {state.verificationSent ? "Resend" : "here"}
                  </span>
                </div>
              </div>
            ) : (
              <div className={"flex items-center gap-4"}>
                <MegaPhone />
                {alert.message}
                {alert.insideButton && (
                  <div className="-ml-2">
                    <a
                      href={alert.insideButton.link}
                      className="font-bold underline cursor-pointer"
                    >
                      {alert.insideButton.name}
                    </a>
                    {alert.insideButton.afterButtonText}
                  </div>
                )}
                {alert.button && (
                  <a
                    href={alert.button.link}
                    className="font-bold underline cursor-pointer"
                    target={alert.button.target}
                  >
                    {alert.button.name}
                  </a>
                )}
                {alert.data && (
                  <span
                    // onClick={() => handleShowDetails(alert.data)}
                    className="-m-3 hover:opacity-50 font-bold underline cursor-pointer"
                  >
                    See details
                  </span>
                )}
              </div>
            )}

            <button
              onClick={() => removeAlert(alert)}
              disabled={state.loading}
              className={"alert-button items-center justify-center flex"}
            >
              {state.loading ? (
                <LoadingIndicator className="text-white w-3 h-3" />
              ) : (
                <Close fill={"white"} />
              )}
            </button>
          </div>
        ))}
    </div>
  );
}
