import React from "react";
import moment from "moment";
import { BsEyeFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getPopoutImage } from "utils/popout-wireframes";
import PopoutAction from "./action";
import EllipsisVIconSVG from "assets/svg/ellipsis-vertical-icon";
import CampaignPopoutEditSVG from "assets/svg/campaign/popout/edit-icon";
import CampaignPopoutDeleteSVG from "assets/svg/campaign/popout/delete-icon";
import CampaignPopoutDuplicateSVG from "assets/svg/campaign/popout/duplicate-icon";
import _ from "lodash";

const TEMPLATE_CONTACT = {
  template: {
    "proof-points": "Proof Points",
    testimonials: "Testimonials",
    videos: "Videos",
    promotions: "Promotions",
    "statistics-data": "Statistics/Data",
    logos: "Logos",
  },
  contacts: {
    "standard-details": "Primary Details",
    "enquiry-form": "Enquiry Form",
    "lead-magnet": "Lead Magnet",
    "map-assistance": "Map Assistance",
    "multiple-addresses": "Multiple Addresses",
    "calendy-event-scheduler": "Calendly Event Scheduler",
  },
};

const PopoutComponent = ({ ...props }, ref) => {
  const {
    record,
    setDefaultTab,
    show,
    setShow,
    handleConfirmDelete,
    handleDuplicatePopout,
  } = props;
  const history = useHistory();

  return (
    <div
      className="w-full popout-card cursor-pointer"
      onClick={() => {
        setShow({
          [`action_list_${record.id}`]: !show[`action_list_${record.id}`],
        });
      }}
      ref={ref}
    >
      <div className="w-full">
        <div
          style={
            !_.isEmpty(record?.desktop_image)
              ? {
                  height: "285px",
                  backgroundImage: `url(${record.desktop_image.url})`,
                }
              : {
                  height: "285px",
                  backgroundColor: "#AEACDF",
                }
          }
          className="rounded-t-md bg-cover"
        >
          {getPopoutImage(record.template_type, record.contact_method_type) && (
            <img
              src={getPopoutImage(
                record.template_type,
                record.contact_method_type
              )}
              alt={`popout-website-${record.id}`}
              className="w-full h-full rounded-t-md"
            />
          )}
        </div>
        <div className="pl-8 pr-2 py-6 flex justify-between">
          <div className="w-full">
            <p className="text-2xl">{record?.title || "Untitled"}</p>
            <p className="text-secondary">{`${
              TEMPLATE_CONTACT.template[record?.template_type] ||
              "Not Available"
            } & ${
              TEMPLATE_CONTACT.contacts[record?.contact_method_type] ||
              "Not Available"
            }`}</p>
            <p className="text-sm opacity-40 mt-4">{`Last Updated ${moment(
              record.updated_at
            ).format("DD MMM YYYY h:mm A")}`}</p>
          </div>
          {/* actions */}
          <PopoutAction
            id={"action_list_" + record.id}
            show={show[`action_list_${record.id}`]}
            setShow={setShow}
            dropdown={[
              {
                icon: <CampaignPopoutEditSVG />,
                text: "Edit Popout",
                action: () => {
                  setDefaultTab("basic");
                  history.push(`/campaigns/popup/${record.id}`);
                },
              },
              {
                icon: <CampaignPopoutDuplicateSVG />,
                text: "Duplicate Popout",
                action: () => handleDuplicatePopout(record),
              },
              {
                icon: <BsEyeFill className="ml-0.5 text-xl" />,
                text: "Preview Popout",
                action: () =>
                  history.push(`/campaigns/popup/preview/${record.id}`),
              },
              {
                icon: <CampaignPopoutDeleteSVG />,
                text: "Delete",
                action: () => handleConfirmDelete(record),
              },
            ]}
          >
            <EllipsisVIconSVG className="m-auto" />
          </PopoutAction>
          {/* end: actions */}
        </div>
      </div>
    </div>
  );
};

const PopoutContent = React.forwardRef(PopoutComponent);
export default PopoutContent;
