import React, { useEffect, useState } from "react";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import LoadingIndicator from "assets/svg/loading-indicator";

function SupportLogin({ setUserData }) {
  const { http, localstorage } = global.services;
  const { key } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const login = async () => {
    setLoading(true);
    try {
      const req = await http.get(`public/supports/login/${key}`);
      const data = req.data;
      localstorage.set("user_data", JSON.stringify(data));
      setUserData(data);
      history.replace("/dashboard");
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
      history.replace("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col flex-1 w-full">
      {loading && (
        <div className="w-full h-screen flex flex-col items-center">
          <div className="m-auto">
            <LoadingIndicator className="w-96 h-96 text-secondary" />
            <p className="mt-5 text-4xl font-bold text-primary">
              Logging you in now ...
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportLogin);
