import CampaignPopoutDeleteSVG from "assets/svg/campaign/popout/delete-icon";
import CampaignPopoutDuplicateSVG from "assets/svg/campaign/popout/duplicate-icon";
import CampaignPopoutEditSVG from "assets/svg/campaign/popout/edit-icon";
import CampaignBanner from "assets/svg/campaign_banner";
import EllipsisVIconSVG from "assets/svg/ellipsis-vertical-icon";
import Button from "components/button/button";
import CampaignCreate from "components/campaigns/campaign-create-modal";
import CampaignModal from "components/campaigns/campaign-modal";
import CampaignTable from "components/campaigns/campaign-table";
import EmptyCampaign from "components/campaigns/empty-campaign";
import ConfirmModal from "components/confirm/confirm";
import NoticeModal from "components/confirm/notice";
import PopoutAction from "components/popouts/action";
import SelectPopoutSort from "components/select/select-sort-popout";
import Toggle from "components/toggle/toggle";
import { Context } from "context/context";
import update from "immutability-helper";
import Layout from "layouts/layout";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useDialogHook } from "utils/customhooks";

const SORT_BY = [
  { label: "Updated: Ascending", value: "updated_at ", sort: "asc" },
  { label: "Updated: Descending", value: "updated_at ", sort: "desc" },
  { label: "Created: Ascending", value: "created_at", sort: "asc" },
  { label: "Created: Descending", value: "created_at", sort: "desc" },
  { label: "Campaign Title: Ascending", value: "title", sort: "asc" },
  { label: "Campaign Title: Descending", value: "title", sort: "desc" },
];

export default function Campaign() {
  const userData = useMemo(() => {
    return JSON.parse(localStorage.getItem("user_data")).user;
  }, []);
  const accountActive = userData.status === "active";
  const history = useHistory();
  const modal = useDialogHook(CampaignModal);
  const { setCampaign, setSelectedTab, setAlerts } = useContext(Context);
  const modalCreate = useDialogHook(CampaignCreate);
  const confirm = useDialogHook(ConfirmModal);
  const notice = useDialogHook(NoticeModal);
  const { http } = global.services;
  const [show, setShow] = useState({});
  const [state, setState] = useState({
    data: [],
    meta: {},
    page: 1,
    limit: 10,
    loading: true,
  });
  const [popupExists, setPopupExists] = useState(false);
  const [sort, setSort] = useState({
    label: "Updated: Descending",
    value: "updated_at",
    sort: "desc",
  });
  const arrayRefs = useRef([]);
  arrayRefs.current = [];

  const checkCampaignLimits = (max, active) => {
    if (active > max) {
      setAlerts([
        {
          message: (
            <span>
              You currently have more active campaigns than available
              ConversionCow licenses.{" "}
              <strong>Please deactivate {active - max} campaign(s).</strong>
            </span>
          ),
          type: "too-many-active-campaigns",
        },
      ]);
    } else {
      setAlerts([]);
    }
  };

  const handleGetCampaigns = async (type = "load") => {
    const query = new URLSearchParams(window.location.search);
    if (type !== "delete") {
      setState({ ...state, loading: true });
    }
    try {
      const params = {
        limit: state.limit,
        page: state.page,
        orderBy: sort.value,
        order: sort.sort,
        show: query.get("show") ?? "owned",
      };

      const result = await http.get(`campaigns`, params);
      const data = result.data.data;
      setPopupExists(result.data.popupExists);

      checkCampaignLimits(
        result.data.max_campaigns ?? 1,
        result.data.active_campaigns ?? 0
      );

      // Trigger modal if no campaigns data was returned
      if (data.length === 0 && state.page === 1) {
        modal({}, (res) => {
          if (res === "create") handleCreate(result.data.popupExists);
        });
      }

      if (state.page > 1 && type === "delete") {
        return setState({ ...state, page: state.page - 1 });
      }

      setState({ ...state, data, meta: result.data.meta, loading: false });
    } catch (error) {
      setState({ ...state, loading: false });
      toast.error("It appears that something went wrong");
    }
  };

  const handleCreate = (initialState = false) => {
    if (!popupExists && !initialState) {
      confirm(
        {
          title: "You don't have any popouts yet",
          buttonText: "YES, TAKE ME THERE!",
          description: "Create your first popout instead?",
          btnType: "primary",
          btnBg: "bg-primary",
        },
        (res) => {
          if (res) history.push("/campaigns/popup");
        }
      );
    } else {
      modalCreate({ type: "create" }, (res) => {
        if (!_.isEmpty(res)) {
          toast.success(res.message);
          handleGetCampaigns();
          history.push(`/campaign-details/${res.id}/popouts`);
        }
      });
    }
  };

  const handleConfirmDelete = (record) => {
    confirm(
      {
        title: "Delete Campaign",
        buttonText: "YES, DELETE IT",
        description: "Are you sure you want to delete this Campaign?",
      },
      (result) => {
        if (result) {
          handleDeleteCampaign(record);
        }
      }
    );
  };

  const handleChangeStatus = async (campaign) => {
    const campaignIndex = state.data.findIndex(
      (item) => item.id === campaign.id
    );
    const previousStatus = campaign.status;
    const status = campaign.status === "dormant" ? "active" : "dormant";
    setCampaignStatus(status, campaignIndex);

    try {
      await http.put(`campaigns/activate/${campaign.id}`, { status });
      const activeCampaigns = await getActiveCampaigns(campaign);
      checkCampaignLimits(activeCampaigns.max, activeCampaigns.active);
    } catch (error) {
      if (error.status >= 400 && error.status < 500 && error.data?.message) {
        notice({
          title: "Unable to activate your campaign",
          buttonText: "Ok",
          description: error.data.message,
        });
      } else {
        toast.error(
          `It appears that something went wrong: ${error.data?.message}`
        );
      }
      // set back to previous status if got an error
      setCampaignStatus(previousStatus, campaignIndex);
    }
  };

  const handleDeleteCampaign = async (campaign) => {
    if (campaign) {
      try {
        await http.delete(`campaigns/${campaign.id}`);

        let payload = _.clone(state.data);
        _.remove(payload, (e) => {
          return e.id === campaign.id;
        });

        setState((prev) => ({ ...prev, data: payload }));
        toast.success("Campaign successfully deleted.");
        handleGetCampaigns("delete");
      } catch (error) {
        if (error.status === 422)
          toast.error("It appears that something went wrong");
      }
    }
  };

  const getActiveCampaigns = async (campaign) => {
    try {
      const activeCampaign = await http.get(`campaigns/${campaign.id}/active`);
      return activeCampaign.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditCampaignStatus = async (campaign) => {
    const activeCampaigns = await getActiveCampaigns(campaign);

    if (campaign.status === "dormant") {
      if (activeCampaigns.active >= activeCampaigns.max) {
        notice({
          title: "Active Campaign Limit",
          buttonText: "Ok",
          description: (
            <>
              <strong>
                You've hit your limit of {activeCampaigns.max} campaign(s)
                active.
              </strong>
              <br />
              Deactivate one before activating this one.
            </>
          ),
        });
      } else {
        confirm(
          {
            title: "Activate Campaign",
            buttonText: "YES, ACTIVATE IT",
            description: "Are you sure you want to activate this Campaign?",
          },
          (result) => {
            if (result) {
              handleChangeStatus(campaign);
            }
          }
        );
      }
    } else {
      handleChangeStatus(campaign);
    }
  };

  const setCampaignStatus = (status, index) => {
    const result = update(state.data, {
      [index]: {
        status: { $set: status },
      },
    });

    setState((prev) => ({ ...prev, data: result }));
  };

  const checkScroll = () => {
    let header = document.getElementById("sticky-header");
    let text = document.getElementById("text-decoration");
    let bound = header.getBoundingClientRect();
    let sticky = bound.top;

    if (sticky === 0) {
      header.classList.add("bg-white", "py-4", "border-b", "border-thBorder");
      text.classList.add("text-xl", "text-decoration-30");
      text.classList.remove("text-2xl", "text-decoration-20");
    } else {
      header.classList.remove(
        "bg-white",
        "py-4",
        "border-b",
        "border-thBorder"
      );
      text.classList.remove("text-xl", "text-decoration-30");
      text.classList.add("text-2xl", "text-decoration-20");
    }
  };

  const handleDuplicateCampaign = async (campaign) => {
    if (campaign) {
      setState({ ...state, loading: true });
      try {
        const del = await http.post(`campaigns/duplicate/${campaign.id}`);
        const res = del.data;
        if (res.success) {
          handleGetCampaigns("load");
          toast.success("Successfully duplicated campaign.");
        }
      } catch (error) {
        if (error.status === 422)
          toast.error("It appears that something went wrong");
        setState({ ...state, loading: false });
      }
    }
  };

  const createEditDropdown = (data) => [
    {
      icon: <CampaignPopoutEditSVG />,
      text: "Edit Campaign",
      action: () => history.push(`/campaign-details/${data.id}/popouts`),
    },
    {
      icon: <CampaignPopoutDuplicateSVG />,
      text: "Duplicate Campaign",
      action: () => handleDuplicateCampaign(data),
    },
    {
      icon: <CampaignPopoutDeleteSVG />,
      text: "Delete Campaign",
      action: () => handleConfirmDelete(data),
    },
  ];

  const addToRef = (el) => {
    if (el && !arrayRefs.current.includes(el)) {
      arrayRefs.current.push(el);
    }
  };

  const columns = [
    {
      title: "Campaigns",
      dataIndex: "title",
      key: "title",
      width: "500px",
      hideSorting: false,
      render: (record) => (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => history.push(`/campaign-details/${record.id}`)}
        >
          <div className="flex-shrink-0 text-left">
            {record.desktop_image ? (
              <img
                src={record.desktop_image.url}
                alt={record?.title || record?.website_url}
                className="campaing-website object-contain"
              />
            ) : (
              <CampaignBanner className="campaing-website" />
            )}
          </div>
          <div className="ml-6 text-left">
            <h3 className="text-2xl leading-9">
              {record?.title || record?.website_url}
            </h3>
            <p className="text-secondary whitespace-nowrap">
              {_.truncate(record?.website_url, {
                length: 45,
                separator: "...",
              })}
            </p>
            <p className="pt-3 text-sm text-black text-opacity-40">
              Published on&nbsp;
              {moment(record?.created_at).format("DD MMM YYYY h:mm A")}
            </p>
            <p className="text-sm text-black text-opacity-40">
              Last Updated on&nbsp;
              {moment(record?.updated_at).format("DD MMM YYYY h:mm A")}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Visitors",
      dataIndex: "impressions",
      key: "opens",
      align: "center",
      showInfo: true,
      infoText: "Total number of visitors that have seen this campaign.",
      render: (record) => {
        return (
          <p className="text-xl leading-7 tracking-normalwide text-center">
            {numeral(record.totals?.unique_opens).format("0,0")}
          </p>
        );
      },
    },
    {
      title: "Opens",
      dataIndex: "Opens",
      key: "clicks",
      align: "center",
      showInfo: true,
      infoText: "Total number of visitors that have opened a popout.",
      render: (record) => {
        return (
          <p className="text-xl leading-7 tracking-normalwide text-center">
            {numeral(record.totals?.unique_clicks).format("0,0")}
          </p>
        );
      },
    },
    {
      title: "Interactions",
      dataIndex: "interactions",
      key: "interactions",
      align: "center",
      showInfo: true,
      infoText: "Total number of interactions with popouts.",
      render: (record) => {
        return (
          <p className="text-xl leading-7 tracking-normalwide text-center">
            {numeral(record.totals?.unique_interactions).format("0,0")}
          </p>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: "125px",
      render: (record) => (
        <div className="relative" ref={addToRef}>
          <PopoutAction
            id={`action_campaign_${record.id}`}
            show={show[`action_campaign_${record.id}`]}
            setShow={setShow}
            dropdown={createEditDropdown(record)}
            width="w-61"
          >
            <EllipsisVIconSVG className="m-auto" />
          </PopoutAction>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record) => (
        <Toggle
          disabled={!accountActive}
          checked={record?.status === "active" && accountActive}
          onChange={(e) => handleEditCampaignStatus(record)}
        />
      ),
    },
  ];
  const onHandleChangeSorting = (col, sort) => {
    let payload = _.clone(SORT_BY);
    let obj = _.find(payload, { value: col, sort: sort });
    setSort(obj);
  };

  // outside clicks
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * set close if clicked on outside of element
       */
      function handleClickOutside(event) {
        let result = false;
        if (ref.current) {
          _.forEach(ref.current, function (value) {
            if (value.contains(event.target)) {
              result = true;
            }
          });
        }
        if (!result) {
          setShow({});
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(arrayRefs);

  useEffect(() => {
    handleGetCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.limit, sort]);

  useEffect(() => {
    setCampaign({});
    setSelectedTab("basic");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      title="Campaigns"
      routeName="campaigns"
      noPadding={true}
      scrollYPosition={checkScroll}
    >
      <div className="w-full py-8">
        <div className="w-full sticky top-0 z-10" id="sticky-header">
          <div className="relative popout-wrapper mx-auto flex-1 px-7">
            <div className="flex items-center justify-between flex-wrap">
              <div className="flex items-center gap-x-5 flex-wrap">
                <p
                  className="text-2xl font-medium underline text-decoration text-decoration-20"
                  id="text-decoration"
                >
                  Campaigns {!_.isEmpty(state.data) && `(${state.meta?.total})`}
                </p>
              </div>
              <div className="flex 7md:justify-end items-center 7md:space-x-5 flex-wrap">
                <div className="flex items-center w-71">
                  <SelectPopoutSort
                    wrapperClass="relative"
                    name="popoutSort"
                    options={SORT_BY}
                    placeholder="Sort By"
                    value={sort}
                    readOnly={true}
                    onChange={(e) => setSort(e)}
                  />
                </div>
                <Button
                  buttonName="CREATE CAMPAIGN"
                  buttonType="primary"
                  buttonTextClass="text-white text-sm font-bold"
                  buttonClass="bg-primary rounded-full py-3 px-12 text-center float-left 1md:float-right"
                  onClick={() => handleCreate(false)}
                />
              </div>
            </div>
          </div>
        </div>
        {/* content */}
        <div className="relative mt-6 popout-wrapper mx-auto px-7 overflow-x-auto 3md:overflow-x-hidden">
          <CampaignTable
            columns={columns}
            dataSource={state.data}
            noData={<EmptyCampaign onCreateClick={() => handleCreate(false)} />}
            paginate={true}
            pagination={{
              lastPage: state.meta.last_page,
              onChangePage: (page) => {
                setState((prev) => ({ ...prev, page: page }));
              },
              currentPage: state.page,
            }}
            loading={state.loading}
            onHandleChangeSorting={(a, b) => onHandleChangeSorting(a, b)}
            sort={sort}
          />
        </div>
      </div>
    </Layout>
  );
}
