import styled, { css, keyframes } from "styled-components";

export const createPulseAnimation = (color) => keyframes`
  0% {
    box-shadow: 0;
  }
  40% {
    box-shadow: 0 0 0 15px ${color}B3;
  }
  to {
    box-shadow: 0 0 0 22px ${color}00;
  }
`;

// Styled component with dynamic color and border-radius
export const DynamicEffects = styled.div`
  .cc-popout-item::before,
  .popup-tab::before {
    ${({ color, effects }) => {
      if (effects.includes("pulse")) {
        // Use the hex color directly, default to a fallback color if necessary
        const hexColor = color || "#fb1f40"; // Default color
        const pulseAnimation = createPulseAnimation(hexColor);
        return css`
          animation: ${pulseAnimation} 2s ease infinite;
          animation-delay: 1s;
        `;
      }
    }}
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    ${({ cornerStyle, isPreview }) => {
      if (cornerStyle === "rounded") {
        if (isPreview) return "border-radius: 32px 0px 0px 32px;";
        return "border-radius: 20px 0px 0px 20px;";
      } else if (cornerStyle === "curved") {
        if (isPreview) return "border-radius: 16px 0px 0px 16px;";
        return "border-radius: 12px 0px 0px 12px;";
      }
    }}
  }
  ${({ effects }) => {
    if (effects.includes("colour")) {
      return css`
        .popup-tab,
        .cc-popout-item {
          -webkit-animation:
            slide 1s forwards,
            saturationFade 2s 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          animation:
            slide 1s forwards,
            saturationFade 2s 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }

        .popup-tab:hover,
        .cc-popout-item:hover {
          /* Stop only the 'saturationFade' animation on hover by setting its name to 'none' */
          -webkit-animation: none;
          animation: none;
        }
      `;
    }
  }}
`;
