import React from "react";

const ButtonColors = ({ selected = false, hex = "", onClick = () => {} }) => {
  return (
    <button
      type="button"
      className={`rounded-full mr-2.5 mb-3.5 ${
        selected
          ? "animate-btn-color btn-color-active btn-primary-picker-active"
          : "btn-primary-picker border border-stlBtnBorder animate-btn"
      }`}
      style={{ background: hex }}
      onClick={onClick}
    />
  );
};

export default ButtonColors;
