import React from "react";
import _ from "lodash";
import { FaStar } from "react-icons/fa";
import "./styles.css";

const StarRating = ({ number = 5, value = 0, className = "" }) => {
  return (
    <div className="flex space-x-1">
      {_.times(number, (i) => (
        <FaStar
          key={i}
          className={`${
            value >= i + 1 ? "star-active" : "star-inactive"
          } ${className}`}
        />
      ))}
    </div>
  );
};

export default StarRating;
