const GoogleSVG = ({ className }) => {
  return (
    <svg
      className={className ?? ""}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4404 11.7148H24.5007V11.6663H14.0007V16.333H20.5941C19.6322 19.0496 17.0474 20.9997 14.0007 20.9997C10.1349 20.9997 7.00065 17.8654 7.00065 13.9997C7.00065 10.1339 10.1349 6.99967 14.0007 6.99967C15.7851 6.99967 17.4085 7.67284 18.6446 8.77242L21.9445 5.47251C19.8608 3.53059 17.0737 2.33301 14.0007 2.33301C7.55773 2.33301 2.33398 7.55676 2.33398 13.9997C2.33398 20.4426 7.55773 25.6663 14.0007 25.6663C20.4436 25.6663 25.6673 20.4426 25.6673 13.9997C25.6673 13.2174 25.5868 12.4538 25.4404 11.7148Z"
        fill="#FFC107"
      />
      <path
        d="M3.67773 8.56943L7.51082 11.3805C8.54798 8.81267 11.0598 6.99967 13.9992 6.99967C15.7837 6.99967 17.4071 7.67284 18.6432 8.77242L21.9431 5.47251C19.8594 3.53059 17.0722 2.33301 13.9992 2.33301C9.51807 2.33301 5.6319 4.86292 3.67773 8.56943Z"
        fill="#FF3D00"
      />
      <path
        d="M13.9995 25.6671C17.013 25.6671 19.7512 24.5138 21.8214 22.6384L18.2106 19.5829C16.9999 20.5036 15.5205 21.0016 13.9995 21.0004C10.965 21.0004 8.38845 19.0655 7.41778 16.3652L3.61328 19.2965C5.54411 23.0747 9.46528 25.6671 13.9995 25.6671Z"
        fill="#4CAF50"
      />
      <path
        d="M25.4398 11.7154H24.5V11.667H14V16.3337H20.5934C20.1333 17.6266 19.3045 18.7563 18.2093 19.5834L18.2111 19.5822L21.8219 22.6377C21.5664 22.8699 25.6667 19.8337 25.6667 14.0003C25.6667 13.2181 25.5862 12.4545 25.4398 11.7154Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default GoogleSVG;
