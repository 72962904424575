import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import _ from "lodash";
import SortingIconSVG from "assets/svg/sorting-icon";
import "./styles.css";

export default function SelectPopoutSort({
  options = [],
  placeholder = "Select",
  label = null,
  value = "",
  name = "select",
  onChange = () => {},
  readOnly = false,
  icon = <FaChevronDown />,
  height = "45px",
  wrapperClass = "select-wrapper",
}) {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(value?.label);
  const [list, setList] = useState(options);

  const handleOnChange = (e) => {
    let filterValue = e.target?.value;
    if (filterValue) {
      let result = _.filter(options, function (b) {
        let label = _.lowerCase(b.label);
        return label.includes(_.lowerCase(filterValue));
      });
      setList(result);
      setOpen(true);
    } else {
      setList(options);
    }
    setFilter(e.target?.value);
  };

  const handleOnSelect = (obj) => {
    onChange(obj);
    setOpen(false);
  };

  // outside clicks
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  // end: outside clicks

  useEffect(() => {
    if (value) {
      setFilter(value?.label);
    }
  }, [value]);

  return (
    <div className="w-full">
      {label && <label htmlFor={label}>{label}</label>}
      <div className={wrapperClass} ref={wrapperRef}>
        {/* <!-- component --> */}
        <div className="relative w-full">
          {/* <!-- trigger button --> */}
          <button
            type="button"
            style={{ height: height }}
            className="select-sort ring-sortBg"
            onClick={() => setOpen(!open)}
          >
            <div className="relative w-full">
              <div className="flex items-center space-x-2">
                <SortingIconSVG />
                <input
                  className={`w-full mr-3 bg-sortBg ${
                    readOnly ? "cursor-pointer" : ""
                  }`}
                  name={name}
                  autoComplete="off"
                  placeholder={placeholder}
                  readOnly={readOnly}
                  value={filter}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            {icon}
          </button>
          {/* list items */}
          <ul
            className={`select-list-container select-scroll z-50 ${
              open ? "block" : "hidden"
            }`}
          >
            {_.isEmpty(list) && (
              <li className="p-2 text-center opacity-50">{`No Data Found ...`}</li>
            )}
            {(list || []).map((obj, index) => (
              <li
                key={index}
                className={`select-list-popout ${
                  obj?.value === value?.value &&
                  obj.sort === value?.sort &&
                  "list-active"
                }`}
                onClick={() => handleOnSelect(obj)}
              >
                <p className="w-full">{obj.label}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
