import styled from "styled-components";

const Button = styled.button`
  border-radius: ${({ radius }) => radius || "9999px"} !important;
  color: ${({ color }) => color || "rgb(255, 255, 255)"} !important;
  text-align: center;

  padding-left: 36px;
  padding-right: 36px;
  padding-top: 16px;
  padding-bottom: 16px;

  font-size: 14px;
  line-height: 18px;

  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;

  display: flex;
  align-items: center;
  transition-duration: 500ms;

  background: ${({ background }) =>
    background ? background : "#848586"} !important;
  display: ${({ display }) => display} !important;
  font-family: ${({ family }) => family} !important;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)} !important;

  &:hover {
    opacity: 0.8 !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px;
    line-height: 21px;

    padding-left: 48px;
    padding-right: 48px;
    padding-top: 16.5px;
    padding-bottom: 16.5px;
  }
`;

Button.defaultProps = {
  background: "#848586",
  display: "inline-block",
  family: "",
  disabled: false,
};

export default Button;
