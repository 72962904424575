import React from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { createPulseAnimation } from "components/global/dynamicEffects";

const PopupImageBase = styled(motion.div)`
  position: ${({ overlay }) => (overlay ? "relative" : "absolute")};
  left: ${({ overlay, fullW }) => (overlay ? 0 : `-${fullW - 10}`)}px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  z-index: 999999;
  height: fit-content !important;

  &:hover {
    left: ${({ overlay, fullW }) => (overlay ? 0 : `-${fullW}`)}px !important;
  }

  &.openup {
    left: ${({ overlay, fullW }) => (overlay ? 0 : `-${fullW}`)}px !important;
    transition: 0.5s !important;
  }

  @media (max-width: 1${({ fullW }) => fullW}px) {
    display: none !important;
  }
`;
const PopupComponent = styled(motion.div)`
  position: ${({ overlay }) => (overlay ? "relative" : "absolute")};
  left: ${({ overlay, fullW }) => (overlay ? 0 : `-${fullW - 10}`)}px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: ${({ fullW }) => fullW}px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 18px;
  padding-left: 18px;
  overflow-wrap: break-word;
  cursor: pointer;
  z-index: 999999;
  background-color: ${({ background }) => background} !important;
  color: ${({ text }) => text} !important;
  border-radius: ${({ radius }) => radius} !important;
  height: fit-content !important;

  &:hover {
    left: ${({ overlay, fullW }) => (overlay ? 0 : `-${fullW}`)}px !important;
  }

  &.openup {
    left: ${({ overlay, fullW }) => (overlay ? 0 : `-${fullW}`)}px !important;
    transition: 0.5s !important;
  }

  @media (max-width: 1${({ fullW }) => fullW}px) {
    display: none !important;
  }

  &&::before {
    ${({ color, effects, overlay }) => {
      if (effects === "pulse" && !overlay) {
        // Use the hex color directly, default to a fallback color if necessary
        const hexColor = color || "#fb1f40"; // Default color
        const pulseAnimation = createPulseAnimation(hexColor);
        return css`
          animation: ${pulseAnimation} 2.5s ease infinite;
          animation-delay: 2s;
        `;
      }
    }}
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    ${({ cornerStyle, isPreview }) => {
      if (cornerStyle === "rounded") {
        if (isPreview) return "border-radius: 32px 0px 0px 32px;";
        return "border-radius: 20px 0px 0px 20px;";
      } else if (cornerStyle === "curved") {
        if (isPreview) return "border-radius: 16px 0px 0px 16px;";
        return "border-radius: 12px 0px 0px 12px;";
      }
    }}
  }
  ${({ effects, overlay }) => {
    if (effects === "colour" && !overlay) {
      return css`
        && {
          -webkit-animation: saturationFade 2s 1s cubic-bezier(0.4, 0, 0.6, 1)
            infinite;
          animation: saturationFade 2s 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }

        &&:hover {
          /* Stop only the 'saturationFade' animation on hover by setting its name to 'none' */
          -webkit-animation: none;
          animation: none;
        }
      `;
    }
  }}
`;

function Popup(
  {
    background = "#3B3B3B",
    text = "#FFFFFF",
    radius = 0,
    overlay = false,
    isImage = false,
    ...props
  },
  ref
) {
  if (isImage) {
    return (
      <PopupImageBase
        className={"cc-popout-item"}
        overlay={overlay}
        {...props}
        ref={ref}
      />
    );
  } else {
    return (
      <PopupComponent
        className={`cc-popout-item`}
        background={background}
        text={text}
        overlay={overlay}
        radius={radius}
        effects={props.effects}
        cornerStyle={props.corner_style}
        color={props.primary_colour}
        isPreview={true}
        {...props}
        ref={ref}
      />
    );
  }
}

export default React.forwardRef(Popup);
