import LogoLandscapeSVG from "assets/svg/logo-landscape";
import {
  Close,
  CodeBox,
  MegaPhone,
  Plug,
  User,
  WindowBox,
  WindowCodeBlock,
  WindowSidebar,
} from "assets/svg/sidebar/icons";
import { Context } from "context/context";
import React from "react";
import { MdHelpOutline } from "react-icons/md";
import { Link } from "react-router-dom";

const linkClass = `sidebar-link items-center group w-full hover:text-white text-darkGray flex gap-3`;
const linkClassActive = `sidebar-link items-center group w-full text-white flex gap-3 is-active`;
const iconWrapperClass =
  "sidebar-icon-bg flex-middle rounded-md bg-darkBlue group-hover:bg-secondary";
const iconWrapperClassActive =
  "sidebar-icon-bg flex-middle rounded-md bg-secondary";
const iconClass = "group-hover:fill-primary";
const iconClassActive = "fill-primary";

export default function SideBarContent({ routeName, onLogout, user }) {
  const { setMobileSidebar, toggleOverlay } = React.useContext(Context);

  const campaigns = ["campaigns", "campaign", "campaign-condition"];

  return (
    <div className="w-full">
      <div className="logo-bar pt-8 lg:block justify-between text-center flex items-center w-full mb-10">
        <LogoLandscapeSVG />
        <button
          onClick={() => setMobileSidebar(false)}
          className={"lg:hidden "}
        >
          <Close className="md:h-8 md:w-8" />
        </button>
      </div>
      <div className="list-of-links">
        <Link
          className={routeName === "dashboard" ? linkClassActive : linkClass}
          to={"/dashboard"}
        >
          <div
            className={
              routeName === "dashboard"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <WindowSidebar
              className={
                routeName === "dashboard" ? iconClassActive : iconClass
              }
            />
          </div>
          Dashboard
        </Link>
        <Link
          className={routeName === "popouts" ? linkClassActive : linkClass}
          to={"/popouts"}
        >
          <div
            className={
              routeName === "popouts"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <WindowCodeBlock className={iconClass} />
          </div>
          Popouts
        </Link>
        <Link
          className={
            campaigns.includes(routeName) ? linkClassActive : linkClass
          }
          to={"/campaigns"}
        >
          <div
            className={
              campaigns.includes(routeName)
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <MegaPhone className={iconClass} />
          </div>
          Campaigns
        </Link>
        <Link
          className={routeName === "contacts" ? linkClassActive : linkClass}
          to={"/contacts"}
        >
          <div
            className={
              routeName === "contacts"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <User className={iconClass} />
          </div>
          Contacts
        </Link>
        <Link
          className={routeName === "install" ? linkClassActive : linkClass}
          to={"/install"}
        >
          <div
            className={
              routeName === "install"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <WindowBox className={iconClass} />
          </div>
          Install
        </Link>
        <Link
          className={routeName === "integrations" ? linkClassActive : linkClass}
          to={"/integrations"}
        >
          <div
            className={
              routeName === "integrations"
                ? iconWrapperClassActive
                : iconWrapperClass
            }
          >
            <Plug className={iconClass} />
          </div>
          Integrations
        </Link>
        <div
          className={`sidebar-help-icon items-center group w-full text-darkGray hover:text-white gr flex gap-3 cursor-pointer`}
          onClick={() => toggleOverlay((prev) => !prev)}
        >
          <div className={iconWrapperClass + " group-hover:text-white"}>
            <MdHelpOutline
              className={`text-mutedGray group-hover:text-white text-2xl`}
            />
          </div>
          Help
        </div>
        {user && (
          <div className={`${linkClass} cursor-pointer`} onClick={onLogout}>
            <div className={iconWrapperClass}>
              <CodeBox className={iconClass} />
            </div>
            Logout
          </div>
        )}
      </div>
    </div>
  );
}
