import React from "react";
import { sanitizeValueHTML } from "utils/constant";

const HeaderExample = ({
  data,
  popup,
  show,
  placeholder = "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt",
  className = "",
}) => {
  return (
    <div
      style={{
        fontFamily: popup?.font?.family || "",
      }}
      className={`text-xl ${!data?.title && "text-tabBorder"} ${className}`}
      dangerouslySetInnerHTML={{
        __html: sanitizeValueHTML(data?.title) || (show ? placeholder : ""),
      }}
    />
  );
};

export default HeaderExample;
