import React, { useEffect, useMemo, useState } from "react";
import Layout from "layouts/layout";
import ContactTable from "components/contact/contact-table";
import SearchIcon from "assets/svg/search-icon";
import contactsAction from "redux/modules/contacts/actions";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import EmptyContact from "components/contact/empty-contact";
import { IoEllipsisVertical } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import Input from "components/input/input";
import Select from "components/select/select";
import Button from "components/button/button";
import _ from "lodash";
import LeadDetail from "components/campaigns/details/lead-details/lead-detail";

export const Contact = ({ contacts, setContacts }) => {
  const { http } = global.services;
  const [filter, setFilter] = useState({
    label: "Date subscribed",
    value: "created_at",
  });
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [drawer, setDrawer] = useState(false);
  const [lead, setLead] = useState({});
  const [metadata, setMetadata] = useState({
    total: 0,
    perPage: 5,
    currentPage: 1,
    lastPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    from: 0,
    to: 0,
  });

  const currentPage = useMemo(() => metadata.currentPage, [metadata]);

  const downloadCsv = async () => {
    try {
      setLoading(true);
      const response = await http.post("contacts/export");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contacts.csv");
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error("Error while fetching contacts:", err.message);
      toast.error("Error while exporting contacts");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "firstname",
      key: "firstname",
      width: "200px",
      thNowrap: true,
      tdNowrap: true,
      render: (record) => <p className="text-sm">{record?.firstname}</p>,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      thNowrap: true,
      width: "300px",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      thNowrap: true,
      width: "150px",
    },
    {
      title: "Date Subscribed",
      dataIndex: "subsribed",
      key: "subsribed",
      width: "200px",
      thNowrap: true,
      render: (record) => (
        <p className="text-sm">
          {new Date(record.created_at).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Campaign Title",
      dataIndex: "campaigns",
      key: "campaigns",
      width: "300px",
      thNowrap: true,
      hideSorting: false,
      tdNowrap: true,
      render: (record) => (
        <div className="text-sm flex items-center">
          {record && record.campaign && record.campaign.title}
        </div>
      ),
    },
    {
      title: "Popout",
      dataIndex: "popout",
      key: "popout",
      width: "200px",
      thNowrap: true,
      tdNowrap: true,
      render: (record) => (
        <p className="text-sm">
          {record?.popup?.title || record?.popup?.quote || "---"}
        </p>
      ),
    },
    {
      title: "Special Condition",
      dataIndex: "conditions",
      key: "conditions",
      width: "300px",
      thNowrap: true,
      tdNowrap: true,
      render: (record) => {
        const condition = JSON.parse(
          record?.campaign_popup?.popup_condition || "{}"
        );
        return <p className="text-sm">{condition?.title || "---"}</p>;
      },
    },
    {
      title: "Page URL",
      dataIndex: "website_url",
      key: "website_url",
      width: "200px",
      thNowrap: true,
      render: (record) => (
        <a
          className="text-sm flex items-center cursor-pointer text-secondary"
          href={record && record.pageUrl}
        >
          {record && record.pageUrl}
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (record) => (
        <button className="p-3" onClick={() => setLead(record)}>
          <IoEllipsisVertical />
        </button>
      ),
    },
  ];

  const onHandleChangeSorting = (col) => {};

  useEffect(() => {
    const getContacts = async () => {
      setLoading(true);
      try {
        const response = await http.get(`contacts?search=${search}`, {
          page: metadata.currentPage,
          limit: metadata.perPage,
        });
        setContacts(response.data.data);
        setMetadata((prev) => ({
          ...prev,
          total: response.data.total,
          currentPage: response.data.current_page,
          lastPage: response.data.last_page,
          hasNextPage: !!response.data.next_page_url,
          hasPrevPage: !!response.data.prev_page_url,
          from: response.data.from,
          to: response.data.to,
        }));
      } catch (err) {
        console.error("Error while fetching contacts:", err.message);
        toast.error("Error while fetching contacts");
      } finally {
        setLoading(false);
      }
    };

    getContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filter, search]);

  const checkScroll = () => {
    let header = document.getElementById("sticky-header");
    let text = document.getElementById("text-decoration");
    let bound = header.getBoundingClientRect();
    let sticky = bound.top;

    if (sticky <= 0) {
      header.classList.add("bg-white", "py-4", "border-b", "border-thBorder");
      text.classList.add("text-xl");
      text.classList.remove("text-2xl");
    } else {
      header.classList.remove(
        "bg-white",
        "py-4",
        "border-b",
        "border-thBorder"
      );
      text.classList.remove("text-xl");
      text.classList.add("text-2xl");
    }
  };

  return (
    <Layout
      title="Contact"
      routeName="contacts"
      noPadding={true}
      scrollYPosition={checkScroll}
    >
      <div className="w-full py-8">
        <div className="w-full sticky top-0 z-10 " id="sticky-header">
          <div className="relative popout-wrapper mx-auto flex-1 px-7">
            <div className="w-full">
              <div className="flex items-center justify-between flex-wrap">
                <div className="flex items-center gap-x-5 flex-wrap">
                  <p
                    className="text-2xl font-medium underline text-decoration text-decoration-20"
                    id="text-decoration"
                  >
                    Contacts {metadata.total > 0 && `(${metadata.total})`}
                  </p>
                </div>
                <div>
                  <Button
                    buttonName="EXPORT LIST"
                    buttonTextClass="text-white text-sm font-bold"
                    buttonType="primary"
                    ariaLabel="Export Contacts to CSV"
                    buttonClass="bg-primary ml-auto rounded-full py-3 px-12 text-center text-white float-right"
                    onClick={() => downloadCsv()}
                  />
                </div>
              </div>
              {/* end row */}
              <div className="flex flex-wrap items-center justify-between w-full mt-5">
                <div className="w-9/20">
                  <div className="w-full flex items-center flex-wrap">
                    <div className="w-12/20">
                      <Input
                        placeholder="Search"
                        noLabelPaddingY="1rem"
                        value={search}
                        onChange={(e) => setSearch(e?.target?.value || "")}
                        inputIcon={<SearchIcon />}
                      />
                    </div>
                    <div className="pl-3 w-8/20">
                      {!_.isEmpty(search) && (
                        <button
                          className="w-full break-all bg-white flex justify-between items-center rounded-lg p-1 text-sm text-black text-opacity-70 border border-thBorder"
                          onClick={() => setSearch("")}
                        >
                          <p className="px-1 pt-1 pb-0.5 mt-0.5">{search}</p>
                          <span>
                            <FaTimes />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center">
                    <p className="mr-5">Sort by</p>
                    <div>
                      <Select
                        height="57px"
                        wrapperClass="no-padding-wrapper"
                        options={[
                          { label: "Date Subscribed", value: "created_at" },
                        ]}
                        value={filter?.label}
                        placeholder="Filter"
                        onChange={(e) => setFilter(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* */}
        <div className="relative mt-6 popout-wrapper mx-auto px-7 overflow-x-auto 3md:overflow-x-hidden">
          <ContactTable
            columns={columns}
            dataSource={contacts}
            noData={
              <EmptyContact
                text={
                  _.isEmpty(search)
                    ? "You have no contacts yet"
                    : "No Contacts Found"
                }
              />
            }
            loading={loading}
            metadata={metadata}
            onHandleChangeSorting={(column) => onHandleChangeSorting(column)}
            onPageChange={(nextPage) =>
              setMetadata((prev) => ({
                ...prev,
                currentPage: nextPage,
              }))
            }
            onClickRow={(record) => {
              setDrawer((prev) => !prev);
              setLead(record);
            }}
          />
        </div>
      </div>
      <LeadDetail overlay={drawer} toggleOverlay={setDrawer} data={lead} />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts.contactList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContacts: (params) => {
      dispatch(contactsAction.setContacts(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
