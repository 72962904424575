import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import Modal from "components/global/modal";
import { Context } from "context/context";
import { useContext, useState } from "react";
import toast from "react-hot-toast";

export default function CustomPlanModal({ hide, data }) {
  const { http } = global.services;
  const [loading, setLoading] = useState(false);
  const { setAlerts, alerts } = useContext(Context);

  const acceptCustomPlan = async () => {
    setLoading(true);

    try {
      const { data: response } = await http.post(
        `custom-subscriptions/accept/${data.id}`
      );
      toast.success(
        response.data?.message ||
          response.message ||
          "Subscription accepted successfully"
      );
      hide(response.data);
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      const CUSTOM_PLAN_OFFER_ALERT_INDEX = alerts.some(
        (alert) => alert.type === "custom-plan-offer"
      );

      // Remove the notification on dashboard after successfully accepted the custom plan
      if (CUSTOM_PLAN_OFFER_ALERT_INDEX !== -1) {
        const prevAlertArr = [...alerts];
        prevAlertArr.splice(CUSTOM_PLAN_OFFER_ALERT_INDEX, 1);
        setAlerts(prevAlertArr);
      }

      setLoading(false);
    }
  };

  const Footer = () => {
    return (
      <div className="relative flex flex-row items-center justify-end w-full space-x-2">
        <Button
          key="proceed-btn"
          buttonName="ACCEPT"
          buttonClass="bg-secondary rounded-full py-3 px-12 w-full"
          buttonType="secondary"
          buttonTextClass="text-white text-sm font-bold"
          onClick={acceptCustomPlan}
          disabled={loading}
          icon={
            loading && (
              <div className="absolute right-3 bottom-0 top-0 flex items-center">
                <LoadingIndicator className="text-white w-5 h-5" />
              </div>
            )
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="350px"
      contentClassName="rounded-lg"
      wrapperClassName="overflow-visible"
    >
      <div className="flex items-start px-4">
        <div className="w-full">
          <h3 className="text-2xl leading-9" id="modal-title">
            {data.name}
          </h3>
          <div className="mt-4 relative">
            <p className="text-base">
              {`Your proposed custom plan is $${data.amount / 100}`}
              <span className="uppercase text-sm">({data.currency})</span>
              {` per ${data.interval}.`}
            </p>
          </div>

          <div className="flex items-center flex-wrap">
            <p className="text-2xl">$</p>
            <p className="pl-2" style={{ fontSize: "48px" }}>
              {data.amount / 100}
            </p>
            <span className="text-base opacity-40 pl-3 -mb-2.5">
              /{data.interval}
            </span>
          </div>
        </div>
      </div>
      <div
        className="close absolute top-6 right-6"
        onClick={() => hide()}
      ></div>
    </Modal>
  );
}
