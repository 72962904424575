import * as Sentry from "@sentry/react";
import { ContextProvider } from "context/context";
import { PusherProvider } from "context/pusher";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "redux/index";
import { routerHistory } from "router";
import httpService from "services/https";
import localstorageService from "services/local-storage";
import validationService from "services/validation";
import { storeQueryStringCid } from "utils/trackdesk";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [process.env.REACT_APP_API_ENDPOINT],
      routingInstrumentation:
        Sentry.reactRouterV5Instrumentation(routerHistory),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: parseInt(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
  replaysSessionSampleRate: parseInt(process.env.REACT_APP_SENTRY_REPLAY_RATE),
  replaysOnErrorSampleRate: 1.0, // Always capture a replace on error
  denyUrls: ["https://www.googletagmanager.com"],
});

global.services = {
  http: httpService,
  localstorage: localstorageService,
  validation: validationService,
};

storeQueryStringCid();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ContextProvider>
    <Provider store={store}>
      <PusherProvider>
        <App />
      </PusherProvider>
    </Provider>
  </ContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
