import { useContext } from "react";
import { Context } from "context/context";
import { useHistory, useParams } from "react-router-dom";
import CampaignPopoutEditSVG from "assets/svg/campaign/popout/edit-icon";
import CampaignPopoutCogSVG from "assets/svg/campaign/popout/cog-icon";
import CampaignPopoutBulbSVG from "assets/svg/campaign/popout/bulb-icon";
import CampaignPopoutDuplicateSVG from "assets/svg/campaign/popout/duplicate-icon";
import CampaignPopoutDeleteSVG from "assets/svg/campaign/popout/delete-icon";
import _ from "lodash";
import toast from "react-hot-toast";
import ConfirmModal from "components/confirm/confirm";
import { useDialogHook } from "utils/customhooks";

export default function PopoutDropdown(data, setLoading) {
  const history = useHistory();
  const { http } = global.services;
  const { id } = useParams();
  const {
    campaign: details,
    setCampaign,
    setDefaultTab,
    setAlternative,
  } = useContext(Context);

  const confirm = useDialogHook(ConfirmModal);

  const onHandleDropdownAction = (type, data = null) => {
    if (type === "edit-popout") onEditPopout(data);

    if (type === "edit-popout-b") onEditPopoutB(data);

    if (type === "configure-conditions") onEditAlternative(data);

    if (type === "make-base-popout") onHandleMakeBasePopout(data);

    if (type === "duplicate-popout") onHandleDuplicatePopout(data);

    if (type === "delete-popout") handleConfirmDelete(data);
  };

  const onEditPopout = (data) => {
    setDefaultTab("basic");
    try {
      setTimeout(() => {
        history.push(
          `/campaigns/${data?.campaign_id}/popup/${data.popup_a_id || data.id}`
        );
      }, 300);
    } catch (e) {
      console.error(e);
      toast.error(JSON.stringify(e));
    }
  };

  const onEditPopoutB = (data) => {
    setDefaultTab("basic");
    try {
      setTimeout(() => {
        history.push(
          `/campaigns/${data?.campaign_id}/popup/${data.popup_b_id}`
        );
      }, 300);
    } catch (e) {
      console.error(e);
      toast.error(JSON.stringify(e));
    }
  };

  const onEditAlternative = (popup) => {
    //set
    setAlternative({
      campaign_id: id,
      popups: details?.popups || [],
      condition: popup.condition,
      popup,
    });
    //push
    history.push(`/campaign-details/${id}/manage/special-condition`);
  };

  const onHandleMakeBasePopout = (popup) => {
    const tempPopout = [...details?.campaign_popups];

    popup.order_in = 0; // update selected popup as base popup
    const sourceIndex = tempPopout.findIndex((item) => item.id === popup.id); // selected popup
    const destinationIndex = tempPopout.findIndex(
      (item) => item.order_in === 0
    ); // base popup
    const [selectedRow] = tempPopout.splice(sourceIndex, 1);

    tempPopout.splice(destinationIndex, 0, selectedRow);
    const data = tempPopout.map((obj, index) => ({ ...obj, order_in: index })); // update order_in on each popup from its index

    handleUpdateOrder(data, true);
  };

  const onHandleDuplicatePopout = async (popup) => {
    if (!_.isEmpty(setLoading)) setLoading(true);

    if (popup.id) {
      try {
        const result = await http.post(`campaign-popups/duplicate/${popup.id}`);
        const data = result.data.data;

        // insert duplicated popup under the original popup
        details.campaign_popups?.splice(data.order_in, 0, data);

        setCampaign((prev) => ({
          ...prev,
          campaign_popups: details.campaign_popups,
        }));
      } catch (error) {
        if (error.status === 422)
          toast.error("It appears that something went wrong");
      } finally {
        if (!_.isEmpty(setLoading)) setLoading(false);
      }
    }
  };

  const handleConfirmDelete = (popup) => {
    const payload = {
      title: "Delete Special Condition",
      buttonText: "YES, DELETE IT",
      description: "Do you want to delete this special condition?",
    };
    confirm(payload, (result) => {
      if (result) {
        onHandleDeletePopout(popup);
      }
    });
  };

  const onHandleDeletePopout = async (popup) => {
    // Alerts delete is not allowed if there's only one popout
    if (details?.campaign_popups.length === 1) {
      return toast.error(
        "Unable to delete popout since this campaign has only one(1) popout"
      );
    }

    if (popup) {
      try {
        const result = await http.delete(`campaign-popups/${popup.id}`);
        const data = result.data.data;
        const popupIndex = details.campaign_popups?.findIndex(
          (item) => item.id === data.id
        );

        details.campaign_popups.splice(popupIndex, 1);

        // if removed popout is a base popout
        if (popupIndex === 0) {
          const newBasePopout = details.campaign_popups?.find(
            (item) => item.order_in === 1
          );
          return onHandleMakeBasePopout(newBasePopout);
        }

        setCampaign((prev) => ({
          ...prev,
          campaign_popups: details.campaign_popups,
        }));
        toast.success("Successfully removed popout");
      } catch (error) {
        if (error.status === 422)
          toast.error("It appears that something went wrong");
      } finally {
        if (!_.isEmpty(setLoading)) setLoading(false);
      }
    }
  };

  const handleUpdateOrder = async (data, forceUpdateData = false) => {
    try {
      await http.put(`campaign-popups/reorder/${id}`, data);

      if (forceUpdateData) {
        setCampaign((prev) => ({ ...prev, campaign_popups: data }));
        toast.success("Successfully updated default popout");
      } else {
        setCampaign((prev) => ({
          ...prev,
          campaign_popups: [prev.campaign_popups[0], ...data],
        }));
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    }
  };

  if (
    data?.popup_a?.status === "active" &&
    data?.popup_b?.status === "active"
  ) {
    return [
      {
        icon: <CampaignPopoutCogSVG />,
        text: "Configure Conditions",
        action: () => onHandleDropdownAction("configure-conditions", data),
      },
      {
        icon: <CampaignPopoutBulbSVG />,
        text: "Make as Default",
        action: () => onHandleDropdownAction("make-base-popout", data),
      },
      {
        icon: <CampaignPopoutDuplicateSVG />,
        text: "Duplicate Condition",
        action: () => onHandleDropdownAction("duplicate-popout", data),
      },
      {
        icon: <CampaignPopoutEditSVG />,
        text: "Edit Popout A",
        action: () => onHandleDropdownAction("edit-popout", data),
      },
      {
        icon: <CampaignPopoutEditSVG />,
        text: "Edit Popout B",
        action: () => onHandleDropdownAction("edit-popout-b", data),
      },
      {
        icon: <CampaignPopoutDeleteSVG />,
        text: "Delete",
        action: () => onHandleDropdownAction("delete-popout", data),
      },
    ];
  } else {
    return [
      {
        icon: <CampaignPopoutCogSVG />,
        text: "Configure Conditions",
        action: () => onHandleDropdownAction("configure-conditions", data),
      },
      ...(data?.campaign_id
        ? [
            {
              icon: <CampaignPopoutBulbSVG />,
              text: "Make as Default",
              action: () => onHandleDropdownAction("make-base-popout", data),
            },
          ]
        : []),
      {
        icon: <CampaignPopoutDuplicateSVG />,
        text: "Duplicate Condition",
        action: () => onHandleDropdownAction("duplicate-popout", data),
      },
      {
        icon: <CampaignPopoutEditSVG />,
        text: "Edit Popout",
        action: () => onHandleDropdownAction("edit-popout", data),
      },
      {
        icon: <CampaignPopoutDeleteSVG />,
        text: "Delete",
        action: () => onHandleDropdownAction("delete-popout", data),
      },
    ];
  }
}
