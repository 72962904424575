import React from "react";
import { Helmet } from "react-helmet";

const EmptyLayout = ({ children, title, color = "red" }) => {
  return (
    <div
      // className="w-screen h-screen overflow-x-auto 1md:overflow-x-hidden bg-siteBg lg:static lg:overflow-y-auto lg:flex-grow flex flex-col flex-1 custom-scroll"
      className="w-full h-screen"
      style={{ minWidth: "552px" }}
    >
      {title && (
        <Helmet>
          <title>{title} - ConversionCow</title>
        </Helmet>
      )}
      <div
        className={`empty-layout-responsive `}
        style={{ backgroundColor: color }}
      >
        {children}
      </div>
    </div>
  );
};

export default EmptyLayout;
