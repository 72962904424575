const SendIconSVG = ({ className = "", fill = "#808080" }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 9.16675L18.3333 1.66675L10.8333 17.5001L9.16667 10.8334L2.5 9.16675Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default SendIconSVG;
