import React from "react";
import "./styles.css";

const InputBrowser = ({ icon, mobile, value }) => {
  return (
    <>
      {icon}
      <input
        value={value || ""}
        className={`${
          mobile ? "bg-siteBg" : "bg-gray"
        } rounded-full w-full py-1 pl-10`}
        readOnly
      />
    </>
  );
};

export default InputBrowser;
