import CampaignEmptySVG from "assets/svg/campaign-empty-svg";

const defaultStyle = {
  height: "calc(100vh - 213px)",
};

const EmptyCampaign = ({
  style = defaultStyle,
  title = "You have no campaign yet",
  subTitle = "Do you want to create a campaign?",
  link = { path: "/campaigns/popup", label: "Create your first campaign" },
  onCreateClick = () => {},
}) => {
  return (
    <div className="grid h-full" style={style}>
      <div className="m-auto">
        <CampaignEmptySVG />
        <div className="text-center">
          <p className="pt-6 text-xl font-medium">{title}</p>
          <p className="pt-2 text-sm">
            <span className="opacity-70">{subTitle}&nbsp;</span>
            <span
              onClick={onCreateClick}
              className="font-medium text-secondary cursor-pointer"
            >
              {link.label}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyCampaign;
