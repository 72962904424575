import styled from "styled-components";
import { device } from "../../../screens";

const Svg = styled.svg`
  margin-right: 8px;
  width: 24px;
  height: 24px;

  @media ${device.xs} {
    margin-right: 12px;
    width: 32px;
    height: 32px;
  }
`;

const PhoneIconSVG = ({ fill = "#808080" }) => {
  return (
    <Svg viewBox="2 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.8528 13.738C28.0216 12.6043 27.965 11.4484 27.6861 10.3366C27.3055 8.81742 26.5185 7.43049 25.4094 6.32463C24.3036 5.21538 22.9167 4.42812 21.3974 4.0473C20.2856 3.76876 19.1298 3.71235 17.9961 3.8813M22.7928 13.202C22.8859 12.5832 22.8323 11.9512 22.6364 11.3569C22.4405 10.7626 22.1077 10.2227 21.6648 9.78063C21.2226 9.33786 20.6827 9.00516 20.0884 8.80934C19.4941 8.61353 18.8622 8.56008 18.2434 8.6533"
        stroke={fill}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.58423 5.86255C9.82206 5.86267 10.0555 5.92641 10.2604 6.04714C10.4653 6.16788 10.6342 6.34123 10.7496 6.54922L12.3809 9.48655C12.4861 9.67611 12.5436 9.88848 12.5482 10.1052C12.5529 10.322 12.5046 10.5367 12.4076 10.7305L10.8362 13.8725C10.8362 13.8725 11.2916 16.2139 13.1969 18.1192C15.1029 20.0246 17.4362 20.4725 17.4362 20.4725L20.5776 18.9012C20.7716 18.8042 20.9864 18.7559 21.2033 18.7607C21.4202 18.7655 21.6326 18.8231 21.8222 18.9285L24.7689 20.5665C24.9765 20.6822 25.1494 20.8511 25.2698 21.056C25.3902 21.2609 25.4536 21.4943 25.4536 21.7319V25.1146C25.4536 26.8366 23.8536 28.0812 22.2216 27.5299C18.8696 26.3992 13.6669 24.2452 10.3682 20.9479C7.07089 17.6499 4.91756 12.4465 3.78622 9.09455C3.23556 7.46255 4.47956 5.86255 6.20223 5.86255H9.58423V5.86255Z"
        stroke={fill}
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default PhoneIconSVG;
