import styled from "styled-components";
import { device } from "../../screens";

const HeaderTemplate = styled.div`
  margin: 0;
  font-size: 24px;
  line-height: 31px;

  font-feature-settings:
    "pnum" on,
    "lnum" on;
  letter-spacing: -0.03em;
  color: #474747;

  ${({ family }) => (family ? `font-family: ${family} !important` : "")};

  ${({ contacts }) => (contacts ? "font-weight: 500" : "")};

  @media ${device.xs} {
    font-size: 32px;
    line-height: 42px;
  }

  @media (min-width: 1${({ width }) => width + 1}px) {
    font-size: 36px;
    line-height: 47px;
  }
`;

HeaderTemplate.defaultProps = {
  family: null,
};

export default HeaderTemplate;
