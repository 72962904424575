const NotificationItemMore = ({ children, className = "", ...props }) => {
  return (
    <li
      className={`flex items-center justify-center px-5 py-3 text-sm border-b border-lightGray cursor-pointer ${className}`}
      {...props}
    >
      {children}
    </li>
  );
};

export default NotificationItemMore;
