const PlusIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="9"
      className={className}
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.84466 0V3.86651H0V5.1335H3.84466V9H5.15534V5.1335H9V3.86651H5.15534V0H3.84466Z"
        fill="black"
      />
    </svg>
  );
};

export default PlusIconSVG;
