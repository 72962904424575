import React from "react";

const Card = ({
  children,
  padding = "px-8 py-5", // default
  background = "bg-white", // default background
  className = "",
  raduis = "rounded-md",
  height = "h-full",
}) => {
  return (
    <div
      className={`${background} ${height} ${raduis} ${padding} ${className}`}
    >
      {children}
    </div>
  );
};

export default Card;
