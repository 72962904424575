import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
  useContext,
} from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import toast from "react-hot-toast";
import Popup from "reactjs-popup";
import { Bell } from "assets/svg/sidebar/icons";
// import profileImage from "assets/images/profile.png";
import mobileLogo from "assets/images/logo-colored-secondary.png";
import { Context } from "context/context";
import { usePusherEvent } from "context/pusher";
import { useNotifications } from "redux/modules/notification/hooks";
import NotificationItem from "components/global/notification/notification-item/notification-item";
import { useHistory } from "react-router-dom";
import parseUrl from "parse-url";
import NotificationList from "components/global/notification/notification-list";
import NotificationItemMore from "./notification/notification-item-more";
import NotificationItemDefault from "./notification/notification-item/notification-item-default";
import Skeleton from "react-loading-skeleton";
import { useDialogHook } from "utils/customhooks";
import ConfirmModal from "components/confirm/confirm";
import Avatar from "react-avatar";
import NotificationItemSystem from "./notification/notification-item/notification-item-system";

export default function Header({
  title,
  userData,
  setUserData,
  currentPlan,
  loading = false,
}) {
  const confirm = useDialogHook(ConfirmModal);
  const { http, localstorage } = global.services;
  const history = useHistory();
  const { setMobileSidebar, setCampaign, setSelectedTab } = useContext(Context);
  const {
    notifications,
    hasUnreadNotification,
    addNotification,
    setNotifications,
    addNotificationUserRead,
  } = useNotifications();

  const [showNotification, setShowNotification] = useState(false);
  const [open, setOpen] = useState(false);

  const [notificationMetadata, setNotificationMetadata] = useState({
    current_page: 0,
    last_page: 0,
  });

  const canLoadMore = useMemo(
    () => notificationMetadata.current_page < notificationMetadata.last_page,
    [notificationMetadata]
  );

  usePusherEvent(
    "private-client-notifications",
    "push-notification-created-event",
    (data) => addNotification(data)
  );

  usePusherEvent(
    `private-notifications-${userData?.user.id || 0}`,
    "access-granted-event",
    (data) => addNotification(data)
  );

  const fetchNotifications = useCallback(
    async (page) => {
      try {
        const { data: response, status } = await http.get("notifications", {
          page,
          for: "header",
        });
        if (status === 200) {
          const nextNotifications =
            page === 1
              ? [...response.data]
              : [...notifications, ...response.data];
          setNotifications(nextNotifications);
          setNotificationMetadata(response.meta);
        }
      } catch (ex) {
        console.error(ex);
        toast.error(ex.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );

  const handleNotificationOnRead = useCallback(
    async (id) => {
      try {
        const notification = notifications.find((notif) => notif.id === id);
        if (notification.isRead) return; // return immediately so we dont need to fire another request
        const { status } = await http.post("user-notifications", {
          notification_id: id,
          status: "read",
        });
        if (status === 200) {
          addNotificationUserRead(id);
        }
      } catch (ex) {
        console.error(ex);
        toast.error(ex.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );

  const handleLogout = () => {
    confirm(
      {
        title: "Logout",
        buttonText: "YES, LOGOUT",
        description: "Are you sure you want to logout?",
      },
      (result) => {
        if (result) {
          proceedLogout();
        }
      }
    );
  };

  const proceedLogout = async () => {
    try {
      const parts = parseUrl(window.location.href);
      const pathname = parts.pathname || "";
      const search = parts.search || "";
      const hash = parts.hash || "";

      await http.post("auth/logout");
      setMobileSidebar(false); // Close sidebar
      localstorage.remove("user_data"); // Remove user data on storage
      setUserData(null); // Remove user data on redux
      setCampaign({});
      setSelectedTab("basic");
      history.push(`${pathname + search + hash}`); // Redirect to current page
    } catch (error) {
      console.error(error);
    }
  };

  // outside clicks
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * set close if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  // end: outside clicks

  return (
    <header
      style={{ height: "89px" }}
      className="relative w-full bg-white flex justify-between items-center site-header"
    >
      <div className="header-left flex items-center gap-4">
        <button onClick={() => setMobileSidebar(true)} className={"lg:hidden"}>
          <GiHamburgerMenu className="text-2xl" />
        </button>
        <img
          src={mobileLogo}
          alt={"ConversionCow Logo"}
          className={"w-10 lg:hidden"}
        />
        <p className="text-2xl font-semibold">{title && title}</p>
      </div>
      <div className="header-right flex items-center justify-end gap-4">
        <Popup
          position="bottom right"
          open={showNotification}
          onClose={() => setShowNotification(false)}
          trigger={
            <button onClick={() => setShowNotification((open) => !open)}>
              <Bell show_ball={hasUnreadNotification} />
            </button>
          }
        >
          <NotificationList>
            {notifications.map((notification) => (
              <NotificationItem
                key={`notification-${notification.id}`}
                isRead={notification.isRead}
                onRead={() => handleNotificationOnRead(notification.id)}
              >
                {notification.category === "system" ? (
                  <NotificationItemSystem
                    onPreviewClick={() => setShowNotification(false)}
                    notification={notification}
                  />
                ) : (
                  <NotificationItemDefault notification={notification} />
                )}
              </NotificationItem>
            ))}
            {canLoadMore ? (
              <NotificationItemMore
                onClick={() =>
                  fetchNotifications(notificationMetadata.current_page + 1)
                }
              >
                Load more
              </NotificationItemMore>
            ) : (
              <NotificationItemMore>You caught up</NotificationItemMore>
            )}
          </NotificationList>
        </Popup>
        <div className="flex items-center" ref={wrapperRef}>
          <button
            className="profile-button cursor-pointer"
            onClick={() => setOpen((prev) => !prev)}
          >
            {/* <img
              src={profileImage}
              alt="two-column-layout-component-brand-logo"
              className="w-12 2md:w-16 profile-image"
            /> */}
            <Avatar
              alt="two-column-layout-component-brand-logo"
              name={userData?.user?.full_name}
              email={userData?.user?.email}
              // className="w-12 2md:w-16 profile-image"
              round={true}
              size="100%"
            />
          </button>
          <button
            className="pl-3 hidden md:flex cursor-pointer"
            onClick={() => setOpen((prev) => !prev)}
          >
            <div className="profile-name">
              <p className="profile-name-text text-left">
                {userData?.user.role === "guest"
                  ? "Guest"
                  : userData?.user.full_name ||
                    userData?.user.email?.split("@")[0]}
              </p>
              {/* <br /> */}
              {loading ? (
                <Skeleton width="100px" />
              ) : (
                <p className="text-left font-light text-darkGray">
                  {userData?.user?.role !== "admin"
                    ? currentPlan
                    : "Administrator"}
                </p>
              )}
            </div>
          </button>
          {/*  */}
          {open && (
            <div className="absolute right-0 z-50 flex flex-col bg-white border rounded-md shadow-2xl border-lightGray top-22">
              <li
                className="flex items-center px-4 py-2 pr-20 space-x-2 cursor-pointer hover:bg-lightGray"
                onClick={() => history.push("/dashboard/account/detail")}
              >
                <span>Account Settings</span>
              </li>
              <li
                className="flex items-center px-4 py-2 pr-20 space-x-2 cursor-pointer hover:bg-lightGray"
                onClick={handleLogout}
              >
                <span>Logout</span>
              </li>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
