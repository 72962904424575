import styled from "styled-components";
import ProofPointsCard from "./proof-points-card";
import HeaderTemplate from "../../commons/header-template";
import { sanitizeValueHTML } from "utils/constant";

const ProofPointsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;

  column-gap: 28px;
  row-gap: 36px;

  margin-top: 36px;

  @media (min-width: 1${({ width }) => width + 1}px) {
    column-gap: 32px;
    row-gap: 40px;
  }
`;
const ProofPointsItem = styled.div`
  width: 100%;
  ${({ hidden }) => (hidden ? `display: none;` : "")}
`;

const ProofPoints = ({ data }) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <HeaderTemplate
        family={data.font?.family}
        width={data.width}
        dangerouslySetInnerHTML={{ __html: sanitizeValueHTML(data.title) }}
      />
      <ProofPointsContainer width={data.width}>
        {data.icons.map((icon, iconIndex) => (
          <ProofPointsItem
            key={`proof-points-${iconIndex}`}
            hidden={!icon.visible}
          >
            <ProofPointsCard
              point={icon}
              color={data?.button_colour}
              width={data.width}
            />
          </ProofPointsItem>
        ))}
      </ProofPointsContainer>
    </div>
  );
};

export default ProofPoints;
