import { Link } from "react-router-dom";
import "./styles.css";

/**
 * buttonName = for button text inside button container
 * buttonWidth = custom width for button
 * buttonClass = customized class for button
 * buttonTextClass = custom classname for button text inside
 * onClick = function for clicking the button
 */

const Button = ({
  buttonName = "",
  buttonWidth = null,
  buttonClass = "defaultButton",
  linkClass = "defaultLink",
  buttonRadius = "9999px",
  buttonTextClass = "defaultButtonText",
  icon = null,
  iconAlign = "right",
  onClick = () => {},
  disabled = false,
  iconOnly = false,
  iconType = "svg",
  ariaLabel = "Campaign Button",
  type = "button",
  path = "/",
  buttonType = "primary",
}) => {
  const displayIcon =
    iconType === "svg" ? (
      icon
    ) : (
      <img className="w-full h-full" src={icon} alt="icon" />
    );

  const hoverEffects = () => {
    let hover = `hover:bg-primaryHover`;
    if (buttonType === "default") {
      hover = `hover:bg-defaultHover`;
    } else if (buttonType === "secondary") {
      hover = `hover:bg-secondaryHover`;
    } else if (buttonType === "transparent") {
      hover = `hover:bg-transparent`;
    } else if (buttonType === "error") {
      hover = `hover:bg-lightRedHover`;
    } else if (buttonType === "action") {
      hover = `hover:bg-lightDarkGray`;
    }
    return hover;
  };

  if (type === "button" || type === "submit") {
    return (
      <button
        aria-label={ariaLabel}
        className={
          buttonClass +
          ` duration-300 ${!disabled ? hoverEffects() : ""} cursor-pointer`
        }
        disabled={disabled}
        onClick={onClick}
        type={type}
        style={
          buttonWidth
            ? {
                width: buttonWidth,
                borderRadius: buttonRadius,
                opacity: disabled && 0.5,
                cursor: disabled && "not-allowed",
              }
            : {
                borderRadius: buttonRadius,
                opacity: disabled && 0.5,
                cursor: disabled && "not-allowed",
              }
        }
      >
        {!iconOnly && (
          <div className={buttonTextClass + ` uppercase`}>
            {iconAlign === "left" && displayIcon}
            {buttonName}
          </div>
        )}
        {iconAlign === "right" && displayIcon}
      </button>
    );
  } else {
    return (
      <Link
        className={linkClass}
        to={path}
        style={
          buttonWidth
            ? {
                width: buttonWidth,
                borderRadius: buttonRadius,
                opacity: disabled && 0.5,
                cursor: disabled && "default",
              }
            : {
                borderRadius: buttonRadius,
                opacity: disabled && 0.5,
                cursor: disabled && "default",
              }
        }
      >
        <p className={buttonTextClass}>{buttonName}</p>
      </Link>
    );
  }
};

export default Button;
