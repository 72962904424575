import Button from "components/button/button";
import { INITIAL_BILLING_PERIOD } from "configs/api";
import { GoCheck } from "react-icons/go";

export default function PricingTable({
  title = "Standard",
  subTitle = "",
  price = 99,
  type = INITIAL_BILLING_PERIOD,
  buttonName = "",
  list = [],
  custom = false,
  onClick = () => {},
  selected = false,
  active = true,
  currency = "",
  interval = "",
  accountLimit = 0,
  footerText,
  small = false,
}) {
  return (
    <div
      className={`bg-white pricing-card flex flex-col ${
        selected ? "border-2 border-secondary" : "pricing-card-border"
      } ${!active && "hidden"} ${small ? "p-6" : "p-8"}`}
    >
      <p className={small ? "text-lg" : "text-28px"}>{title}</p>

      {subTitle && (
        <p className={`${small ? "text-sm mt-2" : "mt-3.5"} flex-1`}>
          {subTitle}
        </p>
      )}

      <div className={`flex items-center flex-wrap ${small ? "mt-2" : "mt-4"}`}>
        {!custom && <p className="text-2xl">$</p>}
        <p className="pl-2 leading-none" style={{ fontSize: "48px" }}>
          {price}
        </p>
        {!custom && <span className="text-base pl-1 self-end">/{type}</span>}
      </div>

      {footerText && <p className={`mb-2`}>{footerText}</p>}

      <div className={`w-full`}>
        <ul
          className={
            small
              ? "w-full mt-2 space-y-1 text-sm"
              : "w-full mt-4 space-y-1 text-base"
          }
        >
          {list.map((value, index) => (
            <li className="flex items-center" key={index}>
              <GoCheck className="font-bold mr-2 text-darkGreen text-xl" />
              {value}
            </li>
          ))}
        </ul>
      </div>

      <div className={small ? "mt-3" : "mt-5"}>
        <Button
          buttonName={buttonName}
          buttonTextClass="text-sm font-bold text-white"
          buttonClass={`${
            selected ? "bg-secondary" : "bg-primary"
          } rounded-full py-3 relative`}
          buttonType={selected ? "secondary" : "primary"}
          onClick={() => onClick(price)}
          buttonWidth="100%"
        />
      </div>
    </div>
  );
}
