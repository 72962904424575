import React, { useContext } from "react";
import _ from "lodash";
import { Context } from "context/context";
import { InlineWidget } from "react-calendly";
import CalendyExampleSVG from "assets/svg/calendy-example";
import PowerByIconSVG from "assets/svg/powered-by-icon";

export default function CalendyEventPopout({ popup }) {
  const { view } = useContext(Context);
  const mobile = view === "mobile";
  const data =
    popup.contact_method_content && JSON.parse(popup.contact_method_content);

  return (
    <div
      className={
        mobile
          ? "overflow-y-auto mobile-scroll"
          : "contacts-details-container overflow-y-auto mobile-scroll"
      }
      style={{ height: mobile ? "619px" : "635px" }}
    >
      {!_.isEmpty(data?.link) ? (
        <InlineWidget
          iframeTitle="Calendly Scheduling Page"
          pageSettings={{
            backgroundColor: "ffffff",
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: "00a2ff",
            textColor: "4d5055",
            hideGdprBanner: true,
          }}
          styles={{
            height: "900px",
            transform: "scale(0.85)",
            transformOrigin: "center top",
          }}
          url={data?.link}
        />
      ) : (
        <div className={"w-full"}>
          <CalendyExampleSVG className="w-full" />
        </div>
      )}
      {popup?.show_logo && (
        <div className="absolute bottom-5 right-7">
          <PowerByIconSVG className="w-40" />
        </div>
      )}
    </div>
  );
}
