import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import userActions from "redux/modules/user/actions";
import PageLoader from "components/page-loader/page-loader";

const Guest = ({ user, setUserData }) => {
  const { http, localstorage } = global.services;
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
  });

  const createGuestAccount = async () => {
    try {
      const result = await http.post(`public/accounts/guest`);
      const data = result.data;
      localstorage.set("user_data", JSON.stringify(data));
      setUserData(data);
      return history.push("/campaigns/popup");
    } catch (e) {
      toast.error("It appears that something went wrong");
    } finally {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    if (user) return history.push("/campaigns/popup");

    createGuestAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-secondary w-full h-full relative">
      <PageLoader visible={state.loading} title="Getting things ready..." />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Guest);
