import json from "assets/json-files/basic-details.json";
import CampaignModalIconSVG from "assets/svg/campaign-modal-icon-svg";
import Button from "components/button/button";
import PopupComponent from "components/popup/popup";
import PopupContent from "components/popup/popup-content";
import { Context } from "context/context";
import { useContext, useRef } from "react";

export default function CampaignPopupBasicDetails() {
  const { view } = useContext(Context);
  const tutorialContent = json;
  const ref = useRef();
  const toggleTooltip = () => {
    ref.current.toggle();
  };

  return (
    <PopupComponent
      className="relative p-2 ml-10 animate-btn"
      closeOnDocumentClick={true}
      ref={ref}
      styles={{ marginTop: view === "mobile" ? 120 : 60 }}
    >
      {tutorialContent.map((content, index) => {
        return (
          <PopupContent
            width={500}
            key={index}
            footer={
              <Button
                buttonName={content.buttonText}
                buttonTextClass="text-white text-xs font-bold"
                buttonClass="bg-primary rounded-full py-2.5 px-10"
                onClick={toggleTooltip}
              />
            }
          >
            {/* content */}
            <div className="px-6 flex flex-row">
              <div>
                <CampaignModalIconSVG />
              </div>
              <div className="pt-3 pl-4">
                <p className="leading-6 mb-2 font-semibold">{content.title}</p>
                <p
                  className="text-sm"
                  dangerouslySetInnerHTML={{ __html: content.description }}
                />
              </div>
            </div>
          </PopupContent>
        );
      })}
    </PopupComponent>
  );
}
