import React from "react";

const HowItWorks = () => {
  return (
    <div>
      <p>HowItWorks</p>
    </div>
  );
};

export default HowItWorks;
