import CampaignDetailsEyeSVG from "assets/svg/campaign-details-eye-icon";
import CampaignDetailsHamburgerSVG from "assets/svg/campaign-details-hamburger";
import Toggle from "components/toggle/toggle";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaChevronDown } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import CampaignDetailsButton from "../button";
import PopoutData from "./popout-data";
import PopoutDetails from "./popout-details";
import PopoutDropdown from "./popout-dropdown";
import PreviewDropdown from "./preview-dropdown";
// import LoadingIndicator from "assets/svg/loading-indicator";

const renderRows = (
  data = [],
  onChangeStatus = {},
  setLoading = {},
  handlePreview = () => {},
  onChangeContent = () => {}
) => {
  return data.length ? (
    data.map((record, recordIndex) => {
      let condition = JSON.parse(record?.popup_condition || "{}");
      let bool = data.length === recordIndex + 1;
      return (
        <Draggable
          key={recordIndex}
          draggableId={recordIndex.toString()}
          index={recordIndex}
        >
          {(provided) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              key={`popup-table-${recordIndex}`}
              data-cc={`record-index-${recordIndex}`}
              className={`w-full bg-white border-b border-thBorder`}
            >
              <td
                className="w-1/12 h-full"
                align="center"
                data-cc="condition-number"
              >
                <p className="text-2xl font-bold">{recordIndex + 1}</p>
              </td>
              <td
                className="w-5/12 h-full py-5 pl-5 border-l border-thBorder"
                data-cc={"condition-details"}
              >
                <PopoutDetails
                  data={record}
                  title={condition?.title || "Untitled"}
                  abTest={record?.popup_b_id}
                  abOngoing={!record?.test_has_ended}
                  abTestDate={record?.abTestDate}
                  isActive={record.active}
                  statusDisplay="upper"
                >
                  <div className="w-full flex justify-between items-center flex-wrap">
                    <div className="flex items-center flex-wrap gap-2">
                      <CampaignDetailsButton
                        onClick={() =>
                          !record.popup_b_id &&
                          handlePreview(record.popup_a_id, record.campaign_id)
                        }
                        dropdown={
                          record.popup_b_id
                            ? PreviewDropdown(record, handlePreview)
                            : []
                        }
                      >
                        <CampaignDetailsEyeSVG className="mr-1" />
                        Preview
                        {record.popup_b_id && (
                          <FaChevronDown className="ml-1" />
                        )}
                      </CampaignDetailsButton>
                      {record?.popup_b_id && (
                        <CampaignDetailsButton
                          onClick={() => onChangeContent("ab-details", record)}
                        >
                          A/B Details
                        </CampaignDetailsButton>
                      )}
                      <CampaignDetailsButton
                        listPositionTop={`${bool ? "bottom" : "top"}-12`}
                        dropdown={PopoutDropdown(record, setLoading)}
                      >
                        Options
                        <FaChevronDown className="ml-1" />
                      </CampaignDetailsButton>
                    </div>
                  </div>
                </PopoutDetails>
              </td>
              <td className="h-full py-5 w-auto" data-cc={"priority-data"}>
                <PopoutData
                  data={record}
                  abTest={record?.popup_b_id}
                  abOngoing={!record?.test_has_ended}
                />
              </td>
              <td
                className="w-1/12 h-full border-l border-thBorder"
                align="center"
                data-cc="condition-status"
              >
                <Toggle
                  checked={record?.active}
                  onChange={() => onChangeStatus(record.id)}
                />
              </td>
              <td
                {...provided.dragHandleProps}
                className="w-1/12 h-full py-5 border-l border-thBorder bg-thBg"
                align="center"
                data-cc={"priority-sorter"}
              >
                <CampaignDetailsHamburgerSVG />
              </td>
            </tr>
          )}
        </Draggable>
      );
    })
  ) : (
    <tr className="flex items-center w-full absolute border-l border-b border-r bg-thBg border-thBorder">
      <td className="w-full py-5 text-lg text-center opacity-40">No Data</td>
    </tr>
  );
};

const PopoutTable = ({
  data = [],
  className,
  onHandleDropdownAction,
  onUpdateOrder,
  onChangeStatus,
  setLoading,
  tableLoading,
  onChangeContent,
  setCampaign,
  setPopoutOrder,
  ...props
}) => {
  const history = useHistory();
  data = data.filter((popup) => popup.order_in > 0);

  const handleDragEnd = (results) => {
    if (!results.destination) return;
    const tempPopout = [...data]; // set temporary data
    const [selectedRow] = tempPopout.splice(results.source.index, 1); // get dragged row

    tempPopout.splice(results.destination.index, 0, selectedRow); // move dragged row to its destination and move other data below dragged row
    data = tempPopout.map((obj, index) => ({ ...obj, order_in: index + 1 })); // set existing data to its updated order AND update "order_in" value depending on index

    setCampaign((prev) => ({
      ...prev,
      campaign_popups: [prev.campaign_popups[0], ...data],
    }));
    setPopoutOrder([...data]);
    //onUpdateOrder(data);
  };

  const handlePreview = (id, cid) => {
    history.push(`/campaigns/popup/preview/${id}/${cid}`);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <table {...props} className={`w-full`}>
        <thead className="w-full border-b bg-thBg border-thBorder h-14">
          <tr className="w-full">
            <th className="w-1/12 font-normal text-sm">Priority</th>
            <th className="w-5/12 pl-5 font-normal text-left text-sm">
              Condition Title
            </th>
            <th className="w-4/12 pl-19 font-normal text-left text-sm">
              Condition Data
            </th>
            <th className="w-1/12 font-normal text-sm">Status</th>
            <th className="w-1/12 font-normal text-sm">Priority</th>
          </tr>
        </thead>
        {/* {tableLoading ? (
          <tbody>
            <tr>
              <td colSpan="5" className="py-10">
                <div className="w-full flex justify-center">
                  <LoadingIndicator className="w-10 h-10 text-secondary" />
                </div>
              </td>
            </tr>
          </tbody>
        ) : (

        )} */}
        <Droppable droppableId="tbody">
          {(provided) => (
            <tbody
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="w-full relative"
              data-cc="special-conditions"
            >
              {renderRows(
                data,
                onChangeStatus,
                setLoading,
                handlePreview,
                onChangeContent
              )}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </table>
    </DragDropContext>
  );
};

export default PopoutTable;
