import ArrowDownIconSVG from "assets/svg/arrow-down-icon";
import ContactNumberIconSVG from "assets/svg/contact-number-icon";
import MinusIconSVG from "assets/svg/minus-icon";
import Button from "components/button/button";
import Input from "components/input/input";
import Toggle from "components/toggle/toggle";
import update from "immutability-helper";
import _ from "lodash";
import { useEffect, useState } from "react";
import ButtonClickIconSVG from "assets/svg/button-click-icon";
import LinkInputIconSVG from "assets/svg/link-input-icon";

export default function EnquiryFormDetailsTab({
  onHandleBlur,
  content,
  state,
  setState,
  setContent,
  handleInputChange,
  handleInputWithObjectChange,
  handleInputWithObjectChangeAddress,
}) {
  const [options, setOptions] = useState(content.enquiry_type_options || []);
  const [emails, setEmails] = useState(
    content.submit_button.email ? content.submit_button.email.split(",") : []
  );

  const handleAddMore = () => {
    let payload = _.clone(options);
    let counter = _.size(payload) + 1;
    payload.push(`Option ${counter}`);
    setOptions(payload);
    setContent((prev) => ({
      ...prev,
      enquiry_type_options: payload,
    }));

    setState((prev) => ({
      ...prev,
      validationErrors: { ...prev.validationErrors, show_enquiry_type: null },
    }));
  };

  const handleAddMoreEmails = () => {
    let payload = _.clone(emails);
    let counter = _.size(payload) + 1;
    if (counter > 5) {
      return setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          submit_button: { email: ["A maximum of 5 email addresses"] },
        },
      }));
    }
    payload.push(`your-email-${counter}@example.com`);
    setEmails(payload);
    setContent((prev) => ({
      ...prev,
      submit_button: {
        ...prev.submit_button,
        email: payload.join(","),
      },
    }));
    setState((prev) => ({
      ...prev,
      validationErrors: { ...prev.validationErrors, submit_button: null },
    }));
  };

  const handleRemove = (index) => {
    let payload = _.clone(options);
    let data = update(payload, { $splice: [[index, 1]] });
    setOptions(data);
    setContent((prev) => ({
      ...prev,
      enquiry_type_options: data,
    }));
  };

  const handleRemoveEmail = (index) => {
    let payload = _.clone(emails);
    let data = update(payload, { $splice: [[index, 1]] });
    setEmails(data);
    setContent((prev) => ({
      ...prev,
      submit_button: {
        ...prev.submit_button,
        email: data.join(","),
      },
    }));
    setState((prev) => ({
      ...prev,
      validationErrors: { ...prev.validationErrors, submit_button: null },
    }));
  };

  const handleChangeValue = (value, index) => {
    let payload = _.clone(options);
    let data = update(payload, {
      [index]: { $set: value },
    });
    setOptions(data);
  };

  const handleChangeValueEmail = (value, index) => {
    let payload = _.clone(emails);
    let data = update(payload, {
      [index]: { $set: value },
    });
    setEmails(data);
    setState((prev) => ({
      ...prev,
      validationErrors: { submit_button: null },
    }));
  };

  const handleOnBlur = () => {
    setContent((prev) => ({
      ...prev,
      enquiry_type_options: options,
    }));
  };

  const handleOnBlurEmail = () => {
    setContent((prev) => ({
      ...prev,
      submit_button: {
        ...prev.submit_button,
        email: emails.join(","),
      },
    }));
  };

  useEffect(() => {
    onHandleBlur(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content.show_name,
    content.enquiry_type_options,
    content.enquiry_type_options?.length,
    content.show_enquiry_type,
    content.show_message,
    content.text?.visible,
    content.show_email_address,
    content.show_phone,
    content.email_address?.visible,
    content.contact_number?.visible,
    content.address.visible,
    content.submit_button?.visible,
    content.submit_button?.email,
    content.show_ticks,
  ]);

  return (
    <div>
      <p className="text-sm pl-1 pt-2 pb-2">Edit Input Fields</p>
      <div className={"mt-2"}>
        <Input
          inputContainerClass="input-container"
          visible={content.show_name}
          value={content.name_input_label ?? "First Name"}
          name="name_input_label"
          onChange={handleInputChange}
          onBlur={onHandleBlur}
          placeholder="First Name"
          masked={true}
          onChangeVisible={() => {
            setContent((prev) => ({
              ...prev,
              show_name: !content.show_name,
            }));
          }}
        />
        <Input
          inputContainerClass="input-container"
          visible={content.show_email_address}
          value={content.email_input_label ?? "Email Address"}
          name="email_input_label"
          onChange={handleInputChange}
          onBlur={onHandleBlur}
          placeholder="Email Address"
          masked={true}
          onChangeVisible={() => {
            setContent((prev) => ({
              ...prev,
              show_email_address: !content.show_email_address,
            }));
          }}
        />
        <Input
          inputContainerClass="input-container"
          visible={content.show_phone}
          value={content.phone_input_label ?? "Phone"}
          name="phone_input_label"
          onChange={handleInputChange}
          onBlur={onHandleBlur}
          placeholder="Phone"
          masked={true}
          onChangeVisible={() => {
            setContent((prev) => ({
              ...prev,
              show_phone: !content.show_phone,
            }));
          }}
        />
        <Input
          inputContainerClass="input-container"
          visible={content.show_enquiry_type}
          name="enquiry_input_label"
          value={content.enquiry_input_label ?? "Enquiry Type"}
          placeholder="Enquiry Type"
          onChange={handleInputChange}
          onBlur={onHandleBlur}
          extra={{
            show: _.size(options) > 0,
            label: `${_.size(options)} Dropdown Options`,
          }}
          inputIcon={<ArrowDownIconSVG />}
          iconPosition="left"
          masked={true}
          onChangeVisible={() => {
            setContent((prev) => ({
              ...prev,
              show_enquiry_type: !content.show_enquiry_type,
            }));
          }}
          errorMessage={state.validationErrors?.show_enquiry_type?.value[0]}
        />
        {/* start: content enquiry type */}
        <div className="ml-1 mr-4 relative">
          <div className="pl-8">
            {options.map((value, index) => (
              <div key={index} className="flex space-x-3">
                <Input
                  inputContainerClass="input-container-1"
                  placeholder={`e.g. Option ${index + 1}`}
                  value={value}
                  labelClass="mb-5"
                  name={value}
                  type="text"
                  showCounter={true}
                  maxLength={60}
                  onChange={(e) => handleChangeValue(e.target?.value, index)}
                  onBlur={handleOnBlur}
                />
                <div className="my-auto relative bottom-1">
                  <button
                    type="button"
                    onClick={() => handleRemove(index)}
                    className="btn-remove rounded-full"
                  >
                    <MinusIconSVG className="m-auto" />
                  </button>
                </div>
              </div>
            ))}
            <Button
              buttonName="+ ADD SELECTION"
              buttonClass="bg-transparent"
              buttonType="transparent"
              buttonTextClass="text-sm font-bold text-left pb-5"
              buttonWidth="30%"
              onClick={handleAddMore}
            />
          </div>
        </div>
        {/* end: content enquiry type */}
        <Input
          inputContainerClass="input-container"
          visible={content.show_message}
          value={content.message_input_label ?? "Message"}
          name="message_input_label"
          placeholder="Message"
          onChange={handleInputChange}
          onBlur={onHandleBlur}
          masked={true}
          onChangeVisible={() => {
            setContent((prev) => ({
              ...prev,
              show_message: !content.show_message,
            }));
          }}
        />
        {/* Tick Points */}
        <Toggle
          name="show_ticks"
          background="bgdefault"
          checked={!content.show_ticks}
          onChange={(e) => {
            setContent((prev) => ({
              ...prev,
              show_ticks: e?.target?.checked,
            }));
          }}
          icon="ticks"
          className="w-60 h-10 my-5"
          custom="popup"
          labelLeft="Tick Points"
          labelRight="Just Text"
        />
        <Input
          inputContainerClass="input-container"
          labelClass="input-label-xs"
          paddingTop="1.5rem"
          placeholder="e.g. More Text for Disclaimer / Message"
          value={content.text.value}
          visible={content.text.visible}
          masked={true}
          maxLength={60}
          showCounter={true}
          label="Disclaimer Message"
          name="text"
          type="text"
          inputIcon={<ContactNumberIconSVG className="w-5 h-5 text-gray" />}
          iconPosition="left"
          onChange={(e) => handleInputWithObjectChange(e, "value")}
          onBlur={onHandleBlur}
          onChangeVisible={() => {
            handleInputWithObjectChange("text", "visible");
          }}
        />
        <div className="px-1 pt-3">
          <Input
            inputContainerClass="input-container"
            labelClass="input-label-xs"
            paddingTop="1.5rem"
            placeholder="e.g. Submit"
            value={content.submit_button?.text}
            maxLength={60}
            showCounter={true}
            label="Button Name"
            name="text"
            type="text"
            inputIcon={<ButtonClickIconSVG className="w-5 h-5 text-gray" />}
            iconPosition="left"
            onChange={(e) => {
              setContent((prev) => {
                return {
                  ...prev,
                  submit_button: {
                    ...prev.submit_button,
                    text: e.target.value,
                  },
                };
              });
              setState({
                ...state,
                validationErrors: { submit_button: null },
              });
            }}
            onBlur={onHandleBlur}
            onChangeVisible={() => {
              handleInputWithObjectChange("text", "visible");
            }}
            errorMessage={
              state.validationErrors?.submit_button?.text &&
              state.validationErrors?.submit_button?.text[0]
            }
          />
          <Input
            inputContainerClass="input-container"
            visible={true}
            value="Recipient Email Addresses"
            extra={{
              show: _.size(emails) > 0,
              label: ` ${_.size(emails)} Email Address`,
            }}
            inputIcon={<LinkInputIconSVG />}
            iconPosition="left"
            disabledInput
            errorMessage={
              state.validationErrors?.submit_button?.email &&
              state.validationErrors?.submit_button?.email[0]
            }
          />
          {/* start: content enquiry type */}
          <div className="ml-1 mr-4 relative">
            <div className="pl-8">
              {emails.map((value, index) => (
                <div key={index} className="flex space-x-3">
                  <Input
                    inputContainerClass="input-container-1"
                    placeholder={`e.g. Email ${index + 1}`}
                    value={value}
                    labelClass="mb-5"
                    name={value}
                    type="email"
                    maxLength={60}
                    onChange={(e) =>
                      handleChangeValueEmail(e.target?.value, index)
                    }
                    onBlur={handleOnBlurEmail}
                  />
                  <div className="my-auto relative bottom-1">
                    <button
                      type="button"
                      onClick={() => handleRemoveEmail(index)}
                      className="btn-remove rounded-full"
                    >
                      <MinusIconSVG className="m-auto" />
                    </button>
                  </div>
                </div>
              ))}
              <Button
                buttonName="+ ADD EMAIL ADDRESS"
                buttonClass="bg-transparent"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold text-left pb-5"
                buttonWidth="50%"
                onClick={handleAddMoreEmails}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
