import React from "react";

const ButtonCornerStyles = ({
  selected = false,
  Icon = null,
  onClick = () => {},
}) => {
  return (
    <div className="relative">
      <button
        className={`btn-styles ${
          selected ? "border-2 border-secondary" : "border border-stlBtnBg"
        }`}
        onClick={onClick}
      >
        <Icon className="btn-image-styles" />
      </button>
    </div>
  );
};

export default ButtonCornerStyles;
