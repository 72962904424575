import json from "assets/json-files/template-content.json";
import CampaignPopoutIconSVG from "assets/svg/campaign-popout-icon";
import Button from "components/button/button";
import PopupComponent from "components/popup/popup";
import PopupContent from "components/popup/popup-content";
import { useRef } from "react";

export default function CampaignPopupTestimonial({
  type = null,
  margin = "80px 0px 0px 0px",
  className = "relative p-2 ml-2.5 box-shadow",
  actveColor = "bg-primary",
}) {
  const tutorialContent = json;
  const ref = useRef();
  const toggleTooltip = () => {
    ref.current.toggle();
  };

  return tutorialContent.map((content, index) => {
    return (
      content.type === type && (
        <PopupComponent
          key={index}
          activeColor={actveColor}
          position="right center"
          className={"animate-btn " + className}
          closeOnDocumentClick={true}
          ref={ref}
        >
          <PopupContent
            width="342px"
            margin={margin}
            styles={content.styles}
            remainArrow={true}
            divMargin="0px 0px 0px 10px"
            footer={
              <span className="w-full">
                <Button
                  buttonName="GOT IT"
                  buttonTextClass="text-white text-xs font-bold"
                  buttonClass="bg-primary rounded-full py-2.5 float-right"
                  buttonWidth="30%"
                  onClick={toggleTooltip}
                />
              </span>
            }
          >
            {/* content */}
            <div className="px-6 flex flex-row">
              <div>
                <CampaignPopoutIconSVG />
              </div>
              <div className="pt-3 pl-4">
                <p className="leading-6 mb-2 font-semibold">{content.title}</p>
                {content.list_items.length > 0 && (
                  <ul className="pl-5 list-disc text-sm">
                    {content.list_items.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            content.list_items.length !== index + 1 && "pb-2"
                          }`}
                        >
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            {/* content */}
          </PopupContent>
        </PopupComponent>
      )
    );
  });
}
