const CampaignDetailsSubmissionsSVG = (props) => (
  <svg
    {...props}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.720784 0.050887L16.0758 7.61689C16.1595 7.65815 16.23 7.72203 16.2793 7.80129C16.3286 7.88056 16.3548 7.97204 16.3548 8.06539C16.3548 8.15873 16.3286 8.25022 16.2793 8.32948C16.23 8.40874 16.1595 8.47262 16.0758 8.51389L0.719785 16.0799C0.633756 16.1221 0.537394 16.1388 0.442175 16.1278C0.346955 16.1169 0.256891 16.0788 0.182709 16.0181C0.108526 15.9574 0.0533524 15.8767 0.023758 15.7855C-0.00583645 15.6943 -0.00860405 15.5966 0.0157845 15.5039L1.53678 9.75889C1.56144 9.66592 1.61238 9.58205 1.68351 9.51731C1.75463 9.45257 1.84292 9.40972 1.93778 9.39389L8.81879 8.24689C8.85979 8.2401 8.89845 8.22319 8.93128 8.1977C8.9641 8.17221 8.99005 8.13893 9.00679 8.10089L9.02479 8.04089C9.03412 7.98528 9.02438 7.92814 8.99715 7.87876C8.96992 7.82939 8.9268 7.79066 8.87479 7.76889L8.81879 7.75289L1.89978 6.59989C1.8051 6.58388 1.71703 6.54095 1.6461 6.47622C1.57516 6.4115 1.52437 6.32771 1.49978 6.23489L0.0157845 0.627887C-0.00885774 0.535126 -0.0062816 0.437234 0.0232055 0.345898C0.0526927 0.254562 0.107845 0.173643 0.182076 0.112803C0.256307 0.051963 0.34648 0.0137732 0.441828 0.00279416C0.537175 -0.00818492 0.633668 0.00851068 0.719785 0.050887H0.720784Z"
      fill="black"
    />
  </svg>
);

export default CampaignDetailsSubmissionsSVG;
