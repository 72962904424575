import json from "assets/json-files/choose-contact-method.json";
import ContactDetailsAvatarSVG from "assets/svg/contact-details-avatar";
import Button from "components/button/button";
import PopupComponent from "components/popup/popup";
import PopupContent from "components/popup/popup-content";
import { useRef } from "react";

export default function CampaignPopupContact({
  margin = "0px 0px 0px 0px",
  className = "absolute p-2 box-shadow -left-14 top-4 z-10",
  actveColor = "bg-primary",
  divMargin = "180px 0px 0px 40px",
  type = "standard-details",
  mobile = false,
}) {
  const tutorialContent = json;
  const ref = useRef();
  // const modal = useDialogHook(TutorialVideoModal);
  const toggleTooltip = () => {
    ref.current.toggle();
  };

  // const showVideo = () => {
  //   modal({});
  //   toggleTooltip();
  // };

  return tutorialContent.map((content, index) => {
    return (
      // Should depend on its type
      content.type === type && (
        <PopupComponent
          contentClassName="builder-popout"
          key={index}
          activeColor={actveColor}
          position="left center"
          className={"animate-btn " + className}
          styles={mobile ? content.stylesMobile : content.styles}
          closeOnDocumentClick={true}
          ref={ref}
        >
          <PopupContent
            width="500px"
            margin={margin}
            remainArrow={true}
            divMargin={divMargin}
            footer={
              <span className="w-full">
                {/* <Button
                  buttonName="WATCH VIDEO TUTORIAL"
                  buttonTextClass="text-black text-xs font-bold font-bold flex px-3"
                  buttonClass="bg-transparent rounded-full py-2.5 border border-black mr-2"
                  buttonType="transparent"
                  buttonWidth="65%"
                  iconAlign="left"
                  icon={<FaPlay className="mr-0.5 mt-0.5" />}
                  onClick={showVideo}
                /> */}
                <Button
                  buttonName="GOT IT"
                  buttonClass="bg-primary rounded-full py-2.5 px-10 float-right"
                  buttonTextClass="text-white text-xs font-bold"
                  onClick={toggleTooltip}
                />
              </span>
            }
          >
            {/* content */}
            <div className="px-6 flex flex-row">
              <div>
                <ContactDetailsAvatarSVG />
              </div>
              <div className="pt-3 pl-4">
                <p className="text-15 font-semibold">{content.title}</p>
                <p className="text-13">{content.description}</p>
                {content.list_items.length > 0 && (
                  <ul className="pl-5 pt-2 list-disc text-13">
                    {content.list_items.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            content.list_items.length !== index + 1 && "pb-2"
                          }`}
                        >
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            {/* content */}
          </PopupContent>
        </PopupComponent>
      )
    );
  });
}
