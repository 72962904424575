import SelectIconSVG from "assets/svg/select-left-icon";
import React from "react";
import "./styles.css";

const InputComponent = (props) => {
  return (
    <input
      className="w-full bg-white box-border rounded text-base input-preview-final"
      {...props}
    />
  );
};

const InputPreview = ({
  preview = false,
  className = "",
  label,
  textArea = false,
  select = false,
  show = true,
  ...props
}) => {
  if (!preview) {
    return (
      <div className={`mt-1 ${!show && "hidden"}`}>
        <label className="text-xs text-tipsColor">{label}</label>
        <div className="mt-1 relative">
          <input
            className={`w-full ${
              textArea ? "input-preview-text-area" : "input-preview"
            }`}
            readOnly
          />
          {select && <SelectIconSVG className="absolute top-3 right-3" />}
        </div>
      </div>
    );
  } else {
    return (
      <div className={className}>
        {label && (
          <label className="text-sm font-bold" htmlFor={label}>
            {label}
          </label>
        )}
        <InputComponent {...props} />
      </div>
    );
  }
};

export default InputPreview;
