export default function PreviewDropdown(data, handlePreview) {
  const tests = ["Test A", "Test B"];
  const popupIDs = [data.popup_a_id, data.popup_b_id];

  const combined = popupIDs.map((id, index) => {
    return {
      text: tests[index],
      action: () => handlePreview(id, data.campaign_id),
    };
  });

  return combined;
}
