import React from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "components/global/tabselector";
import ABPopouts from "./tabs/ab-popouts/ab-popouts";
import ABSetting from "./tabs/ab-setting/ab-setting";
import ABResults from "./tabs/ab-results/ab-results";
import CampaignPopoutDeleteSVG from "assets/svg/campaign/popout/delete-icon";
import CampaignPopoutEditSVG from "assets/svg/campaign/popout/edit-icon";
import "assets/css/components/campaign-tests.css";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

export const ABTestContent = ({
  data,
  popups,
  onChangeStatus,
  setAlternative,
  details,
  handleConfirmDelete,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useTabs([
    "popouts",
    "setting",
    "results",
  ]);
  const toggle = (tab) => setSelectedTab(tab);

  const tabs = [
    {
      tab: "popouts",
      name: "Popouts",
      component: <ABPopouts data={data} onChangeStatus={onChangeStatus} />,
    },
    {
      tab: "setting",
      name: "A/B Setting",
      component: <ABSetting data={data} />,
    },
    {
      tab: "results",
      name: "A/B Results",
      component: <ABResults data={data} />,
    },
  ];

  const handleEdit = (popup) => {
    //set
    setAlternative({
      campaign_id: id,
      popups: details?.popups || [],
      condition: popup.condition,
      popup,
    });
    //push
    history.push(`/campaign-details/${id}/manage/special-condition`);
  };

  return (
    <div className="content-wrapper">
      <div className="content-container">
        {/* content header */}
        <div className="content-header">
          {/* title */}
          <div className="title-container">
            <h1 className="title">
              {data?.condition?.title || `A/B Test ${data.id}`}
            </h1>
          </div>
          {/* tabs */}
          <nav className="flex">
            <div className="relative">
              {tabs.map((item, index) => {
                return (
                  <TabSelector
                    paddingY="py-6"
                    key={index}
                    isActive={selectedTab === item.tab}
                    onClick={() => toggle(item.tab)}
                  >
                    <p className="text-base">{item.name}</p>
                  </TabSelector>
                );
              })}
            </div>
          </nav>
          {/* actions */}
          <div className="tabs-container">
            <button className="tab-icon" onClick={() => handleEdit(data)}>
              <CampaignPopoutEditSVG />
            </button>
            <button
              className="tab-icon"
              onClick={() => handleConfirmDelete(data)}
            >
              <CampaignPopoutDeleteSVG />
            </button>
          </div>
        </div>
        {/* content body */}
        <div className="content-body">
          {tabs.map((item, index) => {
            return (
              <TabPanel
                key={index}
                hidden={selectedTab !== item.tab}
                className="w-full"
              >
                {item.component}
              </TabPanel>
            );
          })}
        </div>
      </div>
    </div>
  );
};
