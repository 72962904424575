import LoadingIndicator from "assets/svg/loading-indicator";
import PencilIconSVG from "assets/svg/pencil-icon";
import PersonIconSVG from "assets/svg/person-icon";
import React, { useState } from "react";
import ImageCropModal from "../image-crop-modal";
import { useDialogHook } from "utils/customhooks";
import toast from "react-hot-toast";

const TestimonialUpload = (
  { id = "testimonial-upload", onClick, image, onUpload = () => {}, ...props },
  ref
) => {
  const modal = useDialogHook(ImageCropModal);
  const { http } = global.services;
  const [loading, setLoading] = useState(false);

  const onCropAvatar = async (e) => {
    const file = e.target.files[0];
    if (file && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const result = reader.result?.toString() || "";
        modal(
          { src: result, name: file.name, locked: true, freeMode: false },
          (cropImg) => {
            if (cropImg) {
              onUploadCropImage(cropImg, e);
            }
          }
        );
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onUploadCropImage = async (cropFile, e) => {
    setLoading(true);
    const formData = new FormData();
    const file = cropFile;
    try {
      /* Remove old image when attempting to re-upload new image */
      if (file.size > 2000000) {
        setLoading(false);
        return toast.error("Oops! The file is too large. Max file size: 2mb");
      } else {
        if (image.image_url && image.image_key) {
          await http.delete(`medias/${image.image_id}`, {
            key: image.image_key,
          });
        }
      }

      formData.append("file", file);
      formData.append("type", "templates");
      const uploadResult = await http.post("medias", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const result = uploadResult.data.data;
      if (result) {
        onUpload({
          image_url: result.presigned_url,
          image_key: result.key,
          image_id: result.id,
        });
      }
      //remove colors
    } catch (error) {
      toast.error(
        "An unknown error occured. Please refresh the page and try again."
      );
    } finally {
      setLoading(false);
      e.target.value = "";
    }
  };

  return (
    <div className={`relative h-20 w-20`}>
      <div className={"testimonial-upload cursor-pointer"}>
        <label htmlFor={id} className={"m-auto cursor-pointer"}>
          <PencilIconSVG className="text-black" />
        </label>
        <input
          ref={ref}
          type="file"
          id={id}
          name={id}
          {...props}
          onChange={onCropAvatar}
        />
      </div>
      <button
        type="button"
        className="bg-siteBg h-20 w-20 rounded-full mt-3 cursor-default"
        style={{
          backgroundImage: `url(${image.image_url})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {loading && (
          <LoadingIndicator className="m-auto text-secondary w-10 h-10" />
        )}
        {!image.image_url && !loading && <PersonIconSVG className="m-auto" />}
      </button>
    </div>
  );
};
const TestimonialUploadSelector = React.forwardRef(TestimonialUpload);

export default TestimonialUploadSelector;
