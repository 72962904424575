const EyeIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9434 8.22148C16.2989 8.68648 16.2989 9.31423 15.9434 9.77848C14.8236 11.2402 12.1371 14.25 9.00062 14.25C5.86412 14.25 3.17762 11.2402 2.05787 9.77848C1.88491 9.55583 1.79102 9.28192 1.79102 8.99998C1.79102 8.71804 1.88491 8.44413 2.05787 8.22148C3.17762 6.75973 5.86412 3.74998 9.00062 3.74998C12.1371 3.74998 14.8236 6.75973 15.9434 8.22148V8.22148Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
        fill="black"
      />
    </svg>
  );
};

export default EyeIconSVG;
