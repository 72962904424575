import Button from "components/button/button";
import WYSIWYGEditorDraft, {
  converToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import toast from "react-hot-toast";
import ReactPlayer from "react-player";
import {
  CONTACTOBJ,
  MAX_TITLE_LENGTH,
  NEWSLETTERSUGGESTIONS,
  sanitizeValueHTML,
} from "utils/constant";
import { DisplayErrorMessage } from "utils/utils";
import validator from "validator";
import SuggestionButton from "../add-content/component/suggestions-btn";
import LeadMagnetDetailsTab from "./component/lead-magnet-details-tab";
import Input from "components/input/input";
import EmailIconSVG from "assets/svg/email-icon";
import ContactNumberIconSVG from "assets/svg/contact-number-icon";
import MarkerIconSVG from "../../../../assets/svg/marker-icon";
import InputMapField from "../../../input/input-map";

export default function LeadMagnetDetails({
  handleChangeStep,
  state,
  setState,
  handleOnSave,
}) {
  const {
    setSelectedTab,
    popup,
    disabledStylePopout,
    setDisabledStylePopout,
    setPopup,
  } = useContext(Context);
  const { http } = global.services;
  const initials = useMemo(() => {
    if (_.isEmpty(popup?.contact_method_content)) {
      return _.find(CONTACTOBJ, { type: "lead-magnet" });
    } else {
      return JSON.parse(popup?.contact_method_content);
    }
  }, [popup.contact_method_content]);
  // start wysiwyg
  const titleInitials = useMemo(() => {
    let data = {};
    if (_.isEmpty(popup?.contact_method_content)) {
      data = _.find(CONTACTOBJ, { type: "lead-magnet" });
    } else {
      data = JSON.parse(popup?.contact_method_content);
    }
    return converToEditorState(data.title || "");
  }, [popup.contact_method_content]);
  const [editorState, setEditorState] = useState(titleInitials);
  // end wysiwyg
  const [content, setContent] = useState(initials);
  const [suggestion, setSuggestion] = useState(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContent((prev) => ({
      ...prev,
      [name]: value,
    }));
    setState((prev) => ({
      ...prev,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleInputWithObjectChange = (e, key) => {
    if (e.target) {
      const { name, value } = e.target;
      content[name][key] = value;

      if (state.validationErrors && state.validationErrors[name])
        state.validationErrors[name][key][0] = null;
    }

    if (!e.target) {
      content[e][key] = !content[e].visible;
    }

    if (!e.target && e === "address" && key === "visible") {
      let payload = content[e];
      let obj = update(payload, {
        pin: { $set: "" },
        latitude: { $set: 0 },
        longitude: { $set: 0 },
      });
      content[e] = obj;
    }

    setContent({ ...content });
    setState({ ...state });
  };

  const handleInputWithObjectChangeAddress = async (value, key) => {
    const geocodePlaceID = await geocodeByPlaceId(value.value.place_id);
    const payload = _.clone(content);
    let obj = update(payload, {
      address: {
        [key]: { $set: value.label },
        latitude: { $set: geocodePlaceID[0].geometry.location.lat() },
        longitude: { $set: geocodePlaceID[0].geometry.location.lng() },
      },
    });

    if (state.validationErrors?.address)
      state.validationErrors.address[key][0] = null;

    setContent(obj);
    setState({ ...state });
    setPopup((prev) => ({
      ...prev,
      contact_method_content: JSON.stringify(content),
    }));
  };

  const onUploadImage = async (e) => {
    setState((prevState) => ({ ...prevState, uploading: true }));
    const formData = new FormData();
    const file = e.target.files[0];

    if (file.size > 300000) {
      setState((prevState) => ({ ...prevState, uploading: false }));
      return toast.error("Oops! The file is too large. Max file size: 300KB");
    } else {
      /* Remove old image when attempting to re-upload new image */
      if (content.image_url && content.image_key && content.image_id) {
        await http.delete(`medias/${content.image_id}`, {
          key: content.image_key,
        });
        setState((prev) => ({ ...prev, media_id: null }));
      }
    }

    try {
      formData.append("file", file);
      formData.append("type", "lead-magnets");
      const uploadResult = await http.post("medias", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const image = uploadResult.data.data;
      setState((prev) => ({ ...prev, media_id: image.id }));
      setContent((prev) => ({
        ...prev,
        video_link: null,
        image_id: image.id,
        image_url: image.presigned_url,
        image_key: image.key,
        image_width: image.width,
        image_height: image.height,
      }));
    } catch (error) {
      toast.error(
        "An unknown error occured. Please refresh the page and try again."
      );
    } finally {
      e.target.value = "";
    }
  };

  const handleRemoveImage = async () => {
    setState((prevState) => ({ ...prevState, uploading: true }));
    const payload = _.clone(content);
    try {
      if (content.image_url && content.image_key && content.image_id) {
        const removeImageResult = await http.delete(
          `medias/${content.image_id}`,
          {
            key: content.image_key,
          }
        );
        if (removeImageResult.data?.success) {
          setState((prev) => ({ ...prev, media_id: null }));
          payload.image_id = null;
          payload.image_url = null;
          payload.image_key = null;
          payload.image_width = null;
          payload.image_height = null;
          setContent(payload);
          handleOnSave(payload);
          toast.success("Image removed successfully");
        }
      }
    } catch (error) {
      if (error.status === 422) {
        toast.error("It appears that something went wrong");
      } else if (error.status === 404) {
        toast.error("It appears that something went wrong");
      }
    } finally {
      setState((prevState) => ({ ...prevState, uploading: false }));
    }
  };

  // ======================== validation start ======================================
  const validateEmail = (email, key, value) => {
    if (validator.isEmail(email)) {
      return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          [key]: {
            [value]: ["The Email Address must be a valid email address."],
          },
        },
      }));
      return "The Email Address must be a valid email address.";
    }
  };

  const validateButton = (obj, key) => {
    if (!obj.text || !obj.email) {
      let err = !obj.text
        ? "A Button Name is required."
        : "The Email Address is required.";
      const value = !obj.text ? "text" : "email";
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          [key]: {
            [value]: [err],
          },
        },
      }));

      return err;
    }
    for (const email of obj.email) {
      if (validator.isEmail(email)) {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            [key]: {
              email: [
                "The email addresses must be valid and limited to a maximum of 5.",
              ],
            },
          },
        }));
        return "The email addresses must be valid and limited to a maximum of 5.";
      }
    }

    return "";
  };

  const validateContactNumber = (number) => {
    if (_.isEmpty(_.trim(number))) {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          contact_number: {
            value: ["The Contact Number format is invalid."],
          },
        },
      }));
      return "The Contact Number format is invalid.";
    } else {
      if (number.match(/^[0-9 ()+]+$/)) {
        if (_.size(number) >= 3) {
          return "";
        } else {
          setState((prev) => ({
            ...prev,
            validationErrors: {
              ...prev.validationErrors,
              contact_number: {
                value: ["The Contact Number must be at least 3 characters"],
              },
            },
          }));
          return "The Contact Number must be at least 3 characters";
        }
      } else {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            contact_number: {
              value: ["The Contact Number format is invalid."],
            },
          },
        }));
        return "The Contact Number format is invalid.";
      }
    }
  };

  const validateAddress = (address) => {
    if (_.size(address) >= 3) {
      return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          address: {
            value: ["The Address must be at least 3 characters."],
          },
        },
      }));
      return "The Address must be at least 3 characters.";
    }
  };

  const validateVideoLink = (link) => {
    let error = "";
    if (validator.isURL(link)) {
      if (ReactPlayer.canPlay(link)) {
        return "";
      } else {
        error = "Video Link is not a valid video link";
      }
    } else {
      error = "Video Link is not a valid link";
    }

    if (error) {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          video_link: {
            value: [error],
          },
        },
      }));
      return error;
    }
  };

  const validateAddressPin = (value) => {
    if (!_.isEmpty(value)) {
      return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          pin: {
            value: ["The Address pin location is required."],
          },
        },
      }));
      return "The Address pin location is required.";
    }
  };

  const validateBeforeSave = (content) => {
    let fields = [
      "contact_number",
      "email_address",
      "address",
      "submit_button",
      "video_link",
    ];
    let email_address = false;
    let contact_number = false;
    let address = false;
    let submit_button = false;
    let video_link = false;
    let err = [];
    setDisabledStylePopout(err);
    _.forEach(content, async (obj, key) => {
      if (fields.includes(key)) {
        if (key !== "submit_button") {
          if (content[key]?.value) {
            if (key === "email_address") {
              let resultValidate = validateEmail(
                obj.value,
                "email_address",
                "value"
              );
              if (_.isEmpty(resultValidate)) {
                email_address = true;
              } else {
                err.push(resultValidate);
              }
            }
            if (key === "contact_number") {
              let resultValidate = validateContactNumber(obj.value);
              if (_.isEmpty(resultValidate)) {
                contact_number = true;
              } else {
                err.push(resultValidate);
              }
            }
            if (key === "address") {
              let resultValidate = validateAddress(obj.value);
              if (_.isEmpty(resultValidate)) {
                address = true;
              } else {
                err.push(resultValidate);
              }
            }
          } else {
            //empty
            if (key === "email_address") {
              email_address = true;
            }
            if (key === "contact_number") {
              contact_number = true;
            }
            if (key === "address") {
              address = true;
            }
          }
          //video link
          if (key === "video_link") {
            if (content[key]) {
              let resultValidate = validateVideoLink(obj);
              if (_.isEmpty(resultValidate)) {
                video_link = true;
              } else {
                err.push(resultValidate);
              }
            } else {
              video_link = true;
            }
          }
        } else {
          if (content[key].email) {
            if (key === "submit_button") {
              let resultValidate = validateButton(
                obj,
                "submit_button",
                "email"
              );
              if (_.isEmpty(resultValidate)) {
                submit_button = true;
              } else {
                err.push(resultValidate);
              }
            }
          } else {
            //empty
            if (key === "submit_button") {
              submit_button = true;
            }
          }
        }
      }
    });

    if (
      email_address &&
      contact_number &&
      address &&
      submit_button &&
      video_link
    ) {
      let payload = _.clone(content);
      if (_.isEmpty(payload.video_link)) {
        handleOnSave(content);
      } else {
        payload.image_id = null;
        payload.image_url = null;
        payload.image_key = null;
        handleOnSave(payload);
        //remove
        setState((prev) => ({ ...prev, media_id: null }));
        setContent((prev) => ({
          ...prev,
          image_id: null,
          image_url: null,
          image_key: null,
        }));
      }
    } else {
      setDisabledStylePopout(err);
    }
  };
  // ======================== validation end ======================================

  //suggesstion
  const handleSuggestion = (type) => {
    const title = NEWSLETTERSUGGESTIONS;
    const size = _.size(NEWSLETTERSUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = title[0];
      } else {
        value = title[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = title[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = title[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = title[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      let payload = _.clone(content);
      let data = update(payload, {
        title: { $set: value },
      });
      //handle set
      setEditorState(converToEditorState(value));
      setContent(data);
      validateBeforeSave(data);
    }
  };

  //additional
  const handleChangeValue = (value, element) => {
    let payload = _.clone(content);
    let finalValue = value;
    let data = payload;
    if (element === "title") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
        data = update(payload, {
          [element]: { $set: finalValue },
          title_decoration: { $set: "normal" },
        });
      } else {
        data = update(payload, {
          [element]: { $set: sanitizeValueHTML(finalValue) },
          title_decoration: { $set: "normal" },
        });
      }
    } else {
      data = update(payload, {
        [element]: { $set: finalValue },
      });
    }
    setState((prev) => ({
      ...prev,
      validationErrors: { ...state.validationErrors, [element]: null },
    }));
    setContent(data);
    //set to popout
    if (element === "title") {
      setPopup((prev) => ({
        ...prev,
        contact_method_content: JSON.stringify(data),
      }));
    }
  };
  //end addtional

  const validateBeforeNext = () => {
    if (!_.isEmpty(disabledStylePopout)) {
      return toast.error(<DisplayErrorMessage errors={disabledStylePopout} />);
    } else {
      if (content?.address?.value) {
        if (!content?.address?.visible) {
          handleChangeStep(content);
        } else {
          let res = validateAddressPin(content?.address?.pin);
          if (_.isEmpty(res)) {
            handleChangeStep(content);
          } else {
            return toast.error(res);
          }
        }
      } else {
        handleChangeStep(content);
      }
    }
  };

  useEffect(() => {
    if (state.uploading) handleOnSave(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    validateBeforeSave(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content.show_name,
    content.enquiry_type_options,
    content.enquiry_type_options?.length,
    content.show_enquiry_type,
    content.show_message,
    content.text?.visible,
    content.show_email_address,
    content.show_phone,
    content.email_address?.visible,
    content.contact_number?.visible,
    content.address.visible,
    content.submit_button?.visible,
    content.submit_button?.email,
    content.show_ticks,
  ]);

  return (
    <>
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Complete Your Contact Details</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validateBeforeNext();
          }}
          className="w-full relative campaign-left-max mr-32"
          style={{ height: "fit-content" }}
        >
          <div className="bg-white rounded-md p-5">
            <p className="text-xl font-medium pl-1 pb-2">Heading Information</p>
            {/* middle */}
            <div>
              <p className="text-sm pl-1 pt-2 pb-2">Fill Heading Details:</p>
              {/* title */}
              <div className="mb-3">
                <WYSIWYGEditorDraft
                  placeholder={`e.g. Excepteur sint occaecat cupidatat non proident, sunt in cul...`}
                  onChange={(value) => {
                    handleChangeValue(value, "title");
                  }}
                  onBlur={() => validateBeforeSave(content)}
                  errorMessage={state.validationErrors?.title}
                  showCounter={true}
                  maxLength={MAX_TITLE_LENGTH}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
                <div className="w-full mt-2 flex items-center 5md:block 3lg:flex 3lg:items-center justify-between">
                  <SuggestionButton onClick={(e) => handleSuggestion(e)} />
                </div>
              </div>

              <Input
                inputContainerClass="input-container-2"
                labelClass="input-label-xs"
                paddingTop="1.5rem"
                placeholder="e.g. 1800 XXX XXX"
                value={content.contact_number.value}
                visible={content.contact_number.visible}
                masked={true}
                label="Contact Number"
                name="contact_number"
                type="text"
                inputIcon={
                  <ContactNumberIconSVG className="w-5 h-5 text-gray" />
                }
                iconPosition="left"
                onChange={(e) => handleInputWithObjectChange(e, "value")}
                onBlur={() => validateBeforeSave(content)}
                onChangeVisible={() =>
                  handleInputWithObjectChange("contact_number", "visible")
                }
                errorMessage={
                  state.validationErrors?.contact_number?.value
                    ? state.validationErrors?.contact_number?.value[0]
                    : null
                }
              />
              <Input
                inputContainerClass="input-container-2"
                labelClass="input-label-xs"
                paddingTop="1.5rem"
                placeholder="e.g. info@email.com"
                value={content.email_address.value}
                visible={content.email_address.visible}
                masked={true}
                label="Email Address"
                name="email_address"
                type="text"
                inputIcon={<EmailIconSVG className="w-5 h-5 text-gray" />}
                iconPosition="left"
                onChange={(e) => handleInputWithObjectChange(e, "value")}
                onBlur={() => validateBeforeSave(content)}
                onChangeVisible={() =>
                  handleInputWithObjectChange("email_address", "visible")
                }
                errorMessage={
                  state.validationErrors?.email_address?.value
                    ? state.validationErrors?.email_address?.value[0]
                    : null
                }
              />
            </div>

            <Input
              inputContainerClass="input-container-3"
              labelClass="input-label-xs"
              paddingTop="1.5rem"
              placeholder="e.g. 375 Thoroughfare, Lake Sabina, Queensland"
              value={content.address.value}
              visible={content.address.visible}
              masked={true}
              maxLength={90}
              showCounter={true}
              label="Address Label"
              name="address"
              type="text"
              inputIcon={<MarkerIconSVG className="w-5 h-5 text-gray" />}
              iconPosition="left"
              onChange={(e) => handleInputWithObjectChange(e, "value")}
              onBlur={() => validateBeforeSave(content)}
              onChangeVisible={() =>
                handleInputWithObjectChange("address", "visible")
              }
              errorMessage={
                state.validationErrors?.address?.value
                  ? state.validationErrors?.address?.value[0]
                  : null
              }
            />
            <div className="pl-1 pr-4 mb-3">
              {content.address?.visible && (
                <InputMapField
                  placeholder="Pin Location (Address on Google Maps)"
                  value={content.address?.pin}
                  label="Pin Location"
                  name="pin"
                  labelClass="input-label-xs"
                  paddingTop="1.5rem"
                  type="text"
                  inputIcon={<MarkerIconSVG className="w-5 h-5 text-gray" />}
                  iconPosition="left"
                  onChange={(e) => handleInputWithObjectChangeAddress(e, "pin")}
                  onBlur={() => validateBeforeSave(content)}
                  errorMessage={
                    state.validationErrors?.pin?.value
                      ? state.validationErrors?.pin?.value[0]
                      : null
                  }
                />
              )}
            </div>
          </div>

          <div className="bg-white rounded mt-2 p-5">
            <p className="text-xl font-medium pl-1 pb-2">Form Fields</p>
            {/* tabs */}
            <LeadMagnetDetailsTab
              content={content}
              state={state}
              setContent={setContent}
              setState={setState}
              handleInputChange={handleInputChange}
              handleInputWithObjectChange={handleInputWithObjectChange}
              handleInputWithObjectChangeAddress={
                handleInputWithObjectChangeAddress
              }
              onUploadImage={onUploadImage}
              handleRemoveImage={handleRemoveImage}
              onHandleBlur={() => validateBeforeSave(content)}
            />
            {/* footer */}
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("contactM")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
