const ImageUploadIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5827 4.8335V7.75016H23.3327V12.1252H20.4993V7.75016H16.2493V4.8335H20.4993V0.458496H23.3327V4.8335H27.5827ZM15.541 15.0418C16.1048 15.0416 16.6454 14.8109 17.0439 14.4004C17.4424 13.9899 17.6662 13.4332 17.666 12.8529C17.6658 12.2725 17.4417 11.716 17.0429 11.3058C16.6441 10.8955 16.1034 10.6652 15.5396 10.6654C15.2604 10.6655 14.984 10.7222 14.7262 10.8322C14.4683 10.9423 14.234 11.1035 14.0367 11.3068C13.8394 11.5101 13.6829 11.7513 13.5761 12.0169C13.4694 12.2824 13.4145 12.567 13.4146 12.8543C13.4147 13.1417 13.4698 13.4262 13.5767 13.6917C13.6836 13.9571 13.8402 14.1983 14.0377 14.4014C14.2352 14.6046 14.4695 14.7657 14.7275 14.8755C14.9854 14.9854 15.2619 15.0419 15.541 15.0418ZM20.4993 19.7581L19.7726 18.9268C19.5069 18.6223 19.1819 18.3787 18.8187 18.2119C18.4555 18.0452 18.0623 17.959 17.6646 17.959C17.2669 17.959 16.8737 18.0452 16.5105 18.2119C16.1473 18.3787 15.8223 18.6223 15.5566 18.9268L14.6287 19.9914L7.74935 12.1252L3.49935 16.9858V7.75016H13.416V4.8335H3.49935C2.7479 4.8335 2.02723 5.14079 1.49588 5.68777C0.964527 6.23475 0.666016 6.97661 0.666016 7.75016V25.2502C0.666016 26.0237 0.964527 26.7656 1.49588 27.3126C2.02723 27.8595 2.7479 28.1668 3.49935 28.1668H20.4993C21.2508 28.1668 21.9715 27.8595 22.5028 27.3126C23.0342 26.7656 23.3327 26.0237 23.3327 25.2502V15.0418H20.4993V19.7581Z"
        fill="#CFCFCF"
      />
    </svg>
  );
};

export default ImageUploadIconSVG;
