import MinusIconSVG from "assets/svg/minus-icon";
import PlusIconSVG from "assets/svg/plus-icon";
import React, { useEffect, useState } from "react";

const InputRangeComponent = (
  {
    name = "range",
    label = null,
    min = 0,
    max = 5,
    step = 1,
    defaultValue = 0,
    hideNumber = false,
    buttons = false,
    className = "input-range",
    onClickButton = () => {},
    ...props
  },
  ref
) => {
  const [state, setState] = useState({
    min: min,
    max: max,
    step: step,
    value: defaultValue,
  });
  const [value, setValue] = useState(0);

  useEffect(() => {
    let value = ((state.value - state.min) / (state.max - state.min)) * 100;
    setValue(value);
  }, [state]);

  useEffect(() => {
    setState((prev) => ({ ...prev, value: defaultValue }));
  }, [defaultValue]);

  return (
    <div className="flex flex-row">
      {label && (
        <label htmlFor={name} className="w-7/12">
          {label}
        </label>
      )}
      {buttons && (
        <button
          className="mr-6"
          type="button"
          onClick={() => {
            setState((prev) => ({
              ...prev,
              value: prev.value <= min ? min : Number(prev.value - step),
            }));
            onClickButton(
              state.value <= min ? min : Number(state.value - step)
            );
          }}
        >
          <MinusIconSVG className="text-2xl" />
        </button>
      )}
      <input
        id={name}
        name={name}
        className={className}
        style={{
          margin: "auto",
          background: `linear-gradient(to right, #EC5627 0%, #EC5627 ${value}%, #DFDFDF ${value}%, #DFDFDF 100%)`,
        }}
        onInput={(e) =>
          setState((prev) => ({ ...prev, value: Number(e?.target?.value) }))
        }
        {...state}
        type="range"
        {...props}
        ref={ref}
      />
      {!hideNumber && (
        <label htmlFor="label-value" className="w-2/12 text-right">
          {state.value}
        </label>
      )}
      {buttons && (
        <button
          className="ml-6"
          type="button"
          onClick={() => {
            setState((prev) => ({
              ...prev,
              value: prev.value >= max ? max : Number(prev.value + step),
            }));
            onClickButton(
              state.value >= max ? max : Number(state.value + step)
            );
          }}
        >
          <PlusIconSVG className="text-2xl" />
        </button>
      )}
    </div>
  );
};

const InputRange = React.forwardRef(InputRangeComponent);

export default InputRange;
