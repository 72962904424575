import styled from "styled-components";
import { motion } from "framer-motion";

const PopupIconMobile = styled(motion.div)`
  display: flex !important;
  border-radius: 100% !important;
  height: 33px !important;
  width: 33px !important;
  background-color: rgb(255, 255, 255) !important;
  ${({ type }) =>
    type === "above"
      ? "margin-bottom: 10px !important;"
      : "margin-top: 8px !important;"}

  background: white !important;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
`;

export default PopupIconMobile;
