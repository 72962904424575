import GoogleSVG from "assets/svg/google";
import React from "react";
import "./styles.css";

const GoogleAuthButton = ({ onClick = {}, title = "Log in with Google" }) => {
  return (
    <div className="google-login-container" onClick={onClick}>
      <div className="google-login-btn">
        <GoogleSVG className="google-login-svg" />
        <p className="google-login-btn-text">{title}</p>
      </div>
    </div>
  );
};

export default GoogleAuthButton;
