import React, { useEffect, useState } from "react";

const SliderComponent = (
  {
    name = "slider",
    min = 0,
    max = 100,
    step = 1,
    defaultValueA = 50,
    defaultValueB = 50,
    disableSlide = false,
    onChangeFinish = () => {},
  },
  ref
) => {
  const [state, setState] = useState({
    min: min,
    max: max,
    step: step,
    value: defaultValueA,
  });
  const [value, setValue] = useState(0);
  const [valueA, setValueA] = useState(defaultValueA);
  const [valueB, setValueB] = useState(defaultValueB);

  const handleOnInput = (e) => {
    let valueA = e?.target?.value;
    let valueB = max - valueA;
    setState((prev) => ({ ...prev, value: Number(valueA) }));
    setValueA(valueA);
    setValueB(valueB);
  };

  useEffect(() => {
    let value = ((state.value - state.min) / (state.max - state.min)) * 100;
    setValue(value);
  }, [state]);

  return (
    <div className="w-full relative flex">
      <div className="w-full flex text-xl font-bold text-center text-white absolute top-3.5 cursor-none overflow-hidden">
        <p
          className="overflow-hidden mr-10"
          style={{ width: `${valueA}%`, zIndex: 11 }}
        >
          {`A: `}
          {valueA > 10 && <span>{`${valueA}%`}</span>}
        </p>
        <p style={{ width: `${valueB}%`, zIndex: 11 }}>
          {`B: `}
          {valueB > 10 && <span>{`${valueB}%`}</span>}
        </p>
      </div>
      <input
        id={name}
        className="custom-slider relative"
        name={name}
        style={{
          margin: "auto",
          background: `linear-gradient(to right, #EC5627 0%, #EC5627 ${value}%, #28265B ${value}%, #28265B 100%)`,
          cursor: "ew-resize",
          zIndex: 10,
        }}
        onInput={handleOnInput}
        onChange={(e) => {
          let a = e?.target?.value;
          let b = max - a;
          onChangeFinish(a, b);
        }}
        {...state}
        disabled={disableSlide}
        type="range"
        ref={ref}
      />
    </div>
  );
};

const Slider = React.forwardRef(SliderComponent);

export default Slider;
