import styled from "styled-components";
import { device } from "../../../screens";
import { getIcon } from "assets/icons/icons-list";

const StatisticsCardContainer = styled.div`
  width: 100%;
`;
const StatisticsCardData = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 8px;

  @media ${device.xs} {
    margin-top: 12px;
  }
`;
const StatisticsCardDataText = styled.h1`
  margin: 0;
  font-weight: 700;
  margin-bottom: 4px;

  letter-spacing: -0.03em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 36px;
  line-height: 47px;

  @media ${device.xs} {
    font-size: 48px;
    line-height: 62px;
  }

  @media (min-width: 1${({ responisve }) => responisve + 1}px) {
    font-size: 56px;
    line-height: 73px;
  }
`;
const StatisticsCardDataSubtext = styled.p`
  margin: 0;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 14px;
  line-height: 22px;

  @media ${device.xs} {
    font-size: 16px;
    line-height: 26px;
  }

  @media (min-width: 1${({ responisve }) => responisve + 1}px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

const StatisticsCard = ({ width, statistics, color = null }) => {
  const Icon = getIcon(statistics.icon?.id);

  const IconContainer = Icon
    ? styled(Icon)`
        height: 60px !important;
        width: 60px !important;

        @media ${device.xs} {
          height: 70px !important;
          width: 70px !important;
        }

        @media (min-width: 1${({ responisve }) => responisve + 1}px) {
          height: 80px !important;
          width: 80px !important;
        }
      `
    : null;

  return (
    <StatisticsCardContainer>
      {Icon && IconContainer && (
        <IconContainer
          responisve={width}
          fill={color ? color : "#000000"}
          sx={{
            color: color ? color : "#000000",
          }}
        />
      )}
      <StatisticsCardData>
        <StatisticsCardDataText responisve={width}>
          {statistics.data}
        </StatisticsCardDataText>
        <StatisticsCardDataSubtext responisve={width}>
          {statistics.subtext}
        </StatisticsCardDataSubtext>
      </StatisticsCardData>
    </StatisticsCardContainer>
  );
};

export default StatisticsCard;
