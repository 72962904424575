import LoadingIndicator from "assets/svg/loading-indicator";
import SortIconSVG from "assets/svg/sort-icon";
import TooltipInfo from "components/tooltip/tooltip-info";
import _ from "lodash";
import Pagination from "react-responsive-pagination";

const thClass =
  "px-5 py-3  bg-thBg font-normal text-sm text-black text-opacity-70 ";
const tdClass = "px-5 pt-8 pb-12 border-b border-thBorder ";

const emptyFunc = () => {
  return null;
};

const LoadingComponent = () => {
  return (
    <div
      className="flex items-center justify-center"
      style={{ height: "65vh" }}
    >
      <LoadingIndicator className="w-10 h-10 text-secondary" />
    </div>
  );
};

export default function CampaignTable({
  columns = [],
  dataSource = [],
  noData,
  paginate = false,
  pagination = {},
  loading = false,
  onHandleChangeSorting = emptyFunc,
  sort = {},
}) {
  const handleSort = (enable, key, sort) => {
    if (!enable) {
      let finalSort = sort === "asc" ? "desc" : "asc";
      onHandleChangeSorting(key, finalSort);
    }
  };

  const getSortValueAsc = (col) => {
    if (sort?.value === col) {
      if (sort.sort === "asc") {
        return "#EC5627";
      } else {
        return null;
      }
    }
    return null;
  };

  const getSortValueDesc = (col) => {
    if (sort?.value === col) {
      if (sort.sort === "desc") {
        return "#EC5627";
      } else {
        return null;
      }
    }
    return null;
  };

  if (_.isEmpty(dataSource) && !loading) {
    return noData;
  } else {
    return (
      <div
        className="px-4 py-4 -mx-4 sm:-mx-8 sm:px-8"
        style={{ minWidth: "1024px" }}
      >
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className="inline-block min-w-full shadow">
            <table className="min-w-full leading-normal campaign-table">
              <thead>
                <tr className="border border-thBorder">
                  {(columns || []).map((value) => {
                    let {
                      title,
                      key,
                      width = "0px",
                      align = "left",
                      infoText = "",
                      showInfo,
                      hideSorting = true,
                    } = value;
                    return (
                      <th
                        key={key}
                        className={thClass + `text-${align}`}
                        width={width}
                      >
                        <div
                          className="flex flex-row items-center space-x-2 cursor-pointer"
                          onClick={() =>
                            handleSort(hideSorting, key, sort?.sort)
                          }
                        >
                          {showInfo ? (
                            <div className="w-full flex justify-center items-center">
                              <p className="mr-2">{title}</p>
                              <TooltipInfo text={infoText} />
                            </div>
                          ) : (
                            <p className="text-sm">{title}</p>
                          )}
                          {!hideSorting && (
                            <SortIconSVG
                              fill="gray"
                              fillAsc={getSortValueAsc(key)}
                              fillDesc={getSortValueDesc(key)}
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {dataSource.map((record, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border border-thBorder hover:bg-thBorder"
                    >
                      {(columns || []).map((column) => {
                        let {
                          key,
                          align = "center",
                          dataIndex,
                          render,
                        } = column;
                        if (render) {
                          return (
                            <td key={key} className={tdClass + `text-${align}`}>
                              {render(record)}
                            </td>
                          );
                        } else {
                          return (
                            <td key={key} className={tdClass + `text-${align}`}>
                              <p className="text-xl">{record[dataIndex]}</p>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {paginate && !_.isEmpty(dataSource) && (
          <div className="mt-4 w-full">
            <Pagination
              total={pagination.lastPage || 1}
              current={pagination.currentPage || 1}
              onPageChange={(page) => pagination.onChangePage(page)}
              previousLabel="Previous"
              nextLabel="Next"
            />
          </div>
        )}
      </div>
    );
  }
}
