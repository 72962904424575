import imageSide from "assets/images/mobile-side.png";
import image from "assets/images/mobile-view.png";
import LoadingIndicator from "assets/svg/loading-indicator";
import InputBrowser from "components/input/input-browser";
import _ from "lodash";
import { FaLock } from "react-icons/fa";
import EmptyBrowser from "../empty-browser";
import PopupExampleRoot from "../popup-example/popup-example-root";

const desktopClass = "pl-3 pr-20 py-2 border-b border-tabBorder";
const mobileClass = "px-2 pt-16 pb-3 border-b border-tabBorder";

export default function SnapshotComponent({
  loading,
  url = "",
  snapshot,
  mobile = false,
}) {
  return (
    <div
      className={`w-full h-full rounded-xl relative ${
        mobile
          ? "campaign-right-mobile-max pl-7 pr-6"
          : "campaign-right-max bg-white"
      }`}
      style={
        mobile
          ? {
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
            }
          : null
      }
    >
      <img
        src={imageSide}
        alt="mobile-side-effect"
        className={`absolute -right-0.5 top-6 z-30 ${mobile ? "" : "hidden"}`}
      />
      {/* header */}
      <div className={mobile ? mobileClass : desktopClass}>
        <div className="w-full relative">
          <InputBrowser
            mobile={mobile}
            icon={
              <FaLock
                style={{
                  color: "#5F6368",
                  position: "absolute",
                  left: 13,
                  top: 7,
                }}
              />
            }
            value={url}
          />
        </div>
      </div>
      {/* content */}
      <div className="grid relative" style={{ height: mobile ? "83%" : "93%" }}>
        <LoadBrowserContent
          loading={loading}
          snapshot={snapshot}
          mobile={mobile}
        />
        <PopupExampleRoot />
      </div>
    </div>
  );
}

const LoadBrowserContent = ({ loading, snapshot, mobile }) => {
  let div = <EmptyBrowser />;
  if (loading) {
    div = (
      <div
        className={`w-full h-full grid bg-size-full ${
          mobile ? "round-mobile-bottom" : "rounded-b-xl bg-no-repeat"
        }`}
        style={
          snapshot?.url
            ? {
                backgroundImage: `url(${snapshot.url})`,
              }
            : null
        }
      >
        <div
          className={`w-full h-full bg-black bg-opacity-60 absolute top-0 ${
            mobile
              ? "round-mobile-bottom"
              : "rounded-b-xl bg-contain bg-no-repeat"
          }`}
        />
        <div className={`mx-auto my-auto z-10 ${mobile ? "px-10" : ""}`}>
          <LoadingIndicator className="text-thBg w-8 h-8 mx-auto" />
          <p className="text-thBg text-sm text-center mt-5 w-80">
            Just a moment, we're grabbing a screenshot of your site in the
            background.
          </p>
        </div>
      </div>
    );
  } else if (!_.isEmpty(snapshot)) {
    div = (
      <div
        id="snapshot"
        className={`w-full bg-size-full ${
          mobile ? "round-mobile-bottom" : "rounded-b-xl bg-no-repeat"
        }`}
        style={{
          backgroundImage: `url(${snapshot?.url})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      />
    );
  }
  return div;
};
