const SortIconSVG = ({
  className = "",
  fill = "#AEAEAE",
  fillAsc = null,
  fillDesc = null,
}) => (
  <svg
    className={className}
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.63636 1L1 3.72727H2.63636H4.27273L2.63636 1Z"
      fill={fillDesc || fill}
    />
    <path
      d="M2.63636 3.72727H4.27273L2.63636 1L1 3.72727H2.63636ZM2.63636 3.72727V13"
      stroke={fillDesc || fill}
    />
    <path
      d="M7.54545 13L5.90909 10.2727H7.54545H9.18182L7.54545 13Z"
      fill={fillAsc || fill}
    />
    <path
      d="M7.54545 10.2727H9.18182L7.54545 13L5.90909 10.2727H7.54545ZM7.54545 10.2727V1"
      stroke={fillAsc || fill}
    />
  </svg>
);
export default SortIconSVG;
