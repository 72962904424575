const Button = ({ children, className, as = "button", ...props }) => {
  if (as === "button") {
    return (
      <button className={`p-3 rounded-full ${className}`} {...props}>
        {children}
      </button>
    );
  }

  return (
    <a className={`p-2 rounded-full ${className}`} {...props}>
      {children}
    </a>
  );
};

export default Button;
