import React, { useEffect, useRef, useState, useCallback } from "react";
import CampaignPopupPopOutIcons from "../popup/campaign-popup-popout-icons";

export default function ContentIconSelector({
  onClick,
  Icon,
  position = "right top",
}) {
  const ref = useRef();
  const buttonRef = useRef();
  const iconRef = useRef();
  const [open, setOpen] = useState(false);

  const toogleSelector = useCallback(() => {
    if (!open) {
      ref.current.open();
    } else {
      ref.current.close();
    }
    setOpen((prev) => !prev);
  }, [open]);

  const onClickIcon = (icon) => {
    onClick(icon);
    setOpen(false);
    ref.current.close();
  };

  const onCloseIcon = () => {
    setOpen(false);
    ref.current.close();
  };

  // outside clicks
  const useOutsideAlerter = (ref, iRef) => {
    useEffect(() => {
      function handleClickOutside(event) {
        let buttonRef = ref.current && !ref.current.contains(event.target);
        let iconsRef = iRef.current && !iRef.current.contains(event.target);

        if (buttonRef && iconsRef) {
          setOpen(false);
        } else if (buttonRef && !iconsRef) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, iRef, open]);
  };
  useOutsideAlerter(buttonRef, iconRef);
  // end: outside clicks

  return (
    <div className={`relative h-20 w-20`}>
      <CampaignPopupPopOutIcons
        onClick={onClickIcon}
        onClose={onCloseIcon}
        className="absolute p-2 right-0 top-1 box-shadow-2"
        icon="editBlack"
        inactiveColor="bg-white"
        position={position}
        ref={ref}
        iconRef={iconRef}
      />
      <button
        type="button"
        className="bg-siteBg h-20 w-20 rounded-full mt-3 box-shadow"
        onClick={toogleSelector}
        ref={buttonRef}
      >
        {Icon ? (
          <div className="relative">
            <Icon className="w-10 h-10 m-auto" sx={{ fontSize: 40 }} />
          </div>
        ) : (
          <div className="relative">
            <span className="w-10 h-10 m-auto opacity-70">None</span>
          </div>
        )}
      </button>
    </div>
  );
}
