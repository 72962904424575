const DashboardInteractionIconSVG = ({ className = "" }) => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 4L24 1M24 1L27 4M24 1V10"
      stroke="#28265B"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 45L24 48M24 48L27 45M24 48V39"
      stroke="#28265B"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.2429 8.12132H40.4856M40.4856 8.12132V12.364M40.4856 8.12132L34.1216 14.4853"
      stroke="#28265B"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3635 8.12132H7.12087M7.12087 8.12132V12.364M7.12087 8.12132L13.4848 14.4853"
      stroke="#28265B"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 22L1 25M1 25L4 28M1 25L10 25"
      stroke="#28265B"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45 22L48 25M48 25L45 28M48 25L39 25"
      stroke="#28265B"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3635 41.4852H7.12087M7.12087 41.4852V37.2426M7.12087 41.4852L13.4848 35.1213"
      stroke="#28265B"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.1113 22.8667L18.1558 17.6667C18.0562 17.6294 17.9481 17.6214 17.8442 17.6437C17.7403 17.666 17.645 17.7177 17.5695 17.7925C17.4941 17.8674 17.4417 17.9623 17.4186 18.0661C17.3955 18.1698 17.4026 18.278 17.4391 18.3778L22.6669 32.3556C22.7075 32.4638 22.7808 32.5567 22.8767 32.6213C22.9726 32.6859 23.0862 32.719 23.2018 32.7159C23.3173 32.7129 23.4291 32.6738 23.5214 32.6042C23.6137 32.5347 23.6821 32.438 23.7169 32.3278L25.2113 27.4667L30.278 32.4111C30.3821 32.5146 30.5229 32.5727 30.6696 32.5727C30.8164 32.5727 30.9572 32.5146 31.0613 32.4111L32.3669 31.1056C32.4703 31.0015 32.5284 30.8607 32.5284 30.7139C32.5284 30.5671 32.4703 30.4263 32.3669 30.3222L27.3169 25.4L32.1113 23.9167C32.2205 23.8789 32.3151 23.808 32.3822 23.7139C32.4492 23.6198 32.4852 23.5072 32.4852 23.3917C32.4852 23.2762 32.4492 23.1635 32.3822 23.0694C32.3151 22.9753 32.2205 22.9044 32.1113 22.8667Z"
      fill="#EC5627"
    />
  </svg>
);

export default DashboardInteractionIconSVG;
