const StarIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="50"
      className={className}
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1537_15651)">
        <path
          d="M48.4728 24.1303C49.8579 22.7802 50.347 20.7987 49.7493 18.959C49.1516 17.1195 47.5912 15.8038 45.6769 15.5257L36.1035 14.1346C35.109 13.9901 34.2499 13.3658 33.8051 12.4648L29.5238 3.78965C28.6675 2.05508 26.9341 0.977539 24.9999 0.977539C23.0658 0.977539 21.3323 2.05508 20.4762 3.78956L16.1949 12.4647C15.7501 13.3657 14.8909 13.9901 13.8965 14.1345L4.32306 15.5257C2.4089 15.8038 0.84835 17.1194 0.250693 18.959C-0.347061 20.7987 0.142002 22.7802 1.52716 24.1304L8.45471 30.883C9.17414 31.5844 9.50237 32.5944 9.33254 33.5848L7.69709 43.1198C7.37023 45.0263 8.13908 46.9167 9.70403 48.0537C11.2688 49.1907 13.3046 49.3378 15.0167 48.4377L23.5796 43.9359C24.4689 43.4684 25.5309 43.4683 26.4205 43.9359L34.9834 48.4377C35.7281 48.8292 36.5338 49.0226 37.3356 49.0226C38.3771 49.0226 39.412 48.6962 40.2961 48.0536C41.8608 46.9167 42.6298 45.0262 42.3029 43.1197L40.6674 33.5848C40.4975 32.5944 40.8257 31.5842 41.5454 30.8829L48.4728 24.1303ZM38.7038 33.9216L40.3392 43.4565C40.5401 44.6276 40.0861 45.7436 39.125 46.4421C38.1637 47.1405 36.962 47.2273 35.9102 46.6745L27.3473 42.1727C26.6123 41.7863 25.8063 41.5931 24.9999 41.5931C24.1938 41.5931 23.3874 41.7864 22.6525 42.1728L14.0896 46.6746C13.0379 47.2275 11.8362 47.1406 10.8748 46.4422C9.91369 45.7437 9.45969 44.6277 9.66057 43.4566L11.296 33.9215C11.5767 32.2846 11.0343 30.6154 9.84514 29.4563L2.91769 22.7038C2.06681 21.8745 1.77794 20.7046 2.14513 19.5745C2.51232 18.4447 3.43341 17.6679 4.60939 17.4971L14.1829 16.1059C15.8263 15.867 17.2461 14.8355 17.9813 13.3462L22.2626 4.6711C22.7884 3.60567 23.8117 2.96954 24.9998 2.96954C26.1879 2.96954 27.2113 3.60557 27.7371 4.6711L32.0184 13.3462C32.7535 14.8354 34.1734 15.8671 35.8168 16.1059L45.3904 17.4971C46.5661 17.6679 47.4873 18.4446 47.8546 19.5745C48.2218 20.7046 47.933 21.8745 47.082 22.7038L40.1547 29.4564C38.9656 30.6155 38.4232 32.2847 38.7038 33.9216Z"
          fill="currentColor"
        />
        <path
          d="M24.401 6.64369C23.9073 6.40004 23.3103 6.60277 23.0669 7.09613L22.2143 8.82348C21.971 9.31684 22.1735 9.91401 22.6668 10.1575C22.8086 10.2274 22.9588 10.2606 23.1068 10.2606C23.474 10.2606 23.8272 10.0566 24.0009 9.70512L24.8534 7.97777C25.0967 7.48441 24.8941 6.88715 24.401 6.64369Z"
          fill="currentColor"
        />
        <path
          d="M21.8435 11.459C21.3501 11.2156 20.7528 11.4181 20.5094 11.9115L19.552 13.8514C18.5139 15.9547 16.5083 17.412 14.1869 17.7494C13.6426 17.8285 13.2654 18.3339 13.3445 18.8783C13.4165 19.3741 13.8422 19.7312 14.3289 19.7312C14.3767 19.7312 14.4249 19.7278 14.4735 19.7208C17.4437 19.2891 20.01 17.4245 21.3384 14.7331L22.2959 12.7931C22.5392 12.2997 22.3367 11.7024 21.8435 11.459Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1537_15651">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarIconSVG;
