import styled from "styled-components";
import { motion } from "framer-motion";
import { device } from "../../screens";

const TabSelector = styled(motion.div)`
  width: 100% !important;
  display: flex !important;

  align-items: center !important;
  justify-content: center !important;
  border-bottom-width: 2px !important;

  padding-top: 14px !important;
  padding-bottom: 14px !important;

  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 12px !important;

  flex-grow: 0 !important;
  cursor: pointer !important;
  white-space: nowrap !important;

  @media ${device.xs} {
    font-size: 14px !important;
    line-height: 14px !important;

    padding-top: 21px !important;
    padding-bottom: 21px !important;
  }

  ${({ isActive }) =>
    isActive
      ? `
		color: rgb(0, 0, 0) !important;
		border-color: rgb(0, 0, 0) !important;

		&:focus {
			outline: 2px solid transparent !important;
			outline-offset: 2px !important;
			color: rgb(0, 0, 0) !important;
			border-color: rgb(0, 0, 0) !important;
		}
	`
      : `	color: #848D9E !important;
		border-color: rgb(209, 213, 219) !important;
		
		&:focus {
			color: rgb(0, 0, 0) !important;
			border-color: rgb(0, 0, 0) !important;
		}

		&:hover {
			color: rgb(0, 0, 0) !important;
			border-color: rgb(0, 0, 0) !important;
		}
	`}
`;

export default TabSelector;
