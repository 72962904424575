import BrowserEmptyIconSVG from "assets/svg/browser-empty-icon";

const EmptyBrowser = () => {
  return (
    <div className="m-auto text-tabBorder">
      <BrowserEmptyIconSVG />
      <div className="text-center text-browserColor">
        <p className="pt-6">
          Your website will be displayed here once
          <br /> you fill in your site URL...
        </p>
        <p className="pt-6">(Make sure it’s a real URL.)</p>
      </div>
    </div>
  );
};

export default EmptyBrowser;
