import styled from "styled-components";
import MailSVG from "../../commons/icons/mail";
import SendSVG from "../../commons/icons/send";
import PhoneIconSamllSVG from "../../commons/icons/phoneIconSmall";
import { device } from "../../../screens";
import _ from "lodash";

const MultipleAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  margin-bottom: 32px;
`;
const MultipleAddressTitle = styled.h1`
  margin: 0;

  font-weight: 700;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 20px;
  line-height: 26px;

  @media ${device.xs} {
    font-size: 24px;
    line-height: 31px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 28px;
    line-height: 36px;
  }
`;
const MultipleAddressData = styled.a`
  margin: 0;
  display: flex;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  align-items: center;
  padding-bottom: 8px;

  ${({ visible }) => (!visible ? `display: none;` : "")}

  font-size: 14px;
  line-height: 17px;

  @media ${device.xs} {
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const MultipleAddressEmail = styled.a`
  margin: 0;
  display: flex;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  align-items: center;

  ${({ visible }) => (!visible ? `display: none;` : "")}

  font-size: 14px;
  line-height: 17px;

  @media ${device.xs} {
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const MultipleAddressPhone = styled.a`
  display: flex;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  align-items: center;
  text-decoration: inherit;
  padding-bottom: 8px;

  ${({ visible }) => (!visible ? `display: none;` : "")}

  font-size: 14px;
  line-height: 17px;

  @media ${device.xs} {
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;
const Div = styled.div`
  margin-top: 16px;
`;

const MultipleAddress = ({ width, data, color }) => {
  return (
    <MultipleAddressContainer>
      <MultipleAddressTitle
        visible={data.contact_title.visible}
        responsive={width}
      >
        {data.contact_title.value}
      </MultipleAddressTitle>
      <Div>
        <MultipleAddressData
          visible={data.address.visible}
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${data.address.value}`}
          responsive={width}
        >
          {data.address.value && (
            <SendSVG fill={color ? color : "#D1D1D1"} responsive={width} />
          )}
          {data.address.value}
        </MultipleAddressData>

        <MultipleAddressPhone
          responsive={width}
          visible={
            data.contact_number.visible && !_.isEmpty(data.contact_number.value)
          }
          href={`tel:${data.contact_number.value}`}
        >
          {data.contact_number.value && (
            <PhoneIconSamllSVG
              fill={color ? color : "#D1D1D1"}
              responsive={width}
            />
          )}
          {data.contact_number.value}
        </MultipleAddressPhone>
        <MultipleAddressEmail
          responsive={width}
          visible={data.email_address.visible}
          href={`mailto:${data.email_address.value}`}
        >
          {data.email_address.value && (
            <MailSVG fill={color ? color : "#D1D1D1"} responsive={width} />
          )}
          {data.email_address.value}
        </MultipleAddressEmail>
      </Div>
    </MultipleAddressContainer>
  );
};

export default MultipleAddress;
