const PromotionAvatarSVG = ({ className = "" }) => {
  return (
    <svg
      width="118"
      height="105"
      viewBox="0 0 118 105"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="77.2275"
        cy="64.0513"
        r="39.8504"
        fill="#EBEBEB"
        stroke="#BEBEBE"
        strokeWidth="0.754266"
      />
      <g clipPath="url(#clip0_1428_22351)">
        <path
          d="M62.5602 21.7342C63.3055 23.1389 63.763 18.4354 64.4831 18.0092C65.2032 17.5829 71.1185 13.8025 73.0355 12.8229C73.693 12.5238 74.3943 12.3974 75.0987 12.4507C75.803 12.5041 76.4966 12.7363 77.139 13.1338C79.0527 14.2573 79.9434 16.8968 80.3903 19.5225C81.0028 23.1444 81.8218 22.3844 82.2984 24.4286C82.8854 26.9466 83.2777 28.664 83.59 28.9899C83.9005 29.1585 84.2439 29.1831 84.56 29.0595C84.8761 28.9359 84.9133 29.0469 85.0511 28.8701C85.3255 28.5181 85.3213 27.9488 85.3167 26.5673C85.5262 23.4513 85.2473 20.2934 84.4962 17.2794C83.74 14.2525 80.3901 10.5429 80.3901 10.5429C80.3901 10.5429 75.3471 8.01174 72.2941 8.40236C69.2565 8.82279 65.9162 8.35595 63.698 10.9864C62.6274 12.1405 62.0375 12.5855 62.1818 16.2872"
          fill="#263238"
        />
        <path
          d="M68.6201 48.3649C68.6201 48.3649 65.6873 68.2045 65.4899 70.5932L90.5343 72.7846L86 51.0005C86 51.0005 85.3236 47.1475 84.7428 43.2009C83.7789 36.6524 87.2469 27.3902 87.2469 27.3902C87.8529 19.0893 83.493 10.8271 75.4571 10.4839L74.7117 10.4729C71.1003 10.5416 66.236 11.1923 63.6107 13.7738C60.9853 16.3552 60.6366 19.5648 60.5413 24.4064C60.446 29.2479 60.4198 28.4877 60.9496 32.7139C61.4794 36.9402 61.8888 44.2962 68.6201 48.3649Z"
          fill="#FFBE9D"
        />
        <path
          d="M68.496 38.9854L72.1359 38.8052C72.3445 38.7738 72.5559 38.8044 72.7446 38.8933C72.9359 39.0321 73.0666 39.241 73.1105 39.4781C73.2776 40.0643 73.2695 40.6927 73.0873 41.2843C72.9051 41.8758 72.5569 42.4042 72.0865 42.8028C71.5996 43.1791 71.0037 43.3735 70.402 43.3524C69.8004 43.3313 69.2308 43.096 68.792 42.6872C67.9125 41.7917 67.9486 40.2783 68.3722 39.0488L68.496 38.9854Z"
          fill="#EB996E"
        />
        <path
          d="M68.5991 42.8777C68.5876 42.9044 68.6919 43.0533 68.9524 43.2418C69.3506 43.504 69.8101 43.6576 70.2884 43.6886C71.0198 43.7353 71.7498 43.5012 72.3351 43.0321C72.6993 42.7494 72.9993 42.3891 73.2131 41.9774C73.4269 41.5658 73.5492 41.1132 73.5709 40.6527C73.5954 40.1763 73.516 39.7033 73.3382 39.2678C73.251 39.0587 73.141 38.8609 73.0101 38.6779C72.9318 38.5679 72.8283 38.4793 72.7083 38.4195C72.5906 38.3641 72.4614 38.3367 72.3304 38.3392C72.1334 38.3552 71.9371 38.3813 71.7422 38.4173L71.2201 38.5075L70.2808 38.6689C69.1368 38.8807 68.4301 39.0455 68.4375 39.1116C68.4448 39.1777 69.1619 39.1434 70.3187 39.021L71.2724 38.8972L71.8085 38.8339C71.9875 38.82 72.1865 38.783 72.3388 38.784C72.4031 38.7801 72.4668 38.7944 72.5228 38.8254C72.5787 38.8564 72.6247 38.9027 72.6557 38.9594C72.7591 39.113 72.8455 39.278 72.9136 39.4515C73.0572 39.8223 73.1223 40.2219 73.1045 40.6244C73.0856 41.0143 72.9845 41.3978 72.8081 41.7487C72.6318 42.0996 72.3844 42.4098 72.0827 42.6579C71.5861 43.0776 70.966 43.3109 70.33 43.3174C69.8926 43.3154 69.4623 43.2253 69.0639 43.0523C68.7735 42.9458 68.6253 42.852 68.5991 42.8777Z"
          fill="#263238"
        />
        <rect
          x="82"
          y="12.0005"
          width="2.81749"
          height="3.4436"
          fill="#FFBE9D"
        />
        <ellipse
          cx="70.326"
          cy="35.5377"
          rx="0.80868"
          ry="0.80868"
          transform="rotate(1.79121 70.326 35.5377)"
          fill="#EB996E"
        />
        <path
          d="M68.6203 48.3666C72.2273 48.3942 76.3451 47.9494 80.2034 44.2971C80.2034 44.2971 76.0939 51.0417 68.3069 50.2451L68.6203 48.3666Z"
          fill="#EB996E"
        />
        <path
          d="M86.202 30.0469C86.3467 30.026 92.3367 29.8255 90.3364 35.5937C88.336 41.3619 83.9043 38.4571 83.946 38.2871C83.9878 38.1172 86.202 30.0469 86.202 30.0469Z"
          fill="#FFBE9D"
        />
        <path
          d="M86.4186 36.4123C86.4186 36.4123 86.4829 36.5125 86.6114 36.6353C86.6995 36.7186 86.8031 36.782 86.916 36.8217C87.0289 36.8615 87.1489 36.8769 87.2694 36.8671C87.6738 36.7591 88.0482 36.54 88.3543 36.2322C88.6604 35.9245 88.8871 35.5392 89.0112 35.1158C89.1812 34.6528 89.2568 34.1604 89.2327 33.6722C89.2439 33.4865 89.2129 33.3025 89.1421 33.1359C89.0714 32.9692 88.9631 32.8248 88.8263 32.7149C88.7357 32.6483 88.6243 32.6193 88.5121 32.6331C88.4 32.6469 88.2946 32.7027 88.2149 32.7903C88.0918 32.9117 88.1251 33.0494 88.0579 33.0354C87.9907 33.0215 87.9865 32.9037 88.1045 32.6933C88.1777 32.5705 88.2867 32.4758 88.4139 32.4243C88.5933 32.3609 88.786 32.3732 88.9513 32.4585C89.1469 32.5765 89.3076 32.7486 89.417 32.9573C89.5264 33.1661 89.5808 33.404 89.5745 33.6472C89.6272 34.1923 89.5519 34.7483 89.3552 35.266C89.2102 35.7605 88.9328 36.2046 88.5577 36.543C88.1825 36.8814 87.7263 37.0991 87.2457 37.1689C87.096 37.1625 86.9508 37.1206 86.8208 37.0463C86.6908 36.972 86.5793 36.8672 86.4943 36.7394C86.3817 36.5447 86.3996 36.4153 86.4186 36.4123Z"
          fill="#EB996E"
        />
        <path
          d="M77.387 13.8293C75.0391 14.1423 73.3173 13.2856 71.2824 13.8293C69.2476 14.3729 66.973 15.1949 65.3342 12.7338C63.299 9.67752 65.4913 7.09895 67.5256 6.31615C69.56 5.53334 72.7783 8.3506 76.2916 8.35073C80.5173 8.35089 83.0376 9.06829 85.0769 11.8546C86.6333 14.1521 87.5965 17.0091 87.847 20.0709C88.0945 23.1193 87.8529 26.2296 87.1362 29.219L87.131 29.2505C86.9133 30.5691 86.823 31.1159 86.4974 31.4101C86.3325 31.559 86.314 31.4451 85.982 31.5131C85.6499 31.5811 85.805 31.5751 85.5264 31.3605C85.2717 30.9929 86.1723 25.5689 83.9609 22.5949C81.7494 19.6209 85.3897 15.7643 83.1782 13.8293C80.9668 11.8943 79.7349 13.5162 77.387 13.8293Z"
          fill="#263238"
        />
        <path
          d="M78.0301 27.9098C77.9991 28.3591 76.8855 29.8322 75.4416 29.7644C73.9978 29.6966 72.8815 28.4488 72.9124 27.9995C72.9433 27.5502 74.2452 26.1022 75.6891 26.17C77.133 26.2378 78.061 27.4605 78.0301 27.9098Z"
          fill="white"
        />
        <path
          d="M73.449 27.6547C73.369 28.1389 73.4955 28.6293 73.8012 29.0206C74.107 29.412 74.5677 29.673 75.0845 29.7477C75.3347 29.7964 75.5938 29.7975 75.8464 29.751C76.099 29.7046 76.34 29.6114 76.5551 29.4771C76.7701 29.3429 76.9548 29.1702 77.0982 28.9694C77.2415 28.7687 77.3406 28.5439 77.3895 28.3084C77.4679 27.8248 77.3419 27.3355 77.0381 26.9438C76.7343 26.552 76.2766 26.2887 75.7615 26.2092C75.5105 26.1615 75.2508 26.1611 74.9977 26.2083C74.7446 26.2555 74.5031 26.3492 74.2876 26.4839C74.0721 26.6186 73.8869 26.7916 73.7429 26.9926C73.5988 27.1937 73.4989 27.4188 73.449 27.6547Z"
          fill="#EB996E"
        />
        <path
          d="M73.4913 27.726C73.4225 28.1426 73.5313 28.5646 73.7944 28.9013C74.0575 29.238 74.4539 29.4626 74.8986 29.5269C75.1138 29.5688 75.3367 29.5698 75.5541 29.5298C75.7715 29.4898 75.9788 29.4096 76.1639 29.2941C76.3489 29.1785 76.5078 29.03 76.6312 28.8572C76.7545 28.6845 76.8398 28.4911 76.8819 28.2885C76.9493 27.8724 76.8409 27.4513 76.5795 27.1143C76.3181 26.7772 75.9242 26.5506 75.4811 26.4823C75.2651 26.4411 75.0417 26.4409 74.8238 26.4815C74.606 26.522 74.3983 26.6027 74.2129 26.7186C74.0274 26.8345 73.8681 26.9833 73.7441 27.1563C73.6202 27.3293 73.5343 27.523 73.4913 27.726Z"
          fill="#263238"
        />
        <ellipse
          rx="0.656802"
          ry="0.597558"
          transform="matrix(0.998956 0.0469052 -0.0686808 0.997698 74.326 27.4952)"
          fill="white"
        />
        <path
          d="M61.7606 27.7521C61.7356 28.2017 62.6609 29.7592 64.1058 29.8111C65.5507 29.8629 66.8159 28.7138 66.8408 28.2643C66.8658 27.8147 65.7501 26.2665 64.3052 26.2147C62.8603 26.1628 61.7855 27.3026 61.7606 27.7521Z"
          fill="white"
        />
        <path
          d="M62.4441 27.4866C62.2968 27.9583 62.3531 28.46 62.601 28.8841C62.8489 29.3082 63.2685 29.6209 63.7698 29.755C64.0107 29.8323 64.2672 29.8634 64.524 29.8465C64.7807 29.8296 65.0325 29.765 65.2643 29.6566C65.4962 29.5481 65.7034 29.398 65.8736 29.2152C66.0438 29.0324 66.1735 28.8206 66.2551 28.5925C66.4006 28.1212 66.3446 27.6206 66.0987 27.1963C65.8529 26.7721 65.4365 26.4575 64.9375 26.3189C64.6956 26.2424 64.4385 26.212 64.1811 26.2295C63.9238 26.247 63.6715 26.3121 63.4391 26.4209C63.2067 26.5297 62.999 26.6801 62.8281 26.8631C62.6572 27.0461 62.5266 27.2581 62.4441 27.4866Z"
          fill="#EB996E"
        />
        <path
          d="M62.4765 27.5622C62.3498 27.968 62.3982 28.3997 62.6115 28.7646C62.8248 29.1295 63.1859 29.3986 63.6172 29.514C63.8245 29.5805 64.0452 29.6073 64.2661 29.5927C64.487 29.5782 64.7037 29.5226 64.9032 29.4293C65.1027 29.336 65.281 29.2068 65.4274 29.0495C65.5738 28.8923 65.6855 28.71 65.7556 28.5137C65.8809 28.1082 65.8326 27.6775 65.6211 27.3124C65.4096 26.9474 65.0513 26.6767 64.622 26.5575C64.4139 26.4916 64.1926 26.4654 63.9711 26.4805C63.7497 26.4956 63.5326 26.5516 63.3327 26.6452C63.1327 26.7388 62.954 26.8682 62.8069 27.0257C62.6599 27.1832 62.5475 27.3656 62.4765 27.5622Z"
          fill="#263238"
        />
        <ellipse
          rx="0.654787"
          ry="0.599582"
          transform="matrix(0.985845 0.162825 -0.207498 0.97965 63.3727 27.5055)"
          fill="white"
        />
        <path
          d="M72.5952 26.2132C72.7733 26.393 73.908 25.6044 75.4773 25.5981C77.0466 25.5918 78.1706 26.3248 78.3479 26.1388C78.437 26.0603 78.2648 25.7357 77.7771 25.3904C77.1147 24.9485 76.315 24.7182 75.4871 24.7312C74.6709 24.734 73.8648 24.9856 73.1872 25.449C72.6897 25.8022 72.5116 26.1326 72.5952 26.2132Z"
          fill="#263238"
        />
        <path
          d="M61.5706 25.7888C61.7221 25.9701 62.7927 25.3824 64.2144 25.5468C65.6361 25.7112 66.6142 26.4936 66.7849 26.3453C66.8699 26.2841 66.7316 25.9729 66.3087 25.6089C65.7328 25.1387 65.0209 24.8445 64.2705 24.7665C63.531 24.6805 62.7872 24.8199 62.1483 25.1642C61.6786 25.4286 61.4992 25.707 61.5706 25.7888Z"
          fill="#263238"
        />
        <path
          d="M71.8182 22.4354C72.5732 23.1874 77.8772 21.6743 81.3982 25.4556C81.0047 19.7968 72.3406 21.1225 71.8789 21.176C71.8789 21.176 71.0632 21.6834 71.8182 22.4354Z"
          fill="#263238"
        />
        <path
          d="M67.9066 22.375C67.3371 22.981 63.8977 20.5505 61.2191 23.6468C61.951 18.1476 67.664 20.9902 67.9699 21.1247C67.9699 21.1247 68.4761 21.769 67.9066 22.375Z"
          fill="#263238"
        />
        <path
          d="M70.1593 26.2483C69.97 27.289 69.732 28.3023 69.5301 29.3334L69.228 30.8856L68.9134 32.4281C68.8006 32.9396 68.7115 33.4593 68.6113 33.9803L68.5267 34.364L68.5052 34.4571C68.5067 34.4682 68.4985 34.4918 68.5 34.5029L68.4933 34.5377C68.4963 34.5598 68.4867 34.5724 68.4896 34.5946C68.4925 34.6168 68.4829 34.6293 68.4859 34.6515C68.4924 34.786 68.5559 34.9243 68.6704 35.022C68.7221 35.0716 68.7945 35.1071 68.864 35.1205C68.8987 35.1272 68.9335 35.1338 68.9793 35.139L69.0126 35.1346L69.0569 35.1288L69.1582 35.1266C69.4273 35.1135 69.6853 35.1019 69.9544 35.0888C70.483 35.0752 71.013 35.0726 71.549 35.1144L71.5593 35.192C71.0527 35.372 70.5403 35.5077 70.0264 35.6322C69.7632 35.6897 69.5111 35.7456 69.2479 35.8031L69.1495 35.8274L69.1052 35.8333L69.0386 35.8421C68.9499 35.8539 68.8486 35.856 68.757 35.8456C68.5737 35.8248 68.3845 35.7596 68.2241 35.6567C68.0638 35.5538 67.9323 35.4132 67.8311 35.2461C67.73 35.0789 67.6814 34.8823 67.6675 34.6923C67.6616 34.6479 67.6653 34.591 67.6705 34.5452C67.6757 34.4993 67.681 34.4535 67.6862 34.4077L67.6995 34.3382L67.7158 34.2909L67.747 34.1852L67.8552 33.8097C68.0027 33.3049 68.1613 32.7987 68.2977 32.2953L68.7165 30.7728L69.1465 29.2488C69.44 28.2281 69.8561 26.514 70.0411 26.2074C70.2261 25.9009 70.1593 26.2483 70.1593 26.2483Z"
          fill="#263238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.4715 51.4009C84.4858 51.6301 84.7108 53.4524 84.9858 55.6703C85.1854 56.8749 85.009 58.114 84.4822 59.2078C84.0111 60.1448 83.4396 61.024 82.7785 61.8286L79.3281 64.941L78.5638 67.8982L73.9205 61.0523C70.2524 60.0654 68.5379 54.3692 68.5379 54.3692L68.1254 51.7275L65.8935 54.034L61.8289 55.1023C61.8289 55.1023 52.7531 58.0595 50.4922 60.61L57.9214 96.3769L57.7893 107.666H100.822C100.822 107.108 101.207 70.1912 101.207 70.1912L103.79 55.7048L88.5063 52.9214L85.9689 50.8555C85.098 51.2551 84.4715 51.4009 84.4715 51.4009Z"
          fill="#EC5627"
        />
        <path
          d="M93 95.7424C93.6363 94.8894 94.3219 94.0768 95.0528 93.3092C95.7401 92.496 96.4729 91.7252 97.2476 91.001C96.6087 91.8541 95.9221 92.6678 95.1913 93.4379C94.5041 94.2489 93.7725 95.0183 93 95.7424Z"
          fill="white"
        />
        <path
          d="M93.1487 86.574C92.1698 85.5756 91.4334 84.3512 91 83.0014C91.0533 82.9793 91.4333 83.8357 92.0299 84.8171C92.6266 85.7985 93.1913 86.5373 93.1487 86.574Z"
          fill="white"
        />
        <path
          d="M58 79.7424C58.6363 78.8894 59.3219 78.0768 60.0528 77.3092C60.7401 76.496 61.4729 75.7252 62.2476 75.001C61.6087 75.8541 60.9221 76.6678 60.1913 77.4379C59.5041 78.2489 58.7725 79.0183 58 79.7424Z"
          fill="white"
        />
        <path
          d="M61 91.147C61.1486 89.7431 61.4172 88.3557 61.8027 87.001C61.7597 87.7062 61.6563 88.4061 61.4937 89.0924C61.39 89.7914 61.2248 90.4791 61 91.147Z"
          fill="white"
        />
        <path
          d="M103.794 55.7036C105.411 56.6553 106.829 57.9293 107.967 59.4521C109.105 60.9749 109.94 62.7162 110.423 64.5751C112.148 70.9626 117.424 87.5966 117.424 87.5966C117.424 87.5966 120.438 91.2672 120.76 93.9841C121.081 96.7009 120.438 103.277 119.467 104.504C119.185 104.86 118.824 105.14 118.415 105.318C118.005 105.497 117.559 105.569 117.117 105.528L104.426 104.282L99.4258 69.654L103.794 55.7036Z"
          fill="#EC5627"
        />
        <path
          d="M50.4971 60.6089C50.4971 60.6089 47.5075 64.8265 46.2681 67.5804C45.0287 70.3343 34.7385 93.4556 34.1242 95.8472C33.5099 98.2388 31.9097 105.757 36.6066 107.502H46.2753L55.9761 87.1975L56.4726 63.6326L50.4971 60.6089Z"
          fill="#EC5627"
        />
        <path
          d="M46.881 92.8349C46.881 92.8349 46.6667 92.639 46.2203 92.3766C45.583 92.0228 44.8997 91.7664 44.1915 91.6151C43.7046 91.5047 43.2103 91.433 42.7128 91.4007C42.1397 91.356 41.5633 91.3984 41.002 91.5264C39.7314 91.8641 38.594 92.6031 37.7481 93.6407C36.888 94.6667 36.2585 95.8775 35.9051 97.1856C35.6134 98.2549 35.4196 99.3502 35.3265 100.457C35.2336 101.403 35.2014 102.176 35.1693 102.704C35.1693 102.956 35.1407 103.159 35.1336 103.318C35.1338 103.391 35.1242 103.463 35.105 103.532C35.1004 103.461 35.1004 103.389 35.105 103.318C35.105 103.155 35.105 102.948 35.105 102.704C35.105 102.168 35.1264 101.4 35.2014 100.446C35.277 99.321 35.46 98.2066 35.7479 97.1191C36.0988 95.7813 36.7374 94.543 37.6159 93.4966C38.4883 92.43 39.663 91.6747 40.9734 91.3378C41.5463 91.2144 42.1338 91.1808 42.7164 91.238C43.2207 91.2764 43.7212 91.3567 44.213 91.4783C44.9291 91.6431 45.6159 91.9224 46.2488 92.3063C46.4193 92.4153 46.5806 92.539 46.731 92.676C46.7863 92.7233 46.8366 92.7766 46.881 92.8349Z"
          fill="#263238"
        />
        <path
          d="M42.3008 88.7915C42.4065 88.7596 42.5157 88.7421 42.6258 88.7397C42.9242 88.7157 43.2239 88.7157 43.5223 88.7397C43.9666 88.7667 44.4077 88.8347 44.8403 88.943C45.9046 89.2093 46.9035 89.7028 47.7727 90.392C48.1268 90.6709 48.4556 90.9827 48.7549 91.3235C48.9578 91.5546 49.1429 91.8018 49.3085 92.0628C49.376 92.1533 49.4312 92.2527 49.4728 92.3585C49.4443 92.3807 49.18 91.9889 48.6585 91.427C48.3504 91.1075 48.0197 90.8122 47.6691 90.5436C47.2374 90.2183 46.7784 89.9338 46.2976 89.6934C45.814 89.46 45.3122 89.2695 44.7974 89.1241C44.3753 89.0116 43.9454 88.9325 43.5116 88.8876C42.7687 88.7877 42.3008 88.8284 42.3008 88.7915Z"
          fill="#263238"
        />
        <path
          d="M51.039 87.6775C50.8895 87.6838 50.7397 87.6726 50.5926 87.6442C50.3068 87.6073 49.8961 87.5629 49.3818 87.5223C46.7106 87.3161 44.0261 87.6488 41.4775 88.5018C40.9882 88.6645 40.5953 88.8086 40.3274 88.9158C40.1907 88.9784 40.0483 89.0266 39.9023 89.06C40.0253 88.9716 40.1585 88.8996 40.2988 88.8456C40.5595 88.7162 40.9489 88.5536 41.4346 88.3724C43.9851 87.4378 46.7046 87.1019 49.396 87.3892C49.9068 87.4446 50.3211 87.5075 50.6033 87.5703C50.7515 87.5916 50.8974 87.6275 51.039 87.6775Z"
          fill="#263238"
        />
        <path
          d="M78.5746 67.9214C78.5354 67.8862 78.5017 67.8451 78.4746 67.7994L78.2103 67.4297L77.2387 66.0399C76.4065 64.82 75.2421 63.1197 73.8742 61.1236L74.0242 61.1014C73.5027 62.6835 72.9276 64.4282 72.324 66.2764L72.2847 66.391L72.1847 66.3245L71.9847 66.1914C71.2065 65.6758 70.4989 65.0542 69.8809 64.3432C69.3099 63.6812 68.806 62.9604 68.3772 62.1919C67.6636 60.8914 67.1082 59.5045 66.7235 58.0629C66.4653 57.0892 66.2566 56.1021 66.0985 55.1058C66.0413 54.7731 65.9985 54.5032 65.9627 54.2999C65.9526 54.231 65.9371 54.163 65.9163 54.0966C65.9163 54.0523 65.8913 54.0301 65.8949 54.0264C65.8985 54.0227 66.0413 54.396 66.1699 55.0947C66.2985 55.7933 66.5021 56.7987 66.8521 58.0149C67.2477 59.4431 67.8102 60.816 68.5273 62.1031C68.9548 62.8573 69.4537 63.5655 70.0167 64.2175C70.6239 64.9115 71.3183 65.5182 72.0811 66.0214L72.2776 66.1544L72.1419 66.2062C72.7526 64.358 73.3313 62.6243 73.8599 61.0312L73.9135 60.8685L74.0099 61.009C75.3529 63.0236 76.5101 64.7387 77.3209 65.9659C77.7102 66.5648 78.0352 67.0416 78.2531 67.3891L78.4924 67.7587C78.5253 67.8098 78.5528 67.8643 78.5746 67.9214Z"
          fill="#263238"
        />
        <path
          d="M117.95 95.3629C117.54 94.9224 117.1 94.5123 116.635 94.1356C115.491 93.2501 114.12 92.7344 112.694 92.6535C111.268 92.5726 109.85 92.9301 108.62 93.681C108.121 94.003 107.644 94.3599 107.191 94.7493C107.286 94.6136 107.395 94.4896 107.516 94.3796C107.838 94.0705 108.188 93.7956 108.563 93.559C109.805 92.7582 111.256 92.3736 112.717 92.4576C114.178 92.5416 115.579 93.0902 116.728 94.0284C117.075 94.305 117.394 94.6184 117.678 94.9636C117.783 95.0853 117.874 95.2193 117.95 95.3629Z"
          fill="#263238"
        />
        <path
          d="M117.443 91.9508C116.932 91.5711 116.379 91.2559 115.796 91.0119C114.363 90.4854 112.806 90.4376 111.346 90.8752C110.542 91.1085 109.769 91.4411 109.042 91.8658C108.45 92.2176 107.892 92.626 107.374 93.0856C106.931 93.4775 106.592 93.8249 106.359 94.0578C106.245 94.1925 106.12 94.3162 105.984 94.4275C106.077 94.2782 106.185 94.1395 106.306 94.0135C106.614 93.6533 106.942 93.3114 107.288 92.9895C107.801 92.5098 108.36 92.085 108.956 91.7217C109.69 91.2777 110.476 90.9336 111.296 90.6977C112.792 90.2453 114.391 90.3141 115.846 90.8936C116.281 91.0733 116.69 91.312 117.064 91.6034C117.196 91.7032 117.285 91.7993 117.353 91.8584C117.421 91.9176 117.443 91.9471 117.443 91.9508Z"
          fill="#263238"
        />
        <path
          d="M117.471 88.9953C117.471 89.0323 116.756 88.8142 115.621 88.6552C114.949 88.562 114.273 88.5176 113.595 88.5221C112.766 88.5316 111.938 88.6082 111.12 88.7513C110.304 88.9066 109.5 89.1266 108.716 89.4093C108.078 89.6441 107.456 89.9257 106.856 90.2521C105.831 90.8066 105.252 91.2575 105.23 91.2243C105.352 91.1024 105.485 90.9934 105.627 90.899C105.783 90.7722 105.946 90.6562 106.116 90.5515C106.313 90.4221 106.534 90.2706 106.791 90.1301C107.392 89.7864 108.016 89.4899 108.659 89.243C109.449 88.9449 110.261 88.7173 111.088 88.5628C111.914 88.4181 112.751 88.3452 113.588 88.3447C114.274 88.3483 114.958 88.4063 115.635 88.5184C115.924 88.5554 116.181 88.6257 116.406 88.6737C116.602 88.7146 116.795 88.7665 116.985 88.829C117.151 88.8697 117.314 88.9254 117.471 88.9953Z"
          fill="#263238"
        />
        <path
          d="M87.1745 52.7979C87.3226 53.142 87.4144 53.5093 87.446 53.8846C87.5526 54.9448 87.502 56.0155 87.296 57.0599C87.1707 57.8095 87.0001 58.5502 86.7852 59.2777C86.5036 60.1243 86.1573 60.9463 85.7494 61.7359C84.8958 63.4362 83.8242 65.2882 82.5813 67.2806L82.4384 67.5134V67.2362C82.4384 65.9277 82.4384 64.5563 82.4384 63.1331L82.5777 63.2034L79.2238 65.972L79.2524 65.9277C78.4568 68.6259 77.9678 71.4108 77.7951 74.2262C77.4665 78.7248 77.7309 83.0274 77.9344 86.9309C78.138 90.8343 78.2273 94.3571 78.3452 97.3142C78.4631 100.271 78.5595 102.659 78.6452 104.312C78.6952 105.132 78.7309 105.768 78.7559 106.212C78.7559 106.422 78.7809 106.581 78.7881 106.707C78.7952 106.833 78.7881 106.877 78.7881 106.877C78.7881 106.877 78.7881 106.821 78.7631 106.711C78.7381 106.6 78.7345 106.426 78.7131 106.215C78.6738 105.783 78.6238 105.143 78.5702 104.315C78.4631 102.667 78.338 100.275 78.213 97.3179C78.088 94.3608 77.9344 90.8454 77.763 86.9383C77.5915 83.0311 77.2808 78.7248 77.6094 74.2114C77.79 71.3793 78.2923 68.579 79.106 65.8685V65.8426H79.1274L82.4705 63.0666L82.6099 62.9483V63.1331C82.6099 64.5563 82.6099 65.9277 82.6099 67.2362L82.4634 67.1881C84.9172 63.2514 86.9138 59.8285 87.221 57.0599C87.4331 56.0254 87.4981 54.9644 87.4138 53.9105C87.3847 53.6336 87.3357 53.3593 87.2674 53.0899C87.196 52.8903 87.1745 52.7979 87.1745 52.7979Z"
          fill="#263238"
        />
        <path
          d="M80.7646 100.479H80.6503C80.3902 100.496 80.1468 100.617 79.9716 100.817C79.7964 101.016 79.7031 101.279 79.7115 101.548C79.7198 101.818 79.8292 102.074 80.0164 102.261C80.2036 102.449 80.454 102.554 80.7146 102.554C80.9752 102.554 81.2256 102.449 81.4128 102.261C81.6001 102.074 81.7094 101.818 81.7177 101.548C81.7261 101.279 81.6328 101.016 81.4576 100.817C81.2824 100.617 81.039 100.496 80.7789 100.479H80.7646Z"
          fill="white"
        />
        <path
          d="M80.7649 86.2329H80.6506C80.3915 86.2502 80.149 86.3711 79.9745 86.5701C79.7999 86.769 79.707 87.0305 79.7154 87.2991C79.7237 87.5677 79.8327 87.8224 80.0192 88.0093C80.2058 88.1963 80.4553 88.3008 80.7149 88.3008C80.9746 88.3008 81.2241 88.1963 81.4106 88.0093C81.5972 87.8224 81.7062 87.5677 81.7145 87.2991C81.7229 87.0305 81.6299 86.769 81.4554 86.5701C81.2808 86.3711 81.0384 86.2502 80.7792 86.2329H80.7649Z"
          fill="white"
        />
        <path
          d="M80.6557 73.4854C80.9223 73.5035 81.185 73.4113 81.386 73.229C81.5869 73.0467 81.7097 72.7892 81.7272 72.5132C81.7447 72.2373 81.6556 71.9654 81.4794 71.7574C81.3033 71.5495 81.0545 71.4224 80.7878 71.4043H80.6557C80.3885 71.4043 80.1323 71.5141 79.9434 71.7096C79.7546 71.9051 79.6484 72.1702 79.6484 72.4467C79.6484 72.7232 79.7546 72.9883 79.9434 73.1838C80.1323 73.3793 80.3885 73.4891 80.6557 73.4891V73.4854Z"
          fill="white"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M66.0091 54.4902C65.5126 59.5063 67.3556 64.8033 71.2452 67.8418C71.5488 68.0821 71.931 68.3187 72.2918 68.1819C72.5323 68.0613 72.7244 67.8572 72.8347 67.6052C73.924 65.6575 74.3326 63.3813 73.9919 61.1586C73.3776 62.8368 72.849 64.4854 72.2275 66.2376C68.845 64.1084 66.7234 59.5839 66.0091 54.4902Z"
            fill="black"
          />
        </g>
        <path
          opacity="0.3"
          d="M79.1797 65.8988L82.0371 64.8194L82.5086 69.8134C82.5086 69.8134 85.5874 64.0062 86.8625 61.3596C88.1376 58.7129 87.4804 54.0332 87.4804 54.0332C87.4804 54.0332 87.934 58.0993 84.8516 63.3002L82.5228 67.2184V63.1154L79.1797 65.8988Z"
          fill="black"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M46.0015 91.2791C44.6989 90.7065 43.2678 90.5209 41.869 90.7431C40.3783 90.8693 38.9522 91.4279 37.7543 92.3548C36.5971 93.3195 35.8149 94.8425 35.9684 96.3728L36.1113 96.3913C37.1078 93.6042 39.0866 91.5859 41.6404 91.3308C44.1942 91.0758 46.6837 92.2587 48.4267 94.1993C48.2374 92.7503 47.2587 91.8964 46.0015 91.2791Z"
            fill="black"
          />
        </g>
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M118.128 94.1158C117.139 93.0643 115.845 92.3752 114.442 92.1529C113.221 91.9414 111.969 92.0166 110.78 92.373C109.59 92.7294 108.494 93.3578 107.57 94.2119L108.113 93.7535C109.494 92.8927 111.08 92.4473 112.692 92.4671C114.31 92.4958 115.862 93.1376 117.053 94.271C118.125 95.3208 118.764 96.7439 119.375 98.1301C119.474 97.407 119.413 96.6701 119.197 95.9748C118.981 95.2795 118.616 94.6439 118.128 94.1158Z"
            fill="black"
          />
        </g>
        <path
          d="M99.7422 70.9372C99.7712 70.7986 99.8143 70.6636 99.8708 70.5343C99.9815 70.2423 100.117 69.8837 100.274 69.4586C100.632 68.553 101.096 67.3036 101.596 65.9101C102.096 64.5165 102.528 63.2486 102.839 62.3319L103.196 61.2414C103.234 61.1052 103.287 60.9738 103.353 60.8496C103.342 60.9912 103.312 61.1306 103.264 61.2636C103.196 61.5298 103.089 61.9068 102.95 62.3725C102.671 63.3041 102.257 64.5904 101.757 65.9766C101.257 67.3628 100.76 68.6195 100.382 69.5104C100.196 69.9577 100.024 70.3162 99.9208 70.5639C99.8763 70.6954 99.8163 70.8208 99.7422 70.9372Z"
          fill="#263238"
        />
        <path
          d="M54.7123 71.2993C54.6623 71.2993 54.273 69.5509 53.8444 67.37C53.4158 65.1891 53.1086 63.4333 53.1479 63.4037C53.1872 63.3741 53.5837 65.1521 54.0123 67.333C54.4409 69.5139 54.7623 71.2882 54.7123 71.2993Z"
          fill="#263238"
        />
        <path
          d="M47.882 79.6971C47.2401 78.6197 46.6512 77.5093 46.1176 76.3703C45.1889 74.5221 44.4746 72.9844 44.5175 72.9585C45.1594 74.0359 45.7483 75.1463 46.2819 76.2853C46.8712 77.3936 47.4052 78.5324 47.882 79.6971Z"
          fill="white"
        />
        <path
          d="M59.582 66.2085C59.8865 65.2608 60.2504 64.3348 60.6714 63.4361C61.0368 62.5127 61.4579 61.6139 61.9322 60.7451C61.6258 61.692 61.262 62.618 60.8429 63.5175C60.4784 64.4413 60.0572 65.3402 59.582 66.2085Z"
          fill="white"
        />
        <path
          d="M71.0575 74.9605C70.4649 74.3704 69.9157 73.7353 69.4145 73.0605C68.8683 72.4256 68.368 71.7498 67.918 71.0386C68.511 71.6271 69.0592 72.2624 69.5574 72.9386C70.1054 73.5729 70.6069 74.2488 71.0575 74.9605Z"
          fill="white"
        />
        <path
          d="M68.418 87.0004C68.7804 86.3246 69.1936 85.6795 69.6538 85.0708C70.0681 84.4275 70.5328 83.8204 71.0432 83.2559C70.6817 83.9322 70.2685 84.5774 69.8074 85.1854C69.3953 85.8304 68.9305 86.4377 68.418 87.0004Z"
          fill="white"
        />
        <path
          d="M64.8675 104.008C64.7008 103.78 64.5605 103.531 64.4496 103.269C64.2139 102.796 63.9103 102.134 63.6031 101.391C63.2959 100.648 63.0495 99.9569 62.8888 99.4579C62.782 99.1919 62.7052 98.9141 62.6602 98.6299C62.8073 98.8751 62.9271 99.1367 63.0173 99.4098L63.7853 101.314C64.0817 102.02 64.346 102.659 64.5782 103.21C64.703 103.464 64.8 103.732 64.8675 104.008Z"
          fill="white"
        />
        <path
          d="M74.94 96.7773C74.2096 96.7272 73.4844 96.6147 72.772 96.4409C72.0445 96.3294 71.3275 96.1538 70.6289 95.916C72.0785 96.0777 73.5123 96.3658 74.915 96.7773H74.94Z"
          fill="white"
        />
        <path
          d="M82.8008 80.889C83.4407 80.0311 84.1302 79.2139 84.8652 78.442C85.5564 77.6241 86.2935 76.849 87.0726 76.1206C86.43 76.9786 85.7395 77.7969 85.0046 78.5714C84.3135 79.387 83.5777 80.1608 82.8008 80.889Z"
          fill="white"
        />
        <path
          d="M90.6525 102.079C90.6132 102.116 89.4595 100.729 88.0737 98.9921C86.6878 97.2548 85.5985 95.8132 85.6377 95.7799C85.677 95.7466 86.8307 97.1291 88.2165 98.8664C89.6024 100.604 90.6846 102.045 90.6525 102.079Z"
          fill="white"
        />
        <path
          d="M41.7929 93.0498C41.8179 93.1016 40.7642 93.656 39.6141 94.5691C38.464 95.4821 37.664 96.384 37.6211 96.3433C37.7509 96.1093 37.9148 95.8974 38.1069 95.7149C38.5346 95.2422 39.0006 94.8081 39.4998 94.4175C39.9969 94.0248 40.525 93.6761 41.0785 93.3751C41.3001 93.2313 41.5408 93.1217 41.7929 93.0498Z"
          fill="white"
        />
        <path
          d="M44.7343 84.8721C44.5074 85.3951 44.2259 85.8909 43.895 86.3507C43.613 86.8458 43.2788 87.307 42.8984 87.7257C43.126 87.203 43.4074 86.7073 43.7378 86.2472C44.019 85.7515 44.3533 85.2902 44.7343 84.8721Z"
          fill="white"
        />
        <path
          d="M51.3687 92.9002C50.7968 91.9591 50.5524 90.8442 50.6758 89.7397C50.7294 89.7397 50.7472 90.479 50.9437 91.3403C51.1401 92.2016 51.4187 92.8743 51.3687 92.9002Z"
          fill="white"
        />
        <path
          d="M52.3452 67.4247C51.9011 67.5851 51.439 67.6857 50.9701 67.7241C50.5089 67.8244 50.0368 67.8604 49.5664 67.8313C50.0118 67.6712 50.4751 67.5705 50.9451 67.5319C51.4054 67.4338 51.876 67.3977 52.3452 67.4247Z"
          fill="white"
        />
        <path
          d="M57.3244 58.2847C56.5552 58.8738 55.7146 59.356 54.8242 59.7189C55.1897 59.3981 55.5944 59.1284 56.0279 58.9168C56.4325 58.6501 56.8681 58.4377 57.3244 58.2847Z"
          fill="white"
        />
        <path
          d="M71.3339 62.1465C70.8292 61.6153 70.3681 61.0416 69.9552 60.4313C69.497 59.858 69.086 59.246 68.7266 58.6016C69.693 59.7031 70.5653 60.8891 71.3339 62.1465Z"
          fill="white"
        />
        <path
          d="M89.1062 71.336C88.1217 70.3319 87.3812 69.1006 86.9453 67.7431C86.9989 67.7209 87.3811 68.5822 87.9811 69.5691C88.5812 70.5561 89.1491 71.2991 89.1062 71.336Z"
          fill="white"
        />
        <path
          d="M95.893 73.6494C95.462 74.9489 94.9118 76.2028 94.25 77.3939C94.4404 76.7352 94.6879 76.0956 94.9894 75.4829C95.2338 74.8435 95.5363 74.2296 95.893 73.6494Z"
          fill="white"
        />
        <path
          d="M96.8432 64.0649C96.1938 63.4125 95.5899 62.7134 95.0359 61.9728C94.4339 61.2736 93.879 60.5326 93.375 59.7549C94.6248 61.1079 95.7836 62.548 96.8432 64.0649Z"
          fill="white"
        />
        <path
          d="M86.4808 56.2807C86.3677 55.6916 86.3138 55.0921 86.3201 54.4916C86.2663 53.8923 86.2746 53.2888 86.3451 52.6914C86.4583 53.2804 86.5122 53.88 86.5059 54.4805C86.5619 55.0797 86.5535 55.6834 86.4808 56.2807Z"
          fill="white"
        />
        <path
          d="M94.9439 55.7964C94.22 55.9928 93.4818 56.1275 92.7366 56.1993C92.0006 56.3331 91.2551 56.4036 90.5078 56.41C91.2317 56.2136 91.97 56.0789 92.7152 56.0071C93.4512 55.8731 94.1966 55.8026 94.9439 55.7964Z"
          fill="white"
        />
        <path
          d="M108.236 65.9134C107.74 65.0711 107.296 64.1969 106.907 63.2963C106.466 62.4224 106.079 61.5198 105.75 60.5942C106.248 61.436 106.693 62.3102 107.082 63.2113C107.522 64.0855 107.908 64.9881 108.236 65.9134Z"
          fill="white"
        />
        <path
          d="M106.012 74.654C106.161 73.2421 106.431 71.8468 106.819 70.4844C106.776 71.1936 106.672 71.8975 106.508 72.5877C106.404 73.2907 106.238 73.9823 106.012 74.654Z"
          fill="white"
        />
        <path
          d="M112.169 81.5441C111.752 80.623 111.394 79.6746 111.097 78.7052C110.743 77.7574 110.448 76.7869 110.215 75.7998C110.629 76.7233 110.987 77.6727 111.286 78.6424C111.639 79.5895 111.934 80.5587 112.169 81.5441Z"
          fill="white"
        />
        <path
          d="M111.309 85.5777C110.672 85.4784 110.059 85.2511 109.506 84.9086C108.924 84.6198 108.406 84.2124 107.98 83.711C108.016 83.6666 108.673 84.2285 109.595 84.7386C110.516 85.2487 111.327 85.5222 111.309 85.5777Z"
          fill="white"
        />
        <path
          d="M72.5146 107.586C72.3623 107.053 72.2689 106.504 72.236 105.949C72.1456 105.401 72.1144 104.844 72.1432 104.289C72.3063 104.825 72.4058 105.38 72.4396 105.941C72.5252 106.485 72.5503 107.037 72.5146 107.586Z"
          fill="white"
        />
        <path
          d="M96.7734 106.951C96.7898 106.41 96.8678 105.873 97.0056 105.35C97.0861 104.815 97.2263 104.292 97.4235 103.791C97.4071 104.332 97.3279 104.869 97.1878 105.391C97.1094 105.926 96.9704 106.45 96.7734 106.951Z"
          fill="white"
        />
        <path
          d="M49.352 65.7473L50.3661 61.0663C50.2435 60.3242 50.1225 59.4399 50.0272 58.4124C49.9443 57.4873 50.246 56.3507 50.5917 55.4785C51.1298 54.1221 51.5768 53.9272 51.9938 53.1511C52.0358 53.0903 52.078 53.0079 52.1207 52.956C52.2127 52.8405 52.2801 52.7073 52.3186 52.5647C52.6152 51.7441 51.7033 51.049 50.6309 51.6064C49.8253 52.0234 48.5624 52.8366 47.3126 56.0567C47.2993 56.1054 47.2827 56.1463 47.2709 56.1913C46.7472 58.194 43.8341 54.3406 42.9575 52.8183C42.8139 52.5856 42.6198 52.3882 42.3896 52.2407C42.1594 52.0933 41.8989 51.9995 41.6276 51.9663C41.3562 51.9331 41.0808 51.9614 40.8218 52.0491C40.5629 52.1368 40.327 52.2816 40.1316 52.4728L35.8011 57.2735C35.2235 57.3415 34.6824 57.5915 34.2562 57.9873C33.9346 58.2792 33.7334 58.6805 33.6917 59.1129C33.6501 59.5452 33.7711 59.9776 34.0311 60.3255L39.9059 68.1257L40.6344 102.427L54.633 100.929L49.352 65.7473Z"
          fill="#FFBE9D"
        />
        <path
          d="M48.7273 48.6019L48.245 55.256L46.6843 55.4574L42.0776 64.1319C41.8901 64.4871 41.5713 64.7552 41.1892 64.8791C40.807 65.0029 40.3916 64.9728 40.0314 64.795L34.4175 60.7677C34.4175 60.7677 37.798 58.0319 37.9833 55.6897C38.1685 53.3476 39.4913 53.585 39.4913 53.585L40.8109 49.6319L48.7273 48.6019Z"
          fill="#EDC164"
        />
        <path
          d="M40.3835 38.9503C40.3835 38.9503 35.9134 39.7718 27.8217 36.6691C19.7301 33.5665 13.9239 27.3534 13.2444 27.6885C12.565 28.0235 8.877 27.8926 8.39888 43.085C7.92077 58.2773 11.4704 60.2529 12.6232 59.7461C13.776 59.2394 20.7488 53.6924 25.9219 52.1687C31.095 50.645 38.5268 50.54 38.5268 50.54L40.3835 38.9503Z"
          fill="#EDC164"
        />
        <path
          d="M51.9965 42.218L51.7211 45.4592L51.5994 46.9155C51.5181 47.8746 51.0719 48.7659 50.3527 49.4057C49.6336 50.0454 48.6963 50.3847 47.7343 50.3536L37.6808 50.9995L37.872 37.1907L48.5973 38.1598C49.5847 38.2508 50.4962 38.7284 51.1329 39.4886C51.7697 40.2488 52.0801 41.2299 51.9965 42.218Z"
          fill="#EDC164"
        />
        <g opacity="0.4">
          <path
            opacity="0.4"
            d="M51.9965 42.218L51.7211 45.4592L51.5994 46.9155C51.5181 47.8746 51.0719 48.7659 50.3527 49.4057C49.6336 50.0454 48.6963 50.3847 47.7343 50.3536L37.6808 50.9995L37.872 37.1907L48.5973 38.1598C49.5847 38.2508 50.4962 38.7284 51.1329 39.4886C51.7697 40.2488 52.0801 41.2299 51.9965 42.218Z"
            fill="black"
          />
        </g>
        <path
          d="M54.3668 45.7327L51.6755 45.9899L51.9661 42.5757L54.2154 42.9306L54.3668 45.7327Z"
          fill="#EDC164"
        />
        <g opacity="0.6">
          <path
            opacity="0.6"
            d="M51.9661 42.5757L51.6755 45.9899L52.2291 45.9368L52.2344 42.6169L51.9661 42.5757Z"
            fill="black"
          />
        </g>
        <path
          d="M50.5993 43.9115C50.5976 44.0025 49.2178 44.0432 47.5207 44.007C45.8235 43.9709 44.4456 43.8542 44.4456 43.7639C44.4456 43.6736 45.8278 43.6339 47.5295 43.6762C49.2312 43.7184 50.6027 43.8198 50.5993 43.9115Z"
          fill="#EDC164"
        />
        <path
          d="M50.6304 41.9379C50.6246 42.0287 49.3609 42.0303 47.8073 41.9396C46.2537 41.8489 44.9995 41.7021 45.0046 41.6096C45.0097 41.5171 46.2724 41.5179 47.826 41.6086C49.3796 41.6993 50.6355 41.8454 50.6304 41.9379Z"
          fill="#EDC164"
        />
        <path
          d="M50.4171 46.0459C50.4225 46.0918 50.1124 46.1464 49.6008 46.1898C49.3465 46.2149 49.0417 46.2392 48.7045 46.2631C48.3674 46.287 47.9983 46.2542 47.6104 46.2434C46.0561 46.1891 44.8142 46.019 44.8164 45.9338C44.8185 45.8486 46.0815 45.8593 47.6182 45.9149C48.0037 45.9248 48.3711 45.9583 48.7035 45.9565C49.0359 45.9546 49.3336 45.9514 49.5868 45.9569C50.0954 45.973 50.4118 45.9999 50.4171 46.0459Z"
          fill="#EDC164"
        />
        <g opacity="0.4">
          <path
            opacity="0.4"
            d="M34.7266 61.0816C35.6069 61.7746 36.5039 62.4852 37.5387 62.8883C38.5736 63.2914 39.7903 63.3747 40.7909 62.8292C41.7914 62.2837 42.3852 61.0299 41.9612 59.9965C42.6076 60.1575 43.2786 59.6404 43.4908 59.0061C43.7031 58.3719 43.5661 57.6758 43.354 57.0422C44.1891 57.1543 44.9876 56.451 45.1663 55.6279C45.2317 55.2107 45.2045 54.7843 45.0865 54.3789C44.9686 53.9734 44.7627 53.599 44.4836 53.2821C43.9191 52.6553 43.265 52.1154 42.5425 51.68C43.0825 51.5013 43.6359 51.3107 43.9974 50.8842C44.3589 50.4577 44.4248 49.7099 43.9601 49.3891C43.6731 49.2217 43.335 49.1643 43.0088 49.2276C42.0916 49.3072 41.4459 49.3521 40.6457 49.8037C40.4525 49.8853 41.9176 51.8761 37.9269 57.1242C35.5561 60.2329 34.7266 61.0816 34.7266 61.0816Z"
            fill="black"
          />
        </g>
        <path
          d="M47.9328 59.2596C47.9427 59.2831 47.5615 59.3641 46.9987 59.6201C46.2663 59.9501 45.6124 60.432 45.0804 61.0338C44.5483 61.6356 44.1501 62.3436 43.9123 63.1109C43.7271 63.6999 43.7065 64.081 43.667 64.0822C43.6543 63.9916 43.6559 63.8996 43.6718 63.8094C43.6997 63.5633 43.7459 63.3195 43.8101 63.0802C44.0281 62.2862 44.4251 61.5527 44.9708 60.9361C45.5165 60.3195 46.1963 59.8363 46.958 59.5235C47.1863 59.4287 47.4223 59.3538 47.6634 59.2995C47.7501 59.2704 47.8413 59.2569 47.9328 59.2596Z"
          fill="#EB996E"
        />
        <path
          d="M34.4738 59.5239C35.6666 59.9804 36.6267 60.6366 37.8635 61.0806C38.4259 61.2832 39.3635 61.7182 39.9255 61.5917C40.4875 61.4652 40.8233 61.2403 40.8236 60.6433C40.8254 60.1594 40.275 59.7302 39.9197 59.4863C38.977 58.835 38.1405 58.3697 37.4253 57.8562C37.0875 57.6173 37.3174 57.4403 37.4753 57.552C38.0613 57.8031 38.8246 57.9055 39.3876 58.2186C40.1547 58.6363 40.5974 59.4371 41.796 58.6173C41.9593 58.5148 42.0966 58.3758 42.1968 58.2111C42.5305 57.624 42.2781 56.946 41.8435 56.5141C41.4883 56.1889 41.0846 55.9213 40.6469 55.7207C41.4715 56.1141 42.3656 56.5336 43.2778 56.2162C43.5414 56.1407 43.7751 55.9852 43.9466 55.7711C44.118 55.5571 44.2188 55.2951 44.2349 55.0213C44.2447 54.3156 43.6276 53.8961 43.0526 53.5911C42.0334 53.0476 40.9657 52.6 39.8635 52.2543C39.4529 52.1277 39.011 52.1478 38.6136 52.3112C38.154 52.5053 37.6043 52.8358 37.5107 53.3431C37.4424 53.5975 37.4265 53.8632 37.4642 54.124C37.5018 54.3848 37.5921 54.6352 37.7296 54.86C37.7296 54.86 36.3346 54.7627 35.667 55.7615C35.2667 56.4015 35.1062 57.1627 35.2139 57.9098L34.4738 59.5239Z"
          fill="#FFBE9D"
        />
        <path
          d="M38.5327 52.0496C38.2588 51.9138 38.0331 51.6974 37.8859 51.4295C37.7387 51.1616 37.677 50.855 37.7092 50.551C37.743 50.0844 37.8308 49.6073 38.0463 49.4179C38.985 48.5877 44.119 47.3957 44.5222 48.4747C44.9254 49.5537 44.7894 50.2081 42.0384 50.9295C41.5539 51.0572 40.8008 51.2705 40.8008 51.2705L40.5709 52.4753L38.5327 52.0496Z"
          fill="#FFBE9D"
        />
        <path
          d="M41.265 56.1331C40.2 55.3628 38.9339 54.919 37.6211 54.856C37.8113 54.8094 38.0082 54.7971 38.2027 54.8197C39.1493 54.8678 40.0619 55.1876 40.8316 55.7406C40.9992 55.8434 41.1461 55.9765 41.265 56.1331Z"
          fill="#EB996E"
        />
        <path
          d="M40.6918 52.5314C40.6771 52.5622 40.2531 52.4397 39.7108 52.3578C39.4824 52.3198 39.252 52.2948 39.0207 52.283C38.8432 52.2779 38.7363 52.3016 38.73 52.2796C38.7238 52.2576 38.8291 52.2008 39.0146 52.181C39.2552 52.1596 39.4976 52.1701 39.7354 52.212C40.2903 52.2947 40.7136 52.4883 40.6918 52.5314Z"
          fill="#EB996E"
        />
        <path
          d="M41.0815 51.2047C40.7855 51.3724 40.455 51.4698 40.1155 51.4895C39.8717 51.5146 39.6253 51.491 39.3908 51.42C39.2128 51.3629 39.1193 51.2946 39.1269 51.2761C39.4467 51.3325 39.7719 51.3521 40.0962 51.3345C40.4214 51.2694 40.7505 51.226 41.0815 51.2047Z"
          fill="#EB996E"
        />
        <path
          d="M38.7808 58.7298C38.5428 58.6264 38.3164 58.4981 38.1054 58.3472C37.6035 57.9907 37.0455 57.7208 36.4544 57.5488C35.8574 57.4077 35.2292 57.4971 34.6954 57.7991C34.4825 57.9375 34.2886 58.1031 34.1187 58.2918C34.1187 58.2918 34.1426 58.2171 34.2221 58.1066C34.3355 57.9497 34.4768 57.815 34.6389 57.7091C34.9113 57.5384 35.215 57.4237 35.5322 57.3717C35.8494 57.3197 36.1738 57.3313 36.4864 57.406C37.095 57.5794 37.6644 57.8687 38.1632 58.2582C38.3819 58.3977 38.5885 58.5555 38.7808 58.7298Z"
          fill="#EB996E"
        />
        <path
          d="M46.9057 67.7623C46.3194 68.1712 45.7062 68.5401 45.0702 68.8664C44.4571 69.2368 43.8189 69.5638 43.1602 69.8451C43.7474 69.4359 44.3615 69.0667 44.9983 68.7399C45.6113 68.3714 46.2486 68.0448 46.9057 67.7623Z"
          fill="#EB996E"
        />
      </g>
      <g clipPath="url(#clip1_1428_22351)">
        <path
          d="M49.352 65.7473L50.3661 61.0663C50.2435 60.3242 50.1225 59.4399 50.0272 58.4124C49.9443 57.4873 50.246 56.3507 50.5917 55.4785C51.1298 54.1221 51.5768 53.9272 51.9938 53.1511C52.0358 53.0903 52.078 53.0079 52.1207 52.956C52.2127 52.8405 52.2801 52.7073 52.3186 52.5647C52.6152 51.7441 51.7033 51.049 50.6309 51.6064C49.8253 52.0234 48.5624 52.8366 47.3126 56.0567C47.2993 56.1054 47.2827 56.1463 47.2709 56.1913C46.7472 58.194 43.8341 54.3406 42.9575 52.8183C42.8139 52.5856 42.6198 52.3882 42.3896 52.2407C42.1594 52.0933 41.8989 51.9995 41.6276 51.9663C41.3562 51.9331 41.0808 51.9614 40.8218 52.0491C40.5629 52.1368 40.327 52.2816 40.1316 52.4728L35.8011 57.2735C35.2235 57.3415 34.6824 57.5915 34.2562 57.9873C33.9346 58.2792 33.7334 58.6805 33.6917 59.1129C33.6501 59.5452 33.7711 59.9776 34.0311 60.3255L39.9059 68.1257L40.6344 102.427L54.633 100.929L49.352 65.7473Z"
          fill="#FFBE9D"
        />
        <path
          d="M48.7273 48.6019L48.245 55.256L46.6843 55.4574L42.0776 64.1319C41.8901 64.4871 41.5713 64.7552 41.1892 64.8791C40.807 65.0029 40.3916 64.9728 40.0314 64.795L34.4175 60.7677C34.4175 60.7677 37.798 58.0319 37.9833 55.6897C38.1685 53.3476 39.4913 53.585 39.4913 53.585L40.8109 49.6319L48.7273 48.6019Z"
          fill="#EC5627"
        />
        <path
          d="M40.3835 38.9503C40.3835 38.9503 35.9134 39.7718 27.8217 36.6691C19.7301 33.5665 13.9239 27.3534 13.2444 27.6885C12.565 28.0235 8.877 27.8926 8.39888 43.085C7.92077 58.2773 11.4704 60.2529 12.6232 59.7461C13.776 59.2394 20.7488 53.6924 25.9219 52.1687C31.095 50.645 38.5268 50.54 38.5268 50.54L40.3835 38.9503Z"
          fill="#EC5627"
        />
        <path
          d="M51.9965 42.218L51.7211 45.4592L51.5994 46.9155C51.5181 47.8746 51.0719 48.7659 50.3527 49.4057C49.6336 50.0454 48.6963 50.3847 47.7343 50.3536L37.6808 50.9995L37.872 37.1907L48.5973 38.1598C49.5847 38.2508 50.4962 38.7284 51.1329 39.4886C51.7697 40.2488 52.0801 41.2299 51.9965 42.218Z"
          fill="#EC5627"
        />
        <g opacity="0.4">
          <path
            opacity="0.4"
            d="M51.9965 42.218L51.7211 45.4592L51.5994 46.9155C51.5181 47.8746 51.0719 48.7659 50.3527 49.4057C49.6336 50.0454 48.6963 50.3847 47.7343 50.3536L37.6808 50.9995L37.872 37.1907L48.5973 38.1598C49.5847 38.2508 50.4962 38.7284 51.1329 39.4886C51.7697 40.2488 52.0801 41.2299 51.9965 42.218Z"
            fill="black"
          />
        </g>
        <path
          d="M8.96447 43.1197C8.76206 51.3256 10.0591 58.0154 11.8601 58.0599C13.6611 58.1043 15.2893 51.4881 15.491 43.2805C15.6927 35.0729 14.3971 28.3865 12.5954 28.3404C10.7937 28.2942 9.16689 34.9138 8.96447 43.1197Z"
          fill="#EC5627"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M8.96447 43.1197C8.76206 51.3256 10.0591 58.0154 11.8601 58.0599C13.6611 58.1043 15.2893 51.4881 15.491 43.2805C15.6927 35.0729 14.3971 28.3865 12.5954 28.3404C10.7937 28.2942 9.16689 34.9138 8.96447 43.1197Z"
            fill="black"
          />
        </g>
        <path
          d="M54.3668 45.7327L51.6755 45.9899L51.9661 42.5757L54.2154 42.9306L54.3668 45.7327Z"
          fill="#EC5627"
        />
        <g opacity="0.6">
          <path
            opacity="0.6"
            d="M51.9661 42.5757L51.6755 45.9899L52.2291 45.9368L52.2344 42.6169L51.9661 42.5757Z"
            fill="black"
          />
        </g>
        <path
          d="M50.5993 43.9115C50.5976 44.0025 49.2178 44.0432 47.5207 44.007C45.8235 43.9709 44.4456 43.8542 44.4456 43.7639C44.4456 43.6736 45.8278 43.6339 47.5295 43.6762C49.2312 43.7184 50.6027 43.8198 50.5993 43.9115Z"
          fill="#EC5627"
        />
        <path
          d="M50.6304 41.9379C50.6246 42.0287 49.3609 42.0303 47.8073 41.9396C46.2537 41.8489 44.9995 41.7021 45.0046 41.6096C45.0097 41.5171 46.2724 41.5179 47.826 41.6086C49.3796 41.6993 50.6355 41.8454 50.6304 41.9379Z"
          fill="#EC5627"
        />
        <path
          d="M50.4171 46.0459C50.4225 46.0918 50.1124 46.1464 49.6008 46.1898C49.3465 46.2149 49.0417 46.2392 48.7045 46.2631C48.3674 46.287 47.9983 46.2542 47.6104 46.2434C46.0561 46.1891 44.8142 46.019 44.8164 45.9338C44.8185 45.8486 46.0815 45.8593 47.6182 45.9149C48.0037 45.9248 48.3711 45.9583 48.7035 45.9565C49.0359 45.9546 49.3336 45.9514 49.5868 45.9569C50.0954 45.973 50.4118 45.9999 50.4171 46.0459Z"
          fill="#EC5627"
        />
        <path
          d="M14.661 38.9091C14.661 38.9091 10.1771 38.2051 10.2608 43.8276C10.3445 49.4501 14.4148 48.6609 14.4148 48.6609C14.9557 45.4381 15.0386 42.1551 14.661 38.9091Z"
          fill="#EC5627"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M16.0257 31.5901C16.0479 31.5807 16.1363 31.9328 16.2754 32.5665C16.4145 33.2002 16.6098 34.1192 16.8231 35.2615L17.0389 37.1513C17.0763 37.4901 17.1153 37.8409 17.1561 38.2037L17.2177 38.7537C17.2368 38.9463 17.2324 39.1308 17.2409 39.322L17.3131 41.732C17.3195 41.9421 17.3241 42.1528 17.3313 42.3646C17.3384 42.5763 17.3112 42.7925 17.3074 43.0069L17.2396 44.32L17.1694 45.6321C17.151 45.8406 17.1515 46.0652 17.1249 46.2731C17.0983 46.481 17.0694 46.6979 17.0406 46.9007L16.7155 49.2919C16.6868 49.4807 16.6739 49.6687 16.6321 49.855L16.5136 50.3967L16.2846 51.4327L15.8645 53.2899C15.5305 54.4004 15.2356 55.2896 15.0343 55.9064C14.9451 56.232 14.8288 56.5497 14.6866 56.856C14.7345 56.5225 14.8105 56.1936 14.9139 55.8729C15.0885 55.2312 15.3369 54.3316 15.6408 53.2259L16.014 51.3764L16.2252 50.3459C16.263 50.1693 16.2995 49.9894 16.3369 49.807C16.3743 49.6246 16.3821 49.4387 16.4117 49.2476L16.724 46.8638L16.8038 46.2401C16.8324 46.0273 16.8304 45.8275 16.8464 45.6039L16.9144 44.3008C16.9301 43.8626 16.9606 43.4262 16.984 42.9969C16.9939 42.792 17.0165 42.5697 17.0084 42.3604L16.994 41.7322L16.9453 39.3444C16.9364 39.1474 16.9439 38.9656 16.9294 38.7791C16.9149 38.5926 16.8933 38.413 16.8751 38.232L16.7741 37.1848L16.5959 35.3032L16.1514 32.6008C16.0808 32.2681 16.0388 31.9299 16.0257 31.5901Z"
            fill="black"
          />
        </g>
        <g opacity="0.4">
          <path
            opacity="0.4"
            d="M34.7266 61.0816C35.6069 61.7746 36.5039 62.4852 37.5387 62.8883C38.5736 63.2914 39.7903 63.3747 40.7909 62.8292C41.7914 62.2837 42.3852 61.0299 41.9612 59.9965C42.6076 60.1575 43.2786 59.6404 43.4908 59.0061C43.7031 58.3719 43.5661 57.6758 43.354 57.0422C44.1891 57.1543 44.9876 56.451 45.1663 55.6279C45.2317 55.2107 45.2045 54.7843 45.0865 54.3789C44.9686 53.9734 44.7627 53.599 44.4836 53.2821C43.9191 52.6553 43.265 52.1154 42.5425 51.68C43.0825 51.5013 43.6359 51.3107 43.9974 50.8842C44.3589 50.4577 44.4248 49.7099 43.9601 49.3891C43.6731 49.2217 43.335 49.1643 43.0088 49.2276C42.0916 49.3072 41.4459 49.3521 40.6457 49.8037C40.4525 49.8853 41.9176 51.8761 37.9269 57.1242C35.5561 60.2329 34.7266 61.0816 34.7266 61.0816Z"
            fill="black"
          />
        </g>
        <path
          d="M47.9328 59.2596C47.9427 59.2831 47.5615 59.3641 46.9987 59.6201C46.2663 59.9501 45.6124 60.432 45.0804 61.0338C44.5483 61.6356 44.1501 62.3436 43.9123 63.1109C43.7271 63.6999 43.7065 64.081 43.667 64.0822C43.6543 63.9916 43.6559 63.8996 43.6718 63.8094C43.6997 63.5633 43.7459 63.3195 43.8101 63.0802C44.0281 62.2862 44.4251 61.5527 44.9708 60.9361C45.5165 60.3195 46.1963 59.8363 46.958 59.5235C47.1863 59.4287 47.4223 59.3538 47.6634 59.2995C47.7501 59.2704 47.8413 59.2569 47.9328 59.2596Z"
          fill="#EB996E"
        />
        <path
          d="M34.4738 59.5239C35.6666 59.9804 36.6267 60.6366 37.8635 61.0806C38.4259 61.2832 39.3635 61.7182 39.9255 61.5917C40.4875 61.4652 40.8233 61.2403 40.8236 60.6433C40.8254 60.1594 40.275 59.7302 39.9197 59.4863C38.977 58.835 38.1405 58.3697 37.4253 57.8562C37.0875 57.6173 37.3174 57.4403 37.4753 57.552C38.0613 57.8031 38.8246 57.9055 39.3876 58.2186C40.1547 58.6363 40.5974 59.4371 41.796 58.6173C41.9593 58.5148 42.0966 58.3758 42.1968 58.2111C42.5305 57.624 42.2781 56.946 41.8435 56.5141C41.4883 56.1889 41.0846 55.9213 40.6469 55.7207C41.4715 56.1141 42.3656 56.5336 43.2778 56.2162C43.5414 56.1407 43.7751 55.9852 43.9466 55.7711C44.118 55.5571 44.2188 55.2951 44.2349 55.0213C44.2447 54.3156 43.6276 53.8961 43.0526 53.5911C42.0334 53.0476 40.9657 52.6 39.8635 52.2543C39.4529 52.1277 39.011 52.1478 38.6136 52.3112C38.154 52.5053 37.6043 52.8358 37.5107 53.3431C37.4424 53.5975 37.4265 53.8632 37.4642 54.124C37.5018 54.3848 37.5921 54.6352 37.7296 54.86C37.7296 54.86 36.3346 54.7627 35.667 55.7615C35.2667 56.4015 35.1062 57.1627 35.2139 57.9098L34.4738 59.5239Z"
          fill="#FFBE9D"
        />
        <path
          d="M38.5327 52.0496C38.2588 51.9138 38.0331 51.6974 37.8859 51.4295C37.7387 51.1616 37.677 50.855 37.7092 50.551C37.743 50.0844 37.8308 49.6073 38.0463 49.4179C38.985 48.5877 44.119 47.3957 44.5222 48.4747C44.9254 49.5537 44.7894 50.2081 42.0384 50.9295C41.5539 51.0572 40.8008 51.2705 40.8008 51.2705L40.5709 52.4753L38.5327 52.0496Z"
          fill="#FFBE9D"
        />
        <path
          d="M41.265 56.1331C40.2 55.3628 38.9339 54.919 37.6211 54.856C37.8113 54.8094 38.0082 54.7971 38.2027 54.8197C39.1493 54.8678 40.0619 55.1876 40.8316 55.7406C40.9992 55.8434 41.1461 55.9765 41.265 56.1331Z"
          fill="#EB996E"
        />
        <path
          d="M40.6918 52.5314C40.6771 52.5622 40.2531 52.4397 39.7108 52.3578C39.4824 52.3198 39.252 52.2948 39.0207 52.283C38.8432 52.2779 38.7363 52.3016 38.73 52.2796C38.7238 52.2576 38.8291 52.2008 39.0146 52.181C39.2552 52.1596 39.4976 52.1701 39.7354 52.212C40.2903 52.2947 40.7136 52.4883 40.6918 52.5314Z"
          fill="#EB996E"
        />
        <path
          d="M41.0815 51.2047C40.7855 51.3724 40.455 51.4698 40.1155 51.4895C39.8717 51.5146 39.6253 51.491 39.3908 51.42C39.2128 51.3629 39.1193 51.2946 39.1269 51.2761C39.4467 51.3325 39.7719 51.3521 40.0962 51.3345C40.4214 51.2694 40.7505 51.226 41.0815 51.2047Z"
          fill="#EB996E"
        />
        <path
          d="M38.7808 58.7298C38.5428 58.6264 38.3164 58.4981 38.1054 58.3472C37.6035 57.9907 37.0455 57.7208 36.4544 57.5488C35.8574 57.4077 35.2292 57.4971 34.6954 57.7991C34.4825 57.9375 34.2886 58.1031 34.1187 58.2918C34.1187 58.2918 34.1426 58.2171 34.2221 58.1066C34.3355 57.9497 34.4768 57.815 34.6389 57.7091C34.9113 57.5384 35.215 57.4237 35.5322 57.3717C35.8494 57.3197 36.1738 57.3313 36.4864 57.406C37.095 57.5794 37.6644 57.8687 38.1632 58.2582C38.3819 58.3977 38.5885 58.5555 38.7808 58.7298Z"
          fill="#EB996E"
        />
        <path
          d="M46.9057 67.7623C46.3194 68.1712 45.7062 68.5401 45.0702 68.8664C44.4571 69.2368 43.8189 69.5638 43.1602 69.8451C43.7474 69.4359 44.3615 69.0667 44.9983 68.7399C45.6113 68.3714 46.2486 68.0448 46.9057 67.7623Z"
          fill="#EB996E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1428_22351">
          <path
            d="M37.625 0H116.828V64.9589C116.828 86.8301 99.0977 104.56 77.2264 104.56C55.3552 104.56 37.625 86.8301 37.625 64.9589V0Z"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_1428_22351">
          <rect
            width="75"
            height="61"
            fill="white"
            transform="translate(0 15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PromotionAvatarSVG;
