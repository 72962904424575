import styled from "styled-components";

const CCIconLogoContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
`;

export default CCIconLogoContainer;
