import { Fragment } from "react";
import styled from "styled-components";
import StatisticsCard from "./statistics-card";
import { device } from "../../../screens";
import HeaderTemplate from "../../commons/header-template";
import { sanitizeValueHTML } from "utils/constant";

const StatisticsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;

  column-gap: 24px !important;
  row-gap: 28px !important;
  margin-top: 28px !important;

  @media ${device.xs} {
    column-gap: 24px !important;
    row-gap: 32px !important;
    margin-top: 32px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    column-gap: 25px !important;
    row-gap: 42px !important;
    margin-top: 36px;
  }
`;
const StatisticsItem = styled.div`
  width: 100%;
  margin-top: 0 !important;

  ${({ hidden }) => (hidden ? `display: none;` : "")}
`;

const Statistics = ({ data }) => {
  return (
    <Fragment>
      <div style={{ width: "100%", height: "100%" }}>
        <HeaderTemplate
          family={data.font?.family}
          width={data.width}
          dangerouslySetInnerHTML={{ __html: sanitizeValueHTML(data.title) }}
        />
        <StatisticsContainer responsive={data.width}>
          {data.stats.map((stat, statIndex) => (
            <StatisticsItem
              key={`statistics-${statIndex}`}
              hidden={!stat.visible}
            >
              <StatisticsCard
                statistics={stat}
                color={data?.button_colour}
                width={data.width}
              />
            </StatisticsItem>
          ))}
        </StatisticsContainer>
      </div>
    </Fragment>
  );
};

export default Statistics;
