import { getIcon } from "assets/icons/icons-list";
import BulbIconSVG from "assets/svg/bulb-icon";
import FileIconSVG from "assets/svg/file-icon";
import Button from "components/button/button";
import ButtonBigIcon from "components/button/button-big-with-icons";
import FileUpload from "components/file-upload/file-upload";
import InputRange from "components/input-range/input-range";
import Input from "components/input/input";
import Toggle from "components/toggle/toggle";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { TabPanel } from "react-headless-tabs";
import toast from "react-hot-toast";
import { QUOTESUGGESTIONS } from "utils/constant";
import useDebounce from "utils/customhooks";
import CampaignIconSelector from "../icons/campaign-icon-selector";
import CampaignPopupPopoutTab from "../popup/campaign-popup-popout-tab";
import PopoutImageUploadIconSVG from "assets/svg/popout-image-upload-icon";

export default function CampaignPopupTab({ id }) {
  const { http } = global.services;
  const {
    popup,
    setPopup,
    selectedTab,
    setSelectedTab,
    tabs,
    setTabs,
    image,
    setImage,
  } = useContext(Context);
  const [selectedIcon, setSelectedIcon] = useState(
    popup.icon ? JSON.parse(popup.icon) : {}
  );
  const [state, setState] = useState({
    validationErrors: null,
  });
  const [loading, setLoading] = useState(false);
  const [suggestion, setSuggestion] = useState(0);

  const quote = useDebounce(popup.quote, 1200);
  const tab_image_size = useDebounce(popup.tab_image_size, 500);
  const container_width = useDebounce(popup.container_width, 500);
  // ====================== start: functions below ===========================//
  const handleSuggestion = (type) => {
    setState((prev) => ({
      validationErrors: { ...prev.validationErrors, quote: null },
    }));
    const quote = QUOTESUGGESTIONS;
    const size = _.size(QUOTESUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = quote[0];
      } else {
        value = quote[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = quote[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = quote[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = quote[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      setPopup((prev) => ({ ...prev, quote: value }));
    }
  };

  const handleChange = (e, type) => {
    const { name, value, checked } = e.target;
    if (type === "toggle") {
      let toggleValue = checked ? "above" : "below";
      setPopup((prev) => ({ ...prev, [name]: toggleValue }));
      handleOnSave({ [name]: toggleValue });
    } else {
      setPopup((prev) => ({ ...prev, [name]: value }));
    }
    setState((prev) => ({
      validationErrors: { ...prev.validationErrors, [name]: null },
    }));
  };

  const handleChangeIcon = (e) => {
    setPopup((prev) => ({ ...prev, icon: e ? JSON.stringify(e) : null }));
    setSelectedIcon(e);
    handleOnSave({ icon: e ? JSON.stringify(e) : null });
  };

  const handleOnBlur = () => {
    handleOnSave();
  };

  const handleOnSave = async (data = {}) => {
    setLoading(true);
    const params = {
      ...popup,
      ...data,
      icon_id: null,
    };
    try {
      if (popup.id) {
        const updatePopup = await http.put(`popups/${popup?.id}`, params);
        if (updatePopup) {
          setPopup((prev) => ({
            ...prev,
            ...updatePopup?.data?.data,
          }));
        }
      }
    } catch (error) {
      if (error.status === 422)
        setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));
      toast.error("It appears that something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveDebounce = async (data = {}) => {
    setLoading(true);
    const params = {
      ...popup,
      ...data,
      icon_id: null,
    };
    try {
      if (popup.id) {
        setPopup((prev) => ({
          ...prev,
          ...params,
        }));
        await http.put(`popups/${popup?.id}`, params);
      }
    } catch (error) {
      if (error.status === 422)
        setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));
      toast.error("It appears that something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const onUploadThumbnail = async (e) => {
    setImage((prev) => ({ ...prev, uploading: true }));
    setState((prev) => ({
      validationErrors: {
        ...prev.validationErrors,
        quote: null,
        image: null,
      },
    }));
    const formData = new FormData();
    const file = e.target.files[0];

    try {
      if (file.size > 250000) {
        setImage((prev) => ({ ...prev, uploading: false }));
        return toast.error("Oops! The file is too large.");
      }

      /* Remove old image when attempting to re-upload new image */
      if (image.image_url && image.image_key) {
        await http.delete(`medias/${image.id}`, {
          key: image.image_key,
        });
      }

      formData.append("file", file);
      formData.append("type", "campaigns");
      const uploadResult = await http.post("medias", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const result = uploadResult.data.data;

      if (result) {
        setImage((prev) => ({
          ...prev,
          id: result.id,
          image_url: result.presigned_url,
          image_key: result.key,
        }));
        setPopup((prev) => ({ ...prev, media_id: result.id }));

        handleOnSave({ media_id: result.id, tab_image_size: 255 });
      }
    } catch (error) {
      toast.error(
        "An unknown error occured. Please refresh the page and try again."
      );
    } finally {
      setImage((prevState) => ({ ...prevState, uploading: false }));
      e.target.value = "";
    }
  };

  const handleRemoveImage = async () => {
    const imagePayload = _.clone(image);
    setPopup((prev) => ({
      ...prev,
      media_id: null,
      image: null,
      tab_image_size: null,
    }));
    setImage({
      uploading: false,
      id: null,
      image_url: null,
      image_key: null,
    });
    handleOnSave({ media_id: null, tab_image_size: 0 });
    try {
      const params = {
        key: imagePayload.image_key,
      };

      if (imagePayload.image_url && imagePayload.image_key) {
        const removeImageResult = await http.delete(
          `medias/${imagePayload.id}`,
          params
        );

        if (removeImageResult.data?.success) {
          setPopup((prev) => ({ ...prev, tab_image_size: 0 }));
          //toast.success("Image removed successfully");
        }
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    }
  };

  const handleChangeStep = () => {
    if (!popup.quote && tabs === "layout") {
      setState((prev) => ({
        validationErrors: {
          ...prev.validationErrors,
          quote: "Qoute is required",
        },
      }));
    } else if (!image.id && tabs === "image") {
      setState((prev) => ({
        validationErrors: {
          ...prev.validationErrors,
          image: "Image is required",
        },
      }));
    } else {
      setSelectedTab("template");
      handleOnSave();
    }
  };

  const handleSlider = (val) => {
    setPopup((prev) => ({ ...prev, tab_image_size: val }));
  };

  const handleContainerWidth = (val) => {
    setPopup((prev) => ({ ...prev, container_width: val }));
  };

  const handleChangeTab = (tab) => {
    setTabs(tab);
    setPopup((prev) => ({ ...prev, tab_type: tab }));
    // save it for checkpoint
    handleOnSave({ tab_type: tab });
  };
  // ====================== end: functions here ===========================//

  useEffect(() => {
    handleSaveDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote, tab_image_size, container_width]);

  useEffect(() => {
    if (id) {
      if (!_.isEmpty(popup.image)) {
        setImage({
          uploading: false,
          id: popup.image?.id,
          image_url: popup.image?.url,
          image_key: popup.image?.key,
        });
        let imageH = parseInt(popup.image?.width || 0);
        let maxSlider = 400;

        if (!popup?.tab_image_size) {
          setPopup((prev) => ({
            ...prev,
            tab_image_size: imageH < maxSlider ? imageH : maxSlider,
          }));
        }
      }
      setSelectedIcon(popup.icon ? JSON.parse(popup.icon) : {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup.icon, popup.image, id]);

  // Update Context for tab type and image on Load
  useEffect(() => {
    if (!popup.image) {
      setImage({
        uploading: false,
        id: null,
        image_url: null,
        image_key: null,
      });
    }
    setTabs(popup.tab_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full pb-7">
      <div className="w-full">
        <p className="font-medium text-2xl">Create Your Popout Tab</p>
      </div>
      <div className="w-full pt-5">
        {/* left */}
        <div className="relative campaign-left-max">
          <div
            className="bg-white rounded-xl relative"
            style={{ height: "fit-content" }}
          >
            <div className="p-5">
              {/* first */}
              <div>
                <p className="text-xl pl-1 pb-2">
                  Let’s get your customer’s attention
                </p>
                <p className="text-sm pl-1 pb-4">
                  Type in the words that you want to show on your popout tab or
                  get a suggestion from our generator. Or if you have your own
                  graphic feel free to upload it.
                </p>
              </div>
              {/* react tabs */}
              <div>
                <div className="border-b border-tabBorder">
                  <div className="grid grid-cols-2 gap-4 mb-7">
                    <ButtonBigIcon
                      selected={tabs === "layout"}
                      buttonName={`Text & Icon`}
                      Icon={FileIconSVG}
                      onClick={() => handleChangeTab("layout")}
                    />
                    <ButtonBigIcon
                      selected={tabs === "image"}
                      buttonName={`Image`}
                      Icon={PopoutImageUploadIconSVG}
                      onClick={() => handleChangeTab("image")}
                    />
                  </div>
                </div>
                <div>
                  {/* Layout */}
                  <TabPanel hidden={tabs !== "layout"}>
                    <div className="mt-5">
                      <div className="flex flex-row space-x-3">
                        <Input
                          inputContainerClass="input-container"
                          outsideLabel={"Type Your Text Here: "}
                          labelClass="mb-5"
                          value={popup.quote || ""}
                          placeholder={`e.g. “Get A Quick Quote”`}
                          name="quote"
                          onChange={handleChange}
                          onBlur={handleOnBlur}
                          type="text"
                          errorMessage={state.validationErrors?.quote}
                        />
                        <div className="relative">
                          <CampaignIconSelector
                            label="Choose An Icon"
                            onClick={(e) => handleChangeIcon(e)}
                            Icon={getIcon(selectedIcon?.id)}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row text-sm space-x-3">
                        <button
                          type="button"
                          className={`bg-default rounded-full py-1 px-4`}
                          disabled={loading}
                          onClick={() => handleSuggestion("prev")}
                        >
                          Previous Suggestion
                        </button>
                        <button
                          type="button"
                          className={`bg-default rounded-full py-1 px-4`}
                          disabled={loading}
                          onClick={() => handleSuggestion("next")}
                        >
                          Next Suggestion
                        </button>
                      </div>
                      <div className="mt-7">
                        <p className="pb-2 pl-1">Choose Icon Position:</p>
                        <Toggle
                          name="icon_position"
                          background="bgdefault"
                          checked={popup.icon_position === "below"}
                          onChange={(e) => handleChange(e, "toggle")}
                          icon="switch"
                          className="w-60 h-10"
                          custom="popup"
                          labelLeft="Above Text"
                          labelRight="Below Text"
                        />
                      </div>
                      <div className="w-full mt-6.3">
                        <p className="font-light">Container Width</p>
                        <div className="mt-2.5">
                          <InputRange
                            className="input-range-slider"
                            hideNumber={true}
                            buttons={true}
                            onClickButton={(value) => {
                              handleContainerWidth(value);
                              handleOnSave({ container_width: value });
                            }}
                            name="rating"
                            min={120}
                            max={190}
                            step={1}
                            defaultValue={popup.container_width || 120}
                            onChange={(e) => {
                              handleContainerWidth(Number(e?.target?.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  {/* Image */}
                  <TabPanel hidden={tabs !== "image"}>
                    <div className="relative">
                      <FileUpload
                        title="Upload Popout Tab Image"
                        leftContainerClass={"image-view-popout-tab"}
                        leftContentClass="w-full rounded-md bg-default"
                        imageClass="image-view-height-popout-tab object-cover"
                        otherLabelClass="pt-3"
                        wrapperClass="mt-5"
                        accept="image/*"
                        width={"70%"} // default: 100%, width for right section label
                        image_url={image.image_url} // default: null, image fetched when uploaded to server
                        uploading={image?.uploading} // default: false, upload loader
                        maxFileSize="250KB"
                        recommendedImageSize="120 x 160px"
                        allowedFiles="SVG, JPG, PNG, GIF"
                        onChange={onUploadThumbnail}
                        removable={true}
                        onRemoveImage={handleRemoveImage}
                        tutorial={
                          <>
                            {selectedTab === "popout" && tabs === "image" && (
                              <CampaignPopupPopoutTab
                                type="editor-image-tab"
                                className="relative mt-2 p-2 box-shadow"
                                actveColor="bg-black"
                              />
                            )}
                          </>
                        }
                        name="popup-upload"
                        id="popup-upload"
                      />
                      <span className="text-xs text-red">
                        {state.validationErrors?.image
                          ? `*${state.validationErrors?.image}`
                          : ""}
                      </span>
                      {/* slider zoom */}
                      <div className="w-full mt-8">
                        <p className="font-light">Image Size</p>
                        <div className="mt-2.5">
                          <InputRange
                            className="input-range-slider"
                            hideNumber={true}
                            buttons={true}
                            onClickButton={(value) => {
                              handleSlider(value);
                              handleOnSave({ tab_image_size: value });
                            }}
                            name="rating"
                            min={110}
                            max={400}
                            step={5}
                            defaultValue={popup.tab_image_size || 110}
                            onChange={(e) => {
                              handleSlider(Number(e?.target?.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="border-t border-tabBorder">
              <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
                <Button
                  buttonName="PREVIOUS"
                  buttonClass="bg-transparent rounded-full py-3"
                  buttonType="transparent"
                  buttonTextClass="text-sm font-bold"
                  onClick={() => setSelectedTab("basic")}
                />
                <Button
                  buttonName="NEXT"
                  buttonClass="bg-primary rounded-full py-3 px-12 relative"
                  buttonType="primary"
                  onClick={handleChangeStep}
                  disabled={!_.isEmpty(state.validationErrors?.quote)}
                />
              </div>
            </div>
          </div>
          {/* pro tips */}
          <div className="bg-white rounded-xl relative mt-6">
            <div className="w-full p-5">
              <div className="border-b border-tabBorder">
                <div className="flex pl-2 pb-3">
                  <div className="text-secondary">
                    <BulbIconSVG />
                  </div>
                  <p className="text-sm font-bold pl-4">PRO TIPS</p>
                </div>
              </div>
              <div className="p-5">
                <ul className="list-disc text-tipsColor">
                  <li className="py-1">
                    Keep your words <strong>short</strong> and{" "}
                    <strong>sharp</strong>
                  </li>
                  <li className="py-1">
                    Think to yourself, would those words make you want to click
                    on the popout tab?
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
