const CampaignSwitchIconSVG = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="switchOnIconTitle"
    stroke="#000000"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    color="#000000"
  >
    <title id="switchOnIconTitle">Switch on</title>{" "}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 10C18.1046 10 19 10.8954 19 12C19 13.1046 18.1046 14 17 14C15.8954 14 15 13.1046 15 12C15 10.8954 15.8954 10 17 10Z"
    />
    <path d="M17 7C19.7614 7 22 9.23858 22 12V12C22 14.7614 19.7614 17 17 17L8 17C5.23858 17 3 14.7614 3 12V12C3 9.23858 5.23858 7 8 7L17 7Z" />
  </svg>
);

export default CampaignSwitchIconSVG;
