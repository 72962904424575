import json from "assets/json-files/popup-popout.json";
import CampaignPopoutIconSVG from "assets/svg/campaign-popout-icon";
import Button from "components/button/button";
import PopupComponent from "components/popup/popup";
import PopupContent from "components/popup/popup-content";
import { useRef } from "react";

export default function CampaignPopupPopoutTab({
  margin = "0",
  className = "relative p-2 ml-5 box-shadow bottom-2",
  actveColor = "bg-primary",
  position = "right center",
  footerVideo = false,
  divMargin = "0",
  remainArrow = false,
  type = null,
}) {
  const tutorialContent = json;
  const ref = useRef();
  const toggleTooltip = () => {
    ref.current.toggle();
  };

  return tutorialContent.map((content, index) => {
    return (
      // Should depend on its type
      content.type === type && (
        <PopupComponent
          key={index}
          activeColor={actveColor}
          position={position}
          className={"animate-btn " + className}
          styles={content.styles}
          closeOnDocumentClick={true}
          ref={ref}
        >
          <PopupContent
            width="342px"
            margin={margin}
            divMargin={divMargin}
            remainArrow={remainArrow}
            footer={
              <span className="w-full">
                {/* {footerVideo && (
                  <Button
                    buttonName={content.videoButtonText}
                    buttonTextClass="text-black text-xs font-bold flex px-3"
                    buttonClass="bg-transparent rounded-full py-2.5 border border-black mr-2"
                    buttonType="transparent"
                    buttonWidth="65%"
                    iconAlign="left"
                    icon={<FaPlay className="mr-0.5 mt-0.5" />}
                    onClick={showVideo}
                  />
                )} */}
                <Button
                  buttonName={content.buttonText}
                  buttonTextClass="text-white text-xs font-bold"
                  buttonClass={`bg-primary rounded-full py-2.5 ${
                    !footerVideo && "float-right"
                  }`}
                  buttonWidth="30%"
                  onClick={toggleTooltip}
                />
              </span>
            }
          >
            {/* content */}
            <div className="px-6 flex flex-row">
              <div>
                <CampaignPopoutIconSVG />
              </div>
              <div className="pt-3 pl-4">
                <p className="leading-6 mb-2 font-semibold">{content.title}</p>
                {content.list_items.length > 0 && (
                  <ul className="pl-5 pt-2 list-disc text-sm">
                    {content.list_items.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            content.list_items.length !== index + 1 && "pb-2"
                          }`}
                        >
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            {/* content */}
          </PopupContent>
        </PopupComponent>
      )
    );
  });
}
