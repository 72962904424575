import React from "react";
import Input from "components/input/input";
import ContactNumberIconSVG from "assets/svg/contact-number-icon";
import EmailIconSVG from "assets/svg/email-icon";
import MarkerIconSVG from "assets/svg/marker-icon";
import InputMapField from "components/input/input-map";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import update from "immutability-helper";
import _ from "lodash";

export default function MultipleAddressesDetailsTab({
  onHandleBlur,
  content,
  setContent,
  state,
}) {
  const handleValidationMessage = (index, key) => {
    if (state.validationErrors?.contacts) {
      let contacts = state.validationErrors?.contacts;
      let contact = { ...contacts[index]?.[key] };
      if (Object.keys(contact).length > 0) contact.value[0] = "";
    }
  };

  const handleInputWithObjectChangeAddress = async (value, index) => {
    const geocodePlaceID = await geocodeByPlaceId(value.value.place_id);

    const payload = _.clone(content);
    let obj = update(payload, {
      contacts: {
        [index]: {
          address: {
            pin: { $set: value.label },
            latitude: { $set: geocodePlaceID[0].geometry.location.lat() },
            longitude: { $set: geocodePlaceID[0].geometry.location.lng() },
          },
        },
      },
    });

    setContent(obj);
    handleValidationMessage(index, "address");
  };

  return (
    <div className="w-full">
      <div className="mt-5">
        {/* Layout */}
        {(content.contacts || []).map((item, index) => {
          return (
            <div className="w-full" key={index}>
              <p className="pl-1 font-bold">CONTACT {index + 1}</p>
              <div className="w-full">
                <Input
                  inputContainerClass="input-container-2"
                  labelClass="input-label-xs"
                  paddingTop="1.5rem"
                  value={item.contact_title.value}
                  visible={item.contact_title.visible}
                  placeholder="e.g. Melbourne"
                  masked={true}
                  label="Contact Title"
                  name="contact_title"
                  type="text"
                  onBlur={onHandleBlur}
                  onChange={(e) => {
                    content.contacts[index].contact_title.value =
                      e.target.value;
                    setContent({ ...content });
                  }}
                  onChangeVisible={() => {
                    content.contacts[index].contact_title.visible =
                      !item.contact_title.visible;
                    setContent({ ...content });
                    onHandleBlur(content);
                  }}
                />
                <Input
                  inputContainerClass="input-container-3"
                  labelClass="input-label-xs"
                  paddingTop="1.5rem"
                  placeholder="e.g. 375 Thoroughfare, Lake Sabina, Queensland, 3126"
                  value={item.address.value}
                  visible={item.address.visible}
                  masked={true}
                  label="Address Label"
                  name="address"
                  showCounter={true}
                  maxLength={60}
                  type="text"
                  onBlur={onHandleBlur}
                  inputIcon={<MarkerIconSVG className="w-5 h-5 text-gray" />}
                  iconPosition="left"
                  onChange={(e) => {
                    content.contacts[index].address.value = e.target.value;
                    setContent({ ...content });
                    handleValidationMessage(index, "address");
                  }}
                  onChangeVisible={() => {
                    content.contacts[index].address.visible =
                      !item.address.visible;
                    if (!item.address.visible) {
                      let payload = content.contacts[index].address;
                      let obj = update(payload, {
                        pin: { $set: "" },
                        latitude: { $set: 0 },
                        longitude: { $set: 0 },
                      });
                      content.contacts[index].address = obj;
                    }
                    setContent({ ...content });
                    onHandleBlur(content);
                  }}
                  errorMessage={
                    state.validationErrors?.contacts
                      ? state.validationErrors?.contacts[index]?.address
                          ?.value[0] || ""
                      : ""
                  }
                />
                <div className="pr-3.5">
                  {content.contacts[index]?.address?.visible && (
                    <InputMapField
                      inputContainerClass="input-container-map"
                      placeholder="e.g. 375 Thoroughfare, Lake Sabina, Queensland"
                      value={item.address.pin}
                      label="Pin Location"
                      name="pin"
                      labelClass="input-label-xs"
                      paddingTop="1.5rem"
                      type="text"
                      inputIcon={
                        <MarkerIconSVG className="w-5 h-5 text-gray" />
                      }
                      iconPosition="left"
                      onChange={(e) => {
                        handleInputWithObjectChangeAddress(e, index);
                        handleValidationMessage(index, "pin");
                      }}
                      onBlur={onHandleBlur}
                      errorMessage={
                        state.validationErrors?.contacts
                          ? state.validationErrors?.contacts[index]?.pin
                              ?.value[0] || ""
                          : ""
                      }
                    />
                  )}
                </div>
                <Input
                  inputContainerClass="input-container-2"
                  labelClass="input-label-xs"
                  paddingTop="1.5rem"
                  placeholder="e.g. (03) 9827 2836"
                  value={item.contact_number.value}
                  visible={item.contact_number.visible}
                  masked={true}
                  label="Contact Number"
                  name="contact_number"
                  type="text"
                  onBlur={onHandleBlur}
                  inputIcon={
                    <ContactNumberIconSVG className="w-5 h-5 text-gray" />
                  }
                  iconPosition="left"
                  onChange={(e) => {
                    content.contacts[index].contact_number.value =
                      e.target.value;
                    setContent({ ...content });
                    handleValidationMessage(index, "contact_number");
                  }}
                  onChangeVisible={() => {
                    content.contacts[index].contact_number.visible =
                      !item.contact_number.visible;
                    setContent({ ...content });
                    onHandleBlur(content);
                  }}
                  errorMessage={
                    state.validationErrors?.contacts
                      ? state.validationErrors?.contacts[index]?.contact_number
                          ?.value[0] || ""
                      : ""
                  }
                />
                <Input
                  inputContainerClass="input-container-2"
                  labelClass="input-label-xs"
                  paddingTop="1.5rem"
                  value={item.email_address.value}
                  visible={item.email_address.visible}
                  masked={true}
                  label="Email Address"
                  name="email_address"
                  placeholder="e.g. melbourne@conversioncow.com"
                  type="text"
                  onBlur={onHandleBlur}
                  inputIcon={<EmailIconSVG className="w-5 h-5 text-gray" />}
                  iconPosition="left"
                  onChange={(e) => {
                    content.contacts[index].email_address.value =
                      e.target.value;
                    setContent({ ...content });
                    handleValidationMessage(index, "email_address");
                  }}
                  onChangeVisible={() => {
                    content.contacts[index].email_address.visible =
                      !item.email_address.visible;
                    setContent({ ...content });
                    onHandleBlur(content);
                  }}
                  errorMessage={
                    state.validationErrors?.contacts
                      ? state.validationErrors?.contacts[index]?.email_address
                          ?.value[0] || ""
                      : ""
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
