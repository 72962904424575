const BulbIconSVG = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 15H11.25V16.5H6.75V15ZM12.6795 10.284C13.452 9.3795 14.25 8.44425 14.25 6.75C14.25 3.85575 11.8943 1.5 9 1.5C6.10575 1.5 3.75 3.85575 3.75 6.75C3.75 8.46375 4.55025 9.396 5.32575 10.2975C5.59425 10.611 5.8725 10.9358 6.13875 11.3093C6.24675 11.4638 6.42375 12.0563 6.582 12.75H6V14.25H12V12.75H11.4195C11.5793 12.0548 11.757 11.4608 11.8643 11.3063C12.1283 10.929 12.4088 10.6013 12.6795 10.284ZM10.6365 10.4438C10.311 10.9065 10.0395 12 9.882 12.75H8.11875C7.962 11.9985 7.68975 10.9028 7.3605 10.44C7.07907 10.0533 6.77974 9.67985 6.4635 9.321C5.733 8.4705 5.25 7.908 5.25 6.75C5.25 4.68225 6.93225 3 9 3C11.0678 3 12.75 4.68225 12.75 6.75C12.75 7.89075 12.2678 8.4555 11.5388 9.30975C11.259 9.63825 10.9418 10.0095 10.6365 10.4438V10.4438Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BulbIconSVG;
