const PhotographIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.1931 21.5527C37.8483 20.8761 37.1014 20.488 36.1439 20.488H27.7023L30.2078 2.12754C30.3198 1.30547 30.2253 0.773438 29.9015 0.402637C29.678 0.146777 29.3568 0 29.0199 0C28.2979 0 27.8233 0.614355 27.4905 1.14951L12.046 25.9842C11.5493 26.7836 11.4786 27.6275 11.8523 28.2994C12.2258 28.9715 12.9801 29.3568 13.9217 29.3568H21.1597L16.9823 47.7779C16.8541 48.3429 16.7588 49.0562 17.1634 49.5629C17.3852 49.8405 17.7155 49.9998 18.07 49.9999C18.0701 49.9999 18.0701 49.9999 18.0702 49.9999C18.7436 49.9999 19.2342 49.4437 19.5866 48.9592L37.8504 23.8357C38.4128 23.0613 38.5377 22.2293 38.1931 21.5527ZM36.1994 22.636L19.5973 45.4733L23.4324 28.5621C23.5009 28.2595 23.4282 27.9421 23.2346 27.6997C23.041 27.4572 22.7476 27.316 22.4372 27.316H13.9216C13.8065 27.316 13.7239 27.3028 13.6703 27.2887C13.6865 27.2358 13.7188 27.159 13.7792 27.0616L27.8284 4.471L25.5221 21.3705C25.4822 21.6625 25.5706 21.9574 25.7644 22.1795C25.9581 22.4015 26.2385 22.5289 26.5331 22.5289H36.1439C36.1902 22.5289 36.2311 22.5309 36.2669 22.5341C36.2487 22.5647 36.2264 22.5988 36.1994 22.636Z"
        fill="currentColor"
      />
      <path
        d="M23.298 16.4176C22.8185 16.1214 22.1897 16.2699 21.8935 16.7493L17.1387 24.4454C16.8426 24.9248 16.9911 25.5536 17.4705 25.8498C17.6377 25.953 17.8229 26.0022 18.0059 26.0022C18.3478 26.0022 18.6819 25.8303 18.875 25.5181L23.6298 17.822C23.926 17.3426 23.7774 16.7138 23.298 16.4176Z"
        fill="currentColor"
      />
      <path
        d="M25.2225 13.2995C24.7429 13.0032 24.1142 13.1518 23.818 13.6311L23.5685 14.0349C23.2723 14.5144 23.4207 15.1432 23.9001 15.4394C24.0673 15.5427 24.2526 15.592 24.4356 15.592C24.7775 15.592 25.1116 15.4201 25.3046 15.1078L25.5541 14.704C25.8503 14.2245 25.7019 13.5957 25.2225 13.2995Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default PhotographIconSVG;
