import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import CampaignPopupTestimonial from "components/campaigns/popup/campaign-popup-testimonial";
import TestimonialUploadSelector from "components/campaigns/upload/testimonial-upload";
import InputRange from "components/input-range/input-range";
import InputDoubleField from "components/input/input-double";
import WYSIWYGEditorDraft, {
  converToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import WYSIWYGEditorDraftSub from "components/wysiwyg/wysiwyg-editor-sub";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import {
  MAX_TITLE_LENGTH,
  TEMPLATEOBJ,
  TESTIMONIALSUGGESTIONS,
  sanitizeValueHTML,
} from "utils/constant";
import SuggestionButton from "./component/suggestions-btn";

export default function Testimonials({
  handleChangeStep = () => console.log("We are missing a function here"),
  handleOnSave = () => {},
  loading = false,
  validationErrors = null,
  setState,
}) {
  const { selectedTab, setSelectedTab, popup, setPopup } = useContext(Context); // context
  const initials = useMemo(() => {
    if (_.isEmpty(popup?.template_content)) {
      return _.find(TEMPLATEOBJ, { type: "testimonials" });
    } else {
      return JSON.parse(popup?.template_content);
    }
  }, [popup.template_content]);
  // start wysiwyg
  const titleInitials = useMemo(() => {
    let data = {};
    if (_.isEmpty(popup?.template_content)) {
      data = _.find(TEMPLATEOBJ, { type: "testimonials" });
    } else {
      data = JSON.parse(popup?.template_content);
    }
    return converToEditorState(data.title || "");
  }, [popup.template_content]);
  const [editorState, setEditorState] = useState(titleInitials);
  // end wysiwyg
  const [object, setObject] = useState(initials);
  const [suggestion, setSuggestion] = useState(0);

  const handleChangeValue = (value, element) => {
    let payload = _.clone(object);
    let finalValue = value;
    let data = payload;
    if (element === "title") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
        data = update(payload, {
          [element]: { $set: finalValue },
          title_decoration: { $set: "normal" },
        });
      } else {
        data = update(payload, {
          [element]: { $set: sanitizeValueHTML(finalValue) },
          title_decoration: { $set: "normal" },
        });
      }
    } else {
      data = update(payload, {
        [element]: { $set: finalValue },
      });
    }
    setObject(data);
    //set to popout
    if (element === "title") {
      setPopup((prev) => ({
        ...prev,
        template_content: JSON.stringify(data),
      }));
    }
  };

  const handleSelectIcon = (e, index) => {
    let payload = _.clone(object);
    delete payload.testimonials[index].icon_url;
    delete payload.testimonials[index].icon_id;
    let data = update(payload, {
      testimonials: {
        [index]: {
          image_url: { $set: e?.image_url },
          image_key: { $set: e?.image_key },
          image_id: { $set: e?.image_id },
        },
      },
    });
    setObject(data);
    handleOnSave(data);
  };

  const handleChangeText = (value, index, element) => {
    let payload = _.clone(object);
    let data = payload;
    let finalValue = value;
    if (element === "quote") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
      }
      data = update(payload, {
        testimonials: {
          [index]: {
            [element]: { $set: sanitizeValueHTML(finalValue) },
          },
        },
      });
    } else {
      data = update(payload, {
        testimonials: {
          [index]: {
            [element]: { $set: value },
          },
        },
      });
    }
    setObject(data);
    //set to popout
    if (element === "quote") {
      setPopup((prev) => ({
        ...prev,
        template_content: JSON.stringify(data),
      }));
    }
    if (element === "rating") {
      handleOnSave(data);
    }
  };

  const handleOnBlur = (e) => {
    handleOnSave(object);
  };

  const validateBeforeNext = () => {
    let payload = _.clone(validationErrors);
    if (_.isEmpty(object?.title)) {
      payload = { title: "Title is required" };
    }
    //check title quate, name and position
    let testimonials = object?.testimonials;
    if (testimonials[0].rating < 0 || testimonials[0].rating === null) {
      payload = { ...payload, rating: "Star Rating is required" };
    }
    if (_.isEmpty(testimonials[0].quote)) {
      payload = { ...payload, quote: "Quote is required" };
    }
    if (_.isEmpty(testimonials[0].name)) {
      payload = { ...payload, name: "Name is required" };
    }
    //set state
    setState((prev) => ({
      ...prev,
      validationErrors: {
        ...prev.validationErrors,
        ...payload,
      },
    }));
    //if empty validations
    if (_.isEmpty(payload)) {
      handleChangeStep();
    }
  };
  //suggestions
  const handleSuggestion = (type) => {
    const title = TESTIMONIALSUGGESTIONS;
    const size = _.size(TESTIMONIALSUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = title[0];
      } else {
        value = title[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = title[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = title[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = title[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      let payload = _.clone(object);
      let data = update(payload, {
        title: { $set: value },
      });
      //handle set
      setEditorState(converToEditorState(value));
      setObject(data);
      handleOnSave(data);
    }
  };

  return (
    <>
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Add Your Content</p>
      </div>

      <div className="w-full pt-5">
        {/* card start */}
        <div
          className="bg-white rounded-xl relative campaign-left-max"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            {/* first */}
            <div className="flex pl-1 pb-2">
              <p className="text-xl mt-1">Enter your testimonials</p>
              {selectedTab === "content" && (
                <CampaignPopupTestimonial type="testimonials-editor" />
              )}
            </div>
            {/* middle */}
            <div className="mb-2">
              <p className="pl-1 pt-2 pb-2">Type your heading here:</p>
              <WYSIWYGEditorDraft
                placeholder="e.g. What Our Clients Say About Us"
                onChange={(value) => {
                  handleChangeValue(value, "title");
                }}
                onBlur={handleOnBlur}
                errorMessage={validationErrors?.title}
                showCounter={true}
                maxLength={MAX_TITLE_LENGTH}
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>
            {/* title_decoration */}
            <div className="flex items-center 5md:block 3lg:flex 3lg:items-center justify-start">
              <SuggestionButton onClick={(e) => handleSuggestion(e)} />
            </div>
            {/* Tabs */}
            <div className="mt-2">
              <p className="pl-1 pt-4 pb-2">Enter your testimonials here:</p>
              {/* Layout */}
              {(object?.testimonials || []).map((value, index) => {
                let ratingErrors = [];
                if (!_.isEmpty(validationErrors.rating)) {
                  ratingErrors.push(validationErrors.rating);
                }
                return (
                  <div className="w-full" key={index}>
                    <div className="w-full">
                      {/*  range */}
                      <div className="relative mx-1 mb-3">
                        {ratingErrors[index] && (
                          <p className="text-red text-xs mt-2">
                            * {ratingErrors[index]}
                          </p>
                        )}
                        <InputRange
                          label="Star Rating (Out Of 5)"
                          name="rating"
                          defaultValue={value?.rating}
                          onChange={(e) =>
                            handleChangeText(
                              parseFloat(e?.target?.value),
                              index,
                              "rating"
                            )
                          }
                        />
                      </div>
                      <WYSIWYGEditorDraftSub
                        label="Testimonial Quote"
                        placeholder={`e.g. “Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.” `}
                        onChange={(value) => {
                          handleChangeText(value, index, "quote");
                        }}
                        value={value?.quote}
                        onBlur={handleOnBlur}
                        errorMessage={index ? null : validationErrors?.quote}
                        showCounter={true}
                        maxLength={250}
                      />

                      {/* lower part */}
                      <div className="mt-1">
                        <div className="flex flex-row justify-center space-x-6 mt-3">
                          <TestimonialUploadSelector
                            id={`testimonial-upload-${index}`}
                            onUpload={(e) => handleSelectIcon(e, index)}
                            image={value}
                          />
                          <InputDoubleField
                            name={["name", "position"]}
                            label={["Name", "Position (Optional)"]}
                            placeholder={[
                              "e.g. John Doe",
                              "e.g. CEO of Open Enterprise",
                            ]}
                            masked={false}
                            valueUpper={value?.name || ""}
                            valueLower={value?.position || ""}
                            onBlurInputUpper={handleOnBlur}
                            onChangeUpper={(e) =>
                              handleChangeText(e?.target?.value, index, "name")
                            }
                            onBlurInputLower={handleOnBlur}
                            onChangeLower={(e) =>
                              handleChangeText(
                                e?.target?.value,
                                index,
                                "position"
                              )
                            }
                            errorMessage={index ? null : validationErrors.name}
                            error={
                              index ? false : !_.isEmpty(validationErrors.name)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("template")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                onClick={validateBeforeNext}
                disabled={loading}
                icon={
                  <div className="absolute right-2 bottom-0 top-0 flex items-center">
                    {loading && (
                      <LoadingIndicator className="text-white w-4 h-4" />
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
