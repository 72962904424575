import { getIcon } from "assets/icons/icons-list";
import ImageUploadIconSVG from "assets/svg/image-upload-icon";
import RightArrow from "assets/svg/popup-example/right-arrow";
import { Context } from "context/context";
import { useContext } from "react";
import CampaignPopupPopoutTab from "../popup/campaign-popup-popout-tab";

export default function PopupExampleTab({
  animationImage,
  animationDiv,
  preview = false,
  show = true,
}) {
  const {
    popup,
    view,
    selectedTab,
    mobileContentOpen,
    setMobileContentOpen,
    tabs,
  } = useContext(Context);
  const mobile = view === "mobile";
  const masked = selectedTab === "contactM" || selectedTab === "contactD";
  const miniTab = popup.options?.tab?.icon?.display === "mini";

  const selectedIcon = popup.icon && JSON.parse(popup.icon);
  const Icon = getIcon(selectedIcon?.id);
  const fontColor = popup.tab_font_colour || "#000000";

  const corner = () => {
    let result = 0;
    if (popup.corner_style === "rounded") {
      result = "20px 0px 0px 20px";
    } else if (popup.corner_style === "curved") {
      result = "12px 0px 0px 12px";
    }
    return result;
  };

  // const calculateFontSize = () => {
  //   const minSize = mobile
  //   ? popup?.container_width - 44
  //   : popup?.container_width - 46;
  //   const origSize = popup?.container_width
  //   const origFontSize = 16;
  //   let minFontSize = (minSize / origSize) * origFontSize
  //   console.log("minFontSize", minFontSize)
  //   return minFontSize
  // };

  return (
    <div className="relative" onClick={() => setMobileContentOpen(true)}>
      {selectedTab === "popout" && (
        <CampaignPopupPopoutTab
          type="preview"
          position="left center"
          className={`absolute p-2 box-shadow ${
            mobile ? "-top-10 right-14" : "-top-12 right-7"
          }`}
        />
      )}
      {selectedTab !== "popout" && !mobileContentOpen && !preview && (
        <CampaignPopupPopoutTab
          type="preview"
          position="left center"
          className={`absolute p-2 box-shadow  ${
            mobile ? "-top-10 right-14" : "-top-12 right-7"
          }`}
        />
      )}
      {(!preview ? tabs === "image" : popup?.tab_type === "image") ? (
        <>
          {/* image-view-height-mobile image-view-height image-view */}
          {show && popup.image?.url ? (
            <div
              className={
                mobile ? "m-top-400 popup-tab-mobile-container" : animationImage
              }
            >
              <img
                src={popup.image?.url}
                alt="popout-tab"
                className={
                  mobile
                    ? `${
                        (selectedTab === "popout" || preview) &&
                        "slideIn-image-mobile object-cover"
                      }`
                    : "object-cover"
                }
                style={{
                  filter: masked && "blur(2px)",
                  cursor: "pointer",
                  width: popup.tab_image_size,
                  maxHeight: "540px",
                }}
              />
            </div>
          ) : (
            <div
              className={
                mobile ? "m-top-400 popup-tab-mobile-container" : animationImage
              }
            >
              <div className="flex w-35 h-35 justify-center items-center bg-white flex-col">
                <ImageUploadIconSVG />
                <p className="mt-1.5 text-xss text-tipsColor text-center font-light opacity-50">
                  Your image will be here
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {show && (
            <div
              className={
                mobile
                  ? `popup-tab-mobile break-words ${
                      (selectedTab === "popout" || preview) &&
                      "slideIn-div-mobile"
                    }`
                  : `popup-tab break-words ${animationDiv}`
              }
              style={{
                filter: masked && !mobile && "blur(2px)",
                borderRadius: corner(),
                background: popup?.primary_colour,
                color: popup?.primary_colour && "#fff",
                cursor: "pointer",
                width: mobile
                  ? popup?.container_width - 40
                  : popup?.container_width - 46,
              }}
            >
              {masked && !mobile && (
                <div
                  style={{ background: popup?.primary_colour || "#ffffff" }}
                  className="h-full w-1 top-0 absolute -right-1"
                />
              )}
              <div className={`popup-quote`}>
                {popup?.icon &&
                  Icon &&
                  popup?.icon_position &&
                  popup?.icon_position === "above" && (
                    <div
                      className={`${
                        mobile
                          ? "icon-circle-mobile pulse white grid"
                          : `icon-circle-desktop ${
                              masked ? "" : "pulse white"
                            } grid`
                      }`}
                      style={{
                        borderRadius: "100%",
                      }}
                    >
                      {Icon && (
                        <Icon
                          className="w-5 h-5 m-auto"
                          fill="#000"
                          sx={{
                            fontSize: 20,
                            color: "#000",
                          }}
                        />
                      )}
                    </div>
                  )}
                <p
                  className={
                    mobile && miniTab
                      ? "hidden"
                      : popup?.icon_position === "above"
                      ? "mt-3"
                      : ""
                  }
                  style={{
                    color: popup?.primary_colour ? fontColor : "black",
                  }}
                >
                  {popup?.quote
                    ? popup?.quote
                    : "Choose a quote to get started!"}
                </p>
                <div className={mobile && miniTab ? "hidden" : "pt-1 "}>
                  <RightArrow
                    color={popup?.primary_colour ? fontColor : "black"}
                  />
                </div>
                {popup?.icon &&
                  Icon &&
                  popup?.icon_position &&
                  popup?.icon_position === "below" && (
                    <div
                      className={`${
                        mobile
                          ? "icon-circle-mobile pulse white"
                          : `icon-circle-desktop ${masked ? "" : "pulse white"}`
                      } grid mt-2`}
                      style={{
                        borderRadius: "100%",
                      }}
                    >
                      {Icon && (
                        <Icon
                          className="w-5 h-5 m-auto"
                          fill="#000"
                          sx={{
                            fontSize: 20,
                            color: "#000",
                          }}
                        />
                      )}
                    </div>
                  )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
