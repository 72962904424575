import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import Toggle from "components/toggle/toggle";
import { Context } from "context/context";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import CampaignPopupPopoutTab from "../popup/campaign-popup-popout-tab";

const Effects = [
  { name: "Mega Pulse", type: "pulse", compatibleWith: ["peep", "red_carpet"] },
  {
    name: "Peep",
    type: "peep",
    compatibleWith: ["pulse", "colour", "red_carpet"],
  },
  {
    name: "Auto Open",
    type: "red_carpet",
    compatibleWith: ["pulse", "colour", "peep"],
  },
  {
    name: "Colour Change",
    type: "colour",
    compatibleWith: ["peep", "red_carpet"],
  },
];

const Triggers = [{ name: "Open on Tab Hover", type: "hover" }];

export default function CampaignEffectsPopout() {
  const { http } = global.services;
  const { popup, setPopup, setSelectedTab } = useContext(Context);

  const [state, setState] = useState({
    loading: false,
    validationErrors: null,
  });

  const handleOnSave = async (data = {}) => {
    setState((prev) => ({ ...prev, loading: true }));
    const params = {
      ...popup,
      ...data,
    };
    try {
      if (popup.id) {
        const updateCampaign = await http.put(`popups/${popup?.id}`, params);
        if (updateCampaign) {
          setPopup((prev) => ({ ...prev, ...updateCampaign?.data?.data }));
        }
      }
    } catch (error) {
      if (error.status === 422)
        setState((prev) => ({
          ...prev,
          validationErrors: error.data.data,
        }));
      toast.error("It appears that something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleChangeEffect = (effect) => {
    const effectEnabled = !!popup.options?.effects?.[effect.type]?.enabled;

    const effects = { ...popup.options?.effects };

    for (const key of Object.keys(effects)) {
      if (key === effect.type) {
        continue;
      }
      if (!effect.compatibleWith.includes(key)) {
        effects[key] = { enabled: false };
      }
    }

    effects[effect.type] = {
      enabled: !effectEnabled,
    };

    const options = {
      ...popup.options,
      effects: effects,
    };

    setPopup((prev) => ({ ...prev, options }));
    handleOnSave({ options });
  };

  const handleChangeTrigger = (trigger) => {
    const triggerEnabled = !!popup.options?.triggers?.[trigger.type]?.enabled;
    const options = {
      ...popup.options,
      triggers: {
        ...popup.options?.triggers,
        [trigger.type]: {
          enabled: !triggerEnabled,
        },
      },
    };

    setPopup((prev) => ({ ...prev, options }));
    handleOnSave({ options });
  };

  const handleChangeStep = () => {
    setSelectedTab("preview");
  };

  return (
    <div className="w-full">
      <div className="w-full">
        <p className="font-medium text-2xl">Select Your Engagement Effects</p>
      </div>
      <div className="w-full pt-5">
        {/* left */}
        <div className="relative campaign-left-max">
          <div
            className="bg-white rounded-xl relative"
            style={{ height: "fit-content" }}
          >
            <div className="p-5 pb-0">
              {/* first */}
              <div className="flex pl-1 pb-2">
                <p className="text-xl mt-1">
                  Popout Triggers (Desktop Only)
                  <CampaignPopupPopoutTab
                    type="engagement-trigger-tab"
                    className="relative mt-2 ml-2 p-2 box-shadow"
                    actveColor="bg-black"
                  />
                </p>
              </div>
              {/* Effect List */}
              <div className="w-full pl-1 pt-2 flex justify-between flex-wrap">
                {Triggers.map((trigger, index) => {
                  return (
                    <div className="w-full my-3 flex items-center" key={index}>
                      <Toggle
                        className="w-10 h-5"
                        hActiveClass="w-3 h-3 ml-auto mr-1 rounded-full bg-white"
                        hInactiveClass="w-3 h-3 ml-1 rounded-full bg-white"
                        checked={
                          !!popup.options?.triggers?.[trigger.type]?.enabled
                        }
                        onChange={() => handleChangeTrigger(trigger)}
                      />
                      <p className="ml-3">{trigger.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="p-5 pt-0">
              {/* first */}
              <div className="flex pl-1 pb-2">
                <p className="text-xl mt-1">
                  Engagement Effects (Desktop Only)
                  <CampaignPopupPopoutTab
                    type="engagement-effect-tab"
                    className="relative mt-2 ml-2 p-2 box-shadow"
                    actveColor="bg-black"
                  />
                </p>
              </div>
              {/* Effect List */}
              <div className="w-full pl-1 pt-2 flex justify-between flex-wrap">
                {Effects.map((effects, index) => {
                  return (
                    <div className="w-full my-3 flex items-center" key={index}>
                      <Toggle
                        className="w-10 h-5"
                        hActiveClass="w-3 h-3 ml-auto mr-1 rounded-full bg-white"
                        hInactiveClass="w-3 h-3 ml-1 rounded-full bg-white"
                        checked={
                          !!popup.options?.effects?.[effects.type]?.enabled
                        }
                        onChange={() => handleChangeEffect(effects)}
                      />
                      <p className="ml-3">{effects.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* footer */}
            <div className="border-t border-tabBorder">
              <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
                <Button
                  buttonName="PREVIOUS"
                  buttonClass="bg-transparent rounded-full py-3"
                  buttonType="transparent"
                  buttonTextClass="text-sm font-bold"
                  onClick={() => setSelectedTab("style")}
                />
                <Button
                  buttonName="NEXT"
                  buttonClass="bg-primary rounded-full py-3 px-12 relative"
                  buttonType="primary"
                  onClick={handleChangeStep}
                  disabled={state.loading}
                  icon={
                    <div className="absolute right-2 bottom-0 top-0 flex items-center">
                      {state.loading && (
                        <LoadingIndicator className="text-white w-4 h-4" />
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          {/* pro tips */}
          {/* <div className="bg-white rounded-xl relative mt-6">
            <div className="w-full p-5">
              <div className="border-b border-tabBorder">
                <div className="flex pl-2 pb-3">
                  <div className="text-secondary">
                    <BulbIconSVG />
                  </div>
                  <p className="text-sm font-bold pl-4">PRO TIPS</p>
                </div>
              </div>
              <div className="p-5">
                <ul className="list-disc text-tipsColor">
                  <li className="py-1">
                    Try to match the existing look and feel of your website.
                  </li>
                  <li className="py-1">
                    If you already know your brand's primary colour, use it!
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
