import "assets/css/components/campaign-tests.css";
import Slider from "components/input-range/slider";
import { useState } from "react";

export const SplitTestingSetting = ({ data }) => {
  const [splitPercent, setSplitPercent] = useState({
    a_percent: data?.a_percent,
    b_percent: data?.b_percent,
  });

  const handleUpdateSplitTesting = (a, b) => {
    setSplitPercent({ ...splitPercent, a_percent: a, b_percent: b });
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex space-x-2 items-center">
        <h1 className="text-lg font-medium">Split Testing Setting</h1>
      </div>
      <p className="text-sm font-light">
        How did your test list was divided during the run
      </p>
      <div className="flex justify-between items-center space-x-4 border border-thBorder p-4 rounded mt-5 h-24">
        <div className="w-full">
          <Slider
            defaultValueA={splitPercent?.a_percent}
            defaultValueB={splitPercent?.b_percent}
            disableSlide={true}
            onChangeFinish={handleUpdateSplitTesting}
          />
        </div>
        <div className="flex flex-col space-y-1 ">
          <div className="text-xs flex items-center space-x-2 whitespace-nowrap">
            <div className="w-3 h-3 bg-secondary rounded-full" />
            <p>Test A (Original)</p>
          </div>
          <div className="text-xs flex items-center space-x-2 whitespace-nowrap">
            <div className="w-3 h-3 bg-primary rounded-full" />
            <p>Test B (Variation)</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitTestingSetting;
