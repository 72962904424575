import React, { useState } from "react";
import "assets/css/components/campaign-tests.css";
import ResultsOverview from "./results-overview";
import ResultsGraph from "./results-graph";
import { useEffect } from "react";
import moment from "moment";
import { getDays, getMonths, getWeeks } from "utils/constant";

const events = {
  open: "content-click",
  click: "content-show",
  form_entry: "form-submission",
};

export const ABResults = ({ data = {} }) => {
  const { http } = global.services;
  const [result, setResult] = useState({
    labels: [],
    graph: {},
  });
  const [filter, setFilter] = useState("weeks");
  const [event, setEvent] = useState("content-click");
  const [filterValue, setFilterValue] = useState("");
  const [filterDates, setFilterDates] = useState({
    start: moment().unix(),
    end: moment().unix(),
  });
  const [dateOptions, setDateOptions] = useState([]);

  const [totals, setTotals] = useState({
    reports_a: {},
    reports_b: {},
  });

  const fetchTestGraph = async ({ id }) => {
    try {
      const { data: response } = await http.get(
        `reports/campaign-popup/${id}/results`,
        {
          start_date: filterDates.start,
          end_date: filterDates.end,
        }
      );
      //graph
      const labels = getDays(
        moment.unix(filterDates.start),
        moment.unix(filterDates.end)
      );
      if (response.success) {
        setResult({
          labels: labels,
          graph: {
            reports_a: response.data?.reports_a?.report,
            reports_b: response.data?.reports_b?.report,
          },
        });
        //
        setTotals({
          reports_a: response.data?.reports_a?.total,
          reports_b: response.data?.reports_b?.total,
        });
      }

      //end graph
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data) {
      fetchTestGraph({ id: data.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, data, filterDates]);

  useEffect(() => {
    let options = [];
    if (filter === "weeks") {
      options = getWeeks(data?.created_at);
    } else if (filter === "months") {
      options = getMonths(data?.created_at);
    }
    setDateOptions(options);
    setFilterValue(options[0].value);
    setFilterDates({
      start: options[0].dates.start,
      end: options[0].dates.end,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  // console.log("filterDates", filterDates);

  return (
    <div className="flex">
      <ResultsOverview data={totals} />
      <ResultsGraph
        record={data}
        event={event}
        data={result}
        onFilterChange={(filter) => setFilter(filter)}
        filter={filter}
        onEventChange={(event) => setEvent(events[event])}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        dateOptions={dateOptions}
        setFilterDates={setFilterDates}
        filterDates={filterDates}
      />
    </div>
  );
};

export default ABResults;
