import ProofPointsCalendy from "assets/popout-wireframes/proofpoints-calendy.png";
import ProofPointsAddress from "assets/popout-wireframes/proofpoints-address.png";
import ProofPointsMap from "assets/popout-wireframes/proofpoints-map.png";
import ProofPointsLead from "assets/popout-wireframes/proofpoints-lead.png";
import ProofPointsEnquiry from "assets/popout-wireframes/proofpoints-enquiry.png";
import ProofPointsStandard from "assets/popout-wireframes/proofpoints-standard.png";
import TestimonialsCalendy from "assets/popout-wireframes/testimonial-calendy.png";
import TestimonialsAddress from "assets/popout-wireframes/testimonial-address.png";
import TestimonialsMap from "assets/popout-wireframes/testimonial-map.png";
import TestimonialsLead from "assets/popout-wireframes/testimonial-lead.png";
import TestimonialsEnquiry from "assets/popout-wireframes/testimonial-enquiry.png";
import TestimonialsStandard from "assets/popout-wireframes/testimonial-standard.png";
import VideosCalendy from "assets/popout-wireframes/videos-calendy.png";
import VideosAddress from "assets/popout-wireframes/videos-address.png";
import VideosMap from "assets/popout-wireframes/videos-map.png";
import VideosLead from "assets/popout-wireframes/videos-lead.png";
import VideosEnquiry from "assets/popout-wireframes/videos-enquiry.png";
import VideosStandard from "assets/popout-wireframes/videos-standard.png";
import PromotionCalendy from "assets/popout-wireframes/promotion-calendy.png";
import PromotionAddress from "assets/popout-wireframes/promotion-address.png";
import PromotionMap from "assets/popout-wireframes/promotion-map.png";
import PromotionLead from "assets/popout-wireframes/promotion-lead.png";
import PromotionEnquiry from "assets/popout-wireframes/promotion-enquiry.png";
import PromotionStandard from "assets/popout-wireframes/promotion-standard.png";
import StatisticsCalendy from "assets/popout-wireframes/statistics-calendy.png";
import StatisticsAddress from "assets/popout-wireframes/statistics-address.png";
import StatisticsMap from "assets/popout-wireframes/statistics-map.png";
import StatisticsLead from "assets/popout-wireframes/statistics-lead.png";
import StatisticsEnquiry from "assets/popout-wireframes/statistics-enquiry.png";
import StatisticsStandard from "assets/popout-wireframes/statistics-standard.png";
import LogoCalendy from "assets/popout-wireframes/logo-calendy.png";
import LogoAddress from "assets/popout-wireframes/logo-address.png";
import LogoMap from "assets/popout-wireframes/logo-map.png";
import LogoLead from "assets/popout-wireframes/logo-lead.png";
import LogoEnquiry from "assets/popout-wireframes/logo-enquiry.png";
import LogoStandard from "assets/popout-wireframes/logo-standard.png";
import _ from "lodash";

const wireframes = [
  {
    template: "proof-points",
    contact: "standard-details",
    image: ProofPointsStandard,
  },
  {
    template: "proof-points",
    contact: "enquiry-form",
    image: ProofPointsEnquiry,
  },
  {
    template: "proof-points",
    contact: "lead-magnet",
    image: ProofPointsLead,
  },
  {
    template: "proof-points",
    contact: "map-assistance",
    image: ProofPointsMap,
  },
  {
    template: "proof-points",
    contact: "multiple-addresses",
    image: ProofPointsAddress,
  },
  {
    template: "proof-points",
    contact: "calendy-event-scheduler",
    image: ProofPointsCalendy,
  },
  {
    template: "testimonials",
    contact: "standard-details",
    image: TestimonialsStandard,
  },
  {
    template: "testimonials",
    contact: "enquiry-form",
    image: TestimonialsEnquiry,
  },
  {
    template: "testimonials",
    contact: "lead-magnet",
    image: TestimonialsLead,
  },
  {
    template: "testimonials",
    contact: "map-assistance",
    image: TestimonialsMap,
  },
  {
    template: "testimonials",
    contact: "multiple-addresses",
    image: TestimonialsAddress,
  },
  {
    template: "testimonials",
    contact: "calendy-event-scheduler",
    image: TestimonialsCalendy,
  },
  {
    template: "videos",
    contact: "standard-details",
    image: VideosStandard,
  },
  {
    template: "videos",
    contact: "enquiry-form",
    image: VideosEnquiry,
  },
  {
    template: "videos",
    contact: "lead-magnet",
    image: VideosLead,
  },
  {
    template: "videos",
    contact: "map-assistance",
    image: VideosMap,
  },
  {
    template: "videos",
    contact: "multiple-addresses",
    image: VideosAddress,
  },
  {
    template: "videos",
    contact: "calendy-event-scheduler",
    image: VideosCalendy,
  },
  {
    template: "promotions",
    contact: "standard-details",
    image: PromotionStandard,
  },
  {
    template: "promotions",
    contact: "enquiry-form",
    image: PromotionEnquiry,
  },
  {
    template: "promotions",
    contact: "lead-magnet",
    image: PromotionLead,
  },
  {
    template: "promotions",
    contact: "map-assistance",
    image: PromotionMap,
  },
  {
    template: "promotions",
    contact: "multiple-addresses",
    image: PromotionAddress,
  },
  {
    template: "promotions",
    contact: "calendy-event-scheduler",
    image: PromotionCalendy,
  },
  {
    template: "statistics-data",
    contact: "standard-details",
    image: StatisticsStandard,
  },
  {
    template: "statistics-data",
    contact: "enquiry-form",
    image: StatisticsEnquiry,
  },
  {
    template: "statistics-data",
    contact: "lead-magnet",
    image: StatisticsLead,
  },
  {
    template: "statistics-data",
    contact: "map-assistance",
    image: StatisticsMap,
  },
  {
    template: "statistics-data",
    contact: "multiple-addresses",
    image: StatisticsAddress,
  },
  {
    template: "statistics-data",
    contact: "calendy-event-scheduler",
    image: StatisticsCalendy,
  },
  {
    template: "logos",
    contact: "standard-details",
    image: LogoStandard,
  },
  {
    template: "logos",
    contact: "enquiry-form",
    image: LogoEnquiry,
  },
  {
    template: "logos",
    contact: "lead-magnet",
    image: LogoLead,
  },
  {
    template: "logos",
    contact: "map-assistance",
    image: LogoMap,
  },
  {
    template: "logos",
    contact: "multiple-addresses",
    image: LogoAddress,
  },
  {
    template: "logos",
    contact: "calendy-event-scheduler",
    image: LogoCalendy,
  },
];

export const getPopoutImage = (template, contact) => {
  const obj = _.find(wireframes, (e) => {
    if (e.template === template && e.contact === contact) {
      return e;
    }
  });

  if (_.isEmpty(obj)) {
    return null;
  } else {
    return obj.image;
  }
};
