const SwitchAboveIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.06184 2.64376C5.51271 3.02984 3.30821 4.8793 2.59846 7.52811C1.70072 10.8785 3.51255 14.3166 6.69033 15.515"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33953 5.3372L8.10121 2.49457C8.12876 2.39176 8.11434 2.28222 8.06112 2.19005C8.00791 2.09787 7.92025 2.03061 7.81745 2.00307L4.97482 1.24139"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SwitchAboveIconSVG;
