const TestimonialsIconSVG = ({ selected }) => {
  return (
    <div className="float-right">
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={selected ? "1" : "0.5"}>
          <path
            d="M12.0953 6.99592H10.3184C10.5856 5.01711 11.6798 4.9177 12.1833 4.87189C12.6863 4.82609 13.0714 4.40441 13.0714 3.89934V0.976973C13.0714 0.708516 12.9608 0.451777 12.7657 0.267305C12.5706 0.082832 12.3079 -0.0127735 12.04 0.00197262C8.95312 0.175605 5.27344 1.7342 5.27344 8.17346V13.2555C5.27344 14.1038 5.96357 14.794 6.81191 14.794H12.0954C12.9438 14.794 13.6339 14.1037 13.6338 13.2554V8.53439C13.6338 7.68605 12.9437 6.99592 12.0953 6.99592ZM11.6808 12.8407H7.22656V8.17346C7.22656 3.68029 9.22471 2.42541 11.1183 2.07121V3.09797C9.81055 3.49602 8.30547 4.6716 8.30547 7.97248C8.30547 8.51184 8.74268 8.94904 9.28203 8.94904H11.6808V12.8407Z"
            fill="#EC5627"
          />
          <path
            d="M22.0798 6.99592H20.3027C20.57 5.01711 21.6642 4.9177 22.1677 4.87189C22.6707 4.82609 23.0558 4.40441 23.0558 3.89934V0.976973C23.0558 0.708516 22.9452 0.451777 22.7501 0.267305C22.555 0.082832 22.2921 -0.0127735 22.0244 0.00197262C18.9375 0.175605 15.2578 1.7342 15.2578 8.17346V13.2555C15.2578 14.1038 15.948 14.794 16.7963 14.794H22.0798C22.9281 14.794 23.6184 14.1037 23.6183 13.2553V8.53439C23.6183 7.68605 22.9281 6.99592 22.0798 6.99592ZM21.6651 12.8408H17.2109V8.17346C17.2109 3.68029 19.2091 2.42541 21.1026 2.07121V3.09797C19.7949 3.49602 18.2898 4.6716 18.2898 7.97248C18.2898 8.51184 18.7271 8.94904 19.2664 8.94904H21.6651V12.8408Z"
            fill="#EC5627"
          />
          <path
            d="M27.8389 7.89014C27.6572 7.7085 27.4053 7.604 27.1484 7.604C26.8916 7.604 26.6396 7.7085 26.458 7.89014C26.2764 8.07178 26.1719 8.32373 26.1719 8.58057C26.1719 8.8374 26.2764 9.08936 26.458 9.271C26.6396 9.45264 26.8916 9.55713 27.1484 9.55713C27.4053 9.55713 27.6572 9.45264 27.8389 9.271C28.0205 9.08936 28.125 8.8374 28.125 8.58057C28.125 8.32373 28.0205 8.07178 27.8389 7.89014Z"
            fill="#333C4E"
          />
          <path
            d="M32.6433 7.604H31.4127C30.8733 7.604 30.4361 8.04121 30.4361 8.58057C30.4361 9.11992 30.8733 9.55713 31.4127 9.55713H32.6433C41.1368 9.55713 48.0469 16.4672 48.0469 24.9607C48.0469 32.21 42.9055 38.5498 35.8216 40.0355C35.3693 40.1304 35.0455 40.5292 35.0455 40.9912V46.6654L29.0305 40.6504C28.8474 40.4673 28.5989 40.3644 28.3399 40.3644H17.3567C8.86318 40.3644 1.95312 33.4543 1.95312 24.9607C1.95312 22.3431 2.62139 19.7582 3.88574 17.4854C4.14805 17.0141 3.97842 16.4194 3.50713 16.1572C3.03574 15.8949 2.44111 16.0646 2.179 16.5358C0.753418 19.0982 0 22.0115 0 24.9607C0 34.5313 7.78613 42.3175 17.3567 42.3175H27.9354L35.3315 49.7136C35.5184 49.9004 35.7681 49.9996 36.0223 49.9996C36.148 49.9996 36.275 49.9753 36.3958 49.9252C36.7606 49.7741 36.9986 49.4181 36.9986 49.023V41.7651C40.5321 40.8495 43.7271 38.8121 46.0618 35.9699C48.6014 32.8784 50 28.9687 50 24.9607C50 15.3902 42.2139 7.6041 32.6433 7.604Z"
            fill="#333C4E"
          />
          <path
            d="M37.0183 17.3335H12.9824C12.4431 17.3335 12.0059 17.7707 12.0059 18.3101C12.0059 18.8494 12.4431 19.2866 12.9824 19.2866H37.0182C37.5575 19.2866 37.9948 18.8494 37.9948 18.3101C37.9948 17.7707 37.5576 17.3335 37.0183 17.3335Z"
            fill="#333C4E"
          />
          <path
            d="M37.0183 22.5415H12.9824C12.4431 22.5415 12.0059 22.9787 12.0059 23.5181C12.0059 24.0574 12.4431 24.4946 12.9824 24.4946H37.0182C37.5575 24.4946 37.9948 24.0574 37.9948 23.5181C37.9948 22.9787 37.5576 22.5415 37.0183 22.5415Z"
            fill="#333C4E"
          />
          <path
            d="M37.0183 27.75H12.9824C12.4431 27.75 12.0059 28.1873 12.0059 28.7266C12.0059 29.2658 12.4431 29.7031 12.9824 29.7031H37.0182C37.5575 29.7031 37.9948 29.2658 37.9948 28.7266C37.9948 28.1873 37.5576 27.75 37.0183 27.75Z"
            fill="#333C4E"
          />
          <path
            d="M37.0187 32.9585H24.207C23.6677 32.9585 23.2305 33.3958 23.2305 33.9351C23.2305 34.4743 23.6677 34.9116 24.207 34.9116H37.0187C37.5581 34.9116 37.9953 34.4743 37.9953 33.9351C37.9953 33.3958 37.5581 32.9585 37.0187 32.9585Z"
            fill="#333C4E"
          />
          <path
            d="M18.711 32.958H18.6953C18.156 32.958 17.7188 33.3953 17.7188 33.9346C17.7188 34.4738 18.156 34.9111 18.6953 34.9111H18.711C19.2504 34.9111 19.6876 34.4738 19.6876 33.9346C19.6876 33.3953 19.2504 32.958 18.711 32.958Z"
            fill="#333C4E"
          />
        </g>
      </svg>
    </div>
  );
};

export default TestimonialsIconSVG;
