import React from "react";
import SelectCheckIconSVG from "assets/svg/select-check";
import "./styles.css";

const ButtonBigIcon = ({
  selected = false,
  buttonName = "",
  Icon = null,
  textClass = "text-left",
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <div className="w-full relative">
      {selected && (
        <SelectCheckIconSVG
          className="absolute right-0"
          style={{ marginRight: "-5px", marginTop: "-10px" }}
        />
      )}
      <button
        className={`w-full rounded-md p-5 flex justify-between items-center ${
          selected
            ? "border-2 border-secondary focus:outline-none focus:border-secondary"
            : "border border-toggleBg hover:border-secondary focus:border-secondary"
        }`}
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        <p className={textClass}>{buttonName}</p>
        {Icon && (
          <div>
            <Icon selected={selected} />
          </div>
        )}
      </button>
    </div>
  );
};

export default ButtonBigIcon;
