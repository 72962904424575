import React from "react";
import "./styles.css";

const PopupComponent = (
  {
    width = "321px",
    footer = "",
    margin = "0",
    divMargin = "0",
    remainArrow = false,
    hiddenArrow = false,
    top = null,
    bottom = null,
    footerBg = "bg-popupFooter",
    ...props
  },
  ref
) => {
  const index = hiddenArrow ? -9999 : 1;
  const color = hiddenArrow ? "transparent" : "white";

  return (
    <div
      className={`bg-white drop-shadow-2 rounded-lg`}
      style={{ width: width }}
      ref={ref}
    >
      <div className="w-full text-black">
        {/* content */}
        {props?.children}
        {/* footer */}
        {footer && (
          <div
            className={`w-full ${footerBg} px-6 py-3 mt-3 border-t border-tabBorder rounded-b-lg`}
          >
            <div className="flex flex-row justify-end">{footer}</div>
          </div>
        )}
      </div>
      <style global="true" jsx="true">
        {`
          .popup-content > div {
            z-index: ${index} !important;
            color: ${color} !important;
            ${!remainArrow && `margin: ${margin};`}
          }
        `}
      </style>
    </div>
  );
};

// export default PopupContent;
const PopupContent = React.forwardRef(PopupComponent);
export default PopupContent;
