import React, { useEffect, useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import Input from "components/input/input";
import { FaExclamationCircle, FaMapMarkerAlt } from "react-icons/fa";
import toast from "react-hot-toast";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { GOOGLE_MAPS_API_KEY } from "configs/api";
import { mapStyleOptions } from "assets/css/map-style-options";
import { components } from "react-select";
import PasswordStrength from "./password-strength";
import LoadingIndicator from "assets/svg/loading-indicator";
import Select from "components/select/select";
import timezones from "./timezones";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import _ from "lodash";

const AutocompleteInput = (props) => (
  <components.Input {...props} isHidden={false} />
);
const EditAccountModal = ({ hide, ...props }) => {
  const { http, localstorage } = global.services;
  const [inputValue, setInputValue] = useState("");
  const [state, setState] = useState({
    verificationSent: false,
    loading: false,
    validationErrors: null,
  });
  const [percent, setPercent] = useState(0);

  const handleUpdate = async () => {
    if (props.field === "phone") {
      if (!_.isEmpty(state.phone)) {
        if (!isValidPhoneNumber(state.phone)) {
          return setState((prev) => ({
            ...prev,
            validationErrors: {
              phone: "Invalid Phone Number",
            },
          }));
        }
      } else {
        return setState((prev) => ({
          ...prev,
          validationErrors: {
            phone: "Phone Number is required",
          },
        }));
      }
    }
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const req = await http.put(`accounts/${props.userData.user.id}`, state);
      const data = {
        ...props.userData,
        profile: req.data.data.profile,
        user: req.data.data.user,
      };

      if (props.field === "email") await sendEmailVerification();

      props.setUserData(data);
      localstorage.set("user_data", JSON.stringify(data));
      if (props.field !== "email") {
        toast.success(req.data.message);
        hide();
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data?.errors,
        }));

      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const sendEmailVerification = async () => {
    try {
      await http.post(`auth/resend-email-verification`, state);
      toast.success("Verification has been sent to your email");
      setState((prev) => ({ ...prev, verificationSent: true }));
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    }
  };

  const onInputChange = (value, { action }) => {
    if (action === "input-change") {
      setInputValue(value);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleInputAddressChange = async (location) => {
    const geocodePlaceID = await geocodeByPlaceId(location.value.place_id);

    setInputValue(location?.label || "");
    setState((prev) => ({
      ...prev,
      address: location.label,
      location_data: {
        formatted_address: geocodePlaceID[0].formatted_address,
        latitude: geocodePlaceID[0].geometry.location.lat(),
        longitude: geocodePlaceID[0].geometry.location.lng(),
        place_id: geocodePlaceID[0].place_id,
      },
    }));
  };

  const Footer = () => {
    let buttonName = state.loading ? "Saving" : "Save Changes";
    if (["email"].indexOf(props.field) > -1) {
      buttonName = state.loading
        ? "Sending"
        : `Verify ${props.field === "email" ? "Email" : "Number"}`;

      if (props.field === "email" && state.verificationSent) {
        buttonName = state.loading ? "Sending" : "Resend";
      }
    }
    return (
      <div className="relative flex flex-row items-center justify-end w-full space-x-2">
        {!state.loading && (
          <Button
            key="cancel-btn"
            buttonName={`Cancel`}
            buttonClass="bg-default rounded-full py-3 px-9"
            buttonType="default"
            buttonTextClass="text-black text-sm font-bold"
            onClick={hide}
          />
        )}

        <Button
          key="edit-btn"
          buttonName={buttonName}
          buttonTextClass="text-white text-sm font-bold"
          buttonClass="bg-primary rounded-full py-3 px-12"
          buttonType="primary"
          disabled={
            (props.field === "email" &&
              !/^\w+([.]?\w+)*@\w+([.]?\w+)*(\.\w{2,3})+$/.test(state.email)) ||
            (props.field === "password" && percent < 40) ||
            state.loading
          }
          onClick={handleUpdate}
          icon={
            state.loading && (
              <div className="absolute right-3 bottom-0 top-0 flex items-center">
                <LoadingIndicator className="text-white w-5 h-5" />
              </div>
            )
          }
        />
      </div>
    );
  };

  useEffect(() => {
    if (props.field === "password") {
      state.password = "";
      state.new_password = "";
      state.password_confirmation = "";
    }

    if (props.field === "name") {
      state.first_name = props.userData?.profile.first_name;
      state.last_name = props.userData?.profile.last_name;
    }

    if (props.field === "address") {
      state.address = props.userData?.profile.address;
      state.location_data = props.userData?.profile.location_raw_data;
    }

    if (props.field === "email") state.email = props.userData?.user.email;

    if (props.field === "phone") state.phone = props.userData?.profile.phone;

    setInputValue(props.userData?.profile.address || "");
    setState({ ...state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      footer={<Footer />}
      contentClassName="rounded-lg"
      wrapperClassName="overflow-visible"
    >
      <div className="flex items-start px-4">
        <div className="w-full">
          <h3 className="text-2xl leading-9" id="modal-title">
            Edit {props.title}
          </h3>
          <div className="mt-4">
            {props.field === "name" && (
              <>
                <Input
                  inputContainerClass="input-container"
                  value={state.first_name}
                  outsideLabel={"First Name"}
                  labelClass="mb-5"
                  autoFocus={true}
                  name="first_name"
                  type="text"
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.first_name}
                />

                <Input
                  inputContainerClass="input-container"
                  value={state.last_name}
                  outsideLabel={"Last Name"}
                  labelClass="mb-5"
                  name="last_name"
                  type="text"
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.last_name}
                />
              </>
            )}

            {props.field === "email" && (
              <>
                {state.verificationSent ? (
                  <div className="flex flex-col items-center space-y-2 -mt-1">
                    <p className="text-base opacity-70">
                      We've sent an email to <b>{state.email}</b> to verify your
                      email address and activate your account. The link in the
                      email will expire in 24 hours.
                    </p>
                    <p className="text-base text-secondary font-bold">
                      Click "Resend" if you did not receive an email
                    </p>
                  </div>
                ) : (
                  <>
                    <Input
                      inputContainerClass="input-container"
                      showInputValidation={true}
                      value={state.email}
                      outsideLabel={"New Email Address"}
                      labelClass="mb-5"
                      validationPassed={/^\w+([.]?\w+)*@\w+([.]?\w+)*(\.\w{2,3})+$/.test(
                        state.email
                      )}
                      autoFocus={true}
                      name="email"
                      type="text"
                      onChange={handleInputChange}
                      errorMessage={state.validationErrors?.email}
                    />

                    <div className="flex items-center space-x-2 -mt-1 mb-2 pl-2 px-2">
                      <FaExclamationCircle className="w-8 h-8 text-gray -mt-3" />
                      <p className="text-xs opacity-70">
                        A confirmation message will be sent to your new email
                        address. Follow the link in the message to verify the
                        change of email address for your profile.
                      </p>
                    </div>
                  </>
                )}
              </>
            )}

            {props.field === "phone" && (
              <>
                {/* <Input
                  inputContainerClass="input-container"
                  value={state.phone}
                  autoFocus={true}
                  name="phone"
                  type="text"
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.phone}
                /> */}
                <PhoneInput
                  international
                  defaultCountry="PH"
                  placeholder="Enter phone number"
                  value={state.phone}
                  className={`input-phone ${
                    state.validationErrors?.phone ? "error-phone" : ""
                  }`}
                  onChange={(e) => {
                    handleInputChange({
                      target: {
                        name: "phone",
                        value: e,
                      },
                    });
                  }}
                  error={state.validationErrors?.phone}
                />
                {state.validationErrors?.phone && (
                  <p className="text-red text-xs ml-10 mt-0.5">
                    * {state.validationErrors?.phone}
                  </p>
                )}

                {/* <div className="flex items-center space-x-2 -mt-1 mb-2 pl-2 px-2">
                  <FaExclamationCircle className="w-8 h-8 text-gray -mt-3" />
                  <p className="text-xs opacity-70">
                    One-time code will be sent to your new phone number once you
                    clicked the verify phone number button.
                  </p>
                </div> */}
              </>
            )}

            {props.field === "address" && (
              <div className="relative">
                <GooglePlacesAutocomplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  debounce={500}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["au"],
                    },
                  }}
                  apiOptions={{ libraries: ["places"] }}
                  selectProps={{
                    id: "address",
                    instanceId: "address",
                    value: state.address,
                    inputValue: inputValue,
                    styles: mapStyleOptions,
                    placeholder: `Search Location Here...`,
                    onInputChange: onInputChange,
                    noOptionsMessage: () => null,
                    controlShouldRenderValue: false,
                    className: "google-autocomplete-input rounded-lg",
                    classNamePrefix: "google-autocomplete-input",
                    components: {
                      DropdownIndicator: null,
                      LoadingIndicator: null,
                      Input: AutocompleteInput,
                    },
                    onChange: async (location) =>
                      handleInputAddressChange(location),
                  }}
                />
                <FaMapMarkerAlt className="top-1 right-1 w-7 h-7 icon-input text-gray" />
              </div>
            )}

            {props.field === "password" && (
              <>
                <Input
                  inputContainerClass="input-container"
                  value={state.password}
                  outsideLabel={"Current Password"}
                  labelClass="mb-5"
                  autoFocus={true}
                  name="password"
                  type="password"
                  onChange={handleInputChange}
                  errorMessagePosition="bottom"
                  errorMessage={state.validationErrors?.password}
                />

                <div className="relative flex items-center">
                  <Input
                    showPasswordStrength={true}
                    inputContainerClass="input-container"
                    value={state.new_password}
                    outsideLabel={"New Password"}
                    labelClass="mb-5"
                    name="new_password"
                    type="password"
                    onChange={handleInputChange}
                    errorMessagePosition="bottom"
                    errorMessage={state.validationErrors?.new_password}
                  />
                </div>
                <div className="w-full -mt-2 mb-2 px-1">
                  <p className="text-sm mb-0.5">Password Strength</p>
                  <PasswordStrength
                    wrapperClassName="relative w-full"
                    password={state.new_password}
                    progressPercentage={(percent) => setPercent(percent)}
                  />
                </div>

                <p className="text-xs mb-3 pl-2 px-2 opacity-70">
                  To confirm with our Strong Password policy, you are required
                  to use a sufficiently strong password. Password must be or
                  more than 8 characters.
                </p>

                <Input
                  inputContainerClass="input-container"
                  value={state.password_confirmation}
                  outsideLabel={"Confirm Password"}
                  labelClass="mb-5"
                  name="password_confirmation"
                  type="password"
                  onChange={handleInputChange}
                  errorMessagePosition="bottom"
                  errorMessage={state.validationErrors?.password_confirmation}
                />
              </>
            )}

            {props.field === "timezone" && (
              <Select
                options={timezones}
                placeholder={"Select Time Zone"}
                value={state?.timezone}
                name="timezone"
                onChange={(data) =>
                  handleInputChange({
                    target: { name: "timezone", value: data.value },
                  })
                }
              />
            )}
          </div>
        </div>

        <span className="close absolute top-6 right-8" onClick={hide}></span>
      </div>
    </Modal>
  );
};
export default EditAccountModal;
