const CampaignDetailsHamburgerSVG = (props) => (
  <svg
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1H21" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M1 6.30811H21"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M1 11.6157H21"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default CampaignDetailsHamburgerSVG;
