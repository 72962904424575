const ContactEmptySVG = () => (
  <svg
    width="219"
    height="220"
    viewBox="0 0 219 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="m-auto"
  >
    <circle cx="109.5" cy="109.5" r="109.5" fill="#DADADA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.8483 3H171.388C174.83 3 178.132 4.36749 180.566 6.80164C183 9.23578 184.367 12.5372 184.367 15.9796V189.846C165.921 207.137 141.484 218.113 114.506 219.327H104.494C46.3422 216.71 0 168.736 0 109.939C0 57.5845 36.7426 13.8117 85.8483 3Z"
      fill="#EDEDED"
    />
    <path
      d="M184.367 28.959H197.347C199.641 28.959 201.842 29.8706 203.465 31.4934C205.088 33.1162 206 35.3171 206 37.612V72.2243C206 74.5192 205.088 76.7202 203.465 78.3429C201.842 79.9657 199.641 80.8774 197.347 80.8774H184.367V28.959Z"
      fill="white"
    />
    <path
      d="M184.367 80.8779H197.347C199.641 80.8779 201.842 81.7896 203.465 83.4124C205.088 85.0351 206 87.2361 206 89.531V124.143C206 126.438 205.088 128.639 203.465 130.262C201.842 131.885 199.641 132.796 197.347 132.796H184.367V80.8779Z"
      fill="#EC5627"
    />
    <path
      d="M184.367 132.796H197.347C199.641 132.796 201.842 133.708 203.465 135.33C205.088 136.953 206 139.154 206 141.449V176.061C206 178.356 205.088 180.557 203.465 182.18C201.842 183.803 199.641 184.714 197.347 184.714H184.367V132.796Z"
      fill="#E7B44B"
    />
    <path
      d="M132.448 106.836C132.448 114.538 130.164 122.066 125.886 128.47C121.607 134.873 115.525 139.864 108.41 142.811C101.295 145.758 93.4659 146.53 85.9125 145.027C78.3592 143.525 71.4209 139.816 65.9752 134.37C60.5296 128.925 56.821 121.986 55.3185 114.433C53.8161 106.88 54.5872 99.0503 57.5344 91.9352C60.4816 84.8201 65.4724 78.7387 71.8759 74.46C78.2793 70.1814 85.8078 67.8977 93.5091 67.8977C98.6272 67.8814 103.698 68.8774 108.43 70.8285C113.161 72.7796 117.46 75.6472 121.079 79.2662C124.698 82.8853 127.566 87.1843 129.517 91.916C131.468 96.6476 132.464 101.718 132.448 106.836Z"
      fill="#D2D2D2"
    />
    <path
      d="M121.416 133.991C117.788 137.721 113.449 140.685 108.656 142.709C103.863 144.733 98.7127 145.776 93.5098 145.776C88.3069 145.776 83.1567 144.733 78.3636 142.709C73.5705 140.685 69.2318 137.721 65.6036 133.991C67.2913 130.008 69.8109 126.432 72.9933 123.503C76.1758 120.573 79.9477 118.357 84.0563 117.004C80.9326 114.967 78.5504 111.975 77.2647 108.475C75.979 104.974 75.8584 101.152 76.9211 97.5771C77.9837 94.0024 80.1726 90.8666 83.1618 88.6367C86.1509 86.4068 89.7805 85.2021 93.5098 85.2021C97.2391 85.2021 100.869 86.4068 103.858 88.6367C106.847 90.8666 109.036 94.0024 110.099 97.5771C111.161 101.152 111.041 104.974 109.755 108.475C108.469 111.975 106.087 114.967 102.963 117.004C107.072 118.357 110.844 120.573 114.026 123.503C117.209 126.432 119.728 130.008 121.416 133.991Z"
      fill="white"
    />
  </svg>
);

export default ContactEmptySVG;
