import "assets/css/components/campaign-tests.css";
import StatsIcon from "assets/svg/campaign/stats-icon";
import { Chart, LineElement, PointElement } from "chart.js";
import CampaignDetailsButton from "components/campaigns/details/button";
import { FilterSelector } from "components/global/filterSelector";
import Select from "components/select/select";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { FaChevronDown } from "react-icons/fa";

Chart.register(PointElement, LineElement);

const filters = [
  { label: "Weekly", value: "weeks" },
  { label: "Monthly", value: "months" },
];

const lineChartOptions = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const dropdown = (setSelectedDropdown) => [
  {
    text: "Unique Visitors",
    action: () =>
      setSelectedDropdown({
        label: "Unique Visitors",
        value: "impressions",
      }),
  },
  {
    text: "Unique Opens",
    action: () =>
      setSelectedDropdown({ label: "Unique Opens", value: "opens" }),
  },
  {
    text: "Form Submissions",
    action: () =>
      setSelectedDropdown({ label: "Form Submissions", value: "conversion" }),
  },
  {
    text: "Unique Interactions",
    action: () =>
      setSelectedDropdown({
        label: "Unique Interactions",
        value: "interactions",
      }),
  },
];

export const ResultsGraph = ({
  data = {},
  onFilterChange = () => {},
  onEventChange = () => {},
  record = {},
  filterValue = "",
  setFilterValue = () => {},
  setFilterDates = () => {},
  filterDates = {},
  dateOptions = [],
  filter = "weeks",
}) => {
  const [lineData, setLineData] = useState({
    labels: [],
    datasets: [
      {
        label: "Popout A",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#28265B",
      },
      {
        label: "Popout B",
        data: [],
        fill: true,
        borderColor: "#EC5627",
      },
    ],
  });

  const [selectedDropdown, setSelectedDropdown] = useState({
    label: "Unique Interactions",
    value: "interactions",
  });

  const getLineGraph = (labels = [], reports = {}) => {
    let opens = [];
    let interactions = [];
    let impressions = [];
    let conversion = [];
    labels.forEach((value) => {
      let payload = {
        opens: 0,
        interactions: 0,
        impressions: 0,
        conversion: 0,
      };
      Object.entries(reports).forEach(([date, data]) => {
        let rec = moment(new Date(date)).format("MM/DD");
        if (value === rec) {
          payload.opens = data?.clicks ?? 0;
          payload.interactions = data?.interactions ?? 0;
          payload.impressions = data?.opens ?? 0;
          payload.conversion = 0;
        }
      });
      opens.push(payload.opens);
      interactions.push(payload.interactions);
      impressions.push(payload.impressions);
      conversion.push(payload.conversion);
    });

    return { opens, interactions, impressions, conversion };
  };

  const displayData = (labels = [], reportsData = []) => {
    const reports = getLineGraph(labels, reportsData);
    return reports[selectedDropdown.value];
  };

  useEffect(() => {
    setLineData({
      labels: data.labels,
      datasets: [
        {
          label: "Popout A",
          data: displayData(data.labels, data.graph.reports_a),
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "#28265B",
        },
        {
          label: "Popout B",
          data: displayData(data.labels, data.graph.reports_b),
          fill: true,
          borderColor: "#EC5627",
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedDropdown, filterDates]);

  const [popoutATotal, popoutBTotal] = useMemo(() => {
    return lineData.datasets.map((set) => {
      return set.data?.reduce((acc, curr) => {
        if (!acc && !curr) return 0;
        return acc + curr;
      }, 0);
    });
  }, [lineData]);

  useEffect(() => {
    if (onEventChange) {
      onEventChange(selectedDropdown.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDropdown]);

  // console.log("lineData => ", lineData);
  // console.log("data.labels", data.labels);

  return (
    <div className="w-full">
      {/* Header */}
      <div className="flex items-center border-b border-thBorder h-5/20 px-6">
        <div className="w-full">
          <div className="w-full flex items-center justify-between">
            <p>Comparison Graph</p>
            {/* Filter */}
            <nav className="hidden 2md:block 2md:bg-lightDarkGray 2md:rounded-full 2md:py-1 2md:px-1 relative">
              {filters.map((obj, index) => {
                return (
                  <FilterSelector
                    key={`filters-${index}`}
                    isActive={filter === obj.value}
                    onClick={() => onFilterChange(obj.value)}
                    filterWidth="95px"
                  >
                    {obj.label}
                  </FilterSelector>
                );
              })}
            </nav>
            <div>
              <CampaignDetailsButton
                className="bg-white"
                style={{ borderWidth: 0 }}
                dropdown={dropdown(setSelectedDropdown)}
              >
                <p className="font-light">{selectedDropdown.label}</p>
                <FaChevronDown className="ml-2 -mr-4" />
              </CampaignDetailsButton>
            </div>
          </div>
          {/*  */}
          <div className="w-full flex items-center justify-center">
            <div className="w-10/20 ">
              <Select
                placeholder="Filter"
                value={_.find(dateOptions, ["value", filterValue])?.label}
                options={dateOptions}
                onChange={({ value, dates }) => {
                  setFilterValue(value);
                  setFilterDates({
                    start: dates.start,
                    end: dates.end,
                  });
                }}
                readOnly={true}
              />
            </div>
          </div>
          {/*  */}
        </div>
      </div>
      {/* Graph Content */}
      <div className="h-15/20 px-7 flex items-center justify-between">
        <div className="w-2/12 space-y-6">
          <div>
            <p className="text-2xl">
              {selectedDropdown.label === "Form Submissions"
                ? `${numeral(popoutATotal).format("0,0.00")}%`
                : popoutATotal}
            </p>
            <div className="flex items-center space-x-2">
              <StatsIcon className="w-5 h-5 text-primary" />
              <p className="text-xs font-light">
                Test A {selectedDropdown.label}
              </p>
            </div>
          </div>
          <div>
            <p className="text-2xl">
              {selectedDropdown.label === "Form Submissions"
                ? `${numeral(popoutBTotal).format("0,0.00")}%`
                : popoutBTotal}
            </p>
            <div className="flex items-center space-x-2">
              <StatsIcon className="w-5 h-5 text-secondary" />
              <p className="text-xs font-light">
                Test B {selectedDropdown.label}
              </p>
            </div>
          </div>
          <div>
            <p className="text-2xl">
              {selectedDropdown.label === "Form Submissions"
                ? `${numeral(popoutATotal + popoutBTotal).format("0,0.00")}%`
                : popoutATotal + popoutBTotal}
            </p>
            <p className="text-xs font-light">
              {(selectedDropdown.label === "Form Submissions" ||
                selectedDropdown.label === "User Interactions") &&
                "Total "}
              {selectedDropdown.label}
            </p>
          </div>
        </div>
        <div className="w-10/12 flex justify-center">
          <Line data={lineData} options={lineChartOptions} height={100} />
        </div>
      </div>
    </div>
  );
};

export default ResultsGraph;
