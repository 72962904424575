import { InlineWidget } from "react-calendly";
import styled from "styled-components";
import CCIconLogoContainer from "../commons/cc-logo-container";
import PowerByIconSVG from "assets/svg/powered-by-icon";
import _ from "lodash";

const CalendyEventContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const CalendyEvent = ({ data }) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <CalendyEventContainer>
          {!_.isEmpty(data?.link) && (
            <InlineWidget
              iframeTitle="Calendly Scheduling Page"
              pageSettings={{
                backgroundColor: "transparent",
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: "00a2ff",
                textColor: "4d5055",
              }}
              styles={{
                height: "100vh",
                maxWidth: "635px",
              }}
              url={data?.link}
            />
          )}
        </CalendyEventContainer>
      </div>
      {data?.show_logo && (
        <CCIconLogoContainer>
          <PowerByIconSVG />
        </CCIconLogoContainer>
      )}
    </div>
  );
};

export default CalendyEvent;
