import { captureException } from "@sentry/react";
import timezones from "components/account/timezones";
import Select from "components/select/select";
import format from "date-fns/format";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { BsCalendarWeek } from "react-icons/bs";

const DateRangePickerComp = ({ data, onSelectDateRange }) => {
  // date state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //initial selection
  useEffect(() => {
    if (data.value) {
      const { startDate, endDate } = data.value;
      try {
        setRange([
          {
            startDate:
              typeof startDate === "string" ? new Date(startDate) : startDate,
            endDate: typeof endDate === "string" ? new Date(endDate) : endDate,
            key: "selection",
          },
        ]);
      } catch (e) {
        captureException(e);
      }
    }
  }, [data]);

  // open close
  const [open, setOpen] = useState(false);
  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className="calendarWrap w-full relative">
      <div className="w-full relative">
        <input
          value={`${format(range[0].startDate, "eee, MMM dd, yy")} - ${format(
            range[0].endDate,
            "eee, MMM dd, yy"
          )}`}
          readOnly
          className="w-full pl-3 pr-10 py-2.5 border border-thBorder datepicker-input"
          onClick={() => setOpen((open) => !open)}
        />
        <BsCalendarWeek className="absolute right-2.5 top-2.5 text-2xl" />
      </div>

      <div ref={refOne}>
        {open && (
          <div className="calendarElement">
            <div className="relative">
              <DateRangePicker
                onChange={(item) => {
                  setRange([item.selection]);
                  onSelectDateRange(
                    {
                      startDate: item.selection["startDate"],
                      endDate: item.selection["endDate"],
                    },
                    null
                  );
                }}
                editableDateInputs={false}
                moveRangeOnFirstSelection={false}
                ranges={range}
                direction="horizontal"
                className="inline-flex"
              />
            </div>
          </div>
        )}
      </div>
      {/* Timezone Select */}
      <Select
        options={timezones}
        placeholder={"Select Time Zone"}
        value={data?.timezone}
        onChange={(e) => onSelectDateRange(null, e.value)}
      />
    </div>
  );
};

export default DateRangePickerComp;
