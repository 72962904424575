import "assets/css/components/campaign-details.css";
import ChevronDownSVG from "assets/svg/chevron-down";
import ChevronUpSVG from "assets/svg/chevron-up";
import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import Checkbox from "components/checkbox/checkbox";
import Toggle from "components/toggle/toggle";
import { Context } from "context/context";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaPlus } from "react-icons/fa";
import Input from "../preview/component/commons/input";

export const CampaignSettings = () => {
  const { campaign, setCampaign } = useContext(Context);
  const { http } = global.services;
  const [loaded, setLoaded] = useState(campaign && campaign.id ? true : false);
  const [time, setTime] = useState(campaign?.display_time || 1);
  const [zIndex, setZIndex] = useState(campaign?.z_index || null);
  const [disableMobile, setDisableMobile] = useState(
    campaign.disable_mobile ? true : false
  );
  const [urlExclusions, setUrlExclusions] = useState([]);
  const [saving, setSaving] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [dirty, setDirty] = useState(false);

  const handleUpdateTime = (type) => {
    setDirty(true);
    let payload = Number(time) + 1;
    if (type === "increment") {
      payload = Number(time) + 1;
      setTime(payload);
    }
    if (Number(time) <= 1) return;
    if (type === "decrement") {
      payload = Number(time) - 1;
      setTime(payload);
    }
  };

  const updateDisableMobile = (e) => {
    setDirty(true);
    const isChecked = e.target.checked;
    setDisableMobile(!isChecked);
  };

  const addNewExclusion = () => {
    setDirty(true);
    setUrlExclusions([
      ...urlExclusions,
      {
        url: "",
        starts_with: false,
      },
    ]);
  };

  const updateExclusionUrl = (idx, value) => {
    setDirty(true);
    const newExclusions = [...urlExclusions];
    newExclusions[idx] = {
      ...newExclusions[idx],
      url: value,
    };
    setUrlExclusions(newExclusions);
  };

  const updateExclusionStartsWith = (idx, value) => {
    setDirty(true);
    const newExclusions = [...urlExclusions];
    newExclusions[idx] = {
      ...newExclusions[idx],
      starts_with: value,
    };
    setUrlExclusions(newExclusions);
  };

  const removeExclusion = (idx) => {
    setDirty(true);
    const newExclusions = [...urlExclusions];
    newExclusions.splice(idx, 1);
    setUrlExclusions(newExclusions);
  };

  const saveCampaignSettings = async () => {
    if (!campaign.id) return;

    setValidationErrors({});

    try {
      console.log(campaign);
      const params = {
        id: campaign.id || null,
        disable_mobile: disableMobile,
        display_time: time,
        z_index: zIndex,
        settings: { url_exclusions: urlExclusions },
      };
      setSaving(true);
      const res = await http.put(`campaigns/${campaign.id}`, params);

      console.log(res.data);
      setCampaign((prev) => ({
        ...prev,
        disable_mobile: res?.data?.data?.disable_mobile,
        display_time: res?.data?.data?.display_time,
        z_index: res?.data?.data?.z_index,
        settings: res?.data?.data?.settings,
      }));
      if (res.data.success) {
        toast.success("Campaign settings updated successfully");
      } else {
        toast.error("It appears that something went wrong");
      }
    } catch (e) {
      if (e.data?.data) {
        setValidationErrors(e.data.data);
      }
      toast.error("It appears that something went wrong");
    }
    setSaving(false);
  };

  useEffect(() => {
    if (campaign.id) {
      setLoaded(true);
      setTime(campaign.display_time || 1);
      setZIndex(campaign.z_index || null);
      setDisableMobile(campaign.disable_mobile ? true : false);
      setUrlExclusions(campaign.settings?.url_exclusions ?? []);
    }
  }, [campaign, loaded]);

  return loaded ? (
    <div className="h-full bg-white border border-thBorder divide-y divide-thBorder">
      <div className="w-full md:flex">
        <div className="w-6/20 pl-10 py-6">
          <p className="text-xl">Display Time</p>
        </div>
        <div className="w-14/20 py-6 pl-10 md:pl-0">
          <p>Configure the delay before the popout tab appears</p>
          <div className="settings-time-container mt-4">
            <p>Display after</p>
            <div className="settings-time-row">
              <input
                style={{ width: "80px" }}
                type="number"
                value={time}
                min="1"
                max="300"
                className="settings-time"
                onChange={(e) => {
                  if (Number(e.target.value)) {
                    setDirty(true);
                    setTime(Number(e.target.value));
                  }
                }}
              />
              <div className="settings-input-arrows">
                <div
                  className="settings-input-icon"
                  onClick={() => handleUpdateTime("increment")}
                >
                  <ChevronUpSVG className="text-black h-3 w-3" />
                </div>
                <div className="border-b border-thBorder" />
                <div
                  className="settings-input-icon"
                  onClick={() => handleUpdateTime("decrement")}
                >
                  <ChevronDownSVG className="text-black h-3 w-3" />
                </div>
              </div>
            </div>
            <p>second(s)</p>
          </div>
        </div>
      </div>
      {/* Z index */}
      <div className="w-full md:flex">
        <div className="w-6/20 pl-10 py-6">
          <p className="text-xl">Popout Z-Index</p>
        </div>
        <div className="w-14/20 py-6 px-10 md:pl-0">
          <p>
            Having trouble with your popout appearing under or over your website
            content? Try changing the z-index value.
          </p>
          <div className="settings-time-container mt-4">
            <Input
              style={{ width: "230px" }}
              type="number"
              value={zIndex}
              min="0"
              max="2147483647"
              placeholder="2147483647"
              onChange={(e) => {
                setDirty(true);
                setZIndex(Number(e.target.value) ? e.target.value : null);
              }}
            />
          </div>
        </div>
      </div>
      {/* mobie view */}
      <div className="w-full md:flex">
        <div className="w-6/20 pl-10 py-6">
          <p className="text-xl">Mobile Devices</p>
        </div>
        <div className="w-14/20 py-6 pl-10 md:pl-0">
          <p>Show popout on mobile devices</p>
          <div className="w-full my-3 flex items-center ">
            <Toggle checked={!disableMobile} onChange={updateDisableMobile} />
            <p className="ml-3">{!disableMobile ? "Enabled" : "Disabled"}</p>
          </div>
        </div>
      </div>
      {/* excluded URLs */}
      <div className="w-full md:flex">
        <div className="w-6/20 pl-10 py-6">
          <p className="text-xl">Excluded URLs</p>
        </div>
        <div className="w-14/20 py-6 pl-10 md:pl-0">
          <p>Don't show campaign on the following URLs:</p>
          <div className="w-full my-3 pr-10 grid grid-cols-1 gap-4 justify-items-start">
            {urlExclusions.map((x, idx) => (
              <div key={idx} className="w-full flex flex-wrap gap-x-4 gap-y-1">
                <Input
                  inputContainerClass="input-container flex-1"
                  placeholder="https://www.example.com/my-page"
                  type="text"
                  value={x.url}
                  onChange={(e) => updateExclusionUrl(idx, e.target.value)}
                  error={
                    validationErrors[`settings.url_exclusions.${idx}.url`] && {
                      message:
                        validationErrors[`settings.url_exclusions.${idx}.url`],
                    }
                  }
                />
                <Checkbox
                  containerStyle={{ flex: 1 }}
                  labelClass="select-none cursor-pointer"
                  name={idx}
                  checked={x.starts_with}
                  label="Include all URLs starting with this"
                  onChange={(e) =>
                    updateExclusionStartsWith(idx, !!e.target.checked)
                  }
                />
                <Button
                  buttonName="Remove"
                  buttonTextClass="text-black text-xs font-bold flex justify-center"
                  buttonClass="bg-default border-none rounded-full py-1.5 border px-6"
                  buttonType="secondary"
                  onClick={(e) => removeExclusion(idx)}
                  disabled={saving}
                />
              </div>
            ))}
            <Button
              buttonName="Add new exclusion"
              buttonTextClass="text-white text-xs font-bold flex justify-center"
              buttonClass="bg-primary border-none rounded-full py-1.5 border px-6"
              buttonType="secondary"
              iconAlign="left"
              icon={<FaPlus className="mr-1 mt-0.5" />}
              onClick={addNewExclusion}
              disabled={saving}
            />
          </div>
        </div>
      </div>
      <div className="py-6 pr-10 text-right">
        <Button
          buttonName="Save Settings"
          buttonTextClass="text-white text-sm font-bold flex justify-center"
          buttonClass="bg-secondary border-none rounded-full py-3 border px-12"
          buttonType="secondary"
          onClick={saveCampaignSettings}
          disabled={!dirty || saving}
        />
      </div>
    </div>
  ) : (
    <div className="w-full grid items-center justify-center">
      <LoadingIndicator className="w-7 h-7 text-secondary" />
    </div>
  );
};

export default CampaignSettings;
