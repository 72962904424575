import React from "react";
import Popup from "reactjs-popup";
import { FaQuestion } from "react-icons/fa";
import "./styles.css";
import PencilIconSVG from "assets/svg/pencil-icon";
import PlusIconSVG from "assets/svg/plus-icon";

const icons = {
  default: {
    active: <FaQuestion className="text-white" />,
    inactive: <FaQuestion className="text-white" />,
  },
  edit: {
    active: <PencilIconSVG className="text-white" />,
    inactive: <PencilIconSVG className="text-white" />,
  },
  editBlack: {
    active: <PencilIconSVG className="text-white" />,
    inactive: <PencilIconSVG className="text-black" />,
  },
  plusIcon: {
    active: <PlusIconSVG className="m-auto" />,
    inactive: <PlusIconSVG className="m-auto" />,
  },
};

const PopUp = (
  {
    position = "bottom center",
    styles = {},
    contentClassName = "",
    className = "defaultButton",
    customButton = null,
    icon = "default",
    activeColor = "bg-primary",
    inactiveColor = "bg-secondary",
    closeOnDocumentClick = false,
    ...props
  },
  ref
) => {
  return (
    <Popup
      className={contentClassName}
      ref={ref}
      contentStyle={styles}
      trigger={(open) => {
        return (
          <button
            type="button"
            className={
              className +
              ` ${open ? activeColor : inactiveColor}` +
              ` ${icon === "default" && !open ? "tooltip-btn" : ""} `
            }
            style={{
              borderRadius: "99999px",
              cursor: "pointer",
            }}
          >
            {open ? icons[icon]?.active : icons[icon]?.inactive}
          </button>
        );
      }}
      position={position}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      {props?.children}
    </Popup>
  );
};

const PopupComponent = React.forwardRef(PopUp);

export default PopupComponent;
