const PasswordDottedIconSVG = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="112"
      height="7"
      viewBox="0 0 112 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3.5" cy="3.5" r="3.5" fill="black" />
      <circle cx="18.5" cy="3.5" r="3.5" fill="black" />
      <circle cx="33.5" cy="3.5" r="3.5" fill="black" />
      <circle cx="48.5" cy="3.5" r="3.5" fill="black" />
      <circle cx="63.5" cy="3.5" r="3.5" fill="black" />
      <circle cx="78.5" cy="3.5" r="3.5" fill="black" />
      <circle cx="93.5" cy="3.5" r="3.5" fill="black" />
      <circle cx="108.5" cy="3.5" r="3.5" fill="black" />
    </svg>
  );
};

export default PasswordDottedIconSVG;
