import mobileTop from "assets/images/mobile-top-view.png";
import { DynamicEffects } from "components/global/dynamicEffects";
import { Context } from "context/context";
import { useContext, useEffect } from "react";
import PopupExampleMobileTemplate from "./mobile/popup-example-mobile-template";
import PopupExampleContactMethod from "./popup-example-contact-method";
import PopupExampleTab from "./popup-example-tab";
import PopupExampleTemplate from "./popup-example-template";
import "./popup-example.css";

const desktopClass =
  "popup-root absolute left-0 top-0 bottom-0 right-0 rounded-b-xl";
const mobileClass = "rounded-mobile";

const stepsTemplate = [
  "template",
  "content",
  "contactM",
  "contactD",
  "style",
  "preview",
];

const stepsContactMethod = ["contactM", "contactD", "style", "preview"];

export default function PopupExampleRoot() {
  const { popup, selectedTab, view, mobileContentOpen, setMobileContentOpen } =
    useContext(Context);
  const mobile = view === "mobile" ? true : false;
  const mbadtlClass =
    selectedTab !== "popout"
      ? mobileContentOpen
        ? "popup-root-mobile-bg"
        : "popup-root-mobile popup-root"
      : "popup-root-mobile popup-root";

  useEffect(() => {
    if (mobile) {
      setMobileContentOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return selectedTab !== "basic" && popup.id ? (
    <div
      className={mobile ? `${mobileClass} ${mbadtlClass}` : desktopClass}
      style={{
        height: mobile ? `113%` : `100%`,
        zIndex: 100,
        fontFamily: "Outfit",
        overflow: "hidden",
      }}
    >
      {mobile && (
        <img src={mobileTop} alt="mobile-top" className="mobile-image-top" />
      )}
      <ViewExamplePopout
        mobile={mobile}
        selectedTab={selectedTab}
        mobileContentOpen={mobileContentOpen}
        popup={popup}
      />
    </div>
  ) : (
    <></>
  );
}

function ViewExamplePopout({ mobile, selectedTab, mobileContentOpen, popup }) {
  const effects = popup.options?.effects
    ? Object.entries(popup.options?.effects)
        .filter(([, v]) => v.enabled)
        .map(([k]) => k)
    : [];

  if (mobile) {
    return (
      <div data-cc="mobile-template">
        {(selectedTab === "popout" || !mobileContentOpen) && (
          <PopupExampleTab />
        )}
        {stepsTemplate.includes(selectedTab) && mobileContentOpen && (
          <PopupExampleMobileTemplate />
        )}
      </div>
    );
  } else {
    return (
      <>
        {selectedTab === "popout" && (
          <PopupExampleTab
            animationImage="slideIn-image"
            animationDiv="slideIn-div"
          />
        )}
        {selectedTab === "effects" &&
          !effects.includes("peep") &&
          !effects.includes("red_carpet") && (
            <DynamicEffects
              effects={effects}
              cornerStyle={popup.corner_style}
              color={popup.primary_colour}
            >
              <PopupExampleTab
                animationImage={`slideIn-image`}
                animationDiv={`slideIn-div$`}
              />
            </DynamicEffects>
          )}
        {selectedTab === "effects" &&
          effects.includes("peep") &&
          !effects.includes("red_carpet") && (
            <>
              <DynamicEffects
                effects={effects}
                cornerStyle={popup.corner_style}
                color={popup.primary_colour}
              >
                <PopupExampleTab
                  animationImage="slideIn-image"
                  animationDiv="slideIn"
                />
              </DynamicEffects>
              <PopupExampleTemplate
                animation="slideIn"
                viewExample={true}
                peep={true}
              />
            </>
          )}
        {selectedTab === "effects" && effects.includes("red_carpet") && (
          <>
            <DynamicEffects
              effects={effects}
              cornerStyle={popup.corner_style}
              color={popup.primary_colour}
            >
              <PopupExampleTab
                animationImage="slideIn-image"
                animationDiv="slideIn"
              />
            </DynamicEffects>
            <PopupExampleTemplate animation="slideIn" viewExample={true} />
            <PopupExampleContactMethod viewExample={false} />
          </>
        )}
        {stepsTemplate.includes(selectedTab) && (
          <>
            <PopupExampleTab
              animationImage="slideIn-image"
              animationDiv="slideIn"
            />
            <PopupExampleTemplate
              animation="slideIn"
              viewExample={
                selectedTab === "template" || selectedTab === "content"
                  ? false
                  : true
              }
            />
            <PopupExampleContactMethod
              viewExample={
                stepsContactMethod.includes(selectedTab) ? false : true
              }
            />
          </>
        )}
      </>
    );
  }
}
