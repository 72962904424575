import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import userActions from "redux/modules/user/actions";
import http from "services/https";
import localstorage from "services/local-storage";

const GoogleCallback = ({ setUserData }) => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const scope = params.get("scope");
  const authuser = params.get("authuser");
  const prompt = params.get("prompt");
  const optin = localStorage.getItem("optin");

  const [state, setSate] = useState({
    error: null,
  });
  const history = useHistory();

  const handleCallback = async () => {
    if (!code || !scope || !authuser || !prompt)
      return setSate((prevState) => ({
        ...prevState,
        error: "There was an error, something wrong",
      }));

    try {
      const res = await http.get("auth/login/google/callback", {
        code,
        scope,
        authuser,
        prompt,
        optin,
      });
      localstorage.set("user_data", JSON.stringify(res.data));
      setUserData(res.data);
      history.replace("/dashboard");
    } catch (error) {
      console.error(error);
      setSate((prevState) => ({ ...prevState, error: error.data.message }));
    }
  };

  useEffect(() => {
    handleCallback();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex items-center justify-center h-full w-full py-20">
      {state.error ? (
        state.error
      ) : (
        <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
          <svg
            fill="none"
            class="w-6 h-6 animate-spin"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>
          <div>Loading...</div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(GoogleCallback);
