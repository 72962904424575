import React from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import { FaTimes } from "react-icons/fa";

export default function ConfirmModal({
  hide,
  btnType = "error",
  btnBg = "bg-lightRed",
  ...props
}) {
  const Footer = () => {
    return (
      <div className="relative flex flex-wrap space-x-3">
        <Button
          key="cancel-btn"
          buttonName="CANCEL"
          buttonClass="bg-default rounded-full py-3 px-9"
          buttonType="default"
          buttonTextClass="text-black text-sm font-bold"
          onClick={() => hide(false)}
        />
        <Button
          key="save-btn"
          buttonName={props?.buttonText}
          buttonClass={`${btnBg} rounded-full py-3 px-12`}
          buttonType={btnType}
          buttonTextClass="text-white text-sm font-bold"
          onClick={() => hide(true)}
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-15/20 md:w-12/20 2md:w-9/20 3md:w-6/20"
      responsive={true}
      contentClassName="rounded-lg"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-tabBorder"
    >
      <div className="w-full px-4 py-3">
        <div className="w-full flex justify-between items-center mb-3">
          <p className="text-2xl">{props?.title}</p>
          <button className="opacity-70" onClick={() => hide()}>
            <FaTimes className="opacity-70" />
          </button>
        </div>
        <p className="text-base">{props?.description}</p>
      </div>
    </Modal>
  );
}
