import React, { memo } from "react";
import Select from "./select";
import timezones from "components/account/timezones";

const weekdays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
const SelectWeekday = ({ data, onSelectDay, errorMessage }) => {
  return (
    <div>
      {/* Weekdays list */}
      <div
        className="flex flex-row rounded-md overflow-hidden"
        style={{ height: 45 }}
      >
        {weekdays.map((day, index) => {
          let selected = -1;

          if (data.value?.length > 0)
            selected = data.value.findIndex((item) => item === day);

          return (
            <div
              key={index}
              style={{ width: 35, marginLeft: 0.6, marginRight: 0.6 }}
              onClick={() => onSelectDay(day)}
              className={`px-1 font-bold flex items-center justify-center cursor-pointer
                        hover:bg-secondary hover:text-white
                        ${
                          selected > -1
                            ? "bg-secondary text-white"
                            : "bg-white text-primary"
                        }`}
            >
              <p className="text-xs capitalize">{day.substring(0, 3)}</p>
            </div>
          );
        })}
      </div>

      {/* Timezone Select */}
      <Select
        options={timezones}
        placeholder={"Select Time Zone"}
        value={data?.timezone}
        onChange={(e) => onSelectDay(null, e.value)}
      />
      {errorMessage && <p className="text-red text-xs">* {errorMessage}</p>}
    </div>
  );
};
export default memo(SelectWeekday);
