import styled from "styled-components";
import { device } from "../../../screens";

const Svg = styled.svg`
  margin-right: 12px;
  width: 16px;
  height: 16px;

  @media ${device.xs} {
    margin-right: 13.5px;
    width: 18px;
    height: 18px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-right: 14px;
    width: 20px;
    height: 20px;
  }
`;

const SendSVG = ({ fill = "#808080", responsive = 140 }) => {
  return (
    <Svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      responsive={responsive}
    >
      <path
        d="M2.5 9.16675L18.3333 1.66675L10.8333 17.5001L9.16667 10.8334L2.5 9.16675Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SendSVG;
