import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import _ from "lodash";
import parseUrl from "parse-url";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { useNotifications } from "redux/modules/notification/hooks";
import userActions from "redux/modules/user/actions";
import RouteList from "./list";
import { sendVirtualPageview } from "utils/gtm-triggers";

export const routerHistory = createBrowserHistory();
const SentryRoute = Sentry.withSentryRouting(Route);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    sendVirtualPageview(window.location.href, document);
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const RouterComponent = ({ user = null, setUserData }) => {
  const { localstorage, http } = global.services;
  const [state, setState] = useState({
    renderContent: false,
  });
  const { setNotifications, setNotificationUserReads } = useNotifications();

  const fetchNotifications = async (page) => {
    try {
      const { data: response, status } = await http.get("notifications", {
        page,
        for: "header",
      });
      if (status === 200) {
        const nextNotifications = [...response.data];
        setNotifications(nextNotifications);
      }
    } catch (ex) {
      console.error(ex);
      toast.error(ex.message);
    }
  };

  const fetchUserNotifications = async () => {
    try {
      const { data: response, status } = await http.get("user-notifications", {
        status: "read",
      });
      if (status === 200) {
        setNotificationUserReads(response.data);
      }
    } catch (ex) {
      console.error(ex);
      toast.error(ex.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // const handleLogout = () => {
  //   const parts = parseUrl(window.location.href);
  //   const pathname = parts.pathname || "";
  //   const search = parts.search || "";
  //   const hash = parts.hash || "";

  //   localstorage.set(
  //     "redirectUrl",
  //     encodeURIComponent(pathname + search + hash)
  //   );
  //   localstorage.remove("user_data");
  //   setUserData(null);
  //   return <Redirect to={{ pathname: "/login" }} />;
  // };

  useEffect(() => {
    const getUserData = async () => {
      const userData = localstorage.get("user_data");

      if (userData.value) {
        const userJson = JSON.parse(userData.value);
        try {
          //this is the one checking if token is still working
          const user = await http.get("auth/user-profile");
          let payload = _.clone(user.data);
          delete payload.profile;
          const setUser = {
            ...userJson,
            user: payload,
            profile: user.data.profile,
          };
          setUserData(setUser);
          localstorage.set("user_data", JSON.stringify(setUser));
        } catch (error) {
          console.error(error);
          localstorage.remove("user_data");
          setUserData(null);
          toast.error("It appears that something went wrong");
          return (window.location.href = `/login`);
        }
      }

      setState((prevState) => ({ ...prevState, renderContent: true }));
    };

    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localstorage, setUserData]);

  useEffect(() => {
    if (_.isEmpty(user)) {
      return;
    } else {
      if (user?.access_token) {
        fetchNotifications(1);
        fetchUserNotifications();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const renderRoutes = () => {
    const routes = RouteList.map((route, routeIndex) => {
      return (
        <SentryRoute
          exact
          key={`route-${routeIndex}`}
          path={route.path}
          render={() => {
            if (route.authRequired) {
              if (!user) {
                const parts = parseUrl(window.location.href);
                const pathname = parts.pathname || "";
                const search = parts.search || "";
                const hash = parts.hash || "";

                if (pathname.startsWith("/campaign-details")) {
                  localstorage.set("redirectUrl", "/campaigns");
                } else {
                  localstorage.set(
                    "redirectUrl",
                    encodeURIComponent(pathname + search + hash)
                  );
                }

                return (
                  <Redirect
                    to={{
                      pathname: "/login",
                    }}
                  />
                );
              }
            }

            return route.component();
          }}
        />
      );
    });

    return routes;
  };

  return (
    state.renderContent && (
      <React.Fragment>
        <Toaster toastOptions={{ duration: 3000 }} position="top-right" />
        <Router history={routerHistory}>
          <ScrollToTop />
          <Switch>{renderRoutes()}</Switch>
        </Router>
      </React.Fragment>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterComponent);
