import React from "react";
import styled from "styled-components";
import MultipleAddressAddress from "./address";
import CCIconLogoContainer from "../../commons/cc-logo-container";
import PowerByIconSVG from "assets/svg/powered-by-icon";
import HeaderTemplate from "../../commons/header-template";
import { sanitizeValueHTML } from "utils/constant";
import _ from "lodash";

const MultipleAddressContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 28px;
`;

const MultipleAddressItem = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${({ hidden }) => (hidden ? `display: none;` : "")}
`;

const MultipleAddress = ({ data }) => {
  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <HeaderTemplate
          family={data.font?.family}
          width={data.width}
          dangerouslySetInnerHTML={{ __html: sanitizeValueHTML(data.title) }}
          contacts={true}
        />
        <MultipleAddressContainer>
          {(data.contacts || []).map((obj, addressIndex) => {
            let valueShow =
              !_.isEmpty(obj.contact_title?.value) ||
              !_.isEmpty(obj?.address?.value) ||
              !_.isEmpty(obj.email_address?.value) ||
              !_.isEmpty(obj?.contact_number?.value);
            if (valueShow) {
              return (
                <MultipleAddressItem key={`address-${addressIndex}`}>
                  <MultipleAddressAddress
                    data={obj}
                    color={data.button_colour}
                    width={data.width}
                  />
                </MultipleAddressItem>
              );
            } else {
              return null;
            }
          })}
        </MultipleAddressContainer>
      </div>
      {data?.show_logo && (
        <CCIconLogoContainer>
          <PowerByIconSVG />
        </CCIconLogoContainer>
      )}
    </>
  );
};

export default MultipleAddress;
