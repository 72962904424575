const PersonCircleIconSVG = ({ className = "" }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "multiply" }}>
      <circle cx="20" cy="20" r="20" fill="#F0F0F0" />
    </g>
    <circle cx="20" cy="15" r="5" fill="#C4C4C4" />
    <path
      d="M11 29.6923C11 25.444 14.444 22 18.6923 22H21.3077C25.556 22 29 25.444 29 29.6923C29 29.8622 28.8622 30 28.6923 30H11.3077C11.1378 30 11 29.8622 11 29.6923Z"
      fill="#C4C4C4"
    />
  </svg>
);

export default PersonCircleIconSVG;
