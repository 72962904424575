const QouteSVG = ({ className }) => {
  return (
    <svg
      className={className ?? ""}
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.04959 0C3.71901 6.07438 0.123967 14.3802 0 21.8182C0 26.6529 3.22314 30 7.56198 30C11.6529 30 15 26.6529 15 22.562C15 18.595 12.1488 15.7438 8.30579 15.3719C8.80165 10.4132 10.1653 5.70247 12.5207 1.36363L9.04959 0ZM31.1157 0C25.7851 6.07438 22.1901 14.3802 22.0661 21.8182C22.0661 26.6529 25.2893 30 29.6281 30C33.719 30 37.0661 26.6529 37.0661 22.562C37.0661 18.595 34.2149 15.7438 30.3719 15.3719C30.8678 10.4132 32.2314 5.70247 34.5868 1.36363L31.1157 0Z"
        fill="white"
      />
    </svg>
  );
};

export default QouteSVG;
