import zapier from "assets/images/integrations-zapier.png";
import IntegrationItem from "components/integrations/integrations-item";
import IntegrationModal from "components/integrations/integrations-modal";
import Layout from "layouts/layout";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDialogHook } from "utils/customhooks";

const Integration = () => {
  const { http } = global.services;
  const showModal = useDialogHook(IntegrationModal);
  const [integrations, setIntegrations] = useState([
    {
      name: "zapier",
      connected: false,
      brand: zapier,
      metadata: {},
    },
  ]);

  const fetchIntegrations = async () => {
    try {
      const search = new URLSearchParams(window.location.search);
      const results = await http.get("apikey", {
        show: search.get("show") ?? "owned",
      });

      setIntegrations((prev) => {
        return [...prev].map((integration) => {
          const current = results.data.data.find(
            (item) => item.app_name === integration.name
          );
          if (!current) return integration;
          return {
            ...integration,
            connected: current.status === "active",
            metadata: current,
          };
        });
      });
    } catch (err) {
      console.error(err);
      toast.error("Error while fetching integrations");
    }
  };

  const createApiKey = async (app_name) => {
    try {
      const result = await http.post("apikey", { status: "active", app_name });
      if (result.data.success) {
        setIntegrations((prev) => {
          return prev.map((item) => {
            if (item.name !== app_name) return item;
            return {
              ...item,
              connected: result.data.data.status === "active",
              metadata: result.data.data,
            };
          });
        });
        toast.success("Zapier API successfully created");
      } else toast.error(result.data.message);
    } catch (err) {
      toast.error(err.data.message);
    }
  };

  const updateStatusApiKey = async (id, app_name) => {
    try {
      const result = await http.put(`apikey/${id}`, {
        status: "disabled",
        app_name,
      });
      if (result.data.success) {
        setIntegrations((prev) => {
          return prev.map((item) => {
            if (item.name !== app_name) return item;
            return {
              ...item,
              connected: result.data.data.status === "active",
              metadata: result.data.data,
            };
          });
        });
        toast.success("Zapier successfully disconnected");
      } else toast.error(result.data.message);
    } catch (err) {
      toast.error(err.data.message);
    }
  };

  useEffect(() => {
    fetchIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Integrations" routeName="integrations">
      <div className="flex flex-col flex-1 w-full">
        <div className="flex flex-wrap -mx-2 overflow-hidden">
          <div className="w-full px-2 my-2 overflow-hidden">
            <h1 className="text-xl font-medium ">Integrations</h1>
          </div>
          <div className="w-full px-2 my-2 overflow-hidden">
            <div className="flex flex-wrap -mx-2 overflow-hidden">
              {integrations.map((integration, integrationIndex) => (
                <div
                  key={`integration-item-${integrationIndex}`}
                  className="w-full px-2 my-2 overflow-hidden"
                >
                  <IntegrationItem
                    name={integration.name}
                    brand={integration.brand}
                    connected={integration.connected}
                    content={integration?.metadata?.token}
                    details={integration?.metadata}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isEmpty(integration.metadata)) {
                        return showModal(integration, fetchIntegrations);
                      }
                      return createApiKey(integration.name);
                    }}
                    disconnect={() => {
                      if (!isEmpty(integration.metadata)) {
                        updateStatusApiKey(
                          integration.metadata?.id,
                          integration.name
                        );
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Integration;
