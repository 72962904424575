import CampaignDetailsInfoIconSVG from "assets/svg/campaign-details-info-icon";
import React from "react";
import Popup from "reactjs-popup";
import "./styles.css";

const TooltipComponent = (
  {
    position = "right center",
    className = "",
    closeOnDocumentClick = true,
    hiddenArrow = false,
    text = "",
  },
  ref
) => {
  const color = hiddenArrow ? "transparent" : "#292d3e";
  return (
    <Popup
      ref={ref}
      contentStyle={{ marginLeft: "10px" }}
      trigger={(open) => {
        return (
          <button type="button" className={className}>
            <CampaignDetailsInfoIconSVG stroke={open ? "#28265B" : "#A3A3A3"} />
          </button>
        );
      }}
      position={position}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      <div
        className="text-white rounded-lg p-2 text-sm"
        style={{ background: "#292d3e" }}
      >
        {text}
      </div>
      <style global="true" jsx="true">
        {`
          .popup-arrow {
            color: ${color} !important;
          }
        `}
      </style>
    </Popup>
  );
};

const TooltipInfo = React.forwardRef(TooltipComponent);

export default TooltipInfo;
