import React from "react";

const Modal = ({
  footer = null,
  width = "510px",
  closeOnoutsideClick = () => {},
  wrapperClassName = "",
  contentClassName = "",
  contentHeight = "100%",
  footerClass = "",
  footerDefaultClass = "sm:flex sm:flex-row-reverse",
  full = false,
  responsive = false,
  ...props
}) => {
  return (
    <div
      id="modal-content"
      className="fixed z-50 inset-0 animated fadeIn faster h-full"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="items-center h-full justify-center max-h-screen pt-4 px-4 pb-20 text-center block md:flex sm:p-0 overflow-y-auto custom-scroll">
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
          aria-hidden="true"
          onClick={() => closeOnoutsideClick()}
        ></div>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-full"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={`${
            responsive ? width : "sm:w-full"
          } relative inline-block align-bottom bg-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle ${wrapperClassName}`}
          style={!responsive ? { width: width } : null}
        >
          {/*  content here */}
          <div
            className={`${!full && "bg-white p-4"} ${contentClassName}`}
            style={{ height: contentHeight }}
          >
            {props?.children}
          </div>
          {/* footer here */}
          {footer && (
            <div
              className={`px-4 py-5 sm:px-6 ${footerDefaultClass} ${footerClass}`}
            >
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
