export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const PUSHER_API_KEY = process.env.REACT_APP_PUSHER_API_KEY;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
export const INITIAL_BILLING_PERIOD = process.env
  .REACT_APP_INITIAL_BILLING_PERIOD
  ? process.env.REACT_APP_INITIAL_BILLING_PERIOD
  : "month";
export const WP_DOWNLOAD = process.env.REACT_APP_WP_DOWNLOAD;
export const GTM_DOWNLOAD = process.env.REACT_APP_GTM_DOWNLOAD;
export const JOOMLA_DOWNLOAD = process.env.REACT_APP_JOOMLA_DOWNLOAD;
export const MANUAL_URL = process.env.REACT_APP_MANUAL_URL;
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
