import React from "react";

const Maintenance = () => {
  return (
    <div>
      <p>Maintenance</p>
    </div>
  );
};

export default Maintenance;
