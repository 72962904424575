const CampaignDetailsPencilSVG = ({ className = "" }) => (
  <svg
    viewBox="0 0 18 18"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3252 5.7696C17.7605 5.33342 18 4.75415 18 4.13796C18 3.52176 17.7605 2.9425 17.3252 2.50631L15.4988 0.676197C15.0635 0.240015 14.4854 0 13.8705 0C13.2556 0 12.6775 0.240015 12.2434 0.675043L0 12.9054V18H5.08182L17.3252 5.7696ZM13.8705 2.30784L15.698 4.1368L13.8671 5.96461L12.0407 4.13565L13.8705 2.30784ZM2.30312 15.6922V13.8632L10.4101 5.76498L12.2365 7.5951L4.13064 15.6922H2.30312Z"
      fill="#8D8D8D"
    />
  </svg>
);

export default CampaignDetailsPencilSVG;
