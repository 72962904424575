import styled from "styled-components";
import { device } from "../../../screens";

const Svg = styled.svg`
  margin-right: 12px;
  width: 16px;
  height: 16px;

  @media ${device.xs} {
    margin-right: 13.5px;
    width: 18px;
    height: 18px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-right: 14px;
    width: 20px;
    height: 20px;
  }
`;

const PhoneIconSamllSVG = ({ fill = "#808080", responsive }) => {
  return (
    <Svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      responsive={responsive}
    >
      <g clipPath="url(#clip0_9228_39986)">
        <path
          d="M18.9299 8.37359C19.055 7.53361 19.0131 6.6772 18.8064 5.85349C18.5244 4.72788 17.9413 3.70028 17.1196 2.88094C16.3003 2.05908 15.2727 1.47578 14.1471 1.19362C13.3233 0.98725 12.4669 0.945454 11.627 1.07063M15.1809 7.97646C15.2499 7.51799 15.2102 7.04973 15.065 6.60942C14.9199 6.1691 14.6733 5.76906 14.3451 5.44154C14.0176 5.11348 13.6175 4.86698 13.1772 4.72189C12.7369 4.57681 12.2686 4.53721 11.8102 4.60628"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.39358 2.5387C5.56979 2.53879 5.74277 2.58601 5.89458 2.67547C6.0464 2.76492 6.17153 2.89336 6.25699 3.04746L7.46567 5.22377C7.54365 5.36422 7.58621 5.52157 7.58966 5.68218C7.5931 5.84279 7.55732 6.00181 7.48543 6.14547L6.3212 8.47343C6.3212 8.47343 6.65856 10.2082 8.07026 11.6198C9.48244 13.0315 11.2112 13.3635 11.2112 13.3635L13.5387 12.1992C13.6825 12.1273 13.8416 12.0916 14.0023 12.0951C14.163 12.0987 14.3204 12.1413 14.4609 12.2195L16.6441 13.4331C16.798 13.5188 16.9261 13.644 17.0153 13.7958C17.1045 13.9476 17.1515 14.1205 17.1514 14.2965V16.8028C17.1514 18.0787 15.9659 19.0008 14.7568 18.5924C12.2732 17.7546 8.41849 16.1587 5.97445 13.7157C3.53141 11.2721 1.93597 7.41688 1.09775 4.93333C0.68975 3.72416 1.61145 2.5387 2.8878 2.5387H5.39358V2.5387Z"
          stroke={fill}
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9228_39986">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default PhoneIconSamllSVG;
