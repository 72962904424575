const EmailIconSVG = ({ className = "", fill = "#808080" }) => (
  <svg
    width="20"
    height="15"
    className={className}
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0006 1.48337C20.0006 5.17808 20.0006 8.8728 20.0006 12.5675C19.985 12.6106 19.9693 12.6497 19.9576 12.6928C19.7658 13.4912 19.0691 14.047 18.2433 14.047C12.7442 14.047 7.24131 14.047 1.74229 14.047C0.783384 14.047 0.00451938 13.2681 0.00451938 12.3131C0.000605482 8.78669 -0.00330841 5.26027 0.00451938 1.73777C0.00451938 1.03327 0.352856 0.51272 0.975165 0.187867C1.13563 0.105675 1.31176 0.0626223 1.48397 0C7.16303 0 12.8382 0 18.5172 0C18.5525 0.0117417 18.5877 0.0313112 18.6229 0.0391389C19.21 0.180039 19.6249 0.53229 19.8675 1.08023C19.9262 1.20939 19.9576 1.35029 20.0006 1.48337ZM2.09063 1.17808C2.07888 1.19374 2.06714 1.20939 2.05931 1.22896C2.11019 1.2681 2.1689 1.30333 2.21196 1.35029C4.63074 3.77299 7.04562 6.19569 9.4644 8.61839C9.81274 8.96673 10.1885 8.97064 10.5368 8.61839C12.9595 6.19178 15.3822 3.76517 17.801 1.33464C17.8441 1.29158 17.8832 1.24462 17.9419 1.17808C12.6386 1.17808 7.36656 1.17808 2.09063 1.17808ZM12.9165 7.91781C12.4272 8.40313 11.9028 8.92368 11.3783 9.44814C10.572 10.2544 9.43701 10.2583 8.63857 9.45597C8.18456 8.99804 7.72663 8.54403 7.27654 8.08611C7.20609 8.01174 7.15129 7.92172 7.08867 7.84344C7.09258 7.87475 7.0965 7.90607 7.10041 7.93738C5.43309 9.58121 3.76969 11.225 2.10628 12.8689C7.37438 12.8689 12.6464 12.8689 17.9341 12.8689C16.255 11.2172 14.5916 9.57338 12.9165 7.91781ZM1.1826 2.05871C1.1826 5.36595 1.1826 8.69667 1.1826 11.9609C2.83035 10.3327 4.5055 8.6771 6.19239 7.0137C4.52115 5.35421 2.84601 3.69863 1.1826 2.05871ZM18.8147 11.9687C18.8147 8.68884 18.8147 5.35421 18.8147 2.08219C17.163 3.71037 15.4918 5.36595 13.8088 7.02153C15.4879 8.68493 17.163 10.3366 18.8147 11.9687Z"
      fill={fill}
    />
  </svg>
);

export default EmailIconSVG;
