const DashboardEyeIconSVG = ({ className = "" }) => (
  <svg
    width="54"
    height="49"
    className={className}
    viewBox="0 0 54 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.0004 24.4702C50.9093 29.3934 47.1437 33.7119 42.4784 37.2069C39.1272 39.7176 35.4642 41.5846 31.3201 42.406C25.7954 43.5023 20.6058 42.4884 15.6963 39.8593C10.4105 37.0271 6.21884 32.9465 2.597 28.2315C1.76184 27.1448 0.98483 26.0189 0.249045 24.8634C-0.0607032 24.3771 -0.0617604 23.9247 0.25116 23.4415C4.03474 17.6007 8.65137 12.5845 14.6878 9.00917C18.8086 6.56818 23.2529 5.26047 28.0841 5.49411C31.6869 5.66854 35.0519 6.70244 38.2181 8.39496C43.524 11.2313 47.7378 15.3088 51.3766 20.0322C52.3185 21.2543 53.1864 22.5271 54.0004 23.8369V24.4691V24.4702ZM26.9931 40.7261C30.9427 40.7261 34.9842 39.4998 38.7741 37.1582C44.0124 33.9212 48.0814 29.5139 51.5108 24.4617C51.6853 24.2048 51.644 24.0484 51.4897 23.819C50.6852 22.6233 49.8384 21.4604 48.9335 20.3398C45.7958 16.4548 42.2184 13.0676 37.8343 10.6023C34.7802 8.88548 31.5336 7.78498 28.0133 7.60526C23.8259 7.39171 19.9545 8.49539 16.3285 10.522C10.5532 13.7505 6.17761 18.438 2.49869 23.8475C2.34963 24.0663 2.33271 24.2175 2.48811 24.4469C5.94398 29.5477 10.0458 34.0005 15.3559 37.2269C18.9259 39.3962 22.7655 40.7219 26.9931 40.7272V40.7261Z"
      fill="#28265B"
    />
    <path
      d="M54.0003 13.3985C53.6028 14.0529 53.1884 14.2749 52.6461 14.1216C52.1788 13.9895 51.8944 13.574 51.8923 12.9841C51.887 11.5791 51.8786 10.1731 51.8987 8.76813C51.904 8.4161 51.7961 8.33364 51.4589 8.33787C50.0888 8.3569 48.7187 8.34844 47.3476 8.34421C46.5325 8.3421 46.0705 7.94355 46.0843 7.26696C46.098 6.62844 46.5716 6.23729 47.3486 6.23623C49.0877 6.23306 50.8278 6.25632 52.5657 6.2246C53.2096 6.21297 53.6832 6.40538 53.9993 6.96567V13.3974L54.0003 13.3985Z"
      fill="#28265B"
    />
    <path
      d="M54.0003 41.3414C53.6821 41.8996 53.2117 42.0952 52.5657 42.0835C50.8267 42.0508 49.0877 42.074 47.3476 42.0708C46.5695 42.0698 46.097 41.6797 46.0843 41.0391C46.0705 40.3625 46.5315 39.965 47.3486 39.9629C48.7187 39.9597 50.0899 39.9512 51.4599 39.9703C51.7993 39.9745 51.904 39.8899 51.8987 39.5389C51.8775 38.1868 51.8892 36.8337 51.8902 35.4816C51.8902 34.7542 52.1408 34.3282 52.6461 34.1865C53.1905 34.0343 53.6039 34.2552 54.0003 34.9096V41.3414Z"
      fill="#28265B"
    />
    <path
      d="M0.00228347 10.1541C0.00228347 9.24069 -0.0040595 8.3273 0.00439779 7.41391C0.0117979 6.65381 0.418805 6.24152 1.18208 6.23835C3.04374 6.23095 4.90646 6.23095 6.76812 6.23835C7.46162 6.24152 7.9088 6.66015 7.91725 7.27542C7.92571 7.91818 7.47853 8.33893 6.75015 8.34316C5.34518 8.35056 3.93915 8.35796 2.53419 8.33681C2.18109 8.33153 2.09864 8.43936 2.10392 8.77659C2.12189 10.1467 2.11344 11.5168 2.11132 12.8868C2.10921 13.7019 1.71277 14.1628 1.03302 14.1501C0.381805 14.1385 0.00439779 13.6818 0.00228347 12.8932C-0.000888016 11.9798 0.00228347 11.0664 0.00228347 10.153V10.1541Z"
      fill="#28265B"
    />
    <path
      d="M3.92418 42.0719C3.02771 42.0719 2.1323 42.0761 1.23582 42.0708C0.40595 42.0656 0.00528581 41.6681 0.00317149 40.8424C-0.00105716 39.0156 -0.00105716 37.1889 0.00317149 35.3621C0.00528581 34.6189 0.396436 34.1686 1.03179 34.1569C1.69569 34.1442 2.10798 34.5978 2.1101 35.3663C2.11538 36.7544 2.12172 38.1414 2.10375 39.5294C2.09952 39.8635 2.17458 39.9777 2.5319 39.9713C3.84913 39.9491 5.16741 39.9618 6.48463 39.9629C7.45616 39.9629 7.92554 40.3138 7.91603 41.0306C7.90757 41.723 7.43396 42.0719 6.50472 42.073C5.64419 42.073 4.7826 42.073 3.92207 42.073L3.92418 42.0719Z"
      fill="#28265B"
    />
    <path
      d="M16.0419 6.1358C15.8823 6.13475 15.7216 6.12735 15.5874 6.0322C14.9985 5.61356 14.4773 5.11564 14.0777 4.5194C13.8208 4.13671 13.9699 3.58593 14.3166 3.27829C14.6771 2.95903 15.2512 2.90194 15.6222 3.22226C16.0715 3.61024 16.4891 4.03945 16.8824 4.48346C17.1615 4.79849 17.1678 5.19176 16.9743 5.56705C16.7819 5.94023 16.4669 6.13263 16.0419 6.1358Z"
      fill="#28265B"
    />
    <path
      d="M38.0461 6.1358C37.5376 6.1358 37.2226 5.94023 37.0291 5.56916C36.8346 5.19493 36.8399 4.80166 37.1179 4.48557C37.5101 4.04051 37.9235 3.6039 38.3791 3.22543C38.7692 2.90089 39.2301 2.93894 39.6403 3.24552C40.0156 3.52673 40.189 4.07011 39.9458 4.4676C39.5515 5.11247 39.0028 5.63259 38.3685 6.05017C38.2596 6.12206 38.1042 6.12312 38.0461 6.13686V6.1358Z"
      fill="#28265B"
    />
    <path
      d="M17.0901 43.1121C17.0912 43.8648 15.6746 45.2984 15.0213 45.292C14.6047 45.2878 14.276 45.1007 14.0825 44.7243C13.8901 44.349 13.8954 43.9526 14.1681 43.6386C14.5582 43.1914 14.9822 42.7686 15.4283 42.3774C15.7423 42.1015 16.1366 42.0962 16.5129 42.2844C16.8924 42.4726 17.0743 42.7939 17.0891 43.1121H17.0901Z"
      fill="#28265B"
    />
    <path
      d="M40.0509 44.2201C40.0044 44.6355 39.8628 44.9601 39.5023 45.1535C39.1302 45.3533 38.7401 45.365 38.4123 45.1102C37.9155 44.7233 37.4567 44.2803 37.0824 43.7739C36.7938 43.3817 36.8403 42.9409 37.148 42.5592C37.4577 42.1734 37.9049 42.0275 38.3225 42.2495C39.0086 42.6142 39.5171 43.2052 39.9537 43.8458C40.0266 43.9536 40.0245 44.1133 40.0499 44.2211L40.0509 44.2201Z"
      fill="#28265B"
    />
    <path
      d="M28.0532 1.80038C28.0532 2.04564 28.068 2.29196 28.05 2.53616C28.0077 3.12712 27.5426 3.56267 26.9833 3.55104C26.4399 3.54047 25.9843 3.11972 25.9558 2.55414C25.9293 2.02978 25.9283 1.50226 25.9558 0.977908C25.9864 0.405984 26.4378 0.00426233 26.9918 3.36831e-05C27.55 -0.00419496 27.9961 0.390126 28.0468 0.959937C28.0711 1.23797 28.0511 1.52023 28.0511 1.80038H28.0532Z"
      fill="#28265B"
    />
    <path
      d="M28.0536 46.5204C28.0536 46.7837 28.0695 47.0469 28.0505 47.3091C28.0071 47.9096 27.5599 48.3187 26.9806 48.3092C26.4267 48.2996 25.9837 47.8937 25.9562 47.3186C25.9308 46.7942 25.9287 46.2667 25.9573 45.7424C25.9879 45.1789 26.4531 44.7613 26.9965 44.7582C27.5388 44.755 27.9976 45.1704 28.0473 45.7329C28.0705 45.994 28.0515 46.2583 28.0515 46.5204H28.0536Z"
      fill="#28265B"
    />
    <path
      d="M14.022 24.2534C13.9628 17.8259 18.6365 12.2927 24.8304 11.2768C28.0473 10.7493 31.0328 11.355 33.8194 13.02C34.0816 13.1765 34.1704 13.2907 33.9822 13.6184C33.2835 14.8352 33.4515 16.3839 34.3364 17.4041C35.2868 18.4993 36.7784 18.9053 38.1115 18.365C38.5301 18.1959 38.6697 18.3323 38.8114 18.6748C40.8295 23.58 40.3506 28.1861 37.0586 32.3894C34.9527 35.0777 32.1301 36.6392 28.7525 37.098C22.1515 37.9944 15.927 33.7774 14.3497 27.1458C14.1139 26.1532 13.9744 25.1478 14.022 24.2524V24.2534ZM24.1823 22.8664C23.5988 22.8855 23.27 23.1233 23.1083 23.5568C22.936 24.0188 23.0575 24.4416 23.4444 24.7366C24.4223 25.4819 25.4087 26.2145 26.4098 26.927C26.8707 27.2547 27.4342 27.1945 27.7862 26.7536C28.9797 25.2577 30.1627 23.7513 31.314 22.2226C31.7072 21.7004 31.4694 20.9731 30.9038 20.7024C30.4259 20.4741 29.9354 20.5999 29.5548 21.082C28.7271 22.1296 27.8951 23.1741 27.1001 24.245C26.8549 24.5759 26.6973 24.56 26.4077 24.3243C25.9045 23.9151 25.3685 23.5451 24.8473 23.1572C24.6168 22.9859 24.3769 22.8485 24.1845 22.8654L24.1823 22.8664Z"
      fill="#EC5627"
    />
    <path
      d="M24.1825 22.8664C24.3739 22.8495 24.6149 22.987 24.8454 23.1582C25.3665 23.5462 25.9025 23.9162 26.4057 24.3253C26.6954 24.5611 26.8529 24.5759 27.0982 24.246C27.8942 23.1751 28.7262 22.1307 29.5529 21.083C29.9335 20.6009 30.424 20.4751 30.9018 20.7035C31.4674 20.9741 31.7053 21.7015 31.312 22.2237C30.1608 23.7523 28.9789 25.2588 27.7843 26.7547C27.4333 27.1945 26.8688 27.2558 26.4078 26.9281C25.4067 26.2155 24.4193 25.4819 23.4425 24.7376C23.0556 24.4427 22.9351 24.0209 23.1063 23.5578C23.2681 23.1244 23.5968 22.8865 24.1804 22.8675L24.1825 22.8664Z"
      fill="white"
    />
  </svg>
);

export default DashboardEyeIconSVG;
