import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import LeadsTable from "./component/leads-table";
import Input from "components/input/input";
import SearchIcon from "assets/svg/search-icon";
import { connect } from "react-redux";
import { IoEllipsisVertical } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import LeadDetail from "./lead-details/lead-detail";
import contactsAction from "redux/modules/contacts/actions";
import _ from "lodash";
import SelectLeadSort from "components/select/select-sort-lead";

const SORT_BY = [
  { label: "Updated: Ascending", value: "updated_at ", sort: "asc" },
  { label: "Updated: Descending", value: "updated_at ", sort: "desc" },
  { label: "Created: Ascending", value: "created_at", sort: "asc" },
  { label: "Created: Descending", value: "created_at", sort: "desc" },
  { label: "Special Condition: Ascending", value: "title", sort: "asc" },
  { label: "Special Condition: Descending", value: "title", sort: "desc" },
];

export const CampaignLeads = ({ contacts, setContacts }) => {
  const { id } = useParams();
  const { http } = global.services;
  const [filter, setFilter] = useState({
    label: "Updated: Descending",
    value: "updated_at ",
    sort: "desc",
  });
  const [drawer, setDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lead, setLead] = useState({});
  const [search, setSearch] = useState("");

  const [metadata, setMetadata] = useState({
    total: 0,
    perPage: 10,
    currentPage: 1,
    lastPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    from: 0,
    to: 0,
  });

  const currentPage = useMemo(() => metadata.currentPage, [metadata]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      thNowrap: true,
      tdNowrap: true,
      render: (record) => <p className="text-sm">{record?.firstname}</p>,
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      thNowrap: true,
      width: "300px",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      thNowrap: true,
      width: "150px",
    },
    {
      title: "Date Subscribed",
      dataIndex: "subsribed",
      key: "subsribed",
      width: "200px",
      thNowrap: true,
      render: (record) => (
        <p className="text-sm">
          {new Date(record.created_at).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Campaign Title",
      dataIndex: "campaigns",
      key: "campaigns",
      width: "300px",
      thNowrap: true,
      hideSorting: false,
      tdNowrap: true,
      render: (record) => (
        <div className="text-sm flex items-center">
          {record && record.campaign && record.campaign.title}
        </div>
      ),
    },
    {
      title: "Popout",
      dataIndex: "popout",
      key: "popout",
      width: "200px",
      thNowrap: true,
      tdNowrap: true,
      render: (record) => (
        <p className="text-sm">
          {record?.popup?.title || record?.popup?.quote || "---"}
        </p>
      ),
    },
    {
      title: "Special Condition",
      dataIndex: "conditions",
      key: "conditions",
      width: "300px",
      thNowrap: true,
      tdNowrap: true,
      render: (record) => {
        const condition = JSON.parse(
          record?.campaign_popup?.popup_condition || "{}"
        );
        return <p className="text-sm">{condition?.title || "---"}</p>;
      },
    },
    {
      title: "Page URL",
      dataIndex: "website_url",
      key: "website_url",
      width: "200px",
      thNowrap: true,
      render: (record) => (
        <a
          className="text-sm flex items-center cursor-pointer text-secondary"
          href={record && record.pageUrl}
        >
          {record && record.pageUrl}
        </a>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (record) => (
        <div className="w-full">
          <button>
            <IoEllipsisVertical />
          </button>
        </div>
      ),
    },
  ];

  const onHandleChangeSorting = (col) => {};

  useEffect(() => {
    const getContacts = async () => {
      setLoading(true);
      try {
        const response = await http.get(
          `contacts?campaign_id=${id}&search=${search}`,
          {
            page: metadata.currentPage,
            sortBy: filter?.value,
            order: filter?.sort,
          }
        );
        setContacts(response.data.data);
        setMetadata((prev) => ({
          ...prev,
          total: response.data.total,
          currentPage: response.data.current_page,
          lastPage: response.data.last_page,
          hasNextPage: !!response.data.next_page_url,
          hasPrevPage: !!response.data.prev_page_url,
          from: response.data.from,
          to: response.data.to,
        }));
      } catch (err) {
        console.error("Error while fetching contacts:", err.message);
        toast.error("Error while fetching contacts");
      } finally {
        setLoading(false);
      }
    };

    getContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filter, search]);

  return (
    <div className="w-full">
      <p className="w-full text-lg">
        You currently have{" "}
        <span className="font-bold">{metadata?.total} submissions</span>
      </p>
      {/* filter */}
      <div className="w-full mt-6">
        <div className="w-full flex justify-between flex-wrap">
          <div className="w-8/20">
            <div className="w-full flex items-center flex-wrap">
              <div className="w-12/20">
                <Input
                  placeholder="Search"
                  noLabelPaddingY="1rem"
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value || "")}
                  inputIcon={<SearchIcon />}
                />
              </div>
              <div className="pl-3 w-8/20">
                {!_.isEmpty(search) && (
                  <button
                    className="w-full break-all bg-white flex justify-between items-center rounded-lg p-1 text-sm text-black text-opacity-70 border border-thBorder"
                    onClick={() => setSearch("")}
                  >
                    <p className="px-1 pt-1 pb-0.5 mt-0.5">{search}</p>
                    <span>
                      <FaTimes />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="w-full flex items-center">
              <p className="mr-5">Sort by</p>
              <div className="w-80">
                <SelectLeadSort
                  wrapperClass="no-padding-wrapper"
                  name="leadSort"
                  options={SORT_BY}
                  placeholder="Sort By"
                  value={filter}
                  readOnly={true}
                  onChange={(e) => setFilter(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* table */}
      <div className="w-full mt-7">
        <LeadsTable
          columns={columns}
          dataSource={contacts}
          loading={loading}
          metadata={metadata}
          onHandleChangeSorting={(column) => onHandleChangeSorting(column)}
          onPageChange={(nextPage) =>
            setMetadata((prev) => ({
              ...prev,
              currentPage: nextPage,
            }))
          }
          onClickRow={(record) => {
            setDrawer((prev) => !prev);
            setLead(record);
          }}
        />
      </div>
      {/* drawer */}
      <LeadDetail overlay={drawer} toggleOverlay={setDrawer} data={lead} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: state.contacts.contactList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContacts: (params) => {
      dispatch(contactsAction.setContacts(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignLeads);
