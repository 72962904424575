import React, { useState, useRef, useCallback } from "react";
import Button from "components/button/button";
import Modal from "components/global/modal";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import InputRange from "components/input-range/input-range";
import "react-image-crop/dist/ReactCrop.css";
import { canvasToFile } from "utils/constant";
import _ from "lodash";

function centerAspectCrop(width, height, mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px",
        width: width,
        height: height,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCropModal({
  hide,
  src,
  name = "crop-image",
  locked = true,
  freeMode = false,
}) {
  const imgSrc = src;
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [zoom, setZoom] = useState(1.5);
  const [minZoom, setMinZoom] = useState(null);
  const [aspect, setAspect] = useState(null);

  const getCropData = useCallback(async () => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      const { image } = await canvasToFile(
        imgRef.current,
        completedCrop,
        zoom,
        0,
        name
      );

      if (image) {
        hide(image);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop, zoom, name]);

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;

    let defaultM = {
      width: 200,
      height: 200,
    };

    let finalW = defaultM.width;
    let finalH = defaultM.height;

    //calculate aspect ratio
    let aw = finalH / finalW;
    let aspectRatioW = aw * defaultM.width;

    let ah = finalW / finalH;
    let aspectRatioH = ah * defaultM.height;

    //calculate scale
    let wsc = defaultM.width / width;
    let hsc = defaultM.height / height;

    let minScale = _.min([wsc, hsc]);

    //set aspect ratio
    let aspectRatio = aspectRatioH / aspectRatioW;
    setAspect(aspectRatio);

    if (aspectRatio) {
      setMinZoom(minScale);
      setCrop(centerAspectCrop(finalW, finalH, width, height, aspectRatio));
    }
  }

  return (
    <Modal
      width="618px"
      footerClass="border-t border-tabBorder"
      footer={
        <div className="w-full flex justify-end">
          <Button
            key="cancel-btn"
            buttonName="CANCEL"
            buttonClass="bg-transparent rounded-full py-3 px-9"
            buttonType="transparent"
            buttonTextClass="text-black text-sm font-bold"
            onClick={() => hide()}
          />
          <Button
            key="save-btn"
            buttonName="SAVE"
            buttonClass="bg-primary rounded-full py-3 px-12"
            buttonType="primary"
            buttonTextClass="text-white text-sm font-bold"
            onClick={() => getCropData()}
          />
        </div>
      }
    >
      <div className="px-7">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:text-left w-full">
            <h3 className="text-2xl leading-9" id="modal-title">
              Crop the Logo
            </h3>
          </div>
          {/* close button */}
          <div className="mt-5 text-center sm:text-right">
            <span
              className="close opacity-100 h-4 w-4"
              onClick={() => hide()}
            ></span>
          </div>
          {/* close button */}
        </div>
        {/* image */}
        <div className="mt-4">
          <div style={{ width: "100%" }} className="flex justify-center">
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={freeMode ? undefined : aspect}
                locked={locked}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${zoom}) rotate(0deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </div>
        </div>
        {/* zoom */}
        <div className="mt-10 mb-5">
          <div className="w-full px-12">
            {minZoom !== null && (
              <InputRange
                hideNumber={true}
                buttons={true}
                onClickButton={(value) => {
                  setZoom(value);
                }}
                name="rating"
                min={minZoom}
                max={10}
                step={0.05}
                defaultValue={zoom}
                onChange={(e) => setZoom(Number(e?.target?.value))}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
