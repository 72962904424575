import ArrowLeftIconSVG from "assets/svg/arrow-left";
import LogoLandscapeSecondary from "assets/svg/logo-landscape-secondary";
import CampaignPopupBasicDetails from "components/campaigns/popup/campaign-popup-basic-details";
import CampaignPopupContact from "components/campaigns/popup/campaign-popup-contacts";
import CampaignPopupTemplateType from "components/campaigns/popup/campaign-popup-template-type";
import SnapshotComponent from "components/campaigns/snapshot/snapshot";
import CampaignChooseTemplatedDetails from "components/campaigns/tab-panel/campaign-add-content-details";
import CampaignChooseTemplate from "components/campaigns/tab-panel/campaign-choose-template";
import CampaignChooseContactMethod from "components/campaigns/tab-panel/campaign-contact-method";
import CampaignChooseContactMethodDetails from "components/campaigns/tab-panel/campaign-contact-method-details";
import CampaignEffectsPopout from "components/campaigns/tab-panel/campaign-effects-popout";
import CampaignPopupTab from "components/campaigns/tab-panel/campaign-popup-tab";
import CampaignPreview from "components/campaigns/tab-panel/campaign-preview";
import CampaignStylesPopout from "components/campaigns/tab-panel/campaign-styles-popout";
import WarningModal from "components/confirm/warning";
import { Stepper } from "components/global/stepper";
import Toggle from "components/toggle/toggle";
import { Context } from "context/context";
import EmptyLayout from "layouts/empty-layout";
import _ from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { TabPanel } from "react-headless-tabs";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SCREENSHOTSOBJ, popoutValidationAll } from "utils/constant";
import { useDialogHook } from "utils/customhooks";
import {
  DisplayErrorMessage,
  globalValidation,
  validateContacts,
  validateTemplates,
} from "utils/utils";
import CampaignBasicDetails from "../../components/campaigns/tab-panel/campaign-basic-details";
import "./styles.css";
import { sendVirtualPageview } from "utils/gtm-triggers";

const completedSteps = {
  basic: [],
  popout: ["basic"],
  template: ["basic", "popout"],
  content: ["basic", "popout", "template"],
  contactM: ["basic", "popout", "template", "content"],
  contactD: ["basic", "popout", "template", "content", "contactM"],
  style: ["basic", "popout", "template", "content", "contactM", "contactD"],
  effects: [
    "basic",
    "popout",
    "template",
    "content",
    "contactM",
    "contactD",
    "style",
  ],
  preview: [
    "basic",
    "popout",
    "template",
    "content",
    "contactM",
    "contactD",
    "style",
    "effects",
  ],
};

const earlyLeaveTitle = "Incomplete popout";
const earlyLeaveContent =
  "You are about to leave the popout builder but there are some fields that aren't complete yet! This may cause problems when displaying your popout.";

const snapshotCheckInterval = 5000; // ms

const CreateCampaign = ({ userData }) => {
  const history = useHistory();
  let { id, cid } = useParams(); //get id in url
  const { http } = global.services;
  // 2023-11-02: Disabled welcome modal
  // const modal = useDialogHook(CampaignWelcome); //modal
  const {
    selectedTab,
    setSelectedTab,
    snapshot,
    setSnapshot,
    popup,
    setPopup,
    view,
    setView,
    fonts,
    setFonts,
    // 2023-11-02: Disabled welcome modal
    // popoutCount,
    disabledStylePopout,
    setTabs,
  } = useContext(Context);
  const [loadingSnapshot, setLoadingSnapshot] = useState(false);
  const warningModal = useDialogHook(WarningModal);
  const [locationKeys, setLocationKeys] = useState([]);
  const [lastCheckedSnapshot, setLastCheckedSnapshot] = useState(Date.now());

  const getAllFonts = async () => {
    try {
      const result = await http.get("fonts?limit=2000");
      const data = result.data.data;
      if (data) {
        const family = _.filter(data, { id: 1 });
        if (!_.isEmpty(family)) {
          setFonts(family);
        }
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    } finally {
      console.log("fonts are loaded");
    }
  };

  //get popout object if id is present
  const getPopup = async () => {
    if (id) {
      try {
        const result = await http.get(`popups/${id}`);
        const data = result.data.data;
        if (data) {
          //put snapshot first
          if (data?.desktop_image?.url && data?.mobile_image?.url) {
            const obj = SCREENSHOTSOBJ(data);
            setSnapshot(obj);
          }
          setPopup((prev) => ({ ...prev, ...data }));
          setTabs(data?.tab_type ?? "layout");
        }
      } catch (error) {
        if (error.status === 422) {
          toast.error("It appears that something went wrong");
        } else if (error.status === 400) {
          history.push("/popouts");
        }
      } finally {
        console.log("data are loaded");
      }
    }
  };

  useEffect(() => {
    window.location.hash = selectedTab;
    sendVirtualPageview(window.location.href, document);
  }, [selectedTab]);

  const onClickMenuTabs = (menu) => {
    if (menu === "popout") {
      let err = globalValidation(popup, "popout");
      if (_.isEmpty(err)) {
        setSelectedTab(menu);
      } else {
        toast.error(<DisplayErrorMessage errors={err} />);
      }
    } else if (menu === "template") {
      let err = globalValidation(popup, "template");
      if (_.isEmpty(err)) {
        setSelectedTab(menu);
      } else {
        toast.error(<DisplayErrorMessage errors={err} />);
      }
    } else if (menu === "content") {
      let err = globalValidation(popup, "content");
      if (_.isEmpty(err)) {
        if (popup.template_type) {
          setSelectedTab(menu);
        }
      } else {
        toast.error(<DisplayErrorMessage errors={err} />);
      }
    } else if (menu === "contactM") {
      let temp = validateTemplates(popup.template_content);
      let global = globalValidation(popup, "contactM");
      let result = global;
      if (_.isEmpty(global)) {
        result = temp;
      }
      if (_.isEmpty(result)) {
        setSelectedTab(menu);
      } else {
        toast.error(<DisplayErrorMessage errors={result} />);
      }
    } else if (menu === "contactD") {
      let temp = validateTemplates(popup.template_content);
      let global = globalValidation(popup, "contactD");
      let result = global;
      if (_.isEmpty(global)) {
        result = temp;
      }
      if (_.isEmpty(result)) {
        if (popup.contact_method_type) {
          setSelectedTab(menu);
        }
      } else {
        toast.error(<DisplayErrorMessage errors={result} />);
      }
    } else if (menu === "style" || menu === "effects" || menu === "preview") {
      if (popup.template_type && popup.contact_method_type) {
        let result = validateContacts(popup.contact_method_content);
        let temp = validateTemplates(popup.template_content);
        let global = globalValidation(popup, menu);
        let errors = global;
        if (_.isEmpty(global)) {
          errors = temp;
        }
        if (_.isEmpty(global) && _.isEmpty(temp)) {
          errors = result;
        }
        if (
          _.isEmpty(result) &&
          _.isEmpty(temp) &&
          _.isEmpty(global) &&
          _.isEmpty(disabledStylePopout)
        ) {
          setSelectedTab(menu);
        } else {
          if (!_.isEmpty(errors)) {
            toast.error(
              <DisplayErrorMessage
                errors={
                  _.isEmpty(disabledStylePopout) ? errors : disabledStylePopout
                }
              />
            );
          }
        }
      }
    } else {
      setSelectedTab(menu);
    }
  };

  const showWarningModal = (obj) => {
    warningModal({ ...obj }, (callBack) => {
      if (callBack) {
        if (callBack === "popouts") history.push(`/popouts`);
        setSelectedTab(callBack);
      }
    });
  };

  const validateBeforeExit = (type = "back") => {
    //validate before back
    if (id) {
      const err = popoutValidationAll(popup);
      if (_.isEmpty(err)) {
        if (type === "back") {
          if (cid) {
            history.push(`/campaign-details/${cid}/popouts`);
          } else {
            history.push(`/popouts`);
          }
        } else {
          history.push(`/dashboard`);
        }
      } else {
        showWarningModal({
          title: earlyLeaveTitle,
          buttonText: "YES, LEAVE PAGE",
          description: earlyLeaveContent,
          errors: err,
        });
      }
    } else {
      if (type === "back") {
        history.push(`/popouts`);
      } else {
        history.push(`/dashboard`);
      }
    }
  };

  useEffect(() => {
    setView("desktop");
    window.location.hash = "basic";
    if (_.isEmpty(fonts)) {
      getAllFonts();
    }
    if (id) {
      getPopup();
    } else {
      if (!popup.id) {
        // 2023-11-02: Disabled welcome modal
        // if (popoutCount < 2) modal({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      return history.listen((location) => {
        if (history.action === "PUSH") {
          if (location.key) setLocationKeys([location.key]);
        }

        if (history.action === "POP") {
          let url = "";
          if (cid) {
            url = `/campaigns/${cid}/popup/${id}`;
          } else {
            url = `/campaigns/popup/${id}`;
          }
          const err = popoutValidationAll(popup);

          if (!_.isEmpty(err)) {
            history.replace(url);
          }

          if (locationKeys[1] === location.key) {
            setLocationKeys(([_, ...keys]) => keys);
          } else {
            setLocationKeys((keys) => [location.key, ...keys]);
            if (!_.isEmpty(err)) {
              alert(earlyLeaveContent);
              showWarningModal({
                title: earlyLeaveTitle,
                buttonText: "YES, LEAVE PAGE",
                description: earlyLeaveContent,
                errors: err,
              });
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKeys, cid, id, popup]);

  // Poll snapshot updates in background
  const checkSnapshot = useCallback(async () => {
    if (popup?.id) {
      const result = await http.get(`popups/${popup.id}`);
      const data = result.data.data;
      if (data) {
        // ignore if data hasn't changed yet.
        if (
          data?.desktop_image?.id === popup?.desktop_image?.id &&
          data?.mobile_image?.id === popup?.mobile_image?.id
        ) {
          return;
        }
        if (data?.desktop_image?.url && data?.mobile_image?.url) {
          const obj = SCREENSHOTSOBJ(data);
          //save new screenshot changes
          setPopup((prev) => ({
            ...prev,
            desktop_image: data.desktop_image,
            desktop_image_id: data.desktop_image_id,
            mobile_image: data.mobile_image,
            mobile_image_id: data.mobile_image_id,
          }));
          setSnapshot(obj);
          setLoadingSnapshot(false);
        }
      }
    }
    setLastCheckedSnapshot(Date.now());
  }, [setSnapshot, popup, setPopup, http, setLastCheckedSnapshot]);

  useEffect(() => {
    if (!loadingSnapshot) {
      return;
    }

    // Due to rerenders potentially dumping the interval, check if we should run it immediately.
    if (lastCheckedSnapshot < Date.now() - snapshotCheckInterval) {
      checkSnapshot();
    }

    const intervalId = setInterval(checkSnapshot, snapshotCheckInterval);
    return () => clearInterval(intervalId);
  }, [checkSnapshot, lastCheckedSnapshot, loadingSnapshot]);

  return (
    <EmptyLayout title="Popout Builder" color={"#F4F4F4"}>
      <div className="w-full">
        <nav className="nav-responsive">
          <div className="nav-logo-responsive">
            <button onClick={() => validateBeforeExit("dashboard")}>
              <LogoLandscapeSecondary />
            </button>
          </div>
          {/* stepper */}
          <div className="w-full 5md:w-18/20 3md:w-15/20 xl:w-12/20">
            <div className="stepper-wrapper">
              <Stepper
                completed={completedSteps[selectedTab].includes("basic")}
                number={1}
                text="Basic Details"
                isActive={selectedTab === "basic"}
                onClick={() => onClickMenuTabs("basic")}
              />
              <Stepper
                completed={completedSteps[selectedTab].includes("popout")}
                number={2}
                text="Popout Tab"
                isActive={selectedTab === "popout"}
                onClick={() => onClickMenuTabs("popout")}
              />
              <Stepper
                completed={completedSteps[selectedTab].includes("template")}
                number={3}
                text="Choose Template"
                isActive={selectedTab === "template"}
                onClick={() => onClickMenuTabs("template")}
              />
              <Stepper
                completed={completedSteps[selectedTab].includes("content")}
                number={4}
                text="Add Content"
                isActive={selectedTab === "content"}
                onClick={() => onClickMenuTabs("content")}
              />
              <Stepper
                completed={completedSteps[selectedTab].includes("contactM")}
                number={5}
                text="Contact Method"
                isActive={selectedTab === "contactM"}
                onClick={() => onClickMenuTabs("contactM")}
              />
              <Stepper
                completed={completedSteps[selectedTab].includes("contactD")}
                number={6}
                text="Contact Details"
                isActive={selectedTab === "contactD"}
                onClick={() => onClickMenuTabs("contactD")}
              />
              <Stepper
                completed={completedSteps[selectedTab].includes("style")}
                number={7}
                text="Style Popout"
                isActive={selectedTab === "style"}
                onClick={() => onClickMenuTabs("style")}
              />
              <Stepper
                completed={completedSteps[selectedTab].includes("effects")}
                number={8}
                text="Engagement Effects"
                isActive={selectedTab === "effects"}
                onClick={() => onClickMenuTabs("effects")}
              />
              <Stepper
                completed={completedSteps[selectedTab].includes("preview")}
                number={9}
                text="Preview"
                isActive={selectedTab === "preview"}
                onClick={() => onClickMenuTabs("preview")}
              />
            </div>
          </div>
          {/* end: stepper */}
        </nav>
      </div>

      {/* back button */}
      {userData.user.role !== "guest" && (
        <button
          className="bg-btnBack flex items-center px-4 py-1.5 rounded-full"
          onClick={() => validateBeforeExit("back")}
        >
          <ArrowLeftIconSVG className="mr-2" />
          <p className="text-xs font-bold">BACK TO DASHBOARD</p>
        </button>
      )}
      {/* end: back button */}
      <div className="w-full pt-3 ">
        <div className="w-full h-full mobile-responsive-container">
          <div className="mobile-responsive-left" id="content-left">
            <TabPanel hidden={selectedTab !== "basic"}>
              <CampaignBasicDetails
                loadingSnapshot={loadingSnapshot}
                setLoadingSnapshot={setLoadingSnapshot}
                role={userData.user.role}
              />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "popout"}>
              <CampaignPopupTab id={id} />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "template"}>
              <CampaignChooseTemplate id={id} />
            </TabPanel>
            {selectedTab === "content" && (
              <TabPanel hidden={selectedTab !== "content"}>
                <CampaignChooseTemplatedDetails />
              </TabPanel>
            )}
            <TabPanel hidden={selectedTab !== "contactM"}>
              <CampaignChooseContactMethod id={id} />
            </TabPanel>
            {selectedTab === "contactD" && (
              <TabPanel hidden={selectedTab !== "contactD"}>
                <CampaignChooseContactMethodDetails />
              </TabPanel>
            )}
            {selectedTab === "style" && (
              <TabPanel hidden={selectedTab !== "style"}>
                <CampaignStylesPopout />
              </TabPanel>
            )}
            {selectedTab === "effects" && (
              <TabPanel hidden={selectedTab !== "effects"}>
                <CampaignEffectsPopout />
              </TabPanel>
            )}
            <TabPanel hidden={selectedTab !== "preview"}>
              <CampaignPreview cid={cid} />
            </TabPanel>
          </div>
          {/* snapshot screen  */}
          <SnapshotDesktopMobile
            selectedTab={selectedTab}
            mobile={view === "mobile"}
            view={view}
            setView={setView}
            loadingSnapshot={loadingSnapshot}
            snapshot={snapshot}
            popup={popup}
          />
        </div>
      </div>
    </EmptyLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

export default connect(mapStateToProps)(CreateCampaign);

const SnapshotDesktopMobile = ({
  selectedTab,
  mobile,
  view,
  setView,
  loadingSnapshot,
  snapshot,
  popup,
}) => {
  const HELP_POPUP_CLASS = mobile ? "help-popup-mobile" : "help-popup-desktop";

  const template = [
    "template",
    "content",
    "contactM",
    "contactD",
    "style",
    "preview",
  ];

  const contacts = ["contactM", "contactD", "style", "preview"];

  return (
    <div className="mobile-responsive-right relative">
      <div className="w-full pt-12 relative">
        {selectedTab === "basic" && (
          <div className={HELP_POPUP_CLASS}>
            <CampaignPopupBasicDetails />
          </div>
        )}
      </div>
      <div className={`w-full 5md:sticky 5md:top-11`}>
        {mobile && selectedTab !== "basic" && (
          <div className="help-popup-mobile-sticky">
            {/* <CampaignPopupBasicDetails /> */}
          </div>
        )}
        <div className="flex flex-row relative">
          <div className="mr-3 pt-1 relative z-20">
            <Toggle
              background="vertical"
              orientation="vertical"
              checked={mobile}
              onChange={(e) =>
                setView(e?.target?.checked ? "mobile" : "desktop")
              }
            />
            {mobile && template.includes(selectedTab) && (
              <CampaignPopupTemplateType
                className="relative p-2 box-shadow top-28"
                divMargin="0px 0px 0px -10px"
                type={popup?.template_type || "proof-points"}
              />
            )}
            {mobile && contacts.includes(selectedTab) && (
              <CampaignPopupContact
                className="popup-contact p-2 box-shadow z-30"
                divMargin="260px 0px 0px -430px"
                type={popup?.contact_method_type || "standard-details"}
                mobile={true}
              />
            )}
          </div>
          <SnapshotComponent
            mobile={mobile}
            loading={loadingSnapshot}
            url={popup?.website_url}
            snapshot={_.find(snapshot?.image_urls, ["device", view])}
          />
        </div>
      </div>
    </div>
  );
};
