import Button from "components/button/button";
import Modal from "components/global/modal";
import _, { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import Badge from "components/badge/badge";
import InstallerCopier from "components/install/installer-copier";

const IntegrationsModal = ({ hide, ...props }) => {
  const { http } = global.services;
  const [integration, setIntegration] = useState(props);
  const [loading, setLoading] = useState(false);
  const toggleStatus = async () => {
    try {
      setLoading(true);
      const result = await http.put(`apikey/${integration.metadata.id}`, {
        status: integration.connected ? "disabled" : "active",
      });

      setIntegration((prev) => ({
        ...prev,
        connected: result.data.data.status === "active",
        metadata: result.data.data,
      }));
      toast.success(
        integration.connected
          ? "Zapier successfully disconnected"
          : "Zapier successfully connected"
      );
    } catch (err) {
      toast.error(err.data.message);
    } finally {
      setLoading(false);
    }
  };

  const [isCopied, setIsCopied] = useState(false);

  const copyInput = useCallback(() => {
    if (isCopied) return;

    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(integration.metadata.token);
      setIsCopied(true);
      toast.success("Content Copied");
    } else {
      toast.error("Clipboard API is not supported");
    }
  }, [isCopied, integration]);

  return (
    <Modal
      width="500px"
      footer={
        <div className="grid w-full grid-cols-2 gap-5 px-12">
          <Button
            key="toggle-btn"
            buttonName={integration.connected ? "Disable" : "Enable"}
            buttonTextClass="text-white text-sm font-bold"
            buttonClass={
              (integration.connected ? "bg-lightRed" : "bg-primary") +
              " rounded-full py-3"
            }
            buttonType={integration.connected ? "error" : "primary"}
            buttonWidth="100%"
            onClick={toggleStatus}
            disabled={loading}
          />
          <Button
            key="close-btn"
            buttonName="CLOSE"
            buttonTextClass="text-black text-sm font-bold"
            buttonClass="bg-default rounded-full py-3 px-9"
            buttonWidth="100%"
            buttonType="default"
            onClick={() => hide()}
          />
        </div>
      }
    >
      <div className="w-full">
        {/* close button */}
        <div className="mt-3 text-center sm:mt-3 sm:ml-7 sm:text-left ">
          <span className="float-right close" onClick={() => hide()}></span>
        </div>
        {/* close button */}
      </div>
      {/* content below */}
      <div className="w-full mt-9">
        <img
          className="mx-auto"
          src={integration.brand}
          alt={integration.name}
        />
        <h3 className="pt-5 text-3xl leading-9 text-center">
          <span className="capitalize">{integration.name}</span> Integration
        </h3>
        <div className="break-all">
          {isEmpty(integration.metadata) ? (
            <h1>Connect</h1>
          ) : (
            <div className="pt-3 text-base ">
              <p>
                <strong>Status: </strong>
                <Badge
                  color={
                    integration.connected
                      ? "bg-lightGreen text-white"
                      : "bg-darkGray text-white"
                  }
                >
                  {_.capitalize(integration.metadata.status)}
                </Badge>
              </p>
              <div className="flex items-center my-4">
                <strong>API Key: </strong>
                <InstallerCopier
                  content={integration.metadata.token.slice(0, 25) + "..."}
                  contentWidth="max-w-max"
                  isCopied={isCopied}
                  onClick={copyInput}
                />
              </div>
              <p>
                <strong>Created At: </strong>
                {new Date(integration.metadata.created_at).toLocaleString()}
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default IntegrationsModal;
