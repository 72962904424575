const EllipsisVIconSVG = ({ className = "" }) => (
  <svg
    width="4"
    height="19"
    viewBox="0 0 4 19"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" r="2" fill="black" />
    <circle cx="2" cy="9.3999" r="2" fill="black" />
    <circle cx="2" cy="16.8" r="2" fill="black" />
  </svg>
);

export default EllipsisVIconSVG;
