import ImageUploadIconSVG from "assets/svg/image-upload-icon";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useMemo } from "react";
import ReactPlayer from "react-player";
import ButtonPreview from "components/button/button-preview";
import { CONTACTOBJ, btnCorner } from "utils/constant";
import PowerByIconSVG from "assets/svg/powered-by-icon";
import toast from "react-hot-toast";
import ContactDetails from "../component/contacts-details";
import CheckIconSVG from "assets/svg/check-icon-circle";
import HeaderExample from "../component/header";

const TEXT = [
  "30 days free trial",
  "No lock in contracts",
  "Easy to install",
  "No lock in contracts",
];

const showDummy = ["template", "content", "contactM", "contactD"];

export default function StandardPopout({ popup, viewExample = false }) {
  const { view, selectedTab, setDisabledStylePopout, primaryMediaTab } =
    useContext(Context);

  const mobile = view === "mobile";
  const data = viewExample
    ? _.find(CONTACTOBJ, { type: "standard-details" })
    : popup.contact_method_content && JSON.parse(popup.contact_method_content);
  const show = showDummy.includes(selectedTab);

  const handleError = (e) => {
    let err = ["Invalid video link."];
    setDisabledStylePopout((prev) => [...prev, ...err]);
    return toast.error("Invalid video link.");
  };

  const getClassName = () => {
    let result = "standard-empty";
    if (show) {
      if (data.video_link || data.image_url) {
        result = "standard-empty-no-bg";
      }
    } else if (
      (data.video_link && primaryMediaTab === "video") ||
      (data.image_url && primaryMediaTab === "image")
    ) {
      result = "standard-empty-no-bg";
    } else {
      result = "hidden";
    }
    return result;
  };

  const res = useMemo(
    getClassName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show, data.video_link, data.image_url]
  );

  return (
    <div
      className={
        mobile ? "overflow-y-auto mobile-scroll" : "contacts-details-container"
      }
      style={{ height: mobile ? "619px" : "635px" }}
    >
      {!_.isEmpty(data) && (
        <div className="w-full">
          <HeaderExample
            data={data}
            popup={popup}
            show={show}
            className="font-medium"
            placeholder="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore"
          />
          <ContactDetails
            data={data}
            popup={popup}
            show={show}
            mobile={mobile}
          />
          {/* button */}
          <div className="mt-4">
            {(data.buttons || []).map((obj, index) => (
              <ButtonPreview
                key={index}
                buttonName={obj.text || `Button ${index + 1}`}
                font={popup?.font?.family}
                radius={btnCorner(popup?.button_corner_style)}
                textColor={
                  show && _.isEmpty(obj.text)
                    ? "#fff"
                    : popup?.button_font_colour || "#fff"
                }
                hidden={show ? !obj.visible : !obj.visible || !obj.text}
                background={
                  show && _.isEmpty(obj.text)
                    ? "#B9BABB"
                    : popup?.button_colour || "#B9BABB"
                }
              />
            ))}
          </div>
          <div
            className={res}
            style={
              (!data.image_url && primaryMediaTab === "image") ||
              (!data.video_link && primaryMediaTab === "video")
                ? show
                  ? { height: mobile ? "143px" : "160px" }
                  : null
                : null
            }
          >
            {data.video_link && primaryMediaTab === "video" && (
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  url={data.video_link}
                  playing={false}
                  controls
                  width="100%"
                  height="100%"
                  onError={handleError}
                />
              </div>
            )}
            {data.image_url && primaryMediaTab === "image" && (
              <img src={data.image_url} alt="standard" className="w-full" />
            )}
            {!data.image_url && primaryMediaTab === "image" && show && (
              <div className="m-auto">
                <p className="promotion-color grid">
                  <span className="mx-auto">
                    <ImageUploadIconSVG className="h-10 w-10" />
                  </span>
                  {mobile && "Photo or Video"}
                </p>
              </div>
            )}
          </div>
          {/*  */}
          <div className="divide-y-2 divide-default pt-3">
            <div className="grid grid-cols-2 gap-x-3 gap-y-2 pb-3">
              {(data.points || []).map((obj, index) => (
                <div key={index} className={`${!obj.visible && "hidden"}`}>
                  <div
                    className={`flex space-x-1 ${
                      !obj.point && "text-tabBorder"
                    } ${obj.point || show ? "" : "hidden"}`}
                  >
                    {data.show_ticks && (
                      <div className={!obj.point && show ? "opacity-50" : ""}>
                        <CheckIconSVG
                          className="w-4 h-4"
                          fill={popup.button_colour}
                        />
                      </div>
                    )}
                    <p className="text-xs overflow-hidden">
                      {obj.point || (show && TEXT[index])}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {popup?.show_logo && (
        <div className="absolute bottom-5 right-7">
          <PowerByIconSVG className="w-40" />
        </div>
      )}
    </div>
  );
}
