import React, { useState, useMemo } from "react";
import { EditorState, convertToRaw, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { converToEditorState } from "./wysiwyg-editor";

export default function WYSIWYGEditorDraftSub({
  errorMessage = "",
  showCounter = false,
  placeholder = "",
  maxLength,
  value,
  onChange = () => {},
  errorMessagePosition = "top",
  label = "",
  ...props // make sure this pops here is for input only
}) {
  const titleInitials = useMemo(() => {
    return converToEditorState(value || "");
  }, [value]);

  const [editorState, setEditorState] = useState(titleInitials);

  const onEditorStateChange = (editorState) => {
    const htmlValue = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    let count = editorState.getCurrentContent().getPlainText().length;
    if (count <= maxLength) {
      setEditorState(editorState);
      onChange(htmlValue);
    }
  };

  const handleBeforInputCheckPoint = (val) => {
    const textLength = editorState.getCurrentContent().getPlainText().length;
    if (val && textLength >= maxLength) {
      return "handled";
    }
    return "not-handled";
  };

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const _removeSelection = () => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endKey = selection.getEndKey();
    const endOffset = selection.getEndOffset();
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(
        editorState.getCurrentContent(),
        selection,
        "forward"
      );
      const tempEditorState = EditorState.push(
        editorState,
        newContent,
        "remove-range"
      );
      setEditorState(tempEditorState);
      return tempEditorState;
    }
    return editorState;
  };

  const _addPastedContent = (input, editorState) => {
    const inputLength = editorState.getCurrentContent().getPlainText().length;
    let remainingLength = maxLength - inputLength;

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      input.slice(0, remainingLength)
    );
    setEditorState(
      EditorState.push(editorState, newContent, "insert-characters")
    );
  };

  const __handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      maxLength
    ) {
      const selection = editorState.getSelection();
      const isCollapsed = selection.isCollapsed();
      const tempEditorState = !isCollapsed ? _removeSelection() : editorState;
      _addPastedContent(pastedText, tempEditorState);
      return "handled";
    }
    return "not-handled";
  };

  const getCount = () => {
    return editorState.getCurrentContent().getPlainText().length;
  };

  return (
    <div className="w-full relative">
      {errorMessage && errorMessagePosition === "top" && (
        <p className="text-red text-xs mb-0.5">* {errorMessage}</p>
      )}
      <div className="w-full relative">
        {label && (
          <label
            htmlFor="wysiwyg-label"
            className={"absolute top-1.5 pl-4 text-xs opacity-50 lg:text-xs"}
          >
            {label}
          </label>
        )}
        <Editor
          editorState={editorState}
          wrapperClassName={`draft-custom-wrapper border ${
            errorMessage ? "border-red" : "border-thBorder"
          } rounded`}
          editorClassName={`pl-4 pb-3 text-base ${
            showCounter ? "pr-11" : "pr-4"
          } ${label ? "pt-6" : "pt-3"}`}
          toolbarClassName="custom-toolBar"
          onEditorStateChange={onEditorStateChange}
          handleBeforeInput={handleBeforInputCheckPoint}
          handlePastedText={__handlePastedText}
          placeholder={placeholder}
          toolbar={{
            options: ["inline"],
            inline: {
              options: ["bold", "italic", "underline"],
              bold: { className: "bold-custom" },
              italic: { className: "italic-custom" },
              underline: { className: "underline-custom" },
            },
          }}
          {...props}
        />
        {showCounter && (
          <span className={`text-xs opacity-70 absolute top-3.5 right-2.5`}>
            {`${getCount()}/${maxLength}`}
          </span>
        )}
      </div>
      {errorMessage && errorMessagePosition === "bottom" && (
        <p className="text-red text-xs mb-0.5">* {errorMessage}</p>
      )}
    </div>
  );
}
