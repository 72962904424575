import TimeRangePicker from "@wojtekmaj/react-timerange-picker/dist/entry.nostyle";
import MinusIconSVG from "assets/svg/minus-icon";
import timezones from "components/account/timezones";
import DateRangePickerComp from "components/date-range-picker/DateRangePicker";
import Input from "components/input/input";
import Select from "components/select/select";
import SelectWeekday from "components/select/select-weekday";
import { timeToSeconds } from "helpers/converter";
import update from "immutability-helper";
import _ from "lodash";
import { useMemo } from "react";
import "../../../../assets/css/timerange-picker/timerange.css";
import Button from "components/button/button";

// TODO: label and value has the same value, should be merge
const defaultOptions = [
  { label: "Time", value: "Time", type: "timezone" },
  { label: "UTM", value: "UTM", type: "utm" },
  { label: "Returning User", value: "Returning User", type: "returning-user" },
  { label: "Internal URL", value: "Internal URL", type: "internal-url" },
  {
    label: "Referral Domain",
    value: "Referral Domain",
    type: "referral-domain",
  },
  { label: "Day Of the Week", value: "Day Of the Week", type: "weekdays" },
  { label: "Date Range", value: "Date Range", type: "daterange" },
];

const OPTIONS_USER = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const UTM = [
  "utm_source=google",
  "utm_medium=cpc",
  "utm_campaign=spring_sale",
  "utm_term=running+shoes",
  "utm_content=logolink",
  "utm_content=textlink",
];

const DEFAULT_TIME = ["00:00", "12:00"];
const DEFAULT_TIME_SEC = [0, 43200];

export default function SpecialCondition({
  data = [],
  setConditions,
  conditionError = {},
}) {
  const type = (value) => {
    let result = "timezone";
    if (value === "UTM") {
      result = "utm";
    } else if (value === "Returning User") {
      result = "returning-user";
    } else if (value === "Internal URL") {
      result = "internal-url";
    } else if (value === "Day Of the Week") {
      result = "weekdays";
    } else if (value === "Date Range") {
      result = "daterange";
    } else if (value === "Referral Domain") {
      result = "referral-domain";
    }
    return result;
  };

  const handleRemove = (index) => {
    let payload = _.clone(data);

    let result = update(payload, { $splice: [[index, 1]] }); //remove
    setConditions(result);
  };

  const handleOnChange = (value, index, name, option) => {
    let payload = _.clone(data);

    let result = [];
    if (value === "Time") {
      let obj = {
        military_time: DEFAULT_TIME,
        seconds_format: DEFAULT_TIME_SEC,
      };
      result = update(payload, {
        [index]: {
          [name]: { $set: value },
          value: {
            $set: obj,
          },
          type: {
            $set: type(name === "condition" ? value : payload[index].condition),
          },
        },
      });
    } else if (value === "Day Of the Week") {
      result = update(payload, {
        [index]: {
          [name]: { $set: value },
          value: { $set: null },
          timezone: { $set: "Australia/Sydney" },
          type: {
            $set: type(name === "condition" ? value : payload[index].condition),
          },
        },
      });
    } else if (value === "Date Range") {
      result = update(payload, {
        [index]: {
          [name]: { $set: value },
          value: {
            $set: {
              startDate: new Date(),
              endDate: new Date(),
            },
          },
          timezone: { $set: "Australia/Sydney" },
          type: {
            $set: type(name === "condition" ? value : payload[index].condition),
          },
        },
      });
    } else if (value === "Referral Domain") {
      result = update(payload, {
        [index]: {
          [name]: { $set: value },
          value: {
            $set: [""],
          },
          type: {
            $set: type(name === "condition" ? value : payload[index].condition),
          },
        },
      });
    } else {
      if (name === "condition") {
        result = update(payload, {
          [index]: {
            [name]: { $set: value },
            value: { $set: "" },
            type: {
              $set: type(
                name === "condition" ? value : payload[index].condition
              ),
            },
          },
        });
      } else {
        result = update(payload, {
          [index]: {
            [name]: { $set: value },
            type: {
              $set: type(
                name === "condition" ? value : payload[index].condition
              ),
            },
          },
        });
      }
    }

    setConditions(result);
  };

  const handleSelectDay = (day = null, timezone = "") => {
    let result = [];
    let payload = _.clone(data);
    const conditionIndex = payload.findIndex((opt) => opt.type === "weekdays");
    let selectedDays = payload[conditionIndex].value || [];

    //only update value or timezone at a time
    if (day) {
      const existedDayIndex =
        payload[conditionIndex].value?.length > 0
          ? payload[conditionIndex].value?.findIndex((item) => item === day)
          : -1;

      if (existedDayIndex > -1) {
        selectedDays.splice(existedDayIndex, 1);
      } else {
        selectedDays.push(day);
      }
      result = update(payload, {
        [conditionIndex]: {
          value: { $set: selectedDays },
        },
      });
    } else if (timezone) {
      result = update(payload, {
        [conditionIndex]: {
          timezone: { $set: timezone },
        },
      });
    }

    setConditions(result);
  };

  const handleSelectDateRange = (range = null, timezone = "") => {
    let result = [];
    let payload = _.clone(data);
    const conditionIndex = payload.findIndex((opt) => opt.type === "daterange");

    //only update value or timezone at a time
    if (range) {
      result = update(payload, {
        [conditionIndex]: {
          value: { $set: range },
        },
      });
    } else if (timezone) {
      result = update(payload, {
        [conditionIndex]: {
          timezone: { $set: timezone },
        },
      });
    }

    setConditions(result);
  };

  const handleOnChangeObject = (value, index, name) => {
    let payload = _.clone(data);

    payload[index][name].military_time.forEach((item, itemIndex) => {
      if (value[itemIndex]) {
        payload[index][name].military_time[itemIndex] = value[itemIndex];
        payload[index][name].seconds_format[itemIndex] = timeToSeconds(
          value[itemIndex]
        );
      } else {
        return item;
      }
    });

    setConditions(payload);
  };

  const handleOnChangeArray = (value, index, domainIndex) => {
    let payload = _.clone(data);
    payload[index].value[domainIndex] = value;
    setConditions(payload);
  };

  const handleAddMore = (index) => {
    let payload = _.clone(data);
    payload[index].value.push("");
    setConditions(payload);
  };

  const handleRemoveDomain = (index, domainIndex) => {
    let payload = _.clone(data);
    payload[index].value = update(payload[index].value, {
      $splice: [[domainIndex, 1]],
    });
    setConditions(payload);

    // remove the condition if no entry available
    // eslint-disable-next-line
    payload.filter((filter) => {
      if (filter.type === "referral-domain") {
        if (filter.value.length === 0) {
          handleRemove(index);
        }
      }
    });
  };

  const timeRange = useMemo(() => {
    const isTimezoneExists = data.filter((item) => item.type === "timezone");
    let payload = [];
    if (!_.isEmpty(isTimezoneExists)) {
      _.forEach(isTimezoneExists, (obj, index) => {
        if (obj.type === "timezone") {
          payload.push(
            obj.value.military_time ? obj.value.military_time : DEFAULT_TIME
          );
        }
      });
    }
    return payload;
  }, [data]);

  const filteredOptions = useMemo(() => {
    // extract the types only
    const currentSelectedOptions = data.map((d) => d.type);

    return defaultOptions.filter(
      (item) => !currentSelectedOptions.includes(item.type)
    );
  }, [data]);

  const displayError = (type = "") => {
    if (type === conditionError?.type) {
      return conditionError?.message;
    }
  };

  const displayErrorArray = (type = "", index) => {
    if (type === conditionError?.type) {
      return conditionError?.message[index];
    }
  };

  const divEqual = (data, index) => {
    return (
      <div className="w-3/5 flex space-x-3">
        <div className={`${data.type !== "returning-user" && "pt-2.5"} w-full`}>
          {data.type === "internal-url" && (
            <Input
              value={data?.value}
              onChange={(e) => handleOnChange(e.target?.value, index, "value")}
              errorMessagePosition="bottom"
              errorMessage={displayError("Internal URL")}
            />
          )}
          {data.type === "referral-domain" && (
            <div className="flex flex-col items-center">
              {data?.value?.map((value, domainIndex) => {
                return (
                  <div className="pb-2 relative w-full" key={domainIndex}>
                    <Input
                      data-cc={`referral-domain-${domainIndex}`}
                      value={value}
                      onChange={(e) =>
                        handleOnChangeArray(e.target?.value, index, domainIndex)
                      }
                      errorMessagePosition="bottom"
                      errorMessage={displayErrorArray(
                        "Referral Domain",
                        domainIndex
                      )}
                    />
                    {domainIndex > 0 && (
                      <div className={`mt-4 referral-remove`}>
                        <button
                          data-cc={`remove-referral-domain-${domainIndex}`}
                          type="button"
                          onClick={() => handleRemoveDomain(index, domainIndex)}
                          className="btn-remove-alternative rounded-full"
                        >
                          <MinusIconSVG className="m-auto" />
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}

              <Button
                buttonName="+ ADD DOMAIN"
                buttonClass="bg-transparent mt-4"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold text-left text-secondary"
                onClick={() => handleAddMore(index)}
              />
            </div>
          )}
          {/* typeahead */}
          {data.type === "utm" && (
            <Input
              inputContainerClass="w-full relative"
              value={data?.value}
              onSelect={(value) => handleOnChange(value, index, "value")}
              onChange={(e) => handleOnChange(e.target?.value, index, "value")}
              showList={true}
              showEmptyIndicator={false}
              list={UTM}
              errorMessagePosition="bottom"
              errorMessage={displayError("UTM")}
            />
          )}
          {/* datepicker */}
          {data.type === "daterange" && (
            <DateRangePickerComp
              data={data}
              onSelectDateRange={handleSelectDateRange}
              errorMessage={displayError("Day Of the Week")}
            />
          )}
          {data.type === "timezone" && (
            <>
              <TimeRangePicker
                closeClock
                disableClock
                name={"time"}
                rangeDivider="to"
                hourPlaceholder="00"
                minutePlaceholder="00"
                clearIcon={null}
                onChange={(value) =>
                  handleOnChangeObject(value, index, "value")
                }
                value={timeRange[0] || []}
              />
              {displayError("Time") && (
                <p className="text-red text-xs">* {displayError("Time")}</p>
              )}
            </>
          )}
          {data.type === "returning-user" && (
            <Select
              options={OPTIONS_USER}
              placeholder={"Returning User"}
              value={data?.value}
              onChange={(e) => handleOnChange(e.value, index, "value")}
              errorMessagePosition="bottom"
              errorMessage={displayError("Returning User")}
            />
          )}
          {data.type === "timezone" && (
            <Select
              options={timezones}
              placeholder={"Select Time Zone"}
              value={data?.timezone}
              onChange={(e) => handleOnChange(e.value, index, "timezone")}
              errorMessagePosition="bottom"
              errorMessage={displayError("timezone")}
            />
          )}

          {data.type === "weekdays" && (
            <SelectWeekday
              data={data}
              onSelectDay={handleSelectDay}
              errorMessage={displayError("Day Of the Week")}
            />
          )}
        </div>
        <div className={`mt-4 relative`}>
          <button
            type="button"
            onClick={() =>
              data.type !== "referral-domain"
                ? handleRemove(index)
                : handleRemoveDomain(index, 0)
            }
            className="btn-remove-alternative rounded-full"
          >
            <MinusIconSVG className="m-auto" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-3">
      {/* header */}
      <div className="flex space-x-4">
        <p className="text-base font-light w-2/5">Condition</p>
        <p className="text-base font-light w-3/5">Is Equal to</p>
      </div>
      {/* content */}
      {data.map((record, index) => {
        // Remove the specific condition if it is already selected on special condition
        return (
          <>
            {record && record.hasOwnProperty("type") && (
              <div className="flex space-x-4" key={index}>
                <div className="w-2/5">
                  <Select
                    options={filteredOptions}
                    name={`select-${index}`}
                    value={record.condition}
                    onChange={(e) =>
                      handleOnChange(e.value, index, "condition", record)
                    }
                  />
                </div>
                {divEqual(record, index)}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
}
