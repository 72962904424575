import React from "react";
import _ from "lodash";
import styled from "styled-components";
import PhoneIconSVG from "./icons/phoneIcon";
import MailSVG from "./icons/mail";
import SendSVG from "./icons/send";
import { device } from "../../screens";

const ContactsDetailsNumber = styled.a`
  font-weight: 700;
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;

  letter-spacing: -0.03em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 20px;
  line-height: 26px;

  width: fit-content !important;

  ${({ visible }) => (!visible ? "display: none;" : "")}

  &:hover {
    color: ${({ color }) => color};
  }

  @media ${device.xs} {
    font-size: 28px;
    line-height: 28px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

const ContactsDetailsEmail = styled.a`
  margin: 0;
  font-weight: 400;
  color: inherit;
  text-decoration: inherit;

  display: flex;
  align-items: center;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 14px;
  line-height: 17px;

  width: fit-content !important;

  ${({ visible }) => (!visible ? "display: none;" : "")}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : "")}

  &:hover {
    color: ${({ color }) => color} !important;
  }

  @media ${device.xs} {
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const ContactsDetailsData = styled.a`
  margin: 0;
  font-weight: 400;
  text-decoration: inherit !important;

  display: flex;
  align-items: center;

  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 14px;
  line-height: 17px;

  width: fit-content !important;

  ${({ visible }) => (!visible ? "display: none;" : "")}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : "")}

  &:hover {
    color: ${({ color }) => color} !important;
  }

  @media ${device.xs} {
    font-size: 16px;
    line-height: 19px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const Div = styled.div`
  margin-top: 26px;

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-top: 28px;
  }
`;

const ContactDetails = ({ data }) => {
  return (
    <>
      <ContactsDetailsNumber
        responsive={data.width}
        href={`tel:${data.contact_number.value}`}
        visible={
          data.contact_number.visible && !_.isEmpty(data.contact_number.value)
        }
        color={data?.button_colour || "#808080"}
      >
        {data.contact_number.value && (
          <PhoneIconSVG fill={data?.button_colour || "#808080"} />
        )}
        {data.contact_number.value}
      </ContactsDetailsNumber>
      <Div responsive={data.width}>
        <ContactsDetailsEmail
          responsive={data.width}
          visible={data.email_address.visible}
          href={`mailto:${data.email_address.value}`}
          color={data?.button_colour || "#808080"}
        >
          {data.email_address.value && (
            <MailSVG
              fill={data?.button_colour || "#808080"}
              responsive={data.width}
            />
          )}
          {data.email_address.value}
        </ContactsDetailsEmail>
        <ContactsDetailsData
          responsive={data.width}
          target="_blank"
          rel="noreferrer"
          href={`https://maps.google.com/?q=${data.address.latitude},${data.address.longitude}`}
          marginTop="8px"
          visible={
            data.address.visible === undefined ? true : data.address.visible
          }
          color={data?.button_colour || "#808080"}
        >
          {data.address.value && (
            <SendSVG
              fill={data?.button_colour || "#808080"}
              responsive={data.width}
            />
          )}
          {data.address.value}
        </ContactsDetailsData>
      </Div>
    </>
  );
};

export default ContactDetails;
