import styled from "styled-components";
import { motion } from "framer-motion";

const PopupText = styled(motion.p)`
  margin: 0 !important;
  font-size: ${({ mobile }) => (mobile ? "16px" : "18px")} !important;
  line-height: ${({ mobile }) => (mobile ? "19px" : "22px")} !important;
  overflow-wrap: break-word !important;
  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  font-weight: 700;
  width: 100% !important;
  font-family: "Outfit", sans-serif !important;
  color: ${({ color }) => color || "#ffffff"} !important;
`;

export default PopupText;
