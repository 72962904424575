const SelectIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 8L9.33013 0.5H0.669873L5 8Z" fill="black" />
    </svg>
  );
};

export default SelectIconSVG;
