const SecureShieldIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1537_15628)">
        <path
          d="M45.5462 8.63389C38.6668 6.74894 31.747 3.81388 25.5348 0.146118C25.2048 -0.048706 24.7954 -0.048706 24.4655 0.146118C18.0741 3.91926 11.5282 6.69552 4.45351 8.63389C3.99648 8.75899 3.67969 9.17442 3.67969 9.64835V20.395C3.67969 31.4617 8.78505 38.7871 13.068 42.9834C17.679 47.5015 23.0501 50 25.0002 50C26.9501 50 32.3211 47.5015 36.9321 42.9834C41.215 38.7872 46.32 31.4618 46.32 20.395V9.64825C46.32 9.17442 46.0032 8.75899 45.5462 8.63389ZM44.2165 20.3949C44.2165 30.7311 39.4547 37.5666 35.4598 41.4808C30.9255 45.9237 26.0776 47.8963 25.0002 47.8963C23.9227 47.8963 19.0745 45.9237 14.5401 41.4808C10.5453 37.5665 5.7833 30.7311 5.7833 20.3949V10.4467C12.5487 8.52579 18.8493 5.84503 25.0003 2.27053C30.9978 5.75021 37.6127 8.56427 44.2165 10.4463V20.3949Z"
          fill="currentColor"
        />
        <path
          d="M15.6768 10.3292C15.464 9.78866 14.8533 9.52313 14.3127 9.73612C12.4338 10.4764 10.5053 11.1579 8.58076 11.7616C8.14219 11.8993 7.84375 12.3055 7.84375 12.7651V16.8267C7.84375 17.4076 8.31475 17.8785 8.89551 17.8785C9.47627 17.8785 9.94726 17.4076 9.94726 16.8267V13.5338C11.6737 12.9752 13.3983 12.3573 15.0838 11.6931C15.6243 11.4804 15.8898 10.8697 15.6768 10.3292Z"
          fill="currentColor"
        />
        <path
          d="M17.6752 10.5285C17.8164 10.5285 17.9596 10.5001 18.097 10.4401L18.1164 10.4317C18.6485 10.1985 18.8881 9.57911 18.6549 9.04718C18.4215 8.51505 17.7986 8.27433 17.267 8.50734L17.2501 8.51466C16.7176 8.74718 16.4772 9.36612 16.7097 9.89835C16.8823 10.2935 17.2695 10.5285 17.6752 10.5285Z"
          fill="currentColor"
        />
        <path
          d="M37.5304 33.9145C37.0444 33.5969 36.3926 33.7335 36.0745 34.2197C35.2078 35.5463 34.191 36.8166 33.052 37.995C32.1153 38.9639 31.1002 39.8671 30.0351 40.6791C29.5733 41.0312 29.4844 41.6912 29.8365 42.1532C30.0436 42.4246 30.3568 42.5673 30.6736 42.5673C30.896 42.5673 31.1201 42.4971 31.3106 42.3518C32.4595 41.4757 33.5542 40.5018 34.5642 39.4571C35.7957 38.1833 36.8963 36.8083 37.8356 35.3703C38.1534 34.884 38.0166 34.2322 37.5304 33.9145Z"
          fill="currentColor"
        />
        <path
          d="M27.5932 42.3283L27.5329 42.3639C27.0302 42.655 26.8585 43.2983 27.1495 43.801C27.3445 44.1379 27.6975 44.326 28.0607 44.326C28.2394 44.326 28.4208 44.2804 28.5866 44.1842L28.6562 44.1433C29.1574 43.8497 29.3258 43.2055 29.0323 42.7042C28.7385 42.2031 28.0942 42.0351 27.5932 42.3283Z"
          fill="currentColor"
        />
        <path
          d="M17.8308 22.8535C17.185 22.2076 16.3261 21.8521 15.4127 21.8521C14.4992 21.8521 13.6402 22.2077 12.9941 22.8535C11.6608 24.1871 11.6608 26.357 12.9941 27.6905L19.2046 33.9008C19.8505 34.5465 20.7094 34.9021 21.623 34.9021C22.5366 34.9021 23.3955 34.5465 24.0415 33.9006L37.0064 20.9356C38.3396 19.6017 38.3396 17.4321 37.0062 16.099C36.3603 15.4531 35.5014 15.0977 34.5877 15.0977C33.6742 15.0977 32.8153 15.4532 32.1693 16.099L21.6229 26.6454L17.8308 22.8535ZM33.6568 17.5865C33.9053 17.338 34.2361 17.2012 34.5878 17.2012C34.9398 17.2012 35.2702 17.338 35.519 17.5865C36.0322 18.0997 36.0322 18.9351 35.5188 19.4484L22.5541 32.4132C22.3055 32.6618 21.9748 32.7986 21.623 32.7986C21.2712 32.7986 20.9405 32.6618 20.6919 32.4132L14.4815 26.2031C13.9681 25.6897 13.9681 24.8544 14.4813 24.3412C14.7299 24.0927 15.0607 23.9556 15.4126 23.9556C15.7644 23.9556 16.095 24.0925 16.3435 24.341L20.8792 28.8767C21.0765 29.0739 21.3439 29.1847 21.6229 29.1847C21.9019 29.1847 22.1693 29.074 22.3665 28.8767L33.6568 17.5865Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1537_15628">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SecureShieldIconSVG;
