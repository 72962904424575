import React from "react";

const InstallerCopiedSVG = () => (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 7L7 12L17 2" stroke="white" strokeWidth="3" />
  </svg>
);

export default InstallerCopiedSVG;
