const allowedKeys = [
  "user_data",
  "redirectUrl",
  "verification_token",
  "pusherTransportTLS",
  "optin",
  "trialCode",
  "trialDays",
];

const unallowedKeyErrorMsg = "The key is not included in the allowed keys.";

class LocalStorageService {
  get(key) {
    if (!allowedKeys.includes(key))
      return { success: false, error: unallowedKeyErrorMsg };

    const value = localStorage.getItem(key);
    return { success: true, value: value };
  }

  set(key, value) {
    if (!allowedKeys.includes(key))
      return { success: false, error: unallowedKeyErrorMsg };

    localStorage.setItem(key, value);
    return { success: true };
  }

  multiRemove(keys) {
    keys.forEach((key) => {
      if (!allowedKeys.includes(key))
        return { success: false, error: unallowedKeyErrorMsg };
    });

    localStorage.multiRemove(keys);
    return { success: true };
  }

  remove(key) {
    if (!allowedKeys.includes(key))
      return { success: false, error: unallowedKeyErrorMsg };

    localStorage.removeItem(key);
    return { success: true };
  }
}

const serviceLocalStorage = new LocalStorageService();

export default serviceLocalStorage;
