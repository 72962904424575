import styled from "styled-components";
import { motion } from "framer-motion";

const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgb(0, 0, 0, 0.4);
  z-index: 10000;
`;

export default Backdrop;
