import { memo } from "react";

const IconList = ({ icons, onClick }) => {
  return (
    <div className="grid grid-cols-10 gap-7 mt-3 pb-5 max-h-[32rem] overflow-y-auto">
      {icons.map((icon, index) => {
        const { Icon } = icon;
        return (
          <div key={index}>
            {icon.category.substring(0, 8) === "material" ? (
              <Icon
                className="cursor-pointer w-12 h-12 rounded-md fill-current hover:text-secondary"
                onClick={() => onClick(icon)}
                sx={{ fontSize: 48 }}
              />
            ) : (
              <Icon
                className="cursor-pointer w-12 h-12 p-1 rounded-md fill-current hover:text-secondary"
                onClick={() => onClick(icon)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default memo(IconList);
