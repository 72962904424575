const StatisticsDataIconSVG = ({ selected }) => {
  return (
    <div className="float-right">
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={selected ? "1" : "0.5"}>
          <path
            d="M47.6951 48.047H45.5532V16.947C45.5532 15.4994 44.3755 14.3218 42.928 14.3218H38.1405C36.693 14.3218 35.5153 15.4995 35.5153 16.947V48.047H30.0767V31.0377C30.0767 29.5901 28.899 28.4125 27.4515 28.4125H22.664C21.2164 28.4125 20.0388 29.5902 20.0388 31.0377V48.047H14.6001V39.238C14.6001 37.7904 13.4224 36.6128 11.9749 36.6128H7.1873C5.73975 36.6128 4.56211 37.7905 4.56211 39.238V48.047H2.30469C1.76533 48.047 1.32812 48.4842 1.32812 49.0235C1.32812 49.5629 1.76533 50.0001 2.30469 50.0001H47.6951C48.2345 50.0001 48.6717 49.5629 48.6717 49.0235C48.6717 48.4842 48.2345 48.047 47.6951 48.047ZM12.6468 48.047H6.51514V39.238C6.51514 38.8674 6.8166 38.5659 7.18721 38.5659H11.9747C12.3453 38.5659 12.6468 38.8674 12.6468 39.238V48.047ZM28.1234 48.047H21.9918V31.0377C21.9918 30.6671 22.2933 30.3656 22.6639 30.3656H27.4514C27.8219 30.3656 28.1234 30.6671 28.1234 31.0377V48.047ZM43.6001 48.047H37.4685V16.947C37.4685 16.5764 37.7699 16.2749 38.1405 16.2749H42.928C43.2986 16.2749 43.6001 16.5764 43.6001 16.947V48.047Z"
            fill="#333C4E"
          />
          <path
            d="M45.5504 1.02695C45.564 0.760449 45.4703 0.489453 45.2668 0.285937C45.0633 0.0824219 44.7923 -0.0114258 44.5258 0.00234375C44.51 0.00166016 44.4945 0 44.4785 0H38.5703C38.0309 0 37.5937 0.437207 37.5937 0.976562C37.5937 1.51592 38.0309 1.95312 38.5703 1.95312H42.2184L32.5644 11.6072L28.9238 7.9666C28.7407 7.7835 28.4923 7.68057 28.2333 7.68057C27.9743 7.68057 27.7259 7.7835 27.5428 7.9666L9.98718 25.5223C9.60583 25.9036 9.60583 26.522 9.98718 26.9034C10.1779 27.0939 10.4278 27.1893 10.6777 27.1893C10.9276 27.1893 11.1776 27.0939 11.3682 26.9032L28.2333 10.0381L31.8739 13.6787C32.2552 14.0601 32.8736 14.0601 33.2551 13.6787L43.5996 3.33428V6.63604C43.5996 7.17539 44.0368 7.6126 44.5761 7.6126C45.1155 7.6126 45.5527 7.17539 45.5527 6.63604V1.07422C45.5527 1.0583 45.5511 1.04277 45.5504 1.02695Z"
            fill="#EC5627"
          />
          <path
            d="M7.38672 30.5967C7.92606 30.5967 8.36328 30.1595 8.36328 29.6201C8.36328 29.0808 7.92606 28.6436 7.38672 28.6436C6.84738 28.6436 6.41016 29.0808 6.41016 29.6201C6.41016 30.1595 6.84738 30.5967 7.38672 30.5967Z"
            fill="#EC5627"
          />
        </g>
      </svg>
    </div>
  );
};

export default StatisticsDataIconSVG;
