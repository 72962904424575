import React from "react";
import InstallerCopySVG from "assets/svg/installer-copy";
import InstallerCopiedSVG from "assets/svg/installer-copied";
import { csx } from "utils/utils";

const InstallerCopier = ({
  isCopied,
  content,
  type,
  onClick,
  contentWidth = "w-2/3",
  ...props
}) => {
  const contentClass = csx([
    "flex items-center h-auto p-4 mr-0 text-gray-800 bg-white border-t border-b border-l rounded-l-lg border-gray",
    contentWidth,
  ]);
  return (
    <div className="flex flex-1 break-all w-full min-h-10" {...props}>
      <div className={contentClass}>
        <p className="break-words">{content}</p>
      </div>

      <button
        className={`flex items-center justify-center p-4 font-bold text-white text-gray-800 border-t border-b border-r rounded-r-lg ${
          !isCopied
            ? "border-primary bg-primary"
            : "border-lightGreen bg-lightGreen"
        }`}
        onClick={onClick}
      >
        <span className="mb-1 mr-2">
          {isCopied ? <InstallerCopiedSVG /> : <InstallerCopySVG />}
        </span>
        <span>{isCopied ? "Copied" : "Copy"}</span>
      </button>
    </div>
  );
};

export default InstallerCopier;
