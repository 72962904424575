const CampaignDetailsInfoIconSVG = ({ className = "", stroke = "#A3A3A3" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.4997 21.9168C17.2526 21.9168 21.9163 17.2531 21.9163 11.5002C21.9163 5.7472 17.2526 1.0835 11.4997 1.0835C5.74671 1.0835 1.08301 5.7472 1.08301 11.5002C1.08301 17.2531 5.74671 21.9168 11.4997 21.9168Z"
      stroke={stroke}
      strokeWidth="2"
    />
    <path
      d="M11.5 6.29199H11.5104"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9.41602 10.4585H11.4993V15.6668"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.41602 15.667H13.5827"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CampaignDetailsInfoIconSVG;
