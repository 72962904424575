const MinusIconSVG = ({ className = "" }) => (
  <svg
    width="9"
    height="3"
    viewBox="0 0 9 3"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.792969 1.5H8.20473"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default MinusIconSVG;
