import styled from "styled-components";
import { device } from "../../../screens";

const Svg = styled.svg`
  margin-right: 12px;
  width: 16px;
  height: 16px;

  @media ${device.xs} {
    margin-right: 13.5px;
    width: 18px;
    height: 18px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-right: 14px;
    width: 20px;
    height: 20px;
  }
`;

const MailSVG = ({ fill = "#808080", responsive = 140 }) => {
  return (
    <Svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      responsive={responsive}
    >
      <path
        d="M3.33268 3.33325H16.666C17.5827 3.33325 18.3327 4.08325 18.3327 4.99992V14.9999C18.3327 15.9166 17.5827 16.6666 16.666 16.6666H3.33268C2.41602 16.6666 1.66602 15.9166 1.66602 14.9999V4.99992C1.66602 4.08325 2.41602 3.33325 3.33268 3.33325Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M18.3327 5L9.99935 10.8333L1.66602 5"
        stroke={fill}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default MailSVG;
