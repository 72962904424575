import "./App.css";
import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ModalProvider } from "react-modal-hook";
import RouterComponent from "router/index";
import NotificationPopupDock from "components/global/notification/popup/notification-popup-dock";
import { ConfigProvider } from "react-avatar";

function App() {
  return (
    <div className="App">
      <ModalProvider>
        <ConfigProvider colors={["#ffc145", "#28265B", "#EC5627"]}>
          <RouterComponent />
        </ConfigProvider>
      </ModalProvider>
      <NotificationPopupDock />
    </div>
  );
}

export default App;
