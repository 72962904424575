import {
  BarElement,
  CategoryScale,
  Chart as ChartJSBar,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import Card from "components/card/card";
import _ from "lodash";
import { useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJSBar.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const LeadGenerated = ({ labels, datasets, ...props }) => {
  const chartRef = useRef(null);
  const data = useMemo(() => {
    return {
      labels,
      datasets: [
        {
          label: "Visitors",
          data: datasets.impressions,
          backgroundColor: "#28265B",
        },
        {
          label: "Opens",
          data: datasets.opens,
          backgroundColor: "#EC5627",
        },
        {
          label: "Interactions",
          data: datasets.interactions,
          backgroundColor: "#F4BE4F",
        },
      ],
    };
  }, [labels, datasets]);

  const [visibility, setVisibility] = useState(data.datasets.map(() => true));

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // hide default legend
        onClick: (e, legendItem) => {
          const chart = e.chart;
          const index = legendItem.datasetIndex;
          const meta = chart.getDatasetMeta(index);

          meta.hidden =
            meta.hidden === null ? !chart.data.datasets[index].hidden : null;
          chart.update();
        },
      },
    },
  };

  //console.log("visibility", visibility);

  return (
    <Card className="border border-thBorder">
      <div className="grid justify-center 7md:flex 7md:justify-between items-center">
        <h1 className="text-xl text-center 7md:text-left">
          Visitors, Opens, and Interactions
        </h1>
        <div className="grid 7md:flex 7md:gap-x-8">
          {data.datasets.map((dataset, index) => (
            <div
              className="flex align-center cursor-pointer"
              style={{ opacity: visibility[index] ? 1 : 0.5 }}
              key={index}
              onClick={() => {
                const chart = chartRef.current;
                const meta = chart.getDatasetMeta(index);

                //change visible
                const payload = _.clone(visibility);
                payload[index] = !payload[index];
                //console.log("newDatasetVisibility", payload);
                setVisibility(payload);

                meta.hidden =
                  meta.hidden === null
                    ? !chart.data.datasets[index].hidden
                    : null;
                chart.update();
              }}
            >
              <span
                className="w-4 h-4 mr-1 rounded-full"
                style={{ backgroundColor: dataset.backgroundColor }}
              >
                &nbsp;
              </span>
              <span
                className={`text-sm ${
                  !visibility[index] ? "line-through" : ""
                }`}
              >
                {dataset.label}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full mt-5 h-300">
        <Bar ref={chartRef} options={options} data={data} />
      </div>
    </Card>
  );
};

export default LeadGenerated;
