import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../../commons/button";
import ReactPlayer from "react-player";
import ContactDetails from "../../commons/contact-details";
import { device } from "../../../screens";
import CheckCircleSVG from "assets/svg/check-circle-preview";
import PowerByIconSVG from "assets/svg/powered-by-icon";
import ContactsContainer from "../../commons/contact-container";
import CCIconLogoContainer from "../../commons/cc-logo-container";
import _ from "lodash";
import HeaderTemplate from "../../commons/header-template";
import { sanitizeValueHTML } from "utils/constant";

const ImageContainer = styled.div`
  display: flex !important;
  width: 100% !important;
  position: relative !important;
  ${({ isVideo }) => isVideo && "min-height: 280px;"}
`;
const TicksContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 29px;
  row-gap: 18px;
`;
const TicksItem = styled.div`
  width: 100%;
  display: inline-flex;
  column-gap: 10px;

  ${({ visible }) => (!visible ? `display: none;` : "")}

  @media ${device.xs} {
    column-gap: 13px;
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 8px;
  justify-content: flex-start;

  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    column-gap: 12px;
    margin-top: 28px;
    margin-bottom: 28px;
  }
`;
const Image = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
`;
const BtnTicksContainer = styled.div`
  width: 100%;
  margin-top: 24px;

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    margin-top: 28px;
  }
`;

const TicksText = styled.p`
  margin: 0;
  letter-spacing: -0.01em;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747;

  font-size: 12px;
  line-height: 18px;

  @media ${device.xs} {
    font-size: 14px;
    line-height: 21px;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
const Div = styled.div`
  margin-top: 2px;
`;

const StandardDetails = ({ data }) => {
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!data.overlay) {
      setPlaying(false);
    }
  }, [data.overlay]);

  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <HeaderTemplate
          family={data.font?.family}
          width={data.width}
          dangerouslySetInnerHTML={{ __html: sanitizeValueHTML(data.title) }}
          contacts={true}
        />
        <ContactsContainer responsive={data.width}>
          <ContactDetails data={data} />
          <ButtonContainer responsive={data.width}>
            {data.buttons.map((obj, index) => (
              <Button
                responsive={data.width}
                key={`button-${index}`}
                display={!obj.visible || !obj.text ? "none" : "inline-block"}
                background={data?.button_colour || "black"}
                family={data.font?.family}
                radius={data?.button_corner_style}
                color={data?.button_font_colour}
                onClick={() => {
                  if (obj.url) {
                    let url =
                      _.toLower(obj.url).includes("https") ||
                      _.toLower(obj.url).includes("http")
                        ? obj.url
                        : `https://${obj.url}`;

                    window.open(url);
                  }
                }}
              >
                {obj.text}
              </Button>
            ))}
          </ButtonContainer>
          {(!_.isEmpty(data.image_url) || !_.isEmpty(data.video_link)) && (
            <ImageContainer isVideo={!!data?.video_link}>
              {data.video_link && (
                <ReactPlayer
                  url={data.video_link}
                  playing={playing}
                  onPlay={() => setPlaying(true)}
                  controls
                  width="100%"
                  height="280px"
                  css={{
                    position: `absolute`,
                    width: "100%",
                    height: "280px",
                    top: 0,
                    left: 0,
                  }}
                />
              )}
              {data.image_url && (
                <Image src={data.image_url} alt={data.image_key} />
              )}
            </ImageContainer>
          )}
          <BtnTicksContainer responsive={data.width}>
            <TicksContainer>
              {data.points.map((point, pointIndex) => (
                <TicksItem
                  key={`ticks-${pointIndex}`}
                  visible={point.visible && !_.isEmpty(point.point)}
                >
                  {data.show_ticks && (
                    <Div>
                      <CheckCircleSVG
                        responsive={data.width}
                        color={data?.button_colour || "#808080"}
                      />
                    </Div>
                  )}
                  <TicksText responsive={data.width}>{point.point}</TicksText>
                </TicksItem>
              ))}
            </TicksContainer>
          </BtnTicksContainer>
        </ContactsContainer>
      </div>
      {data?.show_logo && (
        <CCIconLogoContainer>
          <PowerByIconSVG />
        </CCIconLogoContainer>
      )}
    </>
  );
};

export default StandardDetails;
