import { useContext } from "react";
import styled from "styled-components";
import KeyPointSVG from "../../../../../assets/svg/key-points-icon";
import ContactInfoSVG from "../../../../../assets/svg/contact-icon";
import TabSelectorClose from "./tab-selector-close";
import TabSelector from "./tab-selector";
import { device, size } from "../../screens";
import { Context } from "context/context";

export const MobileNavContainer = styled.div`
  display: flex !important;
  background-color: #f4f4f4 !important;

  width: 100vw !important;
  position: relative;
  flex-direction: column !important;
  overflow: hidden !important;

  z-index: 99999 !important;

  @media ${device.xs} {
    width: ${size.xs} !important;
    padding-left: 46px !important;
    padding-right: 47px !important;
    padding-top: 12px !important;
  }

  @media (min-width: 1${({ width }) => width + 1}px) {
    display: none !important;
  }
`;
export const TabSelectorNav = styled.div`
  width: 100% !important;
  display: flex !important;
`;
export const NavText = styled.span`
  padding-left: 8px !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  letter-spacing: 0.06em;

  @media ${device.xs} {
    font-size: 14px !important;
    line-height: 14px !important;
  }
`;

const noop = () => {};

const MobileNav = ({ onClose = noop, width = 140 }) => {
  const { mobileTabs, setMobileTabs } = useContext(Context);

  return (
    <MobileNavContainer width={width}>
      <TabSelectorClose onClose={onClose}></TabSelectorClose>
      <TabSelectorNav>
        <TabSelector
          isActive={mobileTabs === "key-points"}
          onClick={() => setMobileTabs("key-points")}
        >
          <KeyPointSVG />
          <NavText responsive={width}>key points</NavText>
        </TabSelector>
        <TabSelector
          isActive={mobileTabs === "contact-info"}
          onClick={() => setMobileTabs("contact-info")}
        >
          <ContactInfoSVG />
          <NavText responsive={width}>contact info</NavText>
        </TabSelector>
      </TabSelectorNav>
    </MobileNavContainer>
  );
};

export default MobileNav;
