import React from "react";
import PhoneIconSVG from "assets/svg/phoneIcon";
import MailIconSVG from "assets/svg/mail-icon";
import SendIconSVG from "assets/svg/send-icon";

const ContactDetails = ({ data, popup, show, mobile, type = "" }) => {
  const addressHide = type === "map" ? "" : !data.address?.visible && "hidden";
  return (
    <div className="mt-2.5">
      <div
        className={`mb-2 text-2xl font-bold flex items-center space-x-2 ${
          !data.contact_number?.visible && "hidden"
        } ${!data?.contact_number?.value && "text-tabBorder"}`}
      >
        {(data?.contact_number?.value || show) && (
          <PhoneIconSVG
            className={`w-5 h-5 text-gray ${
              !data?.contact_number?.value && show ? "opacity-50" : ""
            }`}
            fill={popup.button_colour ? popup.button_colour : "#000"}
          />
        )}
        <p style={{ width: "calc(100% - 28px)" }}>
          {data?.contact_number?.value || (show && "1800 XXX XXX")}
        </p>
      </div>

      <div
        className={`w-full flex items-center space-x-1 ${
          mobile ? "text-sm" : "text-xs"
        } ${!data.email_address?.visible && "hidden"} ${
          !data?.email_address?.value && "text-tabBorder"
        }`}
      >
        {(data?.email_address?.value || show) && (
          <MailIconSVG
            className={`w-3 h-3 text-gray ${
              !data?.email_address?.value && show ? "opacity-50" : ""
            }`}
            fill={popup.button_colour ? popup.button_colour : "#000"}
          />
        )}
        <p style={{ width: "calc(100% - 16px)" }}>
          {data?.email_address?.value ||
            (show && "john.citizen@conversioncow.com")}
        </p>
      </div>

      <div
        className={`w-full flex items-center space-x-1 ${
          mobile ? "text-sm" : "text-xs"
        } ${addressHide} ${!data?.address?.value && "text-tabBorder"}`}
      >
        {(data?.address?.value || show) && (
          <SendIconSVG
            className={`w-3 h-3 text-gray ${
              !data?.address?.value && show ? "opacity-50" : ""
            }`}
            fill={popup.button_colour ? popup.button_colour : "#000"}
          />
        )}
        <p style={{ width: "calc(100% - 16px)" }}>
          {data?.address?.value ||
            (show && "375 Thoroughfare, Lake Sabina, Queensland")}
        </p>
      </div>
    </div>
  );
};

export default ContactDetails;
