import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { device } from "../../screens";
import ChevronDownSVG from "assets/svg/chevron-down";

const SelectWrapper = styled.div`
  width: 100% !important;
`;
const SelectContainer = styled.div`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
`;
const SelectComponentWrapper = styled.div`
  position: relative !important;
  width: 100% !important;
`;
const SelectComponent = styled.button`
  display: flex !important;
  width: 100% !important;
  align-items: center !important;
  justify-content: space-between !important;
  background-color: transparent !important;

  border-radius: 4px !important;
  border-width: 1.5px !important;

  border-color: ${({ error }) => (error ? "#f87171" : "#dbdbdb")};

  letter-spacing: -0.01em !important;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747 !important;
  font-family: inherit !important;

  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;

  font-size: 12px !important;
  line-height: 16px !important;

  @media ${device.xs} {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;

    font-size: 14px !important;
    line-height: 18px !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    padding-top: 17.5px !important;
    padding-bottom: 17.5px !important;

    font-size: 16px !important;
    line-height: 21px !important;
  }
`;
const SelectInput = styled.input`
  width: 100% !important;
  margin-right: 12px !important;

  font-size: 12px !important;
  line-height: 16px !important;

  letter-spacing: -0.01em !important;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747 !important;
  font-family: inherit !important;

  cursor: pointer !important;
  margin: 0 !important;
  padding: 0 !important;

  background-color: transparent !important;

  &:focus-visible {
    outline: none !important;
    border-width: 0px !important;
  }

  @media ${device.xs} {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px !important;
    line-height: 21px !important;
  }
`;
const List = styled.ul`
  list-style: none !important;
  padding: 0 !important;
  max-height: 205px !important;
  position: absolute !important;
  margin-top: 8px !important;
  margin-left: 0 !important;
  width: 100% !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  border-width: 1px !important;
  border-color: #dbdbdb !important;
  overflow-y: auto !important;
  z-index: 50 !important;
  ${({ open }) => (!open ? "display: none !important;" : "")}
`;
const ListItem = styled.li`
  cursor: pointer !important;
  user-select: none !important;

  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 13px !important;
  padding-bottom: 13px !important;

  font-size: 12px !important;
  line-height: 16px !important;

  &:hover {
    background-color: #f3f4f6 !important;
  }

  @media ${device.xs} {
    font-size: 14px !important;
    line-height: 18px !important;

    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px !important;
    line-height: 21px !important;
  }
`;
const ListItemEmpty = styled.li`
  padding: 8px !important;
  text-align: center !important;
  opacity: 0.5 !important;

  font-size: 12px !important;
  line-height: 16px !important;

  @media ${device.xs} {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 16px !important;
    line-height: 21px !important;
  }
`;
const Label = styled.label`
  font-weight: 700 !important;
  letter-spacing: -0.01em !important;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #474747 !important;
  margin-bottom: 6px !important;

  font-size: 10px !important;
  line-height: 13px !important;

  @media ${device.xs} {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  @media (min-width: 1${({ responsive }) => responsive + 1}px) {
    font-size: 14px !important;
    line-height: 18px !important;
  }
`;
const Error = styled.span`
  color: #f87171 !important;
  font-size: 12px !important;
  line-height: 16px !important;
`;
const SelectIcon = styled(ChevronDownSVG)`
  width: 9px !important;
  height: 9px !important;
`;

const Required = styled.span`
  color: rgb(248, 113, 113) !important;
  font-size: 12px !important;
  line-height: 16px !important;
`;

const Select = ({
  label,
  placeholder,
  options = [],
  name = "select",
  value = "",
  error,
  withAsterisk = true,
  responsive,
  onSelect = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(value);

  const handleSelect = (value) => {
    setSelected(value);
    onSelect(value);
    setOpen(false);
  };
  // outside clicks
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * set close if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <SelectWrapper>
      {label && (
        <Label htmlFor={label} responsive={responsive}>
          {label} {withAsterisk && <Required>*</Required>}
        </Label>
      )}
      <SelectContainer ref={wrapperRef}>
        {/* <!-- component --> */}
        <SelectComponentWrapper>
          {/* <!-- trigger button --> */}
          <SelectComponent
            responsive={responsive}
            type="button"
            error={error}
            onClick={() => setOpen(!open)}
          >
            <SelectInput
              responsive={responsive}
              autoComplete="off"
              value={selected}
              readOnly
              placeholder={placeholder}
            />
            {/* stores value in select */}
            <input type="hidden" value={selected} name={name} />
            <SelectIcon />
          </SelectComponent>
          {/* <!-- list items --> */}
          <List open={open}>
            {_.isEmpty(options) && (
              <ListItemEmpty responsive={responsive}>Empty</ListItemEmpty>
            )}
            {(options || []).map((value, index) => (
              <ListItem
                key={index}
                onClick={() => handleSelect(value)}
                responsive={responsive}
              >
                {value}
              </ListItem>
            ))}
          </List>
          {error && <Error>{error.message}</Error>}
        </SelectComponentWrapper>
      </SelectContainer>
    </SelectWrapper>
  );
};

export default Select;
