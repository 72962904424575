const PopoutImageUploadIconSVG = ({ selected }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_16010_3971)" opacity={selected ? "1" : "0.5"}>
        <path
          d="M51.5942 15.1914H1V10.463C1 7.99816 2.99813 6.00003 5.463 6.00003H47.1314C49.5962 6.00003 51.5942 7.99816 51.5942 10.463V15.1914Z"
          stroke="#333C4E"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.75 46.8179C62.75 52.841 57.8672 57.7236 51.8442 57.7236C45.8211 57.7236 40.9386 52.841 40.9386 46.8179C40.9386 40.7948 45.8211 35.9123 51.8442 35.9123C57.8672 35.9123 62.75 40.7948 62.75 46.8179Z"
          stroke="#EC5627"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M51.75 43.6518V50.8877"
          stroke="#EC5627"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M55.368 47.2705H48.1321"
          stroke="#EC5627"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M42.125 10.8721H46"
          stroke="#333C4E"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.2864 32H10.2894"
          stroke="#333C4E"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M51.8442 35.9126V10.7397C51.8442 8.27499 49.8462 6.27686 47.3814 6.27686H5.713C3.24813 6.27686 1.25 8.27499 1.25 10.7397V42.3555C1.25 44.8204 3.24813 46.8184 5.713 46.8184H40.9386C40.9386 40.7954 45.8211 35.9126 51.8442 35.9126Z"
          stroke="#333C4E"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.9386 24.1524C40.9386 26.3028 39.1954 28.0459 37.045 28.0459C34.8945 28.0459 33.1512 26.3028 33.1512 24.1524C33.1512 22.002 34.8945 20.2588 37.045 20.2588C39.1954 20.2588 40.9386 22.002 40.9386 24.1524Z"
          stroke="#333C4E"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.55425 34.9546L1.25 39.1546V42.3545C1.25 44.8194 3.24825 46.8174 5.71312 46.8174H40.9386C40.9386 46.5353 40.9493 46.2553 40.9704 45.9783C40.9844 45.7938 41.0031 45.6104 41.0261 45.4285C41.0609 45.1555 41.1057 44.8858 41.1602 44.6195C41.2691 44.0871 41.4169 43.5688 41.6004 43.0676C41.6921 42.8171 41.7928 42.5709 41.902 42.3294C42.2296 41.6046 42.6343 40.9221 43.1051 40.2924C43.1575 40.2225 43.2107 40.1531 43.2647 40.0844C45.2612 37.5436 48.3622 35.9116 51.8442 35.9116V32.7076L51.4015 32.576L48.4061 30.6683C47.125 29.8525 45.4766 29.8978 44.2424 30.7828L38.593 34.8336C36.8846 36.0586 34.566 35.9813 32.943 34.645L24.0845 27.3516C21.8169 25.4846 18.5536 25.4535 16.2507 27.2769L14.0924 28.9861"
          stroke="#333C4E"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default PopoutImageUploadIconSVG;
