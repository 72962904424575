import { motion } from "framer-motion";

export default function QuickSupportContent(props) {
  return (
    <motion.div
      className="fixed right-0 top-0 bottom-0 overflow-visible 
      flex flex-wrap flex-row-reverse content-start z-40"
      {...props}
    >
      {props.children}
    </motion.div>
  );
}
